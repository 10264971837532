export default function SimplePenIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10.4999V12.9999H2.5L9.87333 5.62656L7.37333 3.12656L0 10.4999ZM12.2733 3.22656L9.77333 0.726562L8.08667 2.4199L10.5867 4.9199L12.2733 3.22656Z"
        fill="#1D7ED9"
      />
    </svg>
  )
}
