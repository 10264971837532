import { Box } from '@chakra-ui/react'
import UsersListItem from '../UsersListItem'

export default function UsersList({ data = [] }) {
  return (
    <Box>
      {data
        ?.sort((a, b) => a.id - b.id)
        ?.map((el, idx) => (
          <UsersListItem
            key={'usersListItem' + idx}
            el={el}
            name={el.first_name + ' ' + el.last_name}
            locations={el.locations}
          />
        ))}
    </Box>
  )
}
