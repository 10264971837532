import { Box, Button, Text } from '@chakra-ui/react'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { useHistory, useParams } from 'react-router-dom'
import { StatisticsIcon } from '../../../assets/Icons/StatisticsIcon'
import { PencilIcon } from '../../../assets/Icons/PencilIcon'
import {
  DISCOUNTS_URL,
  EDIT_PROMOCODE_URL,
  PROMO_PAGE_URL
} from '../../../constants'
import { usePromocode } from '../../../libs/hooks/usePromo'
import TwoStyledInput from '@/components/Common/TwoStyledInput'
import { friendlyTimeWithTZ } from '../../../libs'
import CustomDaySelector from '@/components/Common/CustomDaySelector'
import Preloader from '@/components/Common/Preloader'
import ExperienceApplyList from '@/components/Common/ExperienceApplyList'
import { usePartners } from '../../../libs/hooks/usePartners'

export default function PromoPage() {
  const history = useHistory()
  const { id: promoId = 'undefined' } = useParams()
  const { data: promocode = {}, isLoading } = usePromocode(promoId)
  const { data: { results: partners = [] } = {} } = usePartners()
  const code = promocode?.code
  const available = promocode?.available
  const reason = promocode?.reason ? promocode?.reason : '-'
  const discountCent = promocode?.discount_cent
    ? `$${promocode?.discount_cent / 100}`
    : ''
  const discountPercent = promocode?.discount_percent
    ? `${promocode?.discount_percent}%`
    : ''
  const discountToShow =
    discountPercent || discountCent ? discountPercent || discountCent : '—'
  const minPlayers = promocode?.players_number
    ? promocode?.players_number?.lower
      ? promocode?.players_number?.lower
      : ''
    : ''
  const maxPlayers = promocode?.players_number
    ? promocode?.players_number?.upper
      ? promocode?.players_number?.upper
      : ''
    : ''

  function getInitialPartner() {
    let checkedPartner = ''
    if (promocode?.partner && partners.length !== 0) {
      for (let i = 0; i < partners.length; i++) {
        if (promocode.partner === partners[i].id) {
          checkedPartner = partners[i].name
        }
      }
    }
    return checkedPartner
  }

  function getPlayers() {
    switch ((minPlayers, maxPlayers)) {
      case minPlayers && maxPlayers: {
        return `${minPlayers} — ${maxPlayers}`
      }
      case !!minPlayers === true: {
        return minPlayers
      }

      case !!maxPlayers === true: {
        return maxPlayers
      }
      default:
        return '—'
    }
  }

  const availableAllTime =
    new Date(promocode?.booking_interval_end)?.getUTCFullYear() > 3000 &&
    new Date(promocode?.order_interval_end)?.getUTCFullYear() > 3000

  const bookingIntervalStart = !availableAllTime
    ? friendlyTimeWithTZ(promocode?.booking_interval_start, 'MM.DD.YY')
    : '—'

  const bookingIntervalEnd = !availableAllTime
    ? friendlyTimeWithTZ(promocode?.booking_interval_end, 'MM.DD.YY')
    : '—'
  const orderIntervalStart = !availableAllTime
    ? friendlyTimeWithTZ(promocode?.order_interval_start, 'MM.DD.YY')
    : '—'
  const orderIntervalEnd = !availableAllTime
    ? friendlyTimeWithTZ(promocode?.order_interval_end, 'MM.DD.YY')
    : '—'
  const bookingWeekdays = promocode?.booking_weekdays
    ? promocode?.booking_weekdays
    : []
  const orderWeekdays = promocode?.order_weekdays
    ? promocode?.order_weekdays
    : []
  const eventvariants = promocode?.eventvariants ? promocode?.eventvariants : []

  function goTo(path) {
    return () => history.push(path)
  }

  return (
    <Box
      p={{ base: '24px 16px', '2xl': '48px 0 0' }}
      maxW={{ '2xl': '1100px' }}
      m={{ '2xl': '0 auto' }}
    >
      <Box d={{ md: 'flex' }} fontSize={{ base: '12px', '2xl': '14px' }}>
        <Button
          variant="ghost"
          fontFamily="inherit"
          p="0"
          w={{ md: '100%', '2xl': '100%' }}
          justifyContent="start"
          mb="24px"
          onClick={goTo(DISCOUNTS_URL)}
        >
          <Text
            as={'h1'}
            fontSize={{ base: '24px', '2xl': '32px' }}
            fontWeight={{ base: '600' }}
            color="custom.black.500"
            lineHeight="164%"
          >
            <ArrowIcon
              w="auto"
              height="16px"
              mr="16px"
              mb="2px"
              color="custom.gray.200"
            />
            {code}
          </Text>
        </Button>
        <Box
          d="grid"
          gridGap={{ base: '8px', md: '16px' }}
          gridTemplateColumns="1fr 1fr"
          mb={{ base: '16px' }}
        >
          <Button
            variant="blue"
            onClick={goTo(`${PROMO_PAGE_URL}/${promoId}/stat`)}
            w={{ md: '108px', '2xl': '135px' }}
          >
            <StatisticsIcon mr="8px" />
            Statistics
          </Button>
          <Button
            variant="blue"
            w={{ md: '108px', '2xl': '135px' }}
            onClick={goTo(`${EDIT_PROMOCODE_URL}/${promoId}`)}
          >
            <PencilIcon mr="8px" />
            Edit
          </Button>
        </Box>
      </Box>
      <Box
        bg="white"
        p={{ base: '16px' }}
        d="grid"
        fontSize={{ base: '12px', '2xl': '14px' }}
        gridTemplateColumns={{ base: '1fr 1fr', md: 'repeat(3, 1fr)' }}
        gridGap={{ base: '8px', md: '16px' }}
      >
        {!isLoading ? (
          <>
            <TwoStyledInput
              value={code}
              placeholder="Code"
              isDisabled={true}
              gridColumn={{ base: '1/3', md: '1/2' }}
            />
            <TwoStyledInput
              value={available}
              placeholder="Available"
              isDisabled={true}
              gridColumn={{ base: '1/3', md: '2/3' }}
            />
            <Box gridColumn={{ base: '1/3', md: '3/4' }} w="100%">
              <TwoStyledInput
                value={getInitialPartner()}
                placeholder="Partner"
                isDisabled={true}
                w="100%"
                gridColumn={{ base: '1/3', md: '2/3' }}
              />
            </Box>
            <Box
              gridColumn={{ base: '1/3', md: '1/4' }}
              d="grid"
              gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gridGap={{ base: '8px', md: '16px' }}
            >
              <TwoStyledInput
                value={reason}
                placeholder="Reason"
                isDisabled={true}
                w="100%"
              />
              <TwoStyledInput
                value={discountToShow}
                placeholder="Discount"
                isDisabled={true}
                w="100%"
              />
            </Box>

            <TwoStyledInput
              value={getPlayers()}
              placeholder="Players Number"
              isDisabled={true}
              w="100%"
              gridColumn={{ base: '1/3', md: '1/3' }}
            />
            <TwoStyledInput
              value={availableAllTime}
              placeholder="Available all time"
              isDisabled={true}
              w="100%"
              gridColumn={{ base: '1/3', md: '3/4' }}
            />
            <TwoStyledInput
              value={bookingIntervalStart}
              placeholder="Book from"
              isDisabled={true}
              w="100%"
              gridColumn={{ base: '1/2' }}
            />
            <TwoStyledInput
              value={bookingIntervalEnd}
              placeholder="Book to"
              isDisabled={true}
              w="100%"
              gridColumn={{ base: '2/3' }}
            />
            <CustomDaySelector
              gridColumn={{ base: '1/3', md: '3/4' }}
              alignItems="center"
              initialState={bookingWeekdays}
              readOnly={true}
            />
            <TwoStyledInput
              value={orderIntervalStart}
              placeholder="Play from"
              isDisabled={true}
              w="100%"
              gridColumn={{ base: '1/2' }}
            />
            <TwoStyledInput
              value={orderIntervalEnd}
              placeholder="Play to"
              isDisabled={true}
              w="100%"
              gridColumn={{ base: '2/3' }}
            />
            <CustomDaySelector
              gridColumn={{ base: '1/3', md: '3/4' }}
              alignItems="center"
              readOnly={true}
              initialState={orderWeekdays}
            />

            <ExperienceApplyList
              label="Experiences"
              gridColumn={{ base: '1/4' }}
              initialState={eventvariants}
              w="100%"
              readOnly={true}
              mb="16px"
            />
          </>
        ) : (
          <Preloader gridColumn={{ base: '1/4' }} />
        )}
      </Box>
    </Box>
  )
}
