import { Box, Text } from '@chakra-ui/react'
import TemplatesList from './TemplatesList'
import AddTemplateModal from './Modals/AddTemplateModal'
import { useSelector } from 'react-redux'
import {
  EVENTS_LIST,
  LOCATIONS_LIST,
  matchMedia768InState
} from '../../../../constants'
import StyledAccordion from '../../../Common/StyledAccodion'
import * as changeCase from 'change-case'
import { useNotificationTemplates } from '../../../../libs/hooks/useNotificationTemplates'
import { useMemo } from 'react'
import NotificationBlock from './NotificationBlock'
import EmailTemplatesList from './EmailTemplatesList'
import AddEmailTemplateModal from './Modals/AddEmailTemplateModal'
import LocationTemplatesBlock from './LocationTemplatesBlock'
import { useEmailBaseTemplates } from '../../../../libs/hooks/useEmailBaseTemplates'
import { isObject } from '../../../../libs'

export default function Notifications() {
  const { data: notificationTemplates = [] } = useNotificationTemplates()
  const { data: emailBaseTemplates = [] } = useEmailBaseTemplates()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const eventList = EVENTS_LIST
  const locationsList = LOCATIONS_LIST

  const notificationOption = [
    { value: [], label: 'Unset' },
    { value: ['SMS'], label: 'SMS' },
    { value: ['EMAIL'], label: 'E-mail' },
    { value: ['SMS', 'EMAIL'], label: 'SMS and E-mail' }
  ]

  const templatesOptions = useMemo(() => {
    if (notificationTemplates.length !== 0) {
      const arr = notificationTemplates.map((template) => {
        return {
          value: template.id,
          label: template.template_name
        }
      })
      return [{ value: null, label: 'Unset' }, ...arr]
    } else {
      return []
    }
  }, [notificationTemplates])

  const emailTemplatesOptions = useMemo(() => {
    if (emailBaseTemplates.length !== 0) {
      const arr = emailBaseTemplates.map((template) => {
        return {
          value: template.id,
          label: template.template_name
        }
      })
      return [{ value: null, label: 'Unset' }, ...arr]
    } else {
      return []
    }
  }, [emailBaseTemplates])

  return (
    <Box>
      {eventList?.map((queryParam, idx) => {
        const isObj = isObject(queryParam)

        const name = isObj
          ? queryParam.label
          : changeCase.capitalCase(queryParam)
        const queryParamHandler = isObj ? queryParam.queryParam : queryParam
        return (
          <StyledAccordion key={idx} defaultIndex={null}>
            {isLargerThan768 ? `Event: ${name}` : name}
            <NotificationBlock
              queryParam={queryParamHandler}
              templatesOptions={templatesOptions}
              notificationOption={notificationOption}
            />
          </StyledAccordion>
        )
      })}

      <Box mb="16px">
        <Box
          p={{ base: '16px', '2xl': '24px' }}
          mb="2px"
          bg="white"
          d="flex"
          alignItems="center"
        >
          <Text
            color="custom.black.500"
            fontSize={{ base: '18px', '2xl': '24px' }}
            fontWeight="500"
          >
            Event templates
          </Text>
          <AddTemplateModal />
        </Box>
        <TemplatesList data={notificationTemplates} />
      </Box>

      <Box mb="16px">
        <Box
          p={{ base: '16px', '2xl': '24px' }}
          mb="2px"
          bg="white"
          d="flex"
          alignItems="center"
        >
          <Text
            color="custom.black.500"
            fontSize={{ base: '18px', '2xl': '24px' }}
            fontWeight="500"
          >
            Email base templates
          </Text>
          <AddEmailTemplateModal />
        </Box>
        <EmailTemplatesList data={emailBaseTemplates} />
      </Box>

      {locationsList?.map((queryParam, idx) => {
        const name = changeCase.capitalCase(queryParam)
        return (
          <StyledAccordion defaultIndex={null} key={idx}>
            {isLargerThan768 ? `Locations: ${name}` : name}
            <LocationTemplatesBlock templatesOptions={emailTemplatesOptions} />
          </StyledAccordion>
        )
      })}
    </Box>
  )
}
