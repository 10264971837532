import { Box, Button } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import PermissionControl from '../../Common/PermissionControl'
import PermissionRequired from '../../Common/PermissionRequired'
import { toggleMenu } from '../../../actions'
import { NavigationHeaderMenu } from '../../../configs'
import {
  matchMedia1440InState,
  menuIsOpenInState,
  PERMISSION_CASH_VIEW,
  PERMISSION_COMPANIES,
  PERMISSION_CUT_OFF,
  PERMISSION_DISCOUNTS,
  PERMISSION_EVENT,
  PERMISSION_GIFTCARDS,
  PERMISSION_LIMITATIONS,
  PERMISSION_LOCATIONS,
  PERMISSION_NOTIFICATIONS,
  PERMISSION_PLAYERS,
  PERMISSION_STATISTICS,
  PERMISSION_USERS
} from '../../../constants'

export default function NavigationMenu() {
  const dispatch = useDispatch()
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const history = useHistory()
  const location = useLocation()
  const menuIsOpen = useSelector(menuIsOpenInState)
  const cutOffTimePermission = PermissionRequired(PERMISSION_CUT_OFF, true)
  const locationPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const usersPermission = PermissionRequired(PERMISSION_USERS, true)
  const limitationsPermission = PermissionRequired(PERMISSION_LIMITATIONS, true)
  const accountPermission = PermissionRequired(PERMISSION_COMPANIES, true)
  const attractionPermission = PermissionRequired(PERMISSION_EVENT, true)

  const allSettingsBlocked =
    !locationPermission &&
    !notificationPermission &&
    !usersPermission &&
    !accountPermission &&
    !attractionPermission &&
    !limitationsPermission &&
    !cutOffTimePermission

  const initialState = useMemo(() => {
    for (let i = 0; i < NavigationHeaderMenu.length; i++) {
      if (location.pathname === NavigationHeaderMenu[i].path) {
        return i
      }
    }
    return -1
  }, [location])
  const [checked, setChecked] = useState(initialState)
  useEffect(() => {
    let founded = -1
    for (let i = 0; i < NavigationHeaderMenu.length; i++) {
      if (location.pathname === NavigationHeaderMenu[i].path) {
        founded = i
        break
      }
    }
    setChecked(founded)
  }, [location.pathname])

  function bg(item, checked) {
    if (isLargerThan1440) {
      return ''
    } else {
      return checked ? 'custom.blue.600' : ''
    }
  }

  function fontWeight(item, checked) {
    if (isLargerThan1440) {
      return checked ? '500' : '400'
    } else {
      return '500'
    }
  }

  function color(item, checked) {
    if (isLargerThan1440) {
      return checked ? 'rgba(4, 113, 241, 1)' : 'rgba(0,0,0,1)'
    } else {
      return checked ? 'white' : ''
    }
  }

  function borderColor(item, checked) {
    if (isLargerThan1440) {
      return checked ? 'rgba(4, 113, 241, 1)' : 'rgba(255,255,255,1)'
    } else {
      return ''
    }
  }

  function onClickHandler(idx, path) {
    setChecked(idx)
    history.push(path)
    if (!isLargerThan1440) {
      dispatch(toggleMenu())
    }
  }

  useEffect(() => {
    if (isLargerThan1440 && menuIsOpen) {
      dispatch(toggleMenu())
    }
  }, [isLargerThan1440, menuIsOpen])

  return (
    <>
      <PermissionControl onClickHandler={onClickHandler} />
      <Box
        d="flex"
        flexDir={{ base: 'column', '2xl': 'row' }}
        m={{ base: 'unset', '2xl': '0 auto' }}
        h={{ base: 'auto', '2xl': '100%' }}
        fontSize={{ base: '14px', '2xl': '18px' }}
      >
        {NavigationHeaderMenu?.map((item, idx) => {
          return (
            <Button
              bg={bg(item, idx === checked)}
              color={color(item, idx === checked)}
              borderBottom={{
                base: 'none',
                '2xl': `2px solid ${borderColor(item, idx === checked)}`
              }}
              variant="gbaseMenuButton"
              fontWeight={fontWeight(item, idx === checked)}
              key={'burgerMenu' + idx + 'mm'}
              _focus={{
                boxShadow: 'none'
              }}
              _hover={{
                bg: checked === idx ? '' : 'blue.50'
              }}
              onClick={() => onClickHandler(idx, item.path)}
              w={{ base: 'auto', '2xl': '126px' }}
              fontSize="inherit"
              textTransform="capitalize"
              isDisabled={
                allSettingsBlocked && item.label.toLowerCase() === 'settings'
                  ? true
                  : false
              }
              {...(item.label.toLowerCase() === 'gift cards' && {
                ...PermissionRequired(PERMISSION_GIFTCARDS)
              })}
              {...(item.label.toLowerCase() === 'statistics' && {
                ...PermissionRequired(PERMISSION_STATISTICS)
              })}
              {...(item.label.toLowerCase() === 'discounts' && {
                ...PermissionRequired(PERMISSION_DISCOUNTS)
              })}
              {...(item.label.toLowerCase() === 'cash' && {
                ...PermissionRequired(PERMISSION_CASH_VIEW)
              })}
              {...(item.label.toLowerCase() === 'players' && {
                ...PermissionRequired(PERMISSION_PLAYERS)
              })}
            >
              {item.label}
            </Button>
          )
        })}
      </Box>
    </>
  )
}
