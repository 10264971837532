import { api } from '../index'
import { getWaiverWidgetByBooking } from '../waiverWidget'
import { correctionTimezone } from '../../libs'

export async function getPayments({
  bookingId = 1,
  ordering = 'id',
  currentPage = 1,
  itemsPerPage = 25
}) {
  try {
    const data = await api.get('/api/payments/', {
      params: {
        booking: bookingId,
        ordering: ordering,
        page: currentPage,
        page_size: itemsPerPage
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getBooking(bookingId = 1, { paymentsOrdering = null }) {
  const { data } = await api.get(`/api/bookings/${bookingId}/`, {
    params: {
      payments_ordering: paymentsOrdering
    }
  })
  return data
}

export async function getOrder({ orderId = 1 }) {
  try {
    const data = await api.get(`/api/orders/${orderId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getAllBookingsByOrder({ orderId = 1 }) {
  try {
    const data = await api.get(
      `/api/bookings/?order=274&ordering=-id&page=1&page_size=100/`,
      {
        params: {
          order: orderId
        }
      }
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function addNewComment({ orderId = 1, text = '' }) {
  try {
    const { data } = await api.post(`/api/orders/${orderId}/add_comment/`, {
      id: orderId,
      text: text
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function loadImageToCloud(images, returnFullInfo = false, enable) {
  try {
    const response = await Promise.all(
      images.map(async (img) => {
        const formData = new FormData()
        formData.append('file', img)
        const { data } = await api.post(
          '/api/storage/image-upload/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        if (returnFullInfo) {
          return data
        } else {
          return data?.id
        }
      })
    )

    return response
  } catch (e) {
    console.log(e)
  }
}

export async function loadImageToCloudWithAssociate(images = []) {
  try {
    const response = await Promise.all(
      images.map(async ({ img, label }) => {
        const formData = new FormData()
        formData.append('file', img)
        const { data } = await api.post(
          '/api/storage/image-upload/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        return { id: data?.id, label }
      })
    )

    return response
  } catch (e) {
    console.log(e)
  }
}

export async function finishGame({
  teamName = '',
  bookingId = 1,
  minutes = 0,
  seconds = 0,
  result = '',
  photos = [],
  time = 0
}) {
  try {
    const { data } = await api.post(`/api/bookings/${bookingId}/finish/`, {
      id: bookingId,
      team_name: teamName,
      photos: photos,
      time: time,
      result: result,
      seconds: seconds,
      minutes: minutes
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function resendPictures({ bookingId, photos }) {
  const { data } = await api.post(
    `/api/bookings/${bookingId}/resend_pictures/`,
    {
      photos
    }
  )
  return data
}

export async function addPlayers({
  bookingId = 1,
  playersAmount = 1,
  paymentType = '',
  payments
}) {
  const data = await api.post(`/api/bookings/${bookingId}/add_players/`, {
    players_amount: playersAmount,
    payment_source: paymentType,
    ...payments
  })
  return data
}

export async function getSlots({
  locationId,
  eventVariantId,
  players,
  timestamp
}) {
  const correctedTimestamp = correctionTimezone(timestamp, true)
  const { data } = await api.post('/api/eventvariants/slots/', {
    location_id: +locationId,
    event_variant_id: +eventVariantId,
    players: +players,
    timestamp_date: +correctedTimestamp
  })
  return data
}

export async function loadAutocompeteListWaivers(searchString) {
  const { data } = await api.get(
    `/api/waiversentitys/?search=${searchString.trim()}&limit=1`
  )
  return data
}

export async function resendNotification({ bookingId, sendEmail, sendSms }) {
  const { data } = await api.post(
    `/api/bookings/${bookingId}/resend_notification/`,
    {
      send_email: sendEmail,
      send_sms: sendSms
    }
  )
  return data
}

export async function getPlayerPrice(players, bookingId) {
  const { data } = await api.get(`/api/bookings/${bookingId}/players_price/`, {
    params: {
      players: players
    }
  })
  return data
}

export async function removePlayers({ bookingId, players }) {
  const { data } = await api.post(`api/bookings/${bookingId}/remove_players/`, {
    players_amount: players
  })
  return data
}

export async function getBookingsByBookingCodes(array) {
  const promises = await Promise.all(
    array.map(async (bookingCode) => {
      const data = await getWaiverWidgetByBooking(bookingCode)
      return data
    })
  )
  return promises
}

export async function patchBooking({ bookingId, unlimitedSigns }) {
  const { data } = await api.patch(`/api/bookings/${bookingId}/`, {
    unlimited_signs: unlimitedSigns
  })
  return data
}

export async function patchBookingsArray({ bookingsIds, unlimitedSigns }) {
  const data = await Promise.all(
    bookingsIds.map(async (id) => {
      const bookingId = id
      const data = await api.patch(`/api/bookings/${bookingId}/`, {
        unlimited_signs: unlimitedSigns
      })
      return data
    })
  )
  return data
}

export async function sendGroupNotif(orderId) {
  const { data } = await api.post(
    `/api/orders/${orderId}/send_group_notification/`
  )
  return data
}
