export default function Footer({ waiverData = {} }) {
  console.log('waiverData', waiverData)
  const address = waiverData?.address
  const phone = waiverData?.phone
  const email = waiverData?.email

  return (
    <div className="footer">
      <div className="footer--main-info">
        {!!address && <p>{address}&nbsp;&nbsp;·&nbsp;&nbsp;</p>}
        {!!phone && (
          <p>
            <a href={`tel:${phone}`}>{phone}&nbsp;&nbsp;·&nbsp;&nbsp;</a>
          </p>
        )}
        {!!email && (
          <p>
            <a href={`mailto:${email}`}>{email}</a>
          </p>
        )}
      </div>
      <div className="footer--powered">Powered by GBASE</div>
    </div>
  )
}
