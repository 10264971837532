import { Box, Button, IconButton, Input, useToast } from '@chakra-ui/react'
import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../../../../../constants'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { useCreateWaivercheckout } from '../../../../../../libs/hooks/useWaiverscheckouts'
import { useQueryClient } from 'react-query'
import { generateWarningToast } from '../../../../../../libs'

export default function NewCheckoutsWaiverItem({
  placeholder,
  text = '',
  closeNewCheckout = null,
  waiverBlockId = '',
  waiverId = null,
  lastCheckoutPositionIndex = null,
  ...rest
}) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [input, setInput] = useState(text)
  const { mutate: newWaiverCheckout } = useCreateWaivercheckout()

  function createNewCheckout() {
    const toSend = {
      text: input,
      waiverBlockId: waiverBlockId,
      position: lastCheckoutPositionIndex + 1
    }
    newWaiverCheckout(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['waiver', +waiverId])
          closeNewCheckout()
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          generateWarningToast({
            id: 'new-checkout',
            toast,
            msgObjects,
            message: msg
          })
        }
      }
    )
  }

  function onChangeHandler() {
    return (e) => setInput(e.target.value)
  }

  return (
    <Box mb="16px" d="flex" flexDir={{ base: 'column', md: 'row' }} {...rest}>
      <Input
        variant="gbase"
        mb={{ base: '8px', md: 'unset' }}
        value={input}
        onChange={onChangeHandler()}
        placeholder={placeholder}
      />

      {isLargerThan768 ? (
        <Box
          d="grid"
          gridTemplateColumns="1fr 1fr"
          gridGap="20px"
          alignItems="center"
          ml="20px"
        >
          <>
            <IconButton
              variant="funcColumnIconButton"
              color="custom.blue.600"
              w="12px"
              minW="unset"
              h="12px"
              minH="unset"
              _hover={{ boxShadow: 'none' }}
              _focus={false}
              onClick={createNewCheckout}
              icon={<DisketteIcon />}
            />
            <IconButton
              variant="funcColumnIconButton"
              color="custom.gray.200"
              w="12px"
              minW="unset"
              h="12px"
              minH="unset"
              onClick={closeNewCheckout}
              icon={<CrosshairIcon />}
            />
          </>
        </Box>
      ) : (
        <Box d="grid" gridTemplateColumns="1fr 1fr" gridGap="8px">
          <>
            <Button variant="blue" onClick={createNewCheckout}>
              <DisketteIcon mr="8px" />
              Save
            </Button>
            <Button variant="red" onClick={closeNewCheckout}>
              <CrosshairIcon mr="8px" />
              Cancel
            </Button>
          </>
        </Box>
      )}
    </Box>
  )
}
