import Preloader from '../Preloader'

export default function ButtonBlue({
  children,
  isLoading = false,
  icon = null,
  btnRef = null,
  ...rest
}) {
  return (
    <button
      className={`button button-blue ${isLoading ? 'loading' : ''}`}
      ref={btnRef}
      {...rest}
    >
      {isLoading ? (
        <Preloader />
      ) : (
        <span>
          {children} {icon && icon}{' '}
        </span>
      )}
    </button>
  )
}
