import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { friendlyTime } from '../../../../libs'
import { useCancelGame } from '../../../../libs/hooks/useCancelGame'
import { PERMISSION_BOOKING_SET_CANCEL } from '../../../../constants'
import { BookingContext } from '../../../../pages/Booking'
import DataRow from '../../../Common/DataRow'
import PermissionRequired from '../../../Common/PermissionRequired'

export default function CancelGameModal({
  startTime,
  locationName,
  experience
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [bookingData, isLoading, filters, setFilters, queryKeysBooking] =
    useContext(BookingContext)
  const { id } = useParams()
  const queryClient = useQueryClient()
  const data = [
    { key: 'Game Date', value: friendlyTime(startTime, 'MM.DD.YY') },
    { key: 'Start time', value: friendlyTime(startTime, 'h:mm A') },
    { key: 'Location', value: locationName },
    { key: 'Experience', value: experience }
  ]
  const { mutate } = useCancelGame()

  function onSuccessHandler() {
    queryClient.invalidateQueries(queryKeysBooking)
    onClose()
  }

  function onClickHandler() {
    mutate({ bookingId: id }, { onSuccess: onSuccessHandler })
  }

  return (
    <>
      <Button
        onClick={onOpen}
        variant="red"
        {...PermissionRequired(PERMISSION_BOOKING_SET_CANCEL)}
      >
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          Cancel game
        </Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader>Cancel game</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              mb={{ base: '16px', '2xl': '18px' }}
              d="flex"
              flexDir={{ base: 'column', md: 'row' }}
              flexWrap="wrap"
            >
              {data.map((dataRow, idx) => (
                <DataRow
                  title={dataRow.key}
                  key={'modalAdd' + idx}
                  value={dataRow.value}
                  w={{ base: '100%', md: 'unset' }}
                  mb={{ base: '4px', '2xl': '8px' }}
                  keyStyle={{
                    mr: { base: 'unset', md: '8px' }
                  }}
                  valueStyle={{
                    mr: { base: 'unset', md: '16px' }
                  }}
                />
              ))}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClickHandler}
              variant="red"
              w="100%"
              height={{ base: '40px', '2xl': '56px' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              fontWeight="500"
            >
              Cancel game
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
