import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,  ModalHeader,
  ModalOverlay,
  Text,
useDisclosure,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { MdBrokenImage } from 'react-icons/all'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { loadImageToCloud } from '../../../../../../api/booking'
import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PencilIcon } from '../../../../../../assets/Icons/PencilIcon'
import { convertByteToMb, generateWarningToast } from '../../../../../../libs'
import { useCities } from '../../../../../../libs/hooks/useCities'
import {
  useEditLocation,
  useLocationById
} from '../../../../../../libs/hooks/useLocations'
import {
  MAXIMUM_IMAGE_SIZE,
  MAXIMUM_IMAGES_COUNT
} from '../../../../../../configs'
import { addLocationValidation } from '../../../../../../configs/validation/addLocationModal'
import {
  BASE_URL_WO_SLASH,
  matchMedia768InState,
  matchMedia1440InState,
  PERMISSION_LOCATIONS
} from '../../../../../../constants'
import CustomWarningToast from '../../../../../Common/CustomWarningToast'
import FormikDropdown from '../../../../../Common/FormikComponents/FormikDropdown'
import FormikTwoStyledInput from '../../../../../Common/FormikComponents/FormikTwoStyledInput'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import Preloader from '../../../../../Common/Preloader'
import { CityDropdown } from '../../../../../inputs/CityDropdown'

export default function EditLocationModal({ locationId = '' }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: location = {}, isLoading: isLoadingLocation } = useLocationById(
    locationId,
    {
      enabled: isOpen
    }
  )

  const cities = useCities()
  const toast = useToast()
  const {
    name = '',
    address = '',
    timezone = '',
    direction_link_href: linkHref = '',
    direction_link_text: linkText = '',
    parking_instructions: parkingPhoto,
    bad_feedback_link: badFeedbackLink = '',
    good_feedback_link: goodFeedbackLink = '',
    telegram_chat_id: telegramChatId,
    city_id: cityId
  } = location
  const imageHandler = parkingPhoto ? parkingPhoto : ''
  const locationsPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const queryClient = useQueryClient()
  const { mutate: editLocation, isLoading: isLoadingEditLocation } =
    useEditLocation()
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [selectedImage, setSelectedImage] = useState([])
  const [inputReload, setInputReload] = useState(true)
  const preparedTimezone = { value: timezone, label: timezone }
  const preparedCityId = {
    value: cityId,
    label: cities.find((city) => city.id === cityId)?.name
  }
  const [localLoading, setLocalLoading] = useState(false)
  const [itsBroken, setItsBroken] = useState(false)

  function istBrokenHandler() {
    setItsBroken(true)
  }

  const optionsTimezones = useMemo(() => {
    return moment.tz.names().map((tz) => {
      return {
        value: tz,
        label: tz
      }
    })
  }, [])

  const formik = useFormik({
    initialValues: {
      name,
      address,
      timezone: preparedTimezone,
      parkingPhoto: imageHandler,
      linkHref,
      linkText,
      goodFeedbackLink,
      badFeedbackLink,
      telegramChatId,
      cityId: preparedCityId
    },
    onSubmit: submitHandler,
    enableReinitialize: true,
    validationSchema: addLocationValidation
  })

  const handleSubmit = formik.handleSubmit
  const formikValues = formik.values
  const image = formikValues.parkingPhoto
  const setFieldValue = formik.setFieldValue
  const formReset = formik.resetForm

  function onChangeFileHandler() {
    return (e) => {
      const images = Array.from(e.target.files)
      const allSizesSmallerRequired = images.some(
        (img) => img.size > MAXIMUM_IMAGE_SIZE
      )
      if (!allSizesSmallerRequired) {
        if (
          images.length <= MAXIMUM_IMAGES_COUNT &&
          images.length + selectedImage.length <= MAXIMUM_IMAGES_COUNT
        ) {
          if (selectedImage.length !== 0) {
            setSelectedImage([...selectedImage, ...images])
          } else {
            setSelectedImage(images)
          }
        } else {
          toast({
            status: 'warning',
            duration: 5000,
            isClosable: true,
            render: () => (
              <CustomWarningToast
                title="Warning"
                message={`You can load maximum ${MAXIMUM_IMAGES_COUNT} images.`}
                close={toast.closeAll}
              />
            )
          })
        }
      } else {
        toast({
          status: 'warning',
          duration: 5000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={`maximum image size must be
                                         less than ${convertByteToMb(
                                           MAXIMUM_IMAGE_SIZE
                                         )}mb.`}
              close={toast.closeAll}
            />
          )
        })
      }
    }
  }

  function deleteImageHandler(idx) {
    setInputReload(true)
    const newArr = [...selectedImage]
    newArr.splice(idx, 1)
    setSelectedImage(newArr)
  }

  function deleteOldPhoto() {
    setFieldValue('parkingPhoto', '')
  }

  const isLoading = isLoadingLocation || isLoadingEditLocation || localLoading
  useEffect(() => {
    if (!inputReload) {
      setTimeout(() => setInputReload(true), 50)
    }
  }, [inputReload])

  function onCloseHandler() {
    setSelectedImage([])
    setItsBroken(false)
    setInputReload(false)
    setLocalLoading(false)
    formReset()
    onClose()
  }

  function submitHandler(values) {
    setLocalLoading(true)
    const toSend = {
      ...values,
      timezone: values.timezone.value,
      cityId: values.cityId.value
    }
    if (selectedImage.length !== 0) {
      loadImageToCloud(selectedImage)
        .then((r) => {
          return {
            ...toSend,
            parkingPhoto: r[0]
          }
        })
        .then((obj) => {
          editLocation(
            {
              obj: { ...obj, locationId: locationId }
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(['locations'])
                setLocalLoading(false)
                onCloseHandler()
              },
              onError: (err) => {
                setLocalLoading(false)
                const msg = err?.response?.data
                generateWarningToast({
                  msgObjects: msg,
                  toast,
                  id: 'new-location'
                })
              }
            }
          )
        })
    } else {
      editLocation(
        {
          obj: { ...toSend, locationId: locationId }
        },
        {
          onSuccess: () => {
            setLocalLoading(false)
            queryClient.invalidateQueries(['locations'])
            onCloseHandler()
          },
          onError: (err) => {
            setLocalLoading(false)
            const msg = err?.response?.data
            generateWarningToast({
              msgObjects: msg,
              toast,
              id: 'new-location'
            })
          }
        }
      )
    }
  }

  const dropDownSelectProps = {
    styles: {
      control: {
        height: isLargerThan1440 ? '56px' : '40px',
        padding: isLargerThan1440 ? '12px 4px 0' : '0',
        border: !isLargerThan768 && 'none'
      }
    }
  }
  return (
    <>
      <IconButton
        aria-label="editAccount"
        variant="funcColumnIconButtons"
        icon={<PencilIcon w="inherit" h="inherit" />}
        onClick={onOpen}
        isDisabled={!locationsPermission}
        mr={{ base: '16px', '2xl': '24px' }}
        color="custom.gray.200"
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onCloseHandler}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          pos="relative"
          maxW={{ base: '288px', md: '720px', '2xl': '656px' }}
        >
          {isLoading && (
            <Box
              pos="absolute"
              top="0"
              left="0"
              bottom="0"
              right="0"
              zIndex={2}
            >
              <Preloader h="8px" w="calc(100% - 4px)" opacity="0.8" m="2px" />
            </Box>
          )}
          <FormikProvider value={formik}>
            <ModalHeader mb="34px" opacity={isLoading ? 0.4 : 1} d="flex">
              Edit location
              <Box
                ml="auto"
                d={{ base: 'none', md: 'grid' }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="8px"
                fontSize={{ base: '12px' }}
              >
                <Button
                  w="100%"
                  variant="blue"
                  fontSize="inherit"
                  onClick={handleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  w="100%"
                  variant="red"
                  fontSize="inherit"
                  onClick={onCloseHandler}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <Box ml="2">ID: {locationId}</Box>
            <ModalCloseButton d={{ md: 'none' }} />
            <ModalBody
              fontSize={{ base: '12px' }}
              opacity={isLoading ? 0.4 : 1}
              d={{ base: 'block' }}
            >
              <Box
                mb="16px"
                display={{ base: 'grid' }}
                gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
                gridGap={{ base: '8px', md: '16px' }}
              >
                <FormikTwoStyledInput
                  id="name"
                  name="name"
                  gridColumn={{ md: '1/3' }}
                  placeholder="Name"
                />
                <FormikTwoStyledInput
                  id="address"
                  name="address"
                  placeholder="Address"
                />
                <CityDropdown name={'cityId'} />
                <FormikTwoStyledInput
                  name="linkHref"
                  placeholder="Direction link href"
                />
                <FormikTwoStyledInput
                  name="linkText"
                  placeholder="Direction link text"
                />
                <FormikDropdown
                  main={{ options: optionsTimezones, placeholder: 'Unset' }}
                  formik={{
                    name: 'timezone',
                    id: 'timezone'
                  }}
                  label={isLargerThan1440 ? 'Timezone' : ''}
                  selectProps={dropDownSelectProps}
                />
                <FormikTwoStyledInput
                  name="goodFeedbackLink"
                  placeholder="Good Feedback Link"
                />
                <FormikTwoStyledInput
                  name="badFeedbackLink"
                  placeholder="Bad Feedback Link"
                />
                <FormikTwoStyledInput
                  name="telegramChatId"
                  placeholder="Telegram Bot Chat ID"
                  mb="8px"
                />
                <FormControl
                  height="40px"
                  mt="8px"
                  w="100%"
                  mr={{ base: '4px', md: '8px' }}
                  bg="black"
                  gridColumn={{ md: '1/3' }}
                >
                  <FormLabel
                    htmlFor="file-upload"
                    variant="lightBlue"
                    w="inherit"
                    fontWeight="500"
                    height="100%"
                    alignItems="center"
                    d="inline-flex"
                    justifyContent="center"
                    cursor="pointer"
                    _disabled={{ color: 'custom.gray.200', cursor: 'no-drop' }}
                    disabled={
                      !!(selectedImage.length >= 1 || isLoading || image)
                    }
                    m="0"
                    fontSize={{ base: '12px', '2xl': '14px' }}
                  >
                    Add Parking Image
                  </FormLabel>
                  {inputReload && (
                    <Input
                      type="file"
                      id="file-upload"
                      disabled={
                        !!(selectedImage.length >= 1 || isLoading || image)
                      }
                      accept="image/jpeg, image/png"
                      onChange={onChangeFileHandler()}
                      max="5"
                      d="none"
                    />
                  )}
                </FormControl>
                {selectedImage.map((img, idx) => (
                  <Box
                    w="fit-content"
                    maxW={{ base: '100%' }}
                    pos="relative"
                    gridColumn={{ md: '1/3' }}
                    m={{ base: '0 auto 16px', '2xl': '0 auto' }}
                    key={'picturesFinishGame' + idx}
                  >
                    <IconButton
                      aria-label="copy"
                      pos="absolute"
                      right="8px"
                      top="8px"
                      bg="gray"
                      w="24px"
                      height="24px"
                      minH="unset"
                      minW="unset"
                      borderRadius="50%"
                      _focus={{ border: 'none' }}
                      _hover={{ bg: '' }}
                      icon={
                        <CrosshairIcon color="white" w="80%" height="50%" />
                      }
                      onClick={() => deleteImageHandler(idx)}
                    />

                    <Image src={URL.createObjectURL(img)} />
                  </Box>
                ))}
                {!!image &&
                  (itsBroken ? (
                    <Box
                      textAlign="center"
                      border="1px solid"
                      gridColumn={{ md: '1/3' }}
                      borderColor="custom.gray.200"
                      pos="relative"
                      p="20px"
                    >
                      <IconButton
                        aria-label="copy"
                        pos="absolute"
                        right="8px"
                        top="8px"
                        bg="gray"
                        w="24px"
                        height="24px"
                        minH="unset"
                        minW="unset"
                        borderRadius="50%"
                        _focus={{ border: 'none' }}
                        _hover={{ bg: '' }}
                        icon={
                          <CrosshairIcon color="white" w="80%" height="50%" />
                        }
                        onClick={deleteOldPhoto}
                      />
                      <Icon as={MdBrokenImage} w="40px" height="40px" />
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        color="custom.black.500"
                      >
                        Image link broken.
                      </Text>
                    </Box>
                  ) : (
                    <Box pos="relative" gridColumn={{ md: '1/3' }}>
                      <IconButton
                        aria-label="copy"
                        pos="absolute"
                        right="8px"
                        top="8px"
                        bg="gray"
                        w="24px"
                        height="24px"
                        minH="unset"
                        minW="unset"
                        borderRadius="50%"
                        _focus={{ border: 'none' }}
                        _hover={{ bg: '' }}
                        icon={
                          <CrosshairIcon color="white" w="80%" height="50%" />
                        }
                        onClick={deleteOldPhoto}
                      />
                      <Image
                        src={image}
                        onError={istBrokenHandler}
                        border="1px solid"
                        borderColor="custom.gray.200"
                        w="100%"
                      />
                    </Box>
                  ))}
              </Box>
            </ModalBody>

            <ModalFooter
              d={{ base: 'grid', md: 'none' }}
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
              opacity={isLoading ? 0.4 : 1}
            >
              <Button w="100%" variant="blue" onClick={handleSubmit}>
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button w="100%" variant="red" onClick={onCloseHandler}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </ModalFooter>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  )
}
