import { useMutation, useQuery } from 'react-query'

import {
  createPartner,
  deletePartner,
  getPartners,
  updatePartner
} from '../../api/discounts/partners'

export function usePartners(filters = {}, queryOption = {}) {
  const { page = null, pageSize = null } = filters
  return useQuery(['partners', page, pageSize], () => getPartners(filters), {
    ...queryOption
  })
}

export function useUpdatePartner(obj, options = {}) {
  return useMutation(() => updatePartner(obj), {
    ...options
  })
}

export function useCreatePartner(obj, options = {}) {
  return useMutation(() => createPartner(obj), {
    ...options
  })
}

export function useDeletePartner(options = {}) {
  return useMutation(({ obj }) => deletePartner(obj), {
    ...options
  })
}
