import { Box, Button } from '@chakra-ui/react'
import { PlusIcon } from '../../../../assets/Icons/PlusIcon'
import PartnersList from './PartnersList'
import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../../../constants'
import PartnersTable from './PartnersTable'
import { usePartners } from '../../../../libs/hooks/usePartners'
import { useState } from 'react'
import NewPartnerListItem from './NewPartnerListItem'
import WithPagination from '../../../Common/WithPagination'

export default function PartnerTab() {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [paginationState, setPaginationState] = useState({
    page: 1,
    pageSize: 15
  })
  const { data: { results: partnersData = [], count: total = '' } = {} } =
    usePartners(paginationState)
  const [newPartner, setNewPartner] = useState(false)

  function newPartnerHandler() {
    if (!newPartner) {
      setNewPartner(true)
    }
  }

  return (
    <Box>
      <Button
        variant="blue"
        w="100%"
        mb={{ base: '16px' }}
        d={{ md: 'none' }}
        onClick={newPartnerHandler}
      >
        <PlusIcon mr="8px" />
        New Partner
      </Button>
      {isLargerThan768 ? (
        <WithPagination totalCountItems={total} cb={setPaginationState}>
          <PartnersTable tableData={partnersData} />
        </WithPagination>
      ) : (
        <>
          {newPartner && <NewPartnerListItem setNewPartner={setNewPartner} />}
          <WithPagination totalCountItems={total} cb={setPaginationState}>
            <PartnersList data={partnersData} />
          </WithPagination>
        </>
      )}
    </Box>
  )
}
