import { createIcon } from '@chakra-ui/icons'

export const SimpleSkullIcon = createIcon({
  displayName: 'SimpleSkullIcon',
  viewBox: '0 0 16 14',
  boxSize: '16',
  path: (
    <g fill="currentColor">
      <path d="M7.99985 0.0769043C3.20354 0.0769043 0.615234 3.45609 0.615234 6.63561C0.615234 8.18711 1.36034 9.53528 2.83062 10.6495V13.9231H5.046V11.7368H6.52293V13.9231H9.47677V11.7368H10.9537V13.9231H13.1691V10.6488C14.6394 9.53528 15.3845 8.18638 15.3845 6.63561C15.3845 3.45609 12.7962 0.0769043 7.99985 0.0769043ZM5.046 8.0931C4.21819 8.0931 3.56908 7.29294 3.56908 6.27124C3.56908 5.24954 4.21819 4.44937 5.046 4.44937C5.87382 4.44937 6.52293 5.24954 6.52293 6.27124C6.52293 7.29294 5.87382 8.0931 5.046 8.0931ZM10.9537 8.0931C10.1259 8.0931 9.47677 7.29294 9.47677 6.27124C9.47677 5.24954 10.1259 4.44937 10.9537 4.44937C11.7815 4.44937 12.4306 5.24954 12.4306 6.27124C12.4306 7.29294 11.7815 8.0931 10.9537 8.0931Z" />
    </g>
  )
})
