import { useMutation, useQuery } from 'react-query'

import {
  createGiftCard,
  deleteGiftCard,
  getGiftCardDesigns,
  getGiftCards,
  updateGiftCard
} from '../../api/giftcards'

export function useGiftCards(filter = {}, queryOption = {}) {
  const { search = null, page = null, pageSize = null } = filter
  return useQuery(
    ['giftcards', search, page, pageSize],
    () => getGiftCards(filter),
    {
      ...queryOption
    }
  )
}

export function useGiftCardDesigns(queryOption = {}) {
  return useQuery('giftcardDesigns', getGiftCardDesigns, {
    ...queryOption
  })
}

export function useCreateGiftCard(options = {}) {
  return useMutation(({ obj }) => createGiftCard(obj), {
    ...options
  })
}

export function useUpdateGiftCard(options = {}) {
  return useMutation(({ obj }) => updateGiftCard(obj), {
    ...options
  })
}

export function useDeleteGiftCard(options = {}) {
  return useMutation(({ obj }) => deleteGiftCard(obj), {
    ...options
  })
}
