import { useEffect, useState } from 'react'
import { Box, Button, Input, InputGroup, useTheme } from '@chakra-ui/react'

export default function CustomPlayersInput({
  disableButton,
  includeWord = false,
  minValue = null,
  maxValue = null,
  cb = null,
  initialValue = null,
  ...rest
}) {
  const theme = useTheme()
  const customColors = theme.colors.custom
  const [amount, setAmount] = useState(initialValue || minValue || 1)
  const [wordAmount, setWordAmount] = useState('1 player')
  const regexp = /^\+?([1-9]\d*)$/
  const disableDecButton = minValue !== null ? !(amount - 1 >= minValue) : false
  const disableIncButton = maxValue !== null ? !(amount + 1 <= maxValue) : false

  useEffect(() => {
    if (typeof minValue === 'number' && amount < minValue) {
      setAmount(minValue)
    }
  }, [minValue])

  useEffect(() => {
    if (typeof maxValue === 'number' && amount > maxValue) {
      setAmount(maxValue)
    }
  }, [maxValue])

  function onChangeHandler(e) {
    if (regexp.test(e.target.value)) {
      setAmount(+e.target.value)
    }
  }

  function inc() {
    if (maxValue !== null) {
      if (amount + 1 <= maxValue) {
        setAmount(amount + 1)
      }
    } else {
      setAmount(amount + 1)
    }
  }

  function dec() {
    if (minValue !== null) {
      if (amount - 1 >= minValue) {
        setAmount(amount - 1)
      }
    } else {
      setAmount(amount - 1)
    }
  }

  useEffect(() => {
    if (cb !== null && typeof cb === 'function') {
      cb(amount)
    }
    if (includeWord) {
      setWordAmount(amount + ' ' + `${amount === 1 ? 'player' : 'players'}`)
    }
  }, [amount])

  function addStatic() {
    setAmount(100)
  }

  return (
    <Box w="100%" d="flex" {...rest}>
      <InputGroup
        w="100%"
        mr={{
          base: disableButton ? '0' : '4px',
          md: disableButton ? '0' : '8px'
        }}
      >
        <Button
          onClick={dec}
          isDisabled={disableDecButton}
          bg="custom.lightGray"
          border="none"
          fontSize={{ base: '12px', '2xl': '14px' }}
          color="custom.blue.600"
          _focus={{ border: '' }}
          _hover={{
            bg: '',
            borderBottom: `1px solid ${customColors.borderBlue}`,
            borderLeft: `1px solid ${customColors.borderBlue}`,
            borderTop: `1px solid ${customColors.borderBlue}`
          }}
          _disabled={{
            opacity: 1,
            color: 'custom.gray.200',
            cursor: 'not-allowed'
          }}
          borderRadius="none"
          p="0 17px 0 17px"
          minW="unset"
          w="25px"
          borderBottom={`1px solid ${customColors.borderBlue}`}
          borderLeft={`1px solid ${customColors.borderBlue}`}
          borderTop={`1px solid ${customColors.borderBlue}`}
        >
          -
        </Button>
        <Input
          value={includeWord ? wordAmount : amount}
          onChange={(e) => onChangeHandler(e)}
          textAlign="center"
          variant="gbase"
          borderLeft="none"
          borderRight="none"
          name="amount"
          fontSize={{ base: '12px', '2xl': '14px' }}
          p="0"
          color="custom.blue.600"
          disabled={includeWord}
        />
        <Button
          onClick={inc}
          isDisabled={disableIncButton}
          variant="increment"
          bg="custom.lightGray"
          border="none"
          fontSize={{ base: '12px', '2xl': '14px' }}
          color="custom.blue.600"
          _focus={{ border: '' }}
          _hover={{
            bg: '',
            borderBottom: `1px solid ${customColors.borderBlue}`,
            borderRight: `1px solid ${customColors.borderBlue}`,
            borderTop: `1px solid ${customColors.borderBlue}`
          }}
          _disabled={{
            opacity: 1,
            color: 'custom.gray.200',
            cursor: 'not-allowed'
          }}
          borderRadius="none"
          p="0 17px 0 17px"
          minW="unset"
          w="25px"
          borderBottom={`1px solid ${customColors.borderBlue}`}
          borderRight={`1px solid ${customColors.borderBlue}`}
          borderTop={`1px solid ${customColors.borderBlue}`}
        >
          +
        </Button>
      </InputGroup>
      {!disableButton && (
        <Button
          w="100%"
          ml={{ base: '4px', md: '8px' }}
          fontSize="14px"
          fontWeight="500"
          variant="lightBlue"
          onClick={addStatic}
        >
          $100
        </Button>
      )}
    </Box>
  )
}
