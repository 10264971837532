import { Box, Button, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import {
  BOOKING_URL,
  BOOKINGS_URL,
  CREATE_PROMOCODE_URL,
  DISCOUNTS_URL,
  EDIT_PROMOCODE_URL,
  GIFT_CARDS_URL,
  LOGIN_URL,
  MAP_URL,
  PLAYERS_URL,
  PROMO_PAGE_URL,
  SETTING_EXPERIENCE_URL,
  SETTINGS_URL,
  SETTINGS_WAIVER_URL,
  STATISTICS_URL,
  WAIVER_WIDGET_URL
} from '../../constants'

export default function AppMap() {
  const history = useHistory()

  function goTo(url) {
    history.push(url)
  }

  return (
    <Box p="24px">
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="18px"
          mr="20px"
          justifySelf="center"
        >
          Name
        </Text>
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="18px"
          mr="20px"
          justifySelf="center"
        >
          URL
        </Text>
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="18px"
          mr="20px"
          justifySelf="center"
        >
          Notation
        </Text>
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="18px"
          mr="20px"
          justifySelf="center"
        >
          Redirect
        </Text>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Login
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {LOGIN_URL}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          You must be unauthorized, for see this page
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(LOGIN_URL)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Bookings
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {BOOKINGS_URL}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          -
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(BOOKINGS_URL)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Booking
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {BOOKING_URL}/:id
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Required <b>id</b>, in path selected '595'
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(`${BOOKING_URL}/595`)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          New order
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          /new-order
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          -
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo('/new-order')}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Players
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {PLAYERS_URL}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          -
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(PLAYERS_URL)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Player
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          /player/:id
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Required <b>id</b>, in path selected '1'
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo('/player/1')}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Settings
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {SETTINGS_URL}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Setting tabs will changed without changing url
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(SETTINGS_URL)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Map
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {MAP_URL}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          This page
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(MAP_URL)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Settings Experience
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {SETTING_EXPERIENCE_URL + '/:id'}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Required <b>id</b>, in path selected '1'
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(SETTING_EXPERIENCE_URL + '/1')}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Settings Location Waiver
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {SETTINGS_WAIVER_URL + '/:id'}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Required <b>id</b>, in path selected '1'
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(SETTINGS_WAIVER_URL + '/1')}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Waiver client
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {WAIVER_WIDGET_URL + '/:id'}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Required <b>id</b>, in path selected '1'. Phone code for accept
          '0000'.
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(WAIVER_WIDGET_URL + '/1')}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Statistics
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {STATISTICS_URL}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          -
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(STATISTICS_URL)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Giftcards
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {GIFT_CARDS_URL}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          -
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(GIFT_CARDS_URL)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Discount
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {DISCOUNTS_URL}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Discounts tabs will changed without changing url
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(DISCOUNTS_URL)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Create promocode
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          {CREATE_PROMOCODE_URL}
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          -
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(CREATE_PROMOCODE_URL)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Edit promocode
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >{`${EDIT_PROMOCODE_URL}/:id`}</Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Required <b>id</b>, in path selected '1'.
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(`${EDIT_PROMOCODE_URL}/1`)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Promo page
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >{`${PROMO_PAGE_URL}/:id`}</Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Required <b>id</b>, in path selected '1'.
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(`${PROMO_PAGE_URL}/1`)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        alignItems="center"
        p="16px"
        mb="2px"
        bg="white"
      >
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize="14px"
          justifySelf="center"
        >
          Promo statistics
        </Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >{`${PROMO_PAGE_URL}/:id/stat`}</Text>
        <Text
          color="custom.blue.600"
          fontWeight="400"
          fontSize="14px"
          justifySelf="center"
        >
          Required <b>id</b>, in path selected '1'.
        </Text>
        <Button
          variant="blue"
          onClick={() => goTo(`${PROMO_PAGE_URL}/1/stat`)}
          w="150px"
          justifySelf="center"
        >
          Go
        </Button>
      </Box>
    </Box>
  )
}
