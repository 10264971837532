import { Box, IconButton, Text, useTheme } from '@chakra-ui/react'
import { PlusIcon } from '../../../assets/Icons/PlusIcon'
import { CrosshairIcon } from '../../../assets/Icons/CrosshairIcon'
import { useEffect, useState } from 'react'
import { useAllLocations } from '../../../libs/hooks/useLocations'

export default function LocationApplyList({
  cb = null,
  readOnly = false,
  initialState = [],
  label = '',
  ...rest
}) {
  const theme = useTheme()
  const customColor = theme.colors.custom
  const { data: { results: locations = [] } = {}, isLoading } =
    useAllLocations()
  const [checkedExp, setCheckedExp] = useState(initialState)

  function isChecked(id) {
    return checkedExp.includes(id)
  }

  function toggleHandler(id) {
    return () => {
      if (checkedExp.includes(id)) {
        const newArr = []
        checkedExp.forEach((elId) => {
          if (elId !== id) {
            newArr.push(elId)
          }
        })
        setCheckedExp(newArr)
      } else {
        setCheckedExp([...checkedExp, id])
      }
    }
  }

  useEffect(() => {
    if (cb !== null && typeof cb === 'function') {
      cb(checkedExp)
    }
  }, [checkedExp])

  return (
    <Box {...rest}>
      {label && (
        <Text
          fontSize={{ base: '18px', '2xl': '24px' }}
          fontWeight="500"
          mb={{ base: '8px', md: '16px' }}
        >
          {label}
        </Text>
      )}
      {!isLoading ? (
        <Box d={{ md: 'flex' }} flexWrap={{ md: 'wrap' }} w="100%">
          {locations?.length !== 0 &&
            locations?.map((el, idx) => {
              return (
                <Box
                  bg={isChecked(el.id) ? 'custom.blue.600' : 'custom.lightGray'}
                  border={
                    isChecked(el.id)
                      ? 'custom.lightGray'
                      : `1px solid ${customColor.borderBlue}`
                  }
                  color={isChecked(el.id) ? 'white' : 'custom.blue.600'}
                  borderRadius="20px"
                  d="grid"
                  gridTemplateColumns="3fr 1fr"
                  p="0 0 0 14px"
                  minH="40px"
                  key={'applyList' + idx}
                  w={{ md: '180px' }}
                  mb={{ base: '8px', '2xl': '16px' }}
                  mr={{ base: 'unset', md: '8px', '2xl': '16px' }}
                  alignItems="center"
                >
                  <Text
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {el?.name}
                  </Text>
                  {!readOnly && (
                    <IconButton
                      aria-label="delete-applied"
                      variant="funcColumnIconButton"
                      w="12px"
                      h="12px"
                      _focus={false}
                      onClick={toggleHandler(el.id)}
                      icon={
                        isChecked(el.id) ? (
                          <CrosshairIcon w="inherit" h="inherit" />
                        ) : (
                          <PlusIcon w="inherit" h="inherit" />
                        )
                      }
                      justifySelf="end"
                    />
                  )}
                </Box>
              )
            })}
          {locations?.length === 0 && (
            <Text color="custom.gray.300" m="0 auto">
              Empty
            </Text>
          )}
        </Box>
      ) : (
        'Loading'
      )}
    </Box>
  )
}
