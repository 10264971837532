import React, { useMemo } from 'react'
import { Text, useTheme } from '@chakra-ui/react'
import {
  TABLE_COLUMN_VALUES_STATISTICS_QUESTROOM_AFTER_1440,
  TABLE_COLUMN_VALUES_STATISTICS_QUESTROOM_BEFORE_1440
} from '../../../../configs'
import TableCreator from '../../../Common/TableCreator'
import { TABLE_COLUMNS_SIZES_STATISTICS_QUESTROOM } from '../../../../configs/tablesSizes'
import { useSelector } from 'react-redux'
import { matchMedia1440InState } from '../../../../constants'
import TableCreatorStatistics from '../../../Common/TableCreatorStatistics'

export default function StatisticsQuestroomTable({
  tableData = [],
  total,
  skip = false,
  avg,
  ll,
  name,
  played
}) {
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const data = useMemo(() => {
    const lastElem = tableData[tableData.length - 1]
    const newElem = {}
    Object.keys(lastElem).forEach((prop) => {
      if (prop === 'll') {
        newElem[prop] = ll
        return
      }

      if (prop === 'played') {
        newElem[prop] = played
        return
      }

      if (prop === 'total') {
        newElem[prop] = total
        return
      }

      if (prop === 'avg') {
        newElem[prop] = avg
        return
      }

      newElem[prop] = ''
    })
    return skip ? [newElem] : [...tableData, newElem]
  }, [tableData, total, avg, ll, played])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: '0 16px',
      css: {
        [`&:nth-of-type(1)`]: {
          textAlign: 'start'
        }
      }
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 16px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px',
      css: {
        '&:nth-of-type(1)': {
          textAlign: 'start'
        }
      }
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px',
      css: {
        'tbody > &:last-of-type': {
          background: customsColor.blue[200],
          fontWeight: 500,
          '> td': {
            color: customsColor.blue[600]
          }
        },
        '> td:first-of-type': {
          minWidth: '150px'
        }
      }
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }
  const headerLocName = {
    Header: name,
    Accessor: 'name'
  }

  const tableColumnValuesStatLocationWithDynamicallyColumn = [
    headerLocName,
    ...(isLargerThan1440
      ? TABLE_COLUMN_VALUES_STATISTICS_QUESTROOM_AFTER_1440
      : TABLE_COLUMN_VALUES_STATISTICS_QUESTROOM_BEFORE_1440)
  ]

  const dynamicallySizeMapHeaderName = {
    ...TABLE_COLUMNS_SIZES_STATISTICS_QUESTROOM,
    [name]: {
      maxWidth: '1px'
    }
  }

  const columns = useMemo(() => {
    return tableColumnValuesStatLocationWithDynamicallyColumn.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === name.toLowerCase()) {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return <Text color="custom.blue.600">{original.name}</Text>
        }
      }

      if (field.Header.toLowerCase() === 'total, $') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return Math.round(original.total / 100)
        }
      }

      if (field.Header.toLowerCase() === 'avg, $') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return Math.round(original.avg / 100)
        }
      }

      if (typeof +field.Header === 'number' && !isNaN(field.Header)) {
        fieldObject['Cell'] = ({ row }, value) => {
          if (row.original.name !== '') {
            if (
              row.original?.players_info[+field.Header] ||
              row.original?.players_info[+field.Header] === 0
            ) {
              return <>{row.original.players_info[+field.Header]}</>
            } else {
              return <>—</>
            }
          } else return null
        }
      }

      return fieldObject
    })
  }, [isLargerThan1440])
  return (
    <TableCreatorStatistics
      data={data}
      columns={columns}
      styles={tableStyles}
      sizesMap={dynamicallySizeMapHeaderName}
    />
  )
}
