import { combineReducers } from 'redux'

import { appReducer } from './app'
import { ethernetWatcherReducer } from './ethernetWatcher'
import { newOrderReducer } from './newOrder'
import { resolutionWatcherReducer } from './resolutionWatcher'

export const rootReducer = combineReducers({
  app: appReducer,
  resWatcher: resolutionWatcherReducer,
  newOrder: newOrderReducer,
  ethernetWatcher: ethernetWatcherReducer
})
