import { useColorMode } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import Header from '@/components/Header'
import PrivateRoute from '@/components/PrivateRoute'
import AttractionsExperience from '@/components/Settings/Tabs/Attractions/AttractionsExperience'

import ComboDetailsPage from '@/features/combo/ComboDetailsPage'

import { setPermissions, setUser } from './actions'
import {
  authTokenInState,
  BOOKING_URL,
  BOOKINGS_URL,
  CALENDAR_URL,
  CASH_URL,
  COMBOS_URL,
  TICKETS_URL,
  CREATE_PROMOCODE_URL,
  CUT_OFF_URL,
  DISCOUNTS_URL,
  EDIT_PROMOCODE_URL,
  filtersInState,
  GIFT_CARDS_URL,
  LIMITATIONS_URL,
  LOGIN_URL,
  MAP_URL,
  NEW_ORDER_URL,
  PLAYER_URL,
  PLAYERS_URL,
  PROMO_PAGE_URL,
  RESET_PASSWORD_EMAIL_URL,
  RESET_PASSWORD_RESTORE_URL,
  SETTING_COMBO_URL,
  SETTING_EXPERIENCE_URL,
  SETTINGS_URL,
  SETTINGS_WAIVER_URL,
  settingsTabInState,
  STATISTICS_URL,
  userInState,
  USERS_ROLES_URL,
  WAIVER_WIDGET_URL,
  PURCHASE_RECEIPT_URL
} from './constants'
import NotificationService from './features/waiver-widget/components/common/NotificationService'
import WaiverWidget from './features/waiver-widget/pages/WaiverWidget'
import { useProfile } from './libs/hooks/useProfile'
import AppMap from './pages/AppMap'
import Booking from './pages/Booking'
import Bookings from './pages/Bookings'
import Cash from './pages/Cash'
import ComboBookingsPage from './pages/Combos'
import TicketsPage from './pages/Tickets'
import SingleComboBookingPage from './pages/Combos/SingleComboBookingPage'
import CutOffTime from './pages/CutOffTime'
import Discounts from './pages/Discounts'
import CreatePromocode from './pages/Discounts/CreatePromocode'
import EditPromocode from './pages/Discounts/EditPromocode'
import PromoPage from './pages/Discounts/PromoPage'
import StatisticsPromoPage from './pages/Discounts/StatisticsPromoPage'
import Email from './pages/Email'
import GiftCards from './pages/GiftCards'
import Limitations from './pages/Limitations'
import Login from './pages/Login'
import NewOrder from './pages/NewOrder'
import NewPassword from './pages/NewPassword'
import PlayerInfo from './pages/PlayerInfo'
import Players from './pages/Players'
import Settings from './pages/Settings'
import Waiver from './pages/Settings/LocationWaiver'
import UsersRoles from './pages/Settings/UsersRoles'
import Statistics from './pages/Statistics'
import { PurchaseReceiptPage } from './features/order/pages/PurchaseReceiptPage'

function App() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const isAuth = useSelector(authTokenInState)
  const { colorMode, toggleColorMode } = useColorMode()
  useEffect(() => {
    if (colorMode === 'dark') {
      toggleColorMode()
    }
  }, [colorMode])
  const { pathname } = useLocation()
  const user = useSelector(userInState)
  const filters = useSelector(filtersInState)
  const settingsTab = useSelector(settingsTabInState)
  const [blockRequest, setBlockRequest] = useState(false)
  const {
    data = {},
    refetch: refetchProfile,
    isLoading: isProfileLoading,
    isRefetching: isProfileRefetching,
    isSuccess: isProfileSuccess,
    remove: removeUserCache
  } = useProfile({
    enabled: !!isAuth
  })

  useEffect(() => {
    if (!isAuth) {
      queryClient.clear()
      removeUserCache()
    }
  }, [isAuth])

  useEffect(() => {
    if (blockRequest) {
      setTimeout(() => {
        if (blockRequest) {
          setBlockRequest(false)
        }
      }, 5000)
    }
  }, [blockRequest])

  useEffect(() => {
    if (
      !isProfileLoading &&
      !isProfileRefetching &&
      isProfileSuccess &&
      !blockRequest &&
      isAuth
    ) {
      refetchProfile()
      setBlockRequest(true)
    }
  }, [filters, settingsTab, pathname])

  useEffect(() => {
    if (
      !user &&
      Object.keys(data).length !== 0 &&
      isAuth &&
      pathname !== LOGIN_URL
    ) {
      dispatch(setUser(data))
      dispatch(setPermissions(data?.functions))
    }

    if (user && Object.keys(data).length !== 0 && isAuth) {
      dispatch(setPermissions(data?.functions))
    }
  }, [user, data, isAuth, pathname])

  return (
    <Switch>
      <NotificationService>
        <Route exact path={'/'}>
          <Redirect to={LOGIN_URL} />
        </Route>
        <Route exact path={LOGIN_URL}>
          <Login />
        </Route>
        <PrivateRoute exact path={BOOKINGS_URL}>
          <Header />
          <Bookings mode="table" />
        </PrivateRoute>
        <PrivateRoute exact path={COMBOS_URL}>
          <Header />
          <ComboBookingsPage />
        </PrivateRoute>
        <PrivateRoute exact path={TICKETS_URL}>
          <Header />
          <TicketsPage />
        </PrivateRoute>
        <PrivateRoute exact path={COMBOS_URL + '/:id'}>
          <Header />
          <SingleComboBookingPage />
        </PrivateRoute>
        <PrivateRoute exact path={CALENDAR_URL}>
          <Header />
          <Bookings mode="calendar" />
        </PrivateRoute>
        <PrivateRoute exact path={NEW_ORDER_URL}>
          <Header />
          <NewOrder />
        </PrivateRoute>
        <PrivateRoute exact path={PLAYERS_URL}>
          <Header />
          <Players />
        </PrivateRoute>
        <PrivateRoute exact path={SETTINGS_URL}>
          <Header />
          <Settings />
        </PrivateRoute>
        <PrivateRoute exact path={MAP_URL}>
          <AppMap />
        </PrivateRoute>
        <PrivateRoute exact path={STATISTICS_URL}>
          <Header />
          <Statistics />
        </PrivateRoute>
        <PrivateRoute exact path={GIFT_CARDS_URL}>
          <Header />
          <GiftCards />
        </PrivateRoute>
        <PrivateRoute exact path={DISCOUNTS_URL}>
          <Header />
          <Discounts />
        </PrivateRoute>
        <PrivateRoute exact path={CREATE_PROMOCODE_URL}>
          <Header />
          <CreatePromocode />
        </PrivateRoute>
        <PrivateRoute exact path={LIMITATIONS_URL}>
          <Header />
          <Limitations />
        </PrivateRoute>
        <PrivateRoute exact path={`${EDIT_PROMOCODE_URL}/:id`}>
          <Header />
          <EditPromocode />
        </PrivateRoute>
        <PrivateRoute exact path={`${PROMO_PAGE_URL}/:id`}>
          <Header />
          <PromoPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${PROMO_PAGE_URL}/:id/stat`}>
          <Header />
          <StatisticsPromoPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${PLAYER_URL}/:id`}>
          <Header />
          <PlayerInfo />
        </PrivateRoute>
        <PrivateRoute exact path={`${BOOKING_URL}/:id`}>
          <Header />
          <Booking />
        </PrivateRoute>
        <PrivateRoute exact path={USERS_ROLES_URL}>
          <Header />
          <UsersRoles />
        </PrivateRoute>
        <PrivateRoute exact path={`${SETTING_EXPERIENCE_URL}/:id`}>
          <Header />
          <AttractionsExperience />
        </PrivateRoute>
        <PrivateRoute exact path={`${SETTING_COMBO_URL}/:id`}>
          <Header />
          <ComboDetailsPage />
        </PrivateRoute>
        <PrivateRoute exact path={CASH_URL}>
          <Header />
          <Cash />
        </PrivateRoute>
        <PrivateRoute exact path={`${SETTINGS_WAIVER_URL}/:id`}>
          <Waiver />
        </PrivateRoute>
        <Route exact path={WAIVER_WIDGET_URL}>
          <WaiverWidget />
        </Route>
        <Route exact path={PURCHASE_RECEIPT_URL}>
          <PurchaseReceiptPage />
        </Route>
        {/* {!newWaiverEnabled && (
        <Route exact path={WAIVER_WIDGET_URL}>
          {analytics && (
            <Helmet>
              <script>
                {`
                            (function(h,o,t,j,a,r){
                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                            h._hjSettings={hjid:3257074,hjsv:6};
                            a=o.getElementsByTagName('head')[0];
                            r=o.createElement('script');r.async=1;
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                            a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
              </script>
            </Helmet>
          )}
          <WaiverWidget />
        </Route>
      )} */}
        <Route exact path={RESET_PASSWORD_EMAIL_URL}>
          <Email />
        </Route>
        <Route exact path={RESET_PASSWORD_RESTORE_URL}>
          <NewPassword />
        </Route>
        <Route exact path={CUT_OFF_URL}>
          <Header />
          <CutOffTime />
        </Route>
      </NotificationService>
    </Switch>
  )
}

export default App
