import { Box } from '@chakra-ui/react'

export default function UpcomingButtons({ children }) {
  return (
    <Box
      d="grid"
      gridTemplateRows={{
        base: '1fr 1fr 1fr',
        md: '1fr 1fr',
        '2xl': '1fr 1fr 1fr'
      }}
      gridTemplateColumns={{
        base: '1fr 1fr',
        md: '1fr 1fr 1fr',
        '2xl': '1fr 1fr'
      }}
      w={{ base: 'unset', md: '100%', '2xl': 'unset' }}
      fontWeight="500"
      fontSize={{ base: '12px', '2xl': '14px' }}
      mt={{ base: 'unset', md: '16px', '2xl': 'unset' }}
      gridColumnGap={{ base: '8px', md: '16px', '2xl': '16px' }}
      gridRowGap={{ base: '8px', md: '16px', '2xl': '16px' }}
    >
      {children}
    </Box>
  )
}
