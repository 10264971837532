import * as yup from 'yup'

import { cleanPhoneNumber } from '../../../libs'

export const waiverInfoValidation = yup.object().shape({
  phoneDraft: yup
    .string()
    .test('phone length', 'must be 11 charters length', (val) => {
      if (val && val.length) {
        const value = cleanPhoneNumber(val)
        return /\d{10}/.test(value)
      } else return true
    }),
  emailDraft: yup.string().email()
})
