import { useMutation, useQuery } from 'react-query'

import {
  getWaiversentitys,
  patchWaiversentitys
} from '../../api/Settings/location/waiver'

export function useWaiversentitys(bookingId, queryOption = {}) {
  return useQuery(
    ['waiversentitys', +bookingId],
    () => getWaiversentitys({ bookingId: bookingId }),
    {
      ...queryOption
    }
  )
}

export function usePatchWaiversentitys(options = {}) {
  return useMutation(({ obj }) => patchWaiversentitys(obj), {
    ...options
  })
}
