import { Box, Button, Input, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

export default function InputWithApply({
  label,
  cb = null,
  cbClick = null,
  isDisabled = false,
  ...rest
}) {
  const [input, setInput] = useState('')
  useEffect(() => {
    if (cb !== null && typeof cb === 'function') {
      cb(input)
    }
  }, [input])

  function onChange(e) {
    setInput(e.target.value)
  }

  function clickHandler() {
    if (cbClick !== null && typeof cbClick === 'function') {
      cbClick()
    }
  }

  return (
    <>
      <Text
        as="span"
        color="custom.gray.300"
        mb="10px"
        d="block"
        fontSize="inherit"
      >
        {label}
      </Text>
      <Box d="flex" mb={{ base: '10px', '2xl': '14px' }}>
        <Input
          variant="gbase"
          value={input}
          onChange={onChange}
          mr={{ base: '8px', md: '16px', '2xl': '16px' }}
          w="100%"
          fontSize="inherit"
          {...rest}
        />
        <Button
          minW={{ base: '84px', md: '99px', '2xl': '105px' }}
          fontSize="inherit"
          isDisabled={isDisabled}
          _hover={isDisabled && false}
          variant="blue"
          onClick={clickHandler}
        >
          Apply
        </Button>
      </Box>
    </>
  )
}
