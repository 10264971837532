export default function PencilIcon({ rect = true }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {rect && <rect width="32" height="32" rx="16" fill="#F8F8F8" />}
      <path
        d="M8.80078 20.2007V23.2007H11.8008L20.6488 14.3527L17.6488 11.3527L8.80078 20.2007ZM23.5288 11.4727L20.5288 8.47266L18.5048 10.5047L21.5048 13.5047L23.5288 11.4727Z"
        fill="#777777"
      />
    </svg>
  )
}
