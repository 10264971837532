import { api } from '../../index'

export async function getLocationById(locationId) {
  try {
    const { data } = await api.get(`api/locations/${locationId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}
