import { Box } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import DatePickerModal from '../../../../Booking/Modals/DatePickerModal'
import { statusValueInState } from '../../../../../constants'

export default function StatusSelectorButtons({
  options = [],
  onChange = null,
  cb = null,
  type = 'table',
  selectedDate = new Date()
}) {
  const statusValue = useSelector(statusValueInState)

  const initialChecked = useMemo(() => {
    if (options.length !== 0) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === statusValue) {
          return i
        }
      }
    } else {
      return 0
    }
  }, [options, statusValue, type])

  const [checkedIdx, setCheckedIdx] = useState(
    statusValue === 'today' ? 0 : initialChecked
  )
  useEffect(() => {
    if (onChange !== null && typeof onChange === 'function') {
      onChange(options[checkedIdx])
    }
  }, [checkedIdx, options])

  return (
    <Box d="flex" w="100%">
      {options?.map((btn, idx) => {
        return (
          <Box
            key={'statusbtns' + idx}
            as="span"
            cursor="pointer"
            w="100%"
            d="flex"
            justifyContent="center"
            alignItems="center"
            borderBottom={
              idx === checkedIdx
                ? '2px solid rgba(4, 113, 241, 1)'
                : '2px solid transparent'
            }
            height="100%"
            fontSize={{ base: '12px', '2xl': '18px' }}
            color={idx === checkedIdx ? 'custom.blue.600' : 'custom.black.500'}
            fontWeight="400"
            _focus={false}
            onClick={() => setCheckedIdx(idx)}
          >
            {btn.label}
            {idx === 0 && (
              <DatePickerModal cb={cb} selectedDate={selectedDate} />
            )}
          </Box>
        )
      })}
    </Box>
  )
}
