import { Box, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { matchMedia768InState, menuIsOpenInState } from '../../../constants'

export default function HeaderLogo() {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const tenantName = process.env.REACT_APP_TENANT_NAME || 'Questroom'
  return (
    <>
      {!isLargerThan768 && (
        <Text
          as={'h1'}
          fontSize="16px"
          fontWeight="600"
          color="custom.blue.600"
          textTransform="uppercase"
        >
          Gbase
        </Text>
      )}
      {isLargerThan768 && (
        <>
          <Box
            d="flex"
            alignItems="center"
            m={[
              '0 auto 0 18px',
              '0 auto 0 18px',
              '0 auto 0 18px',
              '0 auto 0 18px',
              '0 0 0 18px',
              '0',
              '0 0 0 18px'
            ]}
          >
            <Text
              as={'h1'}
              fontSize="24px"
              fontWeight="700"
              color="custom.blue.600"
              textTransform="uppercase"
            >
              Gbase
            </Text>
            <Text
              fontSize="18px"
              m="0 4px"
              color="custom.gray.300"
              fontWeight="500"
            >
              +
            </Text>
            <Text
              as={'h1'}
              fontSize="18px"
              fontWeight="600"
              color="custom.gray.300"
            >
              {tenantName}
            </Text>
          </Box>
        </>
      )}
    </>
  )
}
