import { Box, IconButton, Text } from '@chakra-ui/react'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'

import { usePaymentGates } from '@/libs/hooks/useStripe'

import { CopyIconWithoutBg } from '@/assets/Icons/CopyIconWithoutBg'

import DeletePaymentGatesModal from '../Modals/DeletePaymentGateModal'
import EditPaymentGatesModal from '../Modals/EditPaymentGatesModal'

export default function PaymentGateItem({ id }) {
  const { data: paymentGates } = usePaymentGates()

  const gate = paymentGates.find((g) => g.id === id)
  if (!gate) return <Box>Failed to load Payment Gate with id {id}</Box>

  return (
    <Box d="flex" bg="white" p={{ base: '16px' }} mb="2px">
      <Text
        color="custom.gray.900"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {name}
      </Text>
      <Box d="flex" alignItems="center" ml="auto">
        {gate.isDefault && <Text color="custom.gray.200">Default</Text>}
        <CopyToClipboard text={gate.public}>
          <IconButton
            aria-label="copy"
            w="16px"
            h="16px"
            bg="none"
            _focus={{ border: 'none' }}
            _hover={{ bg: 'transparent' }}
            icon={
              <CopyIconWithoutBg
                color="custom.gray.200"
                w="16px"
                height="16px"
              />
            }
          />
        </CopyToClipboard>
        <EditPaymentGatesModal
          paymentGateId={gate.id}
          paymentGateName={gate.name}
          privateKey={gate.private}
          additionalPrivateKey={gate.additional}
          publicKey={gate.public}
          isDefault={gate.is_default}
          feePercentInt={+gate.fee}
          salesTaxPercent={+gate.sales_tax_fee}
          paymentProvider={gate.payment_provider}
        />
        <DeletePaymentGatesModal name={name} stripeId={gate.id} />
      </Box>
    </Box>
  )
}
