import { useMutation, useQuery } from 'react-query'

import {
  createWaiversblock,
  deleteWaiverblock,
  getWaiversblock,
  getWaiversblocks,
  patchWaiverblock
} from '../../api/Settings/location/waiver'

export function useWaiversblocks(queryOption = {}) {
  return useQuery(['waiversblocks'], () => getWaiversblocks(), {
    ...queryOption
  })
}

export function useWaiversblock(waiverblockId, queryOption = {}) {
  return useQuery(
    ['waiversblock', +waiverblockId],
    () => getWaiversblock(waiverblockId),
    {
      ...queryOption
    }
  )
}

export function useCreateWaiversblock(options = {}) {
  return useMutation(({ obj }) => createWaiversblock(obj), {
    ...options
  })
}

export function usePatchWaiversblock(options = {}) {
  return useMutation(({ obj }) => patchWaiverblock(obj), {
    ...options
  })
}

export function useDeleteWaiversblock(waiverBlockId, options = {}) {
  return useMutation(() => deleteWaiverblock(waiverBlockId), {
    ...options
  })
}
