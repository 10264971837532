import { api } from '../../index'
import {
  correctionTimezone,
  selectedDayMidnightTimeEnd,
  selectedDayMidnightTimeStart
} from '../../../libs'

export async function getEvents() {
  try {
    const { data } = await api.get('/api/events/')
    return data?.results
  } catch (e) {
    console.log(e)
  }
}

export async function updateEvents(obj) {
  const { eventId } = obj
  try {
    const { data } = await api.patch(`/api/events/${eventId}/`, obj)
    return data?.results
  } catch (e) {
    console.log(e)
  }
}

export async function createEvent(obj) {
  try {
    const { data } = await api.post(`/api/events/`, obj)
    return data?.results
  } catch (e) {
    console.log(e)
  }
}

export async function deleteEvent(obj) {
  const { eventId, eventvariantId } = obj
  try {
    const { data } = await api.delete(
      `/api/events/${eventId}/?eventvariant=${eventvariantId}`
    )
    return data?.results
  } catch (e) {
    console.log(e)
  }
}

export async function getAttractionsExperience(eventId) {
  try {
    const { data } = await api.get(`/api/eventvariants/?event=${eventId}`)
    return data?.results
  } catch (e) {
    console.log(e)
  }
}

export async function getBasePrices(eventVariantId) {
  try {
    const { data } = await api.get(
      `/api/eventvariants/${eventVariantId}/base-prices/`
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function createBasePrice({
  eventVariantId,
  pricingType,
  minPlayers,
  maxPlayers,
  price
}) {
  const { data } = await api.post(
    `/api/eventvariants/${eventVariantId}/base-prices/`,
    {
      pricing_type: pricingType,
      min_players: +minPlayers,
      max_players: +maxPlayers,
      price: +price
    }
  )
  return data
}

export async function patchBasePrice({
  eventVariantId,
  priceId,
  pricingType,
  minPlayers,
  maxPlayers,
  price
}) {
  const { data } = await api.patch(
    `/api/eventvariants/${eventVariantId}/base-prices/${priceId}/`,
    {
      pricing_type: pricingType,
      min_players: +minPlayers,
      max_players: +maxPlayers,
      price: +price,
      id: +priceId
    }
  )
  return data
}

export async function deleteBasePrice({ eventVariantId, priceId }) {
  const { data } = await api.delete(
    `/api/eventvariants/${eventVariantId}/base-prices/${priceId}/`
  )
  return data
}
export async function getPrivateGamePrices(eventVariantId) {
  try {
    const { data } = await api.get(
      `/api/eventvariants/${eventVariantId}/private-game/`
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function createPrivateGamePrice({
  eventVariantId,
  pricingType,
  minPlayers,
  maxPlayers,
  price
}) {
  const { data } = await api.post(
    `/api/eventvariants/${eventVariantId}/private-game/`,
    {
      pricing_type: pricingType,
      min_players: +minPlayers,
      max_players: +maxPlayers,
      price: +price
    }
  )
  return data
}

export async function patchPrivateGamePrice({
  eventVariantId,
  priceId,
  pricingType,
  minPlayers,
  maxPlayers,
  price
}) {
  const { data } = await api.patch(
    `/api/eventvariants/${eventVariantId}/private-game/${priceId}/`,
    {
      pricing_type: pricingType,
      min_players: +minPlayers,
      max_players: +maxPlayers,
      price: +price,
      id: +priceId
    }
  )
  return data
}

export async function deletePrivateGamePrice({ eventVariantId, priceId }) {
  const { data } = await api.delete(
    `/api/eventvariants/${eventVariantId}/private-game/${priceId}/`
  )
  return data
}

export async function patchPriceInterval({
  eventVariantId,
  priceIntervalId,
  pricingType,
  onRequest,
  hide,
  cutOfTimeInput,
  cutOfTimeCheckbox
}) {
  const { data } = await api.patch(
    `/api/eventvariants/${eventVariantId}/price-intervals/${priceIntervalId}/`,
    {
      pricing_type: pricingType,
      on_request: onRequest,
      hide: hide,
      cut_off_time: cutOfTimeCheckbox,
      cut_off_time_value: cutOfTimeInput
    }
  )
  return data
}

export async function patchAttractionExperienceCutOff({
  experienceId,
  cutOffTime
}) {
  const { data } = await api.patch(`/api/eventvariants/${experienceId}/`, {
    cut_off_time: cutOffTime
  })
  return data
}

export async function updateAttractionsExperience({
  humanUsage,
  eventVariantId,
  hideLocked,
  areas,
  defaultType,
  deleted,
  eventName,
  flowsMinPlayers,
  flowsMaxPlayers,
  flowsNumber,
  minPlayers,
  maxPlayers,
  name,
  playersStep,
  ticketEnabled,
  emulation,
  flexStart,
  termsAndConditions,
  ticketMinPlayers,
  lockWith,
  photoFrame,
  image,
  disableStatistic,
  combinedStatisticWith,
  changePriceOnRemovePlayers
}) {
  const { data } = await api.patch(`/api/eventvariants/${eventVariantId}/`, {
    hide_locked: hideLocked,
    areas: +areas,
    default_type: +defaultType,
    deleted: deleted,
    event_name: eventName,
    flows_min_players: +flowsMinPlayers,
    flows_max_players: +flowsMaxPlayers,
    flows_number: +flowsNumber,
    id: +eventVariantId,
    max_players: +maxPlayers,
    min_players: +minPlayers,
    name: name,
    players_step: +playersStep,
    tickets_enabled: ticketEnabled,
    emulation: emulation,
    flex_start: flexStart,
    terms_and_conditions: termsAndConditions,
    human_usage: humanUsage,
    ticket_min_players: +ticketMinPlayers,
    lock_with: lockWith,
    disable_statistic: disableStatistic,
    combined_statistic_with: combinedStatisticWith,
    change_price_on_remove_players: changePriceOnRemovePlayers,
    ...((typeof photoFrame === 'number' || photoFrame === '') && {
      photo_frame: photoFrame
    }),
    ...((typeof image === 'number' || image === '') && { image })
  })
  return data
}

export async function deleteAttractionsExperience({ id, eventvariantId }) {
  try {
    const { data } = await api.delete(
      `/api/eventvariants/${id}/?eventvariant=${eventvariantId}`
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function createAttractionsExperience({
  event,
  name,
  humanUsage,
  areas,
  minPlayers,
  maxPlayers,
  flowMinPlayers,
  flowMaxPlayers,
  playersStep,
  lockWith,
  ticketsEnabled,
  ticketsMinPlayers,
  termsAndConditions,
  photoFrame,
  image,
  disableStatistic,
  combinedStatisticWith,
  changePriceOnRemovePlayers
}) {
  const { data } = await api.post(`/api/eventvariants/`, {
    event: event,
    name: name,
    human_usage: humanUsage,
    areas: areas,
    min_players: minPlayers,
    max_players: maxPlayers,
    flows_min_players: flowMinPlayers,
    flows_max_players: flowMaxPlayers,
    players_step: playersStep,
    lock_with: lockWith,
    tickets_enabled: ticketsEnabled,
    tickets_min_players: ticketsMinPlayers,
    terms_and_conditions: termsAndConditions,
    disable_statistic: disableStatistic,
    combined_statistic_with: combinedStatisticWith,
    change_price_on_remove_players: changePriceOnRemovePlayers,
    ...(typeof photoFrame === 'number' && { photo_frame: photoFrame }),
    ...(typeof image === 'number' && { image })
  })
  return data
}

export async function getHolidays() {
  try {
    const { data } = await api.get('/api/holidays/')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getHoliday(holidayId) {
  try {
    const { data } = await api.get(`/api/holidays/${holidayId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function editHoliday({
  holidayId,
  pricingType,
  effectiveDateFrom,
  effectiveDateTo,
  unlockEverything,
  removeAllLimitations,
  removeAllDiscounts,
  overridePriceLevel,
  addMarkup,
  markup,
  markupType,
  applyTo,
  removePromocode,
  unlockHide
}) {
  const correctedEffectiveDateFrom = correctionTimezone(
    Math.round(effectiveDateFrom / 1000),
    true
  )
  const correctedEffectiveDateTo = correctionTimezone(
    Math.round(effectiveDateTo / 1000),
    true
  )
  const { data } = await api.patch(`/api/holidays/${holidayId}/`, {
    pricing_type: pricingType,
    effective_date_from: correctedEffectiveDateFrom,
    effective_date_to: correctedEffectiveDateTo,
    unlock_everything: unlockEverything,
    remove_all_discounts: removeAllDiscounts,
    remove_all_limitations: removeAllLimitations,
    override_price_level: overridePriceLevel,
    add_markup: addMarkup,
    markup: markup,
    markup_type: markupType,
    apply_to: applyTo,
    remove_promocodes: removePromocode,
    unlock_hide: unlockHide
  })
  return data
}

export async function deleteHoliday(holidayId) {
  const data = await api.delete(`/api/holidays/${holidayId}`)
  return data
}

export async function newHoliday({
  pricingType,
  effectiveDateFrom,
  effectiveDateTo,
  unlockEverything,
  removeAllLimitations,
  overridePriceLevel,
  addMarkup,
  markup,
  markupType,
  applyTo,
  removeAllDiscounts,
  removePromocode,
  unlockHide
}) {
  const correctedEffectiveDateFrom =
    selectedDayMidnightTimeStart(effectiveDateFrom)
  const correctedEffectiveDateTo = selectedDayMidnightTimeEnd(effectiveDateTo)
  const data = await api.post(`/api/holidays/`, {
    pricing_type: pricingType,
    effective_date_from: correctedEffectiveDateFrom,
    effective_date_to: correctedEffectiveDateTo,
    unlock_everything: unlockEverything,
    remove_all_discounts: removeAllDiscounts,
    remove_all_limitations: removeAllLimitations,
    override_price_level: overridePriceLevel,
    add_markup: addMarkup,
    markup: markup,
    markup_type: markupType,
    apply_to: applyTo,
    remove_promocodes: removePromocode,
    unlock_hide: unlockHide
  })
  return data
}

export async function newSchedule({
  eventVariantId,
  weekday,
  shiftsStarts,
  shiftsEnds,
  gameTime,
  breakTime
}) {
  const correctedStart = correctionTimezone(shiftsStarts)
  const correctedEnd = correctionTimezone(shiftsEnds)
  const data = await api.post(
    `/api/eventvariants/${eventVariantId}/price-intervals/new_shedule/`,
    {
      weekday: +weekday,
      shift_starts: +correctedStart,
      shift_ends: +correctedEnd,
      game_time: +gameTime,
      break_time: +breakTime
    }
  )
  return data
}

export async function deletePriceInterval({ eventVariantId, priceIntervalId }) {
  try {
    const data = await api.delete(
      `/api/eventvariants/${eventVariantId}/price-intervals/${priceIntervalId}/`
    )
    return data
  } catch (e) {
    console.log(e)
  }
}
