import { useMutation } from 'react-query'

import { copyWaiverToBooking } from '../../api/Settings/location/waiver'

export function useCopyWaiverToBooking(options = {}) {
  return useMutation(
    ({ bookingId, waiverId }) => copyWaiverToBooking({ bookingId, waiverId }),
    {
      ...options
    }
  )
}
