import { useMutation, useQuery } from 'react-query'

import {
  createBasePrice,
  createPrivateGamePrice,
  deleteBasePrice,
  deletePrivateGamePrice,
  getBasePrices,
  getPrivateGamePrices,
  patchBasePrice,
  patchPrivateGamePrice
} from '../../api/Settings/attractions'

export function useBasePrices(eventvariantId, queryOption = {}) {
  return useQuery(
    ['eventvariantId', +eventvariantId],
    () => getBasePrices(eventvariantId),
    {
      ...queryOption
    }
  )
}

export function useCreateBasePrice(options = {}) {
  return useMutation(({ obj }) => createBasePrice(obj), {
    ...options
  })
}

export function useUpdateBasePrice(options = {}) {
  return useMutation(({ obj }) => patchBasePrice(obj), {
    ...options
  })
}

export function useDeleteBasePrice(options = {}) {
  return useMutation(({ obj }) => deleteBasePrice(obj), {
    ...options
  })
}
export function usePrivateGamePrices(eventvariantId, queryOption = {}) {
  return useQuery(
    ['eventvariantId', 'private', +eventvariantId],
    () => getPrivateGamePrices(eventvariantId),
    {
      ...queryOption
    }
  )
}

export function useCreatePrivateGamePrice(options = {}) {
  return useMutation(({ obj }) => createPrivateGamePrice(obj), {
    ...options
  })
}

export function useUpdatePrivateGamePrice(options = {}) {
  return useMutation(({ obj }) => patchPrivateGamePrice(obj), {
    ...options
  })
}

export function useDeletePrivateGamePrice(options = {}) {
  return useMutation(({ obj }) => deletePrivateGamePrice(obj), {
    ...options
  })
}
