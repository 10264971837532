import DiscountsListItem from '../DiscountsListItem'
import { Box } from '@chakra-ui/react'
import { useCreateDiscount } from '../../../../../libs/hooks/useDiscounts'
import { useMemo } from 'react'
import cloneDeep from 'lodash.clonedeep'

export default function DiscountsList({ discounts }) {
  const { mutate } = useCreateDiscount()
  const orderingDiscounts = useMemo(() => {
    if (discounts.length !== 0) {
      const cloned = cloneDeep(discounts)
      cloned.sort(function (a, b) {
        return a.id - b.id
      })
      return cloned
    } else {
      return []
    }
  }, [discounts])

  return (
    <Box mb={{ base: '24px' }}>
      {orderingDiscounts.length !== 0 &&
        orderingDiscounts?.map((disc, idx) => {
          const amountText =
            disc.amount_type === '%'
              ? disc.amount + disc.amount_type
              : disc.amount_type + disc.amount / 100
          return (
            <DiscountsListItem
              effective={disc.effective}
              includeHolidays={disc.include_holidays}
              isAdjourned={disc.is_adjourned}
              discountId={disc.id}
              discountType={disc.discount_type}
              amountType={disc.amount_type}
              amount={disc.amount}
              daysToActivate={disc.days_to_activate}
              amountText={amountText}
              key={'DiscountsList' + idx}
            />
          )
        })}
    </Box>
  )
}
