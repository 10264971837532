import { Box, Switch, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useUpdateDiscount } from '../../../../../libs/hooks/useDiscounts'
import { useQueryClient } from 'react-query'

export default function DiscountAssignedItem({
  eventvariantId,
  name,
  assignedDiscounts,
  arraysWithEventvariantsByType,
  isLoading,
  setLocalIsLoading = null
}) {
  const queryClient = useQueryClient()
  const [state, setState] = useState({
    ...assignedDiscounts,
    lastChangedType: ''
  })
  const { mutate: updateDiscount } = useUpdateDiscount()

  function onChangeSwitcher(prop) {
    return (e) => {
      setState({
        ...state,
        [prop]: e.target.checked,
        lastChangedType: prop
      })
    }
  }

  useEffect(() => {
    if (state.lastChangedType !== '') {
      if (
        setLocalIsLoading !== null &&
        typeof setLocalIsLoading === 'function'
      ) {
        setLocalIsLoading(true)
      }
      const oldArr =
        arraysWithEventvariantsByType[state.lastChangedType]?.eventvariants
      const discountId =
        arraysWithEventvariantsByType[state.lastChangedType]?.discountId
      const effective =
        arraysWithEventvariantsByType[state.lastChangedType]?.effective
      const daysToActivate =
        arraysWithEventvariantsByType[state.lastChangedType]?.daysToActivate
      const isAdjourned =
        arraysWithEventvariantsByType[state.lastChangedType]?.isAdjourned
      let newArr = []
      if (oldArr.includes(eventvariantId)) {
        oldArr.forEach((el) => {
          if (el !== eventvariantId) {
            newArr.push(el)
          }
        })
      } else {
        newArr = [...oldArr, eventvariantId]
      }
      const toSend = {
        discountId: discountId,
        eventvariants: newArr,
        effective: effective,
        isAdjourned: isAdjourned,
        ...(isAdjourned && { daysToActivate: daysToActivate })
      }

      updateDiscount(
        {
          obj: toSend
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['discountsAssigned'])
            queryClient.invalidateQueries(['discounts'])
          }
        }
      )
    }
  }, [state])

  return (
    <Box
      p={{ base: '16px' }}
      bg="white"
      mb="2px"
      d={{ md: 'grid' }}
      gridTemplateColumns={{ md: 'repeat(4, 1fr)' }}
      fontSize={{ base: '12px', '2xl': '14px' }}
    >
      <Link to="#">
        <Text
          color="custom.blue.600"
          fontWeight="500"
          mb={{ base: '16px', md: '0' }}
        >
          {name}
        </Text>
      </Link>
      <Box
        d="flex"
        fontSize={{ base: '12px' }}
        mb={{ base: '16px', md: '0' }}
        alignItems="center"
      >
        <Text color="custom.gray.900" d={{ md: 'none' }}>
          Same day booking
        </Text>
        <Switch
          m={{ base: '0 0 0 auto', md: '0 auto' }}
          isChecked={state.S}
          onChange={onChangeSwitcher('S')}
          isDisabled={isLoading}
        />
      </Box>
      <Box
        d="flex"
        fontSize={{ base: '12px' }}
        mb={{ base: '16px', md: '0' }}
        alignItems="center"
      >
        <Text color="custom.gray.900" d={{ md: 'none' }}>
          Multiple bookings
        </Text>
        <Switch
          m={{ base: '0 0 0 auto', md: '0 auto' }}
          isChecked={state.M}
          onChange={onChangeSwitcher('M')}
          isDisabled={isLoading}
        />
      </Box>
      <Box
        d="flex"
        fontSize={{ base: '12px' }}
        mb={{ base: '16px', md: '0' }}
        alignItems="center"
      >
        <Text color="custom.gray.900" d={{ md: 'none' }}>
          Advanced booking
        </Text>
        <Switch
          m={{ base: '0 0 0 auto', md: '0 auto' }}
          isChecked={state.A}
          onChange={onChangeSwitcher('A')}
          isDisabled={isLoading}
        />
      </Box>
    </Box>
  )
}
