import { Box, Button, ButtonGroup, Text } from '@chakra-ui/react'
import { useCities } from '../../../../libs/hooks/useCities'
// import AddLocationModal from './Modals/AddLocationModal';
import { useLocations } from '../../../../libs/hooks/useLocations'
import CitiesDataView from './CitiesDataView'

export default function Locations() {
  const cities = useCities()

  const data = cities
  return (
    <Box>
      <Box
        p={{ base: '16px', '2xl': '24px' }}
        mb="2px"
        bg="white"
        d="flex"
        alignItems="center"
      >
        <Text
          color="custom.black.500"
          fontSize={{ base: '18px', '2xl': '24px' }}
          fontWeight="500"
        >
          Cities
        </Text>
        {/* <AddLocationModal /> */}
      </Box>

      <CitiesDataView tableData={data} />
    </Box>
  )
}
