import { Box, Text, useTheme } from '@chakra-ui/react'
import DropdownSelect from '../../../../../Common/DropdownSelect'
import { useState } from 'react'
import { useField } from 'formik'

export default function AccordionDropdown({
  name = '',
  options,
  onChange,
  value,
  isDisabled,
  ...rest
}) {
  const theme = useTheme()
  const customColors = theme.colors.custom
  const [dropdownStates, setDropdownStates] = useState(false)
  const [
    field,
    { touched, error, value: formikValue },
    { setValue, setTouched }
  ] = useField(name)
  function onChangeDropdownState() {
    return (state) => {
      setDropdownStates(state)
    }
  }

  const dropdownTheme = {
    indicatorsContainer: {
      display: 'none'
    },
    control: {
      background:
        error && touched
          ? `${customColors.red[20]}`
          : `${customColors.lightGray}`,
      borderColor: dropdownStates
        ? `${customColors.blue[600]}`
        : `${customColors.lightGray}`
    }
  }

  return (
    <Box
      d="grid"
      gridTemplateColumns="1fr 1fr"
      gridColumnGap="8px"
      fontSize={{ base: '12px', '2xl': '14px' }}
      alignItems="center"
      {...rest}
    >
      <Text color="custom.black.500">{name}</Text>
      <DropdownSelect
        selectProps={{
          main: {
            isDisabled: isDisabled,
            options: options,
            value: value,
            onChange: onChange
          },
          styles: {
            ...dropdownTheme
          }
        }}
        cbState={onChangeDropdownState()}
      />
    </Box>
  )
}
