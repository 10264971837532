import { api } from '../../index'

export async function getPromocodes({
  search = null,
  page = null,
  pageSize = null
}) {
  try {
    const { data } = await api.get('/api/promo/?ordering=id', {
      params: {
        ordering: '-id',
        search,
        page,
        page_size: pageSize
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getPromocode(id) {
  try {
    const { data } = await api.get(`/api/promo/${id}`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function createPromo({
  available,
  code,
  discountCent,
  minPlayers,
  maxPlayers,
  discountPercent,
  bookingIntervalStart,
  bookingIntervalEnd,
  orderIntervalStart,
  orderIntervalEnd,
  bookingWeekdays,
  orderWeekdays,
  eventvariants,
  partner,
  reason
}) {
  const prepare = {
    promo_type: 'promo_code',
    available: +available,
    code: code,
    discount_cent: +discountCent,
    discount_percent: +discountPercent,
    booking_interval_start: bookingIntervalStart
      ? new Date(bookingIntervalStart * 1000).toISOString()
      : null,
    booking_interval_end: bookingIntervalEnd
      ? new Date(bookingIntervalEnd * 1000).toISOString()
      : null,
    order_interval_start: orderIntervalStart
      ? new Date(orderIntervalStart * 1000).toISOString()
      : null,
    order_interval_end: orderIntervalEnd
      ? new Date(orderIntervalEnd * 1000).toISOString()
      : null,
    booking_weekdays: bookingWeekdays,
    order_weekdays: orderWeekdays,
    eventvariants: eventvariants,
    partner: partner,
    reason: reason,
    players_number: {
      lower: minPlayers,
      upper: maxPlayers,
      bounds: '[)'
    }
  }

  const { data } = await api.post('/api/promo/', prepare)
  return data
}

export async function updatePromo({
  promoId,
  available,
  code,
  discountCent,
  minPlayers,
  maxPlayers,
  discountPercent,
  bookingIntervalStart,
  bookingIntervalEnd,
  orderIntervalStart,
  orderIntervalEnd,
  bookingWeekdays,
  orderWeekdays,
  eventvariants,
  partner,
  reason
}) {
  try {
    const prepare = {
      promo_type: 'promo_code',
      available: +available,
      code: code,
      discount_cent: +discountCent,
      discount_percent: +discountPercent,
      booking_interval_start: bookingIntervalStart,
      booking_interval_end: bookingIntervalEnd,
      order_interval_start: orderIntervalStart,
      order_interval_end: orderIntervalEnd,
      booking_weekdays: bookingWeekdays,
      order_weekdays: orderWeekdays,
      eventvariants: eventvariants,
      partner: partner,
      reason: reason,
      players_number: {
        lower: minPlayers,
        upper: maxPlayers,
        bounds: '[)'
      }
    }
    const toSend = {}
    Object.keys(prepare).forEach((key) => {
      if (typeof prepare[key] === 'object') {
        toSend[key] = prepare[key]
      }
      if (
        (!!prepare[key] && typeof prepare[key] !== 'object') ||
        key === 'discount_cent' ||
        key === 'discount_percent'
      ) {
        toSend[key] = prepare[key]
      }
    })
    const { data } = await api.patch(`/api/promo/${promoId}/`, toSend)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function deletePromocode(id) {
  try {
    const { data } = await api.delete(`/api/promo/${id}`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getPromocodeStat(promoId) {
  try {
    const { data } = await api.get(`/api/promo/${promoId}/statistics`)
    return data
  } catch (e) {
    console.log(e)
  }
}
