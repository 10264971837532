import { Box, Text, useToast } from '@chakra-ui/react'
import {
  useApplyTemplate,
  useApplyTemplateChanges,
  useNotificationTemplatesEvents
} from '../../../../../libs/hooks/useNotificationTemplates'
import React, { useState } from 'react'
import Preloader from '../../../../Common/Preloader'
import NotificationTemplatesEventsElement from './ NotificationTemplatesEventsElement'
import { useQueryClient } from 'react-query'
import { generateWarningToast } from '../../../../../libs'

export default function NotificationBlock({
  queryParam = null,
  notificationOption,
  templatesOptions,
  isExpanded
}) {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { data: notificationTemplatesEvents = [], isLoadingTemplateEvents } =
    useNotificationTemplatesEvents(queryParam, {
      enabled: isExpanded,
      refetchOnWindowFocus: false
    })
  const { mutate: applyTemplateChanges, isLoadingApplyChanges } =
    useApplyTemplateChanges()
  const { mutate: applyTemplate, isLoadingApply } = useApplyTemplate()
  const isLoading =
    isLoadingTemplateEvents || isLoadingApply || isLoadingApplyChanges
  const [editMode, setEditMode] = useState({
    idx: null,
    enabled: false
  })

  function applyChangesHandler(templateId, eventvariantId, notifications) {
    const toSend = {
      templateId,
      eventvariantId,
      eventType: queryParam,
      notifications
    }
    applyTemplateChanges(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(['notificationTemplatesEvents', queryParam])
            .then(() => {
              setEditMode({
                idx: null,
                enabled: false
              })
            })
        },
        onError: (err) => {
          const msg = err?.response?.data
          generateWarningToast({ msgObjects: msg, toast, id: 'notif-error' })
        }
      }
    )
  }

  function applyTemplateHandler(templateId, eventvariantId, notifications) {
    const toSend = {
      templateId,
      eventvariantId,
      eventType: queryParam,
      notifications
    }
    applyTemplate(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(['notificationTemplatesEvents', queryParam])
            .then(() => {
              setEditMode({
                idx: null,
                enabled: false
              })
            })
        },
        onError: (err) => {
          const msg = err?.response?.data
          generateWarningToast({ msgObjects: msg, toast, id: 'notif-error-2' })
        }
      }
    )
  }

  return isLoading ? (
    <Box p="16px" bg="white">
      <Preloader />
    </Box>
  ) : (
    <>
      <Box
        d={{ base: 'none', md: 'grid' }}
        gridTemplateColumns={{ base: 'unset', md: '2fr 0.3fr 1fr 1fr' }}
        bg="white"
        mb="2px"
        p={{ base: '16px', '2xl': '16px 24px' }}
        color="custom.black.500"
        fontSize={{ base: '12px', '2xl': '14px' }}
      >
        <Text alignSelf="center">Experience</Text>
        <Text justifySelf="center" alignSelf="center" gridColumn="3/4">
          Template
        </Text>
        <Text justifySelf="center" alignSelf="center" gridColumn="4/5">
          Notification
        </Text>
      </Box>
      {notificationTemplatesEvents.length !== 0 &&
        notificationTemplatesEvents
          .sort(function (a, b) {
            return a.eventvariant.id - b.eventvariant.id
          })
          .map((exp, idx) => (
            <NotificationTemplatesEventsElement
              notificationOption={notificationOption}
              templatesOptions={templatesOptions}
              exp={exp}
              isLoading={isLoading}
              applyChanges={applyChangesHandler}
              applyTemplate={applyTemplateHandler}
              idx={idx}
              editMode={editMode}
              setEditMode={setEditMode}
              key={queryParam + idx + 'notificationTemplatesEvents'}
            />
          ))}
    </>
  )
}
