import { Box, IconButton, Text } from '@chakra-ui/react'
import { SkullIcon } from '../../../assets/Icons/SkullIcon'
import BookingsModal from '../../Booking/Modals/BookingsModal'
import { linksCreator } from '../../../libs'

export default function DataRow({
  title,
  value,
  valueStyle,
  keyStyle,
  offDisableButton = false,
  ...rest
}) {
  return (
    <Box
      d="flex"
      justifyContent="space-between"
      w="100%"
      flexWrap="wrap"
      alignItems="center"
      mb={{ base: '4px', '2xl': '8px' }}
      {...rest}
    >
      <Text
        fontFamily="inherit"
        fontWeight="400"
        color="custom.gray.300"
        fontSize={{ base: '12px', '2xl': '14px' }}
        lineHeight="24px"
        as={'span'}
        textTransform="capitalize"
        {...keyStyle}
      >
        {title.toLowerCase() === 'bookings' ? (
          <BookingsModal title={title} value={value} />
        ) : (
          title + ':'
        )}
      </Text>
      {title.toLowerCase() === 'bookings' && (
        <Box
          d={{ base: 'flex', md: '-webkit-box' }}
          maxW={{ base: 'unset', md: '90%' }}
          flexWrap={{ base: 'wrap', md: 'wrap' }}
          overflow={{ base: 'unset', md: 'hidden' }}
          textOverflow={{ md: 'ellipsis' }}
          sx={{
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {linksCreator(value)}
        </Box>
      )}
      {title.toLowerCase() !== 'bookings' &&
        title.toLowerCase() !== 'client' && (
          <Text
            fontFamily="inherit"
            fontWeight="400"
            color="custom.gray.900"
            fontSize={{ base: '12px', '2xl': '14px' }}
            sx={{ lineBreak: 'anywhere' }}
            as={'span'}
            lineHeight="24px"
            {...valueStyle}
          >
            {value}
          </Text>
        )}
      {title.toLowerCase() === 'client' && (
        <Text
          fontFamily="inherit"
          fontWeight="400"
          color="custom.gray.900"
          fontSize={{ base: '12px', '2xl': '14px' }}
          sx={{ lineBreak: 'anywhere' }}
          as="span"
          lineHeight="24px"
          alignItems="center"
          textTransform="capitalize"
          {...valueStyle}
        >
          <IconButton
            aria-label="blacklist"
            minH="unset"
            minW="unset"
            w="18px"
            height="24px"
            _focus={{ border: 'none' }}
            _hover={{ bg: 'transparent' }}
            bg="white"
            mr="8px"
            icon={<SkullIcon w="100%" height="100%" />}
          />
          {value}
        </Text>
      )}
    </Box>
  )
}
