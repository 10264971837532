import { Box, IconButton } from '@chakra-ui/react'
import { PlusIcon } from '../../../../../../../../assets/Icons/PlusIcon'

export default function PrivateGamePricingElementHeader({
  basePricePermission,
  onClickPlusButton,
  isExpanded,
  timeLetter
}) {
  return (
    <Box d="flex" alignItems="center">
      All day
      {basePricePermission && (
        <IconButton
          aria-label="add-experience"
          w="18px"
          as="span"
          h="18px"
          icon={<PlusIcon w="12px" h="12px" />}
          color="custom.blue.600"
          mr="16px"
          ml="auto"
          variant="funcColumnIconButtons"
          onClick={onClickPlusButton(isExpanded)}
        />
      )}
    </Box>
  )
}
