import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useOutsideClick
} from '@chakra-ui/react'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { PlusIcon } from '../../../assets/Icons/PlusIcon'
import { SearchIcon } from '@chakra-ui/icons'
import WaiversList from './WaiversList'
import WaiversTable from './WaiversTable'
import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../../constants'
import { useAutocompleteWaivers } from '../../../libs/hooks/useAutocompleteWaivers'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import debounce from 'lodash.debounce'
import { debounceSearchWaiversTime } from '../../../configs'
import { useTheme } from '@emotion/react'
import Preloader from '../../Common/Preloader'
import { useParams } from 'react-router-dom'
import { BookingContext } from '../../../pages/Booking'
import { useQueryClient } from 'react-query'
import { useWaiversentitys } from '../../../libs/hooks/useWaiversentitys'
import { useSomePlayers } from '../../../libs/hooks/usePlayers'
import { useCopyWaiverToBooking } from '../../../libs/hooks/useCopyWaiverToBooking'

export default function Waivers({ ...rest }) {
  const queryClient = useQueryClient()
  const [bookingData, isLoading] = useContext(BookingContext)
  const { id: bookingId } = useParams()
  const theme = useTheme()
  const customColors = theme.colors.custom
  const {
    data,
    isLoading: isWaiverLoading,
    refetch: refetchWaiversentitys
  } = useWaiversentitys(+bookingId)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const waivers = data?.results || []

  const playersBookingLimit = bookingData?.players
  const totalPlayers = useMemo(() => {
    if (waivers.length !== 0) {
      return waivers?.reduce((previousValue, currentValue) => {
        const signedFor = currentValue.signed_for
        const minors = currentValue?.minors || []
        const minorsCount = minors.length
        if (signedFor === 'M' || signedFor === 'M&C') {
          return previousValue + 1 + minorsCount
        } else {
          return previousValue + minorsCount
        }
      }, 0)
    }
    return 0
  }, [waivers])

  const alreadyAddedWaivers = useMemo(() => {
    const obj = {}
    if (waivers.length !== 0) {
      waivers.forEach((waiver) => (obj[waiver.id] = true))
    }
    return obj
  }, [waivers])
  const [searchInput, setSearchInput] = useState('')
  const [selectedObject, setSelectedObject] = useState({})
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const [prompt, setPrompt] = useState([])
  const { mutate: copyWaiverToBooking } = useCopyWaiverToBooking()
  const playersInObject = selectedObject?.playersInObject

  const maxPlayersHasReached = playersBookingLimit <= totalPlayers
  const maxPlayersWillBeOverload =
    typeof playersInObject === 'number'
      ? totalPlayers + playersInObject <= playersBookingLimit
      : true

  const playersIds = useMemo(() => {
    const arr = []
    if (waivers?.length !== 0) {
      waivers?.forEach((waiver) => arr.push(waiver.player_id))
    }
    return arr
  }, [waivers])
  const { data: players = [] } = useSomePlayers(playersIds, {
    enabled: playersIds.length !== 0
  })
  const blackListObject = useMemo(() => {
    const obj = {}
    if (players.length > 1) {
      players.forEach(
        (player) => (obj[player?.playerId] = player?.is_in_blacklist)
      )
    }
    if (players.length === 1) {
      const arr = [players]
      arr.forEach((player) => (obj[player?.playerId] = player?.is_in_blacklist))
    }
    return obj
  }, [players])

  const waiversWithBlacklist = useMemo(() => {
    const arr = []
    if (waivers.length !== 0 && Object.keys(blackListObject).length !== 0) {
      waivers.forEach((waiver) => {
        arr.push({
          ...waiver,
          inBlacklist: blackListObject[waiver.player_id]
        })
      })
    }
    return arr
  }, [waivers, blackListObject])

  const isSelectedObjectComplete = useMemo(() => {
    return Object.keys(selectedObject).length !== 0
  }, [selectedObject])

  const { refetch } = useAutocompleteWaivers(searchInput, {
    enabled: false,
    onSuccess: (data) => {
      const correct = []
      for (let i = 0; i < data?.results?.length; i++) {
        const waiver = data?.results[i]
        if (!alreadyAddedWaivers[waiver.id]) {
          //const signedFor = waiver.signed_for;
          //const ownerCount = (signedFor === 'M' || signedFor === 'M&C') ? 1 : 0;
          //const minors = waiver?.minors;
          const playersInObject = 1 //Array.isArray(minors) ? (ownerCount + minors.length) : (ownerCount);
          correct.push({ ...waiver, playersInObject })
        }
      }
      setPrompt(correct)
    }
  })

  useEffect(() => {
    refetchWaiversentitys()
  }, [])

  const inputGroupRef = useRef()

  useOutsideClick({
    ref: inputGroupRef,
    handler: () => setShowAutocomplete(false)
  })

  function isNeedPreloader() {
    return isWaiverLoading || isLoading
  }

  const debouncedSearch = useCallback(
    debounce(() => {
      refetch()
    }, debounceSearchWaiversTime),
    []
  )

  useEffect(() => {
    if (searchInput.length === 0) {
      debouncedSearch.cancel()
      setSelectedObject({})
      setShowAutocomplete(true)
      setPrompt([])
    }
    if (searchInput.length >= 3 && !isSelectedObjectComplete) {
      debouncedSearch()
    }
  }, [searchInput])

  useEffect(() => {
    const firstname = selectedObject?.firstname
      ? selectedObject?.firstname + ' '
      : ''
    const lastname = selectedObject?.lastname
      ? selectedObject?.lastname + ' '
      : ''
    const birthdate = selectedObject?.birthdate
      ? `(${selectedObject?.birthdate})` + ' '
      : ''
    const email = selectedObject?.email ? selectedObject?.email + ' ' : ''
    const phone = selectedObject?.phone ? selectedObject?.phone + ' ' : ''
    const playersInObject =
      typeof selectedObject?.playersInObject === 'number'
        ? selectedObject?.playersInObject
        : ''
    const playersWord = playersInObject > 1 ? 'players' : 'player'
    const players = playersInObject
      ? playersWord
        ? ` (${playersInObject} ${playersWord})`
        : ''
      : ''
    const str = firstname + lastname + birthdate + email + phone
    setSearchInput(str)
  }, [selectedObject])

  function acceptPrompt(obj) {
    setSelectedObject(obj)
    setShowAutocomplete(false)
  }

  function onClickHandler() {
    const arr = selectedObject.bookings
    arr.push(+bookingId)
    const objToSend = {
      waiversentitysId: +selectedObject?.id,
      bookings: arr
    }

    copyWaiverToBooking(
      {
        waiverId: +selectedObject?.id,
        bookingId
      },
      {
        onSuccess: () => {
          setSearchInput('')
          setSelectedObject({})
          setShowAutocomplete(true)
          setPrompt([])
          queryClient
            .invalidateQueries(['waiversentitys', +bookingId])
            .then(() => {
              setTimeout(
                () =>
                  queryClient.invalidateQueries(['waiversentitys', +bookingId]),
                1000
              )
            })
        }
      }
    )
    // patchWaiversentitys(
    //     {
    //         obj: objToSend,
    //     },
    //     {
    //         onSuccess: () => {
    //             setSearchInput('');
    //             setSelectedObject({})
    //             setShowAutocomplete(true);
    //             setPrompt([]);
    //             queryClient.invalidateQueries(['waiversentitys', +(bookingId)]);
    //         }
    //     }
    // )
  }

  return (
    <Accordion
      allowToggle
      defaultIndex={[0]}
      mb="16px"
      borderStyle="hidden"
      fontFamily="inherit"
      {...rest}
    >
      <AccordionItem borderStyle="hidden">
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton
                fontWeight="500"
                fontSize={{ base: '18px', '2xl': '24px' }}
                bg="white"
                p={{
                  base: isExpanded ? '16px 16px 0' : '16px',
                  '2xl': isExpanded ? '24px 24px 0' : '24px'
                }}
                _hover={{ bg: 'white' }}
                _focus={{ border: 'none' }}
                _active={{ bg: 'white' }}
              >
                <Box d="flex" w="100%" alignItems="center">
                  <Box
                    d="flex"
                    flex="1"
                    alignItems={{ base: 'flex-start', md: 'center' }}
                    flexDir={{ base: 'column', md: 'row' }}
                  >
                    <Text>Waivers</Text>
                    {maxPlayersHasReached && (
                      <Text
                        as="p"
                        ml={{ md: 'auto' }}
                        mr={{ md: '24px' }}
                        color="custom.red.600"
                        fontSize="14px"
                      >
                        Players limit reached.
                      </Text>
                    )}
                  </Box>
                  <ArrowIcon
                    w="auto"
                    height="16px"
                    transition="all 0.2s ease-in"
                    transform={isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'}
                    color="custom.blue.600"
                  />
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel p="0">
              <Box
                bg="white"
                p={{ base: '16px', '2xl': '24px' }}
                mb="2px"
                fontSize={{ base: '12px', '2xl': '14px' }}
                d={{ base: 'block', md: 'flex' }}
              >
                <InputGroup ref={inputGroupRef}>
                  <Input
                    placeholder="First name, status, minors"
                    fontSize="inherit"
                    fontWeight="400"
                    p="0 16px"
                    mb="8px"
                    disabled={maxPlayersHasReached}
                    value={searchInput}
                    _disabled={{
                      opacity: 0.5,
                      cursor: 'not-allowed'
                    }}
                    onChange={(e) => setSearchInput(e.target.value)}
                    _placeholder={{
                      color: 'custom.gray.300'
                    }}
                    _focus={{
                      borderColor: 'custom.blue.600'
                    }}
                    onClick={() => setShowAutocomplete(true)}
                    variant="gbase"
                  />
                  {showAutocomplete && prompt?.length !== 0 && (
                    <Box
                      as="ul"
                      listStyleType="none"
                      position="absolute"
                      width="inherit"
                      bg="custom.lightGray"
                      border={`1px solid ${customColors.blue[600]}`}
                      top="39px"
                      zIndex="1"
                      overflow="auto"
                      maxH="200px"
                    >
                      {prompt?.map((prompt, idx) => {
                        const playersInObject = prompt?.playersInObject
                        const playersWord =
                          playersInObject > 1 ? 'players' : 'player'
                        const players = playersWord
                          ? ` (${playersInObject} ${playersWord})`
                          : ''
                        return (
                          <Box
                            as="li"
                            key={'prompt' + idx}
                            minH="40px"
                            display="flex"
                            alignItems="center"
                            p="5px 10px 5px 20px"
                            _hover={{ bg: 'custom.gray.50' }}
                            cursor="pointer"
                            onClick={() => acceptPrompt(prompt)}
                            borderBottom={`1px solid ${customColors.gray[50]}`}
                          >
                            {prompt.firstname} {prompt.lastname} (
                            {prompt.birthdate}){'  '}
                            {prompt.email}
                            {'  '}
                            {prompt.phone}{' '}
                          </Box>
                        )
                      })}
                    </Box>
                  )}
                  {isLargerThan768 && (
                    <InputRightElement
                      children={<SearchIcon color="custom.gray.200" />}
                    />
                  )}
                </InputGroup>

                <Button
                  variant="blue"
                  fontWeight="500"
                  fontSize="inherit"
                  w={{ base: '100%', md: 'auto' }}
                  minW={{ base: '140px', '2xl': '160px' }}
                  ml={{ base: 'unset', md: '8px', '2xl': '16px' }}
                  isDisabled={
                    !isSelectedObjectComplete ||
                    maxPlayersHasReached ||
                    !maxPlayersWillBeOverload
                  }
                  _hover={{
                    _disabled: false
                  }}
                  onClick={onClickHandler}
                >
                  <PlusIcon
                    w={{ base: '12px', '2xl': '14px' }}
                    height={{ base: '12px', '2xl': '14px' }}
                    color="white"
                    mr="8px"
                  />
                  Add waiver
                </Button>
              </Box>
              {waivers?.length === 0 ? (
                <Box
                  bg="white"
                  height="56px"
                  p="10px"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize={{ base: '12px', '2xl': '14px' }}>
                    No Waivers.
                  </Text>
                </Box>
              ) : (
                <>
                  {isNeedPreloader() ? (
                    <Box
                      bg="white"
                      height="56px"
                      p="10px"
                      d="flex"
                      alignItems="center"
                    >
                      <Preloader m="0" />
                    </Box>
                  ) : (
                    <>
                      {!isLargerThan768 && (
                        <WaiversList data={waiversWithBlacklist} />
                      )}
                      {isLargerThan768 && (
                        <WaiversTable tableData={waiversWithBlacklist} />
                      )}
                    </>
                  )}
                </>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
