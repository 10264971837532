import { createIcon } from '@chakra-ui/icons'

export const CheckMarkIcon = createIcon({
  displayName: 'CheckMarkIcon',
  viewBox: '0 0 18 14',
  boxSize: '16',
  path: (
    <g fill="currentColor">
      <path d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z" />
    </g>
  )
})
