import React, { useMemo } from 'react'
import { Box, Link, Text, useTheme } from '@chakra-ui/react'
import { BOOKING_URL, GIFT_CARDS_URL } from '../../../constants'
import TableCreator from '../../Common/TableCreator'
import GBaseTag from '../../Common/GBaseTag'
import { TABLE_COLUMNS_SIZES_CASH } from '../../../configs/tablesSizes'
import { TABLE_COLUMN_VALUES_CASH } from '../../../configs'
import { changeFormatAndTimezone } from '../../../libs'

export default function CashTable({
  tableData = [],
  timezone,
  invalidateCashString
}) {
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const data = useMemo(() => tableData, [tableData, invalidateCashString])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 16px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_CASH.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'booking') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return original.booking_code ? (
            <Link href={`${BOOKING_URL}/${original.booking}`} isExternal>
              <Text
                ml="auto"
                color="custom.blue.600"
                textDecoration="underline"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                #{original?.booking_code}
              </Text>
            </Link>
          ) : (
            '—'
          )
        }
      }

      if (field.Header.toLowerCase() === 'giftcard') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return original.giftcard_code ? (
            <Link
              href={`${GIFT_CARDS_URL}/?find=${original.giftcard_code}`}
              isExternal
            >
              <Text
                ml="auto"
                color="custom.blue.600"
                textDecoration="underline"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                #{original.giftcard_code}
              </Text>
            </Link>
          ) : (
            '—'
          )
        }
      }

      if (field.Header.toLowerCase() === 'sum') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          const isPositive = +original?.amount > 0
          const gbaseTagColor = isPositive ? 'green' : 'red'
          const label = isPositive
            ? `+$${+original.amount}`
            : `-$${-+original.amount}`
          return (
            <GBaseTag
              label={label}
              color={gbaseTagColor}
              m="0 auto"
              minW="100px"
            />
          )
        }
      }

      if (field.Header.toLowerCase() === 'comments') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return original?.comment ? <>{original.comment}</> : '—'
        }
      }

      if (field.Header.toLowerCase() === 'client') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return original?.client_email ? <>{original.client_email}</> : '—'
        }
      }

      if (field.Header.toLowerCase() === 'date') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          return <>{changeFormatAndTimezone(original.created, timezone)}</>
        }
      }

      return fieldObject
    })
  }, [timezone, data])
  return data?.length !== 0 ? (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sizesMap={TABLE_COLUMNS_SIZES_CASH}
    />
  ) : (
    <Box textAlign="center" m="20px 0" fontSize="14px">
      No results were found for your search.
    </Box>
  )
}
