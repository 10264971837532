import { createIcon } from '@chakra-ui/icons'

export const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 16 18',
  boxSize: '16 18',
  path: (
    <g fill="currentColor">
      <path
        d="M5.42856 8.1H3.71428V9.9H5.42856V8.1ZM8.85713 8.1H7.14285V9.9H8.85713V8.1ZM12.2857 8.1H10.5714V9.9H12.2857V8.1ZM14 1.8H13.1428V0H11.4286V1.8H4.57142V0H2.85713V1.8H1.99999C1.04856 1.8 0.294277 2.61 0.294277 3.6L0.285706 16.2C0.285706 17.19 1.04856 18 1.99999 18H14C14.9428 18 15.7143 17.19 15.7143 16.2V3.6C15.7143 2.61 14.9428 1.8 14 1.8ZM14 16.2H1.99999V6.3H14V16.2Z"
        fill="#C7CFD6"
      />
    </g>
  )
})
