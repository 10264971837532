import { Box, Text } from '@chakra-ui/react'
import EditHolidayModal from '../../../Settings/Tabs/Attractions/AttractionsExperience/Modals/EditHolidayModal'
import DeleteHolidayModal from '../../../Settings/Tabs/Attractions/AttractionsExperience/Modals/DeleteHolidayModal'
import { useHolidays } from '../../../../libs/hooks/useHolidays'
import { friendlyTime } from '../../../../libs'

export default function HolidaysList() {
  const { data } = useHolidays()
  return (
    <Box
      p="16px"
      bg="white"
      d={{ base: 'block', md: 'grid' }}
      gridTemplateColumns={{
        md: 'repeat(3, 1fr)',
        lg: 'repeat(4, 1fr)',
        '2xl': 'repeat(5, 1fr)'
      }}
      gridGap={{ base: '8px', '2xl': '16px' }}
    >
      {data
        ?.sort((a, b) => a.effective_date_from - b.effective_date_from)
        .map((el, idx) => {
          const effectiveDateFrom = friendlyTime(
            el.effective_date_from * 1000,
            'MM.DD.YY'
          )
          const effectiveDateTo = friendlyTime(
            el.effective_date_to * 1000,
            'MM.DD.YY'
          )
          const holidayId = el.id
          return (
            <Box
              bg="custom.blue.600"
              key={'HolidaysList' + idx}
              color="white"
              d="grid"
              borderRadius="20px"
              gridTemplateColumns="4fr 1fr"
              justifyContent="center"
              alignItems="center"
              p="0 16px"
              fontSize={{ base: '12px', '2xl': '14px' }}
              mb={{ base: '8px', md: 'unset' }}
            >
              <Text>
                {effectiveDateFrom} - {effectiveDateTo}
              </Text>
              <Box d="flex" justifySelf="end">
                <EditHolidayModal holidayId={holidayId} />
                <DeleteHolidayModal
                  holidayId={holidayId}
                  name={`${effectiveDateFrom} - ${effectiveDateTo}`}
                />
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}
