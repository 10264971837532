import { api } from '../../index'

export async function getNotificationTemplates() {
  try {
    const { data } = await api.get('/api/notification-templates/')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getNotificationTemplate(notificationTemplateId) {
  try {
    const { data } = await api.get(
      `/api/notification-templates/${notificationTemplateId}/`
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getNotificationTemplatesEvents(queryParam = null) {
  try {
    const { data } = await api.get(`/api/notification-templates/events/`, {
      params: {
        event_type: queryParam
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function patchNotificationTemplate({
  notificationTemplateId,
  templateName = null,
  smsMessage = null,
  emailMessage = null,
  sendHoursBeforeGame = null,
  sendHoursAfterGame = null
}) {
  const prepare = {
    template_name: templateName,
    sms_message: smsMessage,
    email_message: emailMessage,
    reminder_delay: sendHoursBeforeGame,
    request_feedback_delay: sendHoursAfterGame
  }
  const toSend = {}
  Object.keys(prepare).forEach((key) => {
    if (prepare[key]) {
      toSend[key] = prepare[key]
    }
  })
  const { data } = await api.patch(
    `/api/notification-templates/${notificationTemplateId}/`,
    toSend
  )
  return data
}

export async function newNotificationTemplate({
  templateName = null,
  smsMessage = null,
  emailMessage = null,
  sendHoursBeforeGame = null,
  sendHoursAfterGame = null
}) {
  const toSend = {
    template_name: templateName,
    sms_message: smsMessage,
    email_message: emailMessage,
    reminder_delay: sendHoursBeforeGame,
    request_feedback_delay: sendHoursAfterGame
  }
  const { data } = await api.post(`/api/notification-templates/`, toSend)
  return data
}

export async function applyTemplateChanges({
  templateId,
  eventvariantId,
  eventType,
  notifications
}) {
  const toSend = {
    template: templateId,
    eventvariant: eventvariantId,
    event_type: eventType,
    notifications
  }
  const { data } = await api.patch(
    '/api/notification-templates/apply_template/',
    toSend
  )
  return data
}

export async function applyTemplate({
  templateId,
  eventvariantId,
  eventType,
  notifications
}) {
  const toSend = {
    template: templateId,
    eventvariant: eventvariantId,
    event_type: eventType,
    notifications
  }
  const { data } = await api.post(
    '/api/notification-templates/apply_template/',
    toSend
  )
  return data
}

export async function deleteTemplate({ templateId }) {
  const { data } = await api.delete(
    `/api/notification-templates/${templateId}/`
  )
  return data
}

export async function getEmailBaseTemplates() {
  try {
    const { data } = await api.get('/api/email-base-templates/ ')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getEmailBaseTemplate(emailBaseTemplateId) {
  try {
    const { data } = await api.get(
      `/api/email-base-templates/${emailBaseTemplateId}/`
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function newEmailBaseTemplate({
  templateName = null,
  templateTheme = null,
  templateHeader = null,
  templateFooter = null
}) {
  const toSend = {
    template_name: templateName,
    theme: templateTheme,
    header: templateHeader,
    footer: templateFooter
  }

  try {
    const { data } = await api.post(`/api/email-base-templates/ `, toSend)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function patchEmailBaseTemplate({
  emailBaseTemplateId,
  templateName = null,
  templateTheme = null,
  templateHeader = null,
  templateFooter = null
}) {
  const prepare = {
    template_name: templateName,
    theme: templateTheme,
    header: templateHeader,
    footer: templateFooter
  }
  const toSend = {}
  Object.keys(prepare).forEach((key) => {
    if (prepare[key]) {
      toSend[key] = prepare[key]
    }
  })
  try {
    const { data } = await api.patch(
      `/api/email-base-templates/${emailBaseTemplateId}/`,
      toSend
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function deleteEmailBaseTemplate({ emailBaseTemplateId }) {
  const { data } = await api.delete(
    `/api/email-base-templates/${emailBaseTemplateId}/`
  )
  return data
}

export async function getEmailBaseTemplatesLocations() {
  try {
    const { data } = await api.get(`/api/email-base-templates/locations/`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function applyEmailBaseTemplateChanges({
  templateId,
  locationId
}) {
  const toSend = {
    template: templateId,
    location: locationId
  }
  try {
    const { data } = await api.patch(
      '/api/email-base-templates/apply_template/',
      toSend
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function applyEmailBaseTemplate({ templateId, locationId }) {
  const toSend = {
    template: templateId,
    location: locationId
  }
  try {
    const { data } = await api.post(
      '/api/email-base-templates/apply_template/',
      toSend
    )
    return data
  } catch (e) {
    console.log(e)
  }
}
