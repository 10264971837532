import { api } from '../../index'

export async function getUsers() {
  try {
    const { data } = await api.get('/api/users/')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function editUser({
  userId,
  firstname = null,
  lastname = null,
  email = null,
  password = null,
  phone = null,
  locations = null,
  role = null,
  disabled = null
}) {
  const prepare = {
    first_name: firstname,
    last_name: lastname,
    email: email,
    role: role,
    locations: locations,
    disabled: disabled,
    phone: phone,
    ...(password && { password })
  }
  const toSend = {}
  Object.keys(prepare).forEach((key) => {
    if (prepare[key] !== null) {
      toSend[key] = prepare[key]
    }
  })
    const { data } = await api.patch(`/api/users/${userId}/`, toSend)
    return data
}

export async function newUser({
  firstname = null,
  lastname = null,
  email = null,
  password = null,
  phone = null,
  locations = null,
  role = null
}) {
  const toSend = {
    first_name: firstname,
    last_name: lastname,
    email: email,
    role: role,
    locations: locations,
    phone: phone,
    password: password,
    disabled: false
  }
    const { data } = await api.post(`/api/users/`, toSend)
    return data
}

export async function deleteUser({ userId }) {
  try {
    const { data } = await api.delete(`/api/users/${userId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}
