import { createIcon } from '@chakra-ui/icons'

export const ErrorIcon = createIcon({
  displayName: 'ErrorIcon',
  viewBox: '0 0 16 16',
  boxSize: '16',
  path: (
    <g fill="currentColor">
      <path d="M8 4C8.44 4 8.8 4.36 8.8 4.8V8C8.8 8.44 8.44 8.8 8 8.8C7.56 8.8 7.2 8.44 7.2 8V4.8C7.2 4.36 7.56 4 8 4ZM7.992 0C3.576 0 0 3.584 0 8C0 12.416 3.576 16 7.992 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 7.992 0ZM8 14.4C4.464 14.4 1.6 11.536 1.6 8C1.6 4.464 4.464 1.6 8 1.6C11.536 1.6 14.4 4.464 14.4 8C14.4 11.536 11.536 14.4 8 14.4ZM8.8 12H7.2V10.4H8.8V12Z" />
    </g>
  )
})
