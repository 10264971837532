import React, { useEffect, useState } from 'react'
import { Input, useTheme } from '@chakra-ui/react'

export default function EditableCell({
  prop = '',
  isNumeric = false,
  initialValue = '',
  cb = null,
  partnerId
}) {
  const regexp = /^[1-9]\d*$/
  const [input, setInput] = useState(initialValue)
  const theme = useTheme()
  const customColors = theme.colors.custom

  useEffect(() => {
    cb({
      name: input,
      partnerId: partnerId
    })
  }, [input])

  function onChange(e) {
    if (e.target.value.length < 50) {
      setInput(e.target.value)
    }
  }

  function onBlur() {
    const obj = {
      property: prop,
      value: input
    }
  }

  return (
    <Input
      placeholder={input ? input : 'empty'}
      variant="unstyled"
      value={input ? input : ''}
      onChange={onChange}
      onBlur={onBlur}
      bg="white"
      textAlign="center"
      borderRadius="none"
      _focus={{ borderColor: 'custom.blue.600' }}
      border={`1px solid ${customColors.borderBlue}`}
      fontSize="inherit"
      height="40px"
      maxW="85%"
      padding="0 2px"
      textOverflow="ellipsis"
      type={isNumeric ? 'number' : 'text'}
    />
  )
}
