import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { onlyFrontDateCorrection } from '@/libs'

import DatePickerModal from '@/components/Booking/Modals/DatePickerModal'
import DropdownSelect from '@/components/Common/DropdownSelect'

import { setCurrentPage, setStatusFilter } from '@/actions'
import { todayOptions } from '@/configs'
import {
  filtersInState,
  matchMedia768InState,
  minCalendarDayInState
} from '@/constants'

import StatusSelectorButtons from './StatusSelectorButtons'

export const useRouterQuery = () => {
  const { search } = useLocation()
  const history = useHistory()

  const setQuery = (query) => {
    const currentQuery = new URLSearchParams(history.location.search)

    Object.entries(query).forEach(([key, value]) => {
      currentQuery.set(key, value)
    })

    history.push({
      pathname: history.location.pathname,
      search: currentQuery.toString()
    })
  }

  return {
    query: React.useMemo(() => new URLSearchParams(search), [search]),
    setQuery
  }
}

export default function StatusSelector({ title = 'Bookings', type = 'table' }) {
  const dispatch = useDispatch()
  const { setQuery } = useRouterQuery()

  const { isLargerThan768, filters, minCalendarDay } = useSelector((state) => ({
    isLargerThan768: matchMedia768InState(state),
    filters: filtersInState(state),
    minCalendarDay: minCalendarDayInState(state)
  }))

  const minCalendarDaySegments = minCalendarDay
    ? minCalendarDay.split('.')
    : null
  const currentTime = new Date()
  const selectedDateSegments = useMemo(() => {
    if (minCalendarDaySegments === null) return null
    const [day, month, year] = minCalendarDaySegments.map((segment) => +segment)
    return { day, month, year }
  }, [minCalendarDaySegments])

  const selectedDate = useMemo(() => {
    if (typeof filters.status.value === 'number') {
      return filters.status.value
    }

    if (selectedDateSegments) {
      return new Date(
        selectedDateSegments.year,
        selectedDateSegments.month,
        selectedDateSegments.day,
        currentTime.getHours(),
        currentTime.getMinutes()
      )
    }

    return new Date()
  }, [filters.status.value, selectedDateSegments, currentTime])

  const [currentSelectedDate, setCurrentSelectedDate] = useState(selectedDate)

  const getSelectedDateOption = () => ({
    value: currentSelectedDate.valueOf(),
    label:
      new Date(currentSelectedDate).toLocaleDateString() ===
      new Date().toLocaleDateString()
        ? 'Today'
        : onlyFrontDateCorrection(
            currentSelectedDate.valueOf() / 1000,
            'MM.DD.YY'
          )
  })

  const statusOptions = useMemo(() => {
    return [getSelectedDateOption(), ...todayOptions]
  }, [currentSelectedDate, minCalendarDay])


  useEffect(() => {
    handleChangeStatus(getSelectedDateOption())
    dispatch(setCurrentPage(1))
  }, [currentSelectedDate])

  function handleChangeStatus(status) {
    if (!status) return
    dispatch(setStatusFilter(status))
    dispatch(setCurrentPage(1))
  }

  useEffect(() => {
    setQuery({ status: filters.status.value })
  }, [filters.status.value])
  console.log(filters.status)
  return (
    <>
      {!isLargerThan768 && (
        <Text
          as="h1"
          color="black"
          fontWeight="600"
          fontSize="24px"
          lineHeight="28px"
          mb="16px"
        >
          {title}
        </Text>
      )}

      <Box
        mb={{ base: '8px', md: '0' }}
        height={{ base: 'auto', md: '56px', '2xl': '64px' }}
      >
        {isLargerThan768 ? (
          <Box d="flex" w="100%" bg="white" height="100%">
            <StatusSelectorButtons
              options={statusOptions}
              onChange={handleChangeStatus}
              cb={setCurrentSelectedDate}
              type={type}
              selectedDate={currentSelectedDate}
            />
          </Box>
        ) : (
          <Box d="flex">
            <DropdownSelect
              selectProps={{
                main: {
                  options: statusOptions,
                  placeholder: 'Today',
                  value: filters?.status,
                  onChange: handleChangeStatus
                }
              }}
              chakraProps={{ width: '100%' }}
            />
            <DatePickerModal
              cb={(value) => {
                setCurrentSelectedDate(value)
              }}
              selectedDate={currentSelectedDate}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
