import {
  Box,
  Button,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../../assets/Icons/DisketteIcon'
import { PlusIcon } from '../../../../../../../assets/Icons/PlusIcon'
import {
  friendlyTimeWithTZ,
  generateWarningToast
} from '../../../../../../../libs'
import { useEventVariants } from '../../../../../../../libs/hooks/useEventVariants'
import { useNewHoliday } from '../../../../../../../libs/hooks/useHolidays'
import { editHolidayModalValidation } from '../../../../../../../configs/validation/editHolidayModalValidation'
import { PERMISSION_EVENT } from '../../../../../../../constants'
import DatePickerModal from '../../../../../../Booking/Modals/DatePickerModal'
import ApplyExperienceList from '../../../../../../Common/ApplyExperienceList'
import ButtonsToggle from '../../../../../../Common/ButtonsToggle'
import FormikInput from '../../../../../../Common/FormikComponents/FormikInput'
import PermissionRequired from '../../../../../../Common/PermissionRequired'
import Preloader from '../../../../../../Common/Preloader'
import PseudoInput from '../../../../../../Common/PseudoInput'

export default function NewHolidayModal() {
  const holidayPermission = PermissionRequired(PERMISSION_EVENT, true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const customColors = theme.colors.custom
  const [dateFrom, setDateFrom] = useState(0)
  const [dateTo, setDateTo] = useState(0)
  const { data: eventVariants } = useEventVariants()
  const { mutate, isLoading } = useNewHoliday()
  const eventVariantsArr = eventVariants?.results
  const toast = useToast()

  function timesOfDay(timeLetter) {
    const letter = timeLetter?.toLowerCase()
    switch (letter) {
      case 'm': {
        return 'morning'
      }

      case 'e': {
        return 'evening'
      }

      case 'w': {
        return 'weekend'
      }

      default: {
        return 'morning'
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      removePromocode: false,
      overridePriceLevel: false,
      pricingType: '',
      addMarkup: false,
      removeAllDiscounts: false,
      removeAllLimitations: false,
      unlockEverything: false,
      markup: '',
      markupType: '',
      applyTo: [],
      unlockHide: false
    },
    validationSchema: editHolidayModalValidation,
    onSubmit: submitHandler
  })

  const formikValues = formik.values
  const handleChange = formik.handleChange
  const handleSubmit = formik.handleSubmit

  function submitHandler(values) {
    const toSend = {
      ...values,
      ...(values.markupType === '$' &&
        values.addMarkup && { markup: +values.markup * 100 }),
      ...(values.markupType === '%' &&
        values.addMarkup && { markup: +values.markup }),
      ...(!values.addMarkup && { markup: null }),
      markupType: values.addMarkup ? values.markupType : null,
      pricingType: values.overridePriceLevel
        ? values.pricingType
          ? values.pricingType.slice(0, 1).toUpperCase()
          : ''
        : null,
      applyTo: values.applyTo,
      effectiveDateFrom: dateFrom,
      effectiveDateTo: dateTo
    }

    mutate(
      { obj: toSend },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['holidays'])
          onClose()
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          generateWarningToast({
            id: 'new-holiday',
            toast,
            msgObjects,
            message: msg
          })
        }
      }
    )
  }

  return (
    <>
      <Button
        variant="blue"
        w={{ base: '100%', md: '140px', '2xl': '180px' }}
        onClick={onOpen}
        ml={{ md: 'auto' }}
        isDisabled={!holidayPermission}
        fontSize={{ base: '12px', '2xl': '14px' }}
      >
        <PlusIcon mr="8px" />
        New holiday
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          pos="relative"
          maxW={{ base: '305px', md: '720px', '2xl': '1100px' }}
        >
          <FormikProvider value={formik}>
            <>
              {isLoading && (
                <Box
                  pos="absolute"
                  top="0"
                  left="0"
                  bottom="0"
                  right="0"
                  zIndex={2}
                >
                  <Preloader
                    h="8px"
                    w="calc(100% - 4px)"
                    opacity="0.8"
                    m="2px"
                  />
                </Box>
              )}
              <ModalHeader
                d="flex"
                flexDir={{ base: 'column', md: 'row' }}
                mb="34px"
                opacity={isLoading ? 0.4 : 1}
                alignItems={{ base: 'unset', md: 'center' }}
              >
                <Text
                  mb={{ base: '8px', md: 'unset' }}
                  fontSize={{ base: '18px', '2xl': '24px' }}
                >
                  New holiday
                </Text>
                <Box
                  d="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridGap="8px"
                  w={{ base: '100%', md: 'unset' }}
                  ml={{ base: 'unset', md: 'auto' }}
                >
                  <Button
                    w="100%"
                    fontSize={{ base: '12px', '2xl': '14px' }}
                    variant="blue"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <DisketteIcon mr="8px" />
                    Save
                  </Button>
                  <Button
                    w="100%"
                    fontSize={{ base: '12px', '2xl': '14px' }}
                    variant="red"
                    onClick={onClose}
                  >
                    <CrosshairIcon mr="8px" />
                    Cancel
                  </Button>
                </Box>
              </ModalHeader>
              <ModalBody
                m="0 0 16px"
                color="custom.black.500"
                d="grid"
                opacity={isLoading ? 0.4 : 1}
                gridGap={{ base: 'unset', md: '16px' }}
                alignItems={{ base: 'unset', md: 'center' }}
                gridTemplateColumns={{ base: 'auto', md: 'repeat(3, 1fr)' }}
                fontSize={{ base: '12px', '2xl': '14px' }}
              >
                <Box
                  d={{ base: 'grid', md: 'flex' }}
                  gridTemplateColumns={{ base: '1fr 1fr' }}
                  mb={{ base: '16px', md: 'unset' }}
                  gridGap="8px"
                  alignItems={{ md: 'center' }}
                  gridColumn={{ base: 'unset', md: '1/4' }}
                >
                  <Text gridColumn={{ base: '1/3', md: 'unset' }}>
                    Effective date:
                  </Text>
                  <InputGroup maxW={{ md: '160px' }}>
                    <PseudoInput
                      h="40px"
                      w="100%"
                      border={`1px solid ${customColors.borderBlue}`}
                      value={friendlyTimeWithTZ(dateFrom, 'MM.DD.YY')}
                    />
                    <InputRightElement>
                      <DatePickerModal
                        selectedDate={dateFrom}
                        cb={setDateFrom}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup maxW={{ md: '160px' }}>
                    <PseudoInput
                      h="40px"
                      w="100%"
                      border={`1px solid ${customColors.borderBlue}`}
                      value={friendlyTimeWithTZ(dateTo, 'MM.DD.YY')}
                    />
                    <InputRightElement>
                      <DatePickerModal
                        selectedDate={dateTo}
                        cb={setDateTo}
                        minDate={dateFrom}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Box>
                <Box
                  d="grid"
                  gridTemplateColumns="3fr 1fr"
                  mb={{ base: '16px', md: 'unset' }}
                  gridColumn={{ base: 'unset', md: '1/2' }}
                >
                  <Text>Unlock everything:</Text>
                  <Switch
                    justifySelf="end"
                    isChecked={formikValues.unlockEverything}
                    onChange={handleChange('unlockEverything')}
                  />
                </Box>
                <Box
                  d="grid"
                  gridTemplateColumns="3fr 1fr"
                  mb={{ base: '16px', md: 'unset' }}
                  gridColumn={{ base: 'unset', md: '2/3' }}
                >
                  <Text>Remove all limitations:</Text>
                  <Switch
                    justifySelf="end"
                    isChecked={formikValues.removeAllLimitations}
                    onChange={handleChange('removeAllLimitations')}
                  />
                </Box>
                <Box
                  d="grid"
                  gridTemplateColumns="3fr 1fr"
                  mb={{ base: '8px', md: 'unset' }}
                  gridColumn={{ base: 'unset', md: '1/2' }}
                  gridRow={{ base: 'unset', md: '3/4' }}
                >
                  <Text>Override price level:</Text>
                  <Switch
                    justifySelf="end"
                    isChecked={formikValues.overridePriceLevel}
                    onChange={handleChange('overridePriceLevel')}
                  />
                </Box>
                <Box
                  gridColumn={{ base: 'unset', md: '1/2' }}
                  gridRow={{ base: 'unset', md: '4/5' }}
                  minH="40px"
                >
                  {formikValues.overridePriceLevel && (
                    <ButtonsToggle
                      data={['morning', 'evening', 'weekend']}
                      mb={{ base: '8px', md: 'unset' }}
                      unCheckedColor="custom.lightGray"
                      initialState={timesOfDay(formikValues.pricingType)}
                      cb={handleChange('pricingType')}
                    />
                  )}
                </Box>
                <Box
                  d="grid"
                  gridTemplateColumns="3fr 1fr"
                  mb={{ base: '16px', md: 'unset' }}
                  gridColumn={{ base: 'unset', md: '3/4' }}
                  gridRow={{ base: 'unset', md: '2/3' }}
                >
                  <Text>Remove all discounts:</Text>
                  <Switch
                    justifySelf="end"
                    isChecked={formikValues.removeAllDiscounts}
                    onChange={handleChange('removeAllDiscounts')}
                  />
                </Box>
                <Box
                  d="grid"
                  gridTemplateColumns="3fr 1fr"
                  mb={{ base: '16px', md: 'unset' }}
                  gridColumn={{ base: 'unset', md: '2/3' }}
                  gridRow={{ base: 'unset', md: '3/4' }}
                >
                  <Text>Add markup:</Text>
                  <Switch
                    justifySelf="end"
                    isChecked={formikValues.addMarkup}
                    onChange={handleChange('addMarkup')}
                  />
                </Box>
                <Box
                  d="grid"
                  gridTemplateColumns="2fr 1fr"
                  gridGap="8px"
                  mb={{ base: '16px', md: 'unset' }}
                  gridRow={{ base: 'unset', md: '4/5' }}
                  gridColumn={{ base: 'unset', md: '2/3' }}
                  minH="40px"
                >
                  {formikValues.addMarkup && (
                    <>
                      <FormikInput
                        name="markup"
                        id="markup"
                        placeholder={'mark up'}
                      />
                      <ButtonsToggle
                        data={['$', '%']}
                        initialState={formikValues.markupType}
                        cb={handleChange('markupType')}
                        w="100%"
                      />
                    </>
                  )}
                </Box>
                <Box
                  d="grid"
                  gridTemplateColumns="3fr 1fr"
                  mb={{ base: '24px', md: 'unset' }}
                  gridColumn={{ base: 'unset', md: '3/4' }}
                  gridRow={{ base: 'unset', md: '3/4' }}
                >
                  <Text>Remove promocode:</Text>
                  <Switch
                    justifySelf="end"
                    isChecked={formikValues.removePromocode}
                    onChange={handleChange('removePromocode')}
                  />
                </Box>
                <Box
                  d="grid"
                  gridTemplateColumns="3fr 1fr"
                  mb={{ base: '24px', md: 'unset' }}
                  alignSelf="start"
                  gridColumn={{ base: 'unset', md: '3/4' }}
                  gridRow={{ base: 'unset', md: '4/5' }}
                >
                  <Text>Unblock hide:</Text>
                  <Switch
                    justifySelf="end"
                    isChecked={formikValues.unlockHide}
                    onChange={handleChange('unlockHide')}
                  />
                </Box>
                <Box
                  gridRow={{ base: 'unset', md: '5/6' }}
                  gridColumn={{ base: 'unset', md: '1/4' }}
                >
                  <ApplyExperienceList keyName="new-holiday" />
                </Box>
              </ModalBody>
            </>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  )
}
