import {
  ACCEPT_CHANGES,
  CLONE_ELEMENT_FOR_EDIT,
  SET_DATA,
  SET_EDIT,
  SET_NEW_MODE,
  SET_PROPERTY_VALUE,
  SET_REQUIRED_PROPS
} from '../constants'

export const setData = (payload) => ({ type: SET_DATA, payload })
export const setPropertyValue = (payload) => ({
  type: SET_PROPERTY_VALUE,
  payload
})
export const cloneElementForEdit = (payload) => ({
  type: CLONE_ELEMENT_FOR_EDIT,
  payload
})
export const acceptChanges = (payload) => ({ type: ACCEPT_CHANGES, payload })
export const setEdit = (payload) => ({ type: SET_EDIT, payload })
export const setNewMode = (payload) => ({ type: SET_NEW_MODE, payload })
export const setRequiredProps = (payload) => ({
  type: SET_REQUIRED_PROPS,
  payload
})
