import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Timer from '../Timer'
import ButtonBlue from '../../common/ButtonBlue'
import ButtonGray from '../../common/ButtonGray'
import FormikInputMask from '../../common/FormikComponents/FormikInputMask'
import ArrowRight from '../../common/Icons/ArrowRight'
import EyeIcon from '../../common/Icons/EyeIcon'
import { useNotification } from '../../common/NotificationService'
import { waiverWidgetSendSms, waiverWidgetValidateSms } from '../../../api'

export default function EnterPhoneNumber() {
  const history = useHistory()
  const toast = useNotification()
  const {
    values: {
      loadedBooking = {},
      phoneNumber = '',
      phoneNumberMasked = '',
      technicalNumber,
      phoneNumberAccepted
    } = {},
    setFieldValue,
    resetForm
  } = useFormikContext()
  const [smsCode, setSmsCode] = useState('')
  const [acceptingBlock, setAcceptingBlock] = useState(false)
  const [disableResendBtn, setDisableResendBtn] = useState(true)
  const [tempExists, setTempExists] = useState(false)
  // const {mutate: sendSms} = useWaiverWidgetSendSms();
  // const {mutate: validateSms} = useWaiverWidgetValidateSms();
  const [inputType, setInputType] = useState('password')
  const bookingId = loadedBooking?.id
  const waiverId = loadedBooking?.waiver_id

  function onChangeCode(e) {
    setSmsCode(e.target.value)
  }

  useEffect(() => {
    if (!phoneNumberAccepted && smsCode) {
      setSmsCode('')
      setAcceptingBlock(false)
    }
  }, [phoneNumberAccepted])

  function onChangeInputType() {
    if (inputType === 'password') setInputType('text')
    if (inputType === 'text') setInputType('password')
  }

  async function onClickNext() {
    if (phoneNumber === '+12222222222') {
      setAcceptingBlock(true)
      setFieldValue('technicalNumber', true)
    } else {
      const toSend = {
        phoneNumber: phoneNumber,
        bookingId,
        waiverId
      }
      await waiverWidgetSendSms(toSend, {
        onSuccess: (data) => {
          setAcceptingBlock(true)
          setTempExists(data.exists)
        },
        onError: (err) => {
          const message = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          toast({
            title: 'Error',
            message,
            msgObjects
          })
        }
      })
    }
  }

  useEffect(() => {
    if (technicalNumber && acceptingBlock) {
      setSmsCode('2222222222')
      setTimeout(() => {
        document.querySelector('#confirm').click()
      }, 0)
    }
  }, [technicalNumber, acceptingBlock])

  useEffect(() => {
    const phone = phoneNumberMasked.replace(/[^0-9]/g, '')
    setFieldValue('phoneNumber', '+1' + phone)
  }, [phoneNumberMasked])

  function timerCb() {
    setDisableResendBtn(false)
  }

  function changePhoneNumber() {
    setAcceptingBlock(false)
    setDisableResendBtn(true)
  }

  async function resendSmsHandler() {
    await onClickNext()
    setDisableResendBtn(true)
  }

  async function confirmHandler() {
    const toSend = {
      phoneNumber: phoneNumber,
      bookingId,
      waiverId,
      smsCode
    }

    await waiverWidgetValidateSms(toSend, {
      onSuccess: (data) => {
        let profiles
        if (Array.isArray(data?.profiles)) {
          profiles = data?.profiles
        } else {
          profiles = data?.profiles?.map((profile) => {
            return {
              ...profile,
              waiver: {
                ...profile.waiver,
                minors: JSON.parse(profile?.waiver?.minors)
              }
            }
          })
        }

        if (data.status === 'skip' || data.status === true) {
          setFieldValue('exists', tempExists)
          setFieldValue('phoneNumberAccepted', true)
          setFieldValue('profiles', profiles)
          setFieldValue('smsCode', smsCode.trim())
        } else {
          toast({
            title: 'Error',
            message: 'Invalid code'
          })
        }
      },
      onError: (err) => {
        const msg = err?.response?.data?.msg
        const msgObjects = err?.response?.data
        toast({
          title: 'Error',
          msgObjects,
          message: msg
        })
      }
    })
  }

  function onClickBack() {
    history.push('/waiver-widget')
    setSmsCode('')
    resetForm()
  }

  return (
    <>
      <div
        className={`waiver-widget--enter-phone-number ${
          acceptingBlock ? 'hide' : 'show'
        }`}
      >
        <span className="waiver-widget--title-text">
          Please enter your phone number
        </span>
        <FormikInputMask
          name="phoneNumberMasked"
          placeholder="Phone"
          mask={'(999)-999-9999'}
        />
        <div className="waiver-widget--buttons-container">
          <ButtonGray onClick={onClickBack} icon={<ArrowRight />}>
            Back
          </ButtonGray>
          <ButtonBlue onClick={onClickNext} icon={<ArrowRight />}>
            Next
          </ButtonBlue>
        </div>
      </div>
      <div
        className={`waiver-widget--enter-sms-code ${
          acceptingBlock ? 'show' : 'hide'
        }`}
      >
        <span className="waiver-widget--title-text">Confirmation Code</span>
        <div className="waiver-widget--input-container">
          <input
            className="formik-input"
            value={smsCode}
            placeholder="code"
            onChange={onChangeCode}
            type={inputType}
          />
          <button
            onClick={onChangeInputType}
            {...(inputType === 'text' && { className: 'checked' })}
          >
            <EyeIcon />
          </button>
        </div>
        {!phoneNumberAccepted && (
          <button
            className={`waiver-widget--timer-button ${
              disableResendBtn ? '' : 'enabled'
            }`}
            onClick={resendSmsHandler}
          >
            {disableResendBtn ? (
              <>
                Resend after:&nbsp;
                {acceptingBlock && <Timer seconds={61} cb={timerCb} />}
                &nbsp;sec
              </>
            ) : (
              <>Resend code</>
            )}
          </button>
        )}
        <div className="waiver-widget--buttons-container">
          <ButtonGray onClick={changePhoneNumber} icon={<ArrowRight />}>
            Change phone
          </ButtonGray>
          <ButtonBlue
            onClick={confirmHandler}
            id="confirm"
            disabled={!smsCode}
            icon={<ArrowRight />}
          >
            Confirm
          </ButtonBlue>
        </div>
      </div>
    </>
  )
}
