import { useMutation, useQuery } from 'react-query'

import {
  applyEmailBaseTemplate,
  applyEmailBaseTemplateChanges,
  deleteEmailBaseTemplate,
  getEmailBaseTemplate,
  getEmailBaseTemplates,
  getEmailBaseTemplatesLocations,
  newEmailBaseTemplate,
  patchEmailBaseTemplate
} from '../../api/Settings/notification'

export function useEmailBaseTemplates(queryOption = {}) {
  return useQuery(['emailBaseTemplates'], () => getEmailBaseTemplates(), {
    ...queryOption
  })
}

export function useEmailBaseTemplate(emailBaseTemplateId, queryOption = {}) {
  return useQuery(
    ['emailBaseTemplate', +emailBaseTemplateId],
    () => getEmailBaseTemplate(emailBaseTemplateId),
    {
      ...queryOption
    }
  )
}

export function useDeleteEmailBaseTemplate(options = {}) {
  return useMutation(({ obj }) => deleteEmailBaseTemplate(obj), {
    ...options
  })
}

export function useNewEmailBaseTemplate(options = {}) {
  return useMutation(({ obj }) => newEmailBaseTemplate(obj), {
    ...options
  })
}

export function usePatchEmailBaseTemplate(options = {}) {
  return useMutation(({ obj }) => patchEmailBaseTemplate(obj), {
    ...options
  })
}

export function useEmailBaseTemplatesLocations(queryOption = {}) {
  return useQuery(
    ['emailBaseTemplatesLocations'],
    () => getEmailBaseTemplatesLocations(),
    {
      ...queryOption
    }
  )
}

export function useApplyEmailBaseTemplateChanges(options = {}) {
  return useMutation(({ obj }) => applyEmailBaseTemplateChanges(obj), {
    ...options
  })
}

export function useApplyEmailBaseTemplate(options = {}) {
  return useMutation(({ obj }) => applyEmailBaseTemplate(obj), {
    ...options
  })
}
