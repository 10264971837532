import {
  Button,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { useAttractionsExperience } from '../../../../../../libs/hooks/useAttractionsExperience'
import { useDeleteEvent } from '../../../../../../libs/hooks/useEvents'
import { useExperiences } from '../../../../../../libs/hooks/useExperiences'
import { deleteLocationModalValidation } from '../../../../../../configs/validation/deleteLocationModalValidation'
import {
  matchMedia768InState,
  matchMedia1440InState,
  PERMISSION_EVENT,
  userInState
} from '../../../../../../constants'
import FormikDropdown from '../../../../../Common/FormikComponents/FormikDropdown'
import PermissionRequired from '../../../../../Common/PermissionRequired'

export default function DeleteAttractionsModal({
  name = '',
  disabled = false,
  eventId
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const queryClient = useQueryClient()
  const attractionPermissionAccess = PermissionRequired(PERMISSION_EVENT, true)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const { data: { results: experience = [] } = {} } = useExperiences()
  const user = useSelector(userInState)
  const userLocation = user?.locations || []
  const { data: experienceByEvent = [] } = useAttractionsExperience(eventId, {
    enabled: !!eventId
  })
  const protectDelete = useMemo(() => {
    const arr = []
    if (experienceByEvent.length !== 0) {
      experienceByEvent.forEach((exp) => {
        arr.push(exp.id)
      })
    }
    return arr
  }, [experienceByEvent])

  const optionsExperience = useMemo(() => {
    const options = []
    if (experience?.length !== 0) {
      for (let i = 0; i < experience.length; i++) {
        const expLocationId = experience[i].event.location
        if (
          userLocation?.includes(expLocationId) &&
          !protectDelete.includes(experience[i].id)
        ) {
          options.push({
            value: experience[i].id,
            label: experience[i].name
          })
        }
      }
      return options
    } else {
      return []
    }
  }, [experience, protectDelete])

  const { mutate: deleteEvent } = useDeleteEvent()

  function deleteHandler(values) {
    deleteEvent(
      {
        obj: {
          eventId: eventId,
          eventvariantId: values.experience.value
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['events'])
          queryClient.invalidateQueries(['experience'])
          queryClient.invalidateQueries(['event', +eventId])
          onClose()
        }
      }
    )
  }

  const formik = useFormik({
    initialValues: {
      experience: { value: '', label: '' }
    },
    validationSchema: deleteLocationModalValidation,
    onSubmit: deleteHandler
  })
  const formikHandleSubmit = formik.handleSubmit
  const resetForm = formik.resetForm
  const formikValues = formik.values

  useEffect(() => {
    if (formikValues.experience.value !== '' && isOpen) {
      resetForm()
    }
  }, [isOpen])
  return (
    <>
      {!isLargerThan1440 && isLargerThan768 ? (
        <IconButton
          aria-label="delete-gate"
          color="custom.gray.200"
          variant="funcColumnIconButtons"
          onClick={onOpen}
          isDisabled={disabled || !attractionPermissionAccess}
          icon={<CrosshairIcon w="inherit" h="inherit" />}
        />
      ) : (
        <Button
          variant={isLargerThan1440 ? 'link' : 'red'}
          color="custom.red.600"
          isDisabled={disabled || !attractionPermissionAccess}
          onClick={onOpen}
        >
          Remove
        </Button>
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
          >
            <ModalHeader mb="8px">Remove attraction</ModalHeader>
            <ModalCloseButton />
            {optionsExperience.length === 0 ? (
              <Text color="custom.black.500" fontSize="14px" mb="16px">
                You can't delete this attraction, because haven't more
                attractions with another experience.
              </Text>
            ) : (
              <>
                <Text
                  color="custom.black.500"
                  fontSize="14px"
                  mb="16px"
                  as="span"
                  d="flex"
                  flexWrap="wrap"
                >
                  You must choose a default experience before delete&nbsp;
                  <Text color="custom.blue.600">{name}</Text>.
                </Text>
                <FormikDropdown
                  main={{
                    options: optionsExperience,
                    placeholder: 'Experience'
                  }}
                  formik={{
                    name: 'experience',
                    id: 'experience'
                  }}
                  chakraProps={{ fontSize: { base: '12px', '2xl': '14px' } }}
                />
              </>
            )}
            <ModalFooter
              d="grid"
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
              mt="16px"
            >
              <Button w="100%" variant="lightBlue" onClick={onClose}>
                Cancel
              </Button>
              <Button
                w="100%"
                variant="red"
                onClick={formikHandleSubmit}
                _hover={false}
                isDisabled={optionsExperience.length === 0}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
