import { api } from '../index'
import { correctionTimezone } from '../../libs'

export async function startGame(bookingId = null) {
  const { data } = await api.post(`api/bookings/${bookingId}/set_started/`)
  return data
}

export async function setNoShow({
  bookingId = null,
  variant = null,
  discount = null
}) {
  try {
    const { data } = await api.post(`api/bookings/${bookingId}/set_no_show/`, {
      id: bookingId,
      discount: discount,
      variant: variant
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function cancelGame(bookingId = null) {
  try {
    const { data } = await api.post(`/api/bookings/${bookingId}/set_cancel/`, {
      id: bookingId,
      cancel: true
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getTimetable({
  variantId,
  players,
  intervalId,
  isPrivate,
  timeStamp
}) {
  const correctedTimestamp = correctionTimezone(timeStamp)

  const { data } = await api.get(
    `/api/eventvariants/${variantId}/schedule/?variant_id=${variantId}&players=${players}&interval_id=${intervalId}&start=${correctedTimestamp}&is_private=${isPrivate}`
  )
  return data
}

export async function getLocations() {
  try {
    const { data } = await api.get('/api/locations/')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getAllLocations() {
  try {
    const { data } = await api.get('/api/locations/')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function editLocation({
  locationId,
  name,
  address,
  linkHref = '',
  linkText = '',
  parkingPhoto = '',
  badFeedbackLink = '',
  goodFeedbackLink = '',
  timezone = 'America/Los_Angeles',
  telegramChatId,
  cityId
}) {
  const toSend = {
    name: name,
    address: address,
    timezone: timezone,
    direction_link_href: linkHref,
    direction_link_text: linkText,
    good_feedback_link: goodFeedbackLink,
    bad_feedback_link: badFeedbackLink,
    telegram_chat_id: telegramChatId,
    city_id: cityId,
    ...((typeof parkingPhoto === 'number' || !parkingPhoto) && {
      parking_instructions: parkingPhoto
    })
  }
  const { data } = await api.patch(`/api/locations/${locationId}/`, toSend)
  return data
}

export async function createLocation({
  name,
  address,
  timezone,
  linkHref = '',
  badFeedbackLink = '',
  goodFeedbackLink = '',
  linkText = '',
  parkingPhoto,
  telegramChatId,
  cityId
}) {
  const { data } = await api.post('/api/locations/', {
    name: name,
    address: address,
    timezone: timezone,
    direction_link_href: linkHref,
    direction_link_text: linkText,
    parking_instructions: parkingPhoto,
    bad_feedback_link: badFeedbackLink,
    telegram_chat_id: telegramChatId,
    city_id: cityId
  })
  return data
}

export async function deleteLocation({ locationId, eventvariantId }) {
  try {
    const { data } = await api.delete(
      `/api/locations/${locationId}/?eventvariant=${eventvariantId}`
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getExperience() {
  try {
    const { data } = await api.get('/api/eventvariants/?ordering=id')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getExperienceById(eventvariantId) {
  try {
    const { data } = await api.get(`/api/eventvariants/${eventvariantId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function rebookBooking({
  bookingId,
  newDate,
  experienceId,
  players,
  intervalId
}) {
  try {
    const { data } = await api.post(`/api/bookings/${bookingId}/rebook/`, {
      id: bookingId,
      date: newDate,
      players: players,
      eventvariant: experienceId,
      interval: intervalId
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function revertToRunning(bookingId) {
  try {
    const { data } = await api.post(`/api/bookings/${bookingId}/revert/`, {
      cancel: false,
      id: +bookingId
    })
    return data
  } catch (e) {
    console.log(e)
  }
}
