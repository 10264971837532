import { Box, Text } from '@chakra-ui/react'
import DataRow from '../../../Common/DataRow'

export default function StatisticsPartnerItem({ partner }) {
  const {
    partner: { name: partnerName = '' } = {},
    total = '',
    used = '',
    amount = '',
    discount = ''
  } = partner
  const amountInDollars = Math.round(amount / 100)
  const discountInDollars = Math.round(discount / 100)
  return (
    <Box p="16px" bg="white">
      <Box d="flex" alignItems="center" mb="4px">
        <Text fontWeight="500" mr="8px">
          Partner:{' '}
        </Text>
        <Text color="custom.gray.900">{partnerName}</Text>
      </Box>
      <Box d="flex">
        <DataRow
          w="auto"
          mr="16px"
          title="Total"
          value={total}
          keyStyle={{ mr: '8px' }}
        />
        <DataRow
          w="auto"
          mr="16px"
          title="Used"
          value={used}
          keyStyle={{ mr: '8px' }}
        />
      </Box>
      <Box d="flex">
        <DataRow
          w="auto"
          mr="16px"
          title="Amount"
          value={amountInDollars}
          keyStyle={{ mr: '8px' }}
        />
        <DataRow
          w="auto"
          mr="16px"
          title="Discount"
          value={discountInDollars}
          keyStyle={{ mr: '8px' }}
        />
      </Box>
    </Box>
  )
}
