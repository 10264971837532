import { Box, IconButton, Input, Text } from '@chakra-ui/react'
import { PencilIcon } from '../../../../../assets/Icons/PencilIcon'
import { CrosshairIcon } from '../../../../../assets/Icons/CrosshairIcon'
import { useState } from 'react'
import { DisketteIcon } from '../../../../../assets/Icons/DisketteIcon'
import {
  useDeletePartner,
  useUpdatePartner
} from '../../../../../libs/hooks/usePartners'
import { useQueryClient } from 'react-query'

export default function PartnerListItem({ item }) {
  const { name = '', id } = item
  const queryClient = useQueryClient()
  const [editMode, setEditMode] = useState(false)
  const [updatedObject, setUpdatedObject] = useState({
    name: name,
    partnerId: id
  })
  const { mutate: deletePartner } = useDeletePartner()
  const { mutate: updatePartners } = useUpdatePartner(updatedObject)

  function onEdit(state) {
    return () => setEditMode(state)
  }

  function onChange(e) {
    setUpdatedObject({
      ...updatedObject,
      name: e.target.value
    })
  }

  function onSave() {
    onEdit(false)()
    updatePartners(
      {},
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(['partners'])
            .then(() => setEditMode(false))
        }
      }
    )
  }

  function deleteHandler() {
    return () => {
      deletePartner(
        {
          obj: {
            partnerId: id
          }
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['partners'])
          }
        }
      )
    }
  }

  return (
    <Box
      p={{ base: editMode ? '8px 16px' : '16px' }}
      bg={editMode ? 'custom.blue.200' : 'white'}
      mb="2px"
      d="flex"
      alignItems="center"
    >
      {editMode ? (
        <Box pr="20px">
          <Input
            variant="gbase"
            value={updatedObject.name}
            onChange={onChange}
          />
        </Box>
      ) : (
        <Text color="custom.black.500" fontSize={{ base: '12px' }}>
          {name}
        </Text>
      )}

      <Box d="flex" ml="auto">
        {editMode ? (
          <>
            <IconButton
              aria-label="edit-partner"
              variant="funcColumnIconButtons"
              icon={<DisketteIcon w="inherit" h="inherit" />}
              w="16px"
              h="16px"
              mr="16px"
              onClick={onSave}
              color="custom.blue.600"
            />
            <IconButton
              aria-label="editAccount"
              variant="funcColumnIconButtons"
              w="16px"
              h="16px"
              onClick={onEdit(false)}
              icon={<CrosshairIcon w="inherit" h="inherit" />}
              color="custom.gray.200"
            />
          </>
        ) : (
          <>
            <IconButton
              aria-label="edit-partner"
              variant="funcColumnIconButtons"
              icon={<PencilIcon w="inherit" h="inherit" />}
              w="16px"
              h="16px"
              mr="16px"
              onClick={onEdit(true)}
              color="custom.gray.200"
            />
            <IconButton
              aria-label="deletePartner"
              onClick={deleteHandler()}
              variant="funcColumnIconButtons"
              w="16px"
              h="16px"
              icon={<CrosshairIcon w="inherit" h="inherit" />}
              color="custom.gray.200"
            />
          </>
        )}
      </Box>
    </Box>
  )
}
