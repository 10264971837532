import CardSection from '../CardElement'
import { Button, useToast } from '@chakra-ui/react'
import { CardElement } from '@stripe/react-stripe-js'
import Preloader from '../Preloader'
import CustomWarningToast from '../CustomWarningToast'

export default function CheckoutForm({
  stripe,
  elements,
  handleSubmitFormik = null,
  setFieldValue = null,
  isLoading = false
}) {
  const toast = useToast()
  const errorToastStripe = {
    id: 'error-stripe-toast',
    text: 'Something went wrong (Stripe).'
  }
  function generateWarningToast({ text, id, incomingText }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 5000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Stripe Error"
              message={incomingText ? incomingText : text}
              close={toast.closeAll}
            />
          )
        })
      }
    }
  }
  async function handleSubmit(event) {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const card = elements.getElement(CardElement)
    const result = await stripe.createPaymentMethod({ type: 'card', card })
    if (result.error) {
      generateWarningToast({
        ...errorToastStripe,
        incomingText: result.error.message
      })()
    } else {
      if (setFieldValue !== null && typeof setFieldValue === 'function') {

        setFieldValue('payData.stripeToken', result.paymentMethod.id)
      }
      if (
        handleSubmitFormik !== null &&
        typeof handleSubmitFormik === 'function'
      ) {
        handleSubmitFormik()
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      {isLoading ? (
        <Preloader />
      ) : (
        <Button
          type="submit"
          variant="blue"
          w="100%"
          mt={{ base: '16px', md: '16px', '2xl': '16px' }}
          fontSize={{ base: '12px', '2xl': '14px' }}
        >
          Pay
        </Button>
      )}
    </form>
  )
}
