import { api } from '../index'

export async function auth({ email, password }) {
  const { data } = await api.post('/api/token/', {
    email,
    password
  })
  return data
}

export async function refreshToken(refreshToken) {
  const data = await api.post(`/api/token/refresh/`, {
    refresh: refreshToken
  })
  return data
}
