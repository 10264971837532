export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_URL_WO_SLASH = process.env.REACT_APP_BASE_URL_WO_SLASH
export const STRIPE_TOKEN =
  'pk_test_51K0FRtKNbWOrNv7NzfSS7ncSG8IhtIsSqr7UKctWOTxX1JOPZafG3w35i6M2GxgMWDQ3iBVO87wH3My0ZCpiuA1N00PGVqD6V8'
export const CALENDAR_REFETCH_TIME = 30 * 1000 //30 sec
export const STATISTICS_PRELOADER_TIME = 10 * 1000 //10 sec
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const COMPLETED = 'COMPLETED'
export const UPCOMING = 'UPCOMING'
export const RUNNING = 'RUNNING'
export const CANCELED = 'CANCELED'
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const SET_BOOKINGS_SORT_LOCATION = 'SET_BOOKINGS_SORT_LOCATION'
export const SET_BOOKINGS_SORT_TIME = 'SET_BOOKINGS_SORT_TIME'
export const SIGN_OUT = 'SIGN_OUT'
export const SET_USER = 'SET_USER'
export const SET_MIN_CALENDAR_DAY = 'SET_MIN_CALENDAR_DAY'
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const SET_BREAKPOINTS_STATE = 'SET_BREAKPOINTS_STATE'
export const SET_STATUS_FILTERS_STATE = 'SET_STATUS_FILTERS_STATE'
export const SET_LOCATION_FILTERS_STATE = 'SET_LOCATION_FILTERS_STATE'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE'
export const SET_ITEMS_TOTAL_COUNT = 'SET_ITEMS_TOTAL_COUNT'
export const SET_SETTINGS_TAB = 'SET_SETTINGS_TAB'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const DELETE_REFRESH_TOKEN = 'DELETE_REFRESH_TOKEN'
export const EDIT_TOKEN = 'EDIT_TOKEN'
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'
export const SET_SELECTED_TAB_SETTINGS = 'SET_SELECTED_TAB_SETTINGS'
export const SET_SELECTED_TAB_STATISTICS = 'SET_SELECTED_TAB_STATISTICS'
export const SET_SELECTED_TAB_DISCOUNTS = 'SET_SELECTED_TAB_DISCOUNTS'
export const SET_SELECTED_TAB_BOOKINGS = 'SET_SELECTED_TAB_BOOKINGS'
export const SET_SELECTED_WAIVER = 'SET_SELECTED_WAIVER'

export const NEW_GAME = 'NEW_GAME'
export const REBOOKING = 'REBOOKING'
export const NO_SHOW = 'NO_SHOW'
export const CANCELLATION = 'CANCELLATION'
export const COMPLETE_GAME = 'COMPLETE_GAME'
export const NEW_MISSED_PROMO = 'NEW_MISSED_PROMO'
export const RESEND_PICTURES = 'RESEND_PICTURES'
export const EMAIL_TEMPLATES = 'EMAIL_TEMPLATES'
export const REMINDER_1 = 'REMINDER_1'
export const REMINDER_2 = 'REMINDER_2'
export const REMINDER_3 = 'REMINDER_3'
export const REQUEST_FEEDBACK = 'REQUEST_FEEDBACK'
export const RESEND_NOTIFICATION = 'RESEND_NOTIFICATION'
export const UNLIMITED_SIGNS = 'UNLIMITED_SIGNS'
export const NEW_COMBO_BOOKING = 'NEW_COMBO_BOOKING'
export const EVENTS_LIST = [
  NEW_GAME,
  REBOOKING,
  { queryParam: NO_SHOW, label: 'No Show (part 1)' },
  CANCELLATION,
  COMPLETE_GAME,
  { queryParam: NEW_MISSED_PROMO, label: 'No Show Promo' },
  RESEND_PICTURES,
  REMINDER_1,
  REMINDER_2,
  REMINDER_3,
  REQUEST_FEEDBACK,
  RESEND_NOTIFICATION,
  UNLIMITED_SIGNS,
  NEW_COMBO_BOOKING
]
export const LOCATIONS_LIST = [EMAIL_TEMPLATES]
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const PERMISSION_BOOKING_SET_CANCEL = 'booking.set_cancel'
export const PERMISSION_BOOKING_DELETE = 'booking.delete'
export const PERMISSION_EVENT = 'event'
export const PERMISSION_PLAYERS = 'players'
export const PERMISSION_USERS = 'users'
export const PERMISSION_LOCATIONS = 'locations'
export const PERMISSION_CUT_OFF = 'cut_off_time'
export const PERMISSION_NOTIFICATIONS = 'notifications'
export const PERMISSION_STATISTICS = 'statistics'
export const PERMISSION_LIMITATIONS = 'limitations'
export const PERMISSION_DISCOUNTS = 'discounts'
export const PERMISSION_CASH_VIEW = 'cash.view'
export const PERMISSION_CASH_EDIT = 'cash.create.upd.del'
export const PERMISSION_GIFTCARDS = 'giftcards'
export const PERMISSION_COMPANIES = 'companies'
export const PHONE_INPUT_MASK = '(999)-999-9999'
export const PAGE_ERROR_MESSAGE = 'Invalid page.'

// const for newOrder reducer
export const ADD_PREBOOKING = 'ADD_PREBOOKING'
export const SET_TO_EMPTY = 'SET_TO_EMPTY'
export const DELETE_ELEM_FROM_PREBOOKINGS = 'DELETE_ELEM_FROM_PREBOOKINGS'

// const for ethernet watcher reducer
export const CHANGE_ETHERNET_STATUS = 'CHANGE_ETHERNET_STATUS'

//// page paths ////
export const BOOKINGS_URL = '/bookings'
export const COMBOS_URL = '/combos'
export const TICKETS_URL = '/tickets'
export const CALENDAR_URL = '/calendar'
export const BOOKING_URL = '/booking'
export const SETTINGS_URL = '/settings'
export const NEW_ORDER_URL = '/new-order'
export const PLAYERS_URL = '/players'
export const PLAYER_URL = '/player'
export const LOGIN_URL = '/login'
export const MAP_URL = '/map'
export const SETTING_EXPERIENCE_URL = SETTINGS_URL + '/experience'
export const SETTING_COMBO_URL = SETTINGS_URL + '/combo'
export const SETTINGS_WAIVER_URL = SETTINGS_URL + '/waiver'
export const WAIVER_WIDGET_URL = '/waiver-widget'
export const WAIVER_FORM_URL = '/waiver/:id'
export const PURCHASE_RECEIPT_URL = '/purchase-receipt/:orderId'
export const RESET_PASSWORD_EMAIL_URL = '/email'
export const RESET_PASSWORD_RESTORE_URL = '/reset'
export const CASH_URL = '/cash'
export const CUT_OFF_URL = '/cut-off'
export const STATISTICS_URL = '/statistics'
export const GIFT_CARDS_URL = '/gift-cards'
export const DISCOUNTS_URL = '/discounts'
export const CREATE_PROMOCODE_URL = '/create-promocode'
export const PROMO_PAGE_URL = '/promo-page'
export const EDIT_PROMOCODE_URL = '/edit-promocode'
export const LIMITATIONS_URL = '/limitations'
export const USERS_ROLES_URL = SETTINGS_URL + '/users-roles'

//// selectors app ////
export const menuIsOpenInState = (state) => state.app.menuIsOpen
export const authTokenInState = (state) => state.app.authToken
export const userInState = (state) => state.app.user
export const filtersInState = (state) => state.app.filters
export const locationFilterInState = (state) => state.app.filters.location
export const paginationInState = (state) => state.app.pagination
export const statusValueInState = (state) => state.app.filters.status.value
export const locationValueInState = (state) => state.app.filters.location.value
export const settingsTabInState = (state) => state.app.settingsTab
export const statisticsTabInState = (state) => state.app.selectedTabs.statistics
export const discountsTabInState = (state) => state.app.selectedTabs.discounts
export const permissionsInState = (state) => state.app.permissions
export const bookingsSortByLocInState = (state) => state.app.bookingsSortByLoc
export const bookingsSortByTimeInState = (state) => state.app.bookingsSortByTime
export const minCalendarDayInState = (state) => state.app.minCalendarDay

//// selector resolutionWatcher ////
export const matchMediaInState = (state) => state.resWatcher.matchMedia
export const matchMedia320InState = (state) => state.resWatcher.matchMedia[320]
export const matchMedia768InState = (state) => state.resWatcher.matchMedia[768]
export const matchMedia1440InState = (state) =>
  state.resWatcher.matchMedia[1440]

//// selector newOrder ////
export const preBookingsInState = (state) => state.newOrder

//// selector ethernet ///
export const onlineStatus = (state) => state.ethernetWatcher.online
