import { IconButton } from '@chakra-ui/react'
import { dismissHandler, editHandler } from '../../../Common'
import { CheckMarkIcon } from '../../../../../../../assets/Icons/CheckMarkIcon'
import { CrosshairIcon } from '../../../../../../../assets/Icons/CrosshairIcon'
import { PencilIcon } from '../../../../../../../assets/Icons/PencilIcon'
import DeleteAttractionsModal from '../../../Modals/DeleteAttractionsModal'
import React from 'react'
import {
  clonedElementInState,
  editInState,
  newModeInState,
  useAttractionsDispatch,
  useAttractionsSelector
} from '../../../localState/constants'
import { setNewMode } from '../../../localState/actions'

export default function AttractionIconButtons({
  row,
  saveEdit,
  attractionPermissionAccess,
  createNew
}) {
  const dispatch = useAttractionsDispatch()
  const edit = useAttractionsSelector(editInState)
  const newMode = useAttractionsSelector(newModeInState)
  const clonedEl = useAttractionsSelector(clonedElementInState)
  const disableSave = !!clonedEl['location']
  function onCancelHandler() {
    if (newMode) {
      dispatch(setNewMode(false))
      dismissHandler(dispatch)()
    } else {
      dismissHandler(dispatch)()
    }
  }
  function onSaveHandler() {
    if (newMode) {
      createNew()
    } else {
      saveEdit(row.original.id)
    }
  }
  return edit.enabled && +row.id === edit.idCell ? (
    <>
      <IconButton
        aria-label="edit-gate"
        color="custom.blue.600"
        variant="funcColumnIconButtons"
        mr="12px"
        onClick={onSaveHandler}
        isDisabled={!disableSave}
        icon={<CheckMarkIcon w="14px" h="14px" minH="unset" minW="unset" />}
      />
      <IconButton
        aria-label="delete-gate"
        color="custom.red.600"
        variant="funcColumnIconButtons"
        onClick={onCancelHandler}
        icon={<CrosshairIcon w="inherit" h="inherit" />}
      />
    </>
  ) : (
    <>
      <IconButton
        aria-label="edit-gate"
        color="custom.gray.200"
        variant="funcColumnIconButtons"
        mr="12px"
        onClick={editHandler(row.id, edit, dispatch)}
        isDisabled={
          (edit.enabled && +row.id !== edit.idCell) ||
          !attractionPermissionAccess
        }
        icon={<PencilIcon w="14px" h="14px" minH="unset" minW="unset" />}
      />
      <DeleteAttractionsModal
        name={row.original.name}
        disabled={edit.enabled}
        eventId={+row.original.id}
      />
    </>
  )
}
