import { useEffect, useState } from 'react'
import { breakpoints } from '../../configs'
import { useDispatch } from 'react-redux'
import { setMatchMedia } from '../../actions'

export default function ResolutionWatcher() {
  const dispatch = useDispatch()
  const [isLargerThan, setIsLargerThan] = useState(breakpointsState())

  function breakpointsState() {
    const isLargerThan = {}
    breakpoints.forEach((breakpoint) => {
      const queryMatch = `(min-width: ${breakpoint}px)`
      const { matches } = window.matchMedia(queryMatch)
      isLargerThan[breakpoint] = matches
    })
    return isLargerThan
  }

  useEffect(() => {
    const mediaQueryList = breakpoints.map((breakpoint) =>
      window.matchMedia(`(min-width: ${breakpoint}px`)
    )

    function change() {
      setIsLargerThan(breakpointsState())
    }

    mediaQueryList.forEach((query) => query.addEventListener('change', change))

    return () =>
      mediaQueryList.forEach((query) =>
        query.addEventListener('change', change)
      )
  }, [])

  useEffect(() => {
    dispatch(setMatchMedia(isLargerThan))
  }, [isLargerThan])

  return null
}
