import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { useContext, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { friendlyTime, generateWarningToast } from '../../../../libs'
import { useExperiences } from '../../../../libs/hooks/useExperiences'
import { useRemovePlayers } from '../../../../libs/hooks/useRemovePlayers'
import { BookingContext } from '../../../../pages/Booking'
import CustomPlayersInput from '../../../Common/CustomPlayersInput'
import DataRow from '../../../Common/DataRow'

export default function RemovePlayersModal() {
  const toast = useToast()
  const [bookingData, _isLoading, filters, setFilters, queryKeysBooking] =
    useContext(BookingContext)
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id: bookingId } = useParams()
  const { mutate: removePlayers } = useRemovePlayers()
  const { data: { results: experience = [] } = {} } = useExperiences()
  const experienceId = bookingData?.eventvariant?.id || ''
  const experienceForThisBooking = useMemo(() => {
    if (isOpen && experienceId && Object.keys(bookingData)?.length !== 0) {
      for (let i = 0; i < experience.length; i++) {
        if (experienceId === experience[i].id) {
          return experience[i]
        }
      }
    } else return {}
  }, [experienceId, bookingData, isOpen])
  const players = bookingData?.players
  const minPlayers = experienceForThisBooking.min_players
  const canAddMorePlayers =
    players < experienceForThisBooking.max_players || false
  const bookingLocation = experienceForThisBooking?.event?.location
  const startTime = bookingData?.start_time
  const locationName = bookingData?.event?.location?.name
  const eventvariantName = bookingData?.eventvariant?.name
  const [futurePlayers, setFuturePlayers] = useState(players)
  const data = [
    { key: 'Game', value: friendlyTime(startTime, 'MM.DD.YY') },
    { key: 'Start time', value: friendlyTime(startTime, 'h:mm A') },
    { key: 'Location', value: locationName },
    { key: 'Experience', value: eventvariantName },
    { key: 'Players', value: players },
    {
      key: 'Max players for experience',
      value: experienceForThisBooking?.max_players
    },
    {
      key: 'Min players for experience',
      value: experienceForThisBooking?.min_players
    }
  ]

  function onChangePlayersHandler(val) {
    setFuturePlayers(val)
  }

  function clickHandler() {
    removePlayers(
      {
        obj: {
          bookingId,
          players: futurePlayers
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeysBooking).then(() => onClose())
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          generateWarningToast({
            id: 'remove-player',
            toast,
            msgObjects,
            message: msg
          })
        }
      }
    )
  }

  const isButtonEnabled = 0 < futurePlayers && futurePlayers < players

  return (
    <>
      <Button onClick={onOpen} variant="red">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          Remove Players
        </Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent maxW={{ base: '288px', md: '348px', '2xl': '427px' }}>
          <ModalHeader>Remove players</ModalHeader>
          <ModalCloseButton as={CrosshairIcon} />
          <ModalBody>
            <Box
              d="flex"
              flexDir={{ base: 'column', md: 'row' }}
              flexWrap="wrap"
            >
              {data.map((dataRow, idx) => (
                <DataRow
                  title={dataRow.key}
                  key={'modalAdd' + idx}
                  value={dataRow.value}
                  w={{ base: '100%', md: 'unset' }}
                  mb={{ base: '4px', '2xl': '8px' }}
                  keyStyle={{
                    mr: { base: 'unset', md: '8px' }
                  }}
                  valueStyle={{
                    mr: { base: 'unset', md: '16px' }
                  }}
                />
              ))}
              <Box
                d="grid"
                gridTemplateColumns="1fr 1fr"
                gridGap="16px"
                mt={{ base: '8px', md: '16px' }}
                fontSize={{ base: '12px', '2xl': '14px' }}
              >
                <Box
                  bg="custom.lightGray"
                  d="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color="custom.blue.600">Players: {players}</Text>
                </Box>
                <CustomPlayersInput
                  disableButton
                  initialValue={players}
                  minValue={minPlayers}
                  maxValue={players}
                  cb={onChangePlayersHandler}
                />
                <Button variant="lightBlue" onClick={onClose}>
                  Close
                </Button>
                <Button
                  variant="blue"
                  isDisabled={!isButtonEnabled}
                  onClick={clickHandler}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
