import { Box, IconButton, Text, useTheme } from '@chakra-ui/react'
import { PlusIcon } from '../../../assets/Icons/PlusIcon'
import { useExperiences } from '../../../libs/hooks/useExperiences'
import { CrosshairIcon } from '../../../assets/Icons/CrosshairIcon'
import { useEffect, useState } from 'react'

export default function ExperienceApplyList({
  cb = null,
  readOnly = false,
  initialState = [],
  label = '',
  error = '',
  onChange,
  ...rest
}) {
  const changeHandler = onChange || cb
  const theme = useTheme()
  const customColor = theme.colors.custom
  const { data: { results: experience = [] } = {}, isLoading } =
    useExperiences()
  const [checkedExp, setCheckedExp] = useState(initialState)

  function isChecked(id) {
    return checkedExp.includes(id)
  }

  function toggleHandler(id: string) {
    return () => {
      setCheckedExp((state) => {
        if (state.includes(id)) return state.filter((_id) => id !== _id)
        return [...state, id]
      })
    }
  }

  useEffect(() => {
    if (changeHandler !== null && typeof changeHandler === 'function')
      changeHandler(checkedExp)
  }, [checkedExp])

  return (
    <Box {...rest}>
      {label && (
        <Text
          as="span"
          fontSize={{ base: '18px', '2xl': '24px' }}
          fontWeight="500"
        >
          {label}
        </Text>
      )}
      <Box
        d={{ md: 'flex' }}
        flexWrap={{ md: 'wrap' }}
        w="100%"
        overflow={{ base: 'auto' }}
        maxH={isLoading ? { base: '0px' } : { base: '430px' }}
        transition={{ base: '0.7s' }}
        mt={{ base: '8px', md: '16px' }}
      >
        {experience?.length !== 0 &&
          experience?.map((el, idx) => {
            return (
              <Box
                bg={
                  error
                    ? 'custom.red.20'
                    : isChecked(el.id)
                    ? 'custom.blue.600'
                    : 'custom.lightGray'
                }
                border={
                  isChecked(el.id)
                    ? 'custom.lightGray'
                    : `1px solid ${customColor.borderBlue}`
                }
                color={isChecked(el.id) ? 'white' : 'custom.blue.600'}
                borderRadius="20px"
                d="grid"
                gridTemplateColumns="3fr 1fr"
                p="0 0 0 14px"
                minH="40px"
                key={'applyList' + idx}
                w={{ md: '180px' }}
                mb={{ base: '8px', '2xl': '16px' }}
                mr={{ base: 'unset', md: '8px', '2xl': '16px' }}
                alignItems="center"
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {el?.name}
                </Text>
                {!readOnly && (
                  <IconButton
                    aria-label="delete-applied"
                    variant="funcColumnIconButton"
                    w="12px"
                    h="12px"
                    _focus={false}
                    onClick={toggleHandler(el.id)}
                    icon={
                      isChecked(el.id) ? (
                        <CrosshairIcon w="inherit" h="inherit" />
                      ) : (
                        <PlusIcon w="inherit" h="inherit" />
                      )
                    }
                    justifySelf="end"
                  />
                )}
              </Box>
            )
          })}
        {experience?.length === 0 && (
          <Text color="custom.gray.300" m="0 auto">
            Empty
          </Text>
        )}
      </Box>
    </Box>
  )
}
