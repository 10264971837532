import {
  Box,
  Button,
  IconButton,
  Input,
  Switch,
  Text,
  useToast
} from '@chakra-ui/react'
import { CrosshairIcon } from '../../../../../../../assets/Icons/CrosshairIcon'
import ButtonsToggle from '../../../../../../Common/ButtonsToggle'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  matchMedia768InState,
  PERMISSION_EVENT
} from '../../../../../../../constants'
import { friendlyTime, generateWarningToast } from '../../../../../../../libs'
import { usePatchPriceInterval } from '../../../../../../../libs/hooks/usePriceIntervals'
import { useDeletePriceInterval } from '../../../../../../../libs/hooks/useSchedules'
import PermissionRequired from '../../../../../../Common/PermissionRequired'
import Preloader from '../../../../../../Common/Preloader'

export default function ScheduleItem({
  id = '',
  pricingType = '',
  startTime = '',
  endTime = '',
  hide = false,
  onRequest = true,
  eventVariantId = '',
  weekday = '',
  cutOffTimeSwitch,
  cutOffTimeValue,
  invalidatePriceIntervals
}) {
  const toast = useToast()
  const dayTime = ['morning', 'evening', 'weekend']
  const schedulesPermission = PermissionRequired(PERMISSION_EVENT, true)
  const { mutate: deletePriceInterval } = useDeletePriceInterval()
  const { mutate: patchPriceInterval, isLoading: patchInProgress } =
    usePatchPriceInterval()
  const [deleteMode, setDeleteMode] = useState(false)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [isLoading, setIsLoading] = useState(false)
  const [timeInput, setTimeInput] = useState(
    cutOffTimeValue ? +cutOffTimeValue : 0
  )
  const initialState = useMemo(() => {
    if (isLoading) setIsLoading(false)
    return {
      priceIntervalId: id,
      pricingType: pricingType,
      startTime: startTime,
      endTime: endTime,
      hide: hide,
      onRequest: onRequest,
      cutOfTimeInput: cutOffTimeValue ? cutOffTimeValue : 0,
      cutOfTimeCheckbox: cutOffTimeSwitch
    }
  }, [
    id,
    pricingType,
    startTime,
    endTime,
    hide,
    onRequest,
    cutOffTimeValue,
    cutOffTimeSwitch
  ])

  const [state, setState] = useState(initialState)

  function initialStateSw() {
    switch (state.pricingType) {
      case 'M': {
        return 'morning'
      }

      case 'E': {
        return 'evening'
      }

      case 'W': {
        return 'weekend'
      }

      default:
        return 'morning'
    }
  }

  function onClickDelete(state) {
    return () => setDeleteMode(state)
  }

  function onChangeDaySwitcher(prop) {
    return (day) =>
      setState({
        ...state,
        [prop]: day?.slice(0, 1)?.toUpperCase()
      })
  }

  function onChangeInput(e) {
    if (
      typeof +e.target.value === 'number' &&
      +e.target.value >= 0 &&
      Number.isInteger(+e.target.value)
    ) {
      setTimeInput(e.target.value)
    }
  }

  function onBlurTimeInput() {
    setState({
      ...state,
      cutOfTimeInput: +timeInput
    })
  }

  const needUpdate = useMemo(() => {
    if (!isLoading) {
      return Object.keys(state).some((key) => state[key] !== initialState[key])
    } else return false
  }, [state, initialState, isLoading])

  useEffect(() => {
    if (needUpdate && !isLoading) {
      setIsLoading(true)
      patchPriceInterval(
        { obj: { ...state, eventVariantId: eventVariantId } },
        {
          onSuccess: invalidatePriceIntervals,
          onError: (err) => {
            const msg = err?.response?.data
            setState(initialState)
            setIsLoading(false)
            setTimeInput(cutOffTimeValue ? +cutOffTimeValue : 0)
            generateWarningToast({
              msgObjects: msg,
              toast,
              id: 'schedules-patch-error-' + id
            })
          }
        }
      )
    }
  }, [needUpdate])

  function onChangeCheckbox(prop) {
    return (e) =>
      setState({
        ...state,
        [prop]: e.target.checked
      })
  }

  function acceptDeleting() {
    const objToSend = {
      eventVariantId: eventVariantId,
      priceIntervalId: id
    }
    deletePriceInterval(
      {
        obj: objToSend
      },
      {
        onSuccess: () => {
          setDeleteMode(false)
          invalidatePriceIntervals()
        }
      }
    )
  }

  return deleteMode ? (
    <Box p="20px 16px 16px" bg="custom.blue.200" mb="2px">
      <Box d="flex" mb="16px">
        <Text
          fontSize={{ base: '18px' }}
          fontWeight="500"
          color="custom.black.500"
        >
          Delete {friendlyTime(startTime, 'h:mm A')} -{' '}
          {friendlyTime(endTime, 'h:mm A')}?
        </Text>
        <IconButton
          aria-label="delete-schedule-item"
          variant="funcColumnIconButtons"
          w="10px"
          h="10px"
          ml="auto"
          color="custom.gray.200"
          disabled={true}
          icon={<CrosshairIcon w="inherit" h="inherit" />}
        />
      </Box>
      <Box d="grid" gridTemplateColumns="1fr 1fr" gridGap="8px">
        <Button variant="white" onClick={onClickDelete(false)}>
          Cancel
        </Button>
        <Button variant="red" onClick={acceptDeleting}>
          Delete
        </Button>
      </Box>
    </Box>
  ) : (
    <Box
      p={{ base: '20px 16px 16px', md: '16px', '2xl': '16px 24px' }}
      bg="white"
      mb="2px"
      d="grid"
      gridColumnGap="8px"
      gridRowGap="16px"
      gridTemplateColumns={{ base: 'repeat(3, 1fr)', md: '2fr 3fr 4fr 1fr' }}
      gridTemplateRows={{ base: 'auto', md: '1fr 1fr' }}
      alignItems="center"
      pos="relative"
    >
      {isLoading && (
        <Preloader
          pos="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          m="0"
          opacity="0.6"
          h="auto"
          zIndex="2"
          cyclic={true}
        />
      )}
      <Text
        fontSize={{ base: '18px', md: '12px', '2xl': '14px' }}
        gridColumn={{ base: '1/3', md: '1/2' }}
        gridRow={{ base: 'auto', md: '1/2' }}
        fontWeight={{ base: '500', md: '400' }}
        color={{ base: 'custom.blue.600', md: 'custom.black.500' }}
      >
        {isLargerThan768
          ? `${friendlyTime(startTime, 'h:mm A')}
                            -
                             ${friendlyTime(endTime, 'h:mm A')}`
          : `${friendlyTime(startTime, 'h:mm A')}`}
      </Text>
      <IconButton
        aria-label="delete-schedule-item"
        variant="funcColumnIconButtons"
        w="10px"
        h="10px"
        ml="auto"
        gridColumn={{ base: '3/4', md: '4/5' }}
        gridRow={{ base: 'auto', md: '1/2' }}
        color="custom.red.600"
        isDisabled={!schedulesPermission}
        onClick={onClickDelete(true)}
        icon={<CrosshairIcon w="inherit" h="inherit" />}
      />
      <Box
        d="flex"
        gridColumn={{ base: '1/4', md: '2/3' }}
        gridRow={{ base: '2/3', md: '1/2' }}
        alignItems="center"
        width="100%"
        justifyContent={{ base: 'unset', md: 'center' }}
      >
        {isLargerThan768 && (
          <Text
            mr={{ base: '4px', md: '8px', '2xl': '16px' }}
            fontSize={{ '2xl': '14px' }}
          >
            Pricing:
          </Text>
        )}
        <ButtonsToggle
          data={dayTime}
          isDisabled={!schedulesPermission || patchInProgress}
          cb={onChangeDaySwitcher('pricingType')}
          initialState={initialStateSw()}
          unCheckedColor={'custom.lightGray'}
          mb={{ base: '16px', md: 'unset' }}
          w={{ base: '100%', md: '120px' }}
          small={isLargerThan768}
        />
      </Box>

      <Box
        d="grid"
        gridTemplateColumns="1fr 1fr"
        gridGap="8px"
        gridRow={{ base: '4/5', md: '2/3' }}
        gridColumn={{ base: '1/4', md: '3/4' }}
        fontSize={{ base: '12px', '2xl': '14px' }}
        alignItems="center"
      >
        <Box
          d="flex"
          gridColumn="1/2"
          justifyContent={{ base: 'unset', md: 'end' }}
          fontSize={{ base: '12px', '2xl': '14px' }}
        >
          <Text>Cut off time:</Text>
          <Switch
            ml="8px"
            isChecked={state.cutOfTimeCheckbox}
            isDisabled={patchInProgress}
            onChange={onChangeCheckbox('cutOfTimeCheckbox')}
          />
        </Box>
        {state.cutOfTimeCheckbox && (
          <Input
            variant="gbase"
            gridColumn="2/3"
            placeholder="Cut off time"
            value={timeInput}
            isDisabled={patchInProgress}
            onChange={onChangeInput}
            onBlur={onBlurTimeInput}
            m={{ md: '0 auto' }}
            textAlign="center"
            w={{ base: '70px', md: '59%' }}
          />
        )}
      </Box>

      <Box
        d="grid"
        gridTemplateColumns="1fr 1fr"
        gridGap="8px"
        gridColumn={{ base: '1/4', md: '3/4' }}
        gridRow={{ base: '3/4', md: '1/2' }}
        fontSize={{ base: '12px', '2xl': '14px' }}
        alignItems="center"
      >
        <Box d="flex" justifyContent={{ base: 'unset', md: 'end' }}>
          <Text>On request:</Text>
          <Switch
            ml="8px"
            isChecked={state.onRequest}
            isDisabled={!schedulesPermission || patchInProgress}
            onChange={onChangeCheckbox('onRequest')}
          />
        </Box>
        <Box d="flex" justifyContent={{ base: 'unset', md: 'center' }}>
          <Text>Hide:</Text>
          <Switch
            ml="8px"
            isChecked={state.hide}
            isDisabled={!schedulesPermission || patchInProgress}
            onChange={onChangeCheckbox('hide')}
          />
        </Box>
      </Box>
    </Box>
  )
}
