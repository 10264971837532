import {
  Box,
  Button,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../assets/Icons/DisketteIcon'
import { PlusIcon } from '../../../../assets/Icons/PlusIcon'
import {
  convertTime12hTo24h,
  correctionTimezone,
  friendlyTimeWithTZ,
  generateWarningToast
} from '../../../../libs'
import { useNewDowntime } from '../../../../libs/hooks/useNewDowntime'
import { addDowntimeValidation } from '../../../../configs/validation/addDowntimeValidation'
import DatePickerModal from '../../../Booking/Modals/DatePickerModal'
import ApplyExperienceList from '../../../Common/ApplyExperienceList'
import ButtonsToggle from '../../../Common/ButtonsToggle'
import FormikScheduleInput from '../../../Common/FormikComponents/FormikScheduleInput'
import PseudoInput from '../../../Common/PseudoInput'

export default function AddDowntimeModal() {
  const theme = useTheme()
  const toast = useToast()
  const queryClient = useQueryClient()
  const customColors = theme.colors.custom
  const { id } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: newDowntime } = useNewDowntime()
  const formik = useFormik({
    initialValues: {
      startDate: new Date(),
      startDateMillis: new Date().valueOf(),
      endDate: new Date(),
      endDateMillis: new Date().valueOf(),
      startTimeAmPm: 'AM',
      endTimeAmPm: 'PM',
      start: '',
      end: '',
      applyTo: []
    },
    validationSchema: addDowntimeValidation,
    onSubmit: (values) => onSubmitHandler(values)
  })

  const resetForm = formik.resetForm

  function onSubmitHandler(values) {
    const convertedTimeFrom = convertTime12hTo24h(
      `${values.start} ${values.startTimeAmPm}`
    )
    const convertedTimeTo = convertTime12hTo24h(
      `${values.end} ${values.endTimeAmPm}`
    )
    const [effectiveDateFromHours, effectiveDateFromMinutes] =
      convertedTimeFrom.split(':')
    const [effectiveDateToHours, effectiveDateToMinutes] =
      convertedTimeTo.split(':')
    const effectiveDateFrom = values.startDate
    const effectiveDateTo = values.endDate
    const timeFromToConvert = new Date(
      effectiveDateFrom.getUTCFullYear(),
      effectiveDateFrom.getMonth(),
      effectiveDateFrom.getDate(),
      effectiveDateFromHours,
      effectiveDateFromMinutes
    )
    const timeToToConvert = new Date(
      effectiveDateTo.getUTCFullYear(),
      effectiveDateTo.getMonth(),
      effectiveDateTo.getDate(),
      effectiveDateToHours,
      effectiveDateToMinutes
    )
    const toSend = {
      effectiveDateFrom: correctionTimezone(
        Math.round(timeFromToConvert.valueOf() / 1000),
        true
      ),
      effectiveDateTo: correctionTimezone(
        Math.round(timeToToConvert.valueOf() / 1000),
        true
      ),
      applyTo: values.applyTo
    }
    newDowntime(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['downtimes'])
          resetForm()
          onClose()
        },
        onError: (err) => {
          const msg = err?.response?.data
          generateWarningToast({
            msgObjects: msg,
            toast,
            id: 'add-downtime-modal'
          })
        }
      }
    )
  }

  const formikValues = formik.values
  const formikErrors = formik.errors
  const formikTouched = formik.touched
  const endTimeTouched = formikTouched.hasOwnProperty('endDateMillis')
  const endTimeError = formikErrors.hasOwnProperty('endDateMillis')
  const startTimeError = formikErrors.hasOwnProperty('startDateMillis')
  const startTimeTouched = formikTouched.hasOwnProperty('startDateMillis')
  const startTimeHaveError = startTimeError && startTimeTouched
  const endTimeHaveError = endTimeError && endTimeTouched
  const setFieldTouched = formik.setFieldTouched
  const startDate = formikValues.startDate
  const endDate = formikValues.endDate
  const setFieldValue = formik.setFieldValue
  const handleSubmit = formik.handleSubmit

  function datePickerHandler(prop, millisProp) {
    return (val) => {
      if (!endTimeTouched) {
        setFieldTouched(millisProp, true)
      }
      handleChange(prop)(val)
      handleChange(millisProp)(val.valueOf())
    }
  }

  function handleChange(prop) {
    return (val) => setFieldValue(prop, val)
  }

  return (
    <>
      <Button onClick={onOpen} variant="blue">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          <PlusIcon mr="8px" /> New downtime
        </Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
          >
            <ModalHeader
              mb="16px"
              d="flex"
              justifyContent="space-between"
              flex="1"
              flexDir={{ base: 'column', md: 'row' }}
              fontSize={{ base: '18px', '2xl': '24px' }}
            >
              <Text mb={{ base: '8px', md: 'unset' }}>New downtime</Text>
              <Box d="grid" gridTemplateColumns="1fr 1fr" gridGap="16px">
                <Button
                  variant="blue"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={handleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  variant="red"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={onClose}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <ModalBody mb="16px">
              <Box
                d="grid"
                gridTemplateColumns={{ base: '1fr', md: '115px 136px 160px' }}
                gridGap="16px"
                mb="16px"
                alignItems="center"
              >
                <Text
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  color="custom.gray.900"
                >
                  {' '}
                  Start date/time:
                </Text>
                <InputGroup w={{ base: '100%', md: '136px' }} mr="16px">
                  <PseudoInput
                    w="100%"
                    bg={
                      startTimeHaveError ? 'custom.red.20' : 'custom.lightGray'
                    }
                    border={`1px solid ${customColors.borderBlue}`}
                    value={friendlyTimeWithTZ(startDate, 'MM.DD.YY')}
                  />
                  <InputRightElement>
                    <DatePickerModal
                      selectedDate={startDate}
                      cb={datePickerHandler('startDate', 'startDateMillis')}
                      skipInitial={true}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup maxW={{ base: '100%', md: '180px' }}>
                  <FormikScheduleInput
                    name="start"
                    id="start"
                    placeholder="HH:MM"
                    color="black"
                    bg="custom.lightGray"
                  />
                  <InputRightElement width="80px">
                    <ButtonsToggle
                      data={['AM', 'PM']}
                      unCheckedColor="custom.lightGray"
                      cb={handleChange('startTimeAmPm')}
                      w="100%"
                      initialState={formikValues.startTimeAmPm}
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Box
                d="grid"
                gridTemplateColumns={{ base: '100%', md: '115px 136px 160px' }}
                gridGap="16px"
                mb="16px"
                alignItems="center"
              >
                <Text
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  color="custom.gray.900"
                >
                  {' '}
                  End date/time:
                </Text>
                <InputGroup w={{ base: '100%' }} mr="16px">
                  <PseudoInput
                    w="100%"
                    bg={endTimeHaveError ? 'custom.red.20' : 'custom.lightGray'}
                    border={`1px solid ${customColors.borderBlue}`}
                    value={friendlyTimeWithTZ(endDate, 'MM.DD.YY')}
                  />
                  <InputRightElement>
                    <DatePickerModal
                      selectedDate={endDate}
                      cb={datePickerHandler('endDate', 'endDateMillis')}
                      skipInitial={true}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup maxW={{ base: '100%', md: '180px' }}>
                  <FormikScheduleInput
                    name="end"
                    id="end"
                    placeholder="HH:MM"
                    color="black"
                    bg="custom.lightGray"
                  />
                  <InputRightElement width="80px">
                    <ButtonsToggle
                      data={['AM', 'PM']}
                      unCheckedColor="custom.lightGray"
                      cb={handleChange('endTimeAmPm')}
                      w="100%"
                      initialState={formikValues.endTimeAmPm}
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>
              <ApplyExperienceList />
              <Box
                d={{ base: 'grid', md: 'none' }}
                mt="8px"
                gridTemplateColumns="1fr 1fr"
                gridGap="16px"
              >
                <Button
                  variant="blue"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={handleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  variant="red"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={onClose}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
