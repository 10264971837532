import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useRadio,
  useRadioGroup,
  useTheme
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { GoSearch } from 'react-icons/all'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { resetPagination } from '@/libs'
import { useComboBookings } from '@/libs/hooks/useComboBookings'

import { H1 } from '@/components/Text/Heading'

import {
  authTokenInState,
  locationFilterInState,
  matchMedia768InState
} from '../../constants'
import ButtonBlue from '@/features/waiver-widget/components/common/ButtonBlue'
import FormikInput from '@/components/Common/FormikComponents/FormikInput'
import { api } from '@/api'
import { useNotification } from '@/features/waiver-widget/components/common/NotificationService'

export default function TicketsPage() {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const authToken = useSelector(authTokenInState)

  const paginationResetId = 'booking-pagination-id'
  const notify = useNotification()
  const theme = useTheme()
  const [status, setStatus] = useState('all')
  const [search, setSearch] = useState('')

  const { value: locationId } = useSelector(locationFilterInState)
  const [paginationState, setPaginationState] = useState({
    page: 1,
    pageSize: 15,
    totalCountItems: ''
  })

  const {
    data: { results: comboBookings = [], count: total = '' } = {},
    isLoading,
    refetch: dataBookingRefetch
  } = useComboBookings(
    {
      page: paginationState?.page,
      page_size: paginationState?.pageSize,
      status,
      search: search,
      location: locationId
    },
    {
      enabled: !!authToken,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  useEffect(() => {
    resetPagination(paginationResetId)
  }, [status])

  return (
    <Box
      p={{
        base: '24px 16px 82px',
        md: '48px 24px 82px',
        '2xl': '66px 48px 82px'
      }}
    >
      {isLargerThan768 && (
        <Box d="flex" alignItems={'baseline'} mb="32px">
          <Link
            color={'custom.blue.600'}
            fontSize={24}
            to={'/bookings'}
            as={RouterLink}
          >
            Bookings
          </Link>
          <Link
            color={'custom.blue.600'}
            fontSize={24}
            mx="3"
            to={'/combos'}
            as={RouterLink}
          >
            Combos
          </Link>
          <H1>Tickets</H1>
        </Box>
      )}
      <Box
        bg={{ base: 'none', md: 'white' }}
        p={{ base: '0', md: '16px' }}
        mt={{ base: '0', md: '2px' }}
        borderColor="custom.lightGray"
      >
        <Formik
          initialValues={{ code: '' }}
          onSubmit={(data) => {
            api
              .delete(`/api/redeem-ticket/${data.code}/`)
              .then((response) => notify.success({ title: 'Code redeemed!' }))
              .catch((error) =>
                notify({
                title: 'Failed to redeem code!',
                message: ERROR_CODES[error.response.status].replace('%s', data.code)
              })
              )
          }}
        >
          <Form>
            <Box
              d="flex"
              flexDir={{ base: 'column', md: 'row' }}
              maxW={{ base: '360px' }}
            >
              <FormikInput
                placeholder="Enter ticket code to redeem"
                name="code"
              />
              <Button
                type="submit"
                variant="gbase"
                fontWeight="400"
                fontSize="14"
                px="6"
              >
                Redeem
              </Button>
            </Box>
          </Form>
        </Formik>
        <Box pl="2" pt="1" color="custom.gray.300">
          Caution! You can't undo redeem after button was pressed
        </Box>
      </Box>
    </Box>
  )
}

const ERROR_CODES = {
  404: 'Ticket with code %s not found',
  400: "Ticket %s already redeemed"
}