import { useQuery } from 'react-query'

import { getAllBookingsByOrder } from '../../api/booking'

export function useAllBookingsByOrder(orderId = null, queryOption = {}) {
  return useQuery(
    ['bookingsByOrder', +orderId],
    () => getAllBookingsByOrder({ orderId: orderId }),
    {
      ...queryOption,
      cacheTime: orderId === null ? 25 : 300000
    }
  )
}
