import React, { useMemo, useState } from 'react'
import {
  COLUMNS_WITH_SORTING_PROMO_TABLE,
  TABLE_COLUMN_VALUES_BOOKINGS_STATISTICS_PROMO_PAGE
} from '../../../../configs'
import { Text, useTheme } from '@chakra-ui/react'
import { TABLE_COLUMNS_SIZES_COMMENTS } from '../../../../configs/tablesSizes'
import { friendlyTimeWithTZ } from '../../../../libs'
import { Link } from 'react-router-dom'
import { BOOKING_URL } from '../../../../constants'
import GBaseTag from '@/components/Common/GBaseTag'
import TableCreator from '@/components/Common/TableCreator'

export default function PromoPageTable({ tableData, tableName = 'comments' }) {
  const theme = useTheme()
  const customsColor = theme.colors.custom

  const [tableFilter, setTableFilter] = useState({
    header: '',
    sort: ''
  })

  const data = useMemo(() => {
    let sortedArray = tableData
    if (tableFilter.sort === '') return tableData

    if (tableFilter.header === 'location') {
      if (tableFilter.sort === 'up') {
        return sortedArray.sort((a, b) => {
          const nameA = a?.location?.toLowerCase()
          const nameB = b?.location?.toLowerCase()
          return nameA?.localeCompare(nameB)
        })
      }
      if (tableFilter.sort === 'down') {
        return sortedArray.sort((a, b) => {
          const nameA = a?.location?.toLowerCase()
          const nameB = b?.location?.toLowerCase()
          return nameB?.localeCompare(nameA)
        })
      }
    }
  }, [tableData, tableFilter])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '16px 10px',
      letterSpacing: '-0.1px',
      textAlign: 'center'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_BOOKINGS_STATISTICS_PROMO_PAGE.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor,
        minWidth: field.minWidth
      }

      if (field.Header === 'code') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return (
            <Link to={`${BOOKING_URL}/${original.id}`}>
              <Text
                ml="auto"
                color="custom.blue.600"
                textDecoration="underline"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                #{value}
              </Text>
            </Link>
          )
        }
      }

      if (field.Header === 'game date') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return <>{friendlyTimeWithTZ(original.start_time, 'MM.DD.YY')}</>
        }
      }

      if (field.Header === 'start time') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return <>{friendlyTimeWithTZ(value, 'h:mm A')}</>
        }
      }

      if (field.Header === 'status') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return (
            <>
              <GBaseTag fontSize="inherit" m="0 auto" label={value} />
            </>
          )
        }
      }

      return fieldObject
    })
  }, [tableFilter])

  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sortingColumns={COLUMNS_WITH_SORTING_PROMO_TABLE}
      sizesMap={TABLE_COLUMNS_SIZES_COMMENTS}
      tableFilter={tableFilter}
      setTableFilter={setTableFilter}
    />
  )
}
