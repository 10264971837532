import React, { useMemo } from 'react'
import { Box, Button, Text, useTheme } from '@chakra-ui/react'
import {
  TABLE_COLUMN_VALUES_CITIES,
  TABLE_COLUMN_VALUES_LOCATIONS
} from '../../../../configs'
import { TABLE_COLUMNS_SIZES_LOCATIONS } from '../../../../configs/tablesSizes'
import TableCreator from '../../../Common/TableCreator'
// import EditLocationModal from '../Modals/EditLocationModal';
// import DeleteLocationModal from '../Modals/DeleteLocationModal';
import { useHistory } from 'react-router-dom'
import { SETTINGS_WAIVER_URL } from '../../../../constants'

export default function CitiesDataView({ tableData = [] }) {
  const history = useHistory()
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const data = useMemo(
    () => tableData?.sort((a, b) => b.name - a.name),
    [tableData]
  )

  function redirectHandler(url) {
    return () => {
      history.push(url)
    }
  }

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 16px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_CITIES.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Accessor.toLowerCase() === 'waiver') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return (
            <Button
              variant="link"
              textDecor="none"
              onClick={redirectHandler(
                `${SETTINGS_WAIVER_URL}/${original?.id}`
              )}
            >
              <Text color="custom.blue.600">Waiver</Text>
            </Button>
          )
        }
      }

      if (field.Accessor.toLowerCase() === 'funccolumn') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return (
            <Box d="flex">
              {/* <EditLocationModal
                locationId={original?.id}
                location={original}
              />
              <DeleteLocationModal
                locationId={original?.id}
                locationName={original?.name}
              /> */}
            </Box>
          )
        }
      }
      return fieldObject
    })
  }, [])
  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sizesMap={TABLE_COLUMNS_SIZES_LOCATIONS}
    />
  )
}
