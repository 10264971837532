import {
  Box,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  useToast
} from '@chakra-ui/react'
import {
  DISCOUNTS_URL,
  matchMedia1440InState,
  matchMedia768InState
} from '../../../constants'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { useHistory } from 'react-router-dom'
import { PlusIcon } from '../../../assets/Icons/PlusIcon'
import ButtonsToggle from '@/components/Common/ButtonsToggle'
import CustomDaySelector from '@/components/Common/CustomDaySelector'
import ExperienceApplyList from '@/components/Common/ExperienceApplyList'
import DatePickerModal from '@/components/Booking/Modals/DatePickerModal'
import { Formik } from 'formik'
import FormikTwoStyledInput from '@/components/Common/FormikComponents/FormikTwoStyledInput'
import FormikDropdown from '@/components/Common/FormikComponents/FormikDropdown'
import { useDispatch, useSelector } from 'react-redux'
import React, { useMemo } from 'react'
import { friendlyTimeWithTZ } from '../../../libs'
import { usePartners } from '../../../libs/hooks/usePartners'
import { createPromoValidation } from '../../../configs/validation/createPromocode'
import { useCreatePromocode } from '../../../libs/hooks/usePromo'
import { useQueryClient } from 'react-query'
import { setSelectedTabDiscounts } from '../../../actions'
import { useTheme } from '@emotion/react'
import CustomWarningToast from '@/components/Common/CustomWarningToast'

export default function CreatePromocode() {
  const toast = useToast()
  const dispatch = useDispatch()
  const history = useHistory()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const errorCreatePromo = {
    id: 'error-create-promocode',
    text: 'Something wrong with creating new promocode.'
  }
  const customColors = theme.colors.custom
  const { data: { results: partners = [] } = {} } = usePartners()
  const { mutate: createPromo } = useCreatePromocode()
  const optionsPartners = useMemo(() => {
    if (partners.length !== 0) {
      return partners.map((part) => {
        return {
          value: part.id,
          label: part.name
        }
      })
    } else {
      return []
    }
  }, [partners])
  const optionsAvailableAllTime = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ]
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const minDate = new Date(2021, 0, 1, 0, 0, 0)

  function goTo(path) {
    return () => history.push(path)
  }

  function generateWarningToast({ text, id, incomingText, msgObject }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 4000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={incomingText ? incomingText : text}
              close={toast.closeAll}
              msgObjects={msgObject}
            />
          )
        })
      }
    }
  }

  function addPartnerHandler() {
    goTo(DISCOUNTS_URL)()
    dispatch(setSelectedTabDiscounts(2))
  }

  function updateArrayValue(setFieldValue, prop) {
    return (array) => setFieldValue(prop, array)
  }

  function setDateHandler(setFieldValue, prop, textProp) {
    return (value) => {
      setFieldValue(prop, value)
      setFieldValue(textProp, friendlyTimeWithTZ(value, 'MM.DD.YY'))
    }
  }

  function onSubmit(values) {
    const toSend = {
      available: values.available,
      code: values.code,
      ...(values.discount &&
        values.typeDiscount === '$' && {
          discountCent: values.discount * 100,
          discountPercent: null
        }),
      ...(values.discount &&
        values.typeDiscount === '%' && {
          discountPercent: values.discount,
          discountCent: null
        }),
      bookingIntervalStart: values.availableAllTime.value
        ? null
        : Math.round(values.bookFrom / 1000),
      bookingIntervalEnd: values.availableAllTime.value
        ? null
        : Math.round(values.bookTo / 1000),
      orderIntervalStart: values.availableAllTime.value
        ? null
        : Math.round(values.playFrom / 1000),
      orderIntervalEnd: values.availableAllTime.value
        ? null
        : Math.round(values.playTo / 1000),
      bookingWeekdays: values.bookingWeekdays,
      orderWeekdays: values.orderWeekdays,
      minPlayers: values.playersMin,
      maxPlayers: values.playersMax,
      eventvariants: values.eventvariants,
      partner: +values.partner.value,
      reason: values.reason
    }

    createPromo(
      { obj: toSend },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['promo'])
          goTo(DISCOUNTS_URL)()
          dispatch(setSelectedTabDiscounts(0))
        },
        onError: (err) => {
          const data = err?.response?.data
          generateWarningToast({ ...errorCreatePromo, msgObject: data })()
        }
      }
    )
  }

  const dataExperienceApplyList = [
    { exp: 'Bloody elbow' },
    { exp: 'Da vinchis’s challenge' },
    { exp: 'Perfumer' }
  ]
  return (
    <Box
      p={{ base: '24px 16px', md: '32px 24px', '2xl': '48px 0 0' }}
      maxW={{ '2xl': '1100px' }}
      m={{ '2xl': '0 auto' }}
    >
      <Button
        variant="ghost"
        fontFamily="inherit"
        p="0"
        w={{ md: '100%', '2xl': 'auto' }}
        justifyContent="start"
        mb="24px"
        onClick={goTo(DISCOUNTS_URL)}
      >
        <Text
          as={'h1'}
          fontSize={{ base: '24px', '2xl': '32px' }}
          fontWeight={{ base: '600' }}
          color="custom.black.500"
          lineHeight="164%"
        >
          <ArrowIcon
            w="auto"
            height="16px"
            mr="16px"
            mb="2px"
            color="custom.gray.200"
          />
          Create promocode
        </Text>
      </Button>

      <Formik
        onSubmit={onSubmit}
        validationSchema={createPromoValidation}
        enableReinitialize={true}
        initialValues={{
          code: '',
          available: '',
          partner: '',
          reason: '',
          discount: '',
          typeDiscount: '$',
          playersMin: '',
          playersMax: '',
          bookFrom: '',
          bookFromText: '',
          bookTo: '',
          bookToText: '',
          playFrom: '',
          playFromText: '',
          playTo: '',
          playToText: '',
          bookingWeekdays: [0, 1, 2, 3, 4, 5, 6],
          orderWeekdays: [0, 1, 2, 3, 4, 5, 6],
          eventvariants: [],
          availableAllTime: optionsAvailableAllTime[1]
        }}
      >
        {({
          isSubmitting,
          values,
          handleSubmit,
          setFieldValue,
          isValid,
          errors,
          touched,
          setTouched
        }) => {
          return (
            <>
              <Box
                bg="white"
                p={{ base: '16px' }}
                d="grid"
                gridTemplateColumns={{ base: '1fr 1fr', md: 'repeat(3, 1fr)' }}
                gridGap={{ base: '8px', md: '16px' }}
                fontSize={{ base: '12px', '2xl': '14px' }}
              >
                <FormikTwoStyledInput
                  name="code"
                  id="code"
                  placeholder="Code"
                  gridColumn={{ base: '1/3', md: '1/2' }}
                />
                <FormikTwoStyledInput
                  name="available"
                  id="available"
                  placeholder="Available"
                  gridColumn={{ base: '1/3', md: '2/3' }}
                />
                <Box d="flex" gridColumn={{ base: '1/3', md: '3/4' }}>
                  <FormikDropdown
                    main={{ options: optionsPartners }}
                    formik={{
                      name: 'partner',
                      id: 'partner'
                    }}
                    chakraProps={{ w: '100%' }}
                    label={isLargerThan1440 ? 'Partner' : ''}
                    selectProps={{
                      styles: {
                        control: {
                          height: isLargerThan1440 ? '56px' : '40px',
                          padding: isLargerThan1440 ? '12px 4px 0' : '0',
                          border: !isLargerThan768 && 'none',
                          background:
                            errors.partner && touched.partner
                              ? `${customColors.red[20]}`
                              : `${customColors.lightGray}`
                        }
                      }
                    }}
                  />
                  <Button
                    variant="likeInput"
                    ml="8px"
                    w="40px"
                    h="100%"
                    onClick={addPartnerHandler}
                  >
                    <PlusIcon color="custom.blue.600" />
                  </Button>
                </Box>
                <Box
                  gridColumn={{ base: '1/3', md: '1/4' }}
                  d="grid"
                  gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
                  gridGap={{ base: '8px', md: '16px' }}
                >
                  <FormikTwoStyledInput
                    name="reason"
                    id="reason"
                    placeholder="Reason"
                  />
                  <Box d="flex">
                    <FormikTwoStyledInput
                      name="discount"
                      id="discount"
                      placeholder="Discount"
                    />
                    <ButtonsToggle
                      data={['$', '%']}
                      initialState={values.typeDiscount}
                      ml="8px"
                      w={{ base: '132px' }}
                      h="100%"
                      cb={(val) => setFieldValue('typeDiscount', val)}
                      unCheckedColor="custom.lightGray"
                    />
                  </Box>
                </Box>
                <Box
                  gridColumn={{ base: '1/3', md: '1/4' }}
                  d="grid"
                  gridTemplateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }}
                  gridGap={{ base: '8px', md: '16px' }}
                >
                  <FormikTwoStyledInput
                    name="playersMin"
                    id="playersMin"
                    placeholder="Players Min"
                  />
                  <FormikTwoStyledInput
                    name="playersMax"
                    id="playersMax"
                    placeholder="Players Max"
                  />
                  <FormikDropdown
                    main={{ options: optionsAvailableAllTime }}
                    formik={{
                      name: 'availableAllTime',
                      id: 'availableAllTime'
                    }}
                    chakraProps={{ w: '100%' }}
                    label={isLargerThan1440 ? 'Available all time' : ''}
                    selectProps={{
                      styles: {
                        control: {
                          height: isLargerThan1440 ? '56px' : '40px',
                          padding: isLargerThan1440 ? '12px 4px 0' : '0',
                          border: !isLargerThan768 && 'none'
                        }
                      }
                    }}
                  />
                </Box>
                <InputGroup gridColumn={{ base: '1/2' }}>
                  <FormikTwoStyledInput
                    id="bookFromText"
                    name="bookFromText"
                    opacity={values.availableAllTime.value ? 0.6 : 1}
                    placeholder="Book from"
                    isDisabled={true}
                  />
                  <InputRightElement h="100%">
                    <DatePickerModal
                      isDisabled={values.availableAllTime.value}
                      minDate={minDate}
                      returnMilliseconds={true}
                      cb={(value) => {
                        setDateHandler(
                          setFieldValue,
                          'bookFrom',
                          'bookFromText'
                        )(value)
                        setDateHandler(
                          setFieldValue,
                          'bookTo',
                          'bookToText'
                        )(value)
                      }}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup gridColumn={{ base: '2/3' }}>
                  <FormikTwoStyledInput
                    id="bookToText"
                    name="bookToText"
                    placeholder="Book to"
                    opacity={values.availableAllTime.value ? 0.6 : 1}
                    isDisabled={true}
                  />
                  <InputRightElement h="100%">
                    <DatePickerModal
                      isDisabled={values.availableAllTime.value}
                      returnMilliseconds={true}
                      minDate={values.bookFrom}
                      cb={setDateHandler(setFieldValue, 'bookTo', 'bookToText')}
                    />
                  </InputRightElement>
                </InputGroup>
                <CustomDaySelector
                  gridColumn={{ base: '1/3', md: '3/4' }}
                  needIdx={true}
                  isDisabled={values.availableAllTime.value}
                  initialState={values.bookingWeekdays}
                  cb={updateArrayValue(setFieldValue, 'bookingWeekdays')}
                />
                <InputGroup gridColumn={{ base: '1/2' }}>
                  <FormikTwoStyledInput
                    id="playFromText"
                    name="playFromText"
                    opacity={values.availableAllTime.value ? 0.6 : 1}
                    placeholder="Play From"
                    isDisabled={true}
                  />

                  <InputRightElement h="100%">
                    <DatePickerModal
                      isDisabled={values.availableAllTime.value}
                      minDate={minDate}
                      returnMilliseconds={true}
                      cb={(value) => {
                        setDateHandler(
                          setFieldValue,
                          'playFrom',
                          'playFromText'
                        )(value)
                        setDateHandler(
                          setFieldValue,
                          'playTo',
                          'playToText'
                        )(value)
                      }}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup gridColumn={{ base: '2/3' }}>
                  <FormikTwoStyledInput
                    id="playToText"
                    name="playToText"
                    opacity={values.availableAllTime.value ? 0.6 : 1}
                    placeholder="Play to"
                    isDisabled={true}
                  />
                  <InputRightElement h="100%">
                    <DatePickerModal
                      returnMilliseconds={true}
                      isDisabled={values.availableAllTime.value}
                      minDate={values.playFrom}
                      cb={setDateHandler(setFieldValue, 'playTo', 'playToText')}
                    />
                  </InputRightElement>
                </InputGroup>
                <CustomDaySelector
                  gridColumn={{ base: '1/3', md: '3/4' }}
                  needIdx={true}
                  isDisabled={values.availableAllTime.value}
                  initialState={values.orderWeekdays}
                  cb={updateArrayValue(setFieldValue, 'orderWeekdays')}
                />
              </Box>
              <Box p={{ base: '16px' }} bg="white">
                <ExperienceApplyList
                  label="Experiences"
                  error={touched.eventvariants && errors.eventvariants}
                  data={dataExperienceApplyList}
                  cb={updateArrayValue(setFieldValue, 'eventvariants')}
                  w="100%"
                  mb="16px"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                />
                <Box
                  d="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridGap="8px"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                >
                  <Button variant="lightBlue" onClick={goTo(DISCOUNTS_URL)}>
                    Cancel
                  </Button>
                  <Button variant="blue" onClick={handleSubmit}>
                    Create
                  </Button>
                </Box>
              </Box>
            </>
          )
        }}
      </Formik>
    </Box>
  )
}
