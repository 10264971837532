import { Box, Text, useTheme } from '@chakra-ui/react'
import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import DeleteGiftcardModal from '../Modals/DeleteGiftcardModal'
import EditGiftCardModal from '../Modals/EditGiftCardModal'
import GBaseTag from '../../Common/GBaseTag'
import TableCreator from '../../Common/TableCreator'
import {
  TABLE_COLUMN_VALUES_GIFT_CARDS_AFTER_1440,
  TABLE_COLUMN_VALUES_GIFT_CARDS_BEFORE_1440
} from '../../../configs'
import {
  TABLE_COLUMNS_SIZES_GIFT_CARDS_AFTER_1440,
  TABLE_COLUMNS_SIZES_GIFT_CARDS_BEFORE_1440
} from '../../../configs/tablesSizes'
import { matchMedia1440InState } from '../../../constants'

export default function GiftCardsTable({ tableData = [] }) {
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const data = useMemo(() => tableData, [tableData])

  const bodyRef = useRef(null)

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: '0 16px'
    },
    tbody: {
      ref: bodyRef,
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 16px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  const columns = useMemo(() => {
    const arrayForCreate = isLargerThan1440
      ? TABLE_COLUMN_VALUES_GIFT_CARDS_AFTER_1440
      : TABLE_COLUMN_VALUES_GIFT_CARDS_BEFORE_1440

    return arrayForCreate.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (
        field.Header.toLowerCase() === 'recipient' ||
        field.Header.toLowerCase() === 'recipient name'
      ) {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return <>{original.recipient_name}</>
        }
      }

      if (field.Header.toLowerCase() === 'buyer') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          const name = original.buyer_name
          const last4 = original.card_last
          const carholder = original.cardholder
          const somethingNotNull = !!last4 || !!carholder || !!name

          if (somethingNotNull) {
            return (
              <Box p="4px 0">
                {!!name && (
                  <Text as="span" fontSize="14px">
                    {name}
                  </Text>
                )}
                {!!last4 && (
                  <Text as="p" fontSize="12px">
                    ****&nbsp;****&nbsp;**** {last4}
                  </Text>
                )}
                {!!carholder && <Text fontSize="12px">{carholder}</Text>}
              </Box>
            )
          } else {
            return <Text color="custom.gray.200">null</Text>
          }
        }
      }

      if (field.Header.toLowerCase() === 'code') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          if (original.code) {
            return (
              <Text color="custom.blue.600" textDecor="underline">
                {original.code}
              </Text>
            )
          } else {
            return <>-</>
          }
        }
      }

      if (field.Header.toLowerCase() === 'total') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          if (original.code) {
            return <>{Math.round(original.total / 100)}</>
          } else {
            return <>-</>
          }
        }
      }

      if (field.Header.toLowerCase() === 'status') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          if (original.status) {
            const color =
              original.status.toLowerCase() === 'used' ? 'red' : 'green'
            return (
              <GBaseTag
                color={color}
                label={original.status}
                p="4px 12px"
                m="0 auto"
              />
            )
          } else {
            return <>-</>
          }
        }
      }

      if (field.Accessor === 'funcColumn') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return (
            <Box d="flex" justifyContent="center">
              <EditGiftCardModal original={original} />
              <DeleteGiftcardModal
                giftcardId={original.id}
                recipientName={original.recipient_name}
              />
            </Box>
          )
        }
      }
      return fieldObject
    })
  }, [isLargerThan1440])

  return (
    <Box mb="60px">
      <TableCreator
        data={data}
        columns={columns}
        styles={tableStyles}
        sizesMap={
          isLargerThan1440
            ? TABLE_COLUMNS_SIZES_GIFT_CARDS_AFTER_1440
            : TABLE_COLUMNS_SIZES_GIFT_CARDS_BEFORE_1440
        }
      />
    </Box>
  )
}
