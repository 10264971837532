import {
  ADD_PREBOOKING,
  DELETE_ELEM_FROM_PREBOOKINGS,
  SET_TO_EMPTY
} from '../../constants'

const initialState = {
  preBookings: [],
  subtotal: 0,
  totalAmount: 0,
  taxPercent: 0,
  feePercent: 0,
  subtotalAmount: 0,
  taxAmount: 0,
  feeAmount: 0,
  stripeAccountId: ''
}

export const newOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PREBOOKING: {
      return {
        ...state,
        preBookings: action.payload.bookings,
        totalAmount: action.payload.total_amount,
        taxPercent: action.payload.tax_percent,
        feePercent: action.payload.fee_percent,
        subtotalAmount: action.payload.subtotal_amount,
        taxAmount: action.payload.tax_amount,
        feeAmount: action.payload.fee_amount,
        stripeAccountId: action.payload.stripe_account_id,
      }
    }

    case SET_TO_EMPTY: {
      return {
        ...state,
        ...initialState
      }
    }

    case DELETE_ELEM_FROM_PREBOOKINGS: {
      const newArray = state.preBookings.filter((item, index) => index !== action.payload)

      return {
        ...state,
        preBookings: newArray
      }
    }

    default:
      return state
  }
}
