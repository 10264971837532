import * as yup from 'yup'

export const newAttractionExperienceModalValidation = yup.object().shape({
  name: yup.string().required(),
  areas: yup.number().positive().required(),
  minPlayers: yup.number().positive().required(),
  maxPlayers: yup.number().positive().required(),
  playersStep: yup.number().positive(),
  ticketMinPlayers: yup.number().positive(),
  flowsMinPlayers: yup.number().positive(),
  flowsMaxPlayers: yup.number().positive(),
  humanUsage: yup.number().positive()
})
