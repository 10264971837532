import {
  Button,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { useDeleteDowntime } from '../../../../libs/hooks/useDeleteDowntime'

export default function DeleteDowntimeModal({ name = '', downtimeId }) {
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: deleteDowntime } = useDeleteDowntime()

  function deleteHoliday() {
    deleteDowntime(downtimeId, {
      onSuccess: () => {
        onClose()
        queryClient.invalidateQueries('downtimes')
      }
    })
  }

  return (
    <>
      <IconButton
        aria-label="edit-holidays"
        variant="funcColumnIconButton"
        w="12px"
        minW="unset"
        ml="16px"
        onClick={onOpen}
        icon={<CrosshairIcon w="inherit" />}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="34px">Delete {name}?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter
            d="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="8px"
            fontSize={{ base: '12px', '2xl': '14px' }}
          >
            <Button w="100%" variant="lightBlue" onClick={onClose}>
              Cancel
            </Button>
            <Button w="100%" variant="red" onClick={deleteHoliday}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
