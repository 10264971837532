import { useMutation, useQuery } from 'react-query'

import {
  createEvent,
  deleteEvent,
  getEvents,
  updateEvents
} from '../../api/Settings/attractions'

export function useEvents(queryOption = {}) {
  return useQuery(['events'], () => getEvents(), {
    ...queryOption
  })
}

export function useUpdateEvent(options = {}) {
  return useMutation(({ obj }) => updateEvents(obj), {
    ...options
  })
}

export function useNewEvent(options = {}) {
  return useMutation(({ obj }) => createEvent(obj), {
    ...options
  })
}

export function useDeleteEvent(options = {}) {
  return useMutation(({ obj }) => deleteEvent(obj), {
    ...options
  })
}
