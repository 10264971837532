import { Box, Button, Text, useToast } from '@chakra-ui/react'
import { loadStripe } from '@stripe/stripe-js'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { addPrebooking, deleteAllPrebookings } from '../../actions'
import {
  convertTZ,
  correctDiscountsPrice,
  correctionPhoneNumber,
  correctionTimezone
} from '../../libs'
import { useAddToOrder } from '../../libs/hooks/useAddToOrder'
import { useExperiences } from '../../libs/hooks/useExperiences'
import { useLocations } from '../../libs/hooks/useLocations'
import { useNewOrder } from '../../libs/hooks/useNewOrder'
import usePrevious from '../../libs/hooks/usePrevious'
import { usePromoAndGift } from '../../libs/hooks/usePromoAndGift'
import { useSlots } from '../../libs/hooks/useSlots'
import { useDefaultStripe } from '../../libs/hooks/useStripe'
import AvailableTime from '@/components/Common/AvailableTime'
import CustomWarningToast from '@/components/Common/CustomWarningToast'
import FormikDiscounts from '@/components/Common/FormikComponents/FormikDiscounts'
import FormikDropdown from '@/components/Common/FormikComponents/FormikDropdown'
import FormikOrderInput from '@/components/Common/FormikComponents/FormikOrderInput'
import FormikPayments from '@/components/Common/FormikComponents/FormikPayments'
import FormikPlayersInput from '@/components/Common/FormikComponents/FormikPlayersInput'
import NewOrderDataPicker from '@/components/Common/NewOrderDatePicker'
import Preloader from '@/components/Common/Preloader'
import NewOrderDiscounts from '@/components/NewOrder/NewOrderDiscounts'
import OrderCards from '@/components/NewOrder/OrderCards'
import { newOrderValidation } from '../../configs/validation/newOrder'
import { PHONE_INPUT_MASK, preBookingsInState } from '../../constants'
import CustomCheckbox from '@/components/Common/CustomCheckbox/index'

export default function NewOrder() {
  const dispatch = useDispatch()
  const toast = useToast()
  const history = useHistory()
  const redirectAfterSuccess = '/bookings'
  const errorToastPlayers = {
    id: 'error-players-toast',
    text: 'Not valid players amount for event variant.'
  }
  const errorToastPromo = {
    id: 'error-players-promo',
    text: 'Promocode has expired or not correct.'
  }
  const errorToastGift = {
    id: 'error-players-gift',
    text: 'Giftcard has expired or not correct.'
  }
  const errorOnCreate = {
    id: 'error-create-order',
    text: 'Something went wrong.'
  }
  const [discounts, setDiscounts] = useState({
    byPromo: 0,
    byGift: 0
  })
  const formik = useFormik({
    initialValues: {
      location: '',
      experience: '',
      players: '',
      minCalendarDay: '',
      date: '',
      promocodeAccepted: false,
      giftcardAccepted: false,
      promocode: '',
      giftcard: '',
      slot: '',
      payData: {
        type: '',
        stripeToken: '',
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        offlineCard: '',
        expDate: '',
        cvc: '',
        transaction: '',
        location: null
      }
    },
    enableReinitialize: true,
    onSubmit: onSubmit,
    validationSchema: newOrderValidation
  })

  const formikValues = formik?.values
  const preselectedLocation = formikValues?.location?.value
  const formikHandleSubmit = formik.handleSubmit
  const formikSetFieldValue = formik.setFieldValue
  const { preBookings, stripeAccountId } = useSelector(preBookingsInState)
  const prevLength = usePrevious(preBookings.length)
  const [stripePromise, setStripePromise] = useState('')
  const { data: { results: locations = [] } = {} } = useLocations()
  const { data: { results: experience = [] } = {} } = useExperiences()
  const { data: { results: defaultStripeObject = [] } = {} } =
    useDefaultStripe()
  const defaultToken = defaultStripeObject[0]?.public
  const isPreBookingsEmpty = preBookings.length === 0
  const { mutate: newOrder, isLoading: isLoadingNewOrder } = useNewOrder()
  const { mutate: addToOrder } = useAddToOrder()
  const { mutate: giftOrPromo, isLoading: giftOrPromoIsLoading } =
    usePromoAndGift()
  const [paramsWasUsed, setParamsWasUsed] = useState(false)
  const optionsLocation = useMemo(() => {
    if (locations?.length !== 0) {
      return locations.map((loc) => {
        return {
          value: loc.id,
          label: loc.name,
          timezone: loc.timezone
        }
      })
    } else {
      return []
    }
  }, [locations])

  const optionsExperience = useMemo(() => {
    const options = []
    if (experience?.length !== 0 && !!formikValues?.location?.value) {
      for (let i = 0; i < experience.length; i++) {
        if (experience[i].event.location === formikValues.location.value) {
          options.push({
            value: experience[i].id,
            label: experience[i].name,
            stripePub: experience[i].stripe_pub,
            fee: experience[i].event.fee,
            minPlayers: experience[i].min_players,
            maxPlayers: experience[i].max_players
          })
        }
      }
      return options
    } else {
      return []
    }
  }, [experience, formikValues?.location?.value])

  useEffect(() => {
    formikSetFieldValue('experience', '')
    if (formikValues?.location?.value) {
      const newDateTz = convertTZ(new Date(), formikValues?.location?.timezone)
      const newDate = new Date(
        moment(newDateTz).year(),
        moment(newDateTz).month(),
        moment(newDateTz).date(),
        moment(newDateTz).hours(),
        moment(newDateTz).minutes()
      )
      formikSetFieldValue('minCalendarDay', newDate)
    }
  }, [formikValues?.location?.value])

  useEffect(() => {
    dispatch(deleteAllPrebookings())
  }, [])

  const isAloneExperienceWasUsed = useMemo(() => {
    if (preBookings.length > 1) {
      const stripeKeys = []
      preBookings.forEach((preBook) => {
        const stripeKey = preBook.stripeKey
        if (!stripeKeys.includes(stripeKey)) {
          stripeKeys.push(stripeKey)
        }
      })
      return stripeKeys.length === 1
    } else {
      return true
    }
  }, [preBookings])

  useEffect(() => {
    async function fetchStripeObject() {
      if (formikValues?.experience && isAloneExperienceWasUsed) {
        if (formikValues?.experience?.stripePub) {
          const stripePromiseLoaded = await loadStripe(
            formikValues?.experience?.stripePub
          )
          setStripePromise('')
          setStripePromise(stripePromiseLoaded)
        } else {
          setStripePromise('')
        }
      }

      if (formikValues?.experience && !isAloneExperienceWasUsed) {
        const stripePromiseLoaded = await loadStripe(defaultToken)
        setStripePromise('')
        setStripePromise(stripePromiseLoaded)
      }
    }

    fetchStripeObject()
  }, [formikValues?.experience, defaultToken, isAloneExperienceWasUsed])

  const objectForCompare = useMemo(() => {
    return {
      date: moment(formikValues?.date).unix(),
      eventvariant: formikValues?.experience?.value,
      interval: formikValues?.slot?.id,
      locationId: formikValues?.location?.value
    }
  }, [
    formikValues?.date,
    formikValues?.experience?.value,
    formikValues?.slot?.id,
    formikValues?.location?.value
  ])

  const readyToAddOrder = useMemo(() => {
    return Object.values(objectForCompare).every((value) => !!value)
  }, [objectForCompare])

  const alreadyHaveSlot = useMemo(() => {
    let res = []

    if (preBookings.length !== 0) {
      for (let i = 0; i < preBookings.length; i++) {
        const result = Object.keys(objectForCompare).every((key) => {
          if (key === 'interval') {
            return preBookings[i][key]['id'] === objectForCompare[key]
          }

          if (key !== 'price') {
            return preBookings[i][key] === objectForCompare[key]
          } else return true
        })
        res.push(result)
      }
    }

    return res.some((val) => !!val)
  }, [preBookings, objectForCompare])

  useEffect(() => {
    if (discounts.byPromo) {
      setDiscounts({
        ...discounts,
        byPromo: 0
      })
      formikSetFieldValue('promocodeAccepted', false)
    }
  }, [formikValues.promocode])

  useEffect(() => {
    if (discounts.byGift) {
      setDiscounts({
        ...discounts,
        byGift: 0
      })
      formikSetFieldValue('giftcardAccepted', false)
    }
  }, [formikValues.giftcard])

  function generateWarningToast({ text, id, incomingText }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 2000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={incomingText ? incomingText : text}
              close={toast.closeAll}
            />
          )
        })
      }
    }
  }

  const {
    data: slots = [],
    refetch: loadSlots,
    error,
    isError
  } = useSlots(
    {
      locationId: formikValues?.location?.value,
      eventVariantId: formikValues?.experience?.value,
      players: formikValues?.players,
      timestamp: formikValues?.date
    },
    {
      enabled: false,
      retry: false,
      onError: generateWarningToast(errorToastPlayers)
    }
  )

  const { search } = useLocation()

  useEffect(() => {
    if (formikValues.slot !== '' && search === '') {
      formikSetFieldValue('slot', '')
    }
  }, [slots])

  useEffect(() => {
    if (search !== '' && !paramsWasUsed) {
      const params = search.replace('?', '').split('&')
      if (params.length === 4 && optionsLocation.length !== 0) {
        const object = {}
        params.forEach((str) => {
          const [key, value] = str.split('=')
          object[key] = +value
        })
        if (object.date.toString().length === 10) {
          let locationValue
          if (optionsLocation.length !== 0 && formikValues.location === '') {
            for (let i = 0; i < optionsLocation.length; i++) {
              if (object.location === optionsLocation[i].value) {
                locationValue = optionsLocation[i]
              }
            }
            formikSetFieldValue('location', locationValue)
          }

          if (
            optionsExperience.length !== 0 &&
            formikValues.location !== '' &&
            formikValues.experience === ''
          ) {
            let experienceValue
            for (let i = 0; i < optionsExperience.length; i++) {
              if (+object.eventvariant === +optionsExperience[i].value) {
                experienceValue = optionsExperience[i]
              }
            }
            formikSetFieldValue('experience', experienceValue)
            formikSetFieldValue('date', object.date)
          }

          if (
            slots.length !== 0 &&
            formikValues.location !== '' &&
            formikValues.experience !== ''
          ) {
            const button = document.querySelector(`#slot-${object.interval}`)
            if (button !== null) {
              button.click()
            }
            setParamsWasUsed(true)
          }
        }
      }
    }
  }, [optionsLocation, optionsExperience, slots, formikValues.slot])

  function createPriceDiscountTypeObject(element) {
    const discounts = {}
    const price = {}
    const objectKeys = Object.keys(element)
    if (objectKeys.length !== 0) {
      objectKeys.forEach((key) => {
        if (key === 'price') {
          price[key] = element[key]
        }
        if (key !== 'price') {
          discounts[key] = element[key]
        }
      })
    }
    return { discounts, price }
  }

  function afterAddToPayment(data) {
    const newElement = {
      date: formikValues?.slot.start,
      players: formikValues?.players,
      eventvariant: formikValues?.experience?.value,
      interval: formikValues?.slot,
      locationId: formikValues?.location?.value,
      stripeKey: formikValues?.experience?.stripePub
    }
    if (!data.bookings.length) return

    const { bookings, ...restResponse } = data
    // if (preBookings.length !== 0) {
    //   const preBookingsWithPrice = preBookings.map((preBooking, idx) => {
    //     const { discounts, price } = createPriceDiscountTypeObject(data[idx])
    //     const correctedDiscounts = correctDiscountsPrice(discounts)
    //     return {
    //       ...preBooking,
    //       ...price,
    //       price: price.price / 100,
    //       discounts: correctedDiscounts
    //     }
    //   })

    //   const correctedDiscounts = correctDiscountsPrice(discounts)
    //   arr = [
    //     ...preBookingsWithPrice,
    //     {
    //       ...newElement,
    //       ...{ ...price, price: data.total_amount / 100 },
    //       discounts: correctedDiscounts
    //     }
    //   ]
    // } else {
    //   const { discounts, price } = createPriceDiscountTypeObject(
    //     data[data.length - 1]
    //   )
    //   const correctedDiscounts = correctDiscountsPrice(discounts)
    //   arr = [
    //     {
    //       ...newElement,
    //       ...price,
    //       price: price.price / 100,
    //       discounts: correctedDiscounts
    //     }
    //   ]
    // }

    dispatch(addPrebooking({ bookings, ...restResponse }))
  }

  function afterDeleteFromPayment(data) {
    if (preBookings.length !== 0) {
      const preBookingsWithPrice = preBookings.map((preBooking, idx) => {
        const { discounts, price } = createPriceDiscountTypeObject(data[idx])
        const correctedDiscounts = correctDiscountsPrice(discounts)
        return {
          ...preBooking,
          ...price,
          price: price.price / 100,
          discounts: correctedDiscounts
        }
      })
      dispatch(addPrebooking(preBookingsWithPrice))
    }
  }

  useEffect(() => {
    const objToValid = {
      locationId: formikValues?.location?.value,
      eventVariantId: formikValues?.experience?.value,
      players: formikValues?.players,
      timestamp: formikValues?.date
    }

    if (Object.values(objToValid).every((value) => !!value)) {
      loadSlots()
    }
  }, [
    formikValues?.location?.value,
    formikValues?.experience?.value,
    formikValues?.players,
    formikValues?.date
  ])

  function submitOrder() {
    const prepare = {
      date: moment(formikValues?.slot.start * 1000).format('YYYY-MM-DD'),
      players: formikValues?.players,
      eventvariant: formikValues?.experience?.value,
      interval: formikValues?.slot?.id
    }

    let arrayToSend
    if (preBookings?.length !== 0) {
      const preBookArray = getBookingsPayload()
      arrayToSend = [...preBookArray, prepare]
    } else {
      arrayToSend = [prepare]
    }

    addToOrder(
      {
        array: arrayToSend,
        promocode: formikValues.promocode,
        giftcard: formikValues.giftcard
      },
      {
        onSuccess: (data) => {
          afterAddToPayment(data)
          if (data.promocode_amount > 0) {
            formikSetFieldValue('promocodeAccepted', true)
            setDiscounts({ ...discounts, byPromo: data.promocode_amount })
          }
          if (data.giftcard_amount > 0) {
            formikSetFieldValue('giftcardAccepted', true)
            setDiscounts({ ...discounts, byGift: data.giftcard_amount })
          }
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          generateWarningToast({ ...errorOnCreate, incomingText: msg })()
        }
      }
    )
  }

  useEffect(() => {
    if (prevLength > preBookings.length) {
      if (preBookings?.length !== 0) {
        addToOrder(
          { array: getBookingsPayload() },
          {
            onSuccess: (data) => {
              afterAddToPayment(data)
              if (data.promocode_amount > 0) {
                formikSetFieldValue('promocodeAccepted', true)
                setDiscounts({ ...discounts, byPromo: data.promocode_amount })
              }
              if (data.giftcard_amount > 0) {
                formikSetFieldValue('giftcardAccepted', true)
                setDiscounts({ ...discounts, byGift: data.giftcard_amount })
              }
            },
            onError: (err) => {
              const msg = err?.response?.data?.msg
              generateWarningToast({ ...errorOnCreate, incomingText: msg })()
            }
          }
        )
        if (formikValues.promocodeAccepted) {
          acceptPromo()
        }

        if (formikValues.giftcardAccepted) {
          acceptGiftcard()
        }
      }
    }
  }, [preBookings])

  function getBookingsPayload() {
    return preBookings?.map((book) => ({
      date: moment(book?.date).format('YYYY-MM-DD'),
      players: book?.players,
      eventvariant: book?.eventvariant,
      interval: book?.interval.id
    }))
  }

  function acceptPromo() {
    if (formikValues?.promocode !== '') {
      const toSend = {
        array: getBookingsPayload(),
        promocode: formikValues?.promocode
      }
      addToOrder(toSend, {
        onSuccess: (data) => {
          setDiscounts({ ...discounts, byPromo: data.promocode_amount })
          afterAddToPayment(data)
          formikSetFieldValue('promocodeAccepted', true)
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          generateWarningToast({ ...errorToastPromo, incomingText: msg })()
        }
      })
    }
  }

  function acceptGiftcard() {
    if (formikValues?.giftcard !== '') {
      const toSend = {
        array: getBookingsPayload(),
        giftcard: formikValues?.giftcard
      }
      addToOrder(
        { ...toSend },
        {
          onSuccess: (data) => {
            setDiscounts({ ...discounts, byGift: data.giftcard_amount })
            afterAddToPayment(data)
            formikSetFieldValue('giftcardAccepted', true)
          },
          onError: (err) => {
            const msg = err?.response?.data?.msg
            generateWarningToast({ ...errorToastGift, incomingText: msg })()
          }
        }
      )
    }
  }

  function onDateChange() {
    return (date) => {
      if (date) {
        formikSetFieldValue('date', Math.round(date.valueOf() / 1000))
      }
    }
  }

  function onSubmit(values) {
    let payment_source =
      values.payData.type === 'free' ? null : values.payData.type
    if (window?.SQUAREUP_TEST) payment_source = 'SquareUp'
    if (values.payData.type === 'walkin') payment_source = 'cash'

    const toSend = {
      bookings: preBookings?.map((book) => {
        return {
          date: moment(book?.date).unix(),
          new_date: moment(book?.date).format('YYYY-MM-DD'),
          players: book?.players,
          eventvariant: book?.eventvariant,
          interval: book?.interval.id
        }
      }),
      firstname: values.payData.firstname,
      lastname: values.payData.lastname,
      phone: correctionPhoneNumber(values.payData.phone, 'send'),
      email: values.payData.email,
      giftcard: values.giftcard,
      promocode: values.promocode,
      payment: {
        payment_source,
        // ...
        ...(values.payData.type === 'stripe' &&
          (window?.SQUAREUP_TEST
            ? { payment_token: 'cnon:card-nonce-ok' }
            : {
                payment_method: values.payData.stripeToken,
                ...(stripeAccountId && { stripe_connect_id: stripeAccountId })
              })),
        ...(values.payData.type === 'offline' && {
          cash_payment_id: values.payData.transaction
        }),
        ...(values.payData.type === 'cash' && {
          location_id: values.payData.location.value
        })
      }
    }

    if (values.payData.type === 'offline') delete toSend.payment.payment_token

    newOrder(
      { obj: toSend },
      {
        onSuccess: () => {
          dispatch(deleteAllPrebookings())
          toast({
            title: 'Order created.',
            description:
              "Order successfully created. Now, you'll be redirected.",
            status: 'success',
            duration: 2000,
            isClosable: true
          })
          setTimeout(() => {
            history.push(redirectAfterSuccess)
          }, 2000)
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          generateWarningToast({ ...errorOnCreate, incomingText: msg })()
        }
      }
    )
  }

  function freeHandler(value) {
    formikSetFieldValue('payData.type', value)
  }

  return (
    <FormikProvider value={formik}>
      <Box
        p={{
          base: '24px 16px 65px',
          md: '48px 24px 65px',
          '2xl': '48px 48px 65px'
        }}
      >
        <Text variant="boldTitle">New order</Text>
        <Box d="flex" flexDir={{ base: 'column', '2xl': 'row' }}>
          <Box
            mr={{ base: 0, '2xl': '16px' }}
            w={{ base: '100%', '2xl': '66%' }}
          >
            <Box
              bg="white"
              p={{ base: '16px', '2xl': '24px' }}
              d="flex"
              flexDir={{ base: 'column', md: 'row' }}
            >
              <Box w={{ base: '100%', md: '50%' }}>
                <Box
                  d="grid"
                  gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
                  gridColumnGap={{ base: '0', md: '16px' }}
                  gridRowGap={{ base: '8px', md: '16px' }}
                  mb={{ base: '8px', md: '0' }}
                  w="100%"
                  mr={{ base: '0', md: '8px' }}
                  alignItems="start"
                >
                  <FormikDropdown
                    main={{ options: optionsLocation, placeholder: 'Location' }}
                    formik={{
                      name: 'location',
                      id: 'location'
                    }}
                    chakraProps={{ fontSize: { base: '12px', '2xl': '14px' } }}
                    gridColumn={{ base: '1/1', md: '1/2' }}
                    gridRow={{ base: '1/2' }}
                  />
                  <FormikDropdown
                    main={{
                      options: optionsExperience,
                      placeholder: 'Experience'
                    }}
                    formik={{
                      name: 'experience',
                      id: 'experience'
                    }}
                    isDisabled={!formikValues?.location}
                    chakraProps={{ fontSize: { base: '12px', '2xl': '14px' } }}
                    gridColumn={{ base: '1/1', md: '1/3' }}
                    gridRow={{ base: '2/3' }}
                  />
                  <FormikPlayersInput
                    name="players"
                    id="players"
                    minValue={formikValues?.experience?.minPlayers}
                    maxValue={formikValues?.experience?.maxPlayers}
                    gridRow={{ base: '3/4', md: '1/2' }}
                    gridColumn={{ base: '1/2', md: '2/3' }}
                  />

                  <NewOrderDataPicker
                    gridRow={{ base: '4/5', md: '3/4' }}
                    gridColumn={{ base: '1/2', md: '1/3' }}
                    w="100%"
                    datepicker={{
                      selected: formikValues.date && formikValues.date * 1000,
                      minDate: formikValues.minCalendarDay
                        ? formikValues.minCalendarDay
                        : new Date()
                    }}
                    cb={onDateChange()}
                  />
                </Box>
              </Box>
              <Box
                w={{ base: '100%', md: '50%' }}
                ml={{ base: '0', md: '16px' }}
              >
                <AvailableTime
                  mb={{ base: '8px', md: '16px' }}
                  data={slots}
                  selectedData={formikValues.date}
                  isObjectComplete={
                    !!formikValues.location &&
                    !!formikValues.experience &&
                    !!formikValues.date
                  }
                  cb={(slot) => formikSetFieldValue('slot', slot)}
                  experience={formikValues.experience}
                />
                <Button
                  variant="blue"
                  w="100%"
                  id="addToOrder"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={submitOrder}
                  _hover={alreadyHaveSlot || !readyToAddOrder}
                  isDisabled={alreadyHaveSlot || !readyToAddOrder}
                >
                  {alreadyHaveSlot
                    ? 'Already have this booking'
                    : 'Add to order'}
                </Button>
              </Box>
            </Box>
            <OrderCards />
          </Box>
          <Box
            ml={{ base: 0, '2xl': '16px' }}
            w={{ base: '100%', '2xl': '31.5%' }}
          >
            <NewOrderDiscounts
              discounts={discounts}
              setFree={freeHandler}
              giftOrPromoIsLoading={giftOrPromoIsLoading}
            >
              <FormikDiscounts
                label="Promocode"
                formik={{ name: 'promocode' }}
                isDisabled={
                  isPreBookingsEmpty || formikValues.promocodeAccepted
                }
                cbClick={acceptPromo}
              />
              <FormikDiscounts
                label="Giftcard"
                formik={{ name: 'giftcard' }}
                isDisabled={isPreBookingsEmpty || formikValues.giftcardAccepted}
                cbClick={acceptGiftcard}
              />
            </NewOrderDiscounts>
            <Box
              bg="white"
              p={{ base: '16px', '2xl': '24px' }}
              d="flex"
              flexDir={{ base: 'column', '2xl': 'column' }}
            >
              <Box w="100%" mr={{ base: 0, md: '8px', '2xl': 0 }}>
                <CustomCheckbox
                  cb={(value) => {
                    if (!value) return formik.resetForm()
                    formik.setValues({
                      payData: {
                        firstname: 'Walk-in',
                        lastname: 'Walk-in',
                        phone: '2229999999',
                        email: 'info@example.com',
                        type: 'walkin'
                      }
                    })
                  }}
                >
                  Walk in
                </CustomCheckbox>
                <FormikOrderInput
                  name="payData.firstname"
                  id="payData.firstname"
                  label="First Name"
                />
                <FormikOrderInput
                  name="payData.lastname"
                  id="payData.lastname"
                  label="Last Name"
                />
                <FormikOrderInput
                  name="payData.phone"
                  id="payData.phone"
                  label="Phone"
                  input={{
                    as: InputMask,
                    mask: PHONE_INPUT_MASK
                  }}
                />
                <FormikOrderInput
                  name="payData.email"
                  id="payData.email"
                  label="E-mail"
                />
              </Box>
              {formikValues.payData.type === 'free' ? (
                isLoadingNewOrder ? (
                  <Preloader />
                ) : (
                  <Button
                    variant="blue"
                    onClick={formikHandleSubmit}
                    fontSize={{ base: '12px', '2xl': '14px' }}
                  >
                    Next
                  </Button>
                )
              ) : (
                <Box
                  w="100%"
                  ml={{ base: 0, md: '8px', '2xl': '0' }}
                  d="flex"
                  flexDir="column"
                >
                  {formikValues.payData.type !== 'walkin' && (
                    <Box mb={{ base: '16px' }}>
                      <FormikPayments
                        name="payData.type"
                        id="payData.type"
                        stripePromise={stripePromise}
                        isLoading={isLoadingNewOrder}
                        experience={formikValues.experience}
                        preselectedLocation={preselectedLocation}
                      />
                    </Box>
                  )}
                  {formikValues.payData.type !== 'stripe' &&
                    (isLoadingNewOrder ? (
                      <Preloader />
                    ) : (
                      <Button
                        type="submit"
                        variant="blue"
                        w="100%"
                        mt={{ base: '0', md: 'auto', '2xl': '0' }}
                        mb={{ base: '0', md: '16px', '2xl': '0' }}
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        onClick={formikHandleSubmit}
                      >
                        Pay
                      </Button>
                    ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </FormikProvider>
  )
}
