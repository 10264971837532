import { useMutation, useQuery } from 'react-query'

import {
  deleteLimitation,
  getLimitation,
  getLimitations,
  newLimitation,
  patchLimitation
} from '../../api/limitations'

export function useLimitations(weekday, queryOption = {}) {
  return useQuery(['limitations', +weekday], () => getLimitations(weekday), {
    ...queryOption
  })
}

export function useLimitation(limitationId, queryOption = {}) {
  return useQuery(
    ['limitation', +limitationId],
    () => getLimitation(limitationId),
    {
      ...queryOption
    }
  )
}

export function usePatchLimitation(options = {}) {
  return useMutation(({ obj }) => patchLimitation(obj), {
    ...options
  })
}

export function useNewLimitation(options = {}) {
  return useMutation(({ obj }) => newLimitation(obj), {
    ...options
  })
}

export function useDeleteLimitation(options = {}) {
  return useMutation(({ obj }) => deleteLimitation(obj), {
    ...options
  })
}
