import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment-timezone'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { convertTZ, correctionTimezone, friendlyTime } from '../../../../libs'
import { useExperiences } from '../../../../libs/hooks/useExperiences'
import { useLocations } from '../../../../libs/hooks/useLocations'
import { useRebookBooking } from '../../../../libs/hooks/useRebookBooking'
import { useSlots } from '../../../../libs/hooks/useSlots'
import { matchMedia768InState, paginationInState } from '../../../../constants'
import { BookingContext } from '../../../../pages/Booking'
import AvailableTime from '../../../Common/AvailableTime'
import CustomWarningToast from '../../../Common/CustomWarningToast'
import DataPicker from '../../../Common/DataPicker'
import DataRow from '../../../Common/DataRow'
import FormikDropdown from '../../../Common/FormikComponents/FormikDropdown'

export default function ReebookModal({
  locationName = '',
  experienceB = '',
  startTime = '',
  players = '',
  type = ''
}) {
  const toast = useToast()
  const pagination = useSelector(paginationInState)
  const queryClient = useQueryClient()
  const [bookingData, isLoading, filters, setFilters, queryKeysBooking] =
    useContext(BookingContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id } = useParams()
  const toastId = 'no-toast'
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [tabIndex, setTabIndex] = useState(0)
  const data = [
    { key: 'Game ', value: friendlyTime(startTime, 'MM.DD.YY') },
    { key: 'Start time', value: friendlyTime(startTime, 'h:mm A') },
    { key: 'Location', value: locationName },
    { key: 'Experience', value: experienceB },
    { key: 'Players', value: players }
  ]
  const { data: { results: locations = [] } = {} } = useLocations()
  const { data: { results: experience = [] } = {} } = useExperiences()
  const { mutate: rebook } = useRebookBooking({
    onSuccess: invalidateAfterSuccessRebook
  })

  const formik = useFormik({
    initialValues: {
      location: '',
      experience: '',
      minCalendarDay: '',
      date: '',
      slot: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const toSend = {
        experienceId: values?.experience?.value,
        intervalId: values?.slot?.id,
        bookingId: +id,
        players: players,
        newDate: correctionTimezone(values?.date, true)
      }

      rebook(
        { obj: toSend },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKeysBooking)
            onClose()
          }
        }
      )
    }
  })
  const formikValues = formik.values
  const setFieldValue = formik.setFieldValue
  const formikHandleSubmit = formik.handleSubmit

  const {
    data: slots,
    refetch: loadSlots,
    isLoading: isLoadingSlots
  } = useSlots(
    {
      locationId: formikValues?.location?.value,
      eventVariantId: formikValues?.experience?.value,
      players: players,
      timestamp: formikValues?.date
    },
    {
      enabled: false,
      retry: false,
      onError: () => {
        if (!toast.isActive(toastId)) {
          toast({
            status: 'warning',
            position: 'bottom-left',
            id: toastId,
            duration: 2000,
            isClosable: true,
            render: () => (
              <CustomWarningToast
                title="Warning"
                message="Not valid players amount for event variant"
                close={toast.closeAll}
              />
            )
          })
        }
      }
    }
  )

  function invalidateAfterSuccessRebook() {
    queryClient.invalidateQueries(queryKeysBooking)
    queryClient.invalidateQueries([
      'bookings',
      pagination.page,
      pagination.pageSize,
      type
    ])
  }

  const optionsLocation = useMemo(() => {
    if (locations.length !== 0) {
      return locations.map((location) => {
        return {
          value: location.id,
          label: location.name,
          timezone: location.timezone
        }
      })
    } else return []
  }, [locations])

  const optionExperience = useMemo(() => {
    const options = []
    if (experience?.length !== 0 && !!formikValues?.location?.value) {
      for (let i = 0; i < experience.length; i++) {
        if (experience[i].event.location === formikValues?.location?.value) {
          options.push({
            value: experience[i].id,
            label: experience[i].name
          })
        }
      }
      return options
    } else {
      return []
    }
  }, [experience, formikValues?.location])

  useEffect(() => {
    setFieldValue('experience', '')
    if (formikValues?.location?.value) {
      const newDateTz = convertTZ(new Date(), formikValues?.location?.timezone)
      const newDate = new Date(
        moment(newDateTz).year(),
        moment(newDateTz).month(),
        moment(newDateTz).date(),
        moment(newDateTz).hours(),
        moment(newDateTz).minutes()
      )
      setFieldValue('minCalendarDay', newDate)
    }
  }, [formikValues?.location])

  useEffect(() => {
    if (isLargerThan768) {
      setTabIndex(0)
    }
  }, [isLargerThan768])

  function toggleTab() {
    if (tabIndex === 0) {
      setTabIndex(1)
    }
    if (tabIndex === 1) {
      setTabIndex(0)
    }
  }

  function onDateChange() {
    return (date) => {
      if (date) {
        setFieldValue('date', Math.round(date.valueOf() / 1000))
      }
    }
  }

  useEffect(() => {
    const objToValid = {
      locationId: formikValues?.location?.value,
      eventVariantId: formikValues?.experience?.value,
      players: players,
      timestamp: formikValues?.date
    }

    if (Object.values(objToValid).every((value) => !!value)) {
      loadSlots()
    }
  }, [
    formikValues?.location?.value,
    formikValues?.experience?.value,
    formikValues?.date
  ])

  const isObjectComplete =
    formikValues?.location?.value &&
    formikValues?.experience?.value &&
    formikValues?.date &&
    formikValues?.slot

  return (
    <>
      <Button onClick={onOpen} variant="lightBlue">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          Rebook
        </Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
          >
            <ModalHeader>Rebook game</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box
                mb={{ base: '16px', '2xl': '18px' }}
                d="flex"
                flexDir={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
              >
                {data.map((dataRow, idx) => (
                  <DataRow
                    title={dataRow.key}
                    key={'modalAdd' + idx}
                    value={dataRow.value}
                    w={{ base: '100%', md: 'unset' }}
                    mb={{ base: '4px', '2xl': '8px' }}
                    keyStyle={{
                      mr: { base: 'unset', md: '8px' }
                    }}
                    valueStyle={{
                      mr: { base: 'unset', md: '16px' }
                    }}
                  />
                ))}
              </Box>

              <Tabs index={tabIndex} variant="gbase">
                <TabPanels>
                  <TabPanel>
                    <Box d={{ md: 'flex' }}>
                      <Box mr={{ md: '8px', '2xl': '12px' }}>
                        <Box
                          d="flex"
                          mb={{ base: '8px', '2xl': '16px' }}
                          fontSize={{ base: '12px', '2xl': '14px' }}
                          flexDir={{
                            base: 'column',
                            md: 'row',
                            '2xl': 'column'
                          }}
                        >
                          <FormikDropdown
                            main={{
                              options: optionsLocation,
                              placeholder: 'Location'
                            }}
                            formik={{
                              name: 'location',
                              id: 'location'
                            }}
                            chakraProps={{
                              width: { md: '100%' },
                              fontSize: 'inherit',
                              mb: { base: '8px', '2xl': '16px' },
                              mr: { md: '8px', '2xl': 'unset' }
                            }}
                          />
                          <FormikDropdown
                            main={{
                              options: optionExperience,
                              placeholder: 'Experience'
                            }}
                            formik={{
                              name: 'experience',
                              id: 'experience'
                            }}
                            chakraProps={{
                              fontSize: 'inherit',
                              width: { md: '100%' },
                              ml: { md: '8px', '2xl': 'unset' }
                            }}
                          />
                        </Box>
                        <DataPicker
                          mb="16px"
                          cb={onDateChange()}
                          selected={formikValues?.date * 1000}
                          datepicker={{
                            minDate: formikValues.minCalendarDay
                          }}
                        />
                      </Box>
                      {isLargerThan768 && (
                        <Box ml={{ md: '8px', '2xl': '12px' }} w="100%">
                          <AvailableTime
                            mb={{ base: '16px' }}
                            cb={(slot) => setFieldValue('slot', slot)}
                            data={slots}
                            selectedData={formikValues?.date}
                            experience={formikValues?.experience}
                            isLoadingTimetable={isLoadingSlots}
                            isObjectComplete={
                              !!formikValues.location &&
                              !!formikValues.experience &&
                              !!formikValues.date
                            }
                          />
                          <Button
                            variant="blue"
                            h={{ base: '40px', '2xl': '54px' }}
                            fontSize={{ base: '12px', '2xl': '14px' }}
                            w="100%"
                            isDisabled={!isObjectComplete}
                            onClick={formikHandleSubmit}
                          >
                            Rebook Game
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <AvailableTime
                      mb={{ base: '16px' }}
                      cb={(slot) => setFieldValue('slot', slot)}
                      data={slots}
                      selectedData={formikValues?.date}
                      experience={formikValues?.experience}
                      isLoadingTimetable={isLoadingSlots}
                      isObjectComplete={
                        !!formikValues.location &&
                        !!formikValues.experience &&
                        !!formikValues.date
                      }
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>

            {!isLargerThan768 && (
              <ModalFooter>
                {tabIndex === 0 && (
                  <Button
                    onClick={toggleTab}
                    variant="blue"
                    w="100%"
                    h={{ base: '40px', '2xl': '56px' }}
                    fontSize={{ base: '12px', '2xl': '14px' }}
                    fontWeight="500"
                  >
                    Show available time
                  </Button>
                )}
                {tabIndex === 1 && (
                  <Box d="flex" w="100%">
                    <Button
                      onClick={toggleTab}
                      variant="lightBlue"
                      w="100%"
                      h={{ base: '40px', '2xl': '56px' }}
                      fontSize={{ base: '12px', '2xl': '14px' }}
                      fontWeight="500"
                      mr="4px"
                    >
                      Back
                    </Button>
                    <Button
                      variant="blue"
                      w="100%"
                      h={{ base: '40px', '2xl': '56px' }}
                      fontSize={{ base: '12px', '2xl': '14px' }}
                      fontWeight="500"
                      ml="4px"
                      isDisabled={!isObjectComplete}
                      onClick={formikHandleSubmit}
                    >
                      Rebook game
                    </Button>
                  </Box>
                )}
              </ModalFooter>
            )}
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
