import AttractionsListItem from '../AttractionsListItem'
import {
  attrDataInState,
  useAttractionsSelector
} from '../localState/constants'
import { Box } from '@chakra-ui/react'
import React from 'react'

export default function AttractionsList({ newElement }) {
  const data = useAttractionsSelector(attrDataInState)
  if (data.length === 0 && !newElement)
    return (
      <Box textAlign="center" m="20px 0" fontSize="12px">
        No results were found for your search.
      </Box>
    )
  return data
    ?.sort((a, b) => a.id - b.id)
    .map((el, idx) => (
      <AttractionsListItem element={el} key={'AttractionsList' + idx} />
    ))
}
