import React, { useMemo } from 'react'

import { Link, Text, useTheme } from '@chakra-ui/react'
import {
  COLUMNS_WITH_SORTING_COMMENTS,
  TABLE_COLUMN_VALUES_PLAYER
} from '../../../configs'
import TableCreator from '../../Common/TableCreator'
import { TABLE_COLUMNS_SIZES_COMMENTS } from '../../../configs/tablesSizes'
import GBaseTag from '../../Common/GBaseTag'
import { friendlyTimeWithTZ } from '../../../libs'

export default function PlayerTable({ tableData = [] }) {
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const data = useMemo(() => tableData, [tableData])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '16px 10px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_PLAYER.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header === 'code') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return (
            <Link href={`/booking/${original.id}`} isExternal _focus={false}>
              <Text
                ml="auto"
                color="custom.blue.600"
                textDecoration="underline"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                #{value}
              </Text>
            </Link>
          )
        }
      }

      if (field.Header === 'game date') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          const value = original?.start_time
          return <>{friendlyTimeWithTZ(value, 'MM.DD.YY')}</>
        }
      }

      if (field.Header === 'start time') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          const value = original?.start_time
          return <>{friendlyTimeWithTZ(value, 'h:mm A')}</>
        }
      }

      if (field.Header === 'status') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return (
            <>
              <GBaseTag fontSize="inherit" m="0 auto" label={value} />
            </>
          )
        }
      }
      return fieldObject
    })
  }, [])
  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sortingColumns={COLUMNS_WITH_SORTING_COMMENTS}
      sizesMap={TABLE_COLUMNS_SIZES_COMMENTS}
    />
  )
}
