import {
  Box,
  Button,
  IconButton,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment-timezone'
import React from 'react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../assets/Icons/DisketteIcon'
import { PencilIcon } from '../../../../assets/Icons/PencilIcon'
import {
  convertTime12hTo24h,
  correctionTimezone,
  friendlyTime,
  generateWarningToast
} from '../../../../libs'
import { useDowntime } from '../../../../libs/hooks/useDowntime'
import { useUpdateDowntime } from '../../../../libs/hooks/useUpdateDowntime'
import { addDowntimeValidation } from '../../../../configs/validation/addDowntimeValidation'
import DatePickerModal from '../../../Booking/Modals/DatePickerModal'
import ApplyExperienceList from '../../../Common/ApplyExperienceList'
import ButtonsToggle from '../../../Common/ButtonsToggle'
import FormikScheduleInput from '../../../Common/FormikComponents/FormikScheduleInput'
import PseudoInput from '../../../Common/PseudoInput'

export default function EditDowntimeModal({ downtimeId }) {
  const theme = useTheme()
  const toast = useToast()
  const queryClient = useQueryClient()
  const customColors = theme.colors.custom
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: updateDowntime } = useUpdateDowntime()
  const formik = useFormik({
    initialValues: {
      startDate: new Date(),
      startDateMillis: new Date().valueOf(),
      endDate: new Date(),
      endDateMillis: new Date().valueOf(),
      startTimeAmPm: 'AM',
      endTimeAmPm: 'PM',
      start: '',
      end: '',
      applyTo: []
    },
    validationSchema: addDowntimeValidation,
    onSubmit: (values) => onSubmitHandler(values)
  })

  const setFields = formik.setValues

  const { data: downtime = {} } = useDowntime(downtimeId, {
    enabled: isOpen,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const startDateMoment = moment(data.effective_date_from * 1000)
      const endDateMoment = moment(data.effective_date_to * 1000)
      const state = {
        startDate: new Date(
          startDateMoment.year(),
          startDateMoment.month(),
          startDateMoment.date(),
          startDateMoment.hour(),
          startDateMoment.minute()
        ),
        endDate: new Date(
          endDateMoment.year(),
          endDateMoment.month(),
          endDateMoment.date(),
          endDateMoment.hour(),
          endDateMoment.minute()
        ),
        startDateMillis: data.effective_date_from * 1000,
        endDateMillis: data.effective_date_to * 1000,
        startTimeAmPm: friendlyTime(data.effective_date_from * 1000, 'A'),
        endTimeAmPm: friendlyTime(data.effective_date_to * 1000, 'A'),
        start: friendlyTime(data.effective_date_from * 1000, 'hh:mm'),
        end: friendlyTime(data.effective_date_to * 1000, 'hh:mm'),
        applyTo: data.apply_to
      }
      setFields(state)
    }
  })

  function onSubmitHandler(values) {
    const convertedTimeFrom = convertTime12hTo24h(
      `${values.start} ${values.startTimeAmPm}`
    )
    const convertedTimeTo = convertTime12hTo24h(
      `${values.end} ${values.endTimeAmPm}`
    )
    const [effectiveDateFromHours, effectiveDateFromMinutes] =
      convertedTimeFrom.split(':')
    const [effectiveDateToHours, effectiveDateToMinutes] =
      convertedTimeTo.split(':')
    const effectiveDateFrom = values.startDate
    const effectiveDateTo = values.endDate
    const timeObjectFrom = new Date(effectiveDateFrom)
    const timeObjectTo = new Date(effectiveDateTo)
    const timeFromToConvert = new Date(
      timeObjectFrom.getUTCFullYear(),
      timeObjectFrom.getMonth(),
      timeObjectFrom.getDate(),
      effectiveDateFromHours,
      effectiveDateFromMinutes
    )
    const timeToToConvert = new Date(
      timeObjectTo.getUTCFullYear(),
      timeObjectTo.getMonth(),
      timeObjectTo.getDate(),
      effectiveDateToHours,
      effectiveDateToMinutes
    )
    const toSend = {
      downtimeId,
      effectiveDateFrom: correctionTimezone(
        Math.round(timeFromToConvert.valueOf() / 1000),
        true
      ),
      effectiveDateTo: correctionTimezone(
        Math.round(timeToToConvert.valueOf() / 1000),
        true
      ),
      applyTo: values.applyTo
    }

    updateDowntime(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['downtimes'])
          onClose()
        },
        onError: (err) => {
          const msg = err?.response?.data
          generateWarningToast({
            msgObjects: msg,
            toast,
            id: 'edit-downtime-modal'
          })
        }
      }
    )
  }

  const formikValues = formik.values
  const startDate = formikValues.startDate
  const endDate = formikValues.endDate
  const setFieldValue = formik.setFieldValue
  const handleSubmit = formik.handleSubmit
  const formikErrors = formik.errors
  const formikTouched = formik.touched
  const endTimeTouched = formikTouched.hasOwnProperty('endDateMillis')
  const endTimeError = formikErrors.hasOwnProperty('endDateMillis')
  const startTimeError = formikErrors.hasOwnProperty('startDateMillis')
  const startTimeTouched = formikTouched.hasOwnProperty('startDateMillis')
  const startTimeHaveError = startTimeError && startTimeTouched
  const endTimeHaveError = endTimeError && endTimeTouched
  const setFieldTouched = formik.setFieldTouched

  function setCorrectionTime(date) {
    const yourOffset = moment(new Date()).utcOffset() * 60 * 1000
    return date - yourOffset
  }

  function datePickerHandler(prop, millisProp) {
    return (val) => {
      if (!endTimeTouched) {
        setFieldTouched(millisProp, true)
      }
      handleChange(prop)(setCorrectionTime(val))
      handleChange(millisProp)(val)
    }
  }

  function handleChange(prop) {
    return (val) => setFieldValue(prop, val)
  }

  const effectiveDateFrom = friendlyTime(
    downtime?.effective_date_from * 1000,
    'MM/DD/YY (h:mm A)'
  )
  const effectiveDateTo = friendlyTime(
    downtime?.effective_date_to * 1000,
    'MM/DD/YY (h:mm A)'
  )

  return (
    <>
      <IconButton
        aria-label="edit-holidays"
        variant="funcColumnIconButton"
        w="12px"
        minW="unset"
        icon={<PencilIcon w="inherit" />}
        onClick={onOpen}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
          >
            <ModalHeader
              mb="16px"
              d="flex"
              justifyContent="space-between"
              flexDir={{ base: 'column', md: 'row' }}
              flex="1"
              fontSize={{ base: '18px', '2xl': '24px' }}
            >
              <Text mb={{ base: '8px', md: 'unset' }}>
                Edit {effectiveDateFrom} - {effectiveDateTo}
              </Text>
              <Box d="grid" gridTemplateColumns="1fr 1fr" gridGap="16px">
                <Button
                  variant="blue"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={handleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  variant="red"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={onClose}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <ModalBody mb="16px">
              <Box
                d="grid"
                gridTemplateColumns={{ base: '1fr', md: '115px 136px 160px' }}
                gridGap="16px"
                mb="16px"
                alignItems="center"
              >
                <Text
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  color="custom.gray.900"
                >
                  {' '}
                  Start date/time:
                </Text>
                <InputGroup w={{ base: '100%', md: '136px' }} mr="16px">
                  <PseudoInput
                    w="100%"
                    bg={
                      startTimeHaveError ? 'custom.red.20' : 'custom.lightGray'
                    }
                    border={`1px solid ${customColors.borderBlue}`}
                    value={friendlyTime(startDate, 'MM.DD.YY')}
                  />
                  <InputRightElement>
                    <DatePickerModal
                      selectedDate={startDate}
                      cb={datePickerHandler('startDate', 'startDateMillis')}
                      returnMilliseconds={true}
                      skipInitial={true}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup maxW={{ base: '100%', md: '180px' }}>
                  <FormikScheduleInput
                    name="start"
                    id="start"
                    placeholder="HH:MM"
                    color="black"
                    bg="custom.lightGray"
                  />
                  <InputRightElement width="80px">
                    <ButtonsToggle
                      data={['AM', 'PM']}
                      unCheckedColor="custom.lightGray"
                      cb={handleChange('startTimeAmPm')}
                      w="100%"
                      initialState={formikValues.startTimeAmPm}
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Box
                d="grid"
                gridTemplateColumns={{ base: '1fr', md: '115px 136px 160px' }}
                gridGap="16px"
                mb="16px"
                alignItems="center"
              >
                <Text
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  color="custom.gray.900"
                >
                  {' '}
                  End date/time:
                </Text>
                <InputGroup w={{ base: '100%' }} mr="16px">
                  <PseudoInput
                    w="100%"
                    bg={endTimeHaveError ? 'custom.red.20' : 'custom.lightGray'}
                    border={`1px solid ${customColors.borderBlue}`}
                    value={friendlyTime(endDate, 'MM.DD.YY')}
                  />
                  <InputRightElement>
                    <DatePickerModal
                      selectedDate={endDate}
                      cb={datePickerHandler('endDate', 'endDateMillis')}
                      returnMilliseconds={true}
                      skipInitial={true}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup maxW={{ base: '100%', md: '180px' }}>
                  <FormikScheduleInput
                    name="end"
                    id="end"
                    placeholder="HH:MM"
                    color="black"
                    bg="custom.lightGray"
                  />
                  <InputRightElement width="80px">
                    <ButtonsToggle
                      data={['AM', 'PM']}
                      unCheckedColor="custom.lightGray"
                      cb={handleChange('endTimeAmPm')}
                      w="100%"
                      initialState={formikValues.endTimeAmPm}
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>
              <ApplyExperienceList />
              <Box
                d={{ base: 'grid', md: 'none' }}
                mt="8px"
                gridTemplateColumns="1fr 1fr"
                gridGap="16px"
              >
                <Button
                  variant="blue"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={handleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  variant="red"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={onClose}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
