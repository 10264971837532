import * as yup from 'yup'

export const addAndEditAttractionValidation = yup.object().shape({
  location: yup
    .object()
    .test('Location', 'Location required', (val) => !!val.value),
  payment: yup
    .object()
    .test('payment', 'Payment required', (val) => !!val.value),
  name: yup.string().required(),
  interval: yup.string().required()
})
