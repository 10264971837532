import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useRadio,
  useRadioGroup,
  useTheme
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { GoSearch } from 'react-icons/all'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import Preloader from '@/components/Common/Preloader'
import TableCreator from '@/components/Common/TableCreator'
import { H1 } from '@/components/Text/Heading'

import { TABLE_COLUMN_VALUES_COMBO_BOOKINGS } from '@/configs'
import { TABLE_COLUMNS_SIZES_COMBO_BOOKINGS } from '@/configs/tablesSizes'

import { resetPagination } from '../../libs'
import { useComboBookings } from '../../libs/hooks/useComboBookings'
import WithPagination2 from '@/components/Common/WithPagination2'
import {
  authTokenInState,
  locationFilterInState,
  matchMedia768InState
} from '../../constants'

export default function ComboBookingsPage() {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const authToken = useSelector(authTokenInState)
  const [status, setStatus] = useState('all')
  const paginationResetId = 'booking-pagination-id'
  const [search, setSearch] = useState('')

  const { value: locationId } = useSelector(locationFilterInState)
  const [paginationState, setPaginationState] = useState({
    page: 1,
    pageSize: 15,
    totalCountItems: ''
  })

  const {
    data: { results: comboBookings = [], count: total = '' } = {},
    isLoading,
    refetch: dataBookingRefetch
  } = useComboBookings(
    {
      page: paginationState?.page,
      page_size: paginationState?.pageSize,
      status,
      search: search,
      location: locationId
    },
    {
      enabled: !!authToken,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  useEffect(() => {
    resetPagination(paginationResetId)
  }, [status])

  const columns = useMemo(() => {
    const columnsAccessor = isLargerThan768 ? 'default' : 'mobile'
    return TABLE_COLUMN_VALUES_COMBO_BOOKINGS[columnsAccessor].map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      switch (field.Header.toLowerCase()) {
        case 'code':
          fieldObject['Cell'] = ({ row }, value) => {
            return (
              <Link href={`/combos/${row.original.combo_code}`}>
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="custom.blue.600"
                  textDecor="underline"
                >
                  #{row.original.combo_code}
                </Text>
              </Link>
            )
          }
          break
        case 'status':
          fieldObject['Cell'] = ({ row }, value) => {
            const isAvailable = row.original.number_of_uses > 0
            return (
              <Text
                p={2}
                bgColor="#BEE9C7"
                color={'#0DB00A'}
                display="inline-block"
              >
                {isAvailable ? 'Available' : 'Used'}
              </Text>
            )
          }
          break
        case 'total':
          fieldObject['Cell'] = ({ row }, value) => {
            const total = row.original.order?.payments?.[0]?.amount / 100 || 0
            return <Text>${total}</Text>
          }
          break
        case 'client':
          fieldObject['Cell'] = ({ row }, value) => {
            const order = row.original.order
            if (!order) return <Preloader />
            return (
              <Box py="2">
                <Text display="block">{`${order.firstname} ${order.lastname}`}</Text>
                <Text display="block">{order.phone}</Text>
                <Text textTransform="lowercase" display="block">
                  {order.email}
                </Text>
              </Box>
            )
          }
          break
        default:
          break
      }
      return fieldObject
    })
  }, [comboBookings])

  const theme = useTheme()
  const customsColor = theme.colors.custom
  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 8px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  function createCustomTr() {
    const main = {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      h: '56px',
      bg: 'white'
    }

    return { tr: { ...main } }
  }

  return (
    <Box
      p={{
        base: '24px 16px 82px',
        md: '48px 24px 82px',
        '2xl': '66px 48px 82px'
      }}
    >
      {isLargerThan768 && (
        <Box d="flex" alignItems={'baseline'} mb="32px">
          <Link
            color={'custom.blue.600'}
            fontSize={24}
            mr="3"
            to={'/bookings'}
            as={RouterLink}
          >
            Bookings
          </Link>
          <H1>Combos</H1>
          <Link
            color={'custom.blue.600'}
            fontSize={24}
            ml="3"
            to={'/tickets'}
            as={RouterLink}
          >
            Tickets
          </Link>
        </Box>
      )}
      <Box d="flex" w="100%" bg="white" height="100%">
        <StatusBar
          selectedValue={status}
          options={[
            { value: 'all', label: 'All' },
            { value: 'available', label: 'Available' },
            { value: 'completed', label: 'Used' }
          ]}
          onChange={(value) => setStatus(value)}
        />
      </Box>
      <Box
        bg={{ base: 'none', md: 'white' }}
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
        p={{ base: '0', md: '16px' }}
        mt={{ base: '0', md: '2px' }}
        borderBottom="2px solid"
        borderColor="custom.lightGray"
      >
        <InputGroup>
          <Input
            placeholder="Search by Code or Client"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fontSize="inherit"
            _placeholder={{
              color: 'custom.gray.300'
            }}
            variant="gbase"
          />
          <InputRightElement
            children={<Icon as={GoSearch} color="rgba(199, 207, 214, 1)" />}
          />
        </InputGroup>
      </Box>

      <WithPagination2
        totalCountItems={total}
        state={paginationState}
        setState={setPaginationState}
        id={paginationResetId}
        isLoading={isLoading}
        actualLength={comboBookings.length}
      >
        <TableCreator
          data={comboBookings}
          columns={columns}
          styles={{ ...tableStyles, ...createCustomTr() }}
          sizesMap={TABLE_COLUMNS_SIZES_COMBO_BOOKINGS}
        />
      </WithPagination2>
    </Box>
  )
}

const StatusButton = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()
  return (
    <Box as="label" height="100%" flex={'1 0 auto'}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        w="100%"
        d="flex"
        justifyContent="center"
        alignItems="center"
        borderBottom={'2px solid transparent'}
        height="100%"
        fontSize={{ base: '12px', '2xl': '18px' }}
        color={'custom.black.500'}
        fontWeight="400"
        _focus={false}
        _checked={{
          borderBottom: '2px solid rgba(4, 113, 241, 1)',
          color: 'custom.blue.600'
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}

const StatusBar = ({ options, onChange, selectedValue }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'combo_bookings_status_filter',
    defaultValue: selectedValue,
    onChange
  })

  const group = getRootProps()

  return (
    <HStack {...group} w="100%" h="50px">
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value })
        return (
          <StatusButton key={value} {...radio}>
            {label}
          </StatusButton>
        )
      })}
    </HStack>
  )
}
