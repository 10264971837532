import { Box, Text } from '@chakra-ui/react'
import BasePricingList from './BasePricingList'
import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../../../../../constants'
import {
  useBasePrices,
  usePrivateGamePrices
} from '../../../../../../libs/hooks/useBasePrices'

export default function BasePricing({
  eventVariantId = null,
  minPlayersLimitation = null,
  maxPlayersLimitation = null
}) {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { data = {} } = useBasePrices(+eventVariantId, {
    enabled: eventVariantId !== null
  })
  return (
    <Box mb={{ base: '2px', md: '24px', '2xl': '32px' }}>
      <Box bg="white" padding="16px" mb="2px">
        <Text fontSize={{ base: '18px', '2xl': '24px' }} fontWeight="500">
          Base pricing
        </Text>
      </Box>
      <Box
        bg="white"
        padding={{ base: '16px', '2xl': '24px' }}
        d="grid"
        gridTemplateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gridGap="2px"
      >
        {Object.keys(data)?.map((key, idx) => (
          <BasePricingList
            expanded={isLargerThan768}
            minPlayersLimitation={minPlayersLimitation}
            maxPlayersLimitation={maxPlayersLimitation}
            timeLetter={key}
            slots={data[key]}
            eventVariantId={eventVariantId}
            key={idx + 'BasePricingList' + idx}
          />
        ))}
      </Box>
    </Box>
  )
}
