import { Box } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useMemo, useState } from 'react'

import ButtonRed from '../../../common/ButtonRed'
import Modal from '../../../common/Modal'

export default function MinorsErrorModal({ toManyMinors }) {
  const formik = useFormikContext()
  const [isOpen, setIsOpen] = useState(false)
  const formikErrors = formik.errors
  const someMinorIsEdit = formik.values.someMinorIsEdit
  const newMinorMode = formik.values.newMinorMode
  const stringErrors = useMemo(() => {
    const someMinorIsEditMsg = 'You must save minor after edit.'
    const newMinorModeMsg = 'You must save minor after adding new.'
    const toManyMinorsMsg = "You can't add all yours minors."
    const array = Object.keys(formikErrors)?.map((key) => {
      if (key === 'signaturePhoto') return formikErrors[key]
      if (key === 'emptyMinors')
        return 'For type "Child(ren)" or "Myself & child(ren)" required at least 1 minor.'
      if (key === 'newMinorMode') return 'You must save minor after adding new.'
      if (key === 'someMinorIsEdit') return someMinorIsEditMsg
      if (key === 'requiredCheckboxes') return 'You must accept terms & conditions.'
      if (key === 'createdProfile')
        return 'Some field(s) in block "Create Profile" incorrect'
      if (key === 'selectedProfile')
        return 'Some field(s) in block "Participants information" incorrect'
    })
    if (someMinorIsEdit && !array?.includes(someMinorIsEditMsg))
      array.push(someMinorIsEditMsg)
    if (newMinorMode && !array?.includes(newMinorModeMsg))
      array.push(newMinorModeMsg)
    if (toManyMinors && !array?.includes(toManyMinorsMsg))
      array.push(toManyMinorsMsg)
    return array
  }, [formikErrors, someMinorIsEdit, newMinorMode, toManyMinors])

  return (
    <div className="waiver-widget--modal-errors">
      {stringErrors?.length !== 0 ? (
        <div className="waiver-widget--notif error">
          {stringErrors.map((err) => (
            <p>{err}</p>
          ))}
        </div>
      ) : null}
      <Modal isOpen={isOpen} setOpen={setIsOpen} closeByOutsideClick={true}>
        <div className="waiver-widget--title-text">Errors</div>
        {stringErrors?.map((message, idx) => {
          return (
            <div className="waiver-widget--text" key={'minor-errors-' + idx}>
              {idx + 1}. {message}
            </div>
          )
        })}
      </Modal>
    </div>
  )
}
