import React, { useMemo } from 'react'
import { Button, Text, useTheme } from '@chakra-ui/react'
import { TABLE_COLUMN_VALUES_PLAYERS } from '../../../configs'
import { TABLE_COLUMNS_SIZES_PLAYERS } from '../../../configs/tablesSizes'
import TableCreator from '../../Common/TableCreator'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { matchMedia1440InState } from '../../../constants'
import { SettingsIcon } from '@chakra-ui/icons'
import EditPlayerModal from '../Modals/EditPlayerModal'

export default function PlayersTable({
  tableData = [],
  blacklist = false,
  refetchFunction
}) {
  const theme = useTheme()
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const customsColor = theme.colors.custom
  const history = useHistory()
  const data = useMemo(() => tableData, [tableData])

  function goToHandler(playerId) {
    return () => history.push(`/player/${playerId}`)
  }

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 16px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_PLAYERS.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      // if (field.Header.toLowerCase() === 'full name') {
      //
      //     fieldObject['Cell'] = ({row: {original}}) => {
      //         return (
      //             <Box d='flex' justifyContent='center' alignItems='center'>
      //                 {(isLargerThan1440 && blacklist) &&
      //                 <IconButton aria-label='blacklist'
      //                             minH='unset'
      //                             minW='unset'
      //                             w='18px'
      //                             height='24px'
      //                             _focus={{border: 'none'}}
      //                             _hover={{bg: 'transparent'}}
      //                             bg='white'
      //                             mr='8px'
      //                             icon={<SkullIcon w='100%' height='100%'/>}
      //                 />
      //                 }
      //                 <Button variant='link' color='custom.blue.600' onClick={goToHandler}>
      //                     {original.fullname}
      //                 </Button>
      //             </Box>);
      //     }
      // }

      if (field.Accessor === 'func') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          if (original.phone === '+12222222222') {
            return <SettingsIcon w="20px" h="20px" />
          } else {
            return null
          }
        }
      }

      if (field.Accessor === 'functionalColumn') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          console.log('here')
          const playerId = original?.id
          return (
            <>
              <EditPlayerModal
                playerId={playerId}
                refetchFunction={refetchFunction}
              />
            </>
          )
        }
      }

      if (field.Header.toLowerCase() === 'full name') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          if (original?.firstname !== null && original?.lastname !== null) {
            return (
              <>
                <Button
                  variant="link"
                  color="custom.blue.600"
                  onClick={goToHandler(original.id)}
                >
                  {original.firstname}&nbsp;{original.lastname}
                </Button>
              </>
            )
          } else {
            return <Text color="custom.gray.200">null</Text>
          }
        }
      }

      if (field.Header.toLowerCase() === 'phone') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          if (original.phone && original.phone !== null) {
            return <>{original.phone}</>
          } else {
            return <Text color="custom.gray.200">null</Text>
          }
        }
      }

      if (field.Header.toLowerCase() === 'e-mail') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          if (original.email && original.email !== null) {
            return <Text textTransform="lowercase">{original.email}</Text>
          } else {
            return <Text color="custom.gray.200">null</Text>
          }
        }
      }

      if (field.Header.toLowerCase() === 'dob') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return <>{original?.birthdate ? original.birthdate : '-'}</>
        }
      }

      if (field.Header.toLowerCase() === 'last room played') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return (
            <>
              {original?.event_variant_name ? original.event_variant_name : '-'}
            </>
          )
        }
      }

      return fieldObject
    })
  }, [refetchFunction])
  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sizesMap={TABLE_COLUMNS_SIZES_PLAYERS}
    />
  )
}
