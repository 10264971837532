import { useMutation, useQuery } from 'react-query'

import {
  deleteAttractionsExperience,
  getAttractionsExperience,
  updateAttractionsExperience
} from '../../api/Settings/attractions'

export function useAttractionsExperience(eventId, queryOption = {}) {
  return useQuery(
    ['event', +eventId],
    () => getAttractionsExperience(eventId),
    {
      ...queryOption
    }
  )
}

export function useUpdateAttractionsExperience(options = {}) {
  return useMutation(({ obj }) => updateAttractionsExperience(obj), {
    ...options
  })
}

export function useDeleteAttractionsExperience(options = {}) {
  return useMutation(({ obj }) => deleteAttractionsExperience(obj), {
    ...options
  })
}
