import { useField } from 'formik'
import { Input } from '@chakra-ui/react'

export default function FormikInput({
  name,
  id,
  placeholder,
  isDisabled,
  variant = 'gbase',
  onBlurFunction = null,
  bg = '',
  ...rest
}) {
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)

  function handleBlur(formikBlur, onBlurFunction = null) {
    return (event) => {
      formikBlur(event)
      if (onBlurFunction !== null && typeof onBlurFunction === 'function') {
        onBlurFunction()
      }
    }
  }

  return (
    <Input
      variant={variant}
      h="40px"
      {...field}
      {...rest}
      placeholder={placeholder}
      isDisabled={isDisabled}
      bg={error && touched ? 'custom.red.20' : bg ? bg : 'custom.lightGray'}
      onBlur={handleBlur(field.onBlur, onBlurFunction)}
    />
  )
}
