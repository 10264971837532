import { useEffect, useRef, useState } from 'react'

export default function Timer({ seconds = 60, cb = null, className }) {
  const [timeLeft, setTimeLeft] = useState(+seconds)
  const timerRef = useRef(null)

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeLeft((t) => t - 1)
    }, 1000)

    return () => clearInterval(timerRef.current)
  }, [])

  useEffect(() => {
    if (timeLeft === 0) {
      if (cb !== null && typeof cb === 'function') {
        cb()
      }
      clearInterval(timerRef.current)
    }
  }, [timeLeft])

  return <p className={className}>{timeLeft}</p>
}
