export default function ChildIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5869 12.5009C25.8482 10.7662 24.695 9.23926 23.2285 8.05423C21.7619 6.86919 20.0269 6.06228 18.1758 5.70431L19.848 3.02875L18.152 1.96875L15.9454 5.49925C13.6978 5.50968 11.5025 6.17837 9.63067 7.4227C7.75887 8.66704 6.29264 10.4325 5.41313 12.5009C4.23504 12.5233 3.11276 13.007 2.28753 13.8481C1.4623 14.6892 1 15.8204 1 16.9987C1 18.1771 1.4623 19.3083 2.28753 20.1494C3.11276 20.9905 4.23504 21.4742 5.41313 21.4966C6.29638 23.5735 7.77104 25.3448 9.65349 26.5898C11.536 27.8349 13.7431 28.4988 16 28.4988C18.257 28.4988 20.4641 27.8349 22.3465 26.5898C24.229 25.3448 25.7036 23.5735 26.5869 21.4966C27.765 21.4742 28.8872 20.9905 29.7125 20.1494C30.5377 19.3083 31 18.1771 31 16.9987C31 15.8204 30.5377 14.6892 29.7125 13.8481C28.8872 13.007 27.765 12.5233 26.5869 12.5009ZM28.2839 18.7494C27.8265 19.2172 27.2031 19.4858 26.5489 19.4969L25.2536 19.5216L24.7465 20.7137C24.0169 22.4297 22.7987 23.8932 21.2434 24.922C19.6882 25.9507 17.8647 26.4993 16 26.4993C14.1353 26.4993 12.3119 25.9507 10.7566 24.922C9.20139 23.8932 7.98314 22.4297 7.25357 20.7137L6.74644 19.5216L5.45113 19.4969C4.79666 19.4847 4.17314 19.2161 3.71464 18.7489C3.25613 18.2818 2.99926 17.6533 2.99926 16.9987C2.99926 16.3442 3.25613 15.7157 3.71464 15.2486C4.17314 14.7814 4.79666 14.5128 5.45113 14.5006L6.74644 14.4759L7.25357 13.2838C7.98316 11.5678 9.20142 10.1043 10.7566 9.07558C12.3119 8.04686 14.1354 7.49834 16 7.49834C17.8647 7.49834 19.6882 8.04686 21.2434 9.07558C22.7987 10.1043 24.0169 11.5678 24.7465 13.2838L25.2536 14.4759L26.5489 14.5006C27.037 14.5098 27.5118 14.6618 27.9144 14.9379C28.3171 15.214 28.6301 15.602 28.8146 16.054C28.9992 16.506 29.0473 17.0022 28.953 17.4812C28.8586 17.9602 28.626 18.4011 28.2839 18.7494Z"
        fill="#777777"
      />
      <path
        d="M10.5 14.5H13V17H10.5V14.5ZM19 14.5H21.5V17H19V14.5ZM16 24C16.6566 24 17.3068 23.8707 17.9134 23.6194C18.52 23.3681 19.0712 22.9998 19.5355 22.5355C19.9998 22.0712 20.3681 21.52 20.6194 20.9134C20.8707 20.3068 21 19.6566 21 19H11C11 19.6566 11.1293 20.3068 11.3806 20.9134C11.6319 21.52 12.0002 22.0712 12.4645 22.5355C13.4021 23.4732 14.6739 24 16 24Z"
        fill="#777777"
      />
    </svg>
  )
}
