import { FormLabel, Input, InputGroup } from '@chakra-ui/react'

export default function NewOrderInput({
  cb = null,
  isError = false,
  field = {},
  input = {},
  ...rest
}) {
  const { label = '', name = '', id = '', chakraStyles = {} } = rest

  return (
    <InputGroup
      flexDir="column"
      fontSize={{ base: '12px', '2xl': '14px' }}
      mb="16px"
      {...chakraStyles}
    >
      {label && (
        <FormLabel color="custom.gray.200" fontWeight="400" fontSize="inherit">
          {label}
        </FormLabel>
      )}
      <Input
        variant="gbase"
        bg={isError ? 'custom.red.20' : 'custom.lightGray'}
        name={name}
        id={id}
        {...field}
        fontSize="inherit"
        {...input}
      />
    </InputGroup>
  )
}
