import { useField } from 'formik'
import { FormControl, Input, InputGroup, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import {
  matchMedia1440InState,
  matchMedia768InState
} from '../../../../constants'

export default function FormikTwoStyledInput({
  name,
  id = '',
  placeholder,
  isDisabled = false,
  input = {},
  ...rest
}) {
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  function inputVariant() {
    return isLargerThan768 ? 'gbase' : 'gbase2'
  }

  return (
    <FormControl {...rest}>
      {isLargerThan768 ? (
        <InputGroup>
          <Text
            pos="absolute"
            zIndex="1"
            top="8px"
            left="15px"
            fontSize="10px"
            textTransform="capitalize"
            color="custom.gray.300"
          >
            {placeholder}
          </Text>
          <Input
            variant="gbase"
            h="56px"
            p="18px 14px 0px"
            {...field}
            isDisabled={isDisabled}
            bg={error && touched ? 'custom.red.20' : 'custom.lightGray'}
            {...input}
            {...(id && { id })}
          />
        </InputGroup>
      ) : (
        <Input
          variant={inputVariant()}
          placeholder={placeholder}
          {...field}
          isDisabled={isDisabled}
          bg={error && touched ? 'custom.red.20' : 'custom.lightGray'}
          fontSize={{ base: '12px', '2xl': '14px' }}
          {...input}
          {...(id && { id })}
        />
      )}
    </FormControl>
  )
}
