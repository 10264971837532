import axios from 'axios'

import { dataURLtoFile } from '../common'
import { BASE_URL_WO_SLASH } from '../constants'

const BASE_URL = BASE_URL_WO_SLASH

export const api = axios.create({
  baseURL: BASE_URL
})

export const apiUnprotect = axios.create({
  baseURL: BASE_URL
})

export async function getWaiverWidgetByBooking(bookingCode) {
  const { data } = await apiUnprotect.get(
    '/api/waiver-widget/entity/booking/',
    {
      params: {
        code: bookingCode
      }
    }
  )

  return data
}

export async function waiverWidgetCreateEntity(
  {
    signedFor,
    sign,
    minors,
    notif,
    player,
    phoneNumber,
    smsCode,
    bookingId,
    waiverId,
    requireAuth
  },
  { onSuccess = null, onError = null }
) {
  const formData = new FormData()
  const file = dataURLtoFile(sign, `sign${Date.now()}.png`)
  formData.append('sign', file)
  formData.append('signed_for', signedFor)
  formData.append('minors', minors)
  formData.append('player', JSON.stringify(player))
  if (phoneNumber !== '' && requireAuth) {
    formData.append('phone_number', phoneNumber)
    formData.append('sms_code', smsCode)
  }
  formData.append('booking_id', bookingId)
  formData.append('waiver_id', waiverId)
  formData.append('notif', notif)
  try {
    const { data } = await apiUnprotect.post(
      '/api/waiver-widget/entity/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    if (onSuccess !== null && typeof onSuccess === 'function') {
      onSuccess(data)
    }
    return data
  } catch (e) {
    if (onError !== null && typeof onError === 'function') {
      onError(e)
    }
    throw e
  }
}

export async function waiverWidgetUpdateEntity(
  {
    signedFor,
    sign,
    minors,
    notif,
    player,
    phoneNumber,
    smsCode,
    bookingId,
    waiverId,
    waiverWidgetEntityId,
    requireAuth
  },
  { onSuccess = null, onError = null }
) {
  const formData = new FormData()
  if (sign) {
    const file = dataURLtoFile(sign, `sign${Date.now()}.png`)
    formData.append('sign', file)
  }
  formData.append('signed_for', signedFor)
  formData.append('minors', minors)
  formData.append('player', JSON.stringify(player))
  if (phoneNumber !== '' && requireAuth) {
    formData.append('phone_number', phoneNumber)
    formData.append('sms_code', smsCode)
  }
  formData.append('booking_id', bookingId)
  formData.append('waiver_id', waiverId)
  formData.append('notif', notif)
  try {
    const { data } = await apiUnprotect.patch(
      `/api/waiver-widget/entity/${waiverWidgetEntityId}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    if (onSuccess !== null && typeof onSuccess === 'function') {
      onSuccess(data)
    }
    return data
  } catch (e) {
    if (onError !== null && typeof onError === 'function') {
      onError(e)
    }
    throw e
  }
}

export async function waiverWidgetSendSms(
  { phoneNumber, bookingId, waiverId },
  { onSuccess = null, onError = null }
) {
  try {
    const { data } = await apiUnprotect.post(
      '/api/waiver-widget/entity/send_sms/',
      {
        phone_number: phoneNumber,
        booking_id: bookingId,
        waiver_id: waiverId
      }
    )
    if (onSuccess !== null && typeof onSuccess === 'function') {
      onSuccess(data)
    }
    return data
  } catch (e) {
    if (onError !== null && typeof onError === 'function') {
      onError(e)
    }
    throw e
  }
}

export async function waiverWidgetValidateSms(
  { phoneNumber, smsCode, bookingId, waiverId },
  { onSuccess = null, onError = null }
) {
  try {
    const { data } = await apiUnprotect.post(
      '/api/waiver-widget/entity/validate_sms/',
      {
        phone_number: phoneNumber,
        booking_id: bookingId,
        waiver_id: waiverId,
        sms_code: smsCode
      }
    )
    if (onSuccess !== null && typeof onSuccess === 'function') {
      onSuccess(data)
    }
    return data
  } catch (e) {
    if (onError !== null && typeof onError === 'function') {
      onError(e)
    }
    throw e
  }
}

export async function getWaiverWidgetEntity(waiverId, { onSuccess, onError }) {
  try {
    const { data } = await apiUnprotect.get(
      `/api/waiver-widget/entity/${waiverId}/`
    )
    if (onSuccess !== null && typeof onSuccess === 'function') {
      onSuccess(data)
    }
    return data
  } catch (e) {
    if (onError !== null && typeof onError === 'function') {
      onError(e)
    }
    throw e
  }
}
