import { Box, Button, Text } from '@chakra-ui/react'
import DropdownSelect from '@/components/Common/DropdownSelect'
import { settingsContainerMap, settingsOptions } from '../../configs'
import { useDispatch, useSelector } from 'react-redux'
import {
  CUT_OFF_URL,
  LIMITATIONS_URL,
  matchMedia768InState,
  PERMISSION_COMPANIES,
  PERMISSION_CUT_OFF,
  PERMISSION_EVENT,
  PERMISSION_LIMITATIONS,
  PERMISSION_LOCATIONS,
  PERMISSION_NOTIFICATIONS,
  PERMISSION_USERS,
  settingsTabInState
} from '../../constants'
import ButtonsSelector from '@/components/Settings/ButtonsSelector'
import { setSettingsTab } from '../../actions'
import Account from '@/components/Settings/Tabs/Account'
import Users from '@/components/Settings/Tabs/Users'
import Notifications from '@/components/Settings/Tabs/Notifications'
import Attractions from '@/components/Settings/Tabs/Attractions'
import Locations from '@/components/Settings/Tabs/Locations'
import Cities from '@/components/Settings/Tabs/Cities'
import { useHistory } from 'react-router-dom'
import PermissionRequired from '@/components/Common/PermissionRequired'
import { useEffect, useMemo } from 'react'
import ComboPackages from '@/features/combo/ComboPackages'
import React from 'react'

export default function Settings() {
  const dispatch = useDispatch()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const settingsTab = useSelector(settingsTabInState)
  const history = useHistory()
  function onChangeDropdownHandler(obj) {
    dispatch(setSettingsTab(obj))
  }

  function goTo(path) {
    return () => history.push(path)
  }

  const cutOffTimePermission = PermissionRequired(PERMISSION_CUT_OFF, true)
  const locationPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const usersPermission = PermissionRequired(PERMISSION_USERS, true)
  const limitationsPermission = PermissionRequired(PERMISSION_LIMITATIONS, true)
  const accountPermission = PermissionRequired(PERMISSION_COMPANIES, true)
  const attractionPermission = PermissionRequired(PERMISSION_EVENT, true)

  const allSettingsBlocked =
    !locationPermission &&
    !notificationPermission &&
    !usersPermission &&
    !accountPermission &&
    !attractionPermission

  useEffect(() => {
    if (allSettingsBlocked) {
      dispatch(setSettingsTab({ value: 'empty', label: 'Empty' }))
    }
  }, [allSettingsBlocked, cutOffTimePermission, limitationsPermission])
  const settingsOptionsWithPermission = useMemo(() => {
    const arr = []
    for (let i = 0; i < settingsOptions.length; i++) {
      const el = settingsOptions[i]
      if (el.value === 'locations' && !locationPermission) {
        continue
      }
      if (el.value === 'notifications' && !notificationPermission) {
        continue
      }
      if (el.value === 'users' && !usersPermission) {
        continue
      }
      if (el.value === 'account' && !accountPermission) {
        continue
      }
      if (el.value === 'attractions' && !attractionPermission) {
        continue
      }
      if (el.value === 'combo' && !attractionPermission) {
        continue
      }
      arr.push(el)
    }

    return arr
  }, [
    settingsOptions,
    locationPermission,
    usersPermission,
    notificationPermission,
    accountPermission,
    attractionPermission
  ])

  const dropdownStyles = {
    selectProps: {
      main: {
        options: settingsOptionsWithPermission,
        value: settingsTab,
        onChange: onChangeDropdownHandler
      },
      styles: {
        control: {
          background: 'white'
        },
        menu: {
          background: 'white'
        }
      }
    },
    chakraProps: {
      mb: {
        base: '8px'
      }
    }
  }

  function createTab(settingsTab) {
    const { value = 'account' } = settingsTab
    switch (value) {
      case 'account': {
        return <Account />
      }

      case 'users': {
        return <Users />
      }

      case 'notifications': {
        return <Notifications />
      }

      case 'attractions': {
        return <Attractions />
      }

      case 'combo': {
        return <ComboPackages />
      }

      case 'locations': {
        return <Locations />
      }

      case 'cities': {
        return <Cities />
      }

      case 'empty': {
        return null
      }

      default:
        return <Account />
    }
  }

  return (
    <Box
      p={{ base: '24px 16px 16px', md: '32px 24px 24px', '2xl': '48px 24px' }}
    >
      <Box
        maxW={{ md: '1100px', '2xl': settingsContainerMap(settingsTab.value) }}
        m="0 auto"
      >
        <Box d="flex" flexDir={{ base: 'column', md: 'row' }}>
          <Text variant="boldTitle">Settings</Text>
          {isLargerThan768 ? (
            <Box d="flex" ml="auto">
              {!allSettingsBlocked && <ButtonsSelector />}
              <Button
                variant="blue"
                isDisabled={!limitationsPermission}
                ml="16px"
                fontSize={{ base: '12px', '2xl': '14px' }}
                onClick={goTo(LIMITATIONS_URL)}
              >
                Limitations
              </Button>
              <Button
                variant="blue"
                ml="8px"
                isDisabled={!cutOffTimePermission}
                fontSize={{ base: '12px', '2xl': '14px' }}
                onClick={goTo(CUT_OFF_URL)}
              >
                Cut off
              </Button>
            </Box>
          ) : (
            <Box>
              {!allSettingsBlocked && <DropdownSelect {...dropdownStyles} />}
              <Box
                d="grid"
                gridTemplateColumns="1fr 1fr"
                gridGap="8px"
                mb="8px"
              >
                <Button
                  variant="blue"
                  isDisabled={!limitationsPermission}
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={goTo(LIMITATIONS_URL)}
                >
                  Limitations
                </Button>
                <Button
                  variant="blue"
                  isDisabled={!cutOffTimePermission}
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  onClick={goTo(CUT_OFF_URL)}
                >
                  Cut off
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        {createTab(settingsTab)}
      </Box>
    </Box>
  )
}
