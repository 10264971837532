import { useContext } from 'react'
import { AttractionContext } from '../../index'

//selector func
export const useAttractionsSelector = (callback) => {
  const [store] = useContext(AttractionContext)
  return callback(store)
}

//dispatch attr func
export const useAttractionsDispatch = () => {
  const [_, dispatch] = useContext(AttractionContext)
  return dispatch
}

export const SET_PROPERTY_VALUE = 'SET_PROPERTY_VALUE'
export const CLONE_ELEMENT_FOR_EDIT = 'CLONE_ELEMENT_FOR_EDIT'
export const ACCEPT_CHANGES = 'ACCEPT_CHANGES'
export const SET_EDIT = 'SET_EDIT'
export const SET_DATA = 'SET_DATA'
export const SET_NEW_MODE = 'SET_NEW_MODE'
export const SET_REQUIRED_PROPS = 'SET_REQUIRED_PROPS'

//selectors
export const attrDataInState = (state) => state.data
export const state = (state) => state
export const clonedElementInState = (state) => state.clonedElement
export const editInState = (state) => state.edit
export const newModeInState = (state) => state.newMode
export const requiredPropsInState = (state) => state.requiredProps
