import { Box, Text } from '@chakra-ui/react'
import HolidaysList from './HolydaysList'
import NewHolidayModal from '../../Settings/Tabs/Attractions/AttractionsExperience/Modals/NewHolidayModal'

export default function Holidays() {
  return (
    <>
      <Box
        mb="2px"
        p="16px"
        bg="white"
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Text
          color="custom.black.500"
          fontSize={{ base: '18px', '2xl': '24px' }}
          mb={{ base: '8px', md: 'unset' }}
          fontWeight="500"
        >
          Holiday
        </Text>
        <NewHolidayModal />
      </Box>
      <HolidaysList />
    </>
  )
}
