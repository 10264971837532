import { useMutation, useQuery } from 'react-query'

import {
  createDiscount,
  deleteDiscount,
  getDiscounts,
  getDiscountsAssigned,
  patchDiscount
} from '../../api/discounts/discounts'

export function useDiscounts(bookingId, queryOption = {}) {
  return useQuery(['discounts'], () => getDiscounts(), {
    ...queryOption
  })
}

export function useDiscountsAssigned(bookingId, queryOption = {}) {
  return useQuery(['discountsAssigned'], () => getDiscountsAssigned(), {
    ...queryOption
  })
}

export function useUpdateDiscount(options = {}) {
  return useMutation(({ obj }) => patchDiscount(obj), {
    ...options
  })
}

export function useCreateDiscount(options = {}) {
  return useMutation(({ obj }) => createDiscount(obj), {
    ...options
  })
}

export function useDeleteDiscount(options = {}) {
  return useMutation(({ discountId }) => deleteDiscount(discountId), {
    ...options
  })
}
