import { Box, IconButton, Switch, useTheme, useToast } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import { useQueryClient } from 'react-query'

import { useEditPaymentGate } from '@/libs/hooks/useStripe'

import CustomWarningToast from '@/components/Common/CustomWarningToast'
import TableCreator from '@/components/Common/TableCreator'

import { CopyIconWithoutBg } from '@/assets/Icons/CopyIconWithoutBg'
import { TABLE_COLUMN_VALUES_PAYMENT_GATES } from '@/configs'
import { TABLE_COLUMNS_SIZES_PAYMENT_GATES } from '@/configs/tablesSizes'

import DeletePaymentGatesModal from '../Modals/DeletePaymentGateModal'
import EditPaymentGatesModal from '../Modals/EditPaymentGatesModal'

export default function PaymentsGatesTable({ tableData = [] }) {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const customsColor = theme.colors.custom

  const { mutate: updatePaymentGate } = useEditPaymentGate()

  const toast = useToast()
  const paymentGateError = {
    id: 'error-payment-gate-edit-table',
    text: 'Something went wrong.'
  }

  function generateWarningToast({ text, id, incomingText, msgObject }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 4000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={incomingText ? incomingText : text}
              close={toast.closeAll}
              msgObjects={msgObject}
            />
          )
        })
      }
    }
  }

  function onChangeSwitcher(gate) {
    return () => {
      updatePaymentGate(
        {
          paymentGateId: gate.id,
          paymentGateName: gate.name,
          privateKey: gate.private,
          publicKey: gate.public,
          isDefault: gate.is_default
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['stripe'])
          },
          onError: (err) => {
            const msg = err?.response?.data?.msg
            const msgObjects = err?.response?.data
            generateWarningToast({
              ...paymentGateError,
              incomingText: msg,
              msgObjects
            })()
          }
        }
      )
    }
  }

  const data = useMemo(
    () => tableData?.sort((a, b) => b.id - a.id),
    [tableData]
  )

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 16px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_PAYMENT_GATES.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'public key') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return (
            <Box d="flex" alignItems="center" justifyContent="center">
              <Box
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxW="80%"
              >
                {original.public}
              </Box>
              <CopyToClipboard text={original.public}>
                <IconButton
                  aria-label="copy"
                  bg="none"
                  _focus={{ border: 'none' }}
                  _hover={{ bg: 'transparent' }}
                  icon={
                    <CopyIconWithoutBg
                      color="custom.gray.200"
                      w="16px"
                      height="16px"
                    />
                  }
                />
              </CopyToClipboard>
            </Box>
          )
        }
      }

      if (field.Header.toLowerCase() === 'private key') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          return <>{original.private ? '***************' : 'empty'}</>
        }
      }

      if (field.Header.toLowerCase() === 'default') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          const value = original.is_default
          return (
            <Box onClick={onChangeSwitcher(original)}>
              <Switch isChecked={value} />
            </Box>
          )
        }
      }

      if (field.Accessor.toLowerCase() === 'funccolumn') {
        fieldObject['Cell'] = ({ row: { original: gate } }, value) => {
          return (
            <Box d="flex" justifyContent="flex-end">
              <EditPaymentGatesModal
                paymentGateId={gate.id}
                paymentGateName={gate.name}
                privateKey={gate.private}
                additionalPrivateKey={gate.additional}
                publicKey={gate.public}
                isDefault={gate.is_default}
                feePercentInt={+gate.fee}
                salesTaxPercent={+gate.sales_tax_fee}
                paymentProvider={gate.payment_provider}
              />
              <DeletePaymentGatesModal stripeId={gate.id} name={gate.name} />
            </Box>
          )
        }
      }
      return fieldObject
    })
  }, [])
  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sizesMap={TABLE_COLUMNS_SIZES_PAYMENT_GATES}
    />
  )
}
