import { api } from '../../index'

export async function getCityList() {
  try {
    const { data } = await api.get('/api/cities/')
    return data
  } catch (e) {
    console.log(e)
  }
}
