import Payment from '../Payment'
import React from 'react'

export default function PaymentsList({ data, email }) {
  return data?.map((payment, idx) => {
    let identifier = '—'
    if (
      payment.payment_source === 'stripe' ||
      payment.payment_source === 'offline' ||
      payment.payment_source === 'promocode' ||
      payment.payment_source === 'giftcard'
    ) {
      if (payment?.raw_data?.id) {
        identifier = payment?.raw_data?.id
      } else {
        identifier = '—'
      }
    }
    if (payment.payment_source === 'cash') {
      identifier = '—'
    }
    const fee = payment.fee / 100
    return (
      <Payment
        identifier={identifier}
        type={payment.payment_source}
        id={payment.id}
        last={payment.card_last}
        cardholder={payment.cardholder}
        total={(payment.amount / 100).toFixed(2)}
        user={email}
        fee={fee}
        key={'payments' + idx}
      />
    )
  })
}
