import GiftCardsItem from '../GiftCardsItem'
import { Box } from '@chakra-ui/react'

export default function GiftCardsList({ data = [] }) {
  return (
    <Box mb="60px">
      {data?.map((card, idx) => (
        <GiftCardsItem card={card} key={'GiftCardsList' + idx} />
      ))}
    </Box>
  )
}
