import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PencilIcon } from '../../../../../../assets/Icons/PencilIcon'
import {
  useEmailBaseTemplate,
  usePatchEmailBaseTemplate
} from '../../../../../../libs/hooks/useEmailBaseTemplates'
import { newAndEditEmailBaseTemplateModalValidation } from '../../../../../../configs/validation/newAndEditEmailBaseTemplateModalValidation'
import { PERMISSION_NOTIFICATIONS } from '../../../../../../constants'
import CustomTextarea from '../../../../../Common/CustomTextarea'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import Preloader from '../../../../../Common/Preloader'

export default function EditEmailTemplateModal({ emailTemplateId }) {
  const queryClient = useQueryClient()
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: templateData = {}, isLoading: isLoadingEmailTemplate } =
    useEmailBaseTemplate(emailTemplateId, {
      enabled: isOpen
    })
  const { mutate: patchEmailBaseTemplate, isLoading: editInProgress } =
    usePatchEmailBaseTemplate()

  function onSubmitHandler(values) {
    const toSend = {
      emailBaseTemplateId: emailTemplateId,
      templateName: values.templateName,
      templateTheme: values.templateTheme,
      templateHeader: values.templateHeader,
      templateFooter: values.templateFooter
    }
    patchEmailBaseTemplate(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['emailBaseTemplates'])
          onClose()
        }
      }
    )
  }

  const formik = useFormik({
    initialValues: {
      templateName: templateData?.template_name,
      templateTheme: templateData?.theme,
      templateHeader: templateData?.header,
      templateFooter: templateData?.footer
    },
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
    validationSchema: newAndEditEmailBaseTemplateModalValidation
  })
  const formikValues = formik.values
  const formikHandleSubmit = formik.handleSubmit
  const setFieldValue = formik.setFieldValue
  const formikErrors = formik.errors
  const resetForm = formik.resetForm
  const isLoading = isLoadingEmailTemplate || editInProgress

  function onCloseHandler() {
    resetForm()
    onClose()
  }

  function onChangeHandler(prop) {
    return (e) => {
      const value = e.target.value
      setFieldValue(prop, value)
    }
  }

  const blue600 = {
    color: 'custom.blue.600',
    ml: '4px'
  }
  return (
    <>
      <IconButton
        aria-label="editAccount"
        variant="funcColumnIconButtons"
        icon={<PencilIcon w="inherit" h="inherit" />}
        onClick={onOpen}
        mr={{ base: '16px', '2xl': '24px' }}
        color="custom.gray.200"
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onCloseHandler}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            pos="relative"
            maxW={{ base: '288px', md: '720px', '2xl': '656px' }}
          >
            {isLoading && (
              <Box
                pos="absolute"
                top="0"
                left="0"
                bottom="0"
                right="0"
                zIndex={2}
              >
                <Preloader h="8px" w="calc(100% - 4px)" opacity="0.8" m="2px" />
              </Box>
            )}
            <ModalHeader
              d="flex"
              opacity={isLoading ? 0.4 : 1}
              fontSize={{ base: '18px', '2xl': '24px' }}
              mb="34px"
            >
              Edit template
              <Box
                ml="auto"
                d={{ base: 'none', md: 'grid' }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="8px"
                fontSize={{ base: '12px', '2xl': '14px' }}
              >
                <Button
                  w="100%"
                  variant="blue"
                  fontSize="inherit"
                  isDisabled={!notificationPermission}
                  onClick={formikHandleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  w="100%"
                  variant="red"
                  fontSize="inherit"
                  onClick={onClose}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <ModalCloseButton d={{ md: 'none' }} />
            <ModalBody opacity={isLoading ? 0.4 : 1}>
              <Box mb="16px" w={{ base: '100%' }}>
                <Text as="span" d="flex" flexWrap="wrap">
                  <Text {...blue600}>{'{{ experience_image }}'}</Text>
                  <Text ml="4px">- Experience image</Text>
                  <Text {...blue600}>{'{{ direction_link_text }}'}</Text>
                  <Text ml="4px">- direction link text,</Text>
                  <Text {...blue600}>{'{{ direction_link_href }}'}</Text>
                  <Text ml="4px">- direction link href,</Text>
                  <Text {...blue600}>{'{{ parking_instructions }}'}</Text>
                  <Text ml="4px">- parking instructions</Text>
                </Text>
              </Box>
              <CustomTextarea
                label="Template name:"
                editMode={true}
                containerProps={{
                  ...(formikErrors.templateName && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateName')}
                externalValue={formikValues.templateName}
              />
              <CustomTextarea
                label="Theme:"
                editMode={true}
                containerProps={{
                  ...(formikErrors.templateTheme && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateTheme')}
                externalValue={formikValues.templateTheme}
              />
              <CustomTextarea
                label="Header:"
                editMode={true}
                containerProps={{
                  ...(formikErrors.templateHeader && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateHeader')}
                externalValue={formikValues.templateHeader}
              />
              <CustomTextarea
                label="Footer:"
                editMode={true}
                containerProps={{
                  ...(formikErrors.templateFooter && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateFooter')}
                externalValue={formikValues.templateFooter}
              />
            </ModalBody>
            <ModalFooter
              d={{ base: 'grid', md: 'none' }}
              opacity={isLoading ? 0.4 : 1}
              fontSize={{ base: '12px', '2xl': '14px' }}
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
            >
              <Button
                w="100%"
                variant="blue"
                isDisabled={!notificationPermission}
                onClick={formikHandleSubmit}
              >
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button w="100%" variant="red" onClick={onCloseHandler}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
