import { Box, Text } from '@chakra-ui/react'
import DropdownSelect from '../../Common/DropdownSelect'
import LimitationsList from './LimitationsList'
import { useEffect, useMemo, useState } from 'react'
import { limitationWeekMenu } from '../../../configs'

export default function LimitationsMobile({ data = [], day = '', setDay }) {
  const optionsWeek = limitationWeekMenu
  const initialStateDay = useMemo(() => {
    if (day) {
      for (let i = 0; i < optionsWeek.length; i++) {
        if (optionsWeek[i].weekday === day) {
          return optionsWeek[i]
        }
      }
      return optionsWeek[0]
    } else {
      return optionsWeek[0]
    }
  }, [day])

  const [selectedDay, setSelectedDay] = useState(initialStateDay)

  useEffect(() => {
    setDay(selectedDay.value)
  }, [selectedDay])

  function onChangeDay(obj) {
    setSelectedDay(obj)
  }

  return (
    <>
      <Box p="16px" bg="white" mb="2px">
        <Text
          color="custom.black.500"
          fontSize="24px"
          fontWeight="600"
          mb="16px"
        >
          Limitations
        </Text>
        <Box d="grid" gridTemplateColumns="1fr" gridGap="8px">
          <DropdownSelect
            selectProps={{
              main: {
                options: optionsWeek,
                placeholder: 'Day',
                onChange: onChangeDay,
                value: selectedDay
              }
            }}
          />
        </Box>
      </Box>
      <LimitationsList data={data} day={day} />
    </>
  )
}
