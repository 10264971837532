import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'

import ScheduleList from '../../Schedule/ScheduleList'
import { CrosshairIcon } from '../../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../../assets/Icons/DisketteIcon'
import { PencilIcon } from '../../../../../../../assets/Icons/PencilIcon'
import { friendlyTime } from '../../../../../../../libs'
import { newScheduleValidation } from '../../../../../../../configs/validation/schedule'
import ButtonsToggle from '../../../../../../Common/ButtonsToggle'
import FormikInput from '../../../../../../Common/FormikComponents/FormikInput'
import FormikScheduleInput from '../../../../../../Common/FormikComponents/FormikScheduleInput'

export default function EditScheduleModal({
  schedule,
  weekday,
  week,
  submitHandler,
  ...rest
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const formik = useFormik({
    initialValues: {
      shiftsStarts: schedule?.shift_starts
        ? friendlyTime(schedule?.shift_starts * 1000, 'hh:mm')
        : '',
      shiftsStartsAmOrPM: schedule?.shift_starts
        ? friendlyTime(schedule?.shift_starts * 1000, 'A')
        : 'am',
      shiftsEnds: schedule?.shift_ends
        ? friendlyTime(schedule?.shift_ends * 1000, 'hh:mm')
        : '',
      shiftsEndsAmOrPM: schedule?.shift_ends
        ? friendlyTime(schedule?.shift_ends * 1000, 'A')
        : 'pm',
      gameTime: schedule?.game_time ? schedule?.game_time : 1,
      breakTime: schedule?.break_time ? schedule?.break_time : 1,
      weekday: weekday
    },
    validationSchema: newScheduleValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitHandler(values)
      onClose()
    }
  })
  const formikValues = formik.values
  const setFieldValue = formik.setFieldValue
  const formikHandleSubmit = formik.handleSubmit

  function handleChange(prop) {
    return (val) => setFieldValue(prop, val)
  }

  return (
    <>
      <Button variant="blue" w="100%" onClick={onOpen} {...rest}>
        <PencilIcon mr="8px" />
        Edit
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
            bg="none"
            p="0"
          >
            <ModalHeader p="16px" bg="white">
              Schedule
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody m="0 0 8px" color="custom.black.500">
              <Box bg="white" mb="2px" p="16px">
                <Box mb="8px" d="grid" gridTemplateColumns="1fr 1fr">
                  <FormikScheduleInput
                    name="shiftsStarts"
                    id="shiftsStarts"
                    placeholder="HH:MM"
                    bg="custom.lightGray"
                    css={{
                      '> input': {
                        padding: 0,
                        textAlign: 'center'
                      }
                    }}
                  />

                  <ButtonsToggle
                    data={['am', 'pm']}
                    cb={handleChange('shiftsStartsAmOrPM')}
                    w="100%"
                    unCheckedColor="custom.lightGray"
                    initialState={formikValues.shiftsStartsAmOrPM}
                  />
                </Box>
                <Box mb="8px" d="grid" gridTemplateColumns="1fr 1fr">
                  <FormikScheduleInput
                    name="shiftsEnds"
                    id="shiftsEnds"
                    placeholder="HH:MM"
                    bg="custom.lightGray"
                    css={{
                      '> input': {
                        padding: 0,
                        textAlign: 'center'
                      }
                    }}
                  />
                  <ButtonsToggle
                    data={['am', 'pm']}
                    cb={handleChange('shiftsEndsAmOrPM')}
                    unCheckedColor="custom.lightGray"
                    w="100%"
                    initialState={formikValues.shiftsEndsAmOrPM}
                  />
                </Box>
                <Box
                  d="grid"
                  gridTemplateColumns="1fr 1fr"
                  alignItems="center"
                  mb="8px"
                >
                  <Text color="custom.black.50">Game Time: </Text>
                  <FormikInput
                    name="gameTime"
                    id="gameTime"
                    placeholder="Game time"
                    textAlign="center"
                  />
                </Box>

                <Box
                  d="grid"
                  gridTemplateColumns="1fr 1fr"
                  alignItems="center"
                  mb="8px"
                >
                  <Text color="custom.black.50">Break Time: </Text>
                  <FormikInput
                    name="breakTime"
                    id="breakTime"
                    placeholder="Break time"
                    textAlign="center"
                  />
                </Box>

                <Box
                  d="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridGap="8px"
                  mb="34px"
                >
                  <Button w="100%" variant="blue" onClick={formikHandleSubmit}>
                    <DisketteIcon mr="8px" />
                    Save
                  </Button>
                  <Button w="100%" variant="red" onClick={onClose}>
                    <CrosshairIcon mr="8px" />
                    Cancel
                  </Button>
                </Box>
              </Box>
              <ScheduleList />
            </ModalBody>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
