import { apiUnprotect } from '../index'
import { dataURLtoFile } from '../../libs'

export async function getWaiverWidgetByBooking(bookingCode) {
  const { data } = await apiUnprotect.get(
    '/api/waiver-widget/entity/booking/',
    {
      params: {
        code: bookingCode
      }
    }
  )
  return data
}

export async function waiverWidgetCreateEntity({
  signedFor,
  sign,
  minors,
  notif,
  player,
  phoneNumber,
  smsCode,
  bookingId,
  waiverId,
  requireAuth
}) {
  const formData = new FormData()
  const file = dataURLtoFile(sign, `sign${Date.now()}.png`)
  formData.append('sign', file)
  formData.append('signed_for', signedFor)
  formData.append('minors', minors)
  formData.append('player', JSON.stringify(player))
  if (phoneNumber !== '' && requireAuth) {
    formData.append('phone_number', phoneNumber)
    formData.append('sms_code', smsCode)
  }
  formData.append('booking_id', bookingId)
  formData.append('waiver_id', waiverId)
  formData.append('notif', notif)
  const { data } = await apiUnprotect.post(
    '/api/waiver-widget/entity/',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return data
}

export async function waiverWidgetUpdateEntity({
  signedFor,
  sign,
  minors,
  notif,
  player,
  phoneNumber,
  smsCode,
  bookingId,
  waiverId,
  waiverWidgetEntityId,
  requireAuth
}) {
  const formData = new FormData()
  if (sign) {
    const file = dataURLtoFile(sign, `sign${Date.now()}.png`)
    formData.append('sign', file)
  }
  formData.append('signed_for', signedFor)
  formData.append('minors', minors)
  formData.append('player', JSON.stringify(player))
  if (phoneNumber !== '' && requireAuth) {
    formData.append('phone_number', phoneNumber)
    formData.append('sms_code', smsCode)
  }
  formData.append('booking_id', bookingId)
  formData.append('waiver_id', waiverId)
  formData.append('notif', notif)
  const { data } = await apiUnprotect.patch(
    `/api/waiver-widget/entity/${waiverWidgetEntityId}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return data
}

export async function waiverWidgetSendSms({
  phoneNumber,
  bookingId,
  waiverId
}) {
  const { data } = await apiUnprotect.post(
    '/api/waiver-widget/entity/send_sms/',
    {
      phone_number: phoneNumber,
      booking_id: bookingId,
      waiver_id: waiverId
    }
  )
  return data
}

export async function waiverWidgetValidateSms({
  phoneNumber,
  smsCode,
  bookingId,
  waiverId
}) {
  const { data } = await apiUnprotect.post(
    '/api/waiver-widget/entity/validate_sms/',
    {
      phone_number: phoneNumber,
      booking_id: bookingId,
      waiver_id: waiverId,
      sms_code: smsCode
    }
  )
  return data
}

export async function getWaiverWidgetEntity(waiverId) {
  const { data } = await apiUnprotect.get(
    `/api/waiver-widget/entity/${waiverId}/`
  )
  return data
}
