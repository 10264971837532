import DatePicker from 'react-datepicker'
import { useEffect, useState } from 'react'
import './custom-datepicker.css'
import './custom-div.css'
import { Box } from '@chakra-ui/react'

export default function DataPicker({
  cb = null,
  selected = null,
  datepicker = {},
  ...rest
}) {
  const [date, setDate] = useState(selected || new Date())

  useEffect(() => {
    if (cb !== null && cb !== undefined && typeof cb === 'function') {
      cb(date)
    }
  }, [date])

  return (
    <Box d="flex" justifyContent="center" {...rest}>
      <div className="wrp">
        <DatePicker
          selected={selected}
          onChange={(date) => setDate(date)}
          inline
          {...datepicker}
        />
      </div>
    </Box>
  )
}
