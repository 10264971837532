import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { getBookings } from '../../api/bookings'

export function useBookings(filters, queryOption = {}) {
  const queryKeys = useMemo(() => {
    const array = ['bookings']
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        array.push(filters[key])
      }
    })
    return array
  }, [filters])

  return useQuery(queryKeys, () => getBookings(filters), {
    ...queryOption
  })
}
