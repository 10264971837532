import { useTheme } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Box, Button } from '@chakra-ui/react'

export default function CustomDaySelector({
  initialState = [],
  needIdx = false,
  readOnly = false,
  cb = null,
  skipFirstRun = false,
  isDisabled = false,
  ...rest
}) {
  const theme = useTheme()
  const customColors = theme.colors.custom
  const week = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ]
  const [isFirstRun, setIsFirstRun] = useState(skipFirstRun)
  const [checkedDays, setCheckedDays] = useState(initialState)
  const [checkedDaysIdx, setCheckedDaysIdx] = useState([])
  const [alreadyUpdate, setAlreadyUpdate] = useState(false)

  useEffect(() => {
    if (initialState.length !== 0 && !alreadyUpdate) {
      const final = []
      initialState.forEach((idx) => {
        final.push(week[idx])
      })
      setCheckedDays(final)
      setCheckedDaysIdx(initialState)
      setAlreadyUpdate(true)
    }

    if (!alreadyUpdate) {
      setAlreadyUpdate(true)
    }
  }, [initialState])

  useEffect(() => {
    if (alreadyUpdate) {
      if (cb !== null && typeof cb === 'function' && !isFirstRun) {
        if (needIdx) {
          cb(checkedDaysIdx)
        } else {
          cb(checkedDays)
        }
      }
      if (isFirstRun) {
        setIsFirstRun(false)
      }
    }
  }, [checkedDays, alreadyUpdate])

  function isChecked(day) {
    return checkedDays.includes(day)
  }

  function onClickHandler(day, idx) {
    return () => {
      if (!readOnly) {
        if (checkedDays.includes(day)) {
          const newArr = []
          checkedDays.forEach((dayEl) => {
            if (dayEl !== day) {
              newArr.push(dayEl)
            }
          })
          const newArrIdx = []
          checkedDaysIdx.forEach((dayEl) => {
            if (dayEl !== idx) {
              newArrIdx.push(dayEl)
            }
          })
          setCheckedDays(newArr)
          setCheckedDaysIdx(newArrIdx)
        } else {
          if (setCheckedDays.length !== 0) {
            setCheckedDays([...checkedDays, day])
          } else {
            setCheckedDays([day])
          }
          if (setCheckedDaysIdx.length !== 0) {
            setCheckedDaysIdx([...checkedDaysIdx, idx])
          } else {
            setCheckedDaysIdx([day])
          }
        }
      }
    }
  }

  return (
    <Box
      d="grid"
      gridTemplateColumns={`repeat(${week.length}, 1fr)`}
      fontSize={{ base: '12px', '2xl': '14px' }}
      bg="custom.lightGray"
      border={`1px solid ${customColors.borderBlue}`}
      {...rest}
    >
      {week?.map((day, idx) => (
        <Button
          h="100%"
          minH="40px"
          isDisabled={isDisabled}
          maxH="56px"
          key={'buttonsToggle' + idx + new Date().valueOf()}
          borderRadius="none"
          fontSize="inherit"
          bg="transparent"
          padding="0"
          minW="unset"
          textTransform="capitalize"
          fontWeight="500"
          color={isChecked(day) ? 'custom.blue.600' : 'custom.gray.300'}
          borderBottom={
            isChecked(day) && !readOnly
              ? `2px solid ${customColors.blue[600]}`
              : `2px solid transparent`
          }
          _focus={{ boxShadow: 'none' }}
          _hover={false}
          _active={false}
          onClick={onClickHandler(day, idx)}
        >
          {day.slice(0, 1).toUpperCase()}
        </Button>
      ))}
    </Box>
  )
}
