import { Box, Button, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { matchMedia768InState, USERS_ROLES_URL } from '../../../../constants'
import UsersTable from './UsersTable'
import UsersList from './UsersList'
import AddUserModal from './Modals/AddUserModal'
import { PlusIcon } from '../../../../assets/Icons/PlusIcon'
import { useUsers } from '../../../../libs/hooks/useUsers'
import { useUsersRoles } from '../../../../libs/hooks/useUsersRoles'
import { useAllLocations } from '../../../../libs/hooks/useLocations'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

export default function Users() {
  const history = useHistory()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { data: { results: usersData = [] } = {} } = useUsers()
  const { data: usersRoles = [] } = useUsersRoles()
  const { data: { results: locations = [] } = {} } = useAllLocations()
  const rolesObject = useMemo(() => {
    const obj = {}
    if (usersRoles.length !== 0) {
      usersRoles.forEach((userRole) => {
        return (obj[userRole.id] = userRole)
      })
    }
    return obj
  }, [usersRoles])

  const locationObject = useMemo(() => {
    const obj = {}
    if (locations.length !== 0) {
      locations.forEach((location) => {
        obj[location.id] = location
      })
    }
    return obj
  }, [locations])

  function goTo(url) {
    return () => history.push(url)
  }

  const data = useMemo(() => {
    if (usersData?.length !== 0 && Object.keys(locationObject).length !== 0) {
      return usersData.map((user) => {
        let locationArray = []
        if (user?.locations?.length !== 0) {
          for (let i = 0; i < user.locations.length; i++) {
            const location = user.locations[i]
            if (locationObject[location]) {
              locationArray.push(locationObject[location])
            }
          }
        }
        return {
          ...user,
          locations: locationArray,
          locationsIds: user.locations,
          role: rolesObject[user.role]
        }
      })
    } else {
      return []
    }
  }, [usersData, rolesObject, locationObject])

  useEffect(() => {
    if (data.length !== 0) {
      data.sort((a, b) => b.id - a.id)
    }
  }, [data])

  return (
    <>
      <Box
        p={{ base: '16px', '2xl': '24px' }}
        bg="white"
        d="flex"
        alignItems="center"
        mb="2px"
      >
        <Text
          color="custom.black.500"
          fontSize={{ base: '18px', '2xl': '24px' }}
          fontWeight="500"
        >
          Users
        </Text>
        <AddUserModal />
        <Button
          variant="blue"
          d={{ base: 'none', '2xl': 'block' }}
          ml={{ '2xl': '16px' }}
          bg={{ '2xl': 'custom.blue.600' }}
          color={{ '2xl': 'white' }}
          p={{ '2xl': '0 25.5px' }}
          alignItems="center"
          fontWeight="500"
          fontSize={{ '2xl': '14px' }}
          onClick={goTo(USERS_ROLES_URL)}
        >
          <PlusIcon mr="8px" color={{ '2xl': 'white' }} />
          Roles settings
        </Button>
      </Box>
      {isLargerThan768 ? (
        <UsersTable tableData={data} />
      ) : (
        <UsersList data={data} />
      )}
    </>
  )
}
