import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import DataRow from '../../Common/DataRow'
import GBaseTag from '../../Common/GBaseTag'
import { useTheme } from '@emotion/react'
import { COMPLETED, RUNNING, UPCOMING } from '../../../constants'
import UpcomingButtons from './UpcomingButtons'
import * as changeCase from 'change-case'
import CompletedButtons from './CompletedButtons'
import RunningButtons from './RunningButtons'
import AddPlayersModal from '../Modals/AddPlayersModal'
import CancelGameModal from '../Modals/CancelGameModal'
import NoShowModal from '../Modals/NoShowModal'
import FinishGameModal from '../Modals/FinishGameModal'
import ReebookModal from '../Modals/Reebook'
import { valueGameStatus } from '../../../configs'
import { useContext, useMemo, useState } from 'react'
import Preloader from '../../Common/Preloader'
import { useParams } from 'react-router-dom'
import { useRevertToRunning } from '../../../libs/hooks/useRevertToRunning'
import { useQueryClient } from 'react-query'
import { useStartGame } from '../../../libs/hooks/useStartGame'
import { BookingContext } from '../../../pages/Booking'
import ResendNotificationModal from '../Modals/ResendNotificationModal'
import { friendlyTime } from '../../../libs'
import ResendPicturesModal from '../Modals/ResendPicturesModal'
import StartGameErrorModal from '../Modals/StartGameErrorModal'
import RemovePlayersModal from '../Modals/RemovePlayersModal'
import { currency } from '../../../libs/index'

export default function GameStatus({ ...rest }) {
  const queryClient = useQueryClient()
  const {
    isOpen: isOpenStartError,
    onOpen: onOpenStartError,
    onClose: onCloseStartError
  } = useDisclosure()
  const toast = useToast()
  const [data = {}, isLoading, filters, setFilters, queryKeysBooking] =
    useContext(BookingContext)
  const type = data?.status?.toUpperCase() || UPCOMING
  const players = data?.players || ''
  const { id } = useParams()
  const [totalPrice, setTotalPrice] = useState('0')
  const [fee, setFee] = useState('0')
  const theme = useTheme()
  const customColors = theme.colors.custom
  const { mutate: revertToRunning } = useRevertToRunning()
  const { mutate: startGame } = useStartGame()
  const discounts = []
  const [startGameError, setStartGameError] = useState('')
  const {
    eventvariant: { name: experience = '' } = {},
    start_time = '',
    event: { location: { name: locationName = '' } = {} } = {}
  } = data

  function correctValue(value, key) {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No'
    } else {
      switch (key) {
        case 'price': {
          return `$${+(value / 100).toFixed(2)}`
        }

        case 'start_time': {
          return friendlyTime(value, 'h:mm A - MM.DD.YY')
        }

        default:
          return value
      }
    }
  }

  function letsStarted() {
    startGame(
      { bookingId: id },
      {
        onSuccess: () => queryClient.invalidateQueries(queryKeysBooking),
        onError: (e) => {
          const msgObjects = e.response.data
          setStartGameError(msgObjects)
          onOpenStartError()
        }
      }
    )
  }

  function revertToRunningHandler() {
    revertToRunning(
      { bookingId: id },
      { onSuccess: () => queryClient.invalidateQueries(queryKeysBooking) }
    )
  }

  const YesOrNo = (value) => (value ? 'Yes' : 'No')
  let calculatedTotalCents = data.price + data.fee
  if (data.private_game) calculatedTotalCents += data.private_game_price.price

  const gameStatusFields = {
    Code: data.code,
    Experience: data.eventvariant?.name,
    Players: data.players,
    'Special Players': data.special_players,
    'Start Time': friendlyTime(data.start_time, 'h:mm A - MM.DD.YY'),
    Subtotal: currency.format(data.price / 100),
    'No Show': YesOrNo(data.no_show),
    'Need to refund': YesOrNo(data.need_to_refund),
    'Private Game': `${YesOrNo(data.private_game)} (${currency.format(
      data.private_game_price?.price * 0.01
    )})`,
    'Total Price': currency.format(calculatedTotalCents * 0.01)
  }

  return (
    <Accordion
      allowToggle
      defaultIndex={[0]}
      bg="white"
      borderStyle="hidden"
      p={{ base: '16px', '2xl': '24px' }}
      mb={{ base: '16px', '2xl': '32px' }}
      fontFamily="inherit"
      {...rest}
    >
      <AccordionItem borderStyle="hidden">
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton
                p="2px 2px 2px 0"
                fontWeight="500"
                fontSize={{ base: '18px', '2xl': '24px' }}
                height="32px"
                mb={{ base: '8px', '2xl': '16px' }}
                _hover={{ bg: 'white' }}
                _focus={{ border: 'none' }}
                _active={{ bg: 'white' }}
              >
                <Box
                  d="flex"
                  w={{ base: '100%', md: 'unset', '2xl': '100%' }}
                  justifyContent={{
                    base: 'space-between',
                    md: 'flex-start',
                    '2xl': 'space-between'
                  }}
                  alignItems="center"
                >
                  <Text mr={{ base: 'unset', md: '18px', '2xl': 'unset' }}>
                    Game status:
                  </Text>
                  <GBaseTag label={type} />
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel p="0">
              <Box
                d={{ base: 'initial', md: 'flex', '2xl': 'initial' }}
                flexWrap="wrap"
                w={{ base: '100%' }}
              >
                {isLoading ? (
                  <Preloader />
                ) : (
                  <>
                    {Object.entries(gameStatusFields).map(
                      ([key, value]) => (
                        <DataRow
                          title={key}
                          value={value}
                          key={'game-status-' + key}
                          w={{ base: '100%', md: 'unset', '2xl': '100%' }}
                          keyStyle={
                            key === 'Total Price' && {
                              color: customColors.blue[600],
                              fontWeight: 500
                            }
                          }
                          valueStyle={{
                            ...(key === 'Total Price' && {
                              color: customColors.blue[600],
                              fontWeight: 500
                            }),
                            margin: {
                              base: '0',
                              md: '0 16px 0 8px',
                              '2xl': '0'
                            }
                          }}
                        />
                      )
                    )}

                    {discounts.length !== 0 && (
                      <Box
                        d={{ base: 'initial', md: 'flex', '2xl': 'initial' }}
                        w="100%"
                        flexWrap="wrap"
                        alignItems="center"
                      >
                        <Text
                          fontWeight="500"
                          color="custom.black.500"
                          mb={{ base: '12px', md: '6px', '2xl': '12px' }}
                          mt={{ base: '8px', md: '6px', '2xl': '18px' }}
                          fontSize={{ base: '14px', '2xl': '18px' }}
                          w={{ base: 'unset', md: '100%', '2xl': 'unset' }}
                        >
                          Discounts:
                        </Text>
                        {discounts[0]?.value?.map((discount, idx) => {
                          return (
                            <DataRow
                              title={'Discount ' + (idx + 1)}
                              value={'$' + discount}
                              key={'gameStatusDisc' + discount + idx}
                              valueStyle={{
                                margin: {
                                  base: '0',
                                  md: '0 16px 0 8px',
                                  '2xl': '0'
                                }
                              }}
                              w={{ base: '100%', md: 'unset', '2xl': '100%' }}
                            />
                          )
                        })}
                        <DataRow
                          title={'Total price'}
                          value="$10"
                          mb={{ base: '16px', md: '4px', '2xl': '16px' }}
                          w={{ base: '100%', md: 'unset', '2xl': '100%' }}
                          keyStyle={{
                            color: customColors.blue[600],
                            fontWeight: 500
                          }}
                          valueStyle={{
                            color: customColors.blue[600],
                            fontWeight: 500,
                            margin: {
                              base: '0',
                              md: '0 16px 0 8px',
                              '2xl': '0'
                            }
                          }}
                        />
                      </Box>
                    )}

                    {/* {discounts.length === 0 && (
                      <DataRow
                        title={'Total price'}
                        value={totalPrice}
                        mb={{ base: '16px', md: '4px', '2xl': '16px' }}
                        w={{ base: '100%', md: 'unset', '2xl': '100%' }}
                        keyStyle={{
                          color: customColors.blue[600],
                          fontWeight: 500
                        }}
                        valueStyle={{
                          color: customColors.blue[600],
                          fontWeight: 500,
                          margin: { base: '0', md: '0 16px 0 8px', '2xl': '0' }
                        }}
                      />
                    )} */}
                  </>
                )}
                {type === UPCOMING && (
                  <UpcomingButtons>
                    <ResendNotificationModal />
                    <CancelGameModal
                      locationName={locationName}
                      startTime={start_time}
                      experience={experience}
                    />
                    <ReebookModal
                      locationName={locationName}
                      startTime={start_time}
                      experienceB={experience}
                      players={players}
                      type={type}
                    />
                    <NoShowModal
                      locationName={locationName}
                      startTime={start_time}
                      experience={experience}
                    />
                    <StartGameErrorModal
                      onOpen={onOpenStartError}
                      isOpen={isOpenStartError}
                      onClose={onCloseStartError}
                      errors={startGameError}
                    />
                    <AddPlayersModal id={id} players={players} />
                    <RemovePlayersModal />
                    <Button
                      variant="blue"
                      onClick={letsStarted}
                      gridColumn="1/3"
                    >
                      <Text
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        Start game
                      </Text>
                    </Button>
                  </UpcomingButtons>
                )}
                {type === COMPLETED && (
                  <CompletedButtons>
                    <ResendPicturesModal />
                    <Button variant="red" onClick={revertToRunningHandler}>
                      <Text
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        Revert to running
                      </Text>
                    </Button>
                  </CompletedButtons>
                )}
                {type === RUNNING && (
                  <RunningButtons>
                    <FinishGameModal
                      locationName={locationName}
                      startTime={start_time}
                      experienceB={experience}
                    />
                    <Button variant="red" onClick={revertToRunningHandler}>
                      <Text
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        Revert
                      </Text>
                    </Button>
                  </RunningButtons>
                )}
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
