import { FormikProvider, useFormik } from 'formik'

import WaiverInfoPublished from '../WaiverInfoPublished'
import WaiverInfoSaved from '../WaiverInfoSaved'
import { waiverInfoValidation } from '../../../../../../configs/validation/waiverInfoValidation'
import ButtonsToggle from '../../../../../Common/ButtonsToggle'

export default function WaiverInfo({ waiver = {} }) {
  const phone = waiver?.phone || ''
  const email = waiver?.email || ''
  const address = waiver?.address || ''
  const logo = waiver?.logo || ''
  const logoDraft = waiver?.logo_draft || ''
  const phoneDraft = waiver?.phone_draft || ''
  const emailDraft = waiver?.email_draft || ''
  const addressDraft = waiver?.address_draft || ''
  const waiverId = waiver?.id
  const publishNotEmpty = !!phone || !!email || !!logo || !!address
  const savedNotEmpty =
    !!phoneDraft || !!emailDraft || !!logoDraft || !!addressDraft

  const formik = useFormik({
    validationSchema: waiverInfoValidation,
    initialValues: {
      phone,
      email,
      address,
      logo,
      phoneDraft,
      emailDraft,
      addressDraft,
      logoDraft,
      type: savedNotEmpty ? 'Saved' : 'Published'
    },
    enableReinitialize: true
  })
  const formikValues = formik.values
  const type = formikValues.type
  const setFieldValue = formik.setFieldValue

  function savedHandler() {
    return (data) => {
      if (data === 'Saved' && type === 'Published') {
        setFieldValue('type', 'Saved')
      }
      if (data === 'Published' && type === 'Saved') {
        setFieldValue('type', 'Published')
      }
    }
  }

  return (
    <>
      <ButtonsToggle
        data={['Saved', 'Published']}
        cb={savedHandler()}
        initialState={type}
        skipInitial={true}
        useExternalControl={true}
        gridColumn="1/3"
      />
      <FormikProvider value={formik}>
        {type === 'Published' && <WaiverInfoPublished waiverId={waiverId} />}
        {type === 'Saved' && <WaiverInfoSaved waiverId={waiverId} />}
      </FormikProvider>
    </>
  )
}
