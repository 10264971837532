import { Text } from '@chakra-ui/react'

export const H1 = ({ children }) => {
  return (
    <Text
      as="h1"
      color="black"
      fontWeight="600"
      fontSize={{ base: '24px' }}
    >
      {children}
    </Text>
  )
}
