import {
  acceptChanges,
  cloneElementForEdit,
  setEdit
} from '../localState/actions'

export function editHandler(id, edit, dispatch, itsCreate = false) {
  return () => {
    if (!edit.enabled && !itsCreate) {
      dispatch(cloneElementForEdit(+id))
    }
    dispatch(setEdit({ enabled: true, idCell: +id }))
  }
}

export function acceptHandler(id, dispatch) {
  return () => {
    dispatch(acceptChanges(+id))
    dispatch(setEdit({ enabled: false, idCell: null }))
  }
}

export function dismissHandler(dispatch) {
  return () => {
    dispatch(cloneElementForEdit(null))
    dispatch(setEdit({ enabled: false, idCell: null }))
  }
}
