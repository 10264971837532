import { Box, Input, InputGroup, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { matchMedia1440InState, matchMedia768InState } from '../../../constants'

export default function TwoStyledInput({
  value = '',
  placeholder = '',
  isDisabled = false,
  onChange = null,
  bg = '',
  innerRef,
  inputs = {},
  ...rest
}) {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)

  function inputVariant() {
    return isLargerThan768 ? 'gbase' : 'gbase2'
  }

  function onChangeHandler() {
    return (e) => {
      if (onChange !== null && typeof onChange === 'function') {
        onChange(e.target.value)
      }
    }
  }

  return (
    <Box {...rest}>
      {isLargerThan1440 ? (
        <InputGroup>
          <Text
            pos="absolute"
            zIndex="1"
            top="8px"
            left="15px"
            fontSize="10px"
            color="custom.gray.300"
          >
            {placeholder}
          </Text>
          <Input
            variant="gbase"
            h="56px"
            p="18px 14px 0px"
            value={value}
            ref={innerRef}
            onChange={onChangeHandler()}
            isDisabled={isDisabled}
            {...inputs}
            bg={bg ? bg : 'custom.lightGray'}
          />
        </InputGroup>
      ) : (
        <Input
          variant={inputVariant()}
          placeholder={placeholder}
          isDisabled={isDisabled}
          value={value}
          ref={innerRef}
          onChange={onChangeHandler()}
          bg={bg ? bg : 'custom.lightGray'}
          {...inputs}
          fontSize={{ base: '12px', '2xl': '14px' }}
        />
      )}
    </Box>
  )
}
