import { useFormikContext } from 'formik'
import { useEffect } from 'react'

export default function SingingWaiver() {
  const { values, setFieldValue, errors } = useFormikContext()
  const selectedProfile = values.selectedProfile
  const signedForFromWaiver = selectedProfile?.waiver?.signed_for
  const signedForFromCommon = values.signedFor
  const someMinorIsEdit = values?.someMinorIsEdit
  const newMinorMode = values.newMinorMode
  const formFulfilled = Object.keys(errors).length === 0
  const someNotComplete = someMinorIsEdit || newMinorMode

  const buttonsToggleData = ['Myself', 'Child', 'Both']
  const initialValue = signedForFromWaiver
    ? correctValue(signedForFromWaiver, 'output')
    : signedForFromCommon
    ? correctValue(signedForFromCommon, 'output')
    : buttonsToggleData[0]
  useEffect(() => {
    if (!signedForFromWaiver && !signedForFromCommon) {
      setFieldValue('signedFor', 'M')
    }
  }, [signedForFromWaiver])

  function correctValue(type, returnType = 'input') {
    if (returnType === 'input') {
      switch (type) {
        case buttonsToggleData[0]:
          return 'M'
        case buttonsToggleData[1]:
          return 'C'
        case buttonsToggleData[2]:
          return 'M&C'
      }
    }

    if (returnType === 'output') {
      switch (type) {
        case 'M':
          return buttonsToggleData[0]
        case 'C':
          return buttonsToggleData[1]
        case 'M&C':
          return buttonsToggleData[2]
      }
    }
  }

  function onChangeHandler(val) {
    return () => {
      if (signedForFromWaiver) {
        setFieldValue(
          'selectedProfile.waiver.signed_for',
          correctValue(val, 'input')
        )
      } else {
        setFieldValue('signedFor', correctValue(val, 'input'))
      }
    }
  }

  function mapButtonsValue(value) {
    if (value === 'Both') return 'Myself & Child'
    return value
  }

  return (
    <>
      <span className="waiver-widget--title-text">
        Who are you signing a waiver for?
      </span>

      <div className="waiver-widget--buttons-selector">
        {buttonsToggleData.map((button, idx) => {
          return (
            <button
              className={`waiver-widget--select-button ${
                initialValue === button ? 'checked' : ''
              }`}
              onClick={onChangeHandler(button)}
              disabled={formFulfilled}
              key={button}
            >
              <span>{mapButtonsValue(button)}</span>
              <span>{button}</span>
            </button>
          )
        })}
      </div>
    </>
  )
}
