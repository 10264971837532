import * as yup from 'yup'

import { cleanPhoneNumber } from '../../../libs'

export const newUserModalValidation = yup.object().shape({
  firstname: yup
    .string()
    .test('length', 'Must be less than 30 characters', (val) =>
      val ? val.length < 30 : false
    )
    .required(),
  lastname: yup
    .string()
    .test('length', 'Must be less than 30 characters', (val) =>
      val ? val.length < 30 : false
    )
    .required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .required()
    .test('phone length', 'must be 11 charters length', (val) => {
      if (val && val.length) {
        const value = cleanPhoneNumber(val)
        return /\d{10}/.test(value)
      } else return false
    }),
  password: yup.string().required()
})
