import { api } from '../index'

export async function getStatisticsPartners({ from = null, to = null }) {
  try {
    const { data } = await api.get('/api/statistics/partners/', {
      params: {
        from_date: from,
        to_date: to
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getStatisticsLocation({ from = null, to = null }) {
  try {
    const { data } = await api.get('/api/statistics/locations/', {
      params: {
        from_date: from,
        to_date: to
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function updateStatistics(type) {
    const { data } = await api.get(`api/statistics/${type}/update`)
    return data
}
