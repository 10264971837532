import { Box } from '@chakra-ui/react'
import { usePageTitle } from '@/libs/hooks/usePageTitle'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { setStatusFilter } from '../../actions'
import { resetPagination, weekdayIndex } from '../../libs'
import { useBookings } from '../../libs/hooks/useBookings'
import usePrevious from '../../libs/hooks/usePrevious'
import BookingCalendar from '@/components/Bookings/BookingCalendar'
import BookingTable from '@/components/Bookings/BookingTable'
import DateBar from '@/components/Bookings/BookingTable/DateBar'
import SearchModule from '@/components/Bookings/BookingTable/SearchModule'
import StatusSelector from '@/components/Bookings/BookingTable/StatusSelector'
import TitleModule from '@/components/Bookings/BookingTable/TitleModule'
import WithPagination2 from '@/components/Common/WithPagination2'
import {
  authTokenInState,
  BOOKINGS_URL,
  bookingsSortByLocInState,
  bookingsSortByTimeInState,
  CALENDAR_URL,
  locationFilterInState,
  matchMedia768InState,
  statusValueInState
} from '../../constants'

export default function Bookings({ mode = 'table' }) {
  usePageTitle('Bookings')
  const dispatch = useDispatch()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const authToken = useSelector(authTokenInState)
  const statusValue = useSelector(statusValueInState)
  const paginationResetId = 'booking-pagination-id'
  const [search, setSearch] = useState('')
  const history = useHistory()
  const [type, setType] = useState(mode)
  const { pathname } = useLocation()
  const [weekday, setWeekday] = useState(null)
  const { value: locationId } = useSelector(locationFilterInState)
  const sortByLoc = useSelector(bookingsSortByLocInState)
  const sortByTime = useSelector(bookingsSortByTimeInState)
  const [paginationState, setPaginationState] = useState({
    page: 1,
    pageSize: 15,
    totalCountItems: ''
  })

  useEffect(() => {
    if (pathname === '/calendar' && type !== 'calendar') {
      setType('calendar')
    }
    if (pathname === '/bookings' && type !== 'table') {
      setType('table')
    }
  }, [pathname])

  const sortByLocHandler = useMemo(() => {
    switch (sortByLoc) {
      case 'up':
        return 'event__location__name'
      case 'down':
        return '-event__location__name'
      case '':
        return ''
    }
  }, [sortByLoc])

  const sortByStartTimeHandler = useMemo(() => {
    switch (sortByTime) {
      case 'up':
        return 'start_time'
      case 'down':
        return '-start_time'
      case '':
        return ''
    }
  }, [sortByTime])

  const bookingsQueryKeys = useMemo(() => {
    const keys = {
      page: paginationState.page,
      pageSize: paginationState.pageSize,
      statusValue,
      search,
      location: locationId
    }
    const array = ['bookings']
    Object.keys(keys).forEach((key) => {
      if (keys[key]) {
        array.push(keys[key])
      }
    })
    return array
  }, [paginationState, statusValue, search, locationId])

  const orderStringHandler =
    !!sortByLocHandler && !!sortByStartTimeHandler
      ? sortByLocHandler + ',' + sortByStartTimeHandler
      : sortByLocHandler || sortByStartTimeHandler
  const {
    data: { results: bookings = [], count: total = '' } = {},
    isLoading,
    refetch: dataBookingRefetch
  } = useBookings(
    {
      page: paginationState?.page,
      pageSize: paginationState?.pageSize,
      status: statusValue,
      search: search,
      location: locationId,
      order: orderStringHandler
    },
    {
      enabled: !!authToken,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  useEffect(() => {
    if (typeof statusValue === 'number') {
      const day = weekdayIndex(statusValue)
      setWeekday(day)
    }

    if (statusValue === 'today') {
      const day = weekdayIndex(Date.now())
      setWeekday(day)
    }
    // if (!!paginationState.totalCountItems) {
    //     setPaginationState({
    //         ...paginationState,
    //         page: 1,
    //     })
    // }

    dataBookingRefetch()

    resetPagination(paginationResetId)
  }, [statusValue])

  const prev = usePrevious(type)

  useEffect(() => {
    if (type === 'calendar') {
      if (typeof statusValue !== 'number' && statusValue !== 'today') {
        dispatch(setStatusFilter({ value: 'today', label: 'Today' }))
      }
      if (paginationState.totalCountItems === total) {
        setPaginationState({
          ...paginationState,
          page: 1,
          pageSize: 1000
        })
      }
    }

    if (type === 'table' && prev === 'calendar') {
      if (paginationState.totalCountItems === total) {
        setPaginationState({
          ...paginationState,
          page: 1,
          pageSize: 15
        })
      }
    }
  }, [type, paginationState.totalCountItems, total])

  useEffect(() => {
    if (!isLargerThan768 && type === 'calendar') {
      history.push(BOOKINGS_URL)
    }
  }, [isLargerThan768])

  // useEffect(() => {
  //     if (paginationState.totalCountItems !== total) {
  //         resetPagination(paginationResetId);
  //     }
  // }, [total, paginationState.totalCountItems]);

  function searchHandler() {
    return (val) => {
      console.log('hello mi');
      if (!paginationState.totalCountItems) {
        setPaginationState({
          ...paginationState,
          page: 1
        })
      }
      setSearch(val)
    }
  }

  function createTab(type) {
    switch (type) {
      case 'table': {
        return (
          <>
            <WithPagination2
              totalCountItems={total}
              state={paginationState}
              setState={setPaginationState}
              id={paginationResetId}
              isLoading={isLoading}
              actualLength={bookings.length}
            >
              <BookingTable
                data={bookings}
                isLoading={isLoading}
                bookingsQueryKeys={bookingsQueryKeys}
              />
            </WithPagination2>
          </>
        )
      }
      case 'calendar': {
        return (
          <BookingCalendar
            data={bookings}
            isLoading={isLoading}
            weekday={weekday}
            tableDataRefetch={dataBookingRefetch}
          />
        )
      }
    }
  }

  function typeHandler() {
    return (type) => {
      if (type === 'table') history.push(BOOKINGS_URL)
      if (type === 'calendar') history.push(CALENDAR_URL)
    }
  }

  return (
    <Box
      p={{
        base: '24px 16px 82px',
        md: '48px 24px 82px',
        '2xl': '66px 48px 82px'
      }}
    >
      {isLargerThan768 && (
        <TitleModule title="Bookings" type={type} setType={typeHandler()} />
      )}
      <Box
        minW="288px"
        w="100%"
        bg={{ base: 'white', md: 'none' }}
        height={{ base: '280px', md: 'auto' }}
        p={{ base: '16px', md: '0' }}
        pos={{ base: 'relative', md: 'sticky' }}
        zIndex="10"
        top={{ base: 'unset', md: '-58px', '2xl': '-66px' }}
        borderBottom="2px solid"
        borderColor="custom.lightGray"
        fontSize={{ base: '12px', '2xl': '14px' }}
      >
        {type === 'calendar' && <DateBar isLoading={isLoading} />}
        {type === 'table' && <StatusSelector type={type} />}
        <SearchModule callback={searchHandler()} />
      </Box>
      {createTab(type)}
    </Box>
  )
}
