import { Box } from '@chakra-ui/react'
import BurgerMenu from './BurgerMenu'
import HeaderLogo from './HeaderLogo'
import HeaderProfile from './HeaderProfile'
import NavigationMenu from './NavigationMenu'
import { useSelector } from 'react-redux'
import { matchMedia1440InState } from '../../constants'

export default function Header({ children }) {
  const isLargerThan1440 = useSelector(matchMedia1440InState)

  return (
    <>
      <Box
        height="72px"
        d="flex"
        justifyContent="space-between"
        bg="white"
        alignItems="center"
        p="0 18px"
        pos="relative"
        zIndex="100"
      >
        {!isLargerThan1440 && <BurgerMenu />}
        <HeaderLogo />
        {isLargerThan1440 && <NavigationMenu />}
        <HeaderProfile />
      </Box>
      {children}
    </>
  )
}
