import { Box, Button, Link, Text, useTheme } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { changeFormatAndTimezone } from '@/libs'
import { usePatchWaiversentitys } from '@/libs/hooks/useWaiversentitys'

import TableCreator from '@/components/Common/TableCreator'

import { SimpleSkullIcon } from '@/assets/Icons/SimpleSkullIcon'
import {
  COLUMNS_WITH_SORTING_WAIVERS,
  TABLE_COLUMN_VALUES_WAIVERS
} from '@/configs'

import { TABLE_COLUMNS_SIZES_WAIVERS } from '../../../../configs/tablesSizes'

export default function WaiversTable({ tableData = [] }) {
  const queryClient = useQueryClient()
  const { id: bookingId } = useParams()
  const theme = useTheme()
  const { mutate: patchWaiversentitys } = usePatchWaiversentitys()

  function deleteHandler(waiversentitysId, bookings) {
    return () => {
      const newArray = [...bookings]
      const indexDelete = newArray.indexOf(+bookingId, 0)

      if (indexDelete !== -1) {
        newArray.splice(indexDelete, 1)
      }

      const objToSend = {
        waiversentitysId: +waiversentitysId,
        bookings: newArray
      }

      patchWaiversentitys(
        {
          obj: objToSend
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['waiversentitys', +bookingId])
          }
        }
      )
    }
  }

  const customsColor = theme.colors.custom
  const data = useMemo(() => tableData, [tableData])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 16px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_WAIVERS.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'full name') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          if (original.lastname || original.firstname) {
            return (
              <Box d="flex" justifyContent="center" alignItems="center">
                <Box
                  w="20px"
                  h="20px"
                  borderRadius="50%"
                  bg="custom.lightGray"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                  justifySelf="start"
                  mr="8px"
                >
                  <SimpleSkullIcon
                    w="60%"
                    h="60%"
                    color={
                      original.inBlacklist
                        ? 'custom.red.600'
                        : 'custom.black.50'
                    }
                  />
                </Box>
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {original.firstname ? original.firstname : ''}
                  &nbsp;
                  {original.lastname ? original.lastname : ''}
                </Text>
              </Box>
            )
          } else {
            return <Text color="custom.gray.200">null</Text>
          }
        }
      }

      if (field.Header.toLowerCase() === 'phone') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          if (original.phone && original.phone !== null) {
            return <>{original.phone}</>
          } else {
            return <Text color="custom.gray.200">null</Text>
          }
        }
      }

      if (field.Header.toLowerCase() === 'e-mail') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          if (original.email && original.email !== null) {
            return <Text textTransform="lowercase">{original.email}</Text>
          } else {
            return <Text color="custom.gray.200">null</Text>
          }
        }
      }

      if (field.Header.toLowerCase() === 'date') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          if (original.sign_time && original.sign_time !== null) {
            return (
              <>
                {changeFormatAndTimezone(
                  original?.sign_time,
                  original?.timezone,
                  'hh:mm A - MM.DD.YY'
                )}
              </>
            )
          } else {
            return <Text color="custom.gray.200">null</Text>
          }
        }
      }

      if (field.Header.toLowerCase() === 'waiver in pdf') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return (
            <Box d="flex" justifyContent="center">
              <Button
                variant="red"
                bg="none"
                minW="unset"
                p="0"
                mr={{ md: '8px', '2xl': '16px' }}
                textDecoration="underline"
                onClick={deleteHandler(original.id, original.bookings)}
              >
                Remove
              </Button>
              <Box d="flex" alignItems="center">
                {original?.doc ? (
                  <Link
                    href={`${original?.doc}`}
                    target="_blank"
                  >
                    <Text textDecoration="underline" color="custom.blue.600">
                      Get PDF
                    </Text>
                  </Link>
                ) : (
                  <Text
                    textDecoration="underline"
                    cursor="not-allowed"
                    color="custom.gray.200"
                  >
                    Get PDF
                  </Text>
                )}
              </Box>
            </Box>
          )
        }
      }
      return fieldObject
    })
  }, [data])
  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sortingColumns={COLUMNS_WITH_SORTING_WAIVERS}
      sizesMap={TABLE_COLUMNS_SIZES_WAIVERS}
    />
  )
}
