import { Box, Button, Text } from '@chakra-ui/react'
import DataRow from '../../../Common/DataRow'
import { SkullIcon } from '../../../../assets/Icons/SkullIcon'
import { useHistory } from 'react-router-dom'
import React from 'react'
import { SettingsIcon } from '@chakra-ui/icons'
import EditPlayerModal from '../../Modals/EditPlayerModal'

export default function PlayersListCard({
  firstname,
  lastname,
  phone,
  email,
  DOB,
  lastRoomPlayed,
  itsBlackList = false,
  playerId,
  refetchFunction
}) {
  const history = useHistory()
  function onClickHandler(playerId) {
    return () => history.push(`/player/${playerId}`)
  }

  const isTechnical = phone === '+12222222222'

  return (
    <Box p="16px 36px 16px 16px" mb="2px" bg="white" pos="relative">
      <EditPlayerModal
        playerId={playerId}
        refetchFunction={refetchFunction}
        btn={{
          pos: 'absolute',
          right: '16px',
          top: '16px',
          height: '16px'
        }}
      />
      <Box d="flex" mb="4px" alignItems="center">
        <Text color="custom.black.500" fontWeight="500" mr="8px">
          Full name:
        </Text>
        <Button
          variant="link"
          color="custom.blue.600"
          mb="2px"
          textDecoration="underline"
          onClick={onClickHandler(playerId)}
        >
          {itsBlackList && <SkullIcon height="20px" w="20px" mr="4px" />}
          {firstname}&nbsp;{lastname}
          {isTechnical && <SettingsIcon ml="4px" color="black" />}
        </Button>
      </Box>
      <DataRow
        title="Phone"
        value={phone ? phone : 'unset'}
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />
      <DataRow
        title="E-mail"
        value={email ? email : 'unset'}
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />
      <DataRow
        title="DOB"
        value={DOB ? DOB : 'unset'}
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />
      <DataRow
        title="Last room played"
        value={lastRoomPlayed ? lastRoomPlayed : 'unset'}
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />
    </Box>
  )
}
