import { Box, Button, Switch, Text, useToast } from '@chakra-ui/react'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { PERMISSION_LOCATIONS, SETTINGS_URL } from '../../../constants'
import { useHistory, useParams } from 'react-router-dom'
import WaiverBlock from '@/components/Settings/Tabs/Locations/Waiver/WaiverBlock'
import { useLocationById } from '../../../libs/hooks/useLocations'
import { useEffect, useMemo, useState } from 'react'
import {
  useCreateWaiver,
  usePatchWaiver,
  useWaiver
} from '../../../libs/hooks/useWaivers'
import { useQueryClient } from 'react-query'
import { PlusIcon } from '../../../assets/Icons/PlusIcon'
import NewWaiverBlock from '@/components/Settings/Tabs/Locations/Waiver/NewWaiverBlock'
import PermissionRequired from '@/components/Common/PermissionRequired'
import { generateWarningToast } from '../../../libs'
import Preloader from '@/components/Common/Preloader'
import WaiverInfo from '@/components/Settings/Tabs/Locations/Waiver/WaiverInfo'

export default function LocationWaiver() {
  const toast = useToast()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { id: locationId } = useParams()
  const { data: location = {} } = useLocationById(locationId)
  const waiverId = location?.waiver?.id
  const locationName = location?.name
  const { data: waiver, isLoading: isLoadingWaiver } = useWaiver(waiverId, {
    enabled: !!waiverId
  })
  const { mutate: patchWaiver, isLoading: isLoadingWaiverPatch } =
    usePatchWaiver()
  const waiverBlocks = waiver?.blocks || []
  const requireAuth = waiver?.require_authentication
  const unassigned = waiver?.unassigned
  const { mutate: createWaiver } = useCreateWaiver()
  const [newWaiverBlock, setNewWaiverBlock] = useState(false)
  const locationsPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const isLoading = isLoadingWaiverPatch || isLoadingWaiver
  function newWaiverBlockHandler() {
    setNewWaiverBlock(!newWaiverBlock)
  }

  const lastWaiverBlockPositionIndex = useMemo(() => {
    if (waiverBlocks.length !== 0) {
      let maxIndex = 1
      waiverBlocks.forEach((waiverBlock) => {
        if (waiverBlock.position > maxIndex) {
          maxIndex = waiverBlock.position
        }
      })
      return maxIndex
    } else {
      return 1
    }
  }, [waiverBlocks])

  useEffect(() => {
    if (location?.waiver === null) {
      const toSend = {
        blocks: [],
        locationId: +locationId
      }
      createWaiver(
        { obj: toSend },
        {
          onSuccess: () =>
            queryClient.invalidateQueries(['location', +locationId]),
          onError: (err) => {
            const msg = err?.response?.data?.msg
            const msgObjects = err?.response?.data
            generateWarningToast({
              id: 'create-waiver',
              toast,
              msgObjects,
              message: msg
            })
          }
        }
      )
    }
  }, [location])

  function goTo(path) {
    return () => history.push(path)
  }

  function closeNewWaiverBlock() {
    setNewWaiverBlock(false)
  }

  function onSwitchChange(prop) {
    return (e) => {
      const value = e.target.checked
      const toSend = {
        waiverId,
        [prop]: value
      }
      patchWaiver(
        {
          obj: toSend
        },
        {
          onSuccess: () => queryClient.invalidateQueries(['waiver', +waiverId]),
          onError: (err) => {
            const msg = err?.response?.data?.msg
            const msgObjects = err?.response?.data
            generateWarningToast({
              id: 'waiver-patch',
              toast,
              msgObjects,
              message: msg
            })
          }
        }
      )
    }
  }

  return (
    <Box
      p={{ base: '24px 16px', md: '48px 24px', '2xl': '48px' }}
      maxW={{ base: 'unset', md: '720px', '2xl': '1100px' }}
      m="0 auto"
    >
      <Box d="flex" flexDir={{ base: 'column', md: 'row' }}>
        <Button
          variant="ghost"
          fontFamily="inherit"
          p="0"
          w={{ md: '100%', '2xl': '50%' }}
          justifyContent="start"
          mb="16px"
          onClick={goTo(SETTINGS_URL)}
        >
          <Text
            as={'h1'}
            fontSize={{ base: '24px', '2xl': '32px' }}
            fontWeight={{ base: '600' }}
            overflow="hidden"
            textOverflow="ellipsis"
            color={locationName ? 'custom.black.500' : 'custom.gray.200'}
            lineHeight="164%"
          >
            <ArrowIcon
              w="auto"
              height="16px"
              mr="16px"
              mb="2px"
              color="custom.gray.200"
            />
            {locationName ? `Waiver ${locationName}` : "Location haven't name"}
          </Text>
        </Button>
        <Button
          variant="blue"
          fontSize={{ base: '12px', '2xl': '14px' }}
          ml={{ md: 'auto' }}
          mb={{ base: '16px', '2xl': 'unset' }}
          isDisabled={!locationsPermission}
          onClick={newWaiverBlockHandler}
        >
          <PlusIcon mr="8px" />
          New Waiver Block
        </Button>
      </Box>
      <Box d={{ base: 'none', '2xl': 'flex' }}>
        <Box d={{ base: 'none', '2xl': 'flex' }} alignItems="center" mb="24px">
          <Text fontSize="14px" color="custom.black.500" mr="8px">
            Require authentication
          </Text>
          <Switch
            isChecked={requireAuth}
            isDisabled={!locationsPermission || isLoading}
            onChange={onSwitchChange('requireAuthentication')}
          />
        </Box>
        <Box
          d={{ base: 'none', '2xl': 'flex' }}
          ml="16px"
          alignItems="center"
          mb="24px"
        >
          <Text fontSize="14px" color="custom.black.500" mr="8px">
            Unassigned
          </Text>
          <Switch
            isChecked={unassigned}
            isDisabled={!locationsPermission || isLoading}
            onChange={onSwitchChange('unassigned')}
          />
        </Box>
        {isLoading && (
          <Box ml="auto" w="400px">
            <Preloader m="0" h="20px" />
          </Box>
        )}
      </Box>
      <WaiverInfo waiver={waiver} />
      {newWaiverBlock && (
        <NewWaiverBlock
          lastWaiverBlockPositionIndex={lastWaiverBlockPositionIndex}
          waiverId={waiverId}
          closeNewWaiverBlock={closeNewWaiverBlock}
        />
      )}
      {waiverBlocks?.map((waiverBlock, idx) => {
        return (
          <WaiverBlock
            waiverId={waiverBlock?.waiver}
            key={'waiverBlock' + idx + waiverId + waiverBlock.id}
            waiverBlockId={waiverBlock.id}
            name={waiverBlock?.name}
            description={waiverBlock?.description}
            title={waiverBlock?.title}
            checkouts={waiverBlock?.checkouts}
          />
        )
      })}
    </Box>
  )
}
