import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { changeTime, friendlyTime } from '@/libs'

import WalkInBookingModal from '@/components/Booking/Modals/WalkInBookingModal/index'

import { BOOKING_URL, NEW_ORDER_URL, userInState } from '@/constants'

export default function StyledCalendarPopover({
  startTime = '',
  endTime = '',
  children = null,
  noShow = null,
  eventName,
  eventVariantId,
  locationId,
  intervalId,
  date,
  bookings = [],
  dateForCompare,
  isLocked,
  timezone
}) {
  const history = useHistory()
  const user = useSelector(userInState)
  const changedTimeByTimezone = changeTime(timezone)
  const nowTimeWithTz =
    changedTimeByTimezone.valueOf() -
    changedTimeByTimezone.getTimezoneOffset() * 60 * 1000
  const [isExpired, setExpired] = useState(dateForCompare < nowTimeWithTz)
  // const intervalRef = useRef(null);

  // useEffect(() => {
  //         if(intervalRef.current !== null && !isExpired) {
  //             clearInterval(intervalRef.current);
  //         }
  //
  //         if(intervalRef.current === null) {
  //             intervalRef.current = setInterval(() => {
  //                 console.log('intervalRef.current', intervalRef.current);
  //                 if(dateForCompare < Date.now() && !isExpired) {
  //                     setExpired(true);
  //                     clearInterval(intervalRef.current);
  //                     console.log('intervalRef.current clear', intervalRef.current);
  //                 }
  //             }, 1000 * 60);
  //
  //         }
  //
  //         return () => clearInterval(intervalRef.current);
  //
  // }, [isExpired]);
  function onOpenHandler() {
    if (dateForCompare < nowTimeWithTz && !isExpired) {
      setExpired(true)
    }
  }

  const havePermission = user.locations.includes(locationId)
  return (
    <Popover isLazy onOpen={onOpenHandler}>
      <PopoverTrigger>{children !== null && children}</PopoverTrigger>
      <Portal>
        <PopoverContent
          borderRadius="0"
          p="8px"
          minW="unset"
          width="270px"
          bg="custom.blue.200"
          _focus={false}
          border={`2px solid black`}
        >
          <PopoverArrow bg="custom.blue.600" />
          <PopoverBody>
            {bookings.length !== 0 ? (
              bookings.map((booking, idx) => {
                const totalLength = bookings.length
                let needMarginAndBorder = false
                if (idx !== totalLength - 1) {
                  needMarginAndBorder = true
                }
                const bookingId = booking.id
                const code = booking.code
                const status = booking.status
                const noShow = booking?.no_show
                return (
                  <Box
                    key={idx + locationId + eventVariantId}
                    mb={{ md: needMarginAndBorder ? '10px' : 0 }}
                    pb={needMarginAndBorder ? '4px' : '0'}
                    borderBottom={
                      needMarginAndBorder
                        ? '1px solid black'
                        : '1px solid transparent'
                    }
                  >
                    <Box d="grid" gridTemplateColumns="1fr 1fr" mb="8px">
                      {bookingId && (
                        <>
                          <Text color="custom.gray.300">Booking #:</Text>
                          <Link href={`${BOOKING_URL}/${bookingId}`} isExternal>
                            <Text color="custom.blue.600" textDecor="underline">
                              {code}
                            </Text>
                          </Link>
                        </>
                      )}
                    </Box>
                    <Box d="grid" gridTemplateColumns="1fr 1fr" mb="8px">
                      <Text color="custom.gray.300">Status:</Text>
                      <Text color="custom.blue.600" textTransform="capitalize">
                        {status}
                      </Text>
                    </Box>
                    {noShow !== null && (
                      <Box d="grid" gridTemplateColumns="1fr 1fr" mb="8px">
                        <Text color="custom.gray.300">No Show:</Text>
                        <Text
                          color="custom.blue.600"
                          textTransform="capitalize"
                        >
                          {noShow ? 'Yes' : 'No'}
                        </Text>
                      </Box>
                    )}
                    <Box d="grid" gridTemplateColumns="1fr 1fr">
                      <Text color="custom.gray.300">Time:</Text>
                      <Box d="flex">
                        <Text color="custom.gray.900">
                          {friendlyTime(startTime, 'h:mm A')}
                        </Text>
                        &nbsp;-&nbsp;
                        <Text color="custom.gray.900">
                          {friendlyTime(endTime, 'h:mm A')}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )
              })
            ) : (
              <>
                <Box d="grid" gridTemplateColumns="1fr 1fr" mb="8px">
                  <Text color="custom.gray.300">Status:</Text>
                  {isExpired && !isLocked && (
                    <Text color="custom.blue.600">Expired</Text>
                  )}
                  {!isExpired && isLocked && (
                    <Text color="custom.blue.600">Locked</Text>
                  )}
                  {!isExpired && !isLocked && (
                    <>
                      {havePermission ? (
                        <Link
                          isExternal
                          color="custom.blue.600"
                          textDecor="underline"
                          _focus={false}
                          href={
                            NEW_ORDER_URL +
                            `?location=${locationId}&eventvariant=${eventVariantId}&interval=${intervalId}&date=${date}`
                          }
                        >
                          Free
                          <ExternalLinkIcon ml="4px" mb="2px" />
                        </Link>
                      ) : (
                        <Text color="custom.blue.600">
                          You don't have access
                        </Text>
                      )}
                    </>
                  )}
                </Box>
                {noShow !== null && (
                  <Box d="grid" gridTemplateColumns="1fr 1fr" mb="8px">
                    <Text color="custom.gray.300">No Show:</Text>
                    <Text color="custom.blue.600" textTransform="capitalize">
                      {noShow ? 'Yes' : 'No'}
                    </Text>
                  </Box>
                )}
                <Box d="grid" gridTemplateColumns="1fr 1fr">
                  <Text color="custom.gray.300">Time:</Text>
                  <Box d="flex">
                    <Text color="custom.gray.900">
                      {friendlyTime(startTime, 'h:mm A')}
                    </Text>
                    &nbsp;-&nbsp;
                    <Text color="custom.gray.900">
                      {friendlyTime(endTime, 'h:mm A')}
                    </Text>
                  </Box>
                </Box>
                <Box mt="12px">
                  {!isExpired && !isLocked && havePermission && (
                    <WalkInBookingModal
                      eventName={eventName}
                      experienceId={eventVariantId}
                      intervalId={intervalId}
                      date={date}
                      startTime={startTime}
                      endTime={endTime}
                      title={'Create Walk-in booking'}
                    />
                  )}
                </Box>
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
