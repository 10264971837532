import { useQuery } from 'react-query'

import { getExperience, getExperienceById } from '../../api/gameStatusRequest'

export function useExperiences(queryOption = {}) {
  return useQuery(['experience'], () => getExperience(), {
    ...queryOption
  })
}

export function useExperience(eventvariantId, queryOption = {}) {
  return useQuery(
    ['experience', +eventvariantId],
    () => getExperienceById(eventvariantId),
    {
      ...queryOption
    }
  )
}
