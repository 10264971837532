import {
  Box,
  Button,
  IconButton,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { PlusIcon } from '../../../../assets/Icons/PlusIcon'
import { convertAmPmToSeconds, convertWeekdayInIndex } from '../../../../libs'
import { useNewLimitation } from '../../../../libs/hooks/useLimitations'
import { editLimitationModal } from '../../../../configs/validation/editLimitationModal'
import { PERMISSION_LIMITATIONS } from '../../../../constants'
import ButtonsToggle from '../../../Common/ButtonsToggle'
import FormikScheduleInput from '../../../Common/FormikComponents/FormikScheduleInput'
import PermissionRequired from '../../../Common/PermissionRequired'

export default function AddLimitationModal({ location, day, locationId }) {
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const limitationsPermissions = PermissionRequired(
    PERMISSION_LIMITATIONS,
    true
  )
  const correctedDay = day?.slice(0, 3)
  const { mutate: newLimitation } = useNewLimitation()
  const formik = useFormik({
    initialValues: {
      startTimeAmPm: 'AM',
      endTimeAmPm: 'PM',
      start: '',
      end: '',
      diff: ''
    },
    validationSchema: editLimitationModal,
    onSubmit: (values) => onSubmitHandler(values),
    enableReinitialize: true
  })

  function onSubmitHandler(values) {
    let endTimeInSecond = convertAmPmToSeconds(
      `${values.end} ${values.endTimeAmPm}`
    )
    let startTimeInSecond = convertAmPmToSeconds(
      `${values.start} ${values.startTimeAmPm}`
    )
    const [endHours] = values.end.split(':') || [null]
    const [startHours] = values.start.split(':') || [null]
    if (endHours < 8 && values.endTimeAmPm === 'AM')
      endTimeInSecond = 86400 + endTimeInSecond
    if (startHours < 2 && values.startTimeAmPm === 'AM')
      startTimeInSecond = 86400 + startTimeInSecond

    const toSend = {
      startTime: startTimeInSecond,
      endTime: endTimeInSecond,
      weekday: convertWeekdayInIndex(day),
      locationId
    }
    newLimitation(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            'limitations',
            +convertWeekdayInIndex(day)
          ])
          onClose()
        }
      }
    )
  }

  const formikValues = formik.values
  const setFieldValue = formik.setFieldValue
  const formikHandleSubmit = formik.handleSubmit
  function handleChange(prop) {
    return (val) => setFieldValue(prop, val)
  }

  useEffect(() => {
    const timeStringStart = formikValues.start.replaceAll('_', '')
    const timeStringEnd = formikValues.end.replaceAll('_', '')
    if (timeStringStart.length === 5 && timeStringEnd.length === 5) {
      const resStart = convertAmPmToSeconds(
        `${formikValues.start} ${formikValues.startTimeAmPm}`
      )
      let resEnd = convertAmPmToSeconds(
        `${formikValues.end} ${formikValues.endTimeAmPm}`
      )
      const [hours] = formikValues.end.split(':') || [null]
      if (hours < 8 && formikValues.endTimeAmPm === 'AM')
        resEnd = 86400 + resEnd
      setFieldValue('diff', resEnd - resStart)
    }
  }, [
    formikValues.startTimeAmPm,
    formikValues.start,
    formikValues.endTimeAmPm,
    formikValues.end
  ])

  return (
    <>
      <IconButton
        aria-label="rebook-name"
        variant="funcColumnIconButton"
        minW="unset"
        w="14px"
        h="14px"
        color="custom.blue.600"
        ml="auto"
        onClick={onOpen}
        _focus={false}
        isDisabled={!limitationsPermissions}
        icon={<PlusIcon w="inherit" h="inherit" />}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
          >
            <ModalHeader mb="16px">Add limitations</ModalHeader>
            <ModalCloseButton />
            <ModalBody mb="16px">
              <Text
                color="custom.blue.600"
                fontSize={{ base: '12px', '2xl': '14px' }}
                mb="16px"
                textTransform="capitalize"
                fontWeight="500"
              >
                {location}&nbsp;-&nbsp;{correctedDay}
              </Text>
              <Box
                d="grid"
                gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
                gridGap="16px"
              >
                <InputGroup mb="8px">
                  <FormikScheduleInput
                    name="start"
                    id="start"
                    placeholder="HH:MM"
                    color="black"
                    bg="custom.lightGray"
                  />
                  <InputRightElement width="80px">
                    <ButtonsToggle
                      data={['AM', 'PM']}
                      unCheckedColor="custom.lightGray"
                      cb={handleChange('startTimeAmPm')}
                      w="100%"
                      initialState={formikValues.startTimeAmPm}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup mb="8px">
                  <FormikScheduleInput
                    name="end"
                    id="end"
                    placeholder="HH:MM"
                    color="black"
                    bg="custom.lightGray"
                  />
                  <InputRightElement width="80px">
                    <ButtonsToggle
                      data={['AM', 'PM']}
                      unCheckedColor="custom.lightGray"
                      cb={handleChange('endTimeAmPm')}
                      w="100%"
                      initialState={formikValues.endTimeAmPm}
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>
            </ModalBody>
            <ModalFooter
              d="grid"
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="16px"
            >
              <Button w="100%" variant="lightBlue" onClick={onClose}>
                Back
              </Button>
              <Button
                w="100%"
                variant="blue"
                isDisabled={formikValues.diff < 30 * 60}
                onClick={formikHandleSubmit}
              >
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
