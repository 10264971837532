import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1440px',
  '4xl': '1920px'
})

export const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  },
  breakpoints,
  fonts: {
    heading: 'Poppins',
    body: 'Poppins'
  },
  sizes: {
    '100vh': '100vh'
  },
  shadows: {
    purple: '0 0 10px 0 rgba(51, 51, 51, 0.1)'
  },
  colors: {
    custom: {
      lightGray: 'rgba(248, 248, 248, 1)',
      blue: {
        20: 'rgba(4, 113, 241, 0.2)',
        200: 'rgba(235, 241, 247, 1)',
        300: 'rgba(234, 243, 255, 1)',
        default: 'rgba(4, 113, 241, 1)',
        600: 'rgba(4, 113, 241, 1)',
        650: 'rgba(4, 113, 241, 0.5)'
      },
      borderBlue: 'rgba(214, 229, 242, 1)',
      gray: {
        50: 'rgba(199, 207, 214, 0.3)',
        200: 'rgba(199, 207, 214, 1)',
        300: 'rgba(153, 153, 153, 1)',
        400: 'rgba(87, 87, 87, 1)',
        500: 'rgba(229,229,229, 1)',
        900: 'rgba(102, 102, 102, 1)'
      },
      black: {
        500: 'rgba(51, 51, 51, 1)',
        600: 'rgba(17, 17, 17, 1)'
      },
      red: {
        20: 'rgba(255,0,0,0.05)',
        100: 'rgba(255, 226, 217, 1)',
        600: 'rgba(251, 38, 38, 1)',
        650: 'rgba(225, 43, 43, 1)'
      }
    }
  },
  components: {
    Input: {
      variants: {
        gbase: {
          field: {
            border: '1px solid',
            borderRadius: 'none',
            borderColor: 'custom.borderBlue',
            bg: 'custom.lightGray',
            fontSize: 'inherit',
            _hover: {
              borderColor: 'custom.gray.300'
            }
          }
        },
        gbase2: {
          field: {
            borderRadius: 'none',
            bg: 'custom.lightGray',
            color: 'custom.gray.900',
            fontSize: 'inherit'
          }
        }
      }
    },
    Accordion: {
      variants: {
        gbase: {
          container: {
            borderStyle: 'hidden',
            fontFamily: 'inherit',
            mb: { base: '16px', '2xl': '32px' }
          },
          item: {
            borderStyle: 'hidden'
          },
          button: {
            fontWeight: '500',
            fontSize: { base: '18px', '2xl': '24px' },
            bg: 'white',
            padding: { base: '16px', '2xl': '24px' },
            _hover: { bg: 'white' },
            _focus: { boxShadow: 'none' },
            _active: { bg: 'white' }
          }
        },
        basePricing: {
          container: {
            borderStyle: 'hidden',
            fontFamily: 'inherit',
            mb: { base: '16px', '2xl': '32px' }
          },
          item: {
            borderStyle: 'hidden'
          },
          button: {
            fontWeight: '500',
            fontSize: { base: '12px', '2xl': '14px' },
            bg: 'custom.lightGray',
            padding: '8px 16px',
            minHeight: '40px',
            _hover: false,
            _focus: { boxShadow: 'none' },
            _active: false
          }
        }
      }
    },
    Checkbox: {
      variants: {
        gbase: {
          control: {
            borderWidth: '1px',
            borderColor: 'custom.borderBlue',
            w: '24px',
            h: '24px',
            background: 'custom.lightGray',
            borderRadius: 'none',
            _active: {
              background: 'white'
            },
            _focus: {
              background: 'white'
            },
            _hover: {
              background: 'custom.lightGray',
              boxShadow: 'none'
            },
            _checked: {
              borderWidth: '1px',
              borderColor: 'custom.blue.600',
              background: 'white',
              _hover: {
                borderWidth: '1px',
                borderColor: 'custom.blue.600',
                background: 'white'
              }
            }
          }
        }
      }
    },
    Button: {
      variants: {
        unstyled: {
          _focus: { boxShadow: 'none' }
        },
        gbase: {
          bg: 'custom.blue.600',
          border: 'none',
          borderRadius: 'none',
          color: 'white',
          cursor: 'pointer',
          height: '40px'
        },
        gbaseOutline: {
          bg: 'white',
          border: '1px solid',
          borderColor: 'custom.borderBlue',
          color: 'black',
          borderRadius: 'none',
          fontWeight: 200,
          cursor: 'pointer'
        },
        gbaseMenuButton: {
          bg: 'none',
          fontsize: '14px',
          fontWeight: 500,
          borderRadius: 'none',
          padding: '16px 0',
          height: 'auto'
        },
        red: {
          padding: '8px 18.5px',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          borderRadius: 'none',
          bg: 'custom.red.100',
          color: 'custom.red.600',
          _hover: {
            _disabled: {
              background: 'custom.red.100'
            }
          }
        },
        lightBlue: {
          padding: '8px 18.5px',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          borderRadius: 'none',
          bg: 'custom.blue.200',
          color: 'custom.blue.600',
          _hover: {
            _disabled: {
              background: 'custom.blue.200'
            }
          }
        },
        blue: {
          padding: '8px 18.5px',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          borderRadius: 'none',
          bg: 'custom.blue.600',
          color: 'white',
          _hover: {
            _disabled: {
              background: 'custom.blue.600'
            }
          }
        },
        likeInput: {
          padding: '8px 18.5px',
          fontWeight: '500',
          border: '1px solid',
          borderRadius: 'none',
          borderColor: 'custom.borderBlue',
          bg: 'custom.lightGray',
          fontSize: 'inherit',
          color: 'custom.blue.600'
        },
        black: {
          padding: '8px 18.5px',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          borderRadius: 'none',
          bg: 'black',
          color: 'white'
        },
        blackChecked: {
          padding: '8px 18.5px',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          borderRadius: 'none',
          bg: 'custom.lightGray',
          color: 'custom.black.500'
        },
        link: {
          fontWeight: 'inherit',
          fontSize: 'inherit',
          padding: '0',
          minHeight: 'unset',
          height: 'auto',
          textDecoration: 'underline'
        },
        white: {
          bg: 'white',
          fontSize: 'inherit',
          borderRadius: 'none',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'custom.borderBlue',
          color: 'custom.blue.600',
          fontWeight: '500',
          _disabled: {
            borderColor: 'white',
            color: 'custom.gray.300',
            opacity: '1'
          },
          _hover: {
            _disabled: {
              bg: ''
            }
          }
        },
        funcColumnIconButtons: {
          bg: 'none',
          minHeight: 'unset',
          minWidth: 'unset',
          height: '14px',
          width: '14px',
          fontSize: 'inherit',
          d: 'inline-flex',
          alignItems: 'center',
          borderRadius: 'none',
          _focus: { boxShadow: 'none' }
        }
      }
    },
    Text: {
      variants: {
        boldTitle: {
          as: 'h1',
          fontSize: { base: '24px', '2xl': '32px' },
          fontWeight: '600',
          mb: { base: '16px', md: '24px', '2xl': '32px' }
        }
      }
    },
    Tabs: {
      variants: {
        gbase: {
          tab: {
            bg: 'custom.lightGray',
            border: '1px solid',
            borderColor: 'custom.borderBlue',
            width: '100%',
            fontWeight: '400',
            color: 'custom.gray.300',
            height: '40px',
            padding: 0,
            _hover: { border: '' },
            _selected: {
              bg: 'custom.blue.600',
              color: 'white',
              border: '1px solid',
              borderColor: 'custom.blue.600',
              _hover: { border: '' }
            }
          },
          tabpanel: {
            padding: 0
          }
        }
      }
    },
    FormLabel: {
      variants: {
        gbase: {
          fontSize: '12px',
          fontWeight: '400',
          color: 'custom.gray.300',
          mb: '8px'
        },
        lightBlue: {
          padding: '8px 18.5px',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          borderRadius: 'none',
          bg: 'custom.blue.200',
          color: 'custom.blue.600'
        }
      }
    },
    Badge: {
      variants: {
        completed: {
          display: 'inline-flex',
          alignItems: 'center',
          bg: 'rgba(190, 233, 199, 1)',
          color: '#0DB00A',
          padding: '0 4px',
          borderRadius: '4px',
          fontWeight: 400,
          height: '24px'
        },
        green: {
          display: 'inline-flex',
          alignItems: 'center',
          bg: 'rgba(190, 233, 199, 1)',
          color: '#0DB00A',
          padding: '0 4px',
          borderRadius: '4px',
          fontWeight: 400,
          height: '24px'
        },
        canceled: {
          display: 'inline-flex',
          alignItems: 'center',
          background: 'rgba(232, 232, 232, 1)',
          color: 'black',
          padding: '0 4px',
          borderRadius: '4px',
          fontWeight: 400,
          height: '24px'
        },
        upcoming: {
          display: 'inline-flex',
          alignItems: 'center',
          bg: 'rgba(255, 227, 184, 1)',
          color: '#FF8A00',
          padding: '0 4px',
          borderRadius: '4px',
          fontWeight: 400,
          height: '24px'
        },
        running: {
          display: 'inline-flex',
          alignItems: 'center',
          bg: 'rgba(255, 226, 217, 1)',
          color: '#FB2626',
          padding: '0 4px',
          borderRadius: '4px',
          fontWeight: 400,
          height: '24px'
        },
        red: {
          display: 'inline-flex',
          alignItems: 'center',
          bg: 'rgba(255, 226, 217, 1)',
          color: '#FB2626',
          padding: '0 4px',
          borderRadius: '4px',
          fontWeight: 400,
          height: '24px'
        }
      }
    },
    Link: {
      variants: {
        gbase: {
          color: 'custom.blue.600',
          textDecoration: 'underline'
        }
      }
    },
    Modal: {
      variants: {
        gbase: {
          header: {
            WebkitTapHighlightColor: 'rgba(0,0,0,0) !important',
            fontSize: { base: '18px', '2xl': '24px' },
            fontWeight: '500',
            color: 'custom.black.500',
            padding: '0'
          },
          closeButton: {
            width: '14px',
            height: '14px',
            color: 'custom.gray.200',
            top: '22px',
            cursor: 'pointer',
            right: '16px',
            _focus: {
              border: ''
            }
          },
          dialog: {
            borderRadius: 'none',
            padding: { base: '16px', '2xl': '24px' }
          },
          footer: {
            width: '100%',
            padding: '0'
          },
          overlay: {
            bg: 'custom.blue.20',
            backdropFilter: 'blur(5px)'
          },
          body: {
            WebkitTapHighlightColor: 'rgba(0,0,0,0) !important',
            padding: '0',
            mt: { base: '8px' }
          }
        }
      }
    }
  },
  styles: {
    global: {
      body: {
        fontSize: '12px',
        height: '100vh',
        background: 'custom.lightGray'
      },
      html: {
        boxSizing: 'border-box',
        overflowY: 'auto',
        scrollbarGutter: 'stable'
      },
      '#root': {
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        height: '100%',
        pos: 'relative'
      }
    }
  }
})
