import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'

export default function FuturePlayersCounter() {
  console.log('futurePlayers')
  const formik = useFormikContext()
  const setFieldValue = formik?.setFieldValue
  const selectedProfile = formik?.values?.selectedProfile
  const loadedBooking = formik?.values?.loadedBooking
  const futurePlayers = formik.values.futurePlayers
  const signedForFromWaiver = selectedProfile?.waiver?.signed_for
  const originalProfile = formik.values?.originalProfile
  const signedForFromOriginalProfile = originalProfile?.waiver?.signed_for
  const signedFor = formik.values.signedFor
  const singedForHandler = signedForFromWaiver || signedFor
  const exists = formik.values.exists
  const itsNewProfile = formik.values.itsNewProfile
  const players = formik.values.loadedBooking?.players
  const isReplaceProfileMode = formik.values.isReplaceProfileMode
  const minors = itsNewProfile
    ? selectedProfile?.minors
    : selectedProfile?.waiver?.minors
    ? selectedProfile?.waiver?.minors
    : []

  function waiversDifferent(fromProfile = [], fromSelectedProfile = []) {
    return fromSelectedProfile?.length - fromProfile?.length
  }

  const minorsCorrectLength = useMemo(() => {
    return waiversDifferent(
      formik.values?.originalProfile?.waiver?.minors,
      formik.values?.selectedProfile?.waiver?.minors
    )
  }, [
    formik.values?.selectedProfile?.waiver?.minors,
    formik.values?.originalProfile?.waiver?.minors
  ])

  const valuesMap = {
    M: {
      M: +players,
      C: +players + minors?.length - 1,
      'M&C': +players + minorsCorrectLength
    },
    'M&C': {
      M: +players - (minors?.length - minorsCorrectLength),
      C: +players - 1 + minorsCorrectLength,
      'M&C': +players + minorsCorrectLength
    },
    C: {
      M: +players + 1 + minorsCorrectLength,
      C: +players + minorsCorrectLength,
      'M&C': +players + 1 + minorsCorrectLength
    }
  }

  function setFuturePlayers(signedForFromOriginalProfile, singedForHandler) {
    const value = valuesMap[signedForFromOriginalProfile][singedForHandler]
    console.log('minorsCorrectLength', minorsCorrectLength)
    console.log('minors?.length,', minors?.length)
    setFieldValue('futurePlayers', value)
  }

  function initialValuesMap(singedForHandler, exists = false) {
    const obj = {
      M: exists ? +players : +players + 1,
      'M&C': exists ? +players : +players + minors?.length + 1,
      C: exists ? +players : +players + minors?.length
    }
    const value = obj[singedForHandler]
    setFieldValue('futurePlayers', value)
  }

  useEffect(() => {
    console.log('here')
    if (
      futurePlayers === null &&
      JSON.stringify(loadedBooking) !== '{}' &&
      exists !== null &&
      (selectedProfile !== null || itsNewProfile) &&
      singedForHandler !== null &&
      !isReplaceProfileMode
    ) {
      initialValuesMap(singedForHandler, exists)
      console.log('here 4')
    }

    if (
      futurePlayers === null &&
      JSON.stringify(loadedBooking) !== '{}' &&
      exists !== null &&
      (selectedProfile !== null || itsNewProfile) &&
      singedForHandler !== null &&
      isReplaceProfileMode
    ) {
      console.log('here 3')
      if (originalProfile?.waiver?.signed_for === 'M') {
        setFieldValue('futurePlayers', +players)
      }
      if (originalProfile?.waiver?.signed_for === 'M&C') {
        setFieldValue('futurePlayers', +players + minorsCorrectLength)
      }
      if (originalProfile?.waiver?.signed_for === 'C') {
        setFieldValue('futurePlayers', +players + 1 + minorsCorrectLength)
      }
    }
  }, [
    futurePlayers,
    loadedBooking,
    exists,
    selectedProfile,
    singedForHandler,
    isReplaceProfileMode
  ])

  useEffect(() => {
    if (
      singedForHandler !== null &&
      typeof futurePlayers === 'number' &&
      exists !== null
    ) {
      if (exists && signedForFromOriginalProfile) {
        console.log(
          'signedForFromOriginalProfile',
          signedForFromOriginalProfile
        )
        console.log('singedForHandler', singedForHandler)
        console.log('here 1')
        setFuturePlayers(signedForFromOriginalProfile, singedForHandler)
      } else {
        console.log('here 2')
        initialValuesMap(singedForHandler)
      }
    }
  }, [
    singedForHandler,
    signedForFromOriginalProfile,
    exists,
    players,
    isReplaceProfileMode
  ])

  return null
}
