import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PromocodesList from './PromocodesList'
import PromocodesTable from './PromocodesTable'
import { PlusIcon } from '../../../../assets/Icons/PlusIcon'
import { useExperiences } from '../../../../libs/hooks/useExperiences'
import { usePromocodes } from '../../../../libs/hooks/usePromo'
import { debounceSearchTime } from '../../../../configs'
import {
  CREATE_PROMOCODE_URL,
  matchMedia768InState
} from '../../../../constants'
import WithPagination from '../../../Common/WithPagination'

export default function PromocodeTab() {
  const history = useHistory()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { data: { results: experience = [] } = {} } = useExperiences()
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState({
    page: 1,
    pageSize: 15,
    search: ''
  })
  const { data: { results: promocodesData = [], count: total = '' } = {} } =
    usePromocodes(filter)

  const debouncedSearch = useCallback(
    debounce((string) => {
      setFilter({
        ...filter,
        search: string
      })
    }, debounceSearchTime),
    []
  )

  function onChangeHandler() {
    return (e) => setSearch(e.target.value)
  }

  function paginationStateHandler(value) {
    setFilter({
      ...filter,
      ...value
    })
  }
  useEffect(() => {
    if (search.length === 0) {
      debouncedSearch('')
    }
    if (search.length > 2) {
      debouncedSearch(search)
    }
  }, [search])

  const experiencesObject = useMemo(() => {
    const obj = {}
    experience?.forEach((exp) => {
      obj[exp.id] = exp.name.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
      const matched = obj[exp.id].match(/(^\w|\s\w)/g)
      let final = ''
      matched?.forEach((letter) => {
        final = final.trim() + letter.trim()
      })
      obj[exp.id] = final
    })
    return obj
  }, [experience])

  const preparedData = useMemo(() => {
    return promocodesData?.map((promo) => {
      const list = []
      promo?.eventvariants?.map((eventvariantID) => {
        if (experiencesObject[eventvariantID]) {
          list.push(experiencesObject[eventvariantID])
        }
      })
      return {
        ...promo,
        experienceToShow: list
      }
    })
  }, [experiencesObject, promocodesData])

  function goTo(path) {
    return () => history.push(path)
  }

  return (
    <>
      <Box
        d={{ md: 'flex' }}
        bg={{ base: 'transparent', md: 'white' }}
        p={{ base: '0', md: '16px' }}
        mb={{ md: '2px' }}
      >
        <InputGroup mb={{ base: '8px', md: '0' }}>
          <Input
            variant="gbase"
            value={search}
            onChange={onChangeHandler()}
            bg={{ base: 'white', md: 'custom.lightGray' }}
            w="100%"
            placeholder="Search"
          />
          <InputRightElement>
            <SearchIcon color="custom.gray.200" />
          </InputRightElement>
        </InputGroup>
        <Button
          variant="blue"
          w={{ base: '100%', md: '160px' }}
          m={{ base: '0 0 16px', md: '0 0 0 16px' }}
          onClick={goTo(CREATE_PROMOCODE_URL)}
        >
          <PlusIcon mr="8px" />
          New promocode
        </Button>
      </Box>
      {isLargerThan768 ? (
        <WithPagination totalCountItems={total} cb={paginationStateHandler}>
          <PromocodesTable tableData={preparedData} />
        </WithPagination>
      ) : (
        <WithPagination totalCountItems={total} cb={paginationStateHandler}>
          <PromocodesList data={preparedData} />
        </WithPagination>
      )}
    </>
  )
}
