import * as yup from 'yup'

export const deleteLocationModalValidation = yup.object().shape({
  experience: yup
    .object()
    .test('Empty Obj', 'Must select experience', (object) => {
      if (!object) return false
      return !!object.value
    })
})
