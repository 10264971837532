import { Box, Text } from '@chakra-ui/react'
import {
  limitationWeekMenu,
  TABLE_COLUMN_VALUES_LIMITATION_GRAPH
} from '../../../configs'
import MenuSwitcher from '../../Common/MenuSwitcher'
import LimitationsTable from './LimitationsTable'

export default function LimitationsDesktop({ data = [], setDay = null, day }) {
  const options = limitationWeekMenu
  const columns = TABLE_COLUMN_VALUES_LIMITATION_GRAPH
  return (
    <Box>
      <Text
        color="custom.black.500"
        fontWeight="600"
        fontSize={{ base: '24px' }}
        mb="24px"
      >
        Limitations
      </Text>
      <MenuSwitcher options={options} cb={setDay} />
      <LimitationsTable tableData={data} day={day} columns={columns} />
    </Box>
  )
}
