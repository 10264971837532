import {
  Box,
  Img,
  InputGroup,
  InputRightElement,
  Switch,
  Text
} from '@chakra-ui/react'
import EditAttractionExperienceModal from '../Modals/EditAttractionExperienceModal'
import { useAttractionsExperience } from '../../../../../../libs/hooks/useAttractionsExperience'
import PseudoInput from '../../../../../Common/PseudoInput'
import { useMemo } from 'react'
import { useExperiences } from '../../../../../../libs/hooks/useExperiences'
import { useHistory, useParams } from 'react-router-dom'
import DeleteExperienceModal from '../Modals/DeleteExperienceModal'
import { BASE_URL } from '../../../../../../constants'
import { BASE_URL_WO_SLASH } from '../../../../../../constants/index'

export default function ExperienceName({ eventId, selectedExperience = 0 }) {
  const { data = [{}] } = useAttractionsExperience(eventId)
  const { id } = useParams()
  const { data: { results: experience = [] } = {} } = useExperiences()
  const history = useHistory()
  const state = data[selectedExperience]
  const experienceId = state?.id
  const name = state?.name
  const ticketMinPlayers = state?.ticket_min_players
  const ticketEnabled = state?.tickets_enabled
  const playersStep = state?.players_step
  const areas = state?.areas
  const minPlayers = state?.min_players
  const maxPlayers = state?.max_players
  const flowsMinPlayers = state?.flows_min_players
  const flowsMaxPlayers = state?.flows_max_players
  const photoFrame = state?.photo_frame
  const disableStatistic = state?.disable_statistic
  const changePriceOnRemovePlayers = state?.change_price_on_remove_players
  const combinedInitialState = useMemo(() => {
    const ids = state?.combined_statistic_with
    const find = experience?.filter((exp) => {
      const id = exp.id
      return ids?.includes(id)
    })
    const names = find?.map((el) => el.name)
    return names.join(', ')
  }, [experience, state])
  const image = state?.image ?  state?.image : ''
  const lockWith = useMemo(() => {
    let nameExperience = 'Unset'
    if (experience?.length !== 0) {
      experience?.forEach((exp) => {
        if (state?.lock_with && state?.lock_with[0] === exp.id) {
          nameExperience = exp.name
        }
      })
    }
    return nameExperience
  }, [experience, state])
  const termsAndConditions = state?.terms_and_conditions
  const humanUsage = state?.human_usage

  return (
    <>
      <Box
        bg="white"
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
        padding={{ base: '16px', md: '20px 16px', '2xl': '24px' }}
        mb="2px"
      >
        <Text
          fontSize={{ base: '18px', '2xl': '24px' }}
          mb="8px"
          fontWeight="500"
        >
          {name}
        </Text>
        <Box
          d="grid"
          gridTemplateColumns="1fr 1fr"
          gridColumnGap={{ base: '8px', md: '20px' }}
          mb="2px"
          ml={{ base: 'unset', md: 'auto' }}
        >
          <EditAttractionExperienceModal
            selectedExperience={selectedExperience}
          />
          <DeleteExperienceModal
            eventId={id}
            name={name}
            eventvariantId={experienceId}
          />
        </Box>
      </Box>
      <Box
        bg="white"
        padding="16px"
        mb={{ base: '2px', md: '24px', '2xl': '32px' }}
      >
        <Box
          d="grid"
          gridGap={{ base: '8px', '2xl': '16px' }}
          gridTemplateColumns={{ base: '1fr 1fr', md: 'repeat(4, 1fr)' }}
          mb={{ base: '8px', '2xl': '16px' }}
        >
          <PseudoInput
            value={experienceId}
            placeholder="ID"
            gridColumn={{ base: '1/3', md: 'unset' }}
          />
          <PseudoInput
            value={name}
            placeholder="Name"
            gridColumn={{ base: '1/3', md: 'unset' }}
          />
          <PseudoInput value={humanUsage} placeholder="Admin usage" />
          <PseudoInput value={areas} placeholder="areas" />
          <PseudoInput value={minPlayers} placeholder="Min players" />
          <PseudoInput value={maxPlayers} placeholder="Max players" />
          <PseudoInput placeholder="Min flow" value={flowsMinPlayers} />
          <PseudoInput placeholder="Max flow" value={flowsMaxPlayers} />
          <PseudoInput placeholder="Players step" value={playersStep} />
          <PseudoInput placeholder="Lock with" value={lockWith} />
          <InputGroup gridColumn={{ base: '1/3', md: 'unset' }}>
            <PseudoInput
              pos="relative"
              w="100%"
              placeholder="Tickets"
              paddingRight="54px"
            />
            <InputRightElement right="12px">
              <Switch
                size="md"
                d="flex"
                alignItems="center"
                isReadOnly
                isChecked={ticketEnabled}
              />
            </InputRightElement>
          </InputGroup>
          <PseudoInput
            gridColumn={{ base: '1/3', md: 'unset' }}
            placeholder="Tickets min players"
            value={ticketMinPlayers}
          />
          <InputGroup gridColumn={{ base: '1/3', md: 'unset' }}>
            <PseudoInput
              pos="relative"
              w="100%"
              placeholder="Exclude Statistics"
              paddingRight="54px"
            />
            <InputRightElement right="12px">
              <Switch
                size="md"
                d="flex"
                alignItems="center"
                isReadOnly
                isChecked={disableStatistic}
              />
            </InputRightElement>
          </InputGroup>
          <InputGroup gridColumn={{ base: '1/3', md: 'unset' }}>
            <PseudoInput
              pos="relative"
              w="100%"
              placeholder="Change price after remove players"
              paddingRight="54px"
              lineHeight="14px"
              p={{
                base: '8px 18.5px 8px 18.5px',
                md: '4px 18.5px 4px 4px',
                '2xl': '8px 18.5px 8px 8px'
              }}
              fontSize={{ base: '10px', '2xl': '12px' }}
            />
            <InputRightElement right="12px">
              <Switch
                size="md"
                d="flex"
                alignItems="center"
                isReadOnly
                isChecked={changePriceOnRemovePlayers}
              />
            </InputRightElement>
          </InputGroup>
        </Box>
        <PseudoInput
          height="auto"
          mb={{ base: '16px', '2xl': '24px' }}
          placeholder="Combine statistics with"
          value={combinedInitialState}
        />
        <Text
          fontSize={{ base: '14px', '2xl': '18px' }}
          fontWeight="500"
          mb="8px"
        >
          Terms & conditions:
        </Text>
        <Box bg="custom.lightGray" p="8px 16px" mb="16px">
          <Text
            as="span"
            color="custom.gray.900"
            fontSize={{ base: '12px', '2xl': '14px' }}
          >
            {termsAndConditions ? (
              termsAndConditions
            ) : (
              <Text color="custom.gray.300">
                Terms & conditions are not provided.
              </Text>
            )}
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={{ base: '14px', '2xl': '18px' }}
            fontWeight="500"
            mb={{ base: '8px', '2xl': '16px' }}
          >
            Frame:
          </Text>
          {photoFrame ? (
            <Img src={photoFrame} maxW="200px" m="0 auto" />
          ) : (
            <Text
              color="custom.gray.200"
              textAlign="center"
              fontSize={{ base: '14px', '2xl': '16px' }}
            >
              No frames.
            </Text>
          )}
        </Box>
        <Box>
          <Text
            fontSize={{ base: '14px', '2xl': '18px' }}
            fontWeight="500"
            mb={{ base: '8px', '2xl': '16px' }}
          >
            Image:
          </Text>
          {image ? (
            <Img src={image} maxW="200px" m="0 auto" />
          ) : (
            <Text
              color="custom.gray.200"
              textAlign="center"
              fontSize={{ base: '14px', '2xl': '16px' }}
            >
              No images.
            </Text>
          )}
        </Box>
      </Box>
    </>
  )
}
