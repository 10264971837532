import * as yup from 'yup'

export const editPromoValidation = yup.object().shape({
  playersMin: yup
    .number()
    .positive()
    .max(yup.ref('playersMax'), 'Max should be <= min')
    .required(),
  playersMax: yup
    .number()
    .positive()
    .min(yup.ref('playersMin'), 'Max should be >= min')
    .required(),
  // bookFrom: yup
  //   .number()
  //   .positive()
  //   .max(yup.ref('bookTo'), 'Book From should be <= book To'),
  // bookTo: yup
  //   .number()
  //   .positive()
  //   .min(yup.ref('bookFrom'), 'Book To should be >= book From'),
  // playFrom: yup
  //   .number()
  //   .positive()
  //   .max(yup.ref('playTo'), 'Play To should be <= play To'),
  // playTo: yup
  //   .number()
  //   .positive()
  //   .min(yup.ref('bookFrom'), 'Play From should be >= play To'),
  bookFrom: yup.string().test((item, context) => {
    const availableAllTime = context.parent.availableAllTime
    const bookFrom = context.parent.bookFrom
    const bookTo = context.parent.bookTo
    return availableAllTime.value ? true : bookFrom <= bookTo
  }),
  playFrom: yup.string().test((item, context) => {
    const availableAllTime = context.parent.availableAllTime
    const playFrom = context.parent.playFrom
    const playTo = context.parent.playTo
    return availableAllTime.value ? true : playFrom <= playTo
  }),
  playTo: yup.string().test((item, context) => {
    const availableAllTime = context.parent.availableAllTime
    const playFrom = context.parent.playFrom
    const playTo = context.parent.playTo
    return availableAllTime.value ? true : playFrom <= playTo
  }),
  bookTo: yup.string().test((item, context) => {
    const availableAllTime = context.parent.availableAllTime
    const bookFrom = context.parent.bookFrom
    const bookTo = context.parent.bookTo
    return availableAllTime.value ? true : bookFrom <= bookTo
  }),
  availableAllTime: yup.object(),
  available: yup.number().positive().required(),
  discount: yup.number().positive().required(),
  partner: yup.object().required(),
  eventvariants: yup
    .array()
    .test(
      'eventvariants',
      'Must select at least one experience',
      (val) => val.length !== 0
    )
})
