import { Box, Text } from '@chakra-ui/react'
import PrivateGamePricingList from './PrivateGamePricingList'
import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../../../../../constants'
import { usePrivateGamePrices } from '../../../../../../libs/hooks/useBasePrices'

export default function PrivateGamePricing({
  eventVariantId = null,
  minPlayersLimitation = null,
  maxPlayersLimitation = null
}) {
  const isLargerThan768 = useSelector(matchMedia768InState)

  const { data = [] } = usePrivateGamePrices(+eventVariantId, {
    enabled: eventVariantId !== null
  })

  return (
    <Box mb={{ base: '2px', md: '24px', '2xl': '32px' }}>
      <Box bg="white" padding="16px" mb="2px">
        <Text fontSize={{ base: '18px', '2xl': '24px' }} fontWeight="500">
          Private Game pricing
        </Text>
      </Box>
      <Box
        bg="white"
        padding={{ base: '16px', '2xl': '24px' }}
        d="grid"
        gridTemplateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gridGap="2px"
      >
        <PrivateGamePricingList
          expanded={isLargerThan768}
          minPlayersLimitation={minPlayersLimitation}
          maxPlayersLimitation={maxPlayersLimitation}
          slots={data}
          eventVariantId={eventVariantId}
        />
      </Box>
    </Box>
  )
}
