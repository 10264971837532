import { useMutation, useQuery } from 'react-query'

import {
  deleteHoliday,
  editHoliday,
  getHoliday,
  getHolidays,
  newHoliday
} from '../../api/Settings/attractions'

export function useHolidays(queryOption = {}) {
  return useQuery(['holidays'], () => getHolidays(), {
    ...queryOption
  })
}

export function useHoliday(holidayId, queryOption = {}) {
  return useQuery(['holiday', holidayId], () => getHoliday(holidayId), {
    ...queryOption
  })
}

export function useEditHoliday(options = {}) {
  return useMutation(({ obj }) => editHoliday(obj), {
    ...options
  })
}

export function useDeleteHoliday(options = {}) {
  return useMutation(({ holidayId }) => deleteHoliday(holidayId), {
    ...options
  })
}

export function useNewHoliday(options = {}) {
  return useMutation(({ obj }) => newHoliday(obj), {
    ...options
  })
}
