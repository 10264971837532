import { useMutation, useQuery } from 'react-query'

import {
  createPromo,
  deletePromocode,
  getPromocode,
  getPromocodes,
  getPromocodeStat,
  updatePromo
} from '../../api/discounts/promocodes'

export function usePromocodes(filter = {}, queryOption = {}) {
  const { search = null, page = null, pageSize = null } = filter
  return useQuery(
    ['promo', search, page, pageSize],
    () => getPromocodes(filter),
    {
      ...queryOption
    }
  )
}

export function usePromocode(id, queryOption = {}) {
  return useQuery(['promo', id], () => getPromocode(id), {
    ...queryOption
  })
}

export function useCreatePromocode(options = {}) {
  return useMutation(({ obj }) => createPromo(obj), {
    ...options
  })
}

export function useDeletePromocode(options = {}) {
  return useMutation(({ id }) => deletePromocode(id), {
    ...options
  })
}

export function useUpdatePromocode(options = {}) {
  return useMutation(({ obj }) => updatePromo(obj), {
    ...options
  })
}

export function usePromocodeStat(id, queryOption = {}) {
  return useQuery(['promoStat', id], () => getPromocodeStat(id), {
    ...queryOption
  })
}
