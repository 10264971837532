import { Box, Switch, Text } from '@chakra-ui/react'
import { useUpdateCutOffTime } from '../../../../libs/hooks/useUpdateCutOffTime'
import { useQueryClient } from 'react-query'
import { useEffect, useState } from 'react'

export default function CutOffElement({
  experienceId,
  name,
  cutOffTime,
  isDisabled = false
}) {
  const queryClient = useQueryClient()
  const { mutate } = useUpdateCutOffTime()

  function onChangeHandler(e) {
    mutate(
      {
        experienceId,
        cutOffTime: e.target.checked
      },
      {
        onSuccess: () => queryClient.invalidateQueries(['experience'])
      }
    )
  }

  return (
    <Box
      d="grid"
      gridTemplateColumns="3fr 1fr"
      bg="white"
      p="12px"
      opacity={isDisabled ? 0.5 : 1}
    >
      <Text fontSize="14px" fontWeight="400" color="custom.gray.300">
        {name}
      </Text>
      <Switch
        size="md"
        isChecked={cutOffTime}
        justifySelf="end"
        isDisabled={isDisabled}
        onChange={onChangeHandler}
      />
    </Box>
  )
}
