import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { useField, useFormikContext } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'

import FormikDropdown from '../FormikDropdown'
import FormikOrderInput from '../FormikOrderInput'
import CheckoutForm from '../../CheckoutForm'
import { useLocations } from '../../../../libs/hooks/useLocations'

export default function FormikPayments({
  stripePromise = '',
  includeLabel = true,
  enableStripe = true,
  isLoading = false,
  experience = '',
  preselectedLocation = null,
  ...rest
}) {
  const { name = '' } = rest
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  const [tabIndex, setTabIndex] = useState(0)
  const {
    values: formikValues,
    handleSubmit,
    setFieldValue
  } = useFormikContext()
  const { data: { results: locations = [] } = {} } = useLocations()
  const optionsLocation = useMemo(() => {
    if (locations.length !== 0) {
      return locations.map((location) => {
        return {
          value: location.id,
          label: location.name
        }
      })
    } else return []
  }, [locations])

  useEffect(() => {
    if (!formikValues?.payData?.location && optionsLocation.length !== 0) {
      let selectedLocation = optionsLocation[0]
      if (preselectedLocation) {
        selectedLocation = optionsLocation.find(
          (loc) => loc.value === preselectedLocation
        )
      }

      setFieldValue('payData.location', selectedLocation)
    }
  }, [preselectedLocation, formikValues, optionsLocation])

  function paymentType(idx) {
    if (enableStripe) {
      if (idx === 0) {
        return 'stripe'
      }

      if (idx === 1) {
        return 'offline'
      }

      if (idx === 2) {
        return 'cash'
      }
    } else {
      if (idx === 0) {
        return 'offline'
      }

      if (idx === 1) {
        return 'cash'
      }
    }
  }

  useEffect(() => {
    setValue(paymentType(tabIndex))
  }, [tabIndex])

  return (
    <Tabs variant="gbase" onChange={(idx) => setTabIndex(idx)} {...rest}>
      <TabList
        mb={{ base: '16px' }}
        fontSize={{ base: '12px', '2xl': '14px' }}
        flexDir="column"
      >
        {includeLabel && (
          <Text
            color="custom.gray.200"
            fontWeight="400"
            fontSize="inherit"
            mb="8px"
          >
            Payment Method
          </Text>
        )}
        <Box d="flex">
          {enableStripe && (
            <Tab
              mr={{ base: '4px', md: '8px', '2xl': '4px' }}
              fontSize="inherit"
            >
              Online
            </Tab>
          )}
          <Tab
            {...(enableStripe
              ? { ml: { base: '4px', md: '8px', '2xl': '4px' } }
              : { ml: 0 })}
            mr={{ base: '4px', md: '8px', '2xl': '4px' }}
            fontSize="inherit"
          >
            Offline card
          </Tab>
          <Tab ml={{ base: '4px', md: '8px', '2xl': '4px' }} fontSize="inherit">
            Cash
          </Tab>
        </Box>
      </TabList>
      <TabPanels>
        {enableStripe && (
          <TabPanel>
            <Box mb={{ base: '16px' }}>
              {stripePromise ? (
                <Elements stripe={stripePromise} options={{ locale: 'en' }}>
                  <ElementsConsumer>
                    {({ stripe, elements }) => (
                      <CheckoutForm
                        stripe={stripe}
                        elements={elements}
                        handleSubmitFormik={handleSubmit}
                        setFieldValue={setFieldValue}
                        isLoading={isLoading}
                      />
                    )}
                  </ElementsConsumer>
                </Elements>
              ) : (
                <>
                  {experience === '' ? (
                    <Box
                      color="custom.gray.300"
                      fontSize={{ base: '14px', md: '16px' }}
                      d="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                    >
                      For Payment Method
                      <Text as="p" color="custom.blue.600" m="0 8px">
                        'Online'
                      </Text>
                      field
                      <Text as="p" color="custom.blue.600" m="0 8px">
                        'Experience'
                      </Text>
                      required.
                    </Box>
                  ) : (
                    <Box
                      color="custom.gray.300"
                      fontSize={{ base: '14px', md: '16px' }}
                      d="flex"
                      justifyContent="center"
                    >
                      Payment gate is not set up.
                    </Box>
                  )}
                </>
              )}
            </Box>
          </TabPanel>
        )}
        <TabPanel>
          <Box>
            <FormikOrderInput
              name="payData.transaction"
              id="payData.transaction"
              label="Transaction"
            />
          </Box>
        </TabPanel>
        <TabPanel>
          <FormikDropdown
            main={{
              options: optionsLocation,
              placeholder: 'Locations'
            }}
            formik={{
              name: 'payData.location',
              id: 'payData.location'
            }}
            // label={isLargerThan1440 ? (!!formikValues.location ? '' : 'Locations') : ''}

            // selectProps={{
            //     styles: {
            //         control: {
            //             height: isLargerThan1440 ? '56px' : '40px',
            //         },
            //     }
            // }}
            chakraProps={{
              h: '100%',
              fontSize: 'inherit'
            }}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
