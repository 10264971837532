import { useQuery } from 'react-query'

import { loadAutocompeteListWaivers } from '../../api/booking'

export function useAutocompleteWaivers(searchString = '', queryOption = {}) {
  return useQuery(
    ['autocompleteWaivers', searchString],
    () => loadAutocompeteListWaivers(searchString),
    {
      ...queryOption,
      cacheTime: 60000
    }
  )
}
