import { useSelector } from 'react-redux'
import FormikDropdown from '../Common/FormikComponents/FormikDropdown'
import { useCities } from '../../libs/hooks/useCities'
import { matchMedia768InState, matchMedia1440InState } from '../../constants'

export const CityDropdown = ({ name = 'cityId', id = name }) => {
  const cities = useCities()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)

  const dropDownSelectProps = {
    styles: {
      control: {
        height: isLargerThan1440 ? '56px' : '40px',
        padding: isLargerThan1440 ? '12px 4px 0' : '0',
        border: !isLargerThan768 && 'none'
      }
    }
  }

  const errorMessage = () => {
    switch (true) {
      case !cities.length:
        return 'Error fetching cities list'
      case !name:
        return 'Provide name to input'
      default:
        return null
    }
  }

  return (
    <FormikDropdown
      main={{
        options: cities.map((city) => ({
          label: city.name,
          value: city.id
        })),
        placeholder: errorMessage() || 'Choose city'
      }}
      formik={{
        name: name,
        id: id
      }}
      selectProps={dropDownSelectProps}
      isDisabled={errorMessage()}
      mb="8px"
    />
  )
}
