import React from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import AttractionIconButtons from './AttractionIconButtons'
import AttractionsLargeButtons from './AttractionsLargeButtons'
import {
  cloneElementForEdit,
  setEdit,
  setNewMode
} from '../../localState/actions'
import {
  clonedElementInState,
  useAttractionsDispatch,
  useAttractionsSelector
} from '../../localState/constants'
import {
  useNewEvent,
  useUpdateEvent
} from '../../../../../../libs/hooks/useEvents'
import { matchMedia1440InState } from '../../../../../../constants'

export default function AttractionsButtons({
  row,
  attractionPermissionAccess
}) {
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const { mutate: editExperience } = useUpdateEvent()
  const { mutate: createNewEvent } = useNewEvent()
  const clonedElement = useAttractionsSelector(clonedElementInState)
  const attractionDispatch = useAttractionsDispatch()
  const queryClient = useQueryClient()
  function saveEdit(eventId) {
    const toSend = {
      name: clonedElement['name'],
      interval: +clonedElement['interval'],
      max_storylines: +clonedElement['max_storylines'],
      max_players: +clonedElement['max_players'],
      location: +clonedElement['location'],
      payments: +clonedElement['payments'],
      pixel_id: clonedElement['pixel_id'],
      google_analytics: clonedElement['google_analytics'],
      stripe: clonedElement['stripe'].id ? clonedElement['stripe']?.id : '',
      eventId: +eventId
    }
    const final = {}

    Object.keys(toSend).forEach((key) => {
      if (toSend[key]) {
        final[key] = toSend[key]
      }
    })
    editExperience(
      { obj: final },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['events'])
          attractionDispatch(setEdit({ enabled: false, idCell: null }))
          attractionDispatch(cloneElementForEdit(null))
        }
      }
    )
  }

  function newEvent() {
    const toSend = {
      name: clonedElement['name'],
      interval: +clonedElement['interval'],
      max_storylines: +clonedElement['max_storylines'],
      max_players: +clonedElement['max_players'],
      location: +clonedElement['location'],
      payments: +clonedElement['payments'],
      pixel_id: clonedElement['pixel_id'],
      google_analytics: clonedElement['google_analytics'],
      stripe: clonedElement['stripe'].id ? clonedElement['stripe']?.id : ''
    }
    const final = {}

    Object.keys(toSend).forEach((key) => {
      if (toSend[key]) {
        final[key] = toSend[key]
      }
    })

    createNewEvent(
      { obj: final },
      {
        onSuccess: () => {
          attractionDispatch(setNewMode(false))
          attractionDispatch(setEdit({ enabled: false, idCell: null }))
          attractionDispatch(cloneElementForEdit(null))
          queryClient.invalidateQueries(['events'])
        }
      }
    )
  }
  return isLargerThan1440 ? (
    <AttractionsLargeButtons
      row={row}
      saveEdit={saveEdit}
      createNew={newEvent}
      attractionPermissionAccess={attractionPermissionAccess}
    />
  ) : (
    <AttractionIconButtons
      row={row}
      saveEdit={saveEdit}
      createNew={newEvent}
      attractionPermissionAccess={attractionPermissionAccess}
    />
  )
}
