import { useQuery } from 'react-query'

import { getCashList } from '../../api/cash'

export function useCash(filters = {}, queryOption = {}) {
  const queryKeys = ['cash']
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      queryKeys.push(filters[key])
    }
  })

  return {
    queryKeys,
    ...useQuery(queryKeys, () => getCashList(filters), {
      ...queryOption
    })
  }
}
