import { Box, IconButton, Text } from '@chakra-ui/react'
import DropdownSelect from '../../../../../Common/DropdownSelect'
import React, { useEffect, useMemo, useState } from 'react'
import { useTheme } from '@emotion/react'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import { PERMISSION_NOTIFICATIONS } from '../../../../../../constants'

export default function LocationTemplatesElement({
  loc,
  templatesOptions,
  applyChanges = null,
  applyTemplate = null,
  isLoading,
  editMode,
  setEditMode,
  idx
}) {
  const theme = useTheme()
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const customColors = theme.colors.custom
  const [isFirstRun, setIsFirstRun] = useState(true)

  const initialStateTemplates = useMemo(() => {
    const template = loc.template
    if (template === null) {
      return templatesOptions[0]
    } else {
      const templateId = loc?.template?.id
      for (let i = 0; i < templatesOptions.length; i++) {
        if (templatesOptions[i].value === templateId) {
          return templatesOptions[i]
        }
      }
    }
  }, [loc, templatesOptions])

  const [selectValues, setSelectValues] = useState({
    template: initialStateTemplates
  })

  const template = selectValues?.template

  function onChangeDropdown(prop) {
    return (val) =>
      setSelectValues({
        ...selectValues,
        [prop]: val
      })
  }

  useEffect(() => {
    if (!isFirstRun) {
      setEditMode({
        idx,
        enabled: true
      })
    }
    if (isFirstRun) {
      setIsFirstRun(false)
    }
  }, [selectValues])

  function saveHandler() {
    if (loc.template === null) {
      if (
        selectValues.template &&
        applyTemplate !== null &&
        typeof applyTemplate === 'function'
      ) {
        applyTemplate(selectValues.template.value, loc.location.id)
      }
    } else {
      if (
        selectValues.template &&
        applyChanges !== null &&
        typeof applyChanges === 'function'
      ) {
        applyChanges(selectValues.template.value, loc.location.id)
      }
    }
  }

  const newGame = {
    box: {
      bg: 'white',
      padding: { base: '16px', md: '8px 16px', '2xl': '8px 24px' },
      fontSize: { base: '12px', '2xl': '14px' },
      mb: '2px',
      display: { base: 'block', md: 'grid' },
      gridTemplateColumns: { base: 'unset', md: '2fr 0.3fr 1fr' },
      alignItems: { base: 'unset', md: 'center' },
      gridGap: { base: 'unset', md: '16px' },
      ...(editMode.idx !== idx && editMode.enabled && { opacity: 0.5 })
    },
    text: {
      color: 'custom.black.500',
      mb: { base: '8px', md: 'unset' },
      fontWeight: { base: '500', md: '400' },
      fontSize: 'inherit',
      gridColumn: { base: 'unset', md: '1/2' }
    },
    dropdown: {
      1: {
        chakraProps: {
          mb: { base: '8px', md: 'unset' },
          gridColumn: { base: 'unset', md: '3/4' }
        },
        selectProps: {
          main: {
            isDisabled:
              isLoading ||
              (editMode.idx !== idx && editMode.enabled) ||
              !notificationPermission,
            options: templatesOptions,
            placeholder: 'Template...',
            value: selectValues?.template,
            onChange: onChangeDropdown('template')
          },
          styles: {
            ...(!template && {
              control: {
                background: customColors.red[20]
              }
            })
          }
        }
      }
    }
  }

  return (
    <Box {...newGame.box}>
      <Text {...newGame.text}>{loc.location.name}</Text>
      {editMode.idx === idx && editMode.enabled && (
        <IconButton
          variant="blue"
          w="40px"
          h="40px"
          m="0 auto"
          icon={<DisketteIcon />}
          onClick={saveHandler}
        />
      )}
      <DropdownSelect {...newGame.dropdown[1]} />
    </Box>
  )
}
