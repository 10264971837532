import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PencilIcon } from '../../../../../../assets/Icons/PencilIcon'
import {
  useNotificationTemplate,
  usePatchNotificationTemplate
} from '../../../../../../libs/hooks/useNotificationTemplates'
import { newAndEditNotificationTemplateModalValidation } from '../../../../../../configs/validation/newAndEditNotificationTemplateModalValidation'
import { PERMISSION_NOTIFICATIONS } from '../../../../../../constants'
import CustomTextarea from '../../../../../Common/CustomTextarea'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import Preloader from '../../../../../Common/Preloader'

export default function EditTemplateModal({ notificationTemplateId }) {
  const queryClient = useQueryClient()
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: templateData = {}, isLoading: isLoadingTemplate } =
    useNotificationTemplate(notificationTemplateId, {
      enabled: isOpen
    })
  const { mutate: patchNotificationTemplate, isLoading: editInProgress } =
    usePatchNotificationTemplate()

  function onSubmitHandler(values) {
    const toSend = {
      notificationTemplateId,
      templateName: values.templateName,
      smsMessage: values.smsMessage,
      emailMessage: values.emailMessage,
      sendHoursBeforeGame: values.sendHoursBeforeGame,
      sendHoursAfterGame: values.sendHoursAfterGame
    }
    patchNotificationTemplate(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['notificationTemplates'])
          onCloseHandler()
        }
      }
    )
  }

  const emailMessage = templateData?.email_message
  const smsMessage = templateData?.sms_message
  const templateName = templateData?.template_name
  const sendHoursBeforeGame =
    templateData?.reminder_delay === null ? 0 : templateData?.reminder_delay
  const sendHoursAfterGame =
    templateData?.request_feedback_delay === null
      ? 0
      : templateData?.request_feedback_delay

  const formik = useFormik({
    initialValues: {
      emailMessage,
      smsMessage,
      templateName,
      sendHoursBeforeGame,
      sendHoursAfterGame
    },
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
    validationSchema: newAndEditNotificationTemplateModalValidation
  })
  const formikValues = formik.values
  const formikHandleSubmit = formik.handleSubmit
  const setFieldValue = formik.setFieldValue
  const formikErrors = formik.errors
  const formikTouched = formik.touched
  const resetForm = formik.resetForm
  const isLoading = isLoadingTemplate || editInProgress

  function onCloseHandler() {
    resetForm()
    onClose()
  }

  function isError(prop) {
    return formikErrors[prop] && formikTouched[prop]
  }

  function onChangeHandler(prop) {
    return (e) => {
      const value = e.target.value
      setFieldValue(prop, value)
    }
  }

  const blue600 = {
    color: 'custom.blue.600'
  }
  return (
    <>
      <IconButton
        aria-label="editAccount"
        variant="funcColumnIconButtons"
        icon={<PencilIcon w="inherit" h="inherit" />}
        onClick={onOpen}
        mr={{ base: '16px', '2xl': '24px' }}
        color="custom.gray.200"
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            pos="relative"
            maxW={{ base: '288px', md: '720px', '2xl': '656px' }}
          >
            {isLoading && (
              <Box
                pos="absolute"
                top="0"
                left="0"
                bottom="0"
                right="0"
                zIndex={2}
              >
                <Preloader h="8px" w="calc(100% - 4px)" opacity="0.8" m="2px" />
              </Box>
            )}
            <ModalHeader
              mb="34px"
              d="flex"
              opacity={isLoading ? 0.4 : 1}
              fontSize={{ base: '18px', '2xl': '24px' }}
            >
              Edit template
              <Box
                ml="auto"
                d={{ base: 'none', md: 'grid' }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="8px"
                fontSize={{ base: '12px', '2xl': '14px' }}
              >
                <Button
                  w="100%"
                  variant="blue"
                  fontSize="inherit"
                  isDisabled={!notificationPermission}
                  onClick={formikHandleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  w="100%"
                  variant="red"
                  fontSize="inherit"
                  onClick={onCloseHandler}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <ModalCloseButton d={{ md: 'none' }} />
            <ModalBody
              fontSize={{ base: '12px' }}
              opacity={isLoading ? 0.4 : 1}
              d={{ base: 'block' }}
            >
              <Box mb="16px" w={{ base: '100%' }}>
                <Text as="span" d="flex" flexWrap="wrap">
                  <Text {...blue600}>{'{{ name }}'}</Text>
                  <Text ml="4px">- Customers name,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ address }}'}
                  </Text>
                  <Text ml="4px">- Location,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ eventvariant }}'}
                  </Text>
                  <Text ml="4px">- Experience,</Text>
                  <Text {...blue600} ml="4px">
                    #{'{{ code }}'}
                  </Text>
                  <Text ml="4px">- Booking number,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ date }}'}
                  </Text>
                  <Text ml="4px">- Date,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ time }}'}
                  </Text>
                  <Text ml="4px">- Time,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ promo }}'}
                  </Text>
                  <Text ml="4px">- Promocode,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ waiver_link }}'}
                  </Text>
                  <Text ml="4px">- Waiver link,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ image_link }}'}
                  </Text>
                  <Text ml="4px">- Image link,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ good_feedback_link }}'}
                  </Text>
                  <Text ml="4px">- Good feedback link</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ bad_feedback_link }}'}
                  </Text>
                  <Text ml="4px">- Bad feedback link</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ combo_code }}'}
                  </Text>
                  <Text ml="4px">- Combo code</Text>
                </Text>
              </Box>
              <CustomTextarea
                label="Template name:"
                editMode={true}
                containerProps={{
                  ...(isError('templateName') && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateName')}
                externalValue={formikValues.templateName}
              />
              <CustomTextarea
                label="SMS message:"
                editMode={true}
                containerProps={{
                  ...(isError('smsMessage') && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('smsMessage')}
                externalValue={formikValues.smsMessage}
              />
              <CustomTextarea
                label="E-mail message:"
                editMode={true}
                containerProps={{
                  ...(isError('emailMessage') && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('emailMessage')}
                externalValue={formikValues.emailMessage}
              />
              <CustomTextarea
                label="Send Hours Before Game:"
                editMode={true}
                containerProps={{
                  ...(isError('sendHoursBeforeGame') && {
                    bg: 'custom.red.20'
                  })
                }}
                externalOnChange={onChangeHandler('sendHoursBeforeGame')}
                externalValue={formikValues.sendHoursBeforeGame}
              />
              <CustomTextarea
                label="Send Hours After Game:"
                editMode={true}
                containerProps={{
                  ...(isError('sendHoursAfterGame') && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('sendHoursAfterGame')}
                externalValue={formikValues.sendHoursAfterGame}
              />
            </ModalBody>

            <ModalFooter
              d={{ base: 'grid', md: 'none' }}
              opacity={isLoading ? 0.4 : 1}
              fontSize={{ base: '12px', '2xl': '14px' }}
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
            >
              <Button
                w="100%"
                variant="blue"
                isDisabled={!notificationPermission}
                onClick={formikHandleSubmit}
              >
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button w="100%" variant="red" onClick={onCloseHandler}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
