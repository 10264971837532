import { Box, Button, Text } from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import AccordionDropdown from './AccordionDropdown'
import AccordionInput from './AccordionInput'
import { useNewEvent } from '../../../../../libs/hooks/useEvents'
import { useLocations } from '../../../../../libs/hooks/useLocations'
import { usePaymentGates } from '../../../../../libs/hooks/useStripe'
import { addAndEditAttractionValidation } from '../../../../../configs/validation/addAttractionValidation'
import StyledAccordion from '../../../../Common/StyledAccodion'

export default function NewAttractionsListItem({ setNewElement }) {
  const { data: { results: locations = [] } = {} } = useLocations()
  const queryClient = useQueryClient()
  const { mutate: createNewEvent } = useNewEvent()
  const optionsLocation = useMemo(() => {
    if (locations?.length !== 0) {
      const arr = locations.map((loc) => {
        return {
          value: loc.id,
          label: loc.name
        }
      })
      return [{ value: '', label: 'Unset' }, ...arr]
    } else {
      return []
    }
  }, [locations])

  const { data: { results: stripeData = [] } = {} } = usePaymentGates()
  const optionsStripe = useMemo(() => {
    if (stripeData?.length !== 0) {
      const arr = stripeData.map((stripe) => {
        return {
          value: {
            id: stripe.id,
            publicKey: stripe.public
          },
          label: stripe.name
        }
      })
      return [{ value: '', label: 'Unset' }, ...arr]
    } else {
      return []
    }
  }, [stripeData])

  const formik = useFormik({
    initialValues: {
      name: '',
      interval: '',
      storylines: '',
      maxplrs: '',
      location: { value: '', label: 'Unset' },
      payment: { value: '', label: 'Unset' },
      pixel: '',
      ga: ''
    },
    enableReinitialize: true,
    validationSchema: addAndEditAttractionValidation,
    onSubmit: (values) => newEvent(values)
  })

  function newEvent(values) {
    const toSend = {
      name: values.name,
      interval: values.interval,
      max_storylines: values.storylines,
      max_players: values.maxplrs,
      location: values.location.value,
      pixel_id: values.pixel,
      google_analytics: values.ga,
      stripe: values.payment.value.id ? values.payment.value.id : ''
    }
    const final = {}

    Object.keys(toSend).forEach((key) => {
      if (toSend[key]) {
        final[key] = toSend[key]
      }
    })

    createNewEvent(
      { obj: final },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(['events'])
            .then(() => setNewElement(false))
        }
      }
    )
  }

  const formikHandleSubmit = formik.handleSubmit
  const formikValues = formik.values
  const setFieldValue = formik.setFieldValue
  const resetForm = formik.resetForm

  function onChangeDropdownHandler(prop) {
    return (val) => setFieldValue(prop, val)
  }

  function cancelHandler() {
    resetForm()
    setNewElement(false)
  }

  return (
    <StyledAccordion styles={{ button: { mb: '0' } }} defaultIndex={[0]}>
      <Text
        color="custom.gray.300"
        fontSize={{ base: '12px', '2xl': '14px' }}
        fontWeight="400"
      >
        New Event
      </Text>
      <FormikProvider value={formik}>
        <Box bg="white" padding="16px">
          <AccordionInput name="name" label="Name" />
          <AccordionInput name="interval" label="Interval" />
          <AccordionInput name="storylines" label="Storylines" />
          <AccordionInput name="maxplrs" label="Max Players" />
          <AccordionInput name="pixel" label="Pixel" />
          <AccordionInput name="ga" label="Ga" />
          <AccordionDropdown
            name="location"
            mb="8px"
            options={optionsLocation}
            value={formikValues.location}
            onChange={onChangeDropdownHandler('location')}
          />
          <AccordionDropdown
            name="payment"
            mb="16px"
            options={optionsStripe}
            value={formikValues.payment}
            onChange={onChangeDropdownHandler('payment')}
          />
          <Box d="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="8px">
            <Button variant="blue" onClick={formikHandleSubmit}>
              Save
            </Button>
            <Button variant="red" onClick={cancelHandler}>
              Cancel
            </Button>
          </Box>
        </Box>
      </FormikProvider>
    </StyledAccordion>
  )
}
