import { CheckIcon, WarningTwoIcon } from '@chakra-ui/icons'
import { Box, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { createContext } from 'react'
import { useContext } from 'react'
import ReactDOM from 'react-dom'


export const NotificationContext = createContext(null)

export const useNotification = () => {
  const [store] = useContext(NotificationContext)
  return store
}

export default function NotificationService({ children }) {
  const [state, setState] = useState({
    title: 'Error',
    message: 'Error text',
    msgObjects: null,
    duration: 5000,
    type: 'error'
  })
  const title = state.title
  const message = state.message
  const duration = state.duration
  const msgObjects = state.msgObjects || null

  const root = document.querySelector('#root')

  const [isOpen, setIsOpen] = useState(false)
  const [showAnimation, setShowAnimation] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowAnimation(true)
      }, 200)

      setTimeout(() => {
        setShowAnimation(false)
      }, duration - 800)

      setTimeout(() => {
        setIsOpen(false)
      }, duration)
    }
  }, [isOpen])

  function toast({
    message,
    title,
    msgObjects,
    duration = 5000,
    type = 'error'
  }) {
    setState({
      message,
      title,
      duration,
      msgObjects,
      type
    })
    setIsOpen(true)
  }

  toast.success = (args) => toast({ ...args, type: 'success' })

  return (
    <>
      <NotificationContext.Provider value={[toast]}>
        {children}
      </NotificationContext.Provider>
      {isOpen &&
        ReactDOM.createPortal(
          <Box
            className={`notification ${showAnimation ? 'open' : ''}`}
            boxShadow="base"
            borderColor={'gray.200'}
          >
            <Box d="flex" alignItems={'center'} mb={1}>
              {state.type === 'error' && (
                <WarningTwoIcon boxSize={16} color={'red.400'} />
              )}
              {state.type === 'success' && <CheckIcon color={'green.400'} />}
              <Text
                ml="2"
                fontWeight={'semibold'}
                color={state.type === 'success' ? 'green.500' : 'red.500'}
                fontSize={16}
              >
                {title}
              </Text>
            </Box>
            <Text fontSize={13}>
              {msgObjects !== null && Object.keys(msgObjects)?.length !== 0
                ? ''
                : Array.isArray(message)
                ? message?.map((message) => message).join(', ')
                : message}
            </Text>
            {state.type === 'error' && <Text fontSize={10} mt={1} color='gray.600'>
              If error persists contact technical support
            </Text>}
          </Box>,
          root
        )}
    </>
  )
}
