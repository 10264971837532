import { Box, Button, Text } from '@chakra-ui/react'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { useHistory, useParams } from 'react-router-dom'
import {
  authTokenInState,
  matchMedia768InState,
  PROMO_PAGE_URL
} from '../../../constants'
import GBaseTag from '@/components/Common/GBaseTag'
import CardList from '@/components/Common/CardList'
import { useSelector } from 'react-redux'
import { usePromocodeStat } from '../../../libs/hooks/usePromo'
import { friendlyTime } from '../../../libs'
import PromoPageTable from './PromoPageTable'

export default function StatisticsPromoPage() {
  const history = useHistory()
  const { id: promoId = 'undefined' } = useParams()
  const authToken = useSelector(authTokenInState)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { data: statPromo, isLoading } = usePromocodeStat(+promoId, {
    enabled: !!promoId
  })

  const bookings = statPromo?.bookings ? statPromo?.bookings : []
  const code = statPromo?.code ? statPromo?.code : '—'
  const created = statPromo?.created
    ? friendlyTime(statPromo?.created, 'MM.DD.YY')
    : '—'
  const expDate = statPromo?.exp_date
    ? friendlyTime(statPromo?.exp_date, 'MM.DD.YY')
    : '—'
  const details = statPromo?.details ? statPromo?.details.toFixed(2) : '—'
  const partner = statPromo?.partner ? statPromo?.partner : '—'
  const status = statPromo?.status ? statPromo?.status : '—'

  function goTo(path) {
    return () => history.push(path)
  }

  return (
    <Box
      p={{ base: '24px 16px', '2xl': '48px 0 0' }}
      maxW={{ '2xl': '1100px' }}
      m={{ '2xl': '0 auto' }}
    >
      <Button
        variant="ghost"
        fontFamily="inherit"
        p="0"
        w={{ md: '100%', '2xl': 'auto' }}
        justifyContent="start"
        mb="24px"
        onClick={goTo(`${PROMO_PAGE_URL}/${promoId}`)}
      >
        <Text
          as={'span'}
          fontSize={{ base: '24px', '2xl': '32px' }}
          fontWeight={{ base: '600' }}
          color="custom.black.600"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxWidth="100%"
          lineHeight="164%"
        >
          <ArrowIcon
            w="auto"
            height="16px"
            mr="16px"
            mb="2px"
            color="custom.gray.200"
          />
          Statistics {isLargerThan768 && code}
        </Text>
      </Button>
      <Box
        bg="white"
        p={{ base: '16px' }}
        mb="2px"
        d={{ md: 'grid' }}
        gridTemplateColumns={{ md: 'repeat(4, 1fr)' }}
        gridGap={{ md: '16px' }}
        alignItems="center"
        fontSize={{ base: '12px', '2xl': '14px' }}
      >
        <Box
          d="grid"
          gridTemplateColumns={{ base: '1fr 1fr', md: '1fr 2fr' }}
          mb="6px"
        >
          <Text color="custom.gray.300">Code:</Text>
          <Text
            color="custom.gray.900"
            justifySelf={{ base: 'end', md: 'start' }}
          >
            {code}
          </Text>
        </Box>
        <Box
          d="grid"
          gridTemplateColumns={{ base: '1fr 1fr', md: '1fr 2fr' }}
          mb="6px"
        >
          <Text color="custom.gray.300">Details:</Text>
          <Text
            color="custom.gray.900"
            justifySelf={{ base: 'end', md: 'start' }}
          >
            {details}
          </Text>
        </Box>
        <Box
          d="grid"
          gridTemplateColumns={{ base: '1fr 1fr', md: '1fr 2fr' }}
          mb="6px"
        >
          <Text color="custom.gray.300">Partner:</Text>
          <Text
            color="custom.gray.900"
            justifySelf={{ base: 'end', md: 'start' }}
          >
            {partner}
          </Text>
        </Box>
        <Box
          d="grid"
          gridTemplateColumns={{ base: '1fr 1fr', md: '1fr 2fr' }}
          mb="6px"
        >
          <Text color="custom.gray.300">Created:</Text>
          <Text
            color="custom.gray.900"
            justifySelf={{ base: 'end', md: 'start' }}
          >
            {created}
          </Text>
        </Box>
        <Box
          d="grid"
          gridTemplateColumns={{ base: '1fr 1fr', md: '1fr 2fr' }}
          mb="4px"
        >
          <Text color="custom.gray.300">Exp.date:</Text>
          <Text
            color="custom.gray.900"
            justifySelf={{ base: 'end', md: 'start' }}
          >
            {expDate}
          </Text>
        </Box>
        <Box
          d="grid"
          gridTemplateColumns={{ base: '1fr 1fr', md: '1fr 2fr' }}
          mb="6px"
          alignItems="center"
        >
          <Text color="custom.gray.300">Status:</Text>
          <GBaseTag
            color={status === 'Used' ? 'red' : 'green'}
            label={status}
            justifySelf={{ base: 'end', md: 'start' }}
            m={{ md: 'unset' }}
          />
        </Box>
      </Box>
      <Box p={{ base: '16px' }} bg="white">
        <Text
          color="custom.black.500"
          fontWeight="500"
          fontSize={{ base: '18px' }}
        >
          {bookings.length === 0 ? 'No bookings' : 'Bookings'}
        </Text>
      </Box>
      {bookings.length !== 0 && (
        <>
          {isLargerThan768 ? (
            <PromoPageTable tableData={bookings} tableName="PromoPageTable" />
          ) : (
            <CardList data={bookings} isLoading={isLoading} />
          )}
        </>
      )}
    </Box>
  )
}
