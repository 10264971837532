import React, { useEffect, useMemo } from 'react'
import { Box, Button, Text, useTheme } from '@chakra-ui/react'
import { TABLE_COLUMN_VALUES_ATTRACTIONS } from '../../../../../configs'
import { TABLE_COLUMNS_SIZES_ATTRACTIONS } from '../../../../../configs/tablesSizes'
import TableCreator from '../../../../Common/TableCreator'
import { PlusIcon } from '../../../../../assets/Icons/PlusIcon'
import EditableCell from './EditableCell'
import DropdownCell from './DropdownCell'
import {
  attrDataInState,
  clonedElementInState,
  editInState,
  newModeInState,
  requiredPropsInState,
  useAttractionsDispatch,
  useAttractionsSelector
} from '../localState/constants'
import AttractionsButtons from './AttractionsButtons'
import { Link } from 'react-router-dom'
import { useLocations } from '../../../../../libs/hooks/useLocations'
import { setNewMode } from '../localState/actions'
import { editHandler } from '../Common'
import { usePaymentGates } from '../../../../../libs/hooks/useStripe'
import DropdownCellPay from './DropdownCellPay'

export default function AttractionsTable({ attractionPermissionAccess }) {
  const theme = useTheme()
  const attractionDispatch = useAttractionsDispatch()
  const tableData = useAttractionsSelector(attrDataInState)
  const edit = useAttractionsSelector(editInState)
  const newMode = useAttractionsSelector(newModeInState)
  const clonedElement = useAttractionsSelector(clonedElementInState)
  const { data: { results: locations = [] } = {} } = useLocations()
  const optionsLocation = useMemo(() => {
    if (locations?.length !== 0) {
      const arr = locations.map((loc) => {
        return {
          value: loc.id,
          label: loc.name
        }
      })
      return [{ value: '', label: 'Unset' }, ...arr]
    } else {
      return []
    }
  }, [locations])

  function locationName(locId, locations) {
    let locName = ''
    for (let i = 0; i < locations?.length; i++) {
      if (locations[i]?.id === +locId) {
        locName = locations[i]?.name
        break
      }
    }
    return locName
  }

  function stripeName(stripeId, stripeData) {
    let stripeName = ''
    for (let i = 0; i < stripeData?.length; i++) {
      if (stripeData[i]?.id === +stripeId) {
        stripeName = stripeData[i]?.name
        break
      }
    }
    return stripeName
  }

  const keysForNewItem = [
    'name',
    'interval',
    'storylines',
    'max ppl',
    'location',
    'pixel',
    'GA',
    ''
  ]
  const customsColor = theme.colors.custom

  const { data: paymentGate } = usePaymentGates()
  const optionsStripe = useMemo(() => {
    if (paymentGate?.length !== 0) {
      const arr = paymentGate?.map((stripe) => {
        return {
          value: {
            id: stripe.id,
            publicKey: stripe.public
          },
          label: stripe.name
        }
      }) || []
      return [{ value: '', label: 'Unset' }, ...arr]
    } else {
      return []
    }
  }, [paymentGate])

  const data = useMemo(() => {
    const arr = tableData?.map((data) => {
      return {
        ...data,
        location: locationName(data.location, locations),
        stripeName: paymentGate?.find(pg => pg.id === data.stripe)?.name
      }
    })
    if (newMode) {
      const obj = {}
      keysForNewItem.forEach((key) => {
        obj[key] = ''
      })
      return [obj, ...arr]
    } else {
      return arr
    }
  }, [tableData, locations, newMode, paymentGate])

  useEffect(() => {
    if (newMode) {
      editHandler(0, edit, attractionDispatch, true)()
    }
  }, [data, newMode])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 8px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }
  const requiredProps = useAttractionsSelector(requiredPropsInState)
  function createCustomTr() {
    const main = {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      h: '56px',
      bg: 'white'
    }

    if (typeof edit.idCell === 'number') {
      return {
        tr: {
          ...main,
          css: {
            [`tbody > &:nth-of-type(${edit.idCell + 1})`]: {
              background: customsColor.blue[300]
            }
          }
        }
      }
    } else {
      return { tr: { ...main } }
    }
  }

  const customColumnHeader = (
    <Button
      variant="unstyled"
      color={edit.enabled ? 'custom.gray.200' : 'custom.blue.600'}
      fontSize="inherit"
      fontWeight="500"
      display="inline-flex"
      alignItems="center"
      _focus={{ boxShadow: 'none' }}
      disabled={edit.enabled || !attractionPermissionAccess}
      onClick={() => attractionDispatch(setNewMode(true))}
    >
      <PlusIcon color="inherit" mr="8px" />
      New
    </Button>
  )

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_ATTRACTIONS.map((field) => {
      const fieldObject = {
        Header:
          field.Accessor === 'funcColumn' ? customColumnHeader : field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'name') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (edit.enabled && +row.id === edit.idCell) {
            return <EditableCell prop="name" isRequired={true} />
          }
          return (
            <Link to={`settings/experience/${row.original.id}`}>
              <Text
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                color="custom.blue.600"
                textDecor="underline"
              >
                {row.original.name}
              </Text>
            </Link>
          )
        }
      }

      if (field.Header.toLowerCase() === 'interval') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (edit.enabled && +row.id === edit.idCell) {
            return (
              <EditableCell
                prop="interval"
                isNumeric={true}
                isRequired={true}
              />
            )
          }
          return <>{row.original.interval}</>
        }
      }

      if (field.Header.toLowerCase() === 'storylines') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (edit.enabled && +row.id === edit.idCell) {
            return <EditableCell prop="max_storylines" isNumeric={true} />
          }
          return (
            <>
              {row.original.max_storylines ? row.original.max_storylines : '-'}
            </>
          )
        }
      }

      if (field.Header.toLowerCase() === 'max ppl') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (edit.enabled && +row.id === edit.idCell) {
            return <EditableCell prop="max_players" isNumeric={true} />
          }
          return (
            <>{row.original.max_players ? row.original.max_players : '-'}</>
          )
        }
      }

      if (field.Header.toLowerCase() === 'location') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (edit.enabled && +row.id === edit.idCell) {
            return <DropdownCell options={optionsLocation} prop="location" />
          }
          return <>{row.original.location}</>
        }
      }

      if (field.Header.toLowerCase() === 'payments') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (edit.enabled && +row.id === edit.idCell) {
            return <DropdownCellPay prop="stripe" options={optionsStripe} />
          }
          return <>{row.original.stripeName ? row.original.stripeName : '-'}</>
        }
      }

      if (field.Header.toLowerCase() === 'pixel') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (edit.enabled && +row.id === edit.idCell) {
            return <EditableCell prop="pixel_id" />
          }
          return <>{row.original.pixel_id ? row.original.pixel_id : '-'}</>
        }
      }

      if (field.Header.toLowerCase() === 'ga') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (edit.enabled && +row.id === edit.idCell) {
            return <EditableCell prop="google_analytics" />
          }
          return (
            <>
              {row.original.google_analytics
                ? row.original.google_analytics
                : '-'}
            </>
          )
        }
      }

      if (field.Accessor.toLowerCase() === 'funccolumn') {
        fieldObject['Cell'] = ({ row }, value) => {
          return (
            <Box d="flex" justifyContent="center">
              <AttractionsButtons
                row={row}
                attractionPermissionAccess={attractionPermissionAccess}
              />
            </Box>
          )
        }
      }
      return fieldObject
    })
  }, [edit])
  return (
    <>
      <TableCreator
        data={data}
        columns={columns}
        styles={{ ...tableStyles, ...createCustomTr() }}
        sizesMap={TABLE_COLUMNS_SIZES_ATTRACTIONS}
      />
      {data.length === 0 && (
        <Box textAlign="center" m="20px 0" fontSize="14px">
          No results were found for your search.
        </Box>
      )}
    </>
  )
}
