import {
  Button,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../assets/Icons/CrosshairIcon'
import { useDeletePromocode } from '../../../../../libs/hooks/usePromo'
import { PERMISSION_DISCOUNTS } from '../../../../../constants'
import PermissionRequired from '../../../../Common/PermissionRequired'

export default function DeletePromocodeModal({
  promocodeId,
  promocodeName = ''
}) {
  const discountsPermission = PermissionRequired(PERMISSION_DISCOUNTS, true)
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: deletePromo } = useDeletePromocode()
  function deleteHandler() {
    deletePromo(
      { id: promocodeId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['promo'])
          onClose()
        }
      }
    )
  }
  return (
    <>
      <IconButton
        aria-label="delete-gate"
        color="custom.gray.200"
        onClick={onOpen}
        variant="funcColumnIconButtons"
        ml="18px"
        isDisabled={!discountsPermission}
        icon={<CrosshairIcon w="inherit" h="inherit" />}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="34px">Delete promocode {promocodeName}?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter
            d="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="8px"
          >
            <Button w="100%" variant="lightBlue" onClick={onClose}>
              Cancel
            </Button>
            <Button w="100%" variant="red" onClick={deleteHandler}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
