import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import { friendlyTimeWithTZ } from '@/libs'

import ButtonBlue from '../../../common/ButtonBlue'
import ButtonGray from '../../../common/ButtonGray'
import { InputDatePickerPlaceholder } from '../../../common/InputDatePickerPlaceholder'
import PlaceholderInput from '../../../common/PlaceholderInput'

export default function NewMinor({
  setNewMode = null,
  id = null,
  editMode = false,
  name,
  birthdate,
  elIdx,
  setEditMode
}) {
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext()
  const selectedProfile = values?.selectedProfile
  const waiver = selectedProfile?.waiver
  const itsNewProfile = values.itsNewProfile
  const minorsFromWaiver = waiver?.minors
  const minorsFromSelectedProfile = selectedProfile?.minors
  const minorsArray = itsNewProfile
    ? minorsFromSelectedProfile
    : minorsFromWaiver
  const newMinorMode = values?.newMinorMode
  const [datePickerError, setDatePickerError] = useState(false)
  const [date, setDate] = useState('')
  const formikMinorsPath = itsNewProfile
    ? 'selectedProfile.minors'
    : 'selectedProfile.waiver.minors'
  const newMinorModeError =
    errors.hasOwnProperty('newMinorMode') &&
    touched.hasOwnProperty('newMinorMode') &&
    !!touched.newMinorMode
  const someMinorIsEditError =
    errors.hasOwnProperty('someMinorIsEdit') &&
    touched.hasOwnProperty('someMinorIsEdit') &&
    !!touched.someMinorIsEdit

  useEffect(() => {
    if (date === '' && editMode && Boolean(birthdate)) {
      const [year, month, day] = birthdate.split('-')
      setDate(new Date(+year, +month - 1, +day))
    }
  }, [editMode, date, birthdate])

  const initialStateNewMode = {
    name: '',
    birthdate: ''
  }

  const initialStateEditMode = {
    name,
    birthdate
  }

  const initialState = editMode ? initialStateEditMode : initialStateNewMode

  const [state, setState] = useState(initialState)

  // console.log('someMinorIsEditError', someMinorIsEditError);
  // console.log('newMode', newMinorMode );
  function onChangeDate() {
    return (val) => {
      setDate(val)
      setState({
        ...state,
        birthdate: friendlyTimeWithTZ(val, 'YYYY-MM-DD')
      })
    }
  }

  function saveHandler() {
    const newObj = { name: state.name, birthdate: state.birthdate }
    if (itsNewProfile) {
      const arr =
        minorsFromSelectedProfile && minorsFromSelectedProfile.length !== 0
          ? [...minorsFromSelectedProfile, newObj]
          : [newObj]
      setFieldValue('selectedProfile.minors', arr)
    } else {
      const arr =
        minorsFromWaiver && minorsFromWaiver.length !== 0
          ? [...minorsFromWaiver, newObj]
          : [newObj]
      setFieldValue('selectedProfile.waiver.minors', arr)
    }
    setDate('')
    setState(initialState)
    setNewMode(false, 'add')
  }

  function onChangeInput(e) {
    const val = e.target.value
    setState({
      ...state,
      name: val
    })
  }

  function saveEditHandler() {
    const newObj = { name: state.name, birthdate: state.birthdate }
    if (itsNewProfile) {
      let arr = [...minorsFromSelectedProfile]
      arr[elIdx] = newObj
      setFieldValue('selectedProfile.minors', arr)
    } else {
      let arr = [...minorsFromWaiver]
      arr[elIdx] = newObj
      setFieldValue('selectedProfile.waiver.minors', arr)
    }
    setState(initialState)
    setDate('')
    setEditMode(false)
  }

  function cancelHandler() {
    setState(initialState)
    setDate('')
    setEditMode(false)
  }

  function deleteHandler() {
    setState(initialState)
    setDate('')
    setNewMode(false)
  }

  const minDate = new Date(
    new Date().getUTCFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0
  )
  return (
    <div
      className={`waiver-widget--new-minor ${editMode ? 'edit-mode' : ''} ${
        someMinorIsEditError || newMinorModeError ? 'error' : ''
      }`}
      {...(!!id && { id })}
    >
      <PlaceholderInput
        placeholder="Full name"
        value={state.name}
        onChange={onChangeInput}
      />
      <DatePicker
        showYearDropdown={true}
        yearDropdownItemNumber={100}
        scrollableYearDropdown={true}
        showMonthDropdown={true}
        onChange={onChangeDate()}
        dateFormat="yyyy-MM-dd"
        selected={date}
        minDate={minDate}
        maxDate={new Date()}
        customInput={
          <InputDatePickerPlaceholder
            setExternalError={setDatePickerError}
            mask={'9999-99-99'}
          />
        }
      />
      {editMode ? (
        <div className="waiver-widget--buttons-container">
          <ButtonGray onClick={cancelHandler}>Cancel</ButtonGray>
          <ButtonBlue
            onClick={saveEditHandler}
            disabled={!state.name || !state.birthdate}
          >
            Save
          </ButtonBlue>
        </div>
      ) : (
        <>
          {minorsArray?.length !== 0 ? (
            <div className="waiver-widget--buttons-container">
              <ButtonGray
                className="button button-gray red"
                onClick={deleteHandler}
              >
                Cancel
              </ButtonGray>
              <ButtonBlue
                onClick={saveHandler}
                disabled={!state.name || !state.birthdate}
              >
                Save new minor
              </ButtonBlue>
            </div>
          ) : (
            <ButtonBlue
              onClick={saveHandler}
              disabled={!state.name || !state.birthdate}
            >
              Save new minor
            </ButtonBlue>
          )}
        </>
      )}
    </div>
  )
}
