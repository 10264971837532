import {
  Box,
  Button,
  Link,
  useMediaQuery,
  Text,
  useTheme
} from '@chakra-ui/react'
import { createContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PlusIcon } from '@/assets/Icons/PlusIcon'
import PermissionRequired from '@/components/Common/PermissionRequired'
import Preloader from '@/components/Common/Preloader'
import { PERMISSION_EVENT } from '@/constants'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import TableCreator from '@/components/Common/TableCreator'
import EditableCell from '@/components/Settings/Tabs/Attractions/AttractionsTable/EditableCell'
import { TABLE_COLUMN_VALUES_COMBO } from '@/configs'
import { ComboEndpoint } from '@/api/combo'
import DeleteComboModal from './DeleteComboModal'

export const AttractionContext = createContext(null)

const comboAPI = ComboEndpoint()

export default function ComboPackages() {
  const queryClient = useQueryClient()
  const history = useHistory()
  const attractionPermissionAccess = PermissionRequired(PERMISSION_EVENT, true)
  const isLargerThan768 = useMediaQuery('(min-width: 768px)')
  const [edit, setEdit] = useState({ enabled: false, idCell: null })

  const { data: comboData = [], isLoading } = useQuery({
    queryKey: ['combo'],
    queryFn(s) {
      return comboAPI.getList()
    }
  })

  const { mutate: deleteSingleCombo } = useMutation({
    mutationFn: comboAPI.deleteSingle,
    onSuccess() {
      queryClient.invalidateQueries(['combo'])
    }
  })

  const theme = useTheme()
  const customsColor = theme.colors.custom
  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 8px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  function createCustomTr() {
    const main = {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      h: '56px',
      bg: 'white'
    }

    if (typeof edit.idCell === 'number') {
      return {
        tr: {
          ...main,
          css: {
            [`tbody > &:nth-of-type(${edit.idCell + 1})`]: {
              background: customsColor.blue[300]
            }
          }
        }
      }
    } else {
      return { tr: { ...main } }
    }
  }

  // const customColumnHeader = (
  //   <Button
  //     variant='unstyled'
  //     color={edit.enabled ? 'custom.gray.200' : 'custom.blue.600'}
  //     fontSize='inherit'
  //     fontWeight='500'
  //     display='inline-flex'
  //     alignItems='center'
  //     _focus={{ boxShadow: 'none' }}
  //     disabled={edit.enabled || !attractionPermissionAccess}
  //     // onClick={() => attractionDispatch(setNewMode(true))}
  //   >
  //     <PlusIcon color='inherit' mr='8px' />
  //     New
  //   </Button>
  // );

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_COMBO.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'title') {
        fieldObject['Cell'] = ({ row }, value) => {
          // if (edit.enabled && +row.id === edit.idCell) {
          //   return <EditableCell prop='title' isRequired={true} />;
          // }
          return (
            <Link to={`settings/combo/${row.original.id}`}>
              <Text
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                color="custom.blue.600"
                textDecor="underline"
              >
                {row.original.title}
              </Text>
            </Link>
          )
        }
      }

      if (field.Accessor.toLowerCase() === 'funccolumn') {
        fieldObject['Cell'] = ({ row }, value) => {
          return (
            <Box d="flex" justifyContent="center">
              <Button
                aria-label="edit-gate"
                color="custom.blue.600"
                variant="link"
                mr="12px"
                isDisabled={
                  (edit.enabled && +row.id !== edit.idCell) ||
                  !attractionPermissionAccess
                }
                onClick={() =>
                  history.push('/settings/combo/' + row.original.id)
                }
              >
                Edit
              </Button>
              <DeleteComboModal
                onDelete={() => deleteSingleCombo(row.original.id)}
                disabled={!attractionPermissionAccess}
              />
            </Box>
          )
        }
      }
      return fieldObject
    })
  }, [comboData])

  return (
    <Box>
      <Button
        variant="blue"
        fontWeight="500"
        fontSize={{ base: '12px', '2xl': '14px' }}
        mb={{ base: '16px' }}
        display={{ base: 'inline-flex' }}
        onClick={() => history.push('/settings/combo/new')}
        isDisabled={!attractionPermissionAccess}
      >
        <PlusIcon mr="8px" color={{ md: 'custom.blue.600', '2xl': 'white' }} />
        New Combo
      </Button>

      {isLoading ? (
        <Preloader />
      ) : (
        <TableCreator
          data={comboData}
          columns={columns}
          styles={{ ...tableStyles, ...createCustomTr() }}
          sizesMap={TABLE_COLUMN_VALUES_COMBO}
        />
      )}
    </Box>
  )
}
