import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { loadStripe } from '@stripe/stripe-js'
import { FormikProvider, useFormik } from 'formik'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { friendlyTime } from '../../../../libs'
import { useAddPlayers } from '../../../../libs/hooks/useAddPlayers'
import { useBooking } from '../../../../libs/hooks/useBooking'
import { useExperiences } from '../../../../libs/hooks/useExperiences'
import { usePlayersPrice } from '../../../../libs/hooks/usePlayersPrice'
import { addPlayersValidation } from '../../../../configs/validation/addPlayers'
import { BookingContext } from '../../../../pages/Booking'
import CustomWarningToast from '../../../Common/CustomWarningToast'
import DataRow from '../../../Common/DataRow'
import FormikPayments from '../../../Common/FormikComponents/FormikPayments'
import FormikPlayersInput from '../../../Common/FormikComponents/FormikPlayersInput'
import FormikTwoStyledInput from '../../../Common/FormikComponents/FormikTwoStyledInput'
import Preloader from '../../../Common/Preloader'

export default function AddPlayersModal({ players }) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const playersToastError = {
    id: 'players-toast-error',
    text: 'Something went wrong.'
  }
  const [_, _isLoading, filters, setFilters, queryKeysBooking] =
    useContext(BookingContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id: bookingId } = useParams()
  const { data: bookingData = {} } = useBooking(
    bookingId,
    {},
    {
      enabled: isOpen,
      select: (d) => {
        const bookingStartHandler = d?.start_time
        const bookingStart =
          bookingStartHandler[bookingStartHandler?.length - 1] === 'Z'
            ? bookingStartHandler
            : bookingStartHandler + 'Z'
        return {
          ...d,
          start_time: bookingStart
        }
      }
    }
  )
  const { data: { results: experience = [] } = {} } = useExperiences()
  const experienceId = bookingData?.eventvariant?.id || ''
  const experienceForThisBooking = useMemo(() => {
    if (isOpen && experienceId && Object.keys(bookingData)?.length !== 0) {
      for (let i = 0; i < experience.length; i++) {
        if (experienceId === experience[i].id) {
          return experience[i]
        }
      }
  } else return {}
  }, [experienceId, bookingData, isOpen])

  const bookingLocation = experienceForThisBooking?.event?.location
  const { mutate: addPlayers, isLoading } = useAddPlayers({ retry: false })
  const [stripePromise, setStripePromise] = useState('')
  const canAddMorePlayers =
    players < experienceForThisBooking.max_players || false

  const formik = useFormik({
    initialValues: {
      playersCount: experienceForThisBooking?.min_players,
      sum: '',
      fee: '',
      sumWithFee: '',
      feeInPercent: experienceForThisBooking?.event?.fee,
      payData: {
        type: 'online',
        offlineCard: '',
        transaction: '',
        stripeToken: '',
        location: null
      }
    },
    enableReinitialize: true,
    onSubmit: onSubmit,
    validationSchema: addPlayersValidation
  })

  const formikHandleSubmit = formik.handleSubmit
  const formikSetFieldValue = formik.setFieldValue
  const formikValues = formik.values
  const startTime = bookingData?.start_time
  const locationName = bookingData?.event?.location?.name
  const eventvariantName = bookingData?.eventvariant?.name
  const feeInPercent = formikValues.feeInPercent
  const paymentMethod = formikValues.payData.type
  function generateWarningToast({ text, id, incomingText, msgObjects = {} }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 2000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={incomingText ? incomingText : text}
              msgObjects={msgObjects}
              close={toast.closeAll}
            />
          )
        })
      }
    }
  }

  const { refetch: refetchPlayersPrice, isLoading: playerPricesLoading } = usePlayersPrice(
    +bookingId,
    formikValues.playersCount,
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        const price = data?.price
        if (price || typeof price === 'number') {
          if (price === 0) {
            formikSetFieldValue('sum', 'Free')
          } else {
            formikSetFieldValue('sum', data?.price / 100)
          }
        }
      },
      onError: (err) => {
        const status = err?.response?.status
        formikSetFieldValue('sum', '—')
        const msg = err?.response?.data?.msg
        const msgObjects = err?.response?.data
        if (status !== 500) {
          generateWarningToast({
            ...playersToastError,
            incomingText: msg,
            msgObjects
          })()
        } else {
          generateWarningToast({
            ...playersToastError,
            incomingText: 'Server error 500'
          })()
        }
      }
    }
  )

  useEffect(() => {
    if (
      (paymentMethod === 'stripe' || paymentMethod === 'offline') &&
      formikValues.sum !== 'Free' &&
      formikValues.sum !== '—'
    ) {
      const fee = (formikValues.sum * formikValues.feeInPercent) / 100
      const price = formikValues.sum
      const sumWithFee =
        typeof (price + fee) === 'number' ? (price + fee)?.toFixed(2) : 0
      const toFixedFee = typeof fee === 'number' ? fee?.toFixed(2) : 0
      formikSetFieldValue('sumWithFee', sumWithFee)
      formikSetFieldValue('fee', toFixedFee)
    } else {
      if (formikValues.sum === 'Free' || formikValues.sum === '—') {
        formikSetFieldValue('sumWithFee', 0)
        formikSetFieldValue('fee', 0)
      } else {
        formikSetFieldValue('sumWithFee', formikValues.sum)
        formikSetFieldValue('fee', 0)
      }
    }
  }, [formikValues.sum, formikValues.payData.type, formikValues.feeInPercent])

  const data = [
    { key: 'Game', value: friendlyTime(startTime, 'MM.DD.YY') },
    { key: 'Start time', value: friendlyTime(startTime, 'h:mm A') },
    { key: 'Location', value: locationName },
    { key: 'Experience', value: eventvariantName },
    { key: 'Players', value: players },
    {
      key: 'Max players for experience',
      value: experienceForThisBooking?.max_players
    },
    {
      key: 'Min players for experience',
      value: experienceForThisBooking?.min_players
    }
  ]

  useEffect(() => {
    if (isOpen && canAddMorePlayers) {
      refetchPlayersPrice()
    }
  }, [isOpen, canAddMorePlayers])

  useEffect(() => {
    if (isOpen && canAddMorePlayers) {
      refetchPlayersPrice()
    }
  }, [formikValues.playersCount, canAddMorePlayers])

  function onSubmit(values) {
    const toSend = {
      bookingId: +bookingId,
      playersAmount: +values.playersCount,
      paymentType: values.payData.type,
      payments: {
        ...(values.payData.type === 'offline' && {
          cash_payment_id: values.payData.transaction
        }),
        ...(values.payData.type === 'stripe' && {
          payment_method: values.payData.stripeToken
        }),
        ...(values.payData.type === 'cash' && {
          location_id: values.payData.location.value
        })
      }
    }
    addPlayers(
      { obj: toSend },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeysBooking)
          onClose()
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          generateWarningToast({ ...playersToastError, incomingText: msg })()
        }
      }
    )
  }

  useEffect(() => {
    async function fetchStripeObject() {
      if (
        experienceId &&
        Object?.keys(experienceForThisBooking)?.length !== 0
      ) {
        const stripePromiseLoaded = await loadStripe(
          experienceForThisBooking.stripe_pub
        )
        setStripePromise('')
        setStripePromise(stripePromiseLoaded)
      }
    }

    fetchStripeObject()
  }, [experienceForThisBooking])

  return (
    <>
      <Button onClick={onOpen} variant="lightBlue">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          Add players
        </Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent maxW={{ base: '288px', md: '348px', '2xl': '427px' }}>
          <ModalHeader>Add players</ModalHeader>
          <ModalCloseButton as={CrosshairIcon} />
          <ModalBody>
            <Box
              mb={{ base: '16px', '2xl': '24px' }}
              d="flex"
              flexDir={{ base: 'column', md: 'row' }}
              flexWrap="wrap"
            >
              {data.map((dataRow, idx) => (
                <DataRow
                  title={dataRow.key}
                  key={'modalAdd' + idx}
                  value={dataRow.value}
                  w={{ base: '100%', md: 'unset' }}
                  mb={{ base: '4px', '2xl': '8px' }}
                  keyStyle={{
                    mr: { base: 'unset', md: '8px' }
                  }}
                  valueStyle={{
                    mr: { base: 'unset', md: '16px' }
                  }}
                />
              ))}
            </Box>
            {canAddMorePlayers ? (
              <FormikProvider value={formik}>
                <>
                  <Box mb={{ base: '8px', md: '16px' }}>
                    <FormikPlayersInput
                      name="playersCount"
                      h="52px"
                      minValue={1}
                      maxValue={
                        experienceForThisBooking?.max_players -
                        bookingData?.players
                      }
                    />
                    <Box
                      d="grid"
                      mb={{ base: '8px', md: '16px' }}
                      gridTemplateColumns={{
                        base: '2fr 1fr',
                        '2xl': '1fr 1fr'
                      }}
                      gridGap="8px"
                      alignItems="center"
                    >
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        d={{ '2xl': 'none' }}
                      >
                        Fee {`${feeInPercent}% `}($):
                      </Text>
                      <FormikTwoStyledInput
                        name="fee"
                        placeholder={`Fee ${feeInPercent}% ($)`}
                        isDisabled={true}
                        input={{
                          textAlign: { base: 'center', '2xl': 'start' }
                        }}
                      />
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        d={{ '2xl': 'none' }}
                      >
                        Sum ($):
                      </Text>
                      <FormikTwoStyledInput
                        name="sumWithFee"
                        placeholder="Sum ($)"
                        isDisabled={true}
                        input={{
                          textAlign: { base: 'center', '2xl': 'start' }
                        }}
                      />
                    </Box>
                  </Box>
                  {!playerPricesLoading && (
                    <>
                      <Box mb={{ base: '16px' }}>
                        <FormikPayments
                          name="payData.type"
                          id="payData.type"
                          stripePromise={stripePromise}
                          isLoading={isLoading}
                          experience={experienceForThisBooking}
                          preselectedLocation={bookingLocation}
                        />
                        {formikValues.payData.type === 'stripe' && (
                          <Button
                            variant="lightBlue"
                            onClick={onClose}
                            w="100%"
                            fontWeight="500"
                            fontSize={{ base: '12px', '2xl': '14px' }}
                          >
                            Close
                          </Button>
                        )}
                      </Box>
                      {formikValues.payData.type !== 'stripe' && (
                        <Box
                          d="grid"
                          gridTemplateColumns="1fr 1fr"
                          gridGap="8px"
                        >
                          <Button
                            variant="lightBlue"
                            onClick={onClose}
                            w="100%"
                            fontWeight="500"
                            fontSize={{ base: '12px', '2xl': '14px' }}
                          >
                            Close
                          </Button>
                          {isLoading ? (
                            <Preloader m="0" h="100%" />
                          ) : (
                            <Button
                              variant="blue"
                              w="100%"
                              fontWeight="500"
                              fontSize={{ base: '12px', '2xl': '14px' }}
                              onClick={formikHandleSubmit}
                            >
                              Pay
                            </Button>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </>
              </FormikProvider>
            ) : (
              <Text
                color="custom.gray.900"
                fontSize={{ base: '12px', md: '14px' }}
              >
                The maximum number of players has been reached
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
