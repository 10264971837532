import StatisticsPartnerItem from '../StatisticsPartnerItem'

export default function StatisticsPartnersList({ data = [] }) {
  return data?.map((partner, idx) => (
    <StatisticsPartnerItem
      partner={partner}
      key={'StatisticsPartnersList' + idx}
    />
  ))
}
