import { Box, Heading } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import FormikCheckbox from '../../common/FormikComponents/FormikCheckbox'

export default function TextBlocks({ block }) {
  const header = block?.title || ''
  const description = block?.description || ''
  const checkouts = block?.checkouts || []
  const {
    values: { requiredCheckboxes = {} },
    errors: { requiredCheckboxes: requiredCheckboxesErrors = {} } = {},
    touched: { requiredCheckboxes: requiredCheckboxesTouched = {} } = {}
  } = useFormikContext()
  function isError(id) {
    return (
      requiredCheckboxesTouched?.hasOwnProperty(id) &&
      requiredCheckboxesErrors?.hasOwnProperty(id)
    )
  }

  const notEmptyRequiredCheckboxes =
    Object.keys(requiredCheckboxes)?.length !== 0

  return (
    <Box className="waiver-widget--text-block" textAlign={'left'}>
      <Heading as={'h4'} size='md' mb={2} textAlign={'center'}>{header}</Heading>
      <div
        className="waiver-widget--text"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {notEmptyRequiredCheckboxes &&
        checkouts.length !== 0 &&
        checkouts?.map((checkout, idx) => {
          return (
            <FormikCheckbox
              key={'checkbox' + header + idx}
              name={'requiredCheckboxes.checkout' + checkout.id}
              id={'checkout' + checkout.id}
            >
              {checkout.text}
            </FormikCheckbox>
          )
        })}
         </Box>
  )
}
