import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { linksCreator } from '../../../../libs'

export default function BookingsModal({ title, value }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        variant="link"
        _focus={{ border: 'none' }}
        _hover={{ bg: 'transparent' }}
        fontSize={{ base: '12px', '2xl': '14px' }}
        mr="8px"
        onClick={onOpen}
      >
        {title}:
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="none">
          <ModalHeader fontSize="24px" fontFamily="inherit">
            Bookings
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} d="flex" flexWrap="wrap">
            {linksCreator(value)}
          </ModalBody>
          <ModalFooter>
            <Button variant="red" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
