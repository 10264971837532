import { Box, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Preloader from '../../../../Common/Preloader'
import { useQueryClient } from 'react-query'
import LocationTemplatesElement from './LocationTemplatesElement'
import {
  useApplyEmailBaseTemplate,
  useApplyEmailBaseTemplateChanges,
  useEmailBaseTemplatesLocations
} from '../../../../../libs/hooks/useEmailBaseTemplates'

export default function LocationTemplatesBlock({
  templatesOptions,
  isExpanded
}) {
  const queryClient = useQueryClient()

  const { data: emailBaseTemplatesLocations = [], isLoadingTemplateLocations } =
    useEmailBaseTemplatesLocations({
      enabled: isExpanded,
      refetchOnWindowFocus: false
    })
  const { mutate: applyTemplateChanges, isLoadingApplyChanges } =
    useApplyEmailBaseTemplateChanges()
  const { mutate: applyTemplate, isLoadingApply } = useApplyEmailBaseTemplate()
  const isLoading =
    isLoadingTemplateLocations || isLoadingApply || isLoadingApplyChanges
  const [editMode, setEditMode] = useState({
    idx: null,
    enabled: false
  })

  function applyChangesHandler(templateId, locationId) {
    const toSend = {
      templateId,
      locationId
    }
    applyTemplateChanges(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(['emailBaseTemplatesLocations'])
            .then(() => {
              setEditMode({
                idx: null,
                enabled: false
              })
            })
        }
      }
    )
  }

  function applyTemplateHandler(templateId, locationId) {
    const toSend = {
      templateId,
      locationId
    }
    applyTemplate(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(['emailBaseTemplatesLocations'])
            .then(() => {
              setEditMode({
                idx: null,
                enabled: false
              })
            })
        }
      }
    )
  }

  return isLoading ? (
    <Box p="16px" bg="white">
      <Preloader />
    </Box>
  ) : (
    <>
      <Box
        d={{ base: 'none', md: 'grid' }}
        gridTemplateColumns={{ base: 'unset', md: '2fr 0.3fr 1fr' }}
        bg="white"
        mb="2px"
        p={{ base: '16px', '2xl': '16px 24px' }}
        color="custom.black.500"
        fontSize={{ base: '12px', '2xl': '14px' }}
      >
        <Text alignSelf="center">Location</Text>
        <Text justifySelf="center" alignSelf="center" gridColumn="3/4">
          Template
        </Text>
      </Box>
      {emailBaseTemplatesLocations.length !== 0 &&
        emailBaseTemplatesLocations
          .sort(function (a, b) {
            return a.location.id - b.location.id
          })
          .map((loc, idx) => (
            <LocationTemplatesElement
              templatesOptions={templatesOptions}
              loc={loc}
              isLoading={isLoading}
              applyChanges={applyChangesHandler}
              applyTemplate={applyTemplateHandler}
              idx={idx}
              editMode={editMode}
              setEditMode={setEditMode}
              key={idx + 'emailBaseTemplatesLocations'}
            />
          ))}
    </>
  )
}
