import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { getBooking } from '../../api/booking'

export function useBooking(bookingId, filters = {}, queryOption = {}) {
  const queryKeys = useMemo(() => {
    const array = ['booking', bookingId]
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        array.push(`${key}:${filters[key]}`)
      }
    })
    return array
  }, [filters])

  return useQuery(queryKeys, () => getBooking(bookingId, filters), {
    ...queryOption
  })
}
