import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { rootReducer } from '../reducers'

const persistConfig = {
  key: 'gbase',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = composeWithDevTools({
  realtime: true,
  port: 8000,
  trace: true,
  traceLimit: 25
})

export const store = createStore(persistedReducer, composeEnhancers())
export const persistor = persistStore(store)
