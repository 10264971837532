import { api } from '../index'

export async function getLimitations(weekday) {
  try {
    const { data } = await api.get('/api/locationlimitations/', {
      params: {
        weekday
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getLimitation(limitationId) {
  try {
    const { data } = await api.get(`/api/locationlimitations/${limitationId}`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function patchLimitation({
  limitationId,
  weekday,
  peoples = 100,
  locationId,
  startTime,
  endTime
}) {
  try {
    const { data } = await api.patch(
      `/api/locationlimitations/${limitationId}/`,
      {
        time_start: startTime,
        time_end: endTime,
        peoples,
        weekday,
        location: locationId
      }
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function newLimitation({
  weekday,
  peoples = 100,
  locationId,
  startTime,
  endTime
}) {
  try {
    const { data } = await api.post(`/api/locationlimitations/`, {
      time_start: startTime,
      time_end: endTime,
      peoples,
      weekday,
      location: locationId
    })
    return data
  } catch (e) {
    console.log(e)
  }
}
export async function deleteLimitation({ limitationId }) {
  try {
    const { data } = await api.delete(
      `/api/locationlimitations/${limitationId}`
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getDowntimes() {
  const { data } = await api.get('/api/downtimes/')
  return data
}

export async function getDowntime(downtimeId) {
  const { data } = await api.get(`/api/downtimes/${downtimeId}`)
  return data
}
export async function deleteDowntime(downtimeId) {
  const { data } = await api.delete(`/api/downtimes/${downtimeId}`)
  return data
}

export async function updateDowntime({
  downtimeId,
  effectiveDateFrom,
  effectiveDateTo,
  applyTo
}) {
  const { data } = await api.patch(`/api/downtimes/${downtimeId}/`, {
    effective_date_from: effectiveDateFrom,
    effective_date_to: effectiveDateTo,
    apply_to: applyTo
  })
  return data
}

export async function newDowntime({
  effectiveDateFrom,
  effectiveDateTo,
  applyTo
}) {
  const { data } = await api.post('/api/downtimes/', {
    effective_date_from: effectiveDateFrom,
    effective_date_to: effectiveDateTo,
    apply_to: applyTo
  })
  return data
}
