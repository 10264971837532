import {
  Button,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { PERMISSION_LOCATIONS } from '../../../../../../constants'
import PermissionRequired from '../../../../../Common/PermissionRequired'

export default function AcceptionDeleteModal({
  functionForDelete,
  text,
  isDisabled = false,
  type,
  icon
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const locationsPermission = PermissionRequired(PERMISSION_LOCATIONS, true)

  function deleteHandler() {
    if (functionForDelete !== null && typeof functionForDelete === 'function') {
      functionForDelete(onClose)
    }
  }

  return (
    <>
      {icon ? (
        <IconButton
          aria-label="delete-waiver-waiverblock-waivercheckout"
          color="custom.gray.200"
          variant="funcColumnIconButtons"
          onClick={onOpen}
          isDisabled={isDisabled}
          icon={<CrosshairIcon w="inherit" h="inherit" />}
        />
      ) : (
        <Button variant="red" onClick={onOpen} isDisabled={isDisabled}>
          <CrosshairIcon mr="8px" />
          Delete
        </Button>
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="34px">
            Delete {type} {text}?
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter
            d="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="8px"
          >
            <Button w="100%" variant="lightBlue" onClick={onClose}>
              Cancel
            </Button>
            <Button w="100%" variant="red" onClick={deleteHandler}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
