import * as yup from 'yup'

import { cleanPhoneNumber } from '../../../libs'

export const newGiftcardModalValidation = yup.object().shape({
  recipientName: yup.string().required(),
  recipientEmail: yup.string().email().required(),
  recipientPhone: yup
    .string()
    .required()
    .test('phone length', 'must be 11 charters length', (val) => {
      if (val && val.length) {
        const value = cleanPhoneNumber(val)
        return /\d{10}/.test(value)
      } else {
        return false
      }
    }),
  buyerName: yup.string().required(),
  buyerEmail: yup.string().email().required(),
  buyerPhone: yup
    .string()
    .required()
    .test('phone length', 'must be 11 charters length', (val) => {
      if (val && val.length) {
        const value = cleanPhoneNumber(val)
        return /\d{10}/.test(value)
      } else return false
    }),
  cardValue: yup
    .number()
    .integer()
    .positive()
    .min(yup.ref('minGiftCardValue'))
    .max(yup.ref('maxGiftCardValue'))
    .required(),
  personalMessage: yup.string().when('addPersonalMessage', {
    is: true,
    then: yup.string().required()
  }),
  giftcardDesign: yup.number().required(),
  payData: yup.object().shape({
    type: yup.string().required(),
    transaction: yup.string().when('type', {
      is: 'offline',
      then: yup.string().required()
    }),
    location: yup.object().when('type', {
      is: 'cash',
      then: yup.object().required()
    })
  })
})
