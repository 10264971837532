import { Box, Input, InputGroup, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { matchMedia1440InState, matchMedia768InState } from '../../../constants'
import InputMask from 'react-input-mask'

export default function TwoStyledInputDatePicker({
  value = '',
  placeholder = 'Birthdate',
  isDisabled = false,
  onBlur,
  onClick,
  onFocus,
  onChange,
  isError,
  innerRef,
  mask = '99.99.9999',
  containerRef,
  bg = '',
  ...rest
}) {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)

  function inputVariant() {
    return isLargerThan768 ? 'gbase' : 'gbase2'
  }

  return (
    <Box ref={containerRef}>
      {isLargerThan1440 ? (
        <InputGroup>
          <Text
            pos="absolute"
            zIndex="1"
            top="8px"
            left="15px"
            fontSize="10px"
            color="custom.gray.300"
          >
            {placeholder}
          </Text>
          <Input
            variant="gbase"
            as={InputMask}
            mask={mask}
            h="56px"
            p="18px 14px 0px"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onClick={onClick}
            ref={innerRef}
            isDisabled={isDisabled}
            bg={
              bg
                ? isError
                  ? 'custom.red.20'
                  : bg
                : isError
                ? 'custom.red.20'
                : 'custom.lightGray'
            }
          />
        </InputGroup>
      ) : isLargerThan768 ? (
        <Input
          variant={inputVariant()}
          as={InputMask}
          mask={mask}
          placeholder={placeholder}
          isDisabled={isDisabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          ref={innerRef}
          onClick={onClick}
          onFocus={onFocus}
          bg={
            bg
              ? isError
                ? 'custom.red.20'
                : bg
              : isError
              ? 'custom.red.20'
              : 'custom.lightGray'
          }
          fontSize={{ base: '12px', '2xl': '14px' }}
        />
      ) : (
        <Input
          variant={inputVariant()}
          as={InputMask}
          mask={mask}
          placeholder={placeholder}
          isDisabled={isDisabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          ref={innerRef}
          onClick={onClick}
          onFocus={onFocus}
          mb="16px"
          bg={
            bg
              ? isError
                ? 'custom.red.20'
                : bg
              : isError
              ? 'custom.red.20'
              : 'custom.white'
          }
          fontSize={{ base: '12px', '2xl': '14px' }}
          {...rest}
        />
      )}
    </Box>
  )
}
