import { Badge } from '@chakra-ui/react'
import { uppercaseFirstLetter } from '../../../libs'

export default function GBaseTag({ label = '', color = null, ...rest }) {
  return (
    <Badge
      variant={color ? color : label?.toLowerCase()}
      textTransform="none"
      d="flex"
      justifyContent="center"
      alignItems="center"
      w="fit-content"
      m={{ md: '0 auto', '2xl': 'unset' }}
      height={{ base: '24px', md: '28px', '2xl': '32px' }}
      fontSize={{ base: '12px', '2xl': '14px' }}
      lineHeight="24px"
      p={{ base: '0 4px', '2xl': '0 8px' }}
      {...rest}
    >
      {uppercaseFirstLetter(label.toLowerCase())}
    </Badge>
  )
}
