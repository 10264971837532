import { FormikProvider, useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'

import '../../styles/index.scss'
import '../../styles/WaiverWidget/index.scss'
import '../../styles/Buttons/index.scss'

import {
  getWaiverWidgetByBooking,
  getWaiverWidgetEntity,
  waiverWidgetCreateEntity,
  waiverWidgetUpdateEntity
} from '../../api'
import ButtonBlue from '../../components/common/ButtonBlue'
import Logo from '../../components/common/Logo'
import { useNotification } from '../../components/common/NotificationService'
import Signature from '../../components/common/Signature'
import SingingWaiver from '../../components/common/SingingWaiver'
import AddMinor from '../../components/WaiverWidget/AddMinor'
import BookingResult from '../../components/WaiverWidget/BookingResult'
import CreateProfile from '../../components/WaiverWidget/CreateProfile'
import EnterBookingNumber from '../../components/WaiverWidget/EnterBookingNumber'
import EnterPhoneNumber from '../../components/WaiverWidget/EnterPhoneNumber'
import Footer from '../../components/WaiverWidget/Footer'
import FuturePlayersCounter from '../../components/WaiverWidget/FuturePlayersCounter'
import CompleteWaiverModal from '../../components/WaiverWidget/Modals/CompleteWaiverModal'
import MinorsErrorModal from '../../components/WaiverWidget/Modals/MinorsErrorModal'
import ParticipantsInformation from '../../components/WaiverWidget/ParticipantsInformation'
import ScrollHelper from '../../components/WaiverWidget/ScrollHelper'
import SelectProfile from '../../components/WaiverWidget/SelectProfile'
import TextBlocks from '../../components/WaiverWidget/TextBlocks'
import { waiverWidgetValidation } from '../../configs/validations/waiverWidgetValidation'

export default function WaiverWidget() {
  const scrollRef = useRef(null)
  const [isOpenSuccess, setIsOpenSuccess] = useState(false)

  function onOpenSuccess() {
    setIsOpenSuccess(true)
  }

  function onCloseSuccess() {
    setIsOpenSuccess(false)
  }

  const toast = useNotification()
  const formik = useFormik({
    validationSchema: waiverWidgetValidation,
    initialValues: {
      unassigned: null,
      bookingRefetchingTimerIsRun: false,
      bookingRefetchFunc: null,
      bookingCode: '',
      bookingWasLoaded: false,
      loadedBooking: {},
      phoneNumber: '',
      exists: null,
      newMinorMode: false,
      phoneNumberMasked: '',
      phoneNumberAccepted: false,
      isReplaceProfileMode: false,
      originalProfile: null,
      profiles: [],
      technicalNumber: false,
      waiverData: null,
      signedFor: null,
      someMinorIsEdit: false,
      futurePlayers: null,
      emptyMinors: true,
      timezone: null,
      createdProfile: {
        firstname: '',
        email: '',
        lastname: '',
        birthdate: ''
      },
      smsCode: '',
      requiredCheckboxes: {},
      itsNewProfile: false,
      selectedProfile: null,
      createMode: false,
      signaturePhoto: null
    },
    onSubmit: (values) => handleSubmit(values)
  })

  async function handleSubmit(values) {
    setFieldValue('bookingRefetchingTimerIsRun', false)

    const {
      unassigned,
      loadedBooking: { unlimited_signs }
    } = values
    const bookingCode = localStorage.getItem('bookingCode')

    if (!bookingCode) {
      return toast({
        title: 'Something went wrong',
        message: 'Please reload page and try again.'
      })
    }

    if (unassigned || unlimited_signs) {
      return complete(values)
    }

    try {
      const waiver = await getWaiverWidgetByBooking(bookingCode)

      const {
        bookingRefetchFunc,
        futurePlayers,
        loadedBooking: { players: initialBookingPlayers }
      } = values

      const { players: bookingPlayers, max_players: maxBookingPlayers } = waiver
      const newPlayers = futurePlayers - initialBookingPlayers

      if (bookingPlayers + newPlayers <= maxBookingPlayers) {
        return complete(values)
      } else {
        bookingRefetchFunc()
        setTimeout(
          () =>
            scrollRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            }),
          500
        )
        const message =
          'The number of players has changed. Please fill out the form again.'
        toast({
          title: 'Please fill out the form again',
          message
        })
      }
    } catch (error) {
      console.error('Error happened at getWaiverWidgetByBooking', error)
    }
  }

  async function complete(values) {
    console.log('complete')
    if (values.itsNewProfile) {
      console.log('complete its new')
      const toSend = {
        signedFor: values.signedFor,
        sign: values.signaturePhoto,
        minors:
          values.signedFor === 'M'
            ? '[]'
            : JSON.stringify(values.selectedProfile.minors),
        notif: true,
        phoneNumber: values.phoneNumber,
        smsCode: values.smsCode,
        bookingId: values.loadedBooking.id,
        waiverId: values.loadedBooking.waiver_id,
        requireAuth: values.waiverData.require_authentication,
        player: {
          firstname: values.selectedProfile.firstname,
          lastname: values.selectedProfile.lastname,
          email: values.selectedProfile.email,
          birthdate: values.selectedProfile.birthdate
        }
      }
      await waiverWidgetCreateEntity(toSend, {
        onSuccess: () => {
          console.log('waiverWidgetCreateEntity on success')
          onOpenSuccess()
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const playerEmailError = err?.response?.data?.player?.email[0]
          toast({
            title: 'Error',
            message: playerEmailError || msg
          })
        }
      })
    } else {
      const selectedProfile = values.selectedProfile
      const toSend = {
        signedFor: selectedProfile.waiver.signed_for,
        ...(values.signaturePhoto && { sign: values.signaturePhoto }),
        notif: true,
        minors:
          selectedProfile.waiver.signed_for === 'M'
            ? '[]'
            : JSON.stringify(selectedProfile.waiver.minors),
        player: {
          firstname: selectedProfile.firstname,
          lastname: selectedProfile.lastname,
          email: selectedProfile.email,
          birthdate: selectedProfile.birthdate
        },
        requireAuth: values.waiverData.require_authentication,
        waiverWidgetEntityId: selectedProfile.waiver.id,
        phoneNumber: values.phoneNumber,
        smsCode: values.smsCode,
        bookingId: values.loadedBooking.id,
        waiverId: values.selectedProfile.waiver.waiver_id
      }
      await waiverWidgetUpdateEntity(toSend, {
        onSuccess: () => {
          onOpenSuccess()
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          toast({
            title: 'Error',
            msgObjects,
            message: msg
          })
        }
      })
    }
  }

  // const {data: {results: locations = []} = {}} = useAllLocations();
  const setFieldValue = formik?.setFieldValue
  const bookingWasLoaded = formik?.values?.bookingWasLoaded
  const phoneNumberAccepted = formik?.values?.phoneNumberAccepted
  const createMode = formik?.values?.createMode
  const selectedProfile = formik?.values?.selectedProfile
  const loadedBooking = formik?.values?.loadedBooking
  const waiverId = loadedBooking?.waiver_id
  const waiverData = formik?.values?.waiverData
  const waiverBlocks = waiverData?.blocks
  const requireAuth = formik.values?.waiverData?.require_authentication
  const futurePlayers = formik.values.futurePlayers
  const signedForFromWaiver = selectedProfile?.waiver?.signed_for
  const signedFor = formik.values.signedFor
  const singedForHandler = signedForFromWaiver ? signedForFromWaiver : signedFor
  const exists = formik.values.exists
  const canAddMore = +loadedBooking.max_players > +futurePlayers
  const itsNewProfile = formik.values.itsNewProfile
  const players = formik.values.loadedBooking?.players
  const maxPlayers = formik.values.loadedBooking?.max_players
  const emptyMinors = formik.values.emptyMinors
  const unassigned = waiverData?.unassigned
  const unlimitedSigns = loadedBooking?.unlimited_signs
  const technicalNumber = formik.values.technicalNumber
  const initialOverflowPlayers = players >= maxPlayers
  const showFormHandler = requireAuth
    ? exists === null || exists || (!exists && maxPlayers - players >= 1)
    : !initialOverflowPlayers
  const showForm = unlimitedSigns || unassigned || showFormHandler
  const showStartOverButton =
    selectedProfile !== null && showForm && bookingWasLoaded
  const bookingRefetchingTimerIsRun = formik.values.bookingRefetchingTimerIsRun
  const toManyMinors =
    futurePlayers > maxPlayers && !unassigned && !unlimitedSigns

  useEffect(() => {
    if (typeof unassigned === 'boolean' && formik.values.unassigned === null) {
      setFieldValue('unassigned', unassigned)
    }
  }, [unassigned])

  useEffect(() => {
    if (itsNewProfile) {
      if (Array.isArray(formik.values.selectedProfile?.minors)) {
        if (
          formik.values.selectedProfile?.minors?.length !== 0 &&
          emptyMinors
        ) {
          setFieldValue('emptyMinors', false)
        } else {
          setFieldValue('emptyMinors', true)
        }
      }
    } else {
      if (Array.isArray(formik.values.selectedProfile?.waiver?.minors)) {
        if (
          formik.values.selectedProfile?.waiver?.minors?.length !== 0 &&
          emptyMinors
        ) {
          setFieldValue('emptyMinors', false)
        } else {
          setFieldValue('emptyMinors', true)
        }
      }
    }
  }, [
    formik.values.selectedProfile?.waiver?.minors,
    formik.values.selectedProfile?.minors
  ])

  useEffect(() => {
    if (typeof requireAuth !== 'undefined' && !requireAuth && exists === null) {
      setFieldValue('exists', false)
    }
  }, [requireAuth])

  useEffect(async () => {
    if (waiverId) {
      await getWaiverWidgetEntity(waiverId, {
        onSuccess: (data) => setFieldValue('waiverData', data),
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          toast({
            title: 'Error',
            msgObjects,
            message: msg
          })
        }
      })
    }
  }, [waiverId,2])

  useEffect(() => {
    if (waiverBlocks && waiverBlocks?.length !== 0) {
      const obj = {}
      waiverBlocks?.forEach((block) => {
        if (block?.checkouts && block?.checkouts.length !== 0) {
          block.checkouts.forEach((checkout) => {
            obj[`checkout${checkout.id}`] = false
          })
        }
      })
      setFieldValue('requiredCheckboxes', obj)
    }
  }, [waiverBlocks])

  useEffect(() => {
    if (requireAuth === false) {
      setFieldValue('itsNewProfile', true)
      setFieldValue('createMode', true)
      setFieldValue('phoneNumberAccepted', true)
    }
  }, [requireAuth])

  useEffect(() => {
    if (formik?.values?.technicalNumber) setFieldValue('createMode', true)
  }, [phoneNumberAccepted])

  useEffect(() => {
    if (!showForm && bookingRefetchingTimerIsRun && bookingWasLoaded) {
      setFieldValue('bookingRefetchingTimerIsRun', false)
    }
  }, [showForm, bookingRefetchingTimerIsRun, bookingWasLoaded])

  useEffect(() => {
    console.log('\n', formik.values, '\n')
  }, [formik.values])

  console.log('(!unassigned && !unlimitedSigns)', !unassigned, !unlimitedSigns)

  const showPhoneNumber = phoneNumberAccepted ? false : bookingWasLoaded
  const showSelectedProfileBlock =
    !createMode &&
    phoneNumberAccepted &&
    !selectedProfile &&
    requireAuth &&
    !formik?.values?.technicalNumber
  const showCreateProfile = createMode && bookingWasLoaded
  const showAddMinorBlock =
    !createMode &&
    bookingWasLoaded &&
    selectedProfile &&
    singedForHandler !== 'M'
  const showCheckoutBlock =
    !createMode && bookingWasLoaded && selectedProfile && waiverData
  return (
    <>
      <div className="container">
        <div className="waiver-widget">
          <div className="waiver-widget--logo">
            <Logo />
          </div>
          <span ref={scrollRef}>Online liability waiver</span>
          <FormikProvider value={formik}>
            <ScrollHelper />
            {!unassigned && !unlimitedSigns && <FuturePlayersCounter />}
            <div className="waiver-widget--container">
              <EnterBookingNumber />
              <div className={bookingWasLoaded ? 'show' : 'hide'}>
                <BookingResult showStartOverButton={showStartOverButton} />
              </div>
              {loadedBooking?.status === 'upcoming' ? (
                showForm ? (
                  <>
                    <div className={showPhoneNumber ? 'show' : 'hide'}>
                      <EnterPhoneNumber />
                    </div>
                    <div className={showSelectedProfileBlock ? 'show' : 'hide'}>
                      <SelectProfile />
                    </div>
                    <div className={showCreateProfile ? 'show' : 'hide'}>
                      <CreateProfile />
                    </div>
                    <div
                      className={
                        bookingWasLoaded && selectedProfile && !createMode
                          ? 'show'
                          : 'hide'
                      }
                    >
                      <ParticipantsInformation />
                      <SingingWaiver />
                    </div>
                    <div
                      className={showAddMinorBlock ? 'show' : 'hide'}
                      style={{ position: 'relative', zIndex: 5 }}
                    >
                      <AddMinor toManyMinors={toManyMinors} />
                    </div>
                    <div className={showCheckoutBlock ? 'show' : 'hide'}>
                      {waiverData &&
                        waiverData?.blocks?.length !== 0 &&
                        waiverData.blocks.map((block, idx) => {
                          return (
                            <TextBlocks
                              block={block}
                              key={'text-block-' + idx}
                            />
                          )
                        })}
                    </div>
                    <div className={showCheckoutBlock ? 'show' : 'hide'}>
                      <Signature />
                    </div>
                    <div
                      className={
                        !createMode && bookingWasLoaded && selectedProfile
                          ? 'show'
                          : 'hide'
                      }
                    >
                      <MinorsErrorModal toManyMinors={toManyMinors} />
                      <ButtonBlue
                        disabled={toManyMinors}
                        onClick={formik.handleSubmit}
                      >
                        Complete {toManyMinors && '(To many minors)'}
                      </ButtonBlue>
                    </div>
                    {bookingWasLoaded && selectedProfile && (
                      <CompleteWaiverModal
                        onOpen={onOpenSuccess}
                        selectedProfile={selectedProfile}
                        isOpen={isOpenSuccess}
                        setOpen={setIsOpenSuccess}
                        isDisabled={!canAddMore && !requireAuth}
                        onClose={onCloseSuccess}
                      />
                    )}
                  </>
                ) : (
                  bookingWasLoaded && (
                    <div className="waiver-widget--notif error">
                      Sorry, you can't add more players to this booking.
                    </div>
                  )
                )
              ) : (
                bookingWasLoaded && (
                  <div className="waiver-widget--notif error">
                    Bookings are no longer available.
                  </div>
                )
              )}
            </div>
          </FormikProvider>
        </div>
      </div>
      <Footer waiverData={waiverData} />
    </>
  )
}
