import { Box, IconButton, Text } from '@chakra-ui/react'
import { PlusIcon } from '../../../../../../assets/Icons/PlusIcon'
import CheckoutsWaiverItem from '../CheckoutsWaiverItem'
import { useMemo, useState } from 'react'
import NewCheckoutsWaiverItem from '../NewCheckoutsWaiverItem'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import { PERMISSION_LOCATIONS } from '../../../../../../constants'

export default function CheckoutsWaiverList({
  checkouts = [],
  waiverBlockId = '',
  waiverId = ''
}) {
  const [addNewCheckout, setAddNewCheckout] = useState(false)
  const locationsPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const lastCheckoutPositionIndex = useMemo(() => {
    if (checkouts.length !== 0) {
      let maxIndex = 1
      checkouts.forEach((checkout) => {
        if (checkout.position > maxIndex) {
          maxIndex = checkout.position
        }
      })
      return maxIndex
    } else {
      return 1
    }
  }, [checkouts])

  function addCheckoutHandler() {
    setAddNewCheckout(!addNewCheckout)
  }

  function closeNewCheckout() {
    setAddNewCheckout(false)
  }
  return (
    <>
      <Box d="flex" alignItems="center" mb="16px">
        <Text fontSize={{ base: '14px', '2xl': '18px' }} fontWeight="500">
          Checkouts
        </Text>
        <IconButton
          aria-label="add-waiver-checkout"
          variant="funcColumnIconButton"
          w="12px"
          minW="unset"
          minH="unset"
          h="12px"
          isDisabled={!locationsPermission}
          onClick={addCheckoutHandler}
          ml={{ base: 'auto', md: '16px' }}
          color="custom.blue.600"
          icon={<PlusIcon w="inherit" />}
        />
      </Box>
      {addNewCheckout && (
        <NewCheckoutsWaiverItem
          placeholder="New Checkout"
          closeNewCheckout={closeNewCheckout}
          waiverBlockId={waiverBlockId}
          waiverId={waiverId}
          lastCheckoutPositionIndex={lastCheckoutPositionIndex}
        />
      )}
      {checkouts.length === 0 && (
        <Box textAlign="center">
          <Text color="custom.gray.300">No checkouts</Text>
        </Box>
      )}
      {checkouts?.map((el, idx) => (
        <CheckoutsWaiverItem
          text={el.text}
          waiverBlockId={waiverBlockId}
          waiverId={waiverId}
          waivercheckoutId={el.id}
          key={'waiverCheckout' + idx + waiverId + waiverBlockId + el.id}
        />
      ))}
    </>
  )
}
