import { Box, Button, Switch, Text } from '@chakra-ui/react'
import { PERMISSION_USERS, SETTINGS_URL } from '../../../constants'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { useHistory } from 'react-router-dom'
import {
  usePatchUserRole,
  useUsersRoles
} from '../../../libs/hooks/useUsersRoles'
import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import PermissionRequired from '@/components/Common/PermissionRequired'

export default function UsersRoles() {
  const queryClient = useQueryClient()
  const usersPermission = PermissionRequired(PERMISSION_USERS, true)
  const history = useHistory()
  const { data: usersRoles = [], isLoading: isLoadingUserRoles } =
    useUsersRoles()
  const { mutate: patchUserRole, isLoading: isLoadingPatchUserRoles } =
    usePatchUserRole()
  const isLoading = isLoadingUserRoles || isLoadingPatchUserRoles

  function goTo(url) {
    return () => history.push(url)
  }
  const sortingPattern = ['game master', 'team lead', 'manager', 'owner']

  const correctSorting = useMemo(() => {
    const arr = []
    sortingPattern.forEach((name) => {
      for (let i = 0; i < usersRoles.length; i++) {
        const roleObj = usersRoles[i]
        const roleName = roleObj.name
        if (name?.toLowerCase() === roleName?.toLowerCase()) {
          arr.push(roleObj)
          break
        }
      }
    })
    return arr
  }, [usersRoles])

  const { columns, allFunctionsNames } = useMemo(() => {
    const finalObj = {}
    const allFunctionsNames = []
    if (correctSorting.length !== 0) {
      correctSorting?.forEach((user) => {
        const obj = {}
        const functions = user.functions
        if (functions.length !== 0) {
          functions.forEach((func) => {
            if (!allFunctionsNames.includes(func.name)) {
              allFunctionsNames.push(func.name)
            }
            obj[func.name] = func
          })
        }
        finalObj[user.name] = {
          ...user,
          ...obj
        }
      })
    }
    return { columns: finalObj, allFunctionsNames }
  }, [correctSorting])

  function onChangeFunctionStatus(roleId, functionId) {
    return (e) =>
      patchUserRole(
        {
          obj: {
            roleId,
            functionId,
            boolean: e.target.checked
          }
        },
        {
          onSuccess: () => queryClient.invalidateQueries(['usersRoles'])
        }
      )
  }

  return (
    <Box
      maxW={{ base: 'unset', md: '720px', '2xl': '1100px' }}
      m={{ base: '16px auto 0', md: '32px auto 0' }}
      p={{ base: '16px', md: 0 }}
    >
      <Box d="flex" flexDir={{ base: 'column', md: 'row' }}>
        <Button
          variant="ghost"
          fontFamily="inherit"
          p="0"
          w={{ md: '100%', '2xl': '50%' }}
          justifyContent="start"
          mb={{ base: '16px', md: '24px', '2xl': '32px' }}
          onClick={goTo(SETTINGS_URL)}
        >
          <Text
            as={'h1'}
            fontSize={{ base: '24px', '2xl': '32px' }}
            fontWeight={{ base: '600' }}
            color="custom.black.500"
          >
            <ArrowIcon
              w="auto"
              height="16px"
              mr="16px"
              mb="2px"
              color="custom.gray.200"
            />
            Roles
          </Text>
        </Button>
      </Box>
      <Box
        bg="white"
        d={{ base: 'none', md: 'grid' }}
        gridTemplateColumns={`2fr repeat(${Object.keys(columns).length}, 1fr)`}
        p="16px"
        fontSize={{ base: '12px', '2xl': '14px' }}
        color="custom.black.500"
      >
        <Box textAlign="center" color="inherit">
          Function
        </Box>
        {Object.keys(columns).length !== 0 &&
          Object.keys(columns).map((key, idx) => (
            <Box textAlign="center" color="inherit" key={'columnsRolexs' + idx}>
              {key}
            </Box>
          ))}
      </Box>
      {allFunctionsNames.length !== 0 &&
        allFunctionsNames.map((funcName, idx) => (
          <Box
            bg="white"
            key={'funcNameUserRole' + idx}
            d="grid"
            gridTemplateColumns={{
              base: 'none',
              md: `2fr repeat(${Object.keys(columns).length}, 1fr)`
            }}
            p="16px"
            gridRowGap={{ base: '12px', md: 'unset' }}
            mb="2px"
            fontSize={{ base: '12px' }}
            color="custom.black.500"
          >
            <Box
              textAlign={{ base: 'start', md: 'center' }}
              gridColumn={{ base: '1/4', md: '1/2' }}
              fontWeight={{ base: '500', md: '400' }}
              mb={{ base: '16px', md: 'unset' }}
            >
              {funcName}
            </Box>
            {Object.keys(columns).length !== 0 &&
              Object.keys(columns).map((roleName, idx) => {
                const name = roleName.replace(/(^\w|\s\w)/g, (m) =>
                  m.toUpperCase()
                )
                const matched = name.match(/(^\w|\s\w)/g)
                let final = ''
                matched?.forEach((letter) => {
                  final = final.trim() + letter.trim()
                })
                return (
                  <Box
                    d={{ base: 'flex' }}
                    key={'roleNameColumns' + idx}
                    alignItems="center"
                    justifyContent={{ base: 'center', md: 'center' }}
                  >
                    <Box d={{ base: 'block', md: 'none' }} mr="8px">
                      {final}
                    </Box>
                    <Switch
                      isChecked={columns[roleName][funcName].applied}
                      isDisabled={isLoading}
                      onChange={onChangeFunctionStatus(
                        columns[roleName].id,
                        columns[roleName][funcName].id
                      )}
                    />
                  </Box>
                )
              })}
          </Box>
        ))}
    </Box>
  )
}
