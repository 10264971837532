import { api } from '..'

const comboStub = {
  title: 'test',
  description: 'test',
  price: '100',
  additional_service_price: '100',
  terms_and_conditions: 'test text',
  number_of_players: '2',
  included_games: [3, 4, 7],
  order_weekdays: [2],
  booking_weekdays: [3]
}

export function ComboEndpoint() {
  const endpointPath = '/api/combos/'
  const getPricingPath = (id) => endpointPath + id + '/price/'
  const handleErrors = (error: unknown) => {
    console.error(
      `Error happened on ${ComboEndpoint.name
        .split(/(?=[A-Z])/)
        .join(' ')}, here's log: `,
      error
    )
  }

  return {
    async createComboPrice(comboId, body) {
      try {
        const { data } = await api.post(getPricingPath(comboId), {
          combo: comboId,
          ...body
        })
        return data
      } catch (error) {
        handleErrors(error)
        throw new Error(error)
      }
    },
    async updateComboPrice(comboId, priceIndex, body) {
      try {
        const { data } = await api.patch(
          getPricingPath(comboId) + priceIndex + '/',
          {
            combo: comboId,
            ...body
          }
        )
        return data
      } catch (error) {
        handleErrors(error)
        throw new Error(error)
      }
    },
    async deleteComboPrice(comboId, priceIndex) {
      try {
        const { data } = await api.delete(getPricingPath(comboId) + priceIndex)
        return data
      } catch (error) {
        handleErrors(error)
        throw new Error(error)
      }
    },
    async getList() {
      try {
        const { data } = await api.get(endpointPath)
        return data
      } catch (error) {
        handleErrors(error)
        throw new Error(error)
      }
    },
    async getSingle(id: string) {
      try {
        const { data } = await api.get(endpointPath + `${id}/`)
        return data
      } catch (error) {
        handleErrors(error)
        throw new Error(error)
      }
    },
    async createSingle(body: Partial<typeof comboStub>) {
      try {
        const { data } = await api.post(
          endpointPath,
          body
          // createFormDataPayload(body),
          // { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        return data
      } catch (error) {
        handleErrors(error)
        throw new Error(error)
      }
    },
    async updateSingle(id: string, body: Partial<typeof comboStub>) {
      try {
        const { data } = await api.patch(
          endpointPath + `${id}/`,
          body
          // createFormDataPayload(body),
          // { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        return data
      } catch (error) {
        handleErrors(error)
        throw new Error(error)
      }
    },
    async putImage(id: string, body: { title: string, image: File }) {
      try {
        const { data } = await api.put(
          endpointPath + `${id}/`,
          createFormDataPayload(body),
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        return data
      } catch (error) {
        handleErrors(error)
        throw new Error(error)
      }
    },
    async deleteSingle(id: string) {
      try {
        const { data } = await api.delete(endpointPath + `${id}/`)
        return data
      } catch (error) {
        handleErrors(error)
        throw new Error(error)
      }
    }
  }
}

const createFormDataPayload = (body) => {
  const formData = new FormData()
  Object.entries(body).forEach(([key, value], index) => {
    if (value instanceof Array) {
      return value.forEach((arrayValue) =>
        formData.append(`${key}[]`, arrayValue.toString())
      )
    }
    formData.append(key, value)
  })

  return formData
}

export function ComboBookingsEndpoint() {
  const endpointPath = '/api/combo_booking/'

  return {
    async getAll(params) {
      const { data } = await api.get(endpointPath, { params })
      return data
    }
  }
}
