import { Box, Button, IconButton } from '@chakra-ui/react'
import { LogoutIcon } from '../../../assets/Icons/LogoutIcon'
import { ProfileIcon } from '../../../assets/Icons/ProfileIcon'
import { useDispatch, useSelector } from 'react-redux'
import { matchMedia768InState, userInState } from '../../../constants'
import { deleteAllPrebookings, signOut } from '../../../actions'

export default function HeaderProfile() {
  const dispatch = useDispatch()
  const user = useSelector(userInState)
  const isLargerThan768 = useSelector(matchMedia768InState)

  function logout() {
    dispatch(deleteAllPrebookings())
    dispatch(signOut())
  }

  return (
    <Box>
      {isLargerThan768 && (
        <Button
          leftIcon={<ProfileIcon color="custom.gray.200" />}
          variant="ghost"
          color="custom.gray.400"
          mr="14px"
          p="0 10px"
          fontWeight="400"
          fontSize="14px"
        >
          {user?.email}
        </Button>
      )}
      <IconButton
        aria-label="logout"
        onClick={logout}
        icon={<LogoutIcon color={'custom.gray.200'} w="20px" height="20px" />}
        bg="none"
      />
    </Box>
  )
}
