import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard/src'

import CapItem from './CapItem'
import { CopyIconWithoutBg } from '../../../../assets/Icons/CopyIconWithoutBg'
import { generateWarningToast } from '../../../../libs'
import { useBookingsByCodes } from '../../../../libs/hooks/useBookingsByCodes'
import { useGroupNotifications } from '../../../../libs/hooks/useGroupNotifications'
import { useUnlimitedSigns } from '../../../../libs/hooks/useUnlimitedSigns'
import { useUnlimitedSignsByArray } from '../../../../libs/hooks/useUnlimitedSignsByArray'
import { BookingContext } from '../../../../pages/Booking'
import Preloader from '../../../Common/Preloader'

export default function RemoveCapOnWaiversModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [bookingData = {}] = useContext(BookingContext)
  const order = bookingData?.order || {}
  const orderId = order?.id
  const bookingCodes = order?.bookings || []
  const { mutate: sendGroupNotifications } = useGroupNotifications()
  const {
    data: bookings = [],
    isLoading: isLoadingBookings,
    refetch: refecthBookings
  } = useBookingsByCodes(bookingCodes, {
    enabled: isOpen
  })
  const { mutate: toogleAllBookings, isLoading: isLoadingToggle } =
    useUnlimitedSignsByArray()
  const name = order?.firstname + ' ' + order?.lastname
  const email = order?.email
  const phone = order?.phone

  const [bookingForCopy, setBookingForCopy] = useState(null)
  useEffect(() => {
    if (bookings.length !== 0 && !bookingForCopy) {
      const find = bookings?.find((book) => !!book?.unlimited_signs)
      if (find) setBookingForCopy(find?.code)
    }
  }, [bookings])
  const linkForCopy = bookingForCopy
    ? process.env.REACT_APP_WAIVER_WIDGET_COPY_LINK +
      `waiver-widget?booking=${bookingForCopy}`
    : null

  const { mutate: setUnlimitedSigns, isLoading: isLoadingSigns } =
    useUnlimitedSigns()
  const isLoading = isLoadingBookings || isLoadingSigns || isLoadingToggle
  const allChecked = useMemo(() => {
    return bookings?.every((book) => book.unlimited_signs)
  }, [bookings])

  function toggleAll() {
    const filteredIds = bookings.filter(
      (book) => book.unlimited_signs === allChecked
    )
    const ids = filteredIds.map((book) => book.id)
    toogleAllBookings(
      {
        bookingsIds: ids,
        unlimitedSigns: !allChecked
      },
      {
        onSuccess: () => {
          setTimeout(() => refecthBookings(), 500)
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          generateWarningToast({
            id: 'notification-send',
            toast,
            msgObjects,
            message: msg
          })
        }
      }
    )
  }

  function onSuccessHandler() {
    onClose()
  }

  function sendGroup() {
    sendGroupNotifications(orderId, {
      onSuccess: () => {
        toast({
          title: 'Notifications have been sent.',
          status: 'success',
          duration: 2000,
          position: 'bottom-right',
          isClosable: true
        })
      },
      onError: (err) => {
        const msg = err?.response?.data?.msg
        const msgObjects = err?.response?.data
        generateWarningToast({
          id: 'notification-send',
          toast,
          msgObjects,
          message: msg
        })
      }
    })
  }

  return (
    <>
      <Button onClick={onOpen} variant="blue">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          Remove cap on waivers
        </Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '305px', md: '720px', '2xl': '1100px' }}
          pos="relative"
        >
          {isLoading && (
            <Box
              pos="absolute"
              top="0"
              left="0"
              bottom="0"
              right="0"
              zIndex={2}
            >
              <Preloader h="8px" w="calc(100% - 4px)" opacity="0.8" m="2px" />
            </Box>
          )}
          <ModalHeader mb={{ base: '16px', md: '24px', '2xl': '32px' }}>
            Unlimited waivers for bookings
            <br />
            <Box
              as="span"
              d="flex"
              color="custom.gray.300"
              fontSize={{ base: '16px', '2xl': '18px' }}
            >
              For{' '}
              <Box as="p" ml="8px" color="custom.blue.600">
                {orderId}
              </Box>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              d="grid"
              gridTemplateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
              gridGap={{ base: '16px' }}
              mb="16px"
            >
              {bookings.map((book) => {
                console.log(book)
                const startTime = book?.start_time * 1000
                const code = book?.code
                const bookingId = book?.id
                const checked = book?.unlimited_signs
                const name = book?.event_variant
                console.log('book', book)

                function callback(state) {
                  setUnlimitedSigns(
                    {
                      bookingId,
                      unlimitedSigns: state
                    },
                    {
                      onSuccess: () => refecthBookings()
                    }
                  )
                }

                const checkedForCopy = bookingForCopy === code
                return (
                  <CapItem
                    key={code}
                    code={code}
                    name={name}
                    startTime={startTime}
                    checkedForCopy={checkedForCopy}
                    checkedInitial={checked}
                    disabled={isLoading}
                    copyCallback={setBookingForCopy}
                    callback={callback}
                  />
                )
              })}
            </Box>
            <Button
              variant="likeInput"
              w="100%"
              mb="16px"
              onClick={toggleAll}
              fontSize={{ base: '12px', '2xl': '14px' }}
            >
              {allChecked ? 'Unselect All' : 'Select All'}
            </Button>
            <Box
              d="grid"
              mb={{ base: '16px' }}
              gridTemplateColumns={{ base: '1fr', md: '3fr 1fr' }}
              gridGap="16px"
            >
              <Button
                variant="blue"
                isDisabled={isLoading || !linkForCopy}
                onClick={sendGroup}
              >
                Send link to handler
              </Button>
              {linkForCopy ? (
                <CopyToClipboard text={linkForCopy}>
                  <Button
                    variant="likeInput"
                    color="custom.gray.300"
                    isDisabled={isLoading || !linkForCopy}
                  >
                    <CopyIconWithoutBg w="20px" h="20px" mr="8px" />
                    Copy link
                  </Button>
                </CopyToClipboard>
              ) : (
                <Button
                  variant="lightBlue"
                  isDisabled={isLoading || !linkForCopy}
                >
                  <CopyIconWithoutBg w="20px" h="20px" mr="8px" />
                  Copy link
                </Button>
              )}
            </Box>
          </ModalBody>

          <Box
            bg="custom.lightGray"
            m={{ base: '0 -16px -16px', '2xl': '0 -24px -24px' }}
            p="24px"
          >
            <Box
              as="div"
              fontSize={{ base: '18px' }}
              fontWeight="500"
              mb="16px"
            >
              Client Information
            </Box>
            <Box d="flex" flexWrap="wrap">
              <Box
                d="flex"
                fontSize={{ base: '12px', '2xl': '14px' }}
                mr="16px"
                mb="16px"
              >
                <Box as="span" color="custom.gray.300">
                  Client:&nbsp;
                </Box>
                <Box as="span" color="custom.gray.900">
                  {name}
                </Box>
              </Box>
              <Box
                d="flex"
                fontSize={{ base: '12px', '2xl': '14px' }}
                mr="16px"
                mb="16px"
              >
                <Box as="span" color="custom.gray.300">
                  Email:&nbsp;
                </Box>
                <Box as="span" color="custom.gray.900">
                  {email}
                </Box>
              </Box>
              <Box
                d="flex"
                fontSize={{ base: '12px', '2xl': '14px' }}
                mr="16px"
                mb="16px"
              >
                <Box as="span" color="custom.gray.300">
                  Phone:&nbsp;
                </Box>
                <Box as="span" color="custom.gray.900">
                  {phone}
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </>
  )
}
