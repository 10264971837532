import { useMutation, useQuery } from 'react-query'

import {
  getUsersRoles,
  patchUserRole
} from '../../api/Settings/users/userRoles'

export function useUsersRoles(queryOption = {}) {
  return useQuery(['usersRoles'], () => getUsersRoles(), {
    ...queryOption
  })
}

export function usePatchUserRole(options = {}) {
  return useMutation(({ obj }) => patchUserRole(obj), {
    ...options
  })
}
