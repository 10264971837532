import { Box, Input, Text } from '@chakra-ui/react'
import { useField } from 'formik'

export default function AccordionInput({ name = '', label = '', disabled }) {
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  return (
    <Box
      d="grid"
      gridTemplateColumns="1fr 1fr"
      gridColumnGap="8px"
      fontSize={{ base: '12px', '2xl': '14px' }}
      mb="8px"
      alignItems="center"
    >
      <Text color="inherit">{label}</Text>
      <Input
        variant="gbase2"
        {...field}
        background={
          touched && error ? 'rgba(255,223,223,0.8)' : 'custom.lightGray'
        }
        isDisabled={disabled}
      />
    </Box>
  )
}
