import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text
} from '@chakra-ui/react'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { valueOrder } from '../../../configs'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAllBookingsByOrder } from '../../../libs/hooks/useAllBookingsByOrder'
import Preloader from '../../Common/Preloader'
import { BookingContext } from '../../../pages/Booking'
import { linksCreator } from '../../../libs'
import BookingsModal from '../Modals/BookingsModal'
import { SimpleSkullIcon } from '../../../assets/Icons/SimpleSkullIcon'
import RemoveCapOnWaiversModal from '../Modals/RemoveCapOnWaiversModal'

export default function OrderDescription({ ...rest }) {
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalPriceWithFee, setTotalPriceWithFee] = useState(0)
  const [bookingData = {}, isLoading] = useContext(BookingContext)
  const order = bookingData?.order || {}
  const client =
    order?.firstname || order?.lastname
      ? order?.firstname + ' ' + order?.lastname
      : '—'
  const email = order?.email ? order?.email : '—'
  const phone = order?.phone ? order?.phone : '—'
  const resource = bookingData?.resource ? bookingData?.resource : '—'
  const emailInBlacklist = order?.email_blacklisted
  const phoneInBlacklist = order?.phone_blacklisted
  const bookingCodes = order?.bookings || []

  const correctObject = useMemo(() => {
    const obj = {}
    if (Object.keys(order).length !== 0) {
      valueOrder.forEach((item) => {
        obj[item.key] = order[item.key]
      })
    }
    return obj
  }, [order])

  const {
    data,
    isLoading: localLoading,
    refetch
  } = useAllBookingsByOrder(correctObject?.id, {
    enabled: false,
    initialData: {}
  })

  const bookingsArr = useMemo(() => {
    let totalPrice = 0
    let totalPriceWithFee = 0
    const arr = data?.data?.results?.map((booking) => {
      totalPrice = totalPrice + booking.price
      totalPriceWithFee = totalPriceWithFee + booking.fee
      return { code: booking.code, id: booking.id, status: booking.status }
    })
    setTotalPrice(+(totalPrice / 100).toFixed(2))
    setTotalPriceWithFee(+(totalPriceWithFee / 100).toFixed(2))
    return arr
  }, [data])

  useEffect(() => {
    if (correctObject?.id) {
      refetch()
    }
  }, [correctObject])

  function isLoadingInProgress() {
    return localLoading || isLoading
  }

  return (
    <Accordion
      allowToggle
      defaultIndex={[0]}
      bg="white"
      borderStyle="hidden"
      p={{ base: '16px 16px 12px', '2xl': '24px' }}
      fontFamily="inherit"
      mb={{ base: '16px', '2xl': '32px' }}
      {...rest}
    >
      <AccordionItem borderStyle="hidden">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              p="2px 2px 2px 0"
              fontWeight="500"
              fontSize={{ base: '18px', '2xl': '24px' }}
              height="32px"
              mb={{ base: '8px', '2xl': '16px' }}
              _hover={{ bg: 'white' }}
              _focus={{ border: 'none' }}
              _active={{ bg: 'white' }}
            >
              <Box flex="1" textAlign="left">
                Order #{correctObject.id}:
              </Box>
              <ArrowIcon
                w="auto"
                height="16px"
                transition="all 0.2s ease-in"
                transform={isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'}
                color="custom.blue.600"
              />
            </AccordionButton>
            <AccordionPanel p="0">
              {isLoadingInProgress() ? (
                <Preloader />
              ) : (
                <>
                  <Box
                    d="grid"
                    gridTemplateRows={{ base: 'repeat(7, auto)' }}
                    gridTemplateColumns={{
                      base: '1fr',
                      md: '1.4fr repeat(2, 1fr)'
                    }}
                    gridRowGap={{ base: '8px', md: 'unset' }}
                  >
                    {/*<AddUserToBlacklistModal/>*/}
                    {/*<IconButton aria-label='blacklist'*/}
                    {/*            minH='unset'*/}
                    {/*            w='24px'*/}
                    {/*            minW='24px'*/}
                    {/*            height='24px'*/}
                    {/*            _focus={{border: 'none'}}*/}

                    {/*            _hover={{bg: 'transparent'}}*/}
                    {/*            mr='8px'*/}
                    {/*            bg='custom.gray.200'*/}
                    {/*            borderRadius='50%'*/}
                    {/*            icon={<SimpleSkullIcon w='60%' h='60%' color='custom.red.600'/>}*/}
                    {/*/>*/}
                    <Box d="grid" gridTemplateColumns="1fr 6fr" minH="30px">
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        color="custom.gray.300"
                        minW="60px"
                      >
                        Client:
                      </Text>
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        minW="150px"
                        justifySelf={{ base: 'end', md: 'unset' }}
                        textAlign={{ base: 'end', md: 'unset' }}
                        color="custom.gray.900"
                      >
                        {client}
                      </Text>
                    </Box>
                    <Box
                      d="grid"
                      gridTemplateColumns="1fr 9fr"
                      gridColumn={{ base: '1/2', md: '2/4' }}
                      gridRow={{ base: 'unset', md: '1/3' }}
                      alignItems="start"
                      minH="30px"
                    >
                      <Box minW="80px">
                        <BookingsModal title="Bookings" value={bookingsArr} />
                      </Box>
                      <Box
                        d={{ base: 'flex', md: '-webkit-box' }}
                        flexWrap={{ base: 'wrap', md: 'wrap' }}
                        overflow={{ base: 'unset', md: 'hidden' }}
                        textOverflow={{ md: 'ellipsis' }}
                        justifySelf={{ base: 'end', md: 'unset' }}
                        sx={{
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical'
                        }}
                      >
                        {linksCreator(bookingsArr)}
                      </Box>
                    </Box>
                    <Box
                      d="grid"
                      gridTemplateColumns="1fr 6fr"
                      minH="30px"
                      d="flex"
                      gridRow={{ base: '2/3', md: 'unset' }}
                    >
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        color="custom.gray.300"
                        minW="60px"
                      >
                        Email:
                      </Text>
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        ml={{ base: 'auto', md: 'unset' }}
                        justifySelf={{ base: 'end', md: 'unset' }}
                        color="custom.gray.900"
                      >
                        {emailInBlacklist && (
                          <SimpleSkullIcon
                            w="18px"
                            h="18px"
                            mr="4px"
                            color={'custom.black.50'}
                          />
                        )}
                        {email}
                      </Text>
                    </Box>
                    <Box
                      d="grid"
                      gridTemplateColumns="1fr 6fr"
                      minH="30px"
                      d="flex"
                      gridRow={{ base: '3/4', md: 'unset' }}
                    >
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        color="custom.gray.300"
                        minW="60px"
                      >
                        Phone:
                      </Text>
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        alignItems="start"
                        d="flex"
                        ml={{ base: 'auto', md: 'unset' }}
                        justifySelf={{ base: 'end', md: 'unset' }}
                        color="custom.gray.900"
                      >
                        {phoneInBlacklist && (
                          <SimpleSkullIcon
                            w="18px"
                            h="18px"
                            mr="4px"
                            color={'custom.black.50'}
                          />
                        )}
                        {phone}
                      </Text>
                    </Box>
                    <Box d="grid" gridTemplateColumns="1fr 4fr" minH="30px">
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        color="custom.gray.300"
                        minW="72px"
                      >
                        Resource:
                      </Text>
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        justifySelf={{ base: 'end', md: 'unset' }}
                        color="custom.gray.900"
                      >
                        {resource}
                      </Text>
                    </Box>
                    <Box d="grid" gridTemplateColumns="1fr 8fr" minH="30px">
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        color="custom.gray.300"
                        minW="40px"
                      >
                        Total:
                      </Text>
                      <Text
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        justifySelf={{ base: 'end', md: 'unset' }}
                        color="custom.gray.900"
                      >
                        ${totalPrice} (${totalPriceWithFee})
                      </Text>
                    </Box>
                  </Box>
                  <RemoveCapOnWaiversModal bookingCodes={bookingCodes} />
                </>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
