import Waiver from '../Waiver'

export default function WaiversList({ data = [] }) {
  return data?.map((waiver, idx) => (
    <Waiver
      firstname={waiver.firstname}
      waiverBookings={waiver.bookings}
      lastname={waiver.lastname}
      email={waiver.email}
      phone={waiver.phone}
      birthdate={waiver.birthdate}
      doc={waiver.doc}
      inBlacklist={waiver.inBlacklist}
      minors={waiver.minors}
      waiversentitysId={waiver.id}
      key={'waiver' + idx}
    />
  ))
}
