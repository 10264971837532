import * as yup from 'yup'

import { cleanPhoneNumber } from '../../../libs'

export const editPlayerValidation = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup
    .string()
    .required()
    .email()
    .test((val) => /@(\w{2,})/.test(val)),
  phone: yup
    .string()
    .test('phone length', 'must be 11 charters length', (val) => {
      if (val && val.length) {
        const value = cleanPhoneNumber(val)
        return /\d{10}/.test(value)
      } else return true
    }),
  birthdate: yup.date().required()
})
