import { Box, Button, Link, Text, useTheme } from '@chakra-ui/react'
import DataRow from '../../../Common/DataRow'
import { friendlyTime } from '../../../../libs'
import { BASE_URL_WO_SLASH } from '../../../../constants'
import { usePatchWaiversentitys } from '../../../../libs/hooks/useWaiversentitys'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import React from 'react'
import { SimpleSkullIcon } from '../../../../assets/Icons/SimpleSkullIcon'
import { BASE_URL } from '@/constants'

export default function Waiver({
  firstname = '',
  lastname = '',
  minors = [],
  email = '',
  doc = '',
  birthdate = '',
  phone = '',
  waiverBookings = [],
  waiversentitysId = '',
  inBlacklist = false
}) {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const customColors = theme.colors.custom
  const { id: bookingId } = useParams()
  const { mutate: patchWaiversentitys } = usePatchWaiversentitys()
  let fullname = ''
  if (firstname || lastname) {
    fullname = (firstname ? firstname : '') + ' ' + (lastname ? lastname : '')
  } else {
    fullname = 'null'
  }

  function deleteHandler(waiversentitysId, bookings) {
    return () => {
      const newArray = [...bookings]
      const indexDelete = newArray.indexOf(+bookingId, 0)

      if (indexDelete !== -1) {
        newArray.splice(indexDelete, 1)
      }

      const objToSend = {
        waiversentitysId: +waiversentitysId,
        bookings: newArray
      }

      patchWaiversentitys(
        {
          obj: objToSend
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['waiversentitys', +bookingId])
          }
        }
      )
    }
  }
  return (
    <Box w="100%" bg="white" p="16px" mb="2px">
      <Box d="flex" color="custom.black.50" fontWeight="500">
        <Text mr="4px" minW="68px">
          Full name:
        </Text>
        <Box d="flex" alignItems="start">
          <Box
            w="20px"
            h="20px"
            minW="20px"
            minH="20px"
            borderRadius="50%"
            bg="custom.lightGray"
            d="flex"
            alignItems="center"
            justifyContent="center"
            justifySelf="start"
            mr="8px"
          >
            <SimpleSkullIcon
              w="60%"
              h="60%"
              color={inBlacklist ? 'custom.red.600' : 'custom.black.50'}
            />
          </Box>
          <Text alignSelf="center" d="inline-flex">
            {fullname}
          </Text>
        </Box>
      </Box>
      <DataRow
        title={'Phone'}
        value={phone ? phone : 'null'}
        w="unset"
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />

      <DataRow
        title={'E-mail'}
        value={email}
        w="unset"
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />
      <DataRow
        title={'date'}
        value={friendlyTime(birthdate, 'MM.DD.YY')}
        w="unset"
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />

      {minors && Array.isArray(minors) && minors.length !== 0 && (
        <Box mt="16px">
          {minors?.map((minor, idx) => (
            <Box
              p="8px 10px"
              border={`1px solid ${customColors.borderBlue}`}
              bg="custom.lightGray"
              mb="4px"
              d="flex"
              justifyContent="center"
              key={'minorMobile' + minor + idx}
            >
              <Text
                fontSize={{ base: '12px', '2xl': '14px' }}
                color="custom.blue.600"
                fontWeight="500"
              >
                Minor:
              </Text>
              &nbsp;{minor.name} ({minor.birthdate})
            </Box>
          ))}
        </Box>
      )}
      <Box
        d="grid"
        gridTemplateRows="1fr"
        gridTemplateColumns="1fr 1fr"
        w="100%"
        gridColumnGap="8px"
        mt="12px"
      >
        <Link
          variant="blue"
          w="100%"
          bg="custom.blue.600"
          color="white"
          d="inline-flex"
          justifyContent="center"
          alignItems="center"
          target="_blank"
          href={`${BASE_URL}${doc}`}
        >
          Get Pdf
        </Link>

        <Button
          variant="red"
          onClick={deleteHandler(waiversentitysId, waiverBookings)}
        >
          Delete
        </Button>
      </Box>
    </Box>
  )
}
