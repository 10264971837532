import { useMutation, useQuery } from 'react-query'

import {
  addStripe,
  deleteStripe,
  getDefaultStripe,
  getPaymentGates,
  updatePaymentGate
} from '@/api/Settings/account'

export function usePaymentGates(queryOption = {}) {
  return useQuery(['stripe'], () => getPaymentGates(), {
    ...queryOption
  })
}
export function useDefaultStripe(queryOption = {}) {
  return useQuery(['defaultStripe'], () => getDefaultStripe(), {
    ...queryOption
  })
}

export function useEditPaymentGate(options = {}) {
  return useMutation(updatePaymentGate, {
    ...options
  })
}

export function useCreateStripe(options = {}) {
  return useMutation(({ obj }) => addStripe(obj), {
    ...options
  })
}

export function useDeleteStripe(options = {}) {
  return useMutation(({ obj }) => deleteStripe(obj), {
    ...options
  })
}
