import { CHANGE_ETHERNET_STATUS } from '../../constants'

const initialState = {
  online: true
}

export const ethernetWatcherReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ETHERNET_STATUS: {
      return {
        ...state,
        online: action.payload
      }
    }

    default:
      return state
  }
}
