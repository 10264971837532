import { Box, IconButton, Text } from '@chakra-ui/react'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import { CopyIconWithoutBg } from '../../../assets/Icons/CopyIconWithoutBg'

export default function AccountField({ placeholder, value }) {
  return (
    <Box
      mb="8px"
      p="8px 16px"
      bg="custom.lightGray"
      height={{ base: '40px', '2xl': '56px' }}
      pos="relative"
      d="flex"
      alignItems="center"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      <CopyToClipboard text={value}>
        <IconButton
          aria-label="copy-link"
          bg="none"
          w="18px"
          height="18px"
          minW="unset"
          minH="unset"
          pos="absolute"
          right="8px"
          bottom="12px"
          p="0"
          _focus={{ border: 'none' }}
          _hover={{ bg: 'transparent' }}
          icon={
            <CopyIconWithoutBg w="100%" height="100%" color="custom.gray.200" />
          }
        />
      </CopyToClipboard>
      <Text
        d={{ base: 'none', '2xl': 'block' }}
        pos="absolute"
        top="10px"
        fontSize="10px"
        color="custom.gray.300"
      >
        {placeholder}
      </Text>
      <Text
        mt={{ base: 'unset', '2xl': '18px' }}
        whiteSpace="nowrap"
        overflow="hidden"
        maxW="90%"
        textOverflow="ellipsis"
      >
        {value}
      </Text>
    </Box>
  )
}
