import { useQuery } from 'react-query'

import { getSlots } from '../../api/booking'

export function useSlots(obj, queryOption = {}) {
  const { locationId, players, eventVariantId, timestamp } = obj
  return useQuery(
    ['slots', locationId, players, eventVariantId, timestamp],
    () => getSlots(obj),
    {
      ...queryOption
    }
  )
}
