import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PlusIcon } from '../../../../../../assets/Icons/PlusIcon'
import { useNewEmailBaseTemplate } from '../../../../../../libs/hooks/useEmailBaseTemplates'
import { newAndEditEmailBaseTemplateModalValidation } from '../../../../../../configs/validation/newAndEditEmailBaseTemplateModalValidation'
import {
  matchMedia768InState,
  PERMISSION_NOTIFICATIONS
} from '../../../../../../constants'
import CustomTextarea from '../../../../../Common/CustomTextarea'
import PermissionRequired from '../../../../../Common/PermissionRequired'

export default function AddEmailTemplateModal() {
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const queryClient = useQueryClient()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: newEmailBaseTemplate } = useNewEmailBaseTemplate()

  function onSubmitHandler(values) {
    const toSend = {
      templateName: values.templateName,
      templateTheme: values.templateTheme,
      templateHeader: values.templateHeader,
      templateFooter: values.templateFooter
    }
    newEmailBaseTemplate(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['emailBaseTemplates'])
          onClose()
        }
      }
    )
  }

  const formik = useFormik({
    initialValues: {
      templateName: '',
      templateTheme: '',
      templateHeader: '',
      templateFooter: ''
    },
    onSubmit: onSubmitHandler,
    validationSchema: newAndEditEmailBaseTemplateModalValidation,
    enableReinitialize: true
  })

  const formikValues = formik.values
  const formikHandleSubmit = formik.handleSubmit
  const setFieldValue = formik.setFieldValue
  const formikErrors = formik.errors

  function onChangeHandler(prop) {
    return (e) => {
      const value = e.target.value
      setFieldValue(prop, value)
    }
  }

  const blue600 = {
    color: 'custom.blue.600',
    ml: '4px'
  }
  return (
    <>
      {isLargerThan768 ? (
        <Button
          variant="blue"
          ml="auto"
          onClick={onOpen}
          isDisabled={!notificationPermission}
          fontSize={{ base: '12px', '2xl': '14px' }}
        >
          <PlusIcon mr="8px" />
          New template
        </Button>
      ) : (
        <IconButton
          aria-label="editAccount"
          variant="funcColumnIconButtons"
          ml="auto"
          isDisabled={!notificationPermission}
          icon={<PlusIcon w="inherit" h="inherit" />}
          onClick={onOpen}
          color="custom.blue.600"
        />
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '720px', '2xl': '656px' }}
          >
            <ModalHeader
              mb="34px"
              d="flex"
              fontSize={{ base: '18px', '2xl': '24px' }}
            >
              Add new email template
              <Box
                ml="auto"
                d={{ base: 'none', md: 'grid' }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="8px"
                fontSize={{ base: '12px', '2xl': '14px' }}
              >
                <Button
                  w="100%"
                  variant="blue"
                  fontSize="inherit"
                  onClick={formikHandleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  w="100%"
                  variant="red"
                  fontSize="inherit"
                  onClick={onClose}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <ModalCloseButton d={{ md: 'none' }} />
            <ModalBody fontSize={{ base: '12px' }} d={{ base: 'block' }}>
              <Box mb="16px" w={{ base: '100%' }}>
                <Text as="span" d="flex" flexWrap="wrap">
                  <Text {...blue600}>{'{{ experience_image }}'}</Text>
                  <Text ml="4px">- Experience image</Text>
                  <Text {...blue600}>{'{{ direction_link_text }}'}</Text>
                  <Text ml="4px">- direction link text,</Text>
                  <Text {...blue600}>{'{{ direction_link_href }}'}</Text>
                  <Text ml="4px">- direction link href,</Text>
                  <Text {...blue600}>{'{{ parking_instructions }}'}</Text>
                  <Text ml="4px">- parking instructions</Text>
                </Text>
              </Box>
              <CustomTextarea
                label="Template name:"
                editMode={true}
                containerProps={{
                  ...(formikErrors.templateName && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateName')}
                externalValue={formikValues.templateName}
              />
              <CustomTextarea
                label="Theme:"
                editMode={true}
                containerProps={{
                  ...(formikErrors.templateTheme && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateTheme')}
                externalValue={formikValues.templateTheme}
              />
              <CustomTextarea
                label="Header:"
                editMode={true}
                containerProps={{
                  ...(formikErrors.templateHeader && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateHeader')}
                externalValue={formikValues.templateHeader}
              />
              <CustomTextarea
                label="Footer:"
                editMode={true}
                containerProps={{
                  ...(formikErrors.templateFooter && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateFooter')}
                externalValue={formikValues.templateFooter}
              />
            </ModalBody>

            <ModalFooter
              d={{ base: 'grid', md: 'none' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
            >
              <Button w="100%" variant="blue" onClick={formikHandleSubmit}>
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button w="100%" variant="red" onClick={onClose}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
