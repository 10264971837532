import { SET_BREAKPOINTS_STATE } from '../../constants'

const initialState = {
  matchMedia: {}
}

export const resolutionWatcherReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BREAKPOINTS_STATE: {
      return {
        ...state,
        matchMedia: action.payload
      }
    }

    default:
      return state
  }
}
