import { api } from '../index'

export async function aboutMe() {
  try {
    const { data } = await api.get('@/api/userinfo/me/')
    return data
  } catch (e) {
    console.log(e)
  }
}
