import {
  Accordion,
  AccordionItem} from '@chakra-ui/react'
import React from 'react'

import Card from './Card'
import Preloader from '../Preloader'

export default function CardList({ data, bookingsQueryKeys }) {
  if (!data) return <Preloader />
  return data?.length !== 0 ? (
    <Accordion allowToggle>
      {data?.map((el, idx) => {
        return (
          <AccordionItem>
            <Card
              start={el?.start_time}
              key={el?.code + el?.status + el?.firstname + idx}
              firstname={el?.order.firstname}
              lastname={el?.order.lastname}
              adults={el?.players}
              children={el?.special_players}
              phone={el?.phone}
              code={el?.code}
              location={el?.event?.location?.name}
              experience={el?.eventvariant?.name}
              status={el?.status}
              bookingsQueryKeys={bookingsQueryKeys}
              id={el?.id}
            />
          </AccordionItem>
        )
      })}
    </Accordion>
  ) : null
}
