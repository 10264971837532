import { useMutation, useQuery } from 'react-query'

import {
  createLocation,
  deleteLocation,
  editLocation,
  getAllLocations,
  getLocations
} from '../../api/gameStatusRequest'
import { getLocationById } from '../../api/Settings/location'

export function useLocations(queryOption = {}) {
  return useQuery(['locations'], () => getLocations(), {
    ...queryOption
  })
}

export function useAllLocations(queryOption = {}) {
  return useQuery(['locationsAll'], () => getAllLocations(), {
    ...queryOption
  })
}

export function useLocationById(locationId, queryOption = {}) {
  return useQuery(
    ['location', +locationId],
    () => getLocationById(locationId),
    {
      ...queryOption
    }
  )
}

export function useCreateLocation(options = {}) {
  return useMutation(({ obj }) => createLocation(obj), {
    ...options
  })
}

export function useEditLocation(options = {}) {
  return useMutation(({ obj }) => editLocation(obj), {
    ...options
  })
}

export function useDeleteLocation(options = {}) {
  return useMutation(({ obj }) => deleteLocation(obj), {
    ...options
  })
}
