import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changeOnlineStatus } from '../../actions'
import { onlineStatus } from '../../constants'

export default function useInternetConnection() {
  const dispatch = useDispatch()
  const isOnline = useSelector(onlineStatus)
  function updateNetwork() {
    if (window.navigator.onLine !== isOnline) {
      dispatch(changeOnlineStatus(window.navigator.onLine))
    }
  }
  useEffect(() => {
    window.addEventListener('offline', updateNetwork)
    window.addEventListener('online', updateNetwork)
    return () => {
      window.removeEventListener('offline', updateNetwork)
      window.removeEventListener('online', updateNetwork)
    }
  })
  return isOnline
}
