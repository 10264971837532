import { Box, Button, Text } from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useSendToEmail } from '../../libs/hooks/resetPassword'
import CustomChakraInput from '@/components/Common/CustomChakraInput'
import { sendToEmailValidation } from '../../configs/validation/sendToEmailValidation'
import { LOGIN_URL } from '../../constants'

export default function Email() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { mutate: sendToEmail } = useSendToEmail()
  const [wasSend, setWasSend] = useState(false)
  function goTo() {
    return () => history.push(LOGIN_URL)
  }
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: sendToEmailValidation,
    onSubmit: (values) => {
      sendToEmail(
        {
          obj: {
            email: values.email
          }
        },
        {
          onSuccess: () => setWasSend(true)
        }
      )
    }
  })
  const formikErrors = formik.errors
  const formikHandleSubmit = formik.handleSubmit
  const formikValues = formik.values
  return (
    <FormikProvider value={formik}>
      <Box d="flex" alignItems="center" justifyContent="center" height="100%">
        <Box
          d="flex"
          flexDir="column"
          bg="white"
          p={{ base: '24px 16px', md: '24px' }}
          boxShadow="purple"
          w={{ base: '288px', md: '350px', '2xl': '427px' }}
        >
          <Text
            as="h1"
            textTransform="uppercase"
            color="custom.blue.600"
            fontSize={{ base: '32px' }}
            lineHeight="150%"
            fontWeight="700"
            textAlign="center"
            mb={{ base: '24px' }}
          >
            Gbase
          </Text>
          {!wasSend ? (
            <>
              <CustomChakraInput
                name="email"
                id="email"
                label="E-mail"
                placeholder="E-mail"
                fontSize={{ base: '14px' }}
                mb={{ base: '16px' }}
                height={{ base: '56px' }}
              />
              <Box d="grid" gridTemplateColumns="1fr 1fr" gridGap="16px">
                <Button
                  variant="lightBlue"
                  type="submit"
                  fontSize={{ base: '14px' }}
                  fontWeight="500"
                  height={{ base: '56px' }}
                  w="100%"
                  transition="all 0.2s ease"
                  onClick={goTo()}
                >
                  Back
                </Button>
                <Button
                  variant="blue"
                  type="submit"
                  fontSize={{ base: '14px' }}
                  fontWeight="500"
                  height={{ base: '56px' }}
                  w="100%"
                  transition="all 0.2s ease"
                  onClick={formikHandleSubmit}
                >
                  Send
                </Button>
              </Box>
            </>
          ) : (
            <Text
              color="custom.gray.900"
              as="span"
              d="flex"
              flexWrap="wrap"
              fontSize={{ base: '12px', md: '14px' }}
            >
              Message with restore link was send to&nbsp;
              <Text color="custom.blue.600">{formikValues.email}</Text>
            </Text>
          )}
        </Box>
      </Box>
    </FormikProvider>
  )
}
