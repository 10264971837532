import TableBookingCalendar from './TableBookingCalendar'

export default function BookingCalendar({
  data = [],
  weekday = null,
  tableDataRefetch,
  isLoading = false
}) {
  return (
    <TableBookingCalendar
      tableData={data}
      weekday={weekday}
      isLoading={isLoading}
      tableDataRefetch={tableDataRefetch}
    />
  )
}
