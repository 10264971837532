import { Box } from '@chakra-ui/react'
import DataRow from '../../Common/DataRow'
import { useSelector } from 'react-redux'
import { preBookingsInState } from '../../../constants'
import { useEffect, useMemo } from 'react'
import Preloader from '../../Common/Preloader'
import { useFormikContext } from 'formik'
import usePrevious from '../../../libs/hooks/usePrevious'

export default function NewOrderDiscounts({
  children,
  discounts = {},
  setFree,
  giftOrPromoIsLoading
}) {
  const {
    preBookings,
    totalAmount,
    taxPercent,
    feePercent,
    subtotalAmount,
    taxAmount,
    feeAmount
  } = useSelector(preBookingsInState)
  const {
    values: { payData: { type = 'type' } = {}, experience = null } = {}
  } = useFormikContext()
  const { byPromo, byGift } = discounts
  const totalDiscount = (byGift + byPromo) / 100
  const prevType = usePrevious(type)

  const feeInPercent =
    experience?.fee && (type === 'stripe' || type === 'offline')
      ? experience?.fee
      : 0

  return (
    <Box
      bg="white"
      p={{ base: '16px', '2xl': '24px' }}
      mb={{ base: '16px', '2xl': '32px' }}
      fontSize={{ base: '12px', '2xl': '14px' }}
    >
      {children}
      <Box d="flex" flexDir={{ base: 'column', md: 'row', '2xl': 'column' }}>
        {giftOrPromoIsLoading ? (
          <Box
            height={{ base: '84px', md: '28px', '2xl': '96px' }}
            d="flex"
            w="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Preloader m={0} h={{ base: '30px', md: '24px', '2xl': '30px' }} />
          </Box>
        ) : (
          <>
            <DataRow
              title="Subtotal"
              value={`$${((subtotalAmount + byGift + byPromo) / 100).toFixed(2)}`}
              mr={{ base: '0', md: '16px', '2xl': '0' }}
              keyStyle={{
                color: 'custom.black.500',
                mr: { base: '0', md: '8px', '2xl': '0' }
              }}
              w={{ base: '100%', md: 'auto', '2xl': '100%' }}
            />
            <DataRow
              title="Discount"
              value={`-$${totalDiscount.toFixed(2)}`}
              mr={{ base: '0', md: '16px' }}
              keyStyle={{
                color: 'custom.black.500',
                mr: { base: '0', md: '8px', '2xl': '0' }
              }}
              w={{ base: '100%', md: 'auto', '2xl': '100%' }}
            />
            <DataRow
              title={`Fee (${feePercent}%)`}
              value={`$${(feeAmount / 100).toFixed(2)}`}
              w={{ base: '100%', md: 'auto', '2xl': '100%' }}
              keyStyle={{
                color: 'custom.blue.600',
                fontWeight: '500',
                mr: { base: '0', md: '8px', '2xl': '0' }
              }}
              valueStyle={{
                color: 'custom.blue.600',
                mr: { md: '16px', '2xl': 'unset' },
                fontWeight: '500'
              }}
            />
            {taxAmount > 0 && (
              <DataRow
                title={`Sales Tax (${taxPercent}%)`}
                value={`$${(taxAmount / 100).toFixed(2)}`}
                w={{ base: '100%', md: 'auto', '2xl': '100%' }}
                keyStyle={{
                  color: 'custom.blue.600',
                  fontWeight: '500',
                  mr: { base: '0', md: '8px', '2xl': '0' }
                }}
                valueStyle={{
                  color: 'custom.blue.600',
                  mr: { md: '16px', '2xl': 'unset' },
                  fontWeight: '500'
                }}
              />
            )}
            <DataRow
              title="Total"
              value={`$${(totalAmount / 100).toFixed(2)}`}
              w={{ base: '100%', md: 'auto', '2xl': '100%' }}
              keyStyle={{
                color: 'custom.blue.600',
                fontWeight: '500',
                mr: { base: '0', md: '8px', '2xl': '0' }
              }}
              valueStyle={{ color: 'custom.blue.600', fontWeight: '500' }}
            />
          </>
        )}
      </Box>
    </Box>
  )
}
