import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { CalendarIcon } from '../../../../assets/Icons/CalendarIcon'
import DataPicker from '../../../Common/DataPicker'

export default function DatePickerModal({
  selectedDate = null,
  cb: onApply = null,
  children = null,
  returnMilliseconds = false,
  minDate = null,
  maxDate = null,
  datepicker = {},
  isDisabled = false,
  skipInitial = false
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [date, setDate] = useState(selectedDate || new Date())
  const [isFirstRun, setIsFirstRun] = useState(skipInitial)

  const handleApply = () => {
    if (returnMilliseconds) onApply(new Date(date).valueOf())
    else onApply(new Date(date))
    return onClose()
  }

  return (
    <>
      {children !== null ? (
        <Button onClick={onOpen} isDisabled={isDisabled} variant="unstyled">
          {children}
        </Button>
      ) : (
        <IconButton
          aria-label="calendar"
          ml="10px"
          isDisabled={isDisabled}
          _focus={false}
          icon={<CalendarIcon />}
          variant="unstyled"
          d="inline-flex"
          alignItems="center"
          onClick={onOpen}
        />
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '365px', '2xl': '460px' }}
        >
          <ModalHeader mb={{ base: '8px', '2xl': '16px' }}>
            Choose a date:
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DataPicker
              cb={setDate}
              selected={date}
              datepicker={{
                ...(!!minDate && { minDate: minDate }),
                ...(!!maxDate && { maxDate: maxDate }),
                ...datepicker
              }}
            />
          </ModalBody>

          <ModalFooter w="100%" p="0">
            <Button
              variant="lightBlue"
              m={{ base: '8px 0 0', '2xl': '16px 0 0' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              variant="blue"
              m={{ base: '8px 0 0', '2xl': '16px 0 0' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              onClick={handleApply}
            >
              Apply
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
