import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import NewMinor from '../NewMinor'
import ChildIcon from '../../../common/Icons/ChildIcon'
import CrosshairIcon from '../../../common/Icons/CrosshairIcon'
import SimplePenIcon from '../../../common/Icons/SimplePenIcon'

export default function MinorItem({ decrementFuturePlayer, elIdx }) {
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext()
  const selectedProfile = values?.selectedProfile
  const waiver = selectedProfile?.waiver
  const minorsFromWaiver = waiver?.minors
  const minorsFromSelectedProfile = selectedProfile?.minors
  const itsNewProfile = values.itsNewProfile
  const minorsArray = itsNewProfile
    ? minorsFromSelectedProfile
    : minorsFromWaiver
  const [editMode, setEditMode] = useState(false)
  const name = minorsArray[elIdx]?.name
  const birthdate = minorsArray[elIdx]?.birthdate
  const [year, month, day] = birthdate.split('-') || []
  const someMinorIsEdit = values.someMinorIsEdit
  const newMinorMode = values.newMinorMode
  const formikMinorsPath = itsNewProfile
    ? 'selectedProfile.minors'
    : 'selectedProfile.waiver.minors'
  const [deleteAnimation, setDeleteAnimation] = useState(false)

  function deleteHandler() {
    setDeleteAnimation(true)
  }

  function editModeChange(state) {
    setFieldValue('someMinorIsEdit', state)
    setFieldTouched('someMinorIsEdit', false)
    setEditMode(state)
  }

  useEffect(() => {
    if (deleteAnimation) {
      setTimeout(() => {
        const newArr = []
        for (let i = 0; i < minorsArray.length; i++) {
          if (elIdx !== i) {
            newArr.push(minorsArray[i])
          }
        }

        setFieldValue(formikMinorsPath, newArr)
        decrementFuturePlayer()
      }, 300)
    }
  }, [deleteAnimation])

  return (
    <>
      <div
        className={`waiver-widget--minor
                ${deleteAnimation ? 'deleting' : ''}
                ${editMode ? 'edit' : ''}`}
      >
        <div className={editMode ? 'show' : 'hide'} id={'edit-minor'}>
          <NewMinor
            editMode={true}
            birthdate={birthdate}
            name={name}
            elIdx={elIdx}
            setEditMode={editModeChange}
          />
        </div>
        <div className={editMode ? 'hide' : 'show'}>
          <span className="waiver-widget--title-text">{name}</span>
          <span className="waiver-widget--text">
            <i>Birthdate:&nbsp;</i>
            {birthdate}
          </span>
          <div className="waiver-widget--minor-icon">
            <ChildIcon />
          </div>
          <div className="waiver-widget--buttons-minor-wrapper">
            <button
              onClick={() => editModeChange(true)}
              disabled={someMinorIsEdit || newMinorMode}
            >
              <SimplePenIcon />
              Edit&nbsp;<i>information</i>
            </button>
            <button
              onClick={deleteHandler}
              disabled={someMinorIsEdit || newMinorMode}
            >
              <CrosshairIcon />
              Delete minor
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
