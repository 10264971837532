import * as yup from 'yup'
import { ValidationError } from 'yup'

yup.addMethod(yup.object, 'allTrue', function (args) {
  const { message, predicate } = args
  return this.test('atMostOne', message, function (list) {
    if (Object.keys(list)?.length !== 0) {
      if (Object.keys(list)?.every((key) => !!list[key])) {
        return true
      } else {
        const errors = Object.keys(list)?.map((key) => {
          if (list[key]) {
            return null
          } // Successful validation, no error
          return new ValidationError(
            `${key} is not checked`,
            list[key],
            `${this.path}.${key}`
          )
        })
        return new ValidationError(errors)
      }
    } else {
      return true
    }
  })
})

export const waiverWidgetValidation = yup.object().shape({
  itsNewProfile: yup.boolean(),
  isReplaceProfileMode: yup.boolean(),
  requiredCheckboxes: yup.object().allTrue('You must accept all checkboxes!'),
  someMinorIsEdit: yup.boolean().test((val) => !val),
  selectedProfile: yup
    .object()
    .nullable()
    .shape({
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      email: yup.string().email().required(),
      birthdate: yup.string().required(),
      minors: yup.array().of(
        yup.object().shape({
          name: yup.string().required(),
          birthdate: yup.string().required()
        })
      )
    }),
  createMode: yup.boolean(),
  createdProfile: yup.object().when('createMode', {
    is: true,
    then: yup.object().shape({
      firstname: yup.string().required(),
      email: yup
        .string()
        .email()
        .required()
        .test((val) => /@(\w{2,})/.test(val)),
      lastname: yup.string().required(),
      birthdate: yup
        .string()
        .required()
        .test((val) => {
          console.log(val)
          if (!val) return false
          return val.includes('-')
        })
    }),
    overwise: yup.object().shape({
      firstname: yup.string(),
      email: yup.string().email(),
      lastname: yup.string(),
      birthdate: yup.string()
    })
  }),
  emptyMinors: yup.boolean().test((val, context) => {
    const itsNewProfile = context.parent.itsNewProfile
    const signedFor = itsNewProfile
      ? context?.parent?.signedFor
      : context?.parent?.selectedProfile?.waiver?.signed_for
    const minors = itsNewProfile
      ? context?.parent?.selectedProfile?.minors
      : context?.parent?.selectedProfile?.waiver?.minors
    if (Array.isArray(minors)) {
      if (signedFor === 'M') {
        return true
      } else {
        return minors.length !== 0
      }
    } else {
      return true
    }
  }),
  newMinorMode: yup.boolean().test((val, context) => {
    const itsNewProfile = context.parent.itsNewProfile
    const signedFor = itsNewProfile
      ? context?.parent?.signedFor
      : context?.parent?.selectedProfile?.waiver?.signed_for
    if (signedFor === 'M') return true
    return !val
  }),
  signaturePhoto: yup
    .mixed()
    .nullable()
    .when('itsNewProfile', {
      is: true,
      then: yup.mixed().required('You must sign waiver!')
    })
    .when('itsNewProfile', {
      is: false,
      then: yup.mixed().when('selectedProfile.waiver.sign', {
        is: null,
        then: yup.mixed().required('You must sign waiver!')
      })
    })
})
