import { Box, Button, Text } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { friendlyTime, friendlyTimeWithTZ } from '../../../libs'
import Preloader from '../Preloader'
import cloneDeep from 'lodash.clonedeep'

export default function AvailableTime({
  cb = null,
  data = [],
  selectedData = '',
  isObjectComplete = false,
  isLoadingTimetable = false,
  experience = null,
  ...rest
}) {
  const slots = data || []
  const cloneAndSortedSlots = useMemo(() => {
    if (slots.length !== 0) {
      const cloned = cloneDeep(slots)
      cloned.sort(function (a, b) {
        return a.start > b.start
      })
      return cloned
    } else {
      return []
    }
  }, [slots])

  const theme = useTheme()
  const customColors = theme.colors.custom
  const [checkedIdx, setCheckedIdx] = useState(-1)
  const [checkedTime, setCheckedTime] = useState(0)
  useEffect(() => {
    if (cb !== null && typeof cb === 'function') {
      cb(checkedTime)
    }
  }, [checkedIdx || checkedTime])

  useEffect(() => {
    setCheckedIdx(-1)
    setCheckedTime(0)
  }, [experience])

  useEffect(() => {
    if (slots.length !== 0) {
      if (cb !== null && typeof cb === 'function') {
        cb(checkedTime)
        setCheckedIdx(-1)
        setCheckedTime(0)
      }
    }
  }, [slots])

  return (
    <Box
      {...rest}
      bg="custom.lightGray"
      border={`1px solid ${customColors.borderBlue}`}
      p="16px"
    >
      {isLoadingTimetable && <Preloader />}
      {!isLoadingTimetable && (
        <Text
          color="custom.black.500"
          as="span"
          fontWeight="500"
          fontSize={{ base: '14px', '2xl': '18px' }}
          mb={{ base: '8px', md: '16px' }}
        >
          {isObjectComplete && isLoadingTimetable === false ? (
            <>
              Available time at{' '}
              {friendlyTimeWithTZ(selectedData * 1000, 'dddd MM.DD.YY')}:<br />
              {slots.length === 0 && (
                <Text color="custom.blue.600">
                  There is no schedule for the selected options.
                </Text>
              )}
            </>
          ) : (
            <>
              Please select a value from the experience field and choose date.
            </>
          )}
        </Text>
      )}
      <Box
        d="grid"
        mt="16px"
        gridTemplateColumns={{ base: '1fr 1fr', md: '1fr 1fr 1fr' }}
        gridColumnGap={{ base: '8px', md: '16px' }}
        gridRowGap={{ base: '8px', md: '16px' }}
      >
        {cloneAndSortedSlots?.map((button, idx) => (
          <Button
            key={'timeCheck' + idx}
            variant={checkedIdx === idx ? 'blue' : 'white'}
            fontSize={{ base: '12px', '2xl': '14px' }}
            fontWeight="500"
            id={`slot-${button.id}`}
            height={{ base: '40px', '2xl': '56px' }}
            disabled={button.locked}
            _hover={button.locked && false}
            onClick={() => {
              if (!button.locked) {
                setCheckedIdx(idx)
                setCheckedTime(button)
              }
            }}
            flexDir="column"
          >
            <Text>{friendlyTime(button.start * 1000, 'hh:mm A')} —</Text>
            <Text>— {friendlyTime(button.end * 1000, 'hh:mm A')}</Text>
          </Button>
        ))}
      </Box>
    </Box>
  )
}
