import LocationListItem from '../LocationListItem'
import { Box } from '@chakra-ui/react'

export default function LocationsList({ data = [] }) {
  return (
    <Box>
      {data
        ?.sort((a, b) => b.id - a.id)
        ?.map((el, idx) => (
          <LocationListItem
            locationId={el.id}
            location={el}
            key={'LocationsList' + 'ff' + idx}
          />
        ))}
    </Box>
  )
}
