import React, { useMemo, useState } from 'react'
import { Box, Button, useTheme } from '@chakra-ui/react'
import { TABLE_COLUMN_VALUES_PARTNERS } from '../../../../../configs'
import TableCreator from '../../../../Common/TableCreator'
import { PlusIcon } from '../../../../../assets/Icons/PlusIcon'
import EditableCell from './EditableCell'
import { TABLE_COLUMNS_SIZES_PARTNERS } from '../../../../../configs/tablesSizes'
import {
  useCreatePartner,
  useDeletePartner,
  useUpdatePartner
} from '../../../../../libs/hooks/usePartners'
import { useQueryClient } from 'react-query'

export default function PartnersTable({ tableData = [] }) {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const [updatedObject, setUpdatedObject] = useState({
    name: '',
    partnerId: ''
  })
  const { mutate: updatePartners } = useUpdatePartner(updatedObject)
  const { mutate: createPartner } = useCreatePartner(updatedObject)
  const { mutate: deletePartner } = useDeletePartner()
  const customsColor = theme.colors.custom
  const [edit, setEdit] = useState({ enabled: false, idCell: null })
  const [newMode, setNewMode] = useState(false)

  function editHandler(state, idCell, cancelNewMode = false) {
    return () => {
      setEdit({ enabled: state, idCell: idCell })
      if (cancelNewMode && newMode) {
        setNewMode(false)
      }
    }
  }

  function isEdit(id) {
    return edit.enabled && edit.idCell === +id
  }

  function saveHandler() {
    return () => {
      if (newMode) {
        createPartner(
          {},
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['partners']).then(() => {
                editHandler(false, null)()
                setNewMode(false)
              })
            }
          }
        )
      } else {
        updatePartners(
          {},
          {
            onSuccess: () => {
              queryClient
                .invalidateQueries(['partners'])
                .then(() => editHandler(false, null)())
            }
          }
        )
      }
    }
  }

  function deleteHandler(partnerId) {
    return () => {
      deletePartner(
        {
          obj: {
            partnerId: partnerId
          }
        },
        {
          onSuccess: () => {
            queryClient
              .invalidateQueries(['partners'])
              .then(() => editHandler(false, null)())
          }
        }
      )
    }
  }

  const data = useMemo(() => {
    if (newMode) {
      editHandler(true, 0)()
      const newObj = {
        name: '',
        coupons: 0,
        used: 0
      }
      return [newObj, ...tableData]
    } else {
      return tableData
    }
  }, [tableData, newMode])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: 0,
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  function createCustomTr() {
    const main = {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      h: '56px',
      bg: 'white'
    }

    if (typeof edit.idCell === 'number') {
      return {
        tr: {
          ...main,
          css: {
            [`tbody > &:nth-of-type(${edit.idCell + 1})`]: {
              background: customsColor.blue[300]
            }
          }
        }
      }
    } else {
      return { tr: { ...main } }
    }
  }

  const customColumnHeader = (
    <Button
      variant="unstyled"
      color={edit.enabled ? 'custom.gray.200' : 'custom.blue.600'}
      fontSize="inherit"
      fontWeight="500"
      display="inline-flex"
      alignItems="center"
      _focus={{ boxShadow: 'none' }}
      disabled={edit.enabled}
      onClick={() => setNewMode(true)}
    >
      <PlusIcon color="inherit" mr="8px" />
      New
    </Button>
  )

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_PARTNERS.map((field) => {
      const fieldObject = {
        Header:
          field.Accessor === 'funcColumn' ? customColumnHeader : field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'name') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (edit && edit.idCell === +row.id) {
            return (
              <EditableCell
                initialValue={row.original.name}
                partnerId={row.original.id}
                cb={setUpdatedObject}
              />
            )
          } else {
            return <>{row.original.name}</>
          }
        }
      }

      if (field.Accessor.toLowerCase() === 'funccolumn') {
        fieldObject['Cell'] = ({ row }, value) => {
          if (isEdit(row.id)) {
            return (
              <Box d="flex" justifyContent="center">
                <Button
                  variant="link"
                  color="custom.blue.600"
                  mr="8px"
                  onClick={saveHandler()}
                >
                  Save
                </Button>
                <Button
                  variant="link"
                  color="custom.red.600"
                  onClick={editHandler(false, null, true)}
                >
                  Cancel
                </Button>
              </Box>
            )
          } else {
            return (
              <Box d="flex" justifyContent="center">
                <Button
                  variant="link"
                  color="custom.blue.600"
                  mr="8px"
                  _disabled={{ color: 'custom.gray.200' }}
                  isDisabled={edit.enabled}
                  onClick={editHandler(true, +row.id)}
                >
                  Edit
                </Button>
                <Button
                  variant="link"
                  color="custom.red.600"
                  _disabled={{ color: 'custom.gray.200' }}
                  isDisabled={edit.enabled}
                  onClick={deleteHandler(row.original.id)}
                >
                  Remove
                </Button>
              </Box>
            )
          }
        }
      }
      return fieldObject
    })
  }, [edit])
  return (
    <Box mb="60px">
      <TableCreator
        data={data}
        columns={columns}
        styles={{ ...tableStyles, ...createCustomTr() }}
        sizesMap={TABLE_COLUMNS_SIZES_PARTNERS}
      />
    </Box>
  )
}
