import { api } from '../index'

export async function newCash({
  amount,
  clientEmail = null,
  comment,
  locationId,
  bookingCode = null,
  giftcardCode = null
}) {
    const { data } = await api.post('/api/cash/', {
      amount,
      ...(clientEmail && { client_email: clientEmail }),
      comment: comment,
      location: locationId,
      booking_code: bookingCode,
      giftcard_code: giftcardCode
    })
    return data
}

export async function getCashList({
  locationId = null,
  page = 1,
  pageSize = 15
}) {
  try {
    const { data } = await api.get(`/api/cash/`, {
      params: {
        location: locationId,
        page,
        page_size: pageSize
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}
