import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import MinorItem from './MinorItem'
import NewMinor from './NewMinor'
import ButtonBlue from '../../common/ButtonBlue'

export default function AddMinor({ toManyMinors }) {
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext()
  const loadedBooking = values?.loadedBooking
  const futurePlayers = values.futurePlayers
  const exists = values.exists
  const players = loadedBooking?.players
  const maxPlayers = loadedBooking?.max_players
  const selectedProfile = values.selectedProfile
  const waiver = selectedProfile?.waiver
  const minorsFromWaiver = waiver?.minors
  const minorsFromSelectedProfile = selectedProfile?.minors
  const itsNewProfile = values.itsNewProfile
  const newMode = values.newMinorMode
  const someMinorIsEdit = values.someMinorIsEdit
  const unassigned = values.unassigned
  const unlimitedSigns = loadedBooking.unlimited_signs
  const minorsArray = itsNewProfile
    ? minorsFromSelectedProfile
    : minorsFromWaiver
  const signedForFromWaiver = selectedProfile?.waiver?.signed_for
  const signedFor = values.signedFor
  const singedForHandler = signedForFromWaiver ? signedForFromWaiver : signedFor
  const emptyMinors =
    errors.hasOwnProperty('emptyMinors') &&
    touched.hasOwnProperty('emptyMinors')

  function setNewModeHandler(val, type) {
    setFieldValue('newMinorMode', val)
    setFieldTouched('newMinorMode', false)
    if (type === 'add') {
      if (futurePlayers === null) {
        setFieldValue('futurePlayers', exists ? +players + 1 : +players + 2)
      }
      if (typeof futurePlayers === 'number') {
        setFieldValue('futurePlayers', futurePlayers + 1)
      }
    }
  }

  useEffect(() => {
    if (
      loadedBooking &&
      !newMode &&
      minorsArray?.length === 0 &&
      singedForHandler !== 'M'
    ) {
      enableNewMode()
      setFieldValue('newMinorMode', true)
    }

    if (singedForHandler === 'M' && newMode) {
      setFieldValue('newMinorMode', false)
      setFieldTouched('newMinorMode', false)
    }
  }, [minorsArray, singedForHandler, newMode, loadedBooking])

  function decrementFuturePlayer() {
    setFieldValue('futurePlayers', futurePlayers - 1)
  }

  const canAddMore =
    +maxPlayers > +futurePlayers || unassigned || unlimitedSigns

  function enableNewMode() {
    if (!newMode && canAddMore) {
      if (touched.hasOwnProperty('newMinorMode')) {
        setFieldTouched('newMinorMode', false)
      }
      setFieldValue('newMinorMode', true)
      setFieldTouched('newMinorMode', false)
    }
  }

  return (
    <div className="waiver-widget--add-minor" id="minors-block">
      <span className="waiver-widget--title-text">Add minor</span>
      {emptyMinors && (
        <span className="waiver-widget--notif error">
          For selected type <i>at least 1</i> minor required.
        </span>
      )}
      {!canAddMore && (
        <span className="waiver-widget--notif error">
          Players limit reached.
        </span>
      )}
      {toManyMinors && (
        <span className="waiver-widget--notif error">
          You cant add all yours minors.
        </span>
      )}
      <span className="waiver-widget--notif">
        <i>Only children</i> should be included in the list of minors
      </span>
      {minorsArray?.length !== 0 &&
        minorsArray?.map((el, idx) => {
          return (
            <MinorItem
              key={'minor' + idx + el.name}
              elIdx={idx}
              decrementFuturePlayer={decrementFuturePlayer}
            />
          )
        })}
      <div className={newMode ? 'show' : 'hide'}>
        <NewMinor setNewMode={setNewModeHandler} id="new-minor" />
      </div>
      <div className={minorsArray?.length !== 0 ? 'show' : 'hide'}>
        <ButtonBlue
          onClick={enableNewMode}
          disabled={newMode || someMinorIsEdit || toManyMinors || !canAddMore}
        >
          Add minor
        </ButtonBlue>
      </div>
    </div>
  )
}
