import DropdownSelect from '../../../../../Common/DropdownSelect'
import { Box } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTheme } from '@emotion/react'
import { useSelector } from 'react-redux'
import { matchMedia1440InState } from '../../../../../../constants'
import {
  clonedElementInState,
  useAttractionsDispatch,
  useAttractionsSelector
} from '../../localState/constants'
import { setPropertyValue } from '../../localState/actions'

export default function DropdownCell({ options = [], prop }) {
  const theme = useTheme()
  const dispatch = useAttractionsDispatch()
  const clonedEl = useAttractionsSelector(clonedElementInState)
  const customColors = theme.colors.custom
  const [dropdownState, setDropdownState] = useState(false)
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const initialState = useMemo(() => {
    let selectedObject = null
    if (options?.length !== 0) {
      options?.forEach((loc) => {
        if (loc.value && loc.value === clonedEl[prop]) {
          selectedObject = {
            value: loc.value,
            label: loc.label
          }
        }
      })
    }
    if (selectedObject === null) {
      selectedObject = { value: '', label: 'Unset' }
    }
    return selectedObject
  }, [options])

  const [input, setInput] = useState(initialState)

  function onChangeDropdownState(state) {
    setDropdownState(state)
  }

  useEffect(() => {
    const obj = {
      property: prop,
      value: input?.value
    }
    dispatch(setPropertyValue(obj))
  }, [input])

  const dropdownTheme = {
    indicatorsContainer: {
      display: isLargerThan1440 ? 'flex' : 'none'
    },
    dropdownIndicator: {
      color: dropdownState
        ? `${customColors.blue[600]}`
        : `${customColors.gray[200]}`
    },
    control: {
      background: dropdownState
        ? 'white'
        : clonedEl[prop]
        ? 'white'
        : 'rgba(255,223,223,0.8)',
      borderColor: dropdownState
        ? `${customColors.blue[600]}`
        : `${customColors.borderBlue}`
    },
    menu: {
      background: 'white'
    }
  }
  return (
    <Box d="flex" justifyContent="center" pos="relative" zIndex="1000">
      <DropdownSelect
        chakraProps={{ width: '85%' }}
        cbState={(state) => onChangeDropdownState(state)}
        selectProps={{
          styles: dropdownTheme,
          main: {
            menuPortalTarget: document.body,
            options: options,
            onChange: (val) => setInput(val),
            value: input
          }
        }}
      />
    </Box>
  )
}
