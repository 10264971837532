import { Box, IconButton, Text } from '@chakra-ui/react'
import { CrosshairIcon } from '../../../assets/Icons/CrosshairIcon'
import { useDispatch } from 'react-redux'
import { deleteElemFromPrebooking } from '../../../actions'

export default function OrderCard({
  location,
  experience,
  date,
  players,
  endTime,
  startTime,
  price,
  idx,
  discounts,
  intervalId
}) {
  const dispatch = useDispatch()

  function correctDiscountName(disc) {
    switch (disc) {
      case 'A':
        return 'AD'
      case 'S':
        return 'SD'
      case 'M':
        return 'MB'
    }
  }

  function deleteElement(idx) {
    return () => dispatch(deleteElemFromPrebooking(idx))
  }

  return (
    <Box
      bg="white"
      p="16px"
      mb={{ base: '2px' }}
      pos="relative"
      d="flex"
      fontSize={{ base: '12px', '2xl': '14px' }}
      flexDir={{ base: 'column', md: 'row' }}
    >
      <IconButton
        aria-label="delete-order-card"
        variant="unstyled"
        color="custom.gray.200"
        pos="absolute"
        right="2px"
        top={{ base: '0', md: '4px' }}
        _focus={false}
        onClick={deleteElement(idx)}
        icon={<CrosshairIcon w="14px" height="14px" />}
      />
      <Box d="flex" flexDir={{ base: 'column', md: 'row' }}>
        <Box
          color="custom.gray.900"
          d="flex"
          fontSize="inherit"
          m={{ base: '0 0 12px', md: '0 16px 0 0' }}
        >
          <Text as="span" mr="16px" lineHeight="16px">
            {location}
          </Text>
          <Text as="span" lineHeight="16px">
            {experience}
          </Text>
        </Box>
        <Box
          color="custom.gray.900"
          d="flex"
          fontSize="inherit"
          mb={{ base: '12px', md: '0' }}
          flexWrap={{ base: 'wrap', md: 'noWrap' }}
        >
          <Text as="span" mr="16px" lineHeight="16px">
            {date}&nbsp;{startTime}&nbsp;—&nbsp;{endTime}
          </Text>
          <Text as="span" mr={{ base: '8px', md: '16px' }} lineHeight="16px">
            {players} player(s)
          </Text>
        </Box>
      </Box>
      <Box d="flex" fontSize="inherit" m={{ base: '0', md: '0 46px 0 auto' }}>
        {Object.keys(discounts)?.map((key, idx) => {
          if (key !== 'base_pice' && discounts[key] > 0) {
            return (
              <Text
                as="span"
                color="custom.red.600"
                mr="16px"
                letterSpacing="-0.4px"
                lineHeight="16px"
                key={'orderDiscount' + key + idx}
              >
                {correctDiscountName(key)} (${(discounts[key] / 100).toFixed(2)})
              </Text>
            )
          }
        })}
        <Text as="span" color="custom.blue.600" lineHeight="16px">
          ${(price / 100).toFixed(2)}
        </Text>
      </Box>
    </Box>
  )
}
