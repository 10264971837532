import TableCreator from '../../../Common/TableCreator'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  COLUMNS_WITH_SORTING_COMMENTS,
  TABLE_COLUMN_VALUES_COMMENTS
} from '../../../../configs'
import { useTheme } from '@chakra-ui/react'
import { TABLE_COLUMNS_SIZES_COMMENTS } from '../../../../configs/tablesSizes'
import { changeFormatAndTimezone } from '../../../../libs'
import { BookingContext } from '../../../../pages/Booking'

export default function CommentsTable({ tableData, tableName = 'comments' }) {
  const theme = useTheme()
  const customsColor = theme.colors.custom

  const [_, isLoading, filters, setFilters] = useContext(BookingContext)
  const [tableFilter, setTableFilter] = useState({
    header: '',
    sort: ''
  })

  useEffect(() => {
    if (
      filters.hasOwnProperty(tableName) &&
      filters[tableName].header !== '' &&
      tableFilter.header === ''
    ) {
      setTableFilter(filters[tableName])
    }
  }, [])

  useEffect(() => {
    if (tableName) {
      setFilters({
        ...filters,
        [tableName]: tableFilter
      })
    }
  }, [tableFilter])

  const data = useMemo(() => {
    let sortedArray = tableData
    if (tableFilter.sort === '') return tableData

    if (tableFilter.header === 'date') {
      if (tableFilter.sort === 'up') {
        return sortedArray.sort((a, b) => {
          return (
            new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()
          )
        })
      }
      if (tableFilter.sort === 'down') {
        return sortedArray.sort((a, b) => {
          return (
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
          )
        })
      }
    }

    if (tableFilter.header === 'name') {
      if (tableFilter.sort === 'up') {
        return sortedArray.sort((a, b) => {
          const nameA = a?.name?.toLowerCase()
          const nameB = b?.name?.toLowerCase()
          return nameA?.localeCompare(nameB)
        })
      }
      if (tableFilter.sort === 'down') {
        return sortedArray.sort((a, b) => {
          const nameA = a?.name?.toLowerCase()
          const nameB = b?.name?.toLowerCase()
          return nameB?.localeCompare(nameA)
        })
      }
    }
  }, [tableData, tableFilter])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '16px 10px',
      letterSpacing: '-0.1px',
      textAlign: 'center'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_COMMENTS.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'date') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return (
            <>
              {changeFormatAndTimezone(
                original?.created_at,
                original?.timezone,
                'hh:mm A - MM.DD.YY'
              )}
            </>
          )
        }
      }
      return fieldObject
    })
  }, [tableFilter])

  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sortingColumns={COLUMNS_WITH_SORTING_COMMENTS}
      sizesMap={TABLE_COLUMNS_SIZES_COMMENTS}
      tableFilter={tableFilter}
      setTableFilter={setTableFilter}
    />
  )
}
