import { api } from '../../../index'

export async function getUsersRoles() {
  try {
    const { data } = await api.get('/api/user-roles/')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function patchUserRole({ roleId, functionId, boolean }) {
  try {
    const { data } = await api.put(`/api/user-roles/${roleId}/`, {
      function: functionId,
      applied: boolean
    })
    return data
  } catch (e) {
    console.log(e)
  }
}
