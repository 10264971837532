import { useState } from 'react'

import ButtonBlue from '../../../common/ButtonBlue'
import ButtonGray from '../../../common/ButtonGray'
import CircularIcon from '../../../common/Icons/CircularIcon'
import Modal from '../../../common/Modal'

export default function AcceptReplaceModal({ cb }) {
  const [isOpen, setIsOpen] = useState(false)

  function toggleHandler(state) {
    return () => setIsOpen(state)
  }

  return (
    <div className="waiver-widget--accept-replace">
      <ButtonGray
        icon={<CircularIcon />}
        onClick={toggleHandler(true)}
        className="button button-gray red"
      >
        Replace profile information
      </ButtonGray>
      <Modal
        isOpen={isOpen}
        setOpen={setIsOpen}
        closeByOutsideClick={true}
        additionalClassName="waiver-widget--accept-replace-modal"
      >
        <span className="waiver-widget--title-text">
          You are changing your account information. Old data{' '}
          <i>will be deleted</i>.
        </span>
        <div className="waiver-widget--buttons-container">
          <ButtonGray onClick={toggleHandler(false)}>Back</ButtonGray>
          <ButtonBlue onClick={cb}>Continue</ButtonBlue>
        </div>
      </Modal>
    </div>
  )
}
