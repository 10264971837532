import {
  BOOKINGS_URL,
  CASH_URL,
  DISCOUNTS_URL,
  GIFT_CARDS_URL,
  PLAYERS_URL,
  SETTINGS_URL,
  STATISTICS_URL
} from '../constants'

export const TABLE_COLUMN_VALUES_BOOKINGS = [
  {
    Header: 'code',
    Accessor: 'code',
    minWidth: '62px',
    width: '10%'
  },
  {
    Header: 'game date',
    Accessor: 'start',
    minWidth: '62px',
    width: '10%'
  },
  {
    Header: 'start time',
    Accessor: 'start_time',
    minWidth: '62px',
    width: '10%'
  },
  {
    Header: 'location',
    Accessor: 'event.location.name',
    minWidth: '62px',
    width: '8%'
  },
  {
    Header: 'experience',
    Accessor: 'eventvariant.name',
    minWidth: '62px',
    width: '8%'
  },
  {
    Header: 'players',
    Accessor: 'players',
    minWidth: '62px',
    width: '10%'
  },
  {
    Header: 'client',
    Accessor: 'order.firstname',
    minWidth: '62px',
    width: '19%'
  },
  {
    Header: 'status',
    Accessor: 'status',
    minWidth: '62px',
    width: '13%'
  },
  {
    Header: '',
    Accessor: 'funcColumn',
    minWidth: '62px',
    width: '2%'
  }
]

export const TABLE_COLUMN_VALUES_BOOKINGS_STATISTICS_PROMO_PAGE = [
  {
    Header: 'code',
    Accessor: 'code',
    minWidth: '62px',
    width: '15%'
  },
  {
    Header: 'game date',
    Accessor: '',
    minWidth: '62px',
    width: '15%'
  },
  {
    Header: 'start time',
    Accessor: 'start_time',
    minWidth: '62px',
    width: '15%'
  },
  {
    Header: 'location',
    Accessor: 'location',
    minWidth: '62px',
    width: '15%'
  },
  {
    Header: 'experience',
    Accessor: 'room',
    minWidth: '62px',
    width: '15%'
  },
  {
    Header: 'status',
    Accessor: 'status',
    minWidth: '62px',
    width: '15%'
  }
]

export const TABLE_COLUMN_VALUES_COMMENTS = [
  {
    Header: 'date',
    Accessor: 'created_at'
  },
  {
    Header: 'name',
    Accessor: 'name'
  },
  {
    Header: 'comment',
    Accessor: 'text'
  }
]

export const TABLE_COLUMN_VALUES_PAYMENTS = [
  {
    Header: 'Id',
    Accessor: 'id'
  },
  {
    Header: 'type',
    Accessor: 'payment_source'
  },
  {
    Header: 'Identifier',
    Accessor: 'cash_payment_id'
  },
  {
    Header: 'total',
    Accessor: 'amount'
  },
  {
    Header: 'fee',
    Accessor: 'fee'
  },
  {
    Header: 'user',
    Accessor: 'funcColumn'
  }
]

export const TABLE_COLUMN_VALUES_WAIVERS = [
  {
    Header: 'full name',
    Accessor: ''
  },
  {
    Header: 'phone',
    Accessor: 'phone'
  },
  {
    Header: 'e-mail',
    Accessor: 'email'
  },
  {
    Header: 'date',
    Accessor: 'sign_time'
  },
  {
    Header: 'Waiver in PDF',
    Accessor: 'doc'
  }
]

export const TABLE_COLUMN_VALUES_PLAYERS = [
  {
    Header: '',
    Accessor: 'func'
  },
  {
    Header: 'Full name',
    Accessor: ''
  },
  {
    Header: 'Phone',
    Accessor: 'phone'
  },
  {
    Header: 'e-mail',
    Accessor: 'email'
  },
  {
    Header: 'DOB',
    Accessor: 'birthdate'
  },
  {
    Header: 'Last Room Played',
    Accessor: 'location_name'
  },
  {
    Header: '',
    Accessor: 'functionalColumn'
  }
]

export const TABLE_COLUMN_VALUES_CASH = [
  {
    Header: 'Booking',
    Accessor: 'booking_code'
  },
  {
    Header: 'Giftcard',
    Accessor: 'giftcard_code'
  },
  {
    Header: 'User',
    Accessor: 'gbase_user'
  },
  {
    Header: 'Date',
    Accessor: 'created'
  },
  {
    Header: 'Client',
    Accessor: 'client_email'
  },
  {
    Header: 'Sum',
    Accessor: 'amount'
  },
  {
    Header: 'Comments',
    Accessor: 'comment'
  },
  {
    Header: '',
    Accessor: 'functionalColumn'
  }
]

export const TABLE_COLUMN_VALUES_LIMITATION = [
  {
    Header: '',
    Accessor: 'location'
  },
  {
    Header: '08 AM',
    time: '08:00',
    Accessor: ''
  },
  {
    Header: '09 AM',
    time: '09:00',
    Accessor: ''
  },
  {
    Header: '10 AM',
    time: '10:00',
    Accessor: ''
  },
  {
    Header: '11 AM',
    time: '11:00',
    Accessor: ''
  },
  {
    Header: '12 AM',
    time: '12:00',
    Accessor: ''
  },
  {
    Header: '01 PM',
    time: '13:00',
    Accessor: ''
  },
  {
    Header: '02 PM',
    time: '14:00',
    Accessor: ''
  },
  {
    Header: '03 PM',
    time: '15:00',
    Accessor: ''
  },
  {
    Header: '04 PM',
    time: '16:00',
    Accessor: ''
  },
  {
    Header: '05 PM',
    time: '17:00',
    Accessor: ''
  },
  {
    Header: '06 PM',
    time: '18:00',
    Accessor: ''
  },
  {
    Header: '07 PM',
    time: '19:00',
    Accessor: ''
  },
  {
    Header: '08 PM',
    time: '20:00',
    Accessor: ''
  },
  {
    Header: '09 PM',
    time: '21:00',
    Accessor: ''
  },
  {
    Header: '10 PM',
    time: '22:00',
    Accessor: ''
  },
  {
    Header: '11 PM',
    time: '23:00',
    Accessor: ''
  },
  {
    Header: '12 PM',
    time: '24:00',
    Accessor: ''
  }
]

export const TABLE_COLUMN_VALUES_BOOKING_CALENDAR = [
  {
    header: '08 AM',
    time: '08:00'
  },
  {
    header: '09 AM',
    time: '09:00'
  },
  {
    header: '10 AM',
    time: '10:00'
  },
  {
    header: '11 AM',
    time: '11:00'
  },
  {
    header: '12 PM',
    time: '12:00'
  },
  {
    header: '01 PM',
    time: '13:00'
  },
  {
    header: '02 PM',
    time: '14:00'
  },
  {
    header: '03 PM',
    time: '15:00'
  },
  {
    header: '04 PM',
    time: '16:00'
  },
  {
    header: '05 PM',
    time: '17:00'
  },
  {
    header: '06 PM',
    time: '18:00'
  },
  {
    header: '07 PM',
    time: '19:00'
  },
  {
    header: '08 PM',
    time: '20:00'
  },
  {
    header: '09 PM',
    time: '21:00'
  },
  {
    header: '10 PM',
    time: '22:00'
  },
  {
    header: '11 PM',
    time: '23:00'
  },
  {
    header: '12 AM',
    time: '24:00'
  },
  {
    header: '01 AM',
    time: '01:00'
  }
]

export const TABLE_COLUMN_VALUES_LIMITATION_GRAPH = [
  {
    header: '08 AM',
    time: '08:00'
  },
  {
    header: '09 AM',
    time: '09:00'
  },
  {
    header: '10 AM',
    time: '10:00'
  },
  {
    header: '11 AM',
    time: '11:00'
  },
  {
    header: '12 PM',
    time: '12:00'
  },
  {
    header: '01 PM',
    time: '13:00'
  },
  {
    header: '02 PM',
    time: '14:00'
  },
  {
    header: '03 PM',
    time: '15:00'
  },
  {
    header: '04 PM',
    time: '16:00'
  },
  {
    header: '05 PM',
    time: '17:00'
  },
  {
    header: '06 PM',
    time: '18:00'
  },
  {
    header: '07 PM',
    time: '19:00'
  },
  {
    header: '08 PM',
    time: '20:00'
  },
  {
    header: '09 PM',
    time: '21:00'
  },
  {
    header: '10 PM',
    time: '22:00'
  },
  {
    header: '11 PM',
    time: '23:00'
  },
  {
    header: '12 AM',
    time: '24:00'
  },

  {
    header: '01 AM',
    time: '01:00'
  }
]

export const TABLE_COLUMN_VALUES_PAYMENT_GATES = [
  {
    Header: 'Provider',
    Accessor: 'payment_provider'
  },
  {
    Header: 'Name',
    Accessor: 'name'
  },
  {
    Header: 'Public key',
    Accessor: 'public'
  },
  {
    Header: 'Fee (%)',
    Accessor: 'fee'
  },
  {
    Header: 'Sales Tax (%)',
    Accessor: 'sales_tax_fee'
  },
  {
    Header: 'Default',
    Accessor: 'is_default'
  },
  {
    Header: '',
    Accessor: 'funcColumn'
  }
]

export const TABLE_COLUMN_VALUES_LOCATIONS = [
  {
    Header: 'Name',
    Accessor: 'name'
  },
  {
    Header: 'Address',
    Accessor: 'address'
  },
  {
    Header: 'Timezone',
    Accessor: 'timezone'
  },
  {
    Header: '',
    Accessor: 'waiver'
  },
  {
    Header: '',
    Accessor: 'funccolumn'
  }
]
export const TABLE_COLUMN_VALUES_CITIES = [
  {
    Header: 'ID',
    Accessor: 'id'
  },
  {
    Header: 'Name',
    Accessor: 'name'
  },
  {
    Header: 'Zip',
    Accessor: 'zip_code'
  },
  {
    Header: '',
    Accessor: 'funccolumn'
  }
]

export const TABLE_COLUMN_VALUES_USERS = [
  {
    Header: 'Full name',
    Accessor: 'name'
  },
  {
    Header: 'E-mail',
    Accessor: 'email'
  },
  {
    Header: 'Phone',
    Accessor: 'phone'
  },
  {
    Header: 'Role',
    Accessor: 'role'
  },
  {
    Header: 'Location',
    Accessor: 'location'
  },
  {
    Header: '',
    Accessor: 'functionalColumn'
  }
]

export const COLUMNS_WITH_SORTING_COMMENTS = ['date', 'name']
export const COLUMNS_WITH_SORTING_PROMO_TABLE = ['location']

export const COLUMNS_WITH_SORTING_PAYMENTS = [
  'type',
  'identifier',
  'total',
  'user'
]
export const COLUMNS_WITH_SORTING_WAIVERS = ['full name', 'date']

export const rowsPerPage = [1, 5, 10, 15, 20, 50, 100]

export const NavigationHeaderMenu = [
  { label: 'bookings', path: BOOKINGS_URL },
  { label: 'discounts', path: DISCOUNTS_URL },
  { label: 'gift cards', path: GIFT_CARDS_URL },
  { label: 'statistics', path: STATISTICS_URL },
  { label: 'settings', path: SETTINGS_URL },
  { label: 'players', path: PLAYERS_URL },
  { label: 'cash', path: CASH_URL }
]

export const sortingColumns = ['location', 'start time']

export const dataRowGridMapOrder = {
  client: {
    row: '1/2',
    column: '1/2'
  },
  email: {
    row: '2/3',
    column: '1/2'
  },
  phone: {
    row: '3/4',
    column: '1/2'
  },
  bookings: {
    row: '1/3',
    column: '2/4'
  },
  resource: {
    row: '3/4',
    column: '2/3'
  },
  total: {
    row: '3/4',
    column: '3/4'
  }
}

export const TABLE_COLUMN_VALUES_PLAYER = [
  {
    Header: 'code',
    Accessor: 'code',
    minWidth: '62px',
    width: '10%'
  },
  {
    Header: 'game date',
    Accessor: 'start',
    minWidth: '62px',
    width: '10%'
  },
  {
    Header: 'start time',
    Accessor: 'start_time',
    minWidth: '62px',
    width: '10%'
  },
  {
    Header: 'location',
    Accessor: 'location',
    minWidth: '62px',
    width: '8%'
  },
  {
    Header: 'Room',
    Accessor: 'room',
    minWidth: '62px',
    width: '8%'
  },
  {
    Header: 'status',
    Accessor: 'status',
    minWidth: '62px',
    width: '14%'
  }
]

export const TABLE_COLUMN_VALUES_PROMOCODES_BEFORE_1440 = [
  {
    Header: 'code',
    Accessor: 'code'
  },
  {
    Header: 'created',
    Accessor: 'created'
  },
  {
    Header: 'details',
    Accessor: 'details'
  },
  {
    Header: 'partner',
    Accessor: 'partner'
  },
  {
    Header: 'status',
    Accessor: 'status'
  },
  {
    Header: '',
    Accessor: 'funcColumn'
  }
]

export const TABLE_COLUMN_VALUES_PROMOCODES_AFTER_1440 = [
  {
    Header: 'code',
    Accessor: 'code'
  },
  {
    Header: 'created',
    Accessor: 'created'
  },
  {
    Header: 'reason',
    Accessor: 'reason'
  },
  {
    Header: 'details',
    Accessor: 'discount_cent'
  },
  {
    Header: 'experience',
    Accessor: 'experienceToShow'
  },
  {
    Header: 'partner',
    Accessor: 'partner'
  },
  {
    Header: 'status',
    Accessor: 'status'
  },
  {
    Header: '',
    Accessor: 'funcColumn'
  }
]

export const TABLE_COLUMN_VALUES_PARTNERS = [
  {
    Header: 'name',
    Accessor: 'name'
  },
  {
    Header: 'coupons',
    Accessor: 'coupons'
  },
  {
    Header: 'used',
    Accessor: 'used'
  },
  {
    Header: '',
    Accessor: 'funcColumn'
  }
]

export const TABLE_COLUMN_VALUES_ATTRACTIONS = [
  {
    Header: 'Name',
    Accessor: 'name'
  },
  {
    Header: 'Interval',
    Accessor: 'interval'
  },
  {
    Header: 'Storylines',
    Accessor: 'max_storylines'
  },
  {
    Header: 'Max ppl',
    Accessor: 'max_players'
  },
  {
    Header: 'Location',
    Accessor: 'location'
  },
  {
    Header: 'Payments',
    Accessor: 'stripe'
  },
  {
    Header: 'Pixel',
    Accessor: 'pixel_id'
  },
  {
    Header: 'GA',
    Accessor: 'google_analytics'
  },
  {
    Header: '',
    Accessor: 'funcColumn'
  }
]

export const TABLE_COLUMN_VALUES_COMBO = [
  {
    Header: 'ID',
    Accessor: 'id'
  },
  {
    Header: 'Name',
    Accessor: 'title'
  },
  {
    Header: 'Price',
    Accessor: 'price'
  },
  {
    Header: '',
    Accessor: 'funcColumn'
  }
]

export const TABLE_COLUMN_VALUES_COMBO_BOOKINGS = {
  mobile: [
    {
      Header: 'Code',
      Accessor: 'combo_code'
    },
    {
      Header: 'Total',
      Accessor: ''
    },
    {
      Header: 'Client',
      Accessor: ''
    }
  ],
  default: [
    {
      Header: 'Code',
      Accessor: 'combo_code'
    },
    {
      Header: 'Adults',
      Accessor: 'players'
    },
    {
      Header: 'Children',
      Accessor: 'special_players'
    },

    {
      Header: 'Uses',
      Accessor: 'number_of_uses'
    },
    {
      Header: 'Total',
      Accessor: ''
    },
    {
      Header: 'Client',
      Accessor: ''
    },
    {
      Header: 'Status',
      Accessor: ''
    }
  ]
}

export const TABLE_COLUMN_VALUES_STATISTICS_QUESTROOM_BEFORE_1440 = [
  {
    Header: 'Sched',
    Accessor: 'sched'
  },
  {
    Header: 'Down',
    Accessor: 'down'
  },
  {
    Header: 'PS',
    Accessor: 'ps'
  },
  {
    Header: 'Played',
    Accessor: 'played'
  },
  {
    Header: 'Waste',
    Accessor: 'waste'
  },
  {
    Header: 'LL, %',
    Accessor: 'll'
  },
  {
    Header: 'Total, $',
    Accessor: 'total'
  },
  {
    Header: 'Avg, $',
    Accessor: 'avg'
  }
]

export const TABLE_COLUMN_VALUES_STATISTICS_QUESTROOM_AFTER_1440 = [
  {
    Header: '2',
    Accessor: '2'
  },
  {
    Header: '3',
    Accessor: '3'
  },
  {
    Header: '4',
    Accessor: '4'
  },
  {
    Header: '5',
    Accessor: '5'
  },
  {
    Header: '6',
    Accessor: '6'
  },
  {
    Header: '7',
    Accessor: '7'
  },
  {
    Header: '8',
    Accessor: '8'
  },
  {
    Header: '9',
    Accessor: '9'
  },
  {
    Header: '10',
    Accessor: '10'
  },
  {
    Header: '11',
    Accessor: '11'
  },
  {
    Header: '12',
    Accessor: '12'
  },
  {
    Header: 'Sched',
    Accessor: 'sched'
  },
  {
    Header: 'Down',
    Accessor: 'down'
  },
  {
    Header: 'PS',
    Accessor: 'ps'
  },
  {
    Header: 'Waste',
    Accessor: 'waste'
  },
  {
    Header: 'Played',
    Accessor: 'played'
  },
  {
    Header: 'LL, %',
    Accessor: 'll'
  },
  {
    Header: 'Total, $',
    Accessor: 'total'
  },
  {
    Header: 'Avg, $',
    Accessor: 'avg'
  }
]

export const TABLE_COLUMN_VALUES_STATISTICS_PARTNER = [
  {
    Header: 'Partner',
    Accessor: 'partner.name'
  },
  {
    Header: 'Total (coupons)',
    Accessor: 'total'
  },
  {
    Header: 'Used (times)',
    Accessor: 'used'
  },
  {
    Header: 'Amount (received $)',
    Accessor: 'amount'
  },
  {
    Header: 'Discount ($)',
    Accessor: 'discount'
  }
]

export const TABLE_COLUMN_VALUES_GIFT_CARDS_BEFORE_1440 = [
  {
    Header: 'Recipient',
    Accessor: 'recipient_name'
  },
  {
    Header: 'Buyer',
    Accessor: 'buyer_name'
  },
  {
    Header: 'Code',
    Accessor: 'code'
  },
  {
    Header: 'Total',
    Accessor: 'total'
  },
  {
    Header: 'Status',
    Accessor: 'status'
  },
  {
    Header: '',
    Accessor: 'funcColumn'
  }
]

export const TABLE_COLUMN_VALUES_GIFT_CARDS_AFTER_1440 = [
  {
    Header: 'Recipient name',
    Accessor: 'recipient_name'
  },
  {
    Header: 'Recipient e-mail',
    Accessor: 'recipient_email'
  },
  {
    Header: 'Buyer',
    Accessor: 'buyer_name'
  },
  {
    Header: 'Buyer e-mail',
    Accessor: 'buyer_email'
  },
  {
    Header: 'Code',
    Accessor: 'code'
  },
  {
    Header: 'Total',
    Accessor: 'total'
  },
  {
    Header: 'Status',
    Accessor: 'status'
  },
  {
    Header: '',
    Accessor: 'funcColumn'
  }
]

export const MAXIMUM_IMAGE_SIZE = 20000000 // in bytes, 20mb
export const MAXIMUM_IMAGES_COUNT = 100
export const breakpoints = ['320', '768', '1440']
export const initialStatus = 'all'

export const valueGameStatus = [
  { key: 'code', label: '' },
  { key: 'eventvariant', label: 'experience', nested: 'name' },
  { key: 'players', label: '' },
  { key: 'special_players', label: '' },
  { key: 'start_time', label: 'start time' },
  { key: 'private_game', label: 'private' },
  { key: 'private_game_price', nested: 'price', label: 'private price' },
  { key: 'price', label: '' },
  { key: 'no_show', label: '' },
  { key: 'need_to_refund', label: '' }
]

export const valueOrder = [
  { key: 'firstname' },
  { key: 'lastname' },
  { key: 'email' },
  { key: 'phone' },
  { key: 'id' }
]

export const valueWaiver = [
  'birthdate',
  'email',
  'firstname',
  'lastname',
  'minor',
  'doc'
]

export const todayOptions = [
  { value: 'upcoming', label: 'Upcoming' },
  { value: 'running', label: 'Running' },
  { value: 'completed', label: 'Completed' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'all', label: 'All dates' },
]

export const settingsOptions = [
  { value: 'account', label: 'Account' },
  { value: 'attractions', label: 'Attractions' },
  { value: 'combo', label: 'Combo' },
  { value: 'users', label: 'Users' },
  { value: 'locations', label: 'Locations' },
  { value: 'cities', label: 'Cities' },
  { value: 'notifications', label: 'Notifications' }
]

export const debounceSearchTime = 500 // 500 ms = 0.5s;
export const debounceSearchWaiversTime = 500 // 500 ms = 0.5s;

export function settingsContainerMap(type) {
  switch (type) {
    case 'notifications': {
      return '1100px'
    }
    default:
      return '1344px'
  }
}

export const switchTypeStatistics = ['games', 'partners']

export const limitationWeekMenu = [
  { label: 'Monday', value: 'monday', weekday: 0 },
  { label: 'Tuesday', value: 'tuesday', weekday: 1 },
  { label: 'Wednesday', value: 'wednesday', weekday: 2 },
  { label: 'Thursday', value: 'thursday', weekday: 3 },
  { label: 'Friday', value: 'friday', weekday: 4 },
  { label: 'Saturday', value: 'saturday', weekday: 5 },
  { label: 'Sunday', value: 'sunday', weekday: 6 }
]
