import PlayersListCard from './PlayersListCard'

export default function PlayersList({
  data = [],
  blacklist = false,
  refetchFunction
}) {
  return data?.map((el, idx) => (
    <PlayersListCard
      email={el.email}
      playerId={el.id}
      key={'playersList' + idx}
      DOB={el.birthdate}
      firstname={el.firstname}
      lastname={el.lastname}
      phone={el.phone}
      lastRoomPlayed={el.event_variant_name}
      itsBlackList={blacklist}
      refetchFunction={refetchFunction}
    />
  ))
}
