import * as yup from 'yup'
import { cleanPhoneNumber } from '../../../libs'

export const editGiftcardModalValidation = yup.object().shape({
  recipientName: yup.string().required(),
  recipientEmail: yup.string().email().required(),
  recipientPhone: yup
    .string()
    .required()
    .test('phone length', 'must be 10 charters length', (val) => {
      if (val && val.length) {
        const value = cleanPhoneNumber(val)
        return /\d{10}/.test(value)
      } else return false
    }),
  buyerName: yup.string().required(),
  buyerEmail: yup.string().email().required(),
  buyerPhone: yup
    .string()
    .required()
    .test('phone length', 'must be 10 charters length', (val) => {
      if (val && val.length) {
        const value = cleanPhoneNumber(val)
        return /\d{10}/.test(value)
      } else return false
    }),
  giftcardDesign: yup.number().required(),
  personalMessage: yup.string().when('addPersonalMessage', {
    is: true,
    then: yup.string().required()
  })
})
