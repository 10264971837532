import InputMask from 'react-input-mask'

export default function PlaceholderInput({
  value = '',
  mask = '',
  onChange = null,
  placeholder,
  error,
  ...rest
}) {
  return (
    <div
      className={`placeholder-input ${
        !!value || value?.length !== 0 ? 'not-empty' : ''
      }`}
    >
      <span className="placeholder-input--label">{placeholder}</span>
      {mask ? (
        <InputMask
          placeholder={placeholder}
          className="placeholder-input--field"
          mask={mask}
          value={value}
          {...(onChange !== null && typeof onChange === 'function'
            ? { onChange }
            : { readOnly: true })}
          {...rest}
        />
      ) : (
        <input
          placeholder={placeholder}
          className="placeholder-input--field"
          value={value}
          {...(onChange !== null && typeof onChange === 'function'
            ? { onChange }
            : { readOnly: true })}
          {...rest}
        />
      )}
    </div>
  )
}
