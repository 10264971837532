import {
  Button,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { useDeleteEmailBaseTemplate } from '../../../../../../libs/hooks/useEmailBaseTemplates'
import { PERMISSION_NOTIFICATIONS } from '../../../../../../constants'
import PermissionRequired from '../../../../../Common/PermissionRequired'

export default function DeleteEmailTemplateModal({ name, emailTemplateId }) {
  const notificationsPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: deleteEmailBaseTemplate } = useDeleteEmailBaseTemplate()

  function deleteHandler() {
    const toSend = {
      emailBaseTemplateId: emailTemplateId
    }
    deleteEmailBaseTemplate(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['emailBaseTemplates'])
          onClose()
        }
      }
    )
  }

  return (
    <>
      <IconButton
        aria-label="delete-gate"
        color="custom.gray.200"
        variant="funcColumnIconButtons"
        onClick={onOpen}
        isDisabled={!notificationsPermission}
        icon={<CrosshairIcon w="inherit" h="inherit" />}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="34px">Delete {name}?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter
            d="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="8px"
          >
            <Button w="100%" variant="lightBlue" onClick={onClose}>
              Cancel
            </Button>
            <Button w="100%" variant="red" onClick={deleteHandler}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
