import { useMutation, useQuery } from 'react-query'

import {
  createWaivercheckouts,
  deleteWaivercheckout,
  getWaivercheckout,
  getWaivercheckouts,
  patchWaivercheckout
} from '../../api/Settings/location/waiver'

export function useWaiverscheckouts(bookingId, queryOption = {}) {
  return useQuery(['waiverscheckouts'], () => getWaivercheckouts(), {
    ...queryOption
  })
}

export function useWaiverscheckout(waivercheckoutID, queryOption = {}) {
  return useQuery(
    ['waiverscheckouts', +waivercheckoutID],
    () => getWaivercheckout(waivercheckoutID),
    {
      ...queryOption
    }
  )
}

export function useCreateWaivercheckout(options = {}) {
  return useMutation(({ obj }) => createWaivercheckouts(obj), {
    ...options
  })
}

export function usePatchWaivercheckout(options = {}) {
  return useMutation(({ obj }) => patchWaivercheckout(obj), {
    ...options
  })
}

export function useDeleteWaivercheckout(options = {}) {
  return useMutation(({ obj }) => deleteWaivercheckout(obj), {
    ...options
  })
}
