import { Box, Text } from '@chakra-ui/react'

export default function LimitationsLegend() {
  const legendData = [
    {
      label: '∞',
      color: 'rgba(205,227,252,1)'
    },
    {
      label: '400%',
      color: 'rgba(59, 143, 244, 1)'
    },
    {
      label: '300%',
      color: 'rgba(80, 155, 245, 1)'
    },
    {
      label: '200%',
      color: 'rgba(109, 172, 247, 1)'
    },
    {
      label: '100%',
      color: 'rgba(145, 195, 249, 1)'
    }
  ]
  return (
    <Box
      bg="white"
      d="flex"
      w="100%"
      h="40px"
      alignItems="center"
      p="0 16px"
      mb="12px"
    >
      {legendData?.map(({ label, color }, idx) => {
        return (
          <Box
            d="grid"
            gridTemplateColumns="1fr 1fr"
            gridGap="8px"
            mr="16px"
            key={idx + 'LimitationsLegend' + idx}
            alignItems="center"
          >
            <Box
              bg={color}
              borderRadius="50%"
              w="14px"
              h="14px"
              justifySelf="end"
            />
            <Text color="custom.gray.300" fontSize="14px">
              {label}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}
