import {
  Box,
  Button,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { loadStripe } from '@stripe/stripe-js'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import InputMask from 'react-input-mask'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import GiftCardsCard from '../../GiftCardsCard'
import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../assets/Icons/DisketteIcon'
import { PlusIcon } from '../../../../assets/Icons/PlusIcon'
import { correctionPhoneNumber, friendlyTimeWithTZ } from '../../../../libs'
import { useCompany } from '../../../../libs/hooks/useCompany'
import {
  useCreateGiftCard,
  useGiftCardDesigns
} from '../../../../libs/hooks/useGiftCards'
import { useDefaultStripe } from '../../../../libs/hooks/useStripe'
import { newGiftcardModalValidation } from '../../../../configs/validation/newGiftCardModal'
import {
  PERMISSION_GIFTCARDS,
  PHONE_INPUT_MASK,
  userInState
} from '../../../../constants'
import DatePickerModal from '../../../Booking/Modals/DatePickerModal'
import CustomCheckbox from '../../../Common/CustomCheckbox'
import CustomWarningToast from '../../../Common/CustomWarningToast'
import FormikInput from '../../../Common/FormikComponents/FormikInput'
import FormikPayments from '../../../Common/FormikComponents/FormikPayments'
import FormikTwoStyledInput from '../../../Common/FormikComponents/FormikTwoStyledInput'
import PermissionRequired from '../../../Common/PermissionRequired'

export default function NewGiftCardModal() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const user = useSelector(userInState)
  const companyId = user?.company
  const { data: company = {} } = useCompany(companyId, {
    enabled: !!companyId
  })

  const giftcardsPermission = PermissionRequired(PERMISSION_GIFTCARDS, true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: addGiftcard } = useCreateGiftCard()
  const { data: { results: defaultStripe = [] } = {} } = useDefaultStripe()
  const { data: { results: cardsArray = [] } = {} } = useGiftCardDesigns({
    onSuccess: setInitialCardDesign
  })
  const [stripePromise, setStripePromise] = useState({})
  const defaultPublicKey = defaultStripe ? defaultStripe[0]?.public : null

  function setInitialCardDesign(data) {
    const { results: cardsArray = [] } = data
    if (cardsArray.length > 0) {
      setFieldValue('giftcardDesign', cardsArray[0]?.id)
    }
  }

  const errorOnCreate = {
    id: 'newGiftCard-error-toast',
    text: 'Something went wrong.'
  }

  const formik = useFormik({
    initialValues: {
      maxGiftCardValue: company?.max_giftcard_value,
      minGiftCardValue: company?.min_giftcard_value,
      recipientName: '',
      recipientEmail: '',
      recipientPhone: '',
      buyerName: '',
      buyerEmail: '',
      buyerPhone: '',
      sendEmailOn: false,
      deliverDate: '',
      deliverDateText: '',
      addPersonalMessage: false,
      personalMessage: '',
      giftcard: '',
      giftcardDesign: '',
      cardValue: '',
      payData: {
        type: '',
        transaction: '',
        stripeToken: '',
        location: null
      }
    },
    validationSchema: newGiftcardModalValidation,
    enableReinitialize: true,
    onSubmit: submitHandler
  })

  const formikValues = formik.values
  const formikHandleSubmit = formik.handleSubmit
  const setFieldValue = formik.setFieldValue
  const paymentTypeIsStripe = formikValues?.payData?.type === 'stripe'

  function generateWarningToast({ text, id, incomingText, msgObject }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 4000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={incomingText ? incomingText : text}
              close={toast.closeAll}
              msgObjects={msgObject}
            />
          )
        })
      }
    }
  }

  useEffect(() => {
    if (defaultPublicKey) {
      const stripePromise = loadStripe(defaultPublicKey)
      setStripePromise(stripePromise)
    }
  }, [defaultPublicKey])

  function setDateHandler() {
    return (value) => {
      setFieldValue('deliverDate', value)
      setFieldValue('deliverDateText', friendlyTimeWithTZ(value, 'MM.DD.YY'))
    }
  }

  function giftCardClickHandler(card) {
    setFieldValue('giftcardDesign', card.id)
  }

  useEffect(() => {
    if (!formikValues.sendEmailOn) {
      setFieldValue('deliverDateText', '')
      setFieldValue('personalMessage', '')
      setFieldValue('addPersonalMessage', false)
      setFieldValue('deliverDate', '')
    }
  }, [formikValues.sendEmailOn])

  useEffect(() => {
    if (!formikValues.addPersonalMessage) {
      setFieldValue('personalMessage', '')
    }
  }, [formikValues.addPersonalMessage])

  const giftCards = useMemo(() => {
    return cardsArray.map((card) => (
      <GiftCardsCard
        key={card.id}
        card={card}
        isChecked={formikValues.giftcardDesign === card.id}
        onCLick={giftCardClickHandler}
      />
    ))
  }, [formikValues.giftcardDesign, cardsArray])

  function submitHandler(values) {
    const { type: payment_source } = values.payData

    const payData = {
      payment_source,
      amount: +(values.cardValue * 100)
    }

    switch (payment_source) {
      case 'cash':
        payData.location_id = values.payData.location.value
        break
      case 'offline':
        payData.cash_payment_id = values.payData.transaction
        break
      case 'stripe':
        payData.payment_token = values.payData.stripeToken
        break

      default:
        break
    }

    const toSend = {
      ...values,
      recipientPhone: correctionPhoneNumber(values.recipientPhone, 'send'),
      buyerPhone: correctionPhoneNumber(values.buyerPhone, 'send'),
      ...(values.sendEmailOn && {
        deliverDate: values.deliverDate
          ? Math.round(values.deliverDate / 1000)
          : ''
      }),
      payData
    }

    addGiftcard(
      { obj: toSend },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['giftcards'])
          onClose()
        },
        onError: (err) => {
          const data = err?.response?.data
          generateWarningToast({ ...errorOnCreate, incomingText: data })()
        }
      }
    )
  }

  return (
    <>
      <Button
        variant="blue"
        w={{ base: '100%', md: '160px' }}
        ml={{ md: '16px' }}
        onClick={onOpen}
        isDisabled={!giftcardsPermission}
        fontSize={{ base: '12px', '2xl': '14px' }}
      >
        <PlusIcon mr="8px" />
        New giftcard
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
        >
          <FormikProvider value={formik}>
            <>
              <ModalHeader mb="34px" d="flex">
                New giftcard
                <Box
                  ml="auto"
                  d={{ base: 'none', md: 'grid' }}
                  gridTemplateColumns="1fr 1fr"
                  gridColumnGap="8px"
                  fontSize={{ base: '12px' }}
                >
                  {!paymentTypeIsStripe && (
                    <>
                      <Button
                        w="100%"
                        variant="blue"
                        fontSize="inherit"
                        onClick={formikHandleSubmit}
                      >
                        <DisketteIcon mr="8px" />
                        Save
                      </Button>
                      <Button
                        w="100%"
                        variant="red"
                        fontSize="inherit"
                        onClick={onClose}
                      >
                        <CrosshairIcon mr="8px" />
                        Cancel
                      </Button>
                    </>
                  )}
                </Box>
              </ModalHeader>
              <ModalCloseButton d={{ md: 'none' }} />
              <ModalBody
                fontSize={{ base: '12px' }}
                d={{ md: 'grid' }}
                gridTemplateColumns={{ md: 'repeat(3, 1fr)' }}
                gridColumnGap={{ md: '16px' }}
              >
                <FormikTwoStyledInput
                  id="recipientName"
                  name="recipientName"
                  placeholder="Recipient Name"
                  mb={{ base: '8px', md: '16px' }}
                />
                <FormikTwoStyledInput
                  id="recipientEmail"
                  name="recipientEmail"
                  placeholder="Recipient Email"
                  mb={{ base: '8px', md: '16px' }}
                />
                <FormikTwoStyledInput
                  id="recipientPhone"
                  name="recipientPhone"
                  placeholder="Recipient Phone"
                  mb={{ base: '8px', md: '16px' }}
                  input={{
                    as: InputMask,
                    mask: PHONE_INPUT_MASK
                  }}
                />

                <FormikTwoStyledInput
                  id="buyerName"
                  name="buyerName"
                  placeholder="Buyer Name"
                  mb={{ base: '8px', md: '16px' }}
                />
                <FormikTwoStyledInput
                  id="buyerEmail"
                  name="buyerEmail"
                  placeholder="Buyer Email"
                  mb={{ base: '8px', md: '16px' }}
                />
                <FormikTwoStyledInput
                  id="buyerPhone"
                  name="buyerPhone"
                  placeholder="Buyer Phone"
                  mb={{ base: '8px', md: '16px' }}
                  input={{
                    as: InputMask,
                    mask: PHONE_INPUT_MASK
                  }}
                />
                <Box gridColumn={{ md: '1/4' }}>
                  <CustomCheckbox
                    mb={{ base: '8px', md: '16px' }}
                    initialState={formikValues.sendEmailOn}
                    cb={(value) => setFieldValue('sendEmailOn', value)}
                  >
                    Send email on (optional)
                  </CustomCheckbox>
                  <InputGroup>
                    <FormikTwoStyledInput
                      id="deliverDateText"
                      name="deliverDateText"
                      placeholder="Pick a date when the
                                                                      E-Gift Certificate should be delivered."
                      isDisabled={true}
                      mb={{ base: '8px', md: '16px' }}
                    />
                    <InputRightElement h="56px">
                      {formikValues.sendEmailOn && (
                        <DatePickerModal
                          cb={setDateHandler(formikValues.sendEmailOn)}
                          returnMilliseconds={true}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </ModalBody>
              <CustomCheckbox
                mb={{ base: '8px', md: '16px' }}
                isDisabled={!formikValues.sendEmailOn}
                initialState={formikValues.addPersonalMessage}
                cb={(value) => setFieldValue('addPersonalMessage', value)}
              >
                Add personal message (optional)
              </CustomCheckbox>
              <FormikTwoStyledInput
                id="personalMessage"
                name="personalMessage"
                placeholder="Personal message"
                isDisabled={
                  !formikValues.addPersonalMessage || !formikValues.sendEmailOn
                }
                mb={{ base: '8px', md: '16px' }}
              />
              <Text
                as="span"
                fontSize={{ base: '14px', '2xl': '18px' }}
                fontWeight="500"
                color="custom.black.500"
                mb={{ base: '8px', '2xl': '16px' }}
              >
                Card design
              </Text>
              <SimpleGrid
                columns={{ base: 1, md: 3 }}
                spacing="16px"
                mb={{ base: '8px', md: '16px' }}
              >
                {giftCards}
              </SimpleGrid>
              <Text
                as="span"
                fontSize={{ base: '14px', '2xl': '18px' }}
                fontWeight="500"
                color="custom.black.500"
                mb={{ base: '8px', '2xl': '16px' }}
              >
                Payment information
              </Text>
              <Box>
                <Box gridColumn={{ base: '1/3' }}>
                  <Text
                    fontSize={{ base: '12px', '2xl': '14px' }}
                    color="custom.gray.300"
                    mb="8px"
                  >
                    Card value (
                    {formikValues.minGiftCardValue +
                      '$ -' +
                      formikValues.maxGiftCardValue +
                      '$'}
                    ):
                  </Text>
                  <FormikInput
                    id="cardValue"
                    name="cardValue"
                    mb={{ base: '8px', md: '16px' }}
                    type="number"
                  />
                </Box>
                <Box gridColumn={{ base: '1/2', md: '1/3' }}>
                  <Text
                    fontSize={{ base: '12px', '2xl': '14px' }}
                    color="custom.gray.300"
                    mb="8px"
                  >
                    Payment method:
                  </Text>
                  <FormikPayments
                    id="payData.type"
                    name="payData.type"
                    stripePromise={stripePromise}
                    includeLabel={false}
                  />
                  {paymentTypeIsStripe && (
                    <Button w="100%" variant="red" onClick={onClose}>
                      <CrosshairIcon mr="8px" />
                      Cancel
                    </Button>
                  )}
                </Box>
              </Box>
              {!paymentTypeIsStripe && (
                <>
                  <ModalFooter
                    d={{ base: 'grid', md: 'none' }}
                    gridTemplateColumns="1fr 1fr"
                    gridColumnGap="8px"
                  >
                    <Button
                      w="100%"
                      variant="blue"
                      onClick={formikHandleSubmit}
                    >
                      <DisketteIcon mr="8px" />
                      Save
                    </Button>
                    <Button w="100%" variant="red" onClick={onClose}>
                      <CrosshairIcon mr="8px" />
                      Cancel
                    </Button>
                  </ModalFooter>
                </>
              )}
            </>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  )
}
