import * as yup from 'yup'

import { convertAmPmToSeconds } from '../../../libs'

export const editLimitationModal = yup.object().shape({
  start: yup
    .string()
    .test('minutes', 'Must be equal or less than 59', (val, object) => {
      if (!val || val?.includes('_')) return false
      const startTimeAmPm = object.parent.startTimeAmPm
      const seconds = convertAmPmToSeconds(val + startTimeAmPm)
      if ((seconds > 7200) & (seconds < 28800)) return false
      const [_, res] = val.split(':') || [null]
      if (res === null) return false
      return +res <= 59
    })
    .required(),
  end: yup
    .string()
    .test('minutes', 'Must be equal or less than 59', (val, object) => {
      if (!val || val?.includes('_')) return false
      const endTimeAmPm = object.parent.endTimeAmPm
      const [hours, res] = val.split(':') || [null]
      let seconds = convertAmPmToSeconds(val + endTimeAmPm)
      if (seconds > 7200 && seconds < 28800) return false
      if (hours < 2 && endTimeAmPm === 'AM') seconds = 86400 + seconds
      if (seconds > 93600) return false
      if (res === null) return false
      return +res <= 59
    })
    .required(),
  diff: yup.number().test('diff', 'Must be equal or greater than 30', (val) => {
    if (val) {
      return val >= 30 * 60
    } else return false
  })
})
