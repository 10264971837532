import { useMutation, useQuery } from 'react-query'

import {
  createWaiver,
  deleteWaiver,
  getWaiver,
  getWaivers,
  patchWaiver
} from '../../api/Settings/location/waiver'

export function useWaivers(queryOption = {}) {
  return useQuery(['waivers'], () => getWaivers(), {
    ...queryOption
  })
}

export function useWaiver(waiverId, queryOption = {}) {
  return useQuery(['waiver', +waiverId], () => getWaiver(waiverId), {
    ...queryOption
  })
}

export function usePatchWaiver(options = {}) {
  return useMutation(({ obj }) => patchWaiver(obj), {
    ...options
  })
}

export function useDeleteWaiver(options = {}) {
  return useMutation(({ obj }) => deleteWaiver(obj), {
    ...options
  })
}

export function useCreateWaiver(options = {}) {
  return useMutation(({ obj }) => createWaiver(obj), {
    ...options
  })
}
