import { useField } from 'formik'
import InputMask from 'react-input-mask'

export default function FormikInputMask({
  name,
  placeholder,
  isDisabled,
  inputRef,
  ...rest
}) {
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  return (
    <InputMask
      className="formik-input"
      {...field}
      {...rest}
      ref={inputRef}
      placeholder={placeholder}
      disabled={isDisabled}
    />
  )
}
