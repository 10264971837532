import { createIcon } from '@chakra-ui/icons'

export const StatisticsIcon = createIcon({
  displayName: 'StatisticsIcon',
  viewBox: '0 0 12 12',
  boxSize: '12',
  path: (
    <g fill="currentColor">
      <path d="M3.3 12H0V4H3.3V12ZM7.65 0H4.35V12H7.65V0ZM12 5.33333H8.7V12H12V5.33333Z" />
    </g>
  )
})
