import { createIcon } from '@chakra-ui/icons'

export const CopyIcon = createIcon({
  displayName: 'CopyIcon ',
  viewBox: '0 0 24 24',
  boxSize: '24',
  path: [
    <g fill="currentColor" key={'CopyIconIcon1'}>
      <circle cx="12" cy="12" r="12" fillOpacity="0.5" />
    </g>,
    <g fill="currentColor" key={'CopyIconIcon2'}>
      <path
        d="M13.7503 5.58334H7.33366C6.69199 5.58334 6.16699 6.10834 6.16699 6.75001V14.3333C6.16699 14.6542 6.42949 14.9167 6.75033 14.9167C7.07116 14.9167 7.33366 14.6542 7.33366 14.3333V7.33334C7.33366 7.01251 7.59616 6.75001 7.91699 6.75001H13.7503C14.0712 6.75001 14.3337 6.48751 14.3337 6.16668C14.3337 5.84584 14.0712 5.58334 13.7503 5.58334ZM16.0837 7.91668H9.66699C9.02533 7.91668 8.50033 8.44168 8.50033 9.08334V17.25C8.50033 17.8917 9.02533 18.4167 9.66699 18.4167H16.0837C16.7253 18.4167 17.2503 17.8917 17.2503 17.25V9.08334C17.2503 8.44168 16.7253 7.91668 16.0837 7.91668ZM15.5003 17.25H10.2503C9.92949 17.25 9.66699 16.9875 9.66699 16.6667V9.66668C9.66699 9.34584 9.92949 9.08334 10.2503 9.08334H15.5003C15.8212 9.08334 16.0837 9.34584 16.0837 9.66668V16.6667C16.0837 16.9875 15.8212 17.25 15.5003 17.25Z"
        fill="white"
      />
    </g>
  ]
})
