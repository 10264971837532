import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react'
import { ArrowIcon } from '../../../../../../../../assets/Icons/ArrowIcon'
import { useTheme } from '@emotion/react'
import { cloneElement } from 'react'

export default function BasePricingAccordion({
  children = [],
  allowToggle = true,
  defaultIndex = [0],
  styles = {},
  isDisabled = false,
  needProps = false,
  ...rest
}) {
  const [header = 'header', bodyContent = 'body'] = Array.isArray(children)
    ? children
    : [children, '']
  const { button = {} } = styles
  const theme = useTheme()
  const customColors = theme.colors.custom

  return (
    <Accordion
      defaultIndex={defaultIndex}
      allowToggle={allowToggle}
      variant="basePricing"
      {...rest}
    >
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              mb={'unset'}
              {...button}
              border="1px solid transparent"
              _expanded={{ border: `1px solid ${customColors.borderBlue}` }}
            >
              <Box flex="1" textAlign="left">
                {needProps
                  ? cloneElement(header, { isExpanded: isExpanded })
                  : cloneElement(header)}
              </Box>
              <ArrowIcon
                w="12px"
                height="12px"
                transition="all 0.2s ease-in"
                transform={isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'}
                color={isExpanded ? 'custom.blue.600' : 'custom.gray.200'}
              />
            </AccordionButton>
            <AccordionPanel
              p="0"
              border={`1px solid ${customColors.borderBlue}`}
              borderTop="none"
            >
              {bodyContent}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
