import { Box, Text, useTheme } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { matchMedia1440InState } from '../../../../../constants'
import AddLimitationModal from '../../../Modals/AddLimitationModal'

export default function LimitationsTableHeader({
  columns = [],
  location = 'test',
  day = '',
  locationId,
  ...rest
}) {
  const theme = useTheme()
  const onlyDigits = /\d\d/
  const customsColor = theme.colors.custom
  const isLargerThan1440 = useSelector(matchMedia1440InState)

  return (
    <Box {...rest} borderBottom={`1px solid ${customsColor.lightGray}`}>
      <Box
        d="grid"
        gridTemplateColumns="3fr 1fr"
        p="0 24px"
        alignItems="center"
        bg="white"
        _before={{
          content: `""`,
          position: 'absolute',
          height: '50%',
          right: 0,
          bottom: 0,
          width: '0.5px',
          background: customsColor.lightGray
        }}
        _after={{
          content: `""`,
          position: 'absolute',
          height: '50%',
          left: 0,
          bottom: 0,
          width: '0.5px',
          background: customsColor.lightGray
        }}
      >
        <Text fontSize="14px" fontWeight="500" color="custom.blue.600">
          {location}
        </Text>
        <AddLimitationModal
          location={location}
          day={day}
          locationId={locationId}
        />
      </Box>
      {columns.length !== 0 &&
        columns?.map((value, idx) => {
          const [digits] = value.header.match(onlyDigits) || [null]
          return (
            <Box
              key={'LimitationsTableHeader' + idx}
              d="flex"
              justifyContent="center"
              alignItems="center"
              pos="relative"
              bg="white"
              fontSize="12px"
              color="custom.gray.300"
              _before={{
                content: `""`,
                position: 'absolute',
                height: '50%',
                right: 0,
                bottom: 0,
                width: '0.5px',
                background: customsColor.lightGray
              }}
              _after={{
                content: `""`,
                position: 'absolute',
                height: '50%',
                left: 0,
                bottom: 0,
                width: '0.5px',
                background: customsColor.lightGray
              }}
            >
              {isLargerThan1440 ? value.header : digits}
            </Box>
          )
        })}
    </Box>
  )
}
