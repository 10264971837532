import DiscountsList from './DiscountsList'
import DiscountAssignedList from './DiscountAssignedList'
import Holidays from '../../../Common/Holidays'
import { useDiscounts } from '../../../../libs/hooks/useDiscounts'
import { useMemo } from 'react'

export default function DiscountsTab() {
  const { data: discounts = [], isRefetching: discountsRefetching } =
    useDiscounts()
  const arraysWithEventvariantsByType = useMemo(() => {
    const obj = {}
    if (discounts.length !== 0) {
      discounts.forEach((discount) => {
        obj[discount.discount_type] = {
          discountId: discount?.id,
          eventvariants: discount?.event_variants
            ? discount?.event_variants
            : [],
          effective: discount?.effective,
          daysToActivate: discount?.days_to_activate,
          isAdjourned: discount?.is_adjourned
        }
      })
    }
    return obj
  }, [discounts])
  return (
    <>
      <DiscountsList discounts={discounts} />
      <DiscountAssignedList
        discounts={discounts}
        arraysWithEventvariantsByType={arraysWithEventvariantsByType}
        discountsRefetching={discountsRefetching}
      />
      <Holidays />
    </>
  )
}
