import { Box, Button, Text } from '@chakra-ui/react'
import { ArrowIcon } from '../../../../../assets/Icons/ArrowIcon'
import BasePricing from './BasePricing'
import Schedule from './Schedule'
import Holidays from '../../../../Common/Holidays'
import ExperienceName from './ExperienceName'
import NewAttractionExperienceModal from './Modals/NewAttractionExperienceModal'
import { useHistory, useParams } from 'react-router-dom'
import { matchMedia768InState, SETTINGS_URL } from '../../../../../constants'
import { useEffect, useMemo, useState } from 'react'
import ButtonsToggle from '../../../../Common/ButtonsToggle'
import { useAttractionsExperience } from '../../../../../libs/hooks/useAttractionsExperience'
import { useLocations } from '../../../../../libs/hooks/useLocations'
import { useTheme } from '@emotion/react'
import { useSelector } from 'react-redux'
import PrivateGamePricing from './PrivateGamePricing'

export default function AttractionsExperience() {
  const { id } = useParams()
  const theme = useTheme()
  const customColors = theme.colors.custom
  const history = useHistory()
  const isLargerThan768 = useSelector(matchMedia768InState)

  const [selectedExperience, setSelectedExperience] = useState(0)

  const { data: { results: locations = [] } = {} } = useLocations()
  const { data: attractions = [] } = useAttractionsExperience(id)

  const buttonsPrepare = useMemo(() => {
    if (attractions?.length !== 0) {
      return attractions?.map((el) => el?.name)
    } else {
      return []
    }
  }, [attractions])

  const eventLength = attractions?.length
  const attraction = attractions[selectedExperience]

  const {
    event_name: name,
    min_players: minPlayers,
    max_players: maxPlayers,
    id: eventVariantId,
    location_id: eventVariantLocationId
  } = attraction || {}

  const location = useMemo(() => {
    let selectedLocation = {}
    for (let i = 0; i < locations.length; i++) {
      if (locations[i].id === eventVariantLocationId) {
        selectedLocation = locations[i]
        break
      }
    }
    return selectedLocation
  }, [eventVariantLocationId, locations])

  useEffect(() => {
    if (attractions.length === 1) {
      setSelectedExperience(0)
    }
  }, [attractions.length])

  function goTo(path) {
    return () => {
      history.push(path)
    }
  }

  const memoSchedule = useMemo(() => {
    if (Object.keys(location).length !== 0 && !!eventVariantId) {
      return <Schedule eventVariantId={eventVariantId} location={location} />
    }
  }, [location, eventVariantId])
  return (
    <Box
      p={{ base: '24px 16px', md: '48px 24px', '2xl': '48px' }}
      maxW={{ base: 'unset', md: '720px', '2xl': '1100px' }}
      m="0 auto"
    >
      <Box d="flex" flexDir={{ base: 'column', md: 'row' }}>
        <Button
          variant="ghost"
          fontFamily="inherit"
          p="0"
          w={{ md: '100%', '2xl': '50%' }}
          justifyContent="start"
          mb="16px"
          onClick={goTo(SETTINGS_URL)}
        >
          <Text
            as={'h1'}
            fontSize={{ base: '24px', '2xl': '32px' }}
            fontWeight={{ base: '600' }}
            color="custom.black.500"
            lineHeight="164%"
          >
            <ArrowIcon
              w="auto"
              height="16px"
              mr="16px"
              mb="2px"
              color="custom.gray.200"
            />
            {name ? name : 'Create new experience'}
          </Text>
        </Button>
        <NewAttractionExperienceModal
          eventId={id}
          locationId={eventVariantLocationId}
          experiencesLength={eventLength}
        />
      </Box>
      {eventLength > 0 && (
        <>
          {eventLength > 1 && (
            <Box>
              <ButtonsToggle
                data={buttonsPrepare}
                buttonsId="experienceButtons"
                initialState={selectedExperience}
                cb={setSelectedExperience}
                returnIndex={true}
                gridTemplateColumns={{
                  base: 'repeat(3, 1fr)',
                  md: `repeat(${eventLength}, 1fr)`
                }}
                gridTemplateRows={{
                  base: `repeat(${Math.ceil(eventLength / 3)}, 1fr)`,
                  md: 'initial'
                }}
                gridRowGap={{ base: '2px', md: 'unset' }}
                w="100%"
                mb="20px"
                maxW="100%"
                {...(!isLargerThan768 && {
                  css: {
                    '> button:nth-of-type(4n+0)': {
                      borderLeft:
                        (selectedExperience + 1) % 4 === 0
                          ? 'initial'
                          : `1px solid ${customColors.borderBlue}`
                    }
                  }
                })}
              />
            </Box>
          )}
          <ExperienceName
            eventId={id}
            selectedExperience={selectedExperience}
          />
          <BasePricing
            eventVariantId={eventVariantId}
            minPlayersLimitation={minPlayers}
            maxPlayersLimitation={maxPlayers}
          />
          <PrivateGamePricing
            eventVariantId={eventVariantId}
            minPlayersLimitation={minPlayers}
            maxPlayersLimitation={maxPlayers}
          />
          {memoSchedule}
          <Holidays />
        </>
      )}
    </Box>
  )
}
