import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react'
import React, { cloneElement, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { rowsPerPage } from '../../../configs'
import { matchMedia768InState } from '../../../constants'

export default function WithPagination2({
  state,
  setState,
  actualLength,
  children = null,
  totalCountItems = '',
  isLoading = false,
  id = '',
  ...rest
}) {
  const [isFirstRun, setIsFirstRun] = useState(true)
  const isLargerThan768 = useSelector(matchMedia768InState)

  useEffect(() => {
    if (
      typeof totalCountItems === 'number' &&
      state.totalCountItems !== totalCountItems
    ) {
      setState({
        ...state,
        totalCountItems: totalCountItems
      })
    }
  }, [totalCountItems, state.totalCountItems])

  useEffect(() => {
    if (!isLoading && actualLength === 0 && state.page !== 1) {
      setState({
        ...state,
        page: state.page - 1
      })
    }
  }, [isLoading, actualLength])

  function reset() {
    setState({
      ...state,
      page: 1,
      totalCountItems: totalCountItems
    })
    setShowPagesFrom(1)
  }

  const totalPages = Math.ceil(state.totalCountItems / state.pageSize)
  const [showPagesFrom, setShowPagesFrom] = useState(1)
  const pagesCountLimit = useMemo(
    () => (isLargerThan768 ? 5 : 3),
    [isLargerThan768]
  )
  const pages = useMemo(() => {
    const pagesArray = []
    for (
      let i = 1;
      i <= Math.ceil(state.totalCountItems / state.pageSize);
      i++
    ) {
      pagesArray.push(i)
    }
    return pagesArray
  }, [state.totalCountItems, state.pageSize])

  function onStateChange(prop, value) {
    setState({
      ...state,
      [prop]: value
    })
  }

  function handleClick(event) {
    onStateChange('page', Number(event.target.id))
  }

  function handleNext() {
    const curPage = state.page + 1 <= totalPages ? state.page + 1 : totalPages
    onStateChange('page', curPage)
    if (showPagesFrom + pagesCountLimit === curPage) {
      setShowPagesFrom(showPagesFrom + pagesCountLimit)
    }
  }

  function handlePrev() {
    const curPage = state.page - 1 > 0 ? state.page - 1 : 1
    onStateChange('page', curPage)
    if (showPagesFrom === curPage + 1) {
      setShowPagesFrom(showPagesFrom - pagesCountLimit)
    }
  }

  function handleLastPage() {
    onStateChange('page', totalPages)
    setShowPagesFrom(totalPages - 1)
  }

  function handleClickpageSize(count) {
    setState({
      ...state,
      page: 1,
      pageSize: count
    })
    setShowPagesFrom(1)
  }

  return (
    <>
      {children !== null && cloneElement(children, { ...rest })}
      {!!id && <Button display="none" id={id} onClick={reset} />}
      <Box
        d="flex"
        justifyContent={['center', 'center', 'center']}
        pos="fixed"
        p="0 24px"
        bottom="0"
        left="0"
        fontSize={['12px', '12px', '12px', '12px', '12px', '14px']}
        boxShadow="0px -2px 8px 0px rgba(51, 51, 51, 0.1)"
        alignItems="center"
        w="100%"
        height="72px"
        bg="white"
        zIndex="10"
      >
        {isLargerThan768 && (
          <Box fontWeight="400" fontSize="inherit">
            {state.totalCountItems} records
          </Box>
        )}
        <Box
          d="flex"
          justifyContent="center"
          alignItems="center"
          m="0 auto"
          maxW={['290px', '290px', 'unset']}
        >
          <Button
            variant="gbaseOutline"
            onClick={handlePrev}
            isDisabled={state.page === pages[0] || state.totalCountItems === 0}
            color="custom.gray.200"
            p={['0', '0', '0 16px 0 8px;']}
            d="flex"
            minW="unset"
            fontSize="inherit"
          >
            <ChevronLeftIcon w="30px" height="30px" />
            {isLargerThan768 && <Text color="black">Prev</Text>}
          </Button>
          {pages.map((number) => {
            if (
              showPagesFrom <= number &&
              showPagesFrom + pagesCountLimit > number
            ) {
              return (
                <Button
                  variant="gbaseOutline"
                  borderLeft="none"
                  key={number + 'withPagination'}
                  id={number}
                  color={state.page === number && 'white'}
                  bg={state.page === number && 'custom.blue.600'}
                  onClick={handleClick}
                  w="40px"
                  fontSize="inherit"
                >
                  {number}
                </Button>
              )
            } else {
              return null
            }
          })}
          {state.page + pagesCountLimit < totalPages && (
            <>
              <Button
                variant="gbaseOutline"
                isDisabled={true}
                _disabled={false}
                borderLeft="none"
              >
                &hellip;
              </Button>
              <Button
                variant="gbaseOutline"
                borderLeft="none"
                onClick={handleLastPage}
                fontSize="inherit"
              >
                {totalPages}
              </Button>
            </>
          )}
          <Button
            variant="gbaseOutline"
            onClick={handleNext}
            isDisabled={
              state.page === pages[pages.length - 1] ||
              state.totalCountItems === 0
            }
            borderLeft="none"
            color="custom.gray.200"
            p={['0', '0', '0 8px 0 16px']}
            d="flex"
            minW="unset"
            fontSize="inherit"
          >
            {isLargerThan768 && <Text color="black">Next</Text>}
            <ChevronRightIcon w="30px" height="30px" />
          </Button>
        </Box>
        {isLargerThan768 && (
          <Box d="flex" alignItems="center">
            <Text fontSize="inherit">Rows per page:</Text>
            <Menu autoSelect={false}>
              <MenuButton
                as={Button}
                color="custom.blue.600"
                bg="none"
                fontSize="inherit"
                textDecoration="underline"
                w="30px"
                height="30px"
                p="2px"
              >
                {state.pageSize}
              </MenuButton>
              <MenuList>
                {rowsPerPage.map((count, idx) => {
                  return (
                    <MenuItem
                      onClick={() => handleClickpageSize(count)}
                      fontWeight={state.pageSize === count && 600}
                      color={state.pageSize === count && 'custom.blue.600'}
                      key={count + 'rowPerPage' + idx}
                    >
                      {count}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </Menu>
          </Box>
        )}
      </Box>
    </>
  )
}
