import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Text,
  useMediaQuery
} from '@chakra-ui/react'
import { api } from '@/api/index'
import PaymentsList from '@/components/Booking/Payments/PaymentsList/index'
import PaymentsTable from '@/components/Booking/Payments/PaymentsTable/index'
import BookingTable from '@/components/Bookings/BookingTable/index'
import Preloader from '@/components/Common/Preloader/index'
import TwoStyledInput from '@/components/Common/TwoStyledInput/index'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import { ArrowIcon } from '@/assets/Icons/ArrowIcon'

export default function SingleComboBookingPage() {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const history = useHistory()
  const { id: comboCode } = useParams()

  const { data, isLoading } = useQuery(
    ['single-combo-booking', comboCode],
    async () => {
      const { data: comboBookingData } = await api.get(
        `/api/combo_booking/by_promo_code/${comboCode}/`
      )

      if (comboBookingData.length === 0)
        throw new Error('No combo bookings with code - ' + comboCode)

      const comboBooking = comboBookingData[0]

      const { data: relatedBookingsData } = await api.get(
        `/api/combo_booking/${comboBooking.id}/related_bookings`
      )

      return { ...comboBooking, related_bookings: relatedBookingsData }
    }
  )

  const timesUsed = data?.number_of_uses || 0,
    totalUses = data?.total_number_of_uses || 0

  const { adults, children, fullName, phone, email } = {
    adults: data?.players || 0,
    children: data?.special_players || 0,
    fullName: data?.order.firstname + ' ' + data?.order.lastname || '',
    phone: data?.order.phone || '',
    email: data?.order.email || ''
  }

  return (
    <Container maxW="container.xl" pb={16}>
      <Box as={'header'} mt={8}>
        <Button
          variant="ghost"
          px={0}
          fontWeight={400}
          onClick={() => history.push('/settings')}
        >
          <ArrowIcon
            w="auto"
            height="12px"
            mr="8px"
            mb="2px"
            color="custom.gray.200"
          />
          Back
        </Button>
        <Flex>
          <Heading as={'h2'} fontWeight={600} color="custom.blue.600">
            #{comboCode}
          </Heading>
          <Spacer />
          <Text
            p={2}
            borderRadius={4}
            alignSelf="center"
            bgColor="#BEE9C7"
            color={'#0DB00A'}
            display="inline-block"
          >
            {data?.number_of_uses > 0 ? 'Available' : 'Used'}
          </Text>
        </Flex>
      </Box>

      <Grid
        gap={3}
        templateColumns={'repeat(6, 1fr)'}
        bgColor="white"
        my={4}
        p={6}
      >
        <GridItem colSpan={{ base: 6, md: 4 }}>
          <CopyToClipboard>
            <TwoStyledInput
              id="uses_available"
              name="uses_available"
              placeholder="Booking Link"
            />
          </CopyToClipboard>
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 1 }}>
          <TwoStyledInput
            id="uses_available"
            name="uses_available"
            placeholder="Adults"
            value={adults}
          />
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <TwoStyledInput
            id="uses_available"
            name="uses_available"
            placeholder="Children"
            value={children}
          />
        </GridItem>
        <GridItem colSpan={{ base: 6, md: 2 }}>
          <TwoStyledInput
            id="uses_available"
            name="uses_available"
            placeholder="Full name"
            value={fullName}
          />
        </GridItem>
        <GridItem colSpan={{ base: 6, md: 2 }}>
          <TwoStyledInput
            id="uses_available"
            name="uses_available"
            placeholder="Phone"
            value={phone}
          />
        </GridItem>
        <GridItem colSpan={{ base: 6, md: 2 }}>
          <TwoStyledInput
            id="uses_available"
            name="uses_available"
            placeholder="Email"
            value={email}
          />
        </GridItem>
      </Grid>

      <Panel header="Payments">
        {data?.order ? (
          <>
            {!isLargerThan768 && (
              <PaymentsList
                data={data.order.payments}
                email={data.order.email}
              />
            )}
            {isLargerThan768 && (
              <PaymentsTable
                tableData={data.order.payments}
                email={data.order.email}
              />
            )}
          </>
        ) : (
          <Preloader />
        )}
      </Panel>

      <Panel header={`Experiences (${timesUsed} / ${totalUses} games left)`}>
        <BookingTable
          data={data?.related_bookings}
          isLoading={isLoading}
          // bookingsQueryKeys={bookingsQueryKeys}
        />
      </Panel>
    </Container>
  )
}

const Panel = ({ header, children }) => {
  return (
    <>
      <Box
        borderBottom={'2px'}
        borderColor="custom.lightGray"
        p={4}
        mt={4}
        bgColor="white"
      >
        <Heading as="h4" size="md" fontWeight={500}>
          {header}
        </Heading>
      </Box>
      {children}
    </>
  )
}
