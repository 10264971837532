import { createIcon } from '@chakra-ui/icons'

export const SkullIcon = createIcon({
  displayName: 'SkullIcon',
  viewBox: '0 0 18 24',
  boxSize: '24',
  path: [
    <g fill="currentColor" key={'SkullIcon1'}>
      <circle cx="9" cy="12" r="9" fill="#FFE2D9" />
    </g>,
    <g fill="currentColor" key={'SkullIcon2'}>
      <path
        d="M9 7C5.42775 7 3.5 9.44053 3.5 11.7368C3.5 12.8574 4.05495 13.8311 5.15 14.6358V17H6.8V15.4211H7.9V17H10.1V15.4211H11.2V17H12.85V14.6353C13.9451 13.8311 14.5 12.8568 14.5 11.7368C14.5 9.44053 12.5723 7 9 7ZM6.8 12.7895C6.18345 12.7895 5.7 12.2116 5.7 11.4737C5.7 10.7358 6.18345 10.1579 6.8 10.1579C7.41655 10.1579 7.9 10.7358 7.9 11.4737C7.9 12.2116 7.41655 12.7895 6.8 12.7895ZM11.2 12.7895C10.5835 12.7895 10.1 12.2116 10.1 11.4737C10.1 10.7358 10.5835 10.1579 11.2 10.1579C11.8166 10.1579 12.3 10.7358 12.3 11.4737C12.3 12.2116 11.8166 12.7895 11.2 12.7895Z"
        fill="#FB2626"
      />
    </g>
  ]
})
