import { Box } from '@chakra-ui/react'
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'

export function CombineMenuIcon({ isMenuOpen }) {
  return (
    <>
      <Box pos="relative" w="100%" h="100%">
        <CloseIcon
          transform={isMenuOpen ? 'scale(1)' : 'scale(0)'}
          transition="all 0.5s ease-in"
          pos="absolute"
          left="21%"
          top="20%"
          color="custom.blue.600"
        />
        <HamburgerIcon
          transform={isMenuOpen ? 'scale(0)' : 'scale(1.2)'}
          transition="all 0.5s ease-out"
          pos="absolute"
          left="21%"
          top="20%"
          color="custom.gray.200"
        />
      </Box>
    </>
  )
}
