import { useSelector } from 'react-redux'
import {
  BOOKINGS_URL,
  matchMedia768InState,
  PERMISSION_LIMITATIONS,
  userInState
} from '../../constants'
import LimitationsDesktop from '@/components/Limitations/LimitationsDesktop'
import LimitationsMobile from '@/components/Limitations/LimitationsMobile'
import { Box } from '@chakra-ui/react'
import { useLimitations } from '../../libs/hooks/useLimitations'
import React, { useMemo, useState } from 'react'
import { convertWeekdayInIndex } from '../../libs'
import Downtime from '@/components/Limitations/Downtime'
import PermissionRequired from '@/components/Common/PermissionRequired'
import { useHistory } from 'react-router-dom'

export default function Limitations() {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const user = useSelector(userInState)
  const usersLocations = user.locations
  const history = useHistory()
  const [day, setDay] = useState('Monday')
  const limitationsPermissions = PermissionRequired(
    PERMISSION_LIMITATIONS,
    true
  )
  if (!limitationsPermissions) history.push(BOOKINGS_URL)
  const { data: limitationsData = [] } = useLimitations(
    convertWeekdayInIndex(day)
  )
  const correctedLimitationData = useMemo(() => {
    const arr = []
    if (limitationsData.length !== 0) {
      limitationsData.forEach((limit) => {
        if (usersLocations.includes(limit.location_id)) {
          arr.push(limit)
        }
      })
    }
    return arr
  }, [limitationsData])

  return (
    <Box p={{ base: '24px 16px', md: '32px 24px', '2xl': '48px' }}>
      {isLargerThan768 ? (
        <>
          <LimitationsDesktop
            data={correctedLimitationData}
            setDay={setDay}
            day={day}
          />
          <Downtime />
        </>
      ) : (
        <>
          <LimitationsMobile
            data={correctedLimitationData}
            setDay={setDay}
            day={day}
          />
          <Downtime />
        </>
      )}
    </Box>
  )
}
