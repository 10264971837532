import { Box, useTheme } from '@chakra-ui/react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  changeTime,
  convertValues,
  correctionTimezone,
  getStringDate
} from '../../../../../libs'
import StyledCalendarPopover from '../../StyledCalendarPopover'
import { useSelector } from 'react-redux'
import { filtersInState } from '../../../../../constants'

export default function TableBodyCalendar({
  location = {},
  isToday,
  locationObject = {},
  columns = [],
  ...rest
}) {
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const timezone = locationObject.timezone
  const { status } = useSelector(filtersInState)
  const selectedDay = useMemo(() => {
    return status.value === 'today'
      ? correctionTimezone(Math.round(new Date().valueOf() / 1000), true) * 1000
      : correctionTimezone(Math.round(status.value / 1000), true) * 1000
  }, [status])

  function getCoordinatesCursor() {
    const newDate = changeTime(timezone)
    const dateNow = newDate
    const initialTimeInSeconds =
      dateNow.getHours() * 60 * 60 + dateNow.getMinutes() * 60
    if (stringDateInLocation === null) {
      setStringDateInLocation(getStringDate(newDate))
    }
    return convertValues(
      initialTimeInSeconds - 1 * 60 * 60,
      25200,
      90000,
      0,
      100
    )
  }

  const [stringDateInLocation, setStringDateInLocation] = useState(null)
  const [cursorCoordinates, setCursorCoordinates] = useState(
    getCoordinatesCursor()
  )
  const isTodayLoc =
    stringDateInLocation === getStringDate(new Date(selectedDay))
  const intervalRef = useRef(null)

  useEffect(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }

    if (isToday && intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setCursorCoordinates(getCoordinatesCursor())
      }, 1000 * 1)
    }
    if (!isToday) return clearInterval(intervalRef.current)

    return () => clearInterval(intervalRef.current)
  }, [isToday])

  function colorizeItem(status, type) {
    switch (status) {
      case 'completed':
      case 'running':
      case 'upcoming': {
        if (type === 'main') {
          return customsColor.blue[600]
        }

        if (type === 'break') {
          return customsColor.borderBlue
        }
        break
      }

      case 'isLocked': {
        if (type === 'main') {
          return 'rgba(210, 210, 210, 1)'
        }

        if (type === 'break') {
          return 'rgba(210, 210, 210, 0.5)'
        }
        break
      }

      case 'several': {
        if (type === 'main') {
          return (
            'linear-gradient(45deg,' +
            ' rgba(4, 113, 241, 0.8) 25%,' +
            ' rgba(4, 113, 241, 1) 0,' +
            ' rgba(4, 113, 241, 1) 50%, ' +
            'rgba(4, 113, 241, 0.8) 0,' +
            ' rgba(4, 113, 241, 0.8) 75%,' +
            ' rgba(4, 113, 241, 1) 0);'
          )
        }

        if (type === 'break') {
          return customsColor.borderBlue
        }
        break
      }

      case 'canceled': {
        if (type === 'main') {
          return customsColor.red[600]
        }

        if (type === 'break') {
          return customsColor.red[100]
        }
        break
      }

      case 'free': {
        if (type === 'main') {
          return 'rgba(85, 201, 103, 1)'
        }

        if (type === 'break') {
          return 'rgba(198, 246, 207, 1)'
        }
        break
      }

      default: {
        if (type === 'main') {
          return 'rgba(85, 201, 103, 1)'
        }

        if (type === 'break') {
          return 'rgba(198, 246, 207, 1)'
        }
      }
    }
  }

  return Object.keys(location)?.map((event, locationIdx) => {
    const eventName = event
    return (
      <Box
        {...rest}
        key={'TableBodyCalendar' + locationIdx + locationIdx}
        overflowX="hidden"
      >
        <Box
          p="0 24px"
          d="flex"
          alignItems="center"
          bg="white"
          color="custom.gray.900"
          fontSize={{ md: '12px', '2xl': '14px' }}
          borderTop={`1px solid ${customsColor.lightGray}`}
          borderBottom={
            locationIdx === Object.keys(location).length - 1 &&
            `1px solid ${customsColor.lightGray}`
          }
        >
          {event}
        </Box>
        <Box
          d="grid"
          gridTemplateColumns={`repeat(${columns.length}, 1fr)`}
          pos="relative"
          overflow="hidden"
        >
          {columns?.map(({ header, time }, idxColumn) => {
            return (
              <Box
                w="100%"
                h="100%"
                bg="white"
                pos="relative"
                borderTop={`1px solid ${customsColor.lightGray}`}
                borderBottom={
                  locationIdx === Object.keys(location).length - 1 &&
                  `1px solid ${customsColor.lightGray}`
                }
                key={Date.now() + idxColumn + 'summaryBooking'}
                _before={{
                  content: `""`,
                  pos: 'absolute',
                  right: 0,
                  top: 0,
                  bottom: 0,
                  w: '0.5px',
                  zIndex: 1,
                  bg: customsColor.lightGray
                }}
                _after={{
                  content: `""`,
                  pos: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  w: '0.5px',
                  zIndex: 1,
                  bg: customsColor.lightGray
                }}
              />
            )
          })}
          {location[event]?.map((event, eventIdx) => {
            if (eventName === 'The Terminal Escape Room')
              console.log(event)
            const bookings = event?.bookings
            const startTime = event?.start * 1000
            const endTime = event?.end * 1000
            const isSeveral = bookings.length > 1
            const dateForCompare = event?.dateForCompare
            const changedTimeByTimezone = changeTime(timezone)
            const correctedLocationTime =
              correctionTimezone(
                Math.round(changedTimeByTimezone.valueOf() / 1000),
                true
              ) * 1000
            const nowInLocSeconds =
              changedTimeByTimezone.getHours() * 60 * 60 +
              changedTimeByTimezone.getMinutes() * 60
            const nowTimeWithTz =
              changedTimeByTimezone.valueOf() -
              changedTimeByTimezone.getTimezoneOffset() * 60 * 1000
            const isPast = dateForCompare < correctedLocationTime
            const isFuture = dateForCompare > correctedLocationTime
            const isExpired =
              isPast || (isTodayLoc && event.secondsStart < nowInLocSeconds)
            // if(timezone === 'America/Los_Angeles') {
            //     console.log('changedTimeByTimezone.valueOf() - (changedTimeByTimezone.getTimezoneOffset() * 60 * 1000)', changedTimeByTimezone.valueOf() - (changedTimeByTimezone.getTimezoneOffset() * 60 * 1000));
            //
            // }
            const isLocked =
              event?.is_locked && !bookings[0]?.status && isExpired
            const bookingStatus = isLocked
              ? 'isLocked'
              : isSeveral
              ? 'several'
              : bookings[0]?.status
            const freeSlot = typeof bookingStatus === 'undefined'
            const startPoint = convertValues(
              event.secondsStart - 1 * 60 * 60,
              25200,
              90000,
              0,
              100
            )
            let dif
            if (event.secondsEnd - event.secondsStart > 0) {
              dif = event.secondsEnd - event.secondsStart
            } else {
              dif = 86400 + (event.secondsEnd - event.secondsStart)
            }
            const pause = event.breakTimeSeconds
            const width = convertValues(dif, 0, 64800, 0, 100)
            const pauseWidth = convertValues(pause, 0, 64800, 0, 100)
            return (
              <React.Fragment key={'bookingTableLine' + eventIdx + locationIdx}>
                <StyledCalendarPopover
                  endTime={endTime}
                  startTime={startTime}
                  location={location}
                  eventName={eventName}
                  eventVariantId={event.experienceId}
                  locationId={event.locationId}
                  intervalId={event.id}
                  date={event.date}
                  bookings={bookings}
                  timezone={timezone}
                  dateForCompare={dateForCompare}
                  isLocked={isLocked}
                >
                  <Box
                    pos="absolute"
                    w={width + '%'}
                    left={startPoint + '%'}
                    h="100%"
                    p={'0.5px 0'}
                    zIndex={5}
                    _hover={{ opacity: '0.9', cursor: 'pointer' }}
                  >
                    <Box
                      w="100%"
                      h="100%"
                      d="flex"
                      alignItems="center"
                      justifyContent="center"
                      color="black"
                      opacity={freeSlot && isExpired ? 0.5 : 1}
                      bg={colorizeItem(bookingStatus, 'main')}
                    ></Box>
                  </Box>
                </StyledCalendarPopover>
                <Box
                  pos="absolute"
                  w={pauseWidth + '%'}
                  left={startPoint + width + '%'}
                  h="100%"
                  p={'0.5px 0'}
                  zIndex={5}
                >
                  <Box
                    w="100%"
                    h="100%"
                    bg={colorizeItem(bookingStatus, 'break')}
                  />
                </Box>
              </React.Fragment>
            )
          })}
          {cursorCoordinates >= 0 && cursorCoordinates <= 100 && isTodayLoc && (
            <Box
              h="100%"
              w="3px"
              borderX="1px solid white"
              bg="red"
              left={cursorCoordinates + '%'}
              pos="absolute"
              zIndex={6}
            />
          )}
        </Box>
      </Box>
    )
  })
}
