import { Box } from '@chakra-ui/react'

export default function RunningButtons({ children }) {
  return (
    <Box
      d="grid"
      gridTemplateRows="1fr"
      gridTemplateColumns="1fr 1fr"
      w={{ base: 'unset', md: '100%', '2xl': 'unset' }}
      fontWeight="500"
      fontSize={{ base: '12px', '2xl': '14px' }}
      mt={{ base: 'unset', md: '16px', '2xl': 'unset' }}
      gridColumnGap={{ base: '8px', '2xl': '16px' }}
      gridRowGap="8px"
    >
      {children}
    </Box>
  )
}
