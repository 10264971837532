import { Box, IconButton, Text, useTheme } from '@chakra-ui/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  COLUMNS_WITH_SORTING_PAYMENTS,
  TABLE_COLUMN_VALUES_PAYMENTS
} from '../../../../configs'
import TableCreator from '../../../Common/TableCreator'
import { TABLE_COLUMNS_SIZES_PAYMENTS } from '../../../../configs/tablesSizes'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import { CopyIconWithoutBg } from '../../../../assets/Icons/CopyIconWithoutBg'
import { BookingContext } from '../../../../pages/Booking'

export default function PaymentsTable({
  tableData,
  email,
  tableName = 'payments'
}) {
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const data = useMemo(() => tableData, [tableData])
  // const [_, isLoading, filters, setFilters] = useContext(BookingContext)
  const [tableFilter, setTableFilter] = useState({
    header: '',
    sort: ''
  })

  // useEffect(() => {
  //   if (
  //     filters.hasOwnProperty(tableName) &&
  //     filters[tableName].header !== '' &&
  //     tableFilter.header === ''
  //   ) {
  //     setTableFilter(filters[tableName])
  //   }
  // }, [])

  // useEffect(() => {
  //   if (tableName) {
  //     setFilters({
  //       ...filters,
  //       [tableName]: tableFilter
  //     })
  //   }
  // }, [tableFilter])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0,
      fontSize: 'inherit',
      _first: {
        textTransform: 'uppercase'
      }
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 20px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: '56px'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_PAYMENTS.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'identifier') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          if (
            original.payment_source === 'giftcard' ||
            original.payment_source === 'promocode'
          ) {
            if (original?.raw_data?.id) {
              return (
                <>
                  <CopyToClipboard text={original?.raw_data?.id}>
                    <IconButton
                      aria-label="copy-link"
                      minW="unset"
                      minH="unset"
                      bg="transparent"
                      mr="4px"
                      icon={<CopyIconWithoutBg color="custom.gray.200" />}
                    />
                  </CopyToClipboard>
                  {original?.raw_data?.id}
                </>
              )
            }
          }
          if (original.payment_source === 'stripe') {
            if (original?.raw_data?.id) {
              return (
                <>
                  <CopyToClipboard text={original?.raw_data?.id}>
                    <IconButton
                      aria-label="copy-link"
                      minW="unset"
                      minH="unset"
                      bg="transparent"
                      mr="4px"
                      icon={<CopyIconWithoutBg color="custom.gray.200" />}
                    />
                  </CopyToClipboard>
                  {original?.raw_data?.id}
                </>
              )
            } else {
              return <>—</>
            }
          }
          if (original.payment_source === 'cash') {
            return <>—</>
          }
          if (original.payment_source === 'offline') {
            if (original?.cash_payment_id) {
              return (
                <>
                  {original?.cash_payment_id}
                  <CopyToClipboard text={original?.cash_payment_id}>
                    <IconButton
                      aria-label="copy-link"
                      minW="unset"
                      minH="unset"
                      bg="transparent"
                      ml="4px"
                      icon={<CopyIconWithoutBg color="custom.gray.200" />}
                    />
                  </CopyToClipboard>
                </>
              )
            } else {
              return <>—</>
            }
          }

          return <>—</>
        }
      }

      if (field.Header.toLowerCase() === 'user') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          const last4 = original.card_last
          const carholder = original.cardholder
          const somethingNotNull = !!last4 || !!carholder || !!email

          if (somethingNotNull) {
            return (
              <Box p="4px 0">
                {!!email && (
                  <>
                    {email}
                    <CopyToClipboard text={email}>
                      <IconButton
                        aria-label="copy-link"
                        minW="unset"
                        minH="unset"
                        bg="transparent"
                        ml="4px"
                        icon={<CopyIconWithoutBg color="custom.gray.200" />}
                      />
                    </CopyToClipboard>
                  </>
                )}
                {!!last4 && (
                  <Text
                    as="span"
                    d="flex"
                    justifyContent="center"
                    lineHeight="18px"
                    fontSize="16px"
                  >
                    ****&nbsp;****&nbsp;****
                    <Text as="p" lineHeight="12px" fontSize="12px">
                      &nbsp; {last4}
                    </Text>
                  </Text>
                )}
                {!!carholder && <Text fontSize="12px">{carholder}</Text>}
              </Box>
            )
          } else {
            return <Text color="custom.gray.200">null</Text>
          }
        }
      }

      if (field.Header.toLowerCase() === 'total') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return <>{(original?.amount / 100).toFixed(2)}</>
        }
      }

      if (field.Header.toLowerCase() === 'fee') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          if (typeof original?.fee === 'number') {
            return <>{(original?.fee / 100).toFixed(2)}</>
          } else {
            return <>—</>
          }
        }
      }
      return fieldObject
    })
  }, [])
  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sortingColumns={COLUMNS_WITH_SORTING_PAYMENTS}
      sizesMap={TABLE_COLUMNS_SIZES_PAYMENTS}
      tableFilter={tableFilter}
      setTableFilter={setTableFilter}
    />
  )
}
