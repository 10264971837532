import { Box, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import ScheduleList from './ScheduleList'
import EditScheduleModal from '../Modals/EditScheduleModal'
import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PencilIcon } from '../../../../../../assets/Icons/PencilIcon'
import { friendlyTime } from '../../../../../../libs'
import { usePriceIntervals } from '../../../../../../libs/hooks/usePriceIntervals'
import { useNewSchedule } from '../../../../../../libs/hooks/useSchedules'
import { newScheduleValidation } from '../../../../../../configs/validation/schedule'
import {
  matchMedia768InState,
  PERMISSION_EVENT
} from '../../../../../../constants'
import ButtonsToggle from '../../../../../Common/ButtonsToggle'
import CustomWarningToast from '../../../../../Common/CustomWarningToast'
import DataRow from '../../../../../Common/DataRow'
import FormikInput from '../../../../../Common/FormikComponents/FormikInput'
import FormikScheduleInput from '../../../../../Common/FormikComponents/FormikScheduleInput'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import Preloader from '../../../../../Common/Preloader'

export default function Schedule({ eventVariantId = '', location = {} }) {
  const queryClient = useQueryClient()
  const toast = useToast()
  const toastId = 'error-notification'
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [editMode, setEditMode] = useState(false)
  const [checkedWeekday, setCheckedWeekday] = useState(0)
  const week = ['mon', 'tue', 'wen', 'thu', 'fri', 'sat', 'sun']
  const schedulesPermission = PermissionRequired(PERMISSION_EVENT, true)
  const {
    invalidate: invalidatePriceIntervals,
    data = [{}],
    refetch: refetchPriceIntervals,
    isLoading: isLoadingPriceIntervals
  } = usePriceIntervals([eventVariantId], checkedWeekday, {
    enabled: !!eventVariantId
  })
  const { mutate: newSchedule } = useNewSchedule()
  const schedule = data[0]
  const shiftsStarts = schedule?.shift_starts
    ? friendlyTime(schedule?.shift_starts * 1000, 'h:mm A')
    : 'HH:MM'
  const shiftsEnd = schedule?.shift_ends
    ? friendlyTime(schedule?.shift_ends * 1000, 'h:mm A')
    : 'HH:MM'
  const breakTime = schedule?.break_time
    ? schedule?.break_time + ' min'
    : 'unset'
  const gameTime = schedule?.game_time ? schedule?.game_time + ' min' : 'unset'
  const weekday = schedule?.weekday
  const intervals = schedule?.intervals || []
  const keyStyles = {
    width: { base: '100%', md: 'auto' },
    keyStyle: { mr: { base: 'unset', md: '8px' } },
    justifyContent: { base: 'space-between', md: 'unset' }
  }

  function onEditMode(value) {
    return () => setEditMode(value)
  }

  const formik = useFormik({
    initialValues: {
      shiftsStarts: schedule?.shift_starts
        ? friendlyTime(schedule?.shift_starts * 1000, 'hh:mm')
        : '',
      shiftsStartsAmOrPM: schedule?.shift_starts
        ? friendlyTime(schedule?.shift_starts * 1000, 'A')
        : 'am',
      shiftsEnds: schedule?.shift_ends
        ? friendlyTime(schedule?.shift_ends * 1000, 'hh:mm')
        : '',
      shiftsEndsAmOrPM: schedule?.shift_ends
        ? friendlyTime(schedule?.shift_ends * 1000, 'A')
        : 'pm',
      gameTime: schedule?.game_time ? schedule?.game_time : 1,
      breakTime: schedule?.break_time ? schedule?.break_time : 1,
      weekday: checkedWeekday
    },
    validationSchema: newScheduleValidation,
    enableReinitialize: true,
    onSubmit: submitHandler
  })

  const formikValues = formik.values
  const formikHandleSubmit = formik.handleSubmit
  const setFieldValue = formik.setFieldValue

  function handleChange(prop) {
    return (val) => setFieldValue(prop, val)
  }

  useEffect(() => {
    if (eventVariantId) {
      refetchPriceIntervals()
    }

    if (editMode) {
      setEditMode(false)
    }
  }, [checkedWeekday, eventVariantId])

  function convertTime(time, amOrPm) {
    const timeBeforeColumn = /\d\d(?=:)/
    const timeAfterColumn = /\d\d(?!:)/
    const [hours] = time?.match(timeBeforeColumn) || [null]
    const [minutes] = time?.match(timeAfterColumn) || [null]

    if (amOrPm.toLowerCase() === 'am' && +hours === 12) {
      return {
        hours: '00',
        minutes: minutes
      }
    }

    if (amOrPm.toLowerCase() === 'am') {
      return {
        hours: hours,
        minutes: minutes
      }
    }
    if (amOrPm.toLowerCase() === 'pm') {
      return {
        hours: 12 + +hours,
        minutes: minutes
      }
    }
  }

  function submitHandler(values) {
    const today = new Date()
    const shiftStartToSend = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      convertTime(values.shiftsStarts, values.shiftsStartsAmOrPM).hours,
      convertTime(values.shiftsStarts, values.shiftsStartsAmOrPM).minutes
    ).valueOf()
    const shiftEndToSend = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      convertTime(values.shiftsEnds, values.shiftsEndsAmOrPM).hours,
      convertTime(values.shiftsEnds, values.shiftsEndsAmOrPM).minutes
    ).valueOf()
    const objToSend = {
      eventVariantId: eventVariantId,
      gameTime: values.gameTime,
      breakTime: values.breakTime,
      shiftsStarts: shiftStartToSend / 1000,
      shiftsEnds: shiftEndToSend / 1000,
      weekday: values.weekday
    }

    newSchedule(
      {
        obj: objToSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([checkedWeekday, 'priceIntervals'])
          setEditMode(false)
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          if (!toast.isActive(toastId)) {
            toast({
              status: 'warning',
              position: 'bottom-left',
              id: toastId,
              duration: 2000,
              isClosable: true,
              render: () => (
                <CustomWarningToast
                  title="Attention"
                  message={msg ? msg : 'Something went wrong'}
                  close={toast.closeAll}
                />
              )
            })
          }
        }
      }
    )
  }

  const memoScheduleList = useMemo(() => {
    if (intervals.length !== 0 && !!eventVariantId) {
      return (
        <ScheduleList
          intervals={intervals}
          eventVariantId={eventVariantId}
          weekday={checkedWeekday}
          isLoadingPriceIntervals={isLoadingPriceIntervals}
          invalidatePriceIntervals={invalidatePriceIntervals}
        />
      )
    }
  }, [intervals, eventVariantId, checkedWeekday])

  return (
    <Box mb={{ md: '24px', '2xl': '32px' }}>
      <Box
        bg="white"
        padding={{ base: '16px', '2xl': '24px' }}
        mb="2px"
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Text
          fontSize={{ base: '18px', '2xl': '24px' }}
          fontWeight="500"
          mb="8px"
        >
          Schedule
        </Text>
        <ButtonsToggle
          data={week}
          ml={{ base: 'unset', md: 'auto' }}
          w={{ base: 'auto', md: '350px', '2xl': '441px' }}
          unCheckedColor="custom.lightGray"
          returnIndex={true}
          skipInitial={true}
          isDisabled={isLoadingPriceIntervals}
          cb={setCheckedWeekday}
        />
      </Box>
      {isLoadingPriceIntervals ? (
        <Box bg="white" padding="22px 16px" mb="2px">
          <Preloader m="0" />
        </Box>
      ) : (
        <>
          {editMode ? (
            <FormikProvider value={formik}>
              <Box
                bg="custom.blue.200"
                p="8px 16px"
                d="grid"
                gridTemplateColumns="repeat(4, 3fr) 1fr"
                gridGap="16px"
              >
                <Box mb="8px" d="grid" gridTemplateColumns="1fr 1fr">
                  <FormikScheduleInput
                    name="shiftsStarts"
                    id="shiftsStarts"
                    placeholder="HH:MM"
                    css={{
                      '> input': {
                        padding: 0,
                        textAlign: 'center'
                      }
                    }}
                  />

                  <ButtonsToggle
                    data={['am', 'pm']}
                    cb={handleChange('shiftsStartsAmOrPM')}
                    w="100%"
                    initialState={formikValues.shiftsStartsAmOrPM}
                  />
                </Box>
                <Box mb="8px" d="grid" gridTemplateColumns="1fr 1fr">
                  <FormikScheduleInput
                    name="shiftsEnds"
                    id="shiftsEnds"
                    placeholder="HH:MM"
                    css={{
                      '> input': {
                        padding: 0,
                        textAlign: 'center'
                      }
                    }}
                  />
                  <ButtonsToggle
                    data={['am', 'pm']}
                    cb={handleChange('shiftsEndsAmOrPM')}
                    w="100%"
                    initialState={formikValues.shiftsEndsAmOrPM}
                  />
                </Box>
                <FormikInput
                  name="gameTime"
                  id="gameTime"
                  placeholder="Game time"
                />
                <FormikInput
                  name="breakTime"
                  id="breakTime"
                  placeholder="Break time"
                />
                <Box d="flex" justifyContent="end">
                  <IconButton
                    aria-label="save-schedule"
                    color="custom.blue.600"
                    w="12px"
                    minW="unset"
                    variant="funcColumnIconButton"
                    _focus={{ boxShadow: 'none' }}
                    type="submit"
                    onClick={formikHandleSubmit}
                    icon={<DisketteIcon w="inherit" />}
                  />
                  <IconButton
                    aria-label="save-schedule"
                    _focus={{ boxShadow: 'none' }}
                    color="custom.gray.200"
                    w="12px"
                    minW="unset"
                    ml="16px"
                    variant="funcColumnIconButton"
                    onClick={onEditMode(false)}
                    icon={<CrosshairIcon w="inherit" />}
                  />
                </Box>
              </Box>
            </FormikProvider>
          ) : (
            <Box
              bg="white"
              padding="16px"
              mb="2px"
              d="grid"
              gridTemplateColumns={{ base: '1fr', md: 'repeat(4, 3fr) 1fr' }}
            >
              <DataRow
                title="shift starts"
                value={shiftsStarts}
                {...keyStyles}
              />
              <DataRow title="shift ends" value={shiftsEnd} {...keyStyles} />
              <DataRow title="game time" value={gameTime} {...keyStyles} />
              <DataRow
                title="brake time"
                value={breakTime}
                mb={{ base: '16px', md: '4px', '2xl': '8px' }}
                {...keyStyles}
              />
              {isLargerThan768 ? (
                <IconButton
                  aria-label="open-edit-schedule-modal"
                  variant="funcColumnIconButton"
                  icon={<PencilIcon />}
                  color="custom.gray.200"
                  gridColumn={{ md: '5/6' }}
                  isDisabled={!schedulesPermission}
                  justifySelf={{ base: 'unset', md: 'end' }}
                  onClick={onEditMode(true)}
                />
              ) : (
                <EditScheduleModal
                  week={week}
                  weekday={checkedWeekday}
                  schedule={schedule}
                  submitHandler={submitHandler}
                  gridColumn={{ base: 'unset', md: '5/6' }}
                />
              )}
            </Box>
          )}
        </>
      )}
      {memoScheduleList}
    </Box>
  )
}
