import { Icon, useTheme } from '@chakra-ui/react'

export default function CustomIcon({ isIndeterminate, isChecked, ...rest }) {
  const theme = useTheme()
  const customColors = theme.colors.custom
  return (
    <Icon viewBox="0 0 12 12" {...rest}>
      <rect
        x="0.5"
        y="0.5"
        width="12"
        height="12"
        fill={customColors.blue[600]}
      />
    </Icon>
  )
}
