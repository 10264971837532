import Select from 'react-select'
import { Box, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'

export default function DropdownSelect({
  chakraProps,
  selectProps = {},
  cb = null,
  cbState = null,
  label = ''
}) {
  const { main = {}, styles = {} } = selectProps
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [wasOpened, setWasOpened] = useState(false)
  const theme = useTheme()
  const customColors = theme.colors.custom
  useEffect(() => {
    if (cb !== null && typeof cb === 'function') {
      if (isMenuOpen) {
        setWasOpened(true)
      }

      if (!isMenuOpen && wasOpened && !main.value) {
        cb()
      }
    }
  }, [isMenuOpen])

  useEffect(() => {
    if (cbState !== null && typeof cbState === 'function') {
      cbState(isMenuOpen)
    }
  }, [isMenuOpen])

  const defaultStyles = {
    control: (provided) => {
      const propsStyles = styles?.control
      return {
        ...provided,
        boxShadow: 'none',
        borderRadius: 'none',
        borderColor: isMenuOpen
          ? `${customColors.blue[600]}`
          : `${customColors.borderBlue}`,
        background: isMenuOpen
          ? `${customColors.blue[200]}`
          : `${customColors.lightGray}`,
        borderBottom: isMenuOpen ? 'none' : '',
        fontSize: 'inherit',
        height: '40px',
        padding: '0 6px',
        cursor: 'pointer',
        ...propsStyles
      }
    },
    placeholder: (provided) => {
      const propsStyles = styles?.placeholder
      return {
        ...provided,
        color: `${customColors.gray[300]}`,
        ...propsStyles
      }
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        background: 'transparent'
      }
    },
    menu: (provided, state) => {
      const propsStyles = styles?.menu
      return {
        ...provided,
        borderRadius: 'none',
        zIndex: 100,
        boxShadow: 'none',
        marginTop: '-10px',
        border: '1px solid',
        borderTop: 'none',
        background: `${customColors.blue[200]}`,
        borderColor: `${customColors.blue[600]}`,
        ...propsStyles
      }
    },
    menuList: (provided, state) => {
      const propsStyles = styles?.menuList
      return {
        ...provided,
        ...propsStyles
      }
    },
    option: (provided, state) => {
      const propsStyle = styles?.options
      return {
        ...provided,
        borderTop: '1px solid',
        borderColor: `${customColors.borderBlue}`,
        fontSize: 'inherit',
        background: state.isSelected ? `${customColors.blue[600]}` : '',
        color: state.isSelected ? 'white' : `${customColors.gray[900]}`,
        ...propsStyle
      }
    },
    dropdownIndicator: (provided, state) => {
      const propsStyle = styles?.dropdownIndicator
      return {
        ...provided,
        ...propsStyle
      }
    },
    indicatorsContainer: (provided, state) => {
      const propsStyle = styles?.indicatorsContainer
      return {
        ...provided,
        transform: isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        ...propsStyle
      }
    }
  }
  return (
    <Box {...chakraProps} pos="relative">
      {label !== '' && (
        <Text
          pos="absolute"
          zIndex="1"
          color="custom.gray.300"
          top="8px"
          left="15px"
          fontSize="10px"
        >
          {label}
        </Text>
      )}
      <Select
        {...main}
        styles={defaultStyles}
        isOptionDisabled={(option) => option.isDisabled}
        onMenuClose={() => setIsMenuOpen(false)}
        onMenuOpen={() => setIsMenuOpen(true)}
      />
    </Box>
  )
}
