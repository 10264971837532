import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GoSearch } from 'react-icons/all'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useLocations } from '@/libs/hooks/useLocations'

import DropdownSelect from '@/components/Common/DropdownSelect'

import { setCurrentPage, setLocationFilter } from '@/actions'
import { debounceSearchTime } from '@/configs'
import { filtersInState } from '@/constants'

import { useRouterQuery } from '../StatusSelector'

export default function SearchModule({ callback }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { query, setQuery } = useRouterQuery()
  const [searchTerm, setSearchTerm] = useState()
  const { data: { results: locations = [] } = {} } = useLocations()
  const filters = useSelector(filtersInState)

  const locationOptions = useMemo(() => {
    return locations.length
      ? [
          { value: '', label: 'All locations' },
          ...locations.map((loc) => ({ value: loc.id, label: loc.name }))
        ]
      : []
  }, [locations])

  const navigateTo = (path) => () => {
    history.push(path)
  }

  const handleLocationChange = useCallback(
    (locationObject) => {
      if (locationObject) {
        dispatch(setCurrentPage(1))
        dispatch(setLocationFilter(locationObject))
      }
    },
    [dispatch]
  )

  const debouncedCallback = useCallback(
    debounce((searchValue = '', cb = null) => {
      if (cb !== null && typeof cb === 'function') {
        const [bookCode] = searchValue?.match(/[^#]+/) || [null]
        cb(bookCode !== null ? bookCode.trim() : searchValue.trim())
      }
    }, debounceSearchTime),
    []
  )

  const handleSearchChange = (e) => {
    const newValue = e.target.value
    setSearchTerm(newValue)
    debouncedCallback(newValue, callback)
  }

  useEffect(() => {
    if (searchTerm?.length > 0) {
      setQuery({ search: searchTerm })
    }
  }, [searchTerm])

  // useEffect(() => {
  //   if (callback !== null && typeof callback === 'function') {
  //     debouncedCallback(searchTerm, callback)
  //   }
  // }, [searchTerm, callback])

  return (
    <Box
      bg={{ base: 'none', md: 'white' }}
      d={{ base: 'flex' }}
      flexDir={{ base: 'column', md: 'row' }}
      p={{ base: '0', md: '16px' }}
      mt={{ base: '0', md: '2px' }}
    >
      <DropdownSelect
        selectProps={{
          main: {
            isDisabled: locationOptions.length === 0,
            options: locationOptions,
            placeholder: locationOptions.length === 0 ? 'Empty' : 'Location',
            value: filters?.location,
            onChange: handleLocationChange
          }
        }}
        chakraProps={{ fontSize: 'inherit', minWidth: '320px' }}
      />
      <InputGroup mt={{ base: '2', md: '0' }} ml={{ md: '2' }}>
        <Input
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          fontSize="inherit"
          p="0 16px"
          _placeholder={{ color: 'custom.gray.300' }}
          variant="gbase"
        />
        <InputRightElement
          children={<Icon as={GoSearch} color="rgba(199, 207, 214, 1)" />}
        />
      </InputGroup>
      <Button
        w={{ base: '100%', md: '20.4%' }}
        variant="gbase"
        fontSize="inherit"
        ml={{ base: '0', md: '8px' }}
        fontWeight="500"
        onClick={navigateTo('/new-order')}
      >
        <Icon as={AddIcon} mr="8px" />
        New Booking
      </Button>
    </Box>
  )
}
