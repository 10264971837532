import { Box, Text } from '@chakra-ui/react'
import ButtonsToggle from '@/components/Common/ButtonsToggle'
import DiscountsTab from '@/components/Discounts/Tabs/DiscountsTab'
import PromocodeTab from '@/components/Discounts/Tabs/PromocodeTab'
import PartnerTab from '@/components/Discounts/Tabs/PartnerTab'
import { useDispatch, useSelector } from 'react-redux'
import { discountsTabInState } from '../../constants'
import { setSelectedTabDiscounts } from '../../actions'

export default function Discounts() {
  const dispatch = useDispatch()
  const buttonsToggleData = ['promocodes', 'discounts', 'partners']
  const selectedDiscountsTab = useSelector(discountsTabInState)
  function createTab(type) {
    switch (type) {
      case buttonsToggleData[0]:
        return <PromocodeTab />
      case buttonsToggleData[1]:
        return <DiscountsTab />
      case buttonsToggleData[2]:
        return <PartnerTab />
    }
  }

  function cbHandler() {
    return (val) => {
      dispatch(setSelectedTabDiscounts(val))
    }
  }

  return (
    <Box p={{ base: '24px 16px', md: '24px', '2xl': '48px' }}>
      <Box
        d={{ md: 'flex' }}
        mb={{ base: '16px', md: '24px', '2xl': '36px' }}
        alignItems="center"
      >
        <Text
          color="custom.black.500"
          fontSize={{ base: '24px', '2xl': '32px' }}
          fontWeight={{ base: '500', md: '600' }}
          mb="16px"
        >
          Discounts
        </Text>
        <ButtonsToggle
          data={buttonsToggleData}
          initialState={buttonsToggleData[selectedDiscountsTab]}
          cb={cbHandler()}
          returnIndex={true}
          skipInitial={true}
          m={{ md: '0 0 0 auto' }}
          w={{ md: '340px' }}
          h="40px"
        />
      </Box>
      {createTab(buttonsToggleData[selectedDiscountsTab])}
    </Box>
  )
}
