import { Box } from '@chakra-ui/react'
import TemplatesItem from '../TemplatesItem'

export default function TemplatesList({ data = [] }) {
  return (
    <Box>
      {data
        ?.sort(function (a, b) {
          return a.id - b.id
        })
        .map((el, idx) => (
          <TemplatesItem
            key={'paymentGate' + idx}
            name={el.template_name}
            notificationTemplateId={el.id}
          />
        ))}
    </Box>
  )
}
