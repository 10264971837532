import { Box, Button, Switch, Text } from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { useUpdateDiscount } from '../../../../../libs/hooks/useDiscounts'
import { discountItemValidation } from '../../../../../configs/validation/discountItemValidation'
import { matchMedia768InState } from '../../../../../constants'
import ButtonsToggle from '../../../../Common/ButtonsToggle'
import CustomDaySelector from '../../../../Common/CustomDaySelector'
import FormikInput from '../../../../Common/FormikComponents/FormikInput'

export default function DiscountsListItem({
  amountText = '',
  discountType,
  discountId,
  effective,
  includeHolidays,
  isAdjourned,
  daysToActivate
}) {
  const { mutate: patchDiscount, isLoading } = useUpdateDiscount()
  const queryClient = useQueryClient()
  const [somethingTouched, setSomethingTouched] = useState(false)
  const [amount] = (amountText || '').match(/\d+/)
  const [amountType] = (amountText || '').match(/[$,%]/)
  const formik = useFormik({
    initialValues: {
      amountType: amountType,
      amountText: +amount,
      discountType: discountType,
      discountId: discountId,
      effective: effective,
      includeHolidays: includeHolidays,
      isAdjourned: isAdjourned,
      daysToActivate: daysToActivate === null ? 0 : daysToActivate
    },
    validationSchema: discountItemValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const toSend = {
        amountType: values.amountType,
        discountType: values.discountType,
        ...(values.amountType === '$' && { amount: values.amountText * 100 }),
        ...(values.amountType === '%' && { amount: values.amountText }),
        effective: values.effective,
        includeHolidays: values.includeHolidays,
        isAdjourned: values.isAdjourned,
        ...(values.isAdjourned && { daysToActivate: +values.daysToActivate }),
        discountId
      }
      patchDiscount(
        { obj: toSend },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['discounts'])
            setSomethingTouched(false)
          }
        }
      )
    }
  })

  const formikHandleSubmit = formik.handleSubmit
  const formikSetFieldValue = formik.setFieldValue
  const formikValues = formik.values
  const isLargerThan768 = useSelector(matchMedia768InState)
  const setFieldValue = formik.setFieldValue

  useEffect(() => {
    if (!somethingTouched && +amount !== +formikValues.amountText) {
      setSomethingTouched(true)
    }
  }, [formikValues.amountText])
  function onBlurHandler() {
    if (!somethingTouched) {
      setSomethingTouched(true)
    }
  }

  useEffect(() => {
    if (
      !isLargerThan768 &&
      !somethingTouched &&
      daysToActivate !== formikValues.daysToActivate
    ) {
      setSomethingTouched(true)
    }
  }, [formikValues.daysToActivate])

  function onChangeToggle(prop) {
    return (e) => {
      formikSetFieldValue(prop, e.target.checked)
      if (!somethingTouched) {
        setSomethingTouched(true)
      }
    }
  }

  function onChangeToggleIsAdjourned(prop) {
    return (e) => {
      formikSetFieldValue(prop, e.target.checked)
      if (!somethingTouched) {
        setSomethingTouched(true)
      }
    }
  }

  function onChangeEffective() {
    return (val) => {
      formikSetFieldValue('effective', val)
      if (!somethingTouched) {
        setSomethingTouched(true)
      }
    }
  }

  function onChangeType() {
    return (val) => {
      formikSetFieldValue('amountType', val)
      if (!somethingTouched && amountType !== val) {
        setSomethingTouched(true)
      }
    }
  }

  function discountName() {
    if (discountType === 'S') return 'Same day booking'
    if (discountType === 'A') return 'Advanced booking'
    if (discountType === 'M') return 'Multiple bookings'
  }

  return (
    <FormikProvider value={formik}>
      <Box
        p={{ base: '16px 16px 0px' }}
        bg="white"
        mb="2px"
        d={{ md: 'flex' }}
        flexWrap="wrap"
      >
        <Box
          d="flex"
          fontSize={{ base: '12px', '2xl': '14px' }}
          m={{ base: '0 0 16px', md: '0 16px 0 0', '2xl': '0 24px 0 0' }}
          alignItems="center"
          h={{ md: '70px' }}
          w={{ md: '280px', '2xl': '300px' }}
        >
          <Text color="custom.gray.900">{discountName()}:</Text>
          <FormikInput
            name="amountText"
            id="amountText"
            placeholder="Amount"
            maxW={{ base: '60px' }}
            p="0 8px"
            textAlign="center"
            ml={{ base: 'auto' }}
            color="custom.gray.900"
          />
          <ButtonsToggle
            data={['$', '%']}
            initialState={formikValues.amountType}
            ml="8px"
            w={{ base: '80px' }}
            h="40px"
            cb={onChangeType()}
            unCheckedColor="custom.lightGray"
          />
        </Box>
        <Box
          fontSize={{ base: '12px', '2xl': '14px' }}
          m={{ base: '0 0 16px', md: '0  16px 0 0', '2xl': '0 24px 0 0' }}
          w={{ md: '312px', '2xl': '340px' }}
          d={{ md: 'flex' }}
          h={{ md: '70px' }}
          alignItems="center"
        >
          <Text color="custom.gray.900" mb={{ base: '8px', md: 'unset' }}>
            Effective:
          </Text>
          <CustomDaySelector
            w={{ md: '100%' }}
            ml={{ md: '8px' }}
            needIdx={true}
            skipFirstRun={true}
            initialState={formikValues.effective}
            cb={onChangeEffective()}
          />
        </Box>
        <Box
          fontSize={{ base: '12px', '2xl': '14px' }}
          m={{ base: '0 0 16px', md: '0 16px 0  0', '2xl': '0 24px 0 0' }}
          d="flex"
          h={{ md: '70px' }}
          w={{ md: '160px', '2xl': '180px' }}
          alignItems="center"
        >
          <Text color="custom.gray.900" mb={{ base: '8px', md: 'unset' }}>
            Include holidays:
          </Text>
          <Switch
            ml={{ base: 'auto' }}
            isChecked={formikValues.includeHolidays}
            onChange={onChangeToggle('includeHolidays')}
          />
        </Box>
        {discountType === 'A' && (
          <Box
            fontSize={{ base: '12px', '2xl': '14px' }}
            m={{ base: '0 0 16px', md: '0  16px 0 0', '2xl': '0 24px 0 0' }}
            d="flex"
            h={{ md: '70px' }}
            w={{
              base: '100%',
              md: '228px',
              '2xl': '256px'
            }}
            mr="16px"
            alignItems="center"
          >
            <Text color="custom.gray.900" mb={{ base: '8px', md: 'unset' }}>
              Days to activate:
            </Text>
            <Switch
              ml={{ base: 'auto', md: '16px' }}
              isChecked={formikValues.isAdjourned}
              onChange={onChangeToggleIsAdjourned('isAdjourned')}
            />

            {isLargerThan768 && (
              <FormikInput
                name="daysToActivate"
                id="daysToActivate"
                ml={{ base: '16px', '2xl': '24px' }}
                w="60px"
                textAlign="center"
                d={{
                  base: 'none',
                  md: formikValues.isAdjourned ? 'inline-block' : 'none'
                }}
                isDisabled={!formikValues.isAdjourned || discountType === 'S'}
              />
            )}
          </Box>
        )}
        {somethingTouched && isLargerThan768 && (
          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            h={{ md: '70px' }}
          >
            <Button
              variant="blue"
              onClick={formikHandleSubmit}
              isDisabled={isLoading}
              _hover={{
                _disabled: false
              }}
            >
              Save
            </Button>
          </Box>
        )}

        {formikValues.isAdjourned &&
          !isLargerThan768 &&
          discountType === 'A' && (
            <FormikInput
              name="daysToActivate"
              w="100%"
              mb="16px"
              isDisabled={!formikValues.isAdjourned}
            />
          )}
        {somethingTouched && !isLargerThan768 && (
          <Button
            variant="blue"
            w="100%"
            mb="16px"
            onClick={formikHandleSubmit}
            isDisabled={isLoading}
            _hover={{
              _disabled: false
            }}
          >
            Save
          </Button>
        )}
      </Box>
    </FormikProvider>
  )
}
