import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,useDisclosure,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'
import InputMask from 'react-input-mask'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PencilIcon } from '../../../../../../assets/Icons/PencilIcon'
import { correctionPhoneNumber } from '../../../../../../libs'
import { usePatchUser } from '../../../../../../libs/hooks/useUsers'
import { useUsersRoles } from '../../../../../../libs/hooks/useUsersRoles'
import { editUserModalValidation } from '../../../../../../configs/validation/editUserModalValidation'
import {
  matchMedia768InState,
  matchMedia1440InState,
  PERMISSION_USERS,
  PHONE_INPUT_MASK
} from '../../../../../../constants'
import CustomWarningToast from '../../../../../Common/CustomWarningToast'
import FormikDropdown from '../../../../../Common/FormikComponents/FormikDropdown'
import FormikTwoStyledInput from '../../../../../Common/FormikComponents/FormikTwoStyledInput'
import LocationApplyList from '../../../../../Common/LocationApplyList'
import PermissionRequired from '../../../../../Common/PermissionRequired'


export default function EditUserModal({
  firstname = '',
  lastname = '',
  phone = '',
  role = '',
  email = '',
  locations = [],
  locationsIds = [],
  userId = ''
}) {
  const toast = useToast()
  const usersPermission = PermissionRequired(PERMISSION_USERS, true)
  const queryClient = useQueryClient()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: usersRoles = [] } = useUsersRoles()
  const { mutate: patchUser } = usePatchUser()
  const errorPatchUser = {
    id: 'error-patch-user',
    text: 'Something went wrong'
  }
  const optionsRoles = useMemo(() => {
    if (usersRoles.length !== 0) {
      return usersRoles.map((userRole) => {
        return {
          value: userRole?.id,
          label: userRole?.name
        }
      })
    } else {
      return []
    }
  }, [usersRoles])

  const initialRolesState = useMemo(() => {
    if (optionsRoles.length !== 0) {
      for (let i = 0; i < optionsRoles.length; i++) {
        if (optionsRoles[i].value === role?.id) {
          return optionsRoles[i]
        }
      }
    } else {
      return optionsRoles[0]
    }
  }, [role, optionsRoles])
  const formik = useFormik({
    initialValues: {
      firstname: firstname,
      lastname: lastname,
      phone: phone ? correctionPhoneNumber(phone, 'show') : '',
      role: initialRolesState,
      email: email,
      locations: locationsIds,
      password: ''
    },
    validationSchema: editUserModalValidation,
    enableReinitialize: true,
    onSubmit: onSubmitHandler
  })

  const setFieldValue = formik.setFieldValue
  const handleSubmit = formik.handleSubmit

  function generateWarningToast({ text, id, incomingText, msgObject }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 4000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={incomingText ? incomingText : text}
              close={toast.closeAll}
              msgObjects={msgObject}
            />
          )
        })
      }
    }
  }

  function onSubmitHandler(values) {
    const toSend = {
      userId: userId,
      firstname: values.firstname,
      lastname: values.lastname,
      phone: correctionPhoneNumber(values.phone, 'send'),
      ...(email !== values.email && { email: values.email }),
      locations: values.locations,
      role: values.role.value,
      ...(values.password !== '' && { password: values.password })
    }

    patchUser(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['users'])
          onClose()
        },
        onError: (err) => {
          const data = err?.response?.data
          generateWarningToast({ ...errorPatchUser, msgObject: data })()
        }
      }
    )
  }

  function onChangeDropdown(obj) {
    setFieldValue('role', obj)
  }

  function onChangeLocations(loc) {
    setFieldValue('locations', loc)
  }

  return (
    <>
      <IconButton
        aria-label="editAccount"
        variant="funcColumnIconButtons"
        icon={<PencilIcon />}
        onClick={onOpen}
        isDisabled={!usersPermission}
        mr="16px"
        color="custom.gray.200"
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
        >
          <FormikProvider value={formik}>
            <ModalHeader
              mb="34px"
              d="flex"
              fontSize={{ base: '18px', '2xl': '24px' }}
            >
              Edit User
              <Box
                ml="auto"
                d={{ base: 'none', md: 'grid' }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="8px"
                fontSize={{ base: '12px', '2xl': '14px' }}
              >
                <Button
                  w="100%"
                  variant="blue"
                  fontSize="inherit"
                  onClick={handleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  w="100%"
                  variant="red"
                  fontSize="inherit"
                  onClick={onClose}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <ModalCloseButton d={{ md: 'none' }} />
            <ModalBody>
              <Box
                fontSize={{ base: '12px', '2xl': '14px' }}
                d={{ base: 'block', md: 'grid' }}
                gridTemplateColumns={{ md: 'repeat(3, 1fr)' }}
                gridGap={{ md: '16px' }}
                mb={{ base: '16px' }}
              >
                <FormikTwoStyledInput
                  name="firstname"
                  mb={{ base: '8px', md: 'unset' }}
                  placeholder="Firstname"
                />
                <FormikTwoStyledInput
                  name="lastname"
                  mb={{ base: '8px', md: 'unset' }}
                  placeholder="Lastname"
                />
                <FormikTwoStyledInput
                  name="email"
                  mb={{ base: '8px', md: 'unset' }}
                  placeholder="E-mail"
                />
                <FormikTwoStyledInput
                  name="phone"
                  input={{
                    as: InputMask,
                    mask: PHONE_INPUT_MASK
                  }}
                  mb={{ base: '8px', md: 'unset' }}
                  placeholder="Phone"
                />
                <FormikTwoStyledInput
                  name="password"
                  mb={{ base: '8px', md: 'unset' }}
                  placeholder="Password"
                  input={{
                    type: 'password',
                    autoComplete: 'new-password'
                  }}
                />
                <FormikDropdown
                  label={isLargerThan1440 ? 'Role' : ''}
                  formik={{
                    name: 'role',
                    id: 'role'
                  }}
                  main={{
                    options: optionsRoles
                  }}
                  selectProps={{
                    styles: {
                      control: {
                        height: isLargerThan1440 ? '56px' : '40px',
                        padding: isLargerThan1440 ? '12px 4px 0' : '0',
                        border: !isLargerThan768 && 'none'
                      }
                    }
                  }}
                />
              </Box>
              <Text
                color="custom.black.500"
                fontSize="14px"
                fontWeight="500"
                mb="16px"
              >
                Locations
              </Text>
              <LocationApplyList
                cb={onChangeLocations}
                initialState={locationsIds}
              />
            </ModalBody>
            <ModalFooter
              d={{ base: 'grid', md: 'none' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
            >
              <Button w="100%" variant="blue" onClick={handleSubmit}>
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button w="100%" variant="red" onClick={onClose}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </ModalFooter>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  )
}
