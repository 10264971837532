import { useFormikContext } from 'formik'

import ProfileItem from '../ProfileItem'

export default function ParticipantsInformation() {
  const { values } = useFormikContext()
  const selectedProfile = values?.selectedProfile

  return (
    <>
      <ProfileItem profile={selectedProfile} simple={true} />
      <div className="waiver-widget--split" />
    </>
  )
}
