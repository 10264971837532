import CutOffBlock from '@/components/Limitations/CutOffBlock'
import React from 'react'
import { Box } from '@chakra-ui/react'
import PermissionRequired from '@/components/Common/PermissionRequired'
import { BOOKINGS_URL, PERMISSION_CUT_OFF } from '../../constants'
import { useHistory } from 'react-router-dom'

export default function CutOffTime() {
  const history = useHistory()
  const cuttOffTimePermission = PermissionRequired(PERMISSION_CUT_OFF, true)

  if (!cuttOffTimePermission) history.push(BOOKINGS_URL)

  return (
    <Box
      p={{
        base: '24px 16px 82px',
        md: '48px 24px 82px',
        '2xl': '66px 48px 82px'
      }}
    >
      <CutOffBlock />
    </Box>
  )
}
