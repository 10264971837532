import { Box, Button, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { generateWarningToast } from '../../../../../../libs'
import { useCreateWaiversblock } from '../../../../../../libs/hooks/useWaiversblocks'
import CustomTextarea from '../../../../../Common/CustomTextarea'
import TwoStyledInput from '../../../../../Common/TwoStyledInput'

export default function NewWaiverBlock({
  waiverId = '',
  lastWaiverBlockPositionIndex = '',
  closeNewWaiverBlock = null
}) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const [state, setState] = useState({
    name: '',
    title: '',
    description: ''
  })
  const needDisable =
    state.name === '' || state.title === '' || state.description === ''
  const { mutate: newWaiverBlock } = useCreateWaiversblock()
  function onChangeHandler(prop) {
    return (value) => {
      setState({
        ...state,
        [prop]: value
      })
    }
  }

  function createNewWaiverBlock() {
    const toSend = {
      checkouts: [],
      name: state.name ? state.name : '',
      title: state.title ? state.title : '',
      description: state.description ? state.description : '',
      position: lastWaiverBlockPositionIndex + 1,
      waiverId: waiverId
    }
    newWaiverBlock(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['waiver', +waiverId])
          if (
            closeNewWaiverBlock !== null &&
            typeof closeNewWaiverBlock === 'function'
          ) {
            closeNewWaiverBlock()
          }
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          generateWarningToast({
            id: 'new-waivwe-block',
            toast,
            msgObjects,
            message: msg
          })
        }
      }
    )
  }

  return (
    <Box mb={{ base: '16px', md: '24px' }}>
      <Box
        bg="white"
        p={{ base: '16px', md: '20px 16px', '2xl': '24px' }}
        mb="2px"
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
      >
        <TwoStyledInput
          placeholder="Name"
          w={{ base: '100%', md: '380px', '2xl': '480px' }}
          mb={{ base: '16px', md: '0' }}
          value={state.name}
          onChange={onChangeHandler('name')}
        />
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gridGap={{ base: '8px', md: '16px' }}
          ml={{ md: 'auto' }}
        >
          <Button
            w="100%"
            fontSize={{ base: '12px', '2xl': '14px' }}
            variant="blue"
            isDisabled={needDisable}
            _hover={{
              _disabled: false
            }}
            onClick={createNewWaiverBlock}
          >
            <DisketteIcon mr="8px" />
            Save
          </Button>
          <Button
            w="100%"
            fontSize={{ base: '12px', '2xl': '14px' }}
            variant="red"
            onClick={closeNewWaiverBlock}
          >
            <CrosshairIcon mr="8px" />
            Cancel
          </Button>
        </Box>
      </Box>
      <Box bg="white" p={{ base: '16px', md: '20px 16px', '2xl': '24px' }}>
        <CustomTextarea
          label="Title"
          mb="8px"
          editMode={true}
          cb={onChangeHandler('title')}
          initialValue={state.title}
        />
        <CustomTextarea
          label="Description"
          mb="16px"
          editMode={true}
          cb={onChangeHandler('description')}
          initialValue={state.description}
        />
      </Box>
    </Box>
  )
}
