import {
  Box,
  Button,
  Heading,
  Skeleton,
  Spacer,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useState } from 'react'

import FormikTwoStyledInput from '@/components/Common/FormikComponents/FormikTwoStyledInput/index'

import { addToOrder } from '@/api/bookings/index'
import { useNewOrder } from '@/libs/hooks/useNewOrder'
import { friendlyTimeWithTZ } from '@/libs'

export default function WalkInBookingModal({
  title,
  eventName,
  date,
  experienceId,
  intervalId
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const { mutate: newOrder, isLoading: isLoadingNewOrder } = useNewOrder()

  const createWalkInBooking = async ({ players, private_game }) => {
    const bookings = [
      {
        date,
        players: parseInt(players),
        eventvariant: experienceId,
        interval: intervalId,
        private_game
      }
    ]

    setLoading(true)

    const calculatedPrices = await addToOrder({ bookings }).catch((err) => {
      toast({
        title: 'Failed to create walk-in',
        description: err.response.data.msg,
        status: 'error',
        duration: 2000,
        isClosable: true
      })
      setLoading(false)
    })

    const { totalAmount } = calculatedPrices
    return newOrder(
      {
        obj: {
          bookings,
          giftcard: '',
          promocode: '',
          firstname: 'Walk IN',
          lastname: 'Walk IN',
          phone: '+12229999999',
          email: 'info@example.com',
          payment: {
            amount: totalAmount,
            payment_source: 'cash'
          }
        }
      },
      {
        onSuccess: () => {
          toast({
            title: 'Booking created!',
            description:
              'Booking successfully created. You will be redirected now',
            status: 'success',
            duration: 2000,
            isClosable: true
          })
          setLoading(false)
        },
        onError: (err) => {
          const msg = err.response.data.msg
          toast({
            title: 'Failed to create walk-in',
            description: msg,
            status: 'error',
            duration: 2000,
            isClosable: true
          })
          setLoading(false)
        }
      }
    )
  }

  return !isOpen ? (
    <Button
      variant="gbase"
      _focus={{ border: 'none' }}
      _hover={{ bg: 'transparent' }}
      fontSize={{ base: '12px', '2xl': '14px' }}
      mr="8px"
      w="100%"
      onClick={onOpen}
    >
      {title}
    </Button>
  ) : (
    <>
      <Button
        variant="link"
        _focus={{ border: 'none' }}
        _hover={{ color: 'custom.blue.500' }}
        fontSize={{ base: '12px' }}
        onClick={onClose}
      >
        Close
      </Button>
      <Heading as="h2" size="sm" mt={2} mb={1} fontWeight={600}>
        {eventName}
      </Heading>
      <Box d="grid" gridTemplateColumns="1fr 3fr">
        <Text color="custom.gray.300">Date:</Text>
        <Box d="flex">
          <Text color="custom.gray.900">
            {friendlyTimeWithTZ(date * 1000, 'MM.DD.YY')}
          </Text>
        </Box>
        <Text color="custom.gray.300">Time:</Text>
        <Box d="flex">
          <Text color="custom.gray.900">
            {friendlyTimeWithTZ(date, 'h:mm A')}
          </Text>
        </Box>
      </Box>
      <Formik
        initialValues={{ players: 0, is_private: false }}
        onSubmit={createWalkInBooking}
      >
        {(formik) => (
          <Form>
            <Spacer my={2} />
            <FormikTwoStyledInput
              onFocus={(ev) => ev.target.select()}
              id="players"
              name="players"
              placeholder="Players"
            />
            <Spacer my={2} />
            {/* <CustomCheckbox
              initialState={false}
              cb={(value) => formik.setFieldValue('private_game', value)}
            >
              Private game?
            </CustomCheckbox> */}

            <Skeleton isLoaded={!loading}>
              <Button
                variant="gbase"
                _focus={{ border: 'none' }}
                _hover={{ bg: 'transparent' }}
                fontSize={{ base: '12px', '2xl': '14px' }}
                mr="8px"
                w="100%"
                type="submit"
              >
                Create Walk-in
              </Button>
            </Skeleton>
          </Form>
        )}
      </Formik>
    </>
  )
}
