import {
  ACCEPT_CHANGES,
  CLONE_ELEMENT_FOR_EDIT,
  SET_DATA,
  SET_EDIT,
  SET_NEW_MODE,
  SET_PROPERTY_VALUE,
  SET_REQUIRED_PROPS
} from '../constants'

export function init(data) {
  return {
    data: data,
    clonedElement: {},
    edit: { enabled: false, idCell: null },
    newMode: false,
    requiredProps: []
  }
}

export function attractionsReducer(state, action) {
  switch (action.type) {
    case SET_PROPERTY_VALUE: {
      const { property, value } = action.payload
      return {
        ...state,
        clonedElement: {
          ...state.clonedElement,
          [property]: value
        }
      }
    }

    case CLONE_ELEMENT_FOR_EDIT: {
      const cloned =
        action.payload !== null
          ? JSON.parse(JSON.stringify(state.data[+action.payload]))
          : {}
      return {
        ...state,
        clonedElement: cloned
      }
    }

    case ACCEPT_CHANGES: {
      const id = action.payload
      return {
        ...state,
        data: state.data.map((el, idx) => {
          if (idx === id) {
            return state.clonedElement
          } else return el
        })
      }
    }

    case SET_EDIT: {
      return {
        ...state,
        edit: action.payload
      }
    }

    case SET_REQUIRED_PROPS: {
      return {
        ...state,
        requiredProps: action.payload
      }
    }

    case SET_DATA: {
      return {
        ...state,
        data: action.payload
      }
    }

    case SET_NEW_MODE: {
      return {
        ...state,
        newMode: action.payload
      }
    }

    default:
      return state
  }
}
