import React, { useMemo } from 'react'
import { useTheme } from '@chakra-ui/react'
import { TABLE_COLUMN_VALUES_STATISTICS_PARTNER } from '../../../../configs'
import TableCreator from '../../../Common/TableCreator'
import { TABLE_COLUMNS_SIZES_STATISTICS_PARTNERS } from '../../../../configs/tablesSizes'

export default function StatisticsPartnersTable({ tableData = [] }) {
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const data = useMemo(() => tableData, [tableData])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: '0 16px'
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 16px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_STATISTICS_PARTNER.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'amount (received $)') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return Math.round(original.amount / 100)
        }
      }

      if (field.Header.toLowerCase() === 'discount ($)') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return Math.round(original.discount / 100)
        }
      }

      return fieldObject
    })
  }, [])
  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sizesMap={TABLE_COLUMNS_SIZES_STATISTICS_PARTNERS}
    />
  )
}
