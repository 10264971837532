import { Box, IconButton, Input } from '@chakra-ui/react'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../assets/Icons/DisketteIcon'
import { useCreatePartner } from '../../../../../libs/hooks/usePartners'

export default function NewPartnerListItem({ setNewPartner }) {
  const queryClient = useQueryClient()
  const [editMode, setEditMode] = useState(false)
  const [updatedObject, setUpdatedObject] = useState({
    name: ''
  })
  const { mutate: createPartner } = useCreatePartner(updatedObject)

  function onEdit(state) {
    return () => setEditMode(state)
  }

  function onChange(e) {
    setUpdatedObject({
      name: e.target.value
    })
  }

  function onSave() {
    onEdit(false)()
    createPartner(
      {},
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(['partners'])
            .then(() => setNewPartner(false))
        }
      }
    )
  }

  return (
    <Box
      p={{ base: editMode ? '8px 16px' : '16px' }}
      bg={editMode ? 'custom.blue.200' : 'white'}
      mb="2px"
      d="flex"
      alignItems="center"
    >
      <Box pr="20px">
        <Input variant="gbase" value={updatedObject.name} onChange={onChange} />
      </Box>
      <Box d="flex" ml="auto">
        <IconButton
          aria-label="edit-partner"
          variant="funcColumnIconButtons"
          icon={<DisketteIcon w="inherit" h="inherit" />}
          w="16px"
          h="16px"
          mr="16px"
          onClick={onSave}
          color="custom.blue.600"
        />
        <IconButton
          aria-label="editAccount"
          variant="funcColumnIconButtons"
          w="16px"
          h="16px"
          onClick={onEdit(false)}
          icon={<CrosshairIcon w="inherit" h="inherit" />}
          color="custom.gray.200"
        />
      </Box>
    </Box>
  )
}
