import { Box, Icon, IconButton, Image, Text } from '@chakra-ui/react'
import { FiAlertCircle, MdBrokenImage } from 'react-icons/all'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import { CopyIcon } from '../../../../assets/Icons/CopyIcon'
import { BASE_URL_WO_SLASH } from '../../../../constants'
import { useContext, useMemo, useState } from 'react'
import { BookingContext } from '../../../../pages/Booking'

export default function PicturesContent() {
  const [data = {}, isLoading] = useContext(BookingContext)
  const framedPhotos = data.result?.framed_photos || []
  const photos = data.result?.photos || []
  const correctDataFramedPhotos = useMemo(() => {
    return framedPhotos?.map((el) => {
      return {
        ...el,
        itsBroken: false
      }
    })
  }, [framedPhotos])

  const correctDataPhotos = useMemo(() => {
    return photos?.map((el) => {
      return {
        ...el,
        itsBroken: false
      }
    })
  }, [photos])

  const [framedPhotosArray, setFramedPhotosArray] = useState(
    correctDataFramedPhotos
  )
  const [photosArray, setPhotosArray] = useState(correctDataPhotos)

  function setBroken(idx, type = '') {
    const arr = type === 'framedPhotos' ? framedPhotosArray : photosArray
    const withBroken = arr.map((el, i) => {
      if (i === idx) {
        return {
          ...el,
          itsBroken: true
        }
      } else {
        return el
      }
    })
    if (type === 'framedPhotos') {
      setFramedPhotosArray(withBroken)
    } else {
      setPhotosArray(withBroken)
    }
  }

  const allPhotos = useMemo(() => {
    const arr = []
    for (let i = 0; i < photos.length; i++) {
      if (framedPhotos[i]) {
        arr.push(framedPhotos[i])
      }
      if (photos[i]) {
        arr.push(photos[i])
      }
    }
    return arr
  }, [photos, framedPhotos])

  return (
    <Box
      d="grid"
      gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
      gridGap="16px"
    >
      {allPhotos?.length !== 0 &&
        allPhotos?.map((img, idx) => (
          <Box
            pos="relative"
            m={{ base: '0 4px 8px', md: '0 8px 16px' }}
            key={'framedPictures' + idx}
          >
            {img.itsBroken ? (
              <Box
                textAlign="center"
                border="1px solid"
                borderColor="custom.gray.200"
                p="20px"
              >
                <Icon as={MdBrokenImage} w="40px" height="40px" />
                <Text
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  color="custom.black.500"
                >
                  Image link broken.
                </Text>
              </Box>
            ) : (
              <>
                <CopyToClipboard text={img.short_url}>
                  <IconButton
                    aria-label="copy"
                    pos="absolute"
                    right="0"
                    top="0"
                    bg="none"
                    _focus={{ border: 'none' }}
                    _hover={{ bg: 'transparent' }}
                    icon={
                      <CopyIcon
                        color="custom.blue.650"
                        w="24px"
                        height="24px"
                      />
                    }
                  />
                </CopyToClipboard>
                <Image
                  src={img.url}
                  onError={() => setBroken(idx, 'framedPhotos')}
                  border="1px solid"
                  borderColor="custom.gray.200"
                  w="100%"
                />
              </>
            )}
          </Box>
        ))}

      {allPhotos?.length === 0 && allPhotos?.length === 0 && (
        <Box textAlign="center" gridColumn={{ base: 'unset', md: '1/3' }}>
          <Icon as={FiAlertCircle} w="40px" height="40px" />
          <Text
            fontSize={{ base: '12px', '2xl': '14px' }}
            color="custom.black.500"
          >
            No photos.
          </Text>
        </Box>
      )}
    </Box>
  )
}
