import { createIcon } from '@chakra-ui/icons'

export const ArrowIcon = createIcon({
  displayName: 'ArrowIcon',
  viewBox: '0 0 10 16',
  boxSize: '16',
  path: (
    <g fill="currentColor">
      <path d="M10 14.12L3.81916 8L10 1.88L8.09717 -8.31755e-08L-3.49691e-07 8L8.09717 16L10 14.12Z" />
    </g>
  )
})
