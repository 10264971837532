import { Box, Button, IconButton, Input, Text } from '@chakra-ui/react'
import { ArrowIcon } from '../../assets/Icons/ArrowIcon'
import { useHistory, useParams } from 'react-router-dom'
import DataRow from '@/components/Common/DataRow'
import { SimpleSkullIcon } from '../../assets/Icons/SimpleSkullIcon'
import { matchMedia768InState, PLAYERS_URL } from '../../constants'
import { useSelector } from 'react-redux'
import {
  usePlayer,
  useToggleBlacklistPlayer
} from '../../libs/hooks/usePlayers'
import PlayerTable from '@/components/PlayersInfo/PlayerTable'
import CardList from '@/components/Common/CardList'
import { useQueryClient } from 'react-query'
import CommentsList from '@/components/Booking/Comments/CommentsList'
import CommentsTable from '@/components/Booking/Comments/CommentsTable'
import PlayerCommentsTable from '@/components/PlayersInfo/PlayerCommentsTable'
import { usePlayerAddComment } from '../../libs/hooks/usePlayerAddComment'
import Preloader from '@/components/Common/Preloader'
import { PlusIcon } from '../../assets/Icons/PlusIcon'
import { useState } from 'react'

export default function PlayerInfo() {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { id: playerId } = useParams()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { data: playersData, refetch: refetchPlayer } = usePlayer(playerId)
  const firstname = playersData?.firstname ? playersData?.firstname : '—'
  const lastname = playersData?.lastname ? playersData?.lastname : '—'
  const phone = playersData?.phone ? playersData?.phone : '—'
  const email = playersData?.email ? playersData?.email : '—'
  const birthdate = playersData?.birthdate ? playersData?.birthdate : '—'
  const games = playersData?.games ? playersData?.games : []
  const inBlacklist = playersData?.is_in_blacklist
  const { mutate: toggleBlacklist } = useToggleBlacklistPlayer()
  const { mutate: sendComment, isLoading: isCommentSending } =
    usePlayerAddComment()
  const commentsData = playersData?.comments || []
  const [commentText, setCommentText] = useState('')
  function sendCommentHandler() {
    sendComment(
      {
        playerId,
        text: commentText
      },
      {
        onSuccess: () => {
          setCommentText('')
          refetchPlayer()
        }
      }
    )
  }
  console.log('commentsData', commentsData)
  console.log('playersData', playersData)
  console.log('playerId', playerId)
  function toggleBlacklistHandler() {
    const toSend = {
      playerId: +playerId,
      isInBlacklist: !inBlacklist
    }
    toggleBlacklist(
      {
        obj: toSend
      },
      {
        onSuccess: () => queryClient.invalidateQueries(['player', +playerId])
      }
    )
  }

  const dataRowStyle = {
    width: { base: '100%', md: 'auto' },
    mr: { base: 'unset', md: '16px' },
    keyStyle: {
      mr: { base: 'unset', md: '8px' }
    }
  }
  return (
    <Box p={{ base: '48px 16px 16px' }} maxW="1100px" m="0 auto">
      <Button
        variant="ghost"
        p="0"
        w={{ md: '100%', '2xl': 'auto' }}
        justifyContent="start"
        mb={{ base: '24px', '2xl': '32px' }}
        onClick={() => history.push(PLAYERS_URL)}
      >
        <Text variant="boldTitle" mb="0">
          <ArrowIcon
            w="auto"
            height="16px"
            mr="16px"
            mb="2px"
            color="custom.gray.200"
          />
          Player info
        </Text>
      </Button>
      <Box
        p={{ base: '16px' }}
        bg="white"
        mb="2px"
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Box
          d="flex"
          flexDir={{ base: 'column', md: 'row' }}
          flexWrap={{ base: 'nowrap', md: 'wrap' }}
          w={{ base: 'auto', md: '80%' }}
        >
          <DataRow title="First name" value={firstname} {...dataRowStyle} />
          <DataRow title="Last name" value={lastname} {...dataRowStyle} />
          <DataRow title="Phone" value={phone} {...dataRowStyle} />
          <DataRow title="Email" value={email} {...dataRowStyle} />
          <DataRow title="Birth date" value={birthdate} {...dataRowStyle} />
        </Box>
        <IconButton
          variant="blackChecked"
          w={{ base: '100%', md: '45px' }}
          height={{ base: '30px', md: '45px' }}
          borderRadius={{ base: 'unset', md: '50%' }}
          minW="unset"
          ml={{ base: 'unset', md: 'auto' }}
          onClick={toggleBlacklistHandler}
          icon={
            <SimpleSkullIcon
              color={inBlacklist ? 'custom.red.600' : 'custom.gray.900'}
              height={{ base: '14px', md: '30px' }}
              w={{ base: '15px', md: '30px' }}
            />
          }
        />
      </Box>
      <Box p={{ base: '16px', md: '24px' }} bg="white" mb="2px">
        <Text
          fontSize={{ base: '18px', '2xl': '24px' }}
          color="custom.black.500"
          fontWeight="500"
        >
          Played games
        </Text>
      </Box>
      {games?.length === 0 ? (
        <Box
          textAlign="center"
          color="custom.gray.300"
          bg="white"
          p="16px 0"
          mb="2px"
          fontSize={{ base: '12px', md: '14px' }}
        >
          This player has not participated in any games
        </Box>
      ) : (
        <>
          {isLargerThan768 ? (
            <PlayerTable tableData={games} isLoading={false} />
          ) : (
            <CardList data={games} isLoading={false} />
          )}
        </>
      )}
      <Box p={{ base: '16px', md: '24px' }} bg="white" mb="2px">
        <Text
          fontSize={{ base: '18px', '2xl': '24px' }}
          color="custom.black.500"
          fontWeight="500"
        >
          Comments
        </Text>
      </Box>
      <Box
        bg="white"
        p={{ base: '16px', '2xl': '24px' }}
        mb="2px"
        fontSize={{ base: '12px', '2xl': '14px' }}
        d={{ base: 'block', md: 'flex' }}
      >
        <Input
          placeholder="Write comment"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          fontSize="inherit"
          fontWeight="400"
          p="0 16px"
          w="100%"
          mb="8px"
          _placeholder={{
            color: 'custom.gray.300'
          }}
          variant="gbase"
        />
        {isCommentSending ? (
          <Preloader
            w={{ base: '100%', md: 'auto' }}
            m="0"
            ml={{ base: 'unset', md: '8px', '2xl': '16px' }}
            minW={{ base: '140px', '2xl': '160px' }}
            height="40px"
          />
        ) : (
          <Button
            variant="blue"
            w={{ base: '100%', md: 'auto' }}
            ml={{ base: 'unset', md: '8px', '2xl': '16px' }}
            minW={{ base: '140px', '2xl': '160px' }}
            fontWeight="500"
            onClick={sendCommentHandler}
          >
            <PlusIcon
              w={{ base: '12px', '2xl': '14px' }}
              height={{ base: '12px', '2xl': '14px' }}
              color="white"
              mr="8px"
            />
            Add comment
          </Button>
        )}
      </Box>
      {commentsData?.length === 0 ? (
        <Box
          textAlign="center"
          color="custom.gray.300"
          bg="white"
          p="16px 0"
          mb="2px"
          fontSize={{ base: '12px', md: '14px' }}
        >
          No comments
        </Box>
      ) : (
        <>
          {!isLargerThan768 && <CommentsList data={commentsData} />}
          {isLargerThan768 && <PlayerCommentsTable tableData={commentsData} />}
        </>
      )}
    </Box>
  )
}
