import { useField } from 'formik'

import NewOrderInput from '../../../NewOrder/NewOrderInput'

export default function FormikOrderInput({ ...rest }) {
  const { name = '' } = rest
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  return <NewOrderInput isError={touched && error} field={field} {...rest} />
}
