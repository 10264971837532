import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useTheme
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import ResendNotificationModal from '../ResendNotificationModal'

export default function StartGameErrorModal({
  isOpen,
  onOpen,
  onClose,
  errors = null
}) {
  const theme = useTheme()
  const { id } = useParams()

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="16px">Start game error:</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb="24px">
            {errors !== null &&
              Object.keys(errors).length !== 0 &&
              Object.keys(errors)?.map((key, idx) => {
                const isArray = Array.isArray(errors[key])
                return (
                  <Text
                    fontWeight="400"
                    fontSize={{ base: '12px', '2xl': '14px' }}
                    key={idx + 'customWarningToast'}
                    textTransform="capitalize"
                  >
                    {isArray
                      ? errors[key]?.map((msg) => msg).join(',')
                      : errors[key]}
                  </Text>
                )
              })}
          </ModalBody>

          <ModalFooter d="grid" gridTemplateColumns="1fr 1fr" gridGap="8px">
            <ResendNotificationModal />
            <Button variant="red" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
