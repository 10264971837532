import {
  Box,
  Button,
  IconButton,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import InputMask from 'react-input-mask'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import GiftCardsCard from '../../GiftCardsCard'
import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../assets/Icons/DisketteIcon'
import { PencilIcon } from '../../../../assets/Icons/PencilIcon'
import {
  correctionPhoneNumber,
  friendlyTime,
  friendlyTimeWithTZ
} from '../../../../libs'
import {
  useGiftCardDesigns,
  useUpdateGiftCard
} from '../../../../libs/hooks/useGiftCards'
import { editGiftcardModalValidation } from '../../../../configs/validation/editGiftcardModalValidation'
import {
  matchMedia768InState,
  PERMISSION_GIFTCARDS,
  PHONE_INPUT_MASK
} from '../../../../constants'
import DatePickerModal from '../../../Booking/Modals/DatePickerModal'
import CustomCheckbox from '../../../Common/CustomCheckbox'
import CustomWarningToast from '../../../Common/CustomWarningToast'
import FormikTwoStyledInput from '../../../Common/FormikComponents/FormikTwoStyledInput'
import PermissionRequired from '../../../Common/PermissionRequired'

export default function EditGiftCardModal({ original = {} }) {
  const queryClient = useQueryClient()
  const toast = useToast()
  const giftcardsPermission = PermissionRequired(PERMISSION_GIFTCARDS, true)
  const giftcardId = original?.id
  const recipient_name = original?.recipient_name
    ? original?.recipient_name
    : ''
  const recipient_email = original?.recipient_email
    ? original?.recipient_email
    : ''
  const recipient_phone = original?.recipient_phone
    ? original?.recipient_phone
    : ''
  const buyer_name = original?.buyer_name ? original?.buyer_name : ''
  const buyer_email = original?.buyer_email ? original?.buyer_email : ''
  const buyer_phone = original?.buyer_phone ? original?.buyer_phone : ''
  const send_email_on = original?.send_email_on
  const deliver_date = original?.deliver_date ? original?.deliver_date : ''
  const add_personal_message = original?.add_personal_message
  const personal_message = original?.personal_message
    ? original?.personal_message
    : ''
  const giftcard = original?.giftcard ? original?.giftcard : ''
  const giftcard_design = original?.giftcard_design
    ? original?.giftcard_design
    : ''
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { mutate: editGiftcard } = useUpdateGiftCard()
  const { data: { results: cardsArray = [] } = {} } = useGiftCardDesigns()
  const formik = useFormik({
    initialValues: {
      recipientName: recipient_name,
      recipientEmail: recipient_email,
      recipientPhone: correctionPhoneNumber(recipient_phone, 'show'),
      buyerName: buyer_name,
      buyerEmail: buyer_email,
      buyerPhone: correctionPhoneNumber(buyer_phone, 'show'),
      sendEmailOn: send_email_on,
      deliverDate: deliver_date,
      deliverDateText: deliver_date
        ? friendlyTime(deliver_date * 1000, 'MM.DD.YY')
        : '',
      addPersonalMessage: add_personal_message,
      personalMessage: personal_message,
      giftcard: giftcard,
      giftcardDesign: giftcard_design
    },
    onSubmit: submitHandler,
    validationSchema: editGiftcardModalValidation,
    enableReinitialize: true
  })
  const formikHandleSubmit = formik.handleSubmit
  const setFieldValue = formik.setFieldValue
  const formikValues = formik.values

  function setDateHandler(condition) {
    return (value) => {
      if (condition) {
        setFieldValue('deliverDate', value)
        setFieldValue('deliverDateText', friendlyTimeWithTZ(value, 'MM.DD.YY'))
      }
    }
  }

  useEffect(() => {
    if (!formikValues.sendEmailOn) {
      setFieldValue('deliverDateText', '')
      setFieldValue('personalMessage', '')
      setFieldValue('addPersonalMessage', false)
      setFieldValue('deliverDate', '')
    }
  }, [formikValues.sendEmailOn])

  useEffect(() => {
    if (!formikValues.addPersonalMessage) {
      setFieldValue('personalMessage', '')
    }
  }, [formikValues.addPersonalMessage])

  function generateWarningToast({ text, id, incomingText, msgObject }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 4000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={incomingText ? incomingText : text}
              close={toast.closeAll}
              msgObjects={msgObject}
            />
          )
        })
      }
    }
  }

  function submitHandler(values) {
    const toSend = {
      ...values,
      recipientPhone: correctionPhoneNumber(values.recipientPhone, 'send'),
      buyerPhone: correctionPhoneNumber(values.buyerPhone, 'send'),
      giftcardId: giftcardId,
      deliverDate: values.deliverDate
        ? Math.round(values.deliverDate / 1000)
        : ''
    }

    editGiftcard(
      { obj: toSend },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['giftcards'])
          onClose()
        },
        onError: (err) => {
          const data = err?.response?.data
          generateWarningToast({
            id: 'editGiftCard-error-toast',
            msgObject: data
          })()
        }
      }
    )
  }

  function giftCardClickHandler(card) {
    setFieldValue('giftcardDesign', card.id)
  }

  const giftCards = useMemo(() => {
    return cardsArray.map((card) => (
      <GiftCardsCard
        key={card.id}
        card={card}
        isChecked={formikValues.giftcardDesign === card.id}
        onCLick={giftCardClickHandler}
      />
    ))
  }, [formikValues.giftcardDesign, cardsArray])

  return (
    <>
      {isLargerThan768 ? (
        <IconButton
          aria-label="delete-gate"
          color="custom.gray.200"
          variant="funcColumnIconButtons"
          onClick={onOpen}
          isDisabled={!giftcardsPermission}
          icon={<PencilIcon w="inherit" h="inherit" />}
        />
      ) : (
        <Button
          variant="lightBlue"
          w="100%"
          onClick={onOpen}
          isDisabled={!giftcardsPermission}
          fontSize={{ base: '12px', '2xl': '14px' }}
        >
          Edit
        </Button>
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '720px', '2xl': '656px' }}
        >
          <FormikProvider value={formik}>
            <>
              <ModalHeader mb="34px" d="flex">
                Edit giftcard
                <Box
                  ml="auto"
                  d={{ base: 'none', md: 'grid' }}
                  gridTemplateColumns="1fr 1fr"
                  gridColumnGap="8px"
                  fontSize={{ base: '12px' }}
                >
                  <Button
                    w="100%"
                    variant="blue"
                    fontSize="inherit"
                    onClick={formikHandleSubmit}
                  >
                    <DisketteIcon mr="8px" />
                    Save
                  </Button>
                  <Button
                    w="100%"
                    variant="red"
                    fontSize="inherit"
                    onClick={onClose}
                  >
                    <CrosshairIcon mr="8px" />
                    Cancel
                  </Button>
                </Box>
              </ModalHeader>
              <ModalCloseButton d={{ md: 'none' }} />
              <ModalBody
                fontSize={{ base: '12px' }}
                d={{ md: 'grid' }}
                gridTemplateColumns={{ md: 'repeat(3, 1fr)' }}
                gridColumnGap={{ md: '16px' }}
              >
                <FormikTwoStyledInput
                  id="recipientName"
                  name="recipientName"
                  placeholder="Recipient Name"
                  mb={{ base: '8px', md: '16px' }}
                />
                <FormikTwoStyledInput
                  id="recipientEmail"
                  name="recipientEmail"
                  placeholder="Recipient Email"
                  mb={{ base: '8px', md: '16px' }}
                />
                <FormikTwoStyledInput
                  id="recipientPhone"
                  name="recipientPhone"
                  placeholder="Recipient Phone"
                  mb={{ base: '8px', md: '16px' }}
                  input={{
                    as: InputMask,
                    mask: PHONE_INPUT_MASK
                  }}
                />

                <FormikTwoStyledInput
                  id="buyerName"
                  name="buyerName"
                  placeholder="Buyer Name"
                  mb={{ base: '8px', md: '16px' }}
                />
                <FormikTwoStyledInput
                  id="buyerEmail"
                  name="buyerEmail"
                  placeholder="Buyer Email"
                  mb={{ base: '8px', md: '16px' }}
                />
                <FormikTwoStyledInput
                  id="buyerPhone"
                  name="buyerPhone"
                  placeholder="Buyer Phone"
                  input={{
                    as: InputMask,
                    mask: PHONE_INPUT_MASK
                  }}
                  mb={{ base: '8px', md: '16px' }}
                />
                <Box gridColumn={{ md: '1/4' }}>
                  <CustomCheckbox
                    mb={{ base: '8px', md: '16px' }}
                    initialState={formikValues.sendEmailOn}
                    cb={(value) => setFieldValue('sendEmailOn', value)}
                  >
                    Send email on (optional)
                  </CustomCheckbox>
                  <InputGroup>
                    <FormikTwoStyledInput
                      id="deliverDateText"
                      name="deliverDateText"
                      placeholder="Pick a date when the
                                                                      E-Gift Certificate should be delivered."
                      isDisabled={true}
                      mb={{ base: '8px', md: '16px' }}
                    />
                    <InputRightElement h="56px">
                      {formikValues.sendEmailOn && (
                        <DatePickerModal
                          cb={setDateHandler(formikValues.sendEmailOn)}
                          returnMilliseconds={true}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </ModalBody>
              <CustomCheckbox
                mb={{ base: '8px', md: '16px' }}
                isDisabled={!formikValues.sendEmailOn}
                initialState={formikValues.addPersonalMessage}
                cb={(value) => setFieldValue('addPersonalMessage', value)}
              >
                Add personal message (optional)
              </CustomCheckbox>
              <FormikTwoStyledInput
                id="personalMessage"
                name="personalMessage"
                placeholder="Personal message"
                isDisabled={
                  !formikValues.addPersonalMessage || !formikValues.sendEmailOn
                }
                mb={{ base: '8px', md: '16px' }}
              />
              <Text
                as="span"
                fontSize={{ base: '14px', '2xl': '18px' }}
                fontWeight="500"
                color="custom.black.500"
                mb={{ base: '8px', '2xl': '16px' }}
              >
                Card design
              </Text>
              <SimpleGrid
                columns={{ base: 1, md: 3 }}
                spacing="16px"
                mb={{ base: '8px', md: '16px' }}
              >
                {giftCards}
              </SimpleGrid>
              <ModalFooter
                d={{ base: 'grid', md: 'none' }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="8px"
              >
                <Button w="100%" variant="blue" onClick={formikHandleSubmit}>
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button w="100%" variant="red" onClick={onClose}>
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </ModalFooter>
            </>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  )
}
