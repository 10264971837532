import { Box, Button, IconButton, Text } from '@chakra-ui/react'
import { ArrowIcon } from '../../assets/Icons/ArrowIcon'
import OrderDescription from '@/components/Booking/OrderDescription'
import GameStatus from '@/components/Booking/GameStatus'
import Comments from '@/components/Booking/Comments'
import Pictures from '@/components/Booking/Pictures'
import Payments from '@/components/Booking/Payments'
import Waivers from '@/components/Booking/Waivers'
import { BOOKINGS_URL, COMPLETED, matchMedia1440InState } from '../../constants'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useBooking } from '../../libs/hooks/useBooking'
import { createContext, useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import { CopyIconWithoutBg } from '../../assets/Icons/CopyIconWithoutBg'

export const BookingContext = createContext(null)

export default function Booking({ bookingCode = '000000' }) {
  const history = useHistory()
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const { id } = useParams()
  const [filters, setFilters] = useState({})
  const paymentFilter = filters?.payments || null

  const paymentFilterQueries = {
    type: {
      up: 'payment_source',
      down: '-payment_source'
    },
    total: {
      up: 'amount',
      down: '-amount'
    },
    user: {
      up: 'raw_data__receipt_email',
      down: '-raw_data__receipt_email'
    },
    Identifier: {
      up: 'raw_data__id',
      down: '-raw_data__id'
    }
  }

  const queryKeysBooking = useMemo(() => {
    const queryFilter = {
      paymentsOrdering: paymentFilter?.header
        ? paymentFilterQueries[paymentFilter?.header][paymentFilter?.sort]
        : null
    }
    const array = ['booking', id]
    Object.keys(queryFilter).forEach((key) => {
      if (queryFilter[key]) {
        array.push(`${key}:${queryFilter[key]}`)
      }
    })
    return array
  }, [filters])

  const { data = {}, isLoading } = useBooking(
    id,
    {
      paymentsOrdering: paymentFilter?.header
        ? paymentFilterQueries[paymentFilter?.header][paymentFilter?.sort]
        : null
    },
    {
      retry: false,
      select: (d) => {
        const bookingStartHandler = d?.start_time
        const bookingStart =
          bookingStartHandler[bookingStartHandler?.length - 1] === 'Z'
            ? bookingStartHandler
            : bookingStartHandler + 'Z'
        return {
          ...d,
          start_time: bookingStart
        }
      },
      onError: () => history.push(BOOKINGS_URL)
    }
  )

  const code = data?.code
  const type = data?.status?.toUpperCase() || ''

  return (
    <BookingContext.Provider
      value={[data, isLoading, filters, setFilters, queryKeysBooking]}
    >
      <Box p={{ base: '24px 16px', md: '48px 24px', '2xl': '88px 48px' }}>
        <Box d="flex" alignItems="center">
          <Button
            variant="ghost"
            fontFamily="inherit"
            d="flex"
            p="0"
            w={{ md: 'auto' }}
            justifyContent="start"
            mb="24px"
            h="auto"
            alignItems="center"
            flexWrap="wrap"
            onClick={() => history.push(BOOKINGS_URL)}
          >
            <ArrowIcon
              w="auto"
              height="16px"
              mr="16px"
              mb="2px"
              color="custom.gray.200"
            />
            <Text
              fontSize={{ base: '24px', '2xl': '32px' }}
              fontWeight={{ base: '600' }}
              color="custom.black.500"
              lineHeight="164%"
            >
              Booking
            </Text>
            <Text
              as={'span'}
              fontSize={{ base: '24px', '2xl': '32px' }}
              fontWeight={{ base: '600' }}
              color={'custom.blue.600'}
              ml="6px"
            >
              #{code ? code : bookingCode}
            </Text>
          </Button>
          <CopyToClipboard text={code}>
            <IconButton
              aria-label="copy-link"
              bg="none"
              w="20px"
              height="20px"
              minW="unset"
              minH="unset"
              ml="8px"
              mb="24px"
              p="0"
              _focus={{ border: 'none' }}
              _hover={{ bg: 'transparent' }}
              icon={
                <CopyIconWithoutBg
                  w="100%"
                  height="100%"
                  color="custom.gray.200"
                />
              }
            />
          </CopyToClipboard>
        </Box>

        <Box
          d={{ base: 'flex', '2xl': 'grid' }}
          flexDir={{ base: 'column', '2xl': 'unset' }}
          gridTemplateColumns={{ '2xl': '1fr 2fr' }}
          gridColumnGap={{ '2xl': '32px' }}
          gridAutoFlow={{ '2xl': 'column' }}
        >
          {!isLargerThan1440 && <OrderDescription />}
          <Box
            gridColumn={{ '2xl': '1/2' }}
            alignSelf={{ '2xl': 'start' }}
            order={{ base: '' }}
          >
            <GameStatus />
            {type === COMPLETED && isLargerThan1440 && <Pictures />}
          </Box>
          <Box
            gridColumn={{ '2xl': '2/3' }}
            alignSelf={{ '2xl': 'start' }}
            d={{ base: 'flex', '2xl': 'unset' }}
            flexDir={{ base: 'column', '2xl': 'unset' }}
            mb="100px"
          >
            {isLargerThan1440 && <OrderDescription />}
            <Comments order={2} />
            {type === COMPLETED && !isLargerThan1440 && <Pictures order={1} />}
            <Payments order={3} />
            <Waivers order={4} />
          </Box>
        </Box>
      </Box>
    </BookingContext.Provider>
  )
}
