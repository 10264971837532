import { Box } from '@chakra-ui/react'
import { BASE_URL_WO_SLASH } from '../../../constants'

export default function GiftCardsCard({ card, isChecked, onCLick }) {
  const { image } = card
  const bgImageLink = image ? image : ''

  return (
    <Box
      cursor="pointer"
      h="158px"
      bgColor={image ? '' : 'custom.lightGray'}
      bgImage={bgImageLink}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      borderRadius="8px"
      borderColor={isChecked ? 'custom.blue.600' : 'custom.borderBlue'}
      borderWidth={isChecked ? '2px' : '1px'}
      onClick={() => onCLick(card)}
    ></Box>
  )
}
