import { api } from '../index'
import { correctionTimezone } from '../../libs'

export async function getGiftCards({ search, page, pageSize }) {
  try {
    const { data } = await api.get('/api/giftcard-purchases/', {
      params: {
        search,
        page,
        page_size: pageSize
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function createGiftCard({
  recipientName,
  recipientEmail,
  recipientPhone,
  buyerName,
  buyerEmail,
  buyerPhone,
  sendEmailOn,
  deliverDate,
  addPersonalMessage,
  personalMessage,
  payData,
  giftcard,
  giftcardDesign
}) {
  const correctedDeliverDate = correctionTimezone(deliverDate, true)
  const objToSend = {
    recipient_name: recipientName,
    recipient_email: recipientEmail,
    recipient_phone: recipientPhone,
    buyer_name: buyerName,
    buyer_email: buyerEmail,
    buyer_phone: buyerPhone,
    send_email_on: sendEmailOn,
    ...(sendEmailOn && { deliver_date: correctedDeliverDate }),
    add_personal_message: addPersonalMessage,
    personal_message: personalMessage,
    payment_information: payData,
    giftcard: giftcard,
    giftcard_design: giftcardDesign
  }
  const final = {}

  Object.keys(objToSend).forEach((key) => {
    if (typeof objToSend[key] === 'boolean' || !!objToSend[key]) {
      final[key] = objToSend[key]
    }
  })

  const { data } = await api.post('/api/giftcard-purchases/', final)
  return data
}

export async function updateGiftCard({
  giftcardId,
  recipientName,
  recipientEmail,
  recipientPhone,
  buyerName,
  buyerEmail,
  buyerPhone,
  sendEmailOn,
  deliverDate,
  addPersonalMessage,
  personalMessage,
  paymentInformation,
  giftcard,
  giftcardDesign
}) {
  const correctedDeliverDate = correctionTimezone(deliverDate, true)
  const objToSend = {
    recipient_name: recipientName,
    recipient_email: recipientEmail,
    recipient_phone: recipientPhone,
    buyer_name: buyerName,
    buyer_email: buyerEmail,
    buyer_phone: buyerPhone,
    send_email_on: sendEmailOn,
    ...(sendEmailOn && { deliver_date: correctedDeliverDate }),
    ...(sendEmailOn === false && { deliver_date: null }),
    add_personal_message: addPersonalMessage,
    personal_message: addPersonalMessage ? personalMessage : null,
    payment_information: paymentInformation,
    giftcard: giftcard,
    giftcard_design: giftcardDesign
  }
  const final = {}
  Object.keys(objToSend).forEach((key) => {
    if (
      typeof objToSend[key] === 'boolean' ||
      !!objToSend[key] ||
      key === 'deliver_date' ||
      key === 'personal_message'
    ) {
      final[key] = objToSend[key]
    }
  })

  const { data } = await api.patch(
    `/api/giftcard-purchases/${giftcardId}/`,
    final
  )
  return data
}

export async function deleteGiftCard({ giftcardId }) {
  try {
    const { data } = await api.delete(`/api/giftcard-purchases/${giftcardId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getGiftCardDesigns() {
  try {
    const { data } = await api.get(`/api/carddesign/`)
    return data
  } catch (e) {
    console.log(e)
  }
}
