import {
  Box,
  Button,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import React from 'react'
import DatePicker from 'react-datepicker'
import InputMask from 'react-input-mask'

import {
  correctionPhoneNumber,
  friendlyTime,
  generateWarningToast
} from '../../../../libs'
import { useAddNewPlayer } from '../../../../libs/hooks/useAddNewPlayer'
import { addPlayerValidation } from '../../../../configs/validation/addPlayerValidation'
import { PHONE_INPUT_MASK } from '../../../../constants'
import { CustomInputDatePicker } from '../../../Common/CustomInput'
import FormikTwoStyledInput from '../../../Common/FormikComponents/FormikTwoStyledInput'

export default function AddPlayerModal({ refetchFunc }) {
  const theme = useTheme()
  const toast = useToast()
  const customColors = theme.colors.custom
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: addNewPlayer } = useAddNewPlayer()
  const formik = useFormik({
    validationSchema: addPlayerValidation,
    initialValues: {
      firstname: '',
      lastname: '',
      birthdate: '',
      phone: '',
      inBlacklist: false,
      email: ''
    },
    onSubmit: (values) => {
      const toSend = {
        ...values,
        birthdate: values?.birthdate
          ? friendlyTime(values?.birthdate, 'yyyy-MM-DD')
          : '',
        phone: values?.phone ? correctionPhoneNumber(values?.phone, 'send') : ''
      }

      addNewPlayer(toSend, {
        onSuccess: () => {
          refetchFunc()
          onCloseHandler()
        },
        onError: (err) => {
          const msg = err?.response?.data
          generateWarningToast({
            msgObjects: msg,
            toast,
            id: 'add-new-player-modal'
          })
        }
      })
    }
  })

  const [datePickerError, setDatePickerError] = useState(false)
  const formikValues = formik.values
  const formikErrors = formik.errors
  const formikTouched = formik.touched
  const birthdateError =
    formikErrors?.hasOwnProperty('birthdate') &&
    formikTouched?.hasOwnProperty('birthdate')
  const birthdate = formikValues.birthdate
  const setFieldValue = formik.setFieldValue
  const inBlacklist = formikValues.inBlacklist
  const handleSubmit = formik.handleSubmit
  const resetForm = formik.resetForm

  console.log('formik ', formikValues)

  function onCloseHandler() {
    resetForm()
    onClose()
  }

  function dateChangeHandler(val) {
    setFieldValue('birthdate', val)
  }

  function onChangeSwitch(e) {
    setFieldValue('inBlacklist', e.target.checked)
  }

  function onClickHandler() {
    handleSubmit()
  }

  return (
    <>
      <Button onClick={onOpen} variant="lightBlue" w="100%">
        Add player
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onCloseHandler}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w={{ base: '90%', md: '100%' }}
            maxW={{ base: 'unset', md: '720px', '2xl': '1100px' }}
          >
            <ModalHeader mb="16px">Add player</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              mb="16px"
              d="grid"
              gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gridGap="16px"
            >
              <FormikTwoStyledInput placeholder="Firstname" name="firstname" />
              <FormikTwoStyledInput placeholder="Lastname" name="lastname" />
              <InputGroup mb="unset">
                <DatePicker
                  showYearDropdown={true}
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown={true}
                  showMonthDropdown={true}
                  onChange={dateChangeHandler}
                  dateFormat="yyyy-MM-dd"
                  selected={birthdate}
                  minDate={new Date(1900, 1, 1)}
                  maxDate={new Date()}
                  customInput={
                    <CustomInputDatePicker
                      setExternalError={setDatePickerError}
                      useExternalError={true}
                      disableErrors={true}
                      mask="9999-99-99"
                      bg={birthdateError ? 'custom.red.50' : 'custom.lightGray'}
                      mb="unset"
                    />
                  }
                />
              </InputGroup>
              <FormikTwoStyledInput placeholder="Email" name="email" />
              <FormikTwoStyledInput
                name="phone"
                placeholder="Phone"
                input={{
                  mask: PHONE_INPUT_MASK,
                  as: InputMask
                }}
              />
              <Box d="flex" alignItems="center">
                <Text
                  color="custom.gray.900"
                  fontSize={{ base: '12px', md: '14px', '2xl': '16px' }}
                >
                  Add to blacklist?
                </Text>
                <Switch
                  ml={{ base: 'auto' }}
                  size="md"
                  colorScheme="red"
                  isChecked={inBlacklist}
                  onChange={onChangeSwitch}
                />
              </Box>
            </ModalBody>
            <ModalFooter
              d="grid"
              gridTemplateColumns={'1fr 1fr'}
              mt={{ base: '16px' }}
              gridGap={{ base: '8px', md: '16px' }}
            >
              <Button variant="lightBlue" w="100%" onClick={onClose}>
                Close
              </Button>
              <Button variant="blue" w="100%" onClick={onClickHandler}>
                Add player
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
