import { Box, useDisclosure } from '@chakra-ui/react'
import { useRef } from 'react'

import BasePricingAccordion from './BasePricingAccrodion'
import BasePricingElementHeader from './BasePricingElementHeader'
import BasePricingItem from './BasePricingItem'
import NewBasePricingItem from './NewBasePricingItem'
import { PERMISSION_EVENT } from '../../../../../../../constants'
import PermissionRequired from '../../../../../../Common/PermissionRequired'

export default function BasePricingList({
  expanded = false,
  timeLetter = '',
  eventVariantId = '',
  minPlayersLimitation = null,
  maxPlayersLimitation = null,
  slots = []
}) {
  const data = slots
  const {
    isOpen: isAddNewOpen,
    onOpen: onAddNewOpen,
    onClose: onAddNewClose
  } = useDisclosure()
  const basePricePermission = PermissionRequired(PERMISSION_EVENT, true)
  const accordionRef = useRef(null)
  function timesOfDay(timeLetter) {
    switch (timeLetter) {
      case 'M': {
        return 'Morning (M)'
      }

      case 'E': {
        return 'Evening (E)'
      }

      case 'W': {
        return 'Weekend (W)'
      }

      default: {
        return 'unset'
      }
    }
  }

  function onClickPlusButton(isExpanded) {
    return (e) => {
      if (!isExpanded) {
        accordionRef.current.click()
      }
      e.stopPropagation()
      onAddNewOpen()
    }
  }

  const isEmptyAccordion = data.length === 0 && !isAddNewOpen

  return (
    <BasePricingAccordion
      defaultIndex={expanded ? [0] : null}
      isDisabled={isEmptyAccordion}
      needProps={true}
      styles={{
        button: {
          ref: accordionRef
        }
      }}
    >
      <BasePricingElementHeader
        basePricePermission={basePricePermission}
        timeLetter={timesOfDay(timeLetter)}
        onClickPlusButton={onClickPlusButton}
      />
      <Box>
        {isAddNewOpen && (
          <NewBasePricingItem
            onClose={onAddNewClose}
            timeLetter={timeLetter}
            minPlayersLimitation={minPlayersLimitation}
            maxPlayersLimitation={maxPlayersLimitation}
            eventVariantId={eventVariantId}
          />
        )}
        {data.length === 0 && !isAddNewOpen && (
          <Box
            h="40px"
            d="flex"
            alignItems="center"
            fontWeight="500"
            color="custom.gray.300"
            justifyContent="center"
          >
            No slots
          </Box>
        )}
        {data
          ?.sort((a, b) => a.min_players - b.min_players)
          .map((el, idx) => (
            <BasePricingItem
              key={'BasePricingList' + idx}
              minPlayersLimitation={minPlayersLimitation}
              maxPlayersLimitation={maxPlayersLimitation}
              minPlayers={el.min_players}
              maxPlayers={el.max_players}
              price={el.price}
              timeLetter={timeLetter}
              eventVariantId={eventVariantId}
              priceId={el.id}
            />
          ))}
      </Box>
    </BasePricingAccordion>
  )
}
