import { useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import ButtonBlue from '../ButtonBlue'
import ButtonGray from '../ButtonGray'
import { useNotification } from '../NotificationService'
import { BASE_URL_WO_SLASH } from '../../../constants'

export default function Signature() {
  const { values, setFieldValue, errors, isSubmitting, isValidating } =
    useFormikContext()
  const error = errors['signaturePhoto']
  const toast = useNotification()
  const waiver = values?.selectedProfile?.waiver
  const sign = waiver?.sign
  const itsNewProfile = values.itsNewProfile
  const [additionalError, setAdditionalError] = useState(false)
  const isEditMode = values.isEditMode
  const signaturePhoto = values?.signaturePhoto
  const signWrapper = useRef(null)
  const signRef = useRef(null)
  const [signSize, setSignSize] = useState({
    w: '',
    h: ''
  })
  const [signTouched, setSignTouched] = useState(false)
  useEffect(() => {
    if (signTouched && isSubmitting && !isValidating) {
      if (!additionalError) {
        setAdditionalError(true)
      }
      toast({ title: 'Signature error', message: 'Please, save signature.' })
    }
  }, [isSubmitting, isValidating])

  function onStartHandler() {
    if (!signTouched) {
      setSignTouched(true)
    }
  }

  useEffect(() => {
    if (signWrapper !== null) {
      setSignSize({
        w: signWrapper.current.clientWidth,
        h: signWrapper.current.clientHeight
      })
    }
  }, [signWrapper])

  function deleteImageHandler() {
    setFieldValue('signaturePhoto', null)
    setSignTouched(false)
    signRef?.current?.clear()
  }

  function saveSign() {
    if (additionalError) {
      setAdditionalError(false)
    }

    setSignTouched(false)
    const dataURL = signRef?.current?.toDataURL('image')
    setFieldValue('signaturePhoto', dataURL)
  }

  return (
    <div className="waiver-widget--signature">
      <span className="waiver-widget--title-text">Signature</span>
      {error && (
        <div className="waiver-widget--notif error">You must sign waiver!</div>
      )}
      <div className="waiver-widget--signature-container" ref={signWrapper}>
        {((sign && !isEditMode) || signaturePhoto !== null) && (
          <img src={signaturePhoto === null ? sign : signaturePhoto} />
        )}
        {signaturePhoto === null && (
          <SignatureCanvas
            penColor={'black'}
            ref={signRef}
            onBegin={onStartHandler}
            canvasProps={{
              width: signSize.w,
              height: signSize.h,
              className: 'sigCanvas'
            }}
          />
        )}
      </div>
      <div className="waiver-widget--buttons-container">
        {(((signaturePhoto !== null || signTouched) && itsNewProfile) ||
          (isEditMode && (signaturePhoto !== null || signTouched))) && (
          <ButtonGray onClick={deleteImageHandler}>Clear</ButtonGray>
        )}

        {signTouched && (
          <ButtonBlue onClick={saveSign}>Save signature</ButtonBlue>
        )}
      </div>
    </div>
  )
}
