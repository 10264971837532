import {
  Box,
  Button,
  IconButton,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import InputMask from 'react-input-mask'
import { useQueryClient } from 'react-query'

import { PencilIcon } from '../../../../assets/Icons/PencilIcon'
import {
  correctionPhoneNumber,
  friendlyTime,
  generateWarningToast
} from '../../../../libs'
import { useEditPlayer } from '../../../../libs/hooks/useEditPlayer'
import { usePlayer } from '../../../../libs/hooks/usePlayers'
import { editPlayerValidation } from '../../../../configs/validation/editPlayerValidation'
import { PHONE_INPUT_MASK } from '../../../../constants'
import { CustomInputDatePicker } from '../../../Common/CustomInput'
import FormikTwoStyledInput from '../../../Common/FormikComponents/FormikTwoStyledInput'
import Preloader from '../../../Common/Preloader'

export default function EditPlayerModal({
  playerId,
  refetchFunction,
  btn = {}
}) {
  const theme = useTheme()
  const toast = useToast()
  const customColors = theme.colors.custom
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    data: player = {},
    isLoading: isLoadingPlayer,
    remove: resetCache
  } = usePlayer(playerId, {
    enabled: isOpen && !!playerId
  })
  const { mutate: editPlayer } = useEditPlayer()
  const firstname = player?.firstname || ''
  const lastname = player?.lastname || ''
  const phone = player?.phone
    ? correctionPhoneNumber(player?.phone, 'show')
    : ''
  console.log('phone', phone)
  const inBlacklistInitial = player?.is_in_blacklist
  const email = player?.email || ''
  const birthdateHandler = player?.birthdate
  const date = birthdateHandler ? birthdateHandler?.split('-') : []
  const initialBirthdate =
    date.length !== 0 ? new Date(+date[0], +date[1] - 1, +date[2]) : null
  const formik = useFormik({
    validationSchema: editPlayerValidation,
    enableReinitialize: true,
    initialValues: {
      firstname,
      lastname,
      birthdate: initialBirthdate,
      phone,
      inBlacklist: inBlacklistInitial,
      email
    },
    onSubmit: (values) => {
      const toSend = {
        playerId,
        ...values,
        birthdate: values?.birthdate
          ? friendlyTime(values?.birthdate, 'yyyy-MM-DD')
          : '',
        phone: values?.phone ? correctionPhoneNumber(values?.phone, 'send') : ''
      }
      console.log('fds')

      editPlayer(toSend, {
        onSuccess: () => {
          onCloseHandler()
        },
        onError: (err) => {
          const msg = err?.response?.data
          generateWarningToast({
            msgObjects: msg,
            toast,
            id: 'add-new-player-modal'
          })
        }
      })
    }
  })

  const [datePickerError, setDatePickerError] = useState(false)
  const formikValues = formik.values
  const formikErrors = formik.errors
  const formikTouched = formik.touched
  const birthdateError =
    formikErrors?.hasOwnProperty('birthdate') &&
    formikTouched?.hasOwnProperty('birthdate')
  if (isOpen) console.log('birthdateError', birthdateError)
  const birthdate = formikValues.birthdate
  const setFieldValue = formik.setFieldValue
  const inBlacklist = formikValues.inBlacklist
  const handleSubmit = formik.handleSubmit
  const resetForm = formik.resetForm

  const isLoading = isLoadingPlayer
  console.log('formik ', formikValues)

  function onCloseHandler() {
    resetCache()
    resetForm()
    refetchFunction()
    onClose()
  }

  function dateChangeHandler(val) {
    setFieldValue('birthdate', val)
  }

  function onChangeSwitch(e) {
    setFieldValue('inBlacklist', e.target.checked)
  }

  function onClickHandler() {
    handleSubmit()
  }

  if (isOpen) console.log('formik.values', formik.values)
  return (
    <>
      <IconButton
        aria-label="edit-holidays"
        variant="funcColumnIconButton"
        w="20px"
        minW="unset"
        icon={<PencilIcon w="12px" />}
        onClick={onOpen}
        {...btn}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onCloseHandler}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w={{ base: '90%', md: '100%' }}
            pos="relative"
            maxW={{ base: 'unset', md: '720px', '2xl': '1100px' }}
          >
            {isLoading && (
              <Box
                pos="absolute"
                top="0"
                left="0"
                bottom="0"
                right="0"
                zIndex={2}
              >
                <Preloader h="8px" w="calc(100% - 4px)" opacity="0.8" m="2px" />
              </Box>
            )}
            <ModalHeader mb="16px">
              Edit player {firstname}&nbsp;{lastname}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              mb="16px"
              d="grid"
              gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gridGap="16px"
            >
              <FormikTwoStyledInput placeholder="Firstname" name="firstname" />
              <FormikTwoStyledInput placeholder="Lastname" name="lastname" />
              <InputGroup mb="unset">
                <DatePicker
                  showYearDropdown={true}
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown={true}
                  showMonthDropdown={true}
                  onChange={dateChangeHandler}
                  dateFormat="yyyy-MM-dd"
                  selected={birthdate}
                  minDate={new Date(1900, 1, 1)}
                  maxDate={new Date()}
                  customInput={
                    <CustomInputDatePicker
                      setExternalError={setDatePickerError}
                      useExternalError={true}
                      disableErrors={true}
                      mask="9999-99-99"
                      bg={birthdateError ? 'custom.red.20' : 'custom.lightGray'}
                      mb="unset"
                    />
                  }
                />
              </InputGroup>
              <FormikTwoStyledInput placeholder="Email" name="email" />
              <FormikTwoStyledInput
                name="phone"
                placeholder="Phone"
                input={{
                  mask: PHONE_INPUT_MASK,
                  as: InputMask
                }}
              />
              <Box d="flex" alignItems="center">
                <Text
                  color="custom.gray.900"
                  fontSize={{ base: '12px', md: '14px', '2xl': '16px' }}
                >
                  Add to blacklist?
                </Text>
                <Switch
                  ml={{ base: 'auto' }}
                  size="md"
                  colorScheme="red"
                  isChecked={inBlacklist}
                  onChange={onChangeSwitch}
                />
              </Box>
            </ModalBody>
            <ModalFooter
              d="grid"
              gridTemplateColumns={'1fr 1fr'}
              mt={{ base: '16px' }}
              gridGap={{ base: '8px', md: '16px' }}
            >
              <Button variant="lightBlue" w="100%" onClick={onClose}>
                Close
              </Button>
              <Button variant="blue" w="100%" onClick={onClickHandler}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
