import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PlusIcon } from '../../../../../../assets/Icons/PlusIcon'
import { useNewNotificationTemplate } from '../../../../../../libs/hooks/useNotificationTemplates'
import { newAndEditNotificationTemplateModalValidation } from '../../../../../../configs/validation/newAndEditNotificationTemplateModalValidation'
import {
  matchMedia768InState,
  PERMISSION_NOTIFICATIONS
} from '../../../../../../constants'
import CustomTextarea from '../../../../../Common/CustomTextarea'
import PermissionRequired from '../../../../../Common/PermissionRequired'

export default function AddTemplateModal() {
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const queryClient = useQueryClient()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: newNotificationTemplate } = useNewNotificationTemplate()

  function onSubmitHandler(values) {
    const toSend = {
      templateName: values.templateName,
      smsMessage: values.smsMessage,
      emailMessage: values.emailMessage,
      sendHoursBeforeGame: +values.sendHoursBeforeGame,
      sendHoursAfterGame: +values.sendHoursAfterGame
    }
    newNotificationTemplate(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['notificationTemplates'])
          onClose()
        }
      }
    )
  }

  const formik = useFormik({
    initialValues: {
      emailMessage: '',
      smsMessage: '',
      templateName: '',
      sendHoursBeforeGame: 0,
      sendHoursAfterGame: 0
    },
    onSubmit: onSubmitHandler,
    validationSchema: newAndEditNotificationTemplateModalValidation,
    enableReinitialize: true
  })

  const formikValues = formik.values
  const formikHandleSubmit = formik.handleSubmit
  const setFieldValue = formik.setFieldValue
  const formikErrors = formik.errors
  const formikTouched = formik.touched

  function isError(prop) {
    return formikErrors[prop] && formikTouched[prop]
  }

  function onChangeHandler(prop) {
    return (e) => {
      const value = e.target.value
      setFieldValue(prop, value)
    }
  }

  const blue600 = {
    color: 'custom.blue.600'
  }
  return (
    <>
      {isLargerThan768 ? (
        <Button
          variant="blue"
          ml="auto"
          onClick={onOpen}
          isDisabled={!notificationPermission}
          fontSize={{ base: '12px', '2xl': '14px' }}
        >
          <PlusIcon mr="8px" />
          New template
        </Button>
      ) : (
        <IconButton
          aria-label="editAccount"
          variant="funcColumnIconButtons"
          ml="auto"
          isDisabled={!notificationPermission}
          icon={<PlusIcon w="inherit" h="inherit" />}
          onClick={onOpen}
          color="custom.blue.600"
        />
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '720px', '2xl': '656px' }}
          >
            <ModalHeader
              mb="34px"
              d="flex"
              fontSize={{ base: '18px', '2xl': '24px' }}
            >
              Add new template
              <Box
                ml="auto"
                d={{ base: 'none', md: 'grid' }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="8px"
                fontSize={{ base: '12px', '2xl': '14px' }}
              >
                <Button
                  w="100%"
                  variant="blue"
                  fontSize="inherit"
                  onClick={formikHandleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  w="100%"
                  variant="red"
                  fontSize="inherit"
                  onClick={onClose}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <ModalCloseButton d={{ md: 'none' }} />
            <ModalBody fontSize={{ base: '12px' }} d={{ base: 'block' }}>
              <Box mb="16px" w={{ base: '100%' }}>
                <Text as="span" d="flex" flexWrap="wrap">
                  <Text {...blue600}>{'{{ name }}'}</Text>
                  <Text ml="4px">- Customers name,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ address }}'}
                  </Text>
                  <Text ml="4px">- Location,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ eventvariant }}'}
                  </Text>
                  <Text ml="4px">- Experience,</Text>
                  <Text {...blue600} ml="4px">
                    #{'{{ code }}'}
                  </Text>
                  <Text ml="4px">- Booking number,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ date }}'}
                  </Text>
                  <Text ml="4px">- Date,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ time }}'}
                  </Text>
                  <Text ml="4px">- Time,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ promo }}'}
                  </Text>
                  <Text ml="4px">- Promocode,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ waiver_link }}'}
                  </Text>
                  <Text ml="4px">- Waiver link,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ image_link }}'}
                  </Text>
                  <Text ml="4px">- Image link,</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ good_feedback_link }}'}
                  </Text>
                  <Text ml="4px">- Good feedback link</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ bad_feedback_link }}'}
                  </Text>
                  <Text ml="4px">- Bad feedback link</Text>
                  <Text {...blue600} ml="4px">
                    {'{{ combo_code }}'}
                  </Text>
                  <Text ml="4px">- Combo code</Text>
                </Text>
              </Box>
              <CustomTextarea
                label="Template name:"
                editMode={true}
                containerProps={{
                  ...(isError('templateName') && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('templateName')}
                externalValue={formikValues.templateName}
              />
              <CustomTextarea
                label="SMS message:"
                editMode={true}
                containerProps={{
                  ...(isError('smsMessage') && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('smsMessage')}
                externalValue={formikValues.smsMessage}
              />
              <CustomTextarea
                label="E-mail message:"
                editMode={true}
                containerProps={{
                  ...(isError('emailMessage') && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('emailMessage')}
                externalValue={formikValues.emailMessage}
              />
              <CustomTextarea
                label="Send hours before game:"
                editMode={true}
                containerProps={{
                  ...(isError('sendHoursBeforeGame') && {
                    bg: 'custom.red.20'
                  })
                }}
                externalOnChange={onChangeHandler('sendHoursBeforeGame')}
                externalValue={formikValues.sendHoursBeforeGame}
              />
              <CustomTextarea
                label="Send hours after game:"
                editMode={true}
                containerProps={{
                  ...(isError('sendHoursAfterGame') && { bg: 'custom.red.20' })
                }}
                externalOnChange={onChangeHandler('sendHoursAfterGame')}
                externalValue={formikValues.sendHoursAfterGame}
              />
            </ModalBody>

            <ModalFooter
              d={{ base: 'grid', md: 'none' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
            >
              <Button w="100%" variant="blue" onClick={formikHandleSubmit}>
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button w="100%" variant="red" onClick={onClose}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
