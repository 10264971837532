import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import InputMask from 'react-input-mask'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PencilIcon } from '../../../../../../assets/Icons/PencilIcon'
import { correctionPhoneNumber } from '../../../../../../libs'
import {
  useCompany,
  useUpdateCompany
} from '../../../../../../libs/hooks/useCompany'
import { editAccountModalValidation } from '../../../../../../configs/validation/editAccountModalValidation'
import {
  matchMedia768InState,
  PERMISSION_COMPANIES,
  PHONE_INPUT_MASK,
  userInState
} from '../../../../../../constants'
import FormikTwoStyledInput from '../../../../../Common/FormikComponents/FormikTwoStyledInput'
import PermissionRequired from '../../../../../Common/PermissionRequired'

export default function EditAccountModal() {
  const queryClient = useQueryClient()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useSelector(userInState)
  const companyId = user?.company
  const permissionCompany = PermissionRequired(PERMISSION_COMPANIES, true)
  const { data: company = {} } = useCompany(companyId, {
    enabled: !!companyId
  })
  const { mutate: updateCompany } = useUpdateCompany()

  function onSubmitHandler(values) {
    updateCompany(
      {
        obj: {
          companyId,
          ...values,
          twilioPhone: correctionPhoneNumber(values?.twilioPhone, 'send')
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['company', +companyId])
          onClose()
        }
      }
    )
  }

  const formik = useFormik({
    initialValues: {
      name: company.name || '',
      sendgrid: company?.sendgrid || '',
      notifEmail: company?.notif_email || '',
      twilioSeed: company?.twillio_sid || '',
      twilioToken: company?.twillio_token || '',
      minGiftcardValue: company?.min_giftcard_value || '',
      maxGiftcardValue: company?.max_giftcard_value || '',
      twilioPhone: company?.twillio_phone
        ? correctionPhoneNumber(company?.twillio_phone, 'show')
        : '',
      sendgridContactList: company?.sendgrid_contact_list || '',
      minimalAge: company?.minimal_age || ''
    },
    onSubmit: onSubmitHandler,
    validationSchema: editAccountModalValidation,
    enableReinitialize: true
  })
  const handleSubmit = formik.handleSubmit
  return (
    <>
      {isLargerThan768 ? (
        <Button
          variant="blue"
          ml="auto"
          onClick={onOpen}
          isDisabled={!permissionCompany}
          fontSize={{ base: '12px', '2xl': '14px' }}
        >
          <PencilIcon mr="8px" />
          Edit
        </Button>
      ) : (
        <IconButton
          aria-label="editAccount"
          variant="funcColumnIconButtons"
          ml="auto"
          isDisabled={!permissionCompany}
          icon={<PencilIcon w="inherit" h="inherit" />}
          onClick={onOpen}
          color="custom.gray.200"
        />
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
          >
            <ModalHeader mb="34px" d="flex">
              Edit Account
              <Box
                ml="auto"
                d={{ base: 'none', md: 'grid' }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="8px"
                fontSize={{ base: '12px' }}
              >
                <Button
                  w="100%"
                  variant="blue"
                  fontSize="inherit"
                  onClick={handleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  w="100%"
                  variant="red"
                  fontSize="inherit"
                  onClick={onClose}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <ModalCloseButton d={{ md: 'none' }} />
            <ModalBody
              fontSize={{ base: '12px' }}
              d={{ base: 'block', md: 'grid' }}
              gridTemplateColumns={{ base: 'unset', md: 'repeat(3, 1fr)' }}
              gridGap={'16px'}
            >
              <FormikTwoStyledInput name="name" placeholder="Name" mb="8px" />
              <FormikTwoStyledInput
                name="sendgrid"
                placeholder="Sendgrid"
                mb="8px"
              />
              <FormikTwoStyledInput
                name="sendgridContactList"
                placeholder="Sendgrid Contact List"
                mb="8px"
              />
              <FormikTwoStyledInput
                name="twilioSeed"
                placeholder="Twillio seed"
                mb="8px"
              />
              <FormikTwoStyledInput
                name="twilioToken"
                placeholder="Twillio token"
                mb="8px"
              />
              <FormikTwoStyledInput
                name="twilioPhone"
                input={{
                  as: InputMask,
                  mask: PHONE_INPUT_MASK
                }}
                mb="8px"
                placeholder="Twillio phone"
              />
              <FormikTwoStyledInput
                name="notifEmail"
                placeholder="Notification e-mail"
                mb="8px"
              />
              <FormikTwoStyledInput
                name="minGiftcardValue"
                placeholder="Min Giftcard value, $"
                mb="8px"
              />
              <FormikTwoStyledInput
                name="maxGiftcardValue"
                placeholder="Max Giftcard value, $"
                mb="8px"
              />
              <FormikTwoStyledInput
                name="minimalAge"
                placeholder="Minimal age"
                mb="8px"
              />
            </ModalBody>
            <ModalFooter
              d={{ base: 'grid', md: 'none' }}
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
            >
              <Button w="100%" variant="blue" onClick={handleSubmit}>
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button w="100%" variant="red" onClick={onClose}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
