import { Box, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { PROMO_PAGE_URL } from '../../../../../constants'
import DeletePromocodeModal from '../../Modals/DeletePromocodeModal'

export default function PromocodeListItem({ item }) {
  const { id = '', code = '' } = item
  return (
    <Box p={{ base: '16px' }} bg="white" mb="2px" d="flex" alignItems="center">
      <Link to={`${PROMO_PAGE_URL}/${id}`}>
        <Text color="custom.blue.600" textDecor="underline">
          {code}
        </Text>
      </Link>
      <Box ml={{ base: 'auto' }}>
        <DeletePromocodeModal promocodeId={id} promocodeName={code} />
      </Box>
    </Box>
  )
}
