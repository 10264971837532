import { Box, Button, Text } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'

export default function ButtonsToggle({
  data = [],
  small = false,
  cb = null,
  useExternalControl = false,
  initialState = '',
  returnIndex = false,
  unCheckedColor = 'white',
  skipInitial = false,
  isDisabled = false,
  buttonsId = '',
  ...rest
}) {
  const theme = useTheme()
  const customColors = theme.colors.custom
  const initial = useMemo(() => {
    return initialState
  }, [initialState])
  const initialIndex = useMemo(() => {
    if (typeof initialState === 'string') {
      for (let i = 0; i < data.length; i++) {
        if (initialState?.toLowerCase() === data[i].toLowerCase()) {
          return i
        }
      }
    }
  }, [initialState])
  const [isFirstRun, setIsFirstRun] = useState(skipInitial)
  const [checkedDay, setCheckedDay] = useState(
    initial || (data.length ? data[0] : '')
  )
  const [checkedIndex, setCheckedIndex] = useState(
    initialIndex ? initialIndex : 0
  )

  useEffect(() => {
    if (useExternalControl) {
      setCheckedDay(initial)
      setCheckedIndex(initialIndex)
    }
  }, [initialIndex, initial, useExternalControl])

  function isChecked(day, idx) {
    return checkedIndex === idx
  }

  function onClickHandler(day, idx) {
    return () => {
      setCheckedDay(day)
      setCheckedIndex(idx)
    }
  }

  useEffect(() => {
    if (cb !== null && typeof cb === 'function' && !isFirstRun) {
      if (returnIndex) {
        cb(checkedIndex)
      } else {
        cb(checkedDay)
      }
    }

    if (isFirstRun) {
      setIsFirstRun(false)
    }
  }, [checkedDay, checkedIndex])

  return (
    <Box
      d="grid"
      gridTemplateColumns={`repeat(${data.length}, 1fr)`}
      fontSize={{ base: '12px', '2xl': '14px' }}
      {...rest}
    >
      {data.map((day, idx) => (
        <Button
          h="100%"
          minH="40px"
          {...(buttonsId && { id: `${buttonsId}-${idx}` })}
          key={'buttonsToggle' + idx + new Date().valueOf()}
          bg={isChecked(day, idx) ? 'custom.blue.600' : unCheckedColor}
          borderRadius="none"
          fontSize="inherit"
          padding="0"
          minW="unset"
          textTransform="capitalize"
          fontWeight="400"
          color={isChecked(day, idx) ? 'white' : 'custom.gray.300'}
          border={`1px solid ${
            isChecked(day, idx)
              ? customColors.blue[600]
              : customColors.borderBlue
          }`}
          borderLeft={idx !== 0 && 'none'}
          _focus={{ boxShadow: 'none' }}
          _hover={false}
          _active={false}
          isDisabled={isDisabled}
          onClick={onClickHandler(day, idx)}
        >
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            maxW="82%"
          >
            {small ? day.slice(0, 1) : day}
          </Text>
        </Button>
      ))}
    </Box>
  )
}
