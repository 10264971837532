import {
  Button,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../../../assets/Icons/CrosshairIcon'
import { useDeleteHoliday } from '../../../../../../../libs/hooks/useHolidays'
import { PERMISSION_EVENT } from '../../../../../../../constants'
import PermissionRequired from '../../../../../../Common/PermissionRequired'

export default function DeleteHolidayModal({ name = '', holidayId }) {
  const queryClient = useQueryClient()
  const holidayPermission = PermissionRequired(PERMISSION_EVENT, true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate } = useDeleteHoliday()
  function deleteHoliday() {
    mutate(
      { holidayId: holidayId },
      {
        onSuccess: () => {
          onClose()
          queryClient.invalidateQueries('holidays')
        }
      }
    )
  }

  return (
    <>
      <IconButton
        aria-label="edit-holidays"
        variant="funcColumnIconButton"
        w="12px"
        minW="unset"
        ml="16px"
        onClick={onOpen}
        isDisabled={!holidayPermission}
        icon={<CrosshairIcon w="inherit" />}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="34px">Delete {name}?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter
            d="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="8px"
            fontSize={{ base: '12px', '2xl': '14px' }}
          >
            <Button w="100%" variant="lightBlue">
              Cancel
            </Button>
            <Button w="100%" variant="red" onClick={deleteHoliday}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
