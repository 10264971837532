import { useMutation } from 'react-query'

import { sendToEmail, setNewPassword } from '../../api/resetPassword'

export function useSendToEmail(options = {}) {
  return useMutation(({ obj }) => sendToEmail(obj), {
    ...options
  })
}

export function useSetNewPassword(options = {}) {
  return useMutation(({ obj }) => setNewPassword(obj), {
    ...options
  })
}
