import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { cloneElement } from 'react'

export default function StyledAccordion({
  children = [],
  allowToggle = true,
  defaultIndex = [0],
  styles = {},
  needProps = true,
  ...rest
}) {
  const [header = 'header', bodyContent = 'body'] = Array.isArray(children)
    ? children
    : [children, '']
  const { button = { cursor: 'pointer' }, item = {} } = styles
  return (
    <Accordion
      defaultIndex={defaultIndex}
      variant="gbase"
      allowToggle={allowToggle}
      {...rest}
    >
      <AccordionItem {...item}>
        {({ isExpanded }) => (
          <>
            <AccordionButton mb={'unset'} {...button} as={'span'}>
              <Box flex="1" textAlign="left">
                {header}
              </Box>

              <ArrowIcon
                w="auto"
                height="16px"
                transition="all 0.2s ease-in"
                transform={isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'}
                color="custom.blue.600"
              />
            </AccordionButton>
            <AccordionPanel p="0">
              {needProps
                ? cloneElement(bodyContent, { isExpanded: isExpanded })
                : cloneElement(bodyContent)}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
