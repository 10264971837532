import { Box, Text } from '@chakra-ui/react'
import StatisticsQuestItem from '../StatisticsQuestItem'
import DataRow from '../../../Common/DataRow'

export default function StatisticsQuestList({
  data = [],
  total = '',
  ll = '',
  avg = '',
  name = '',
  played = '',
  skip = false
}) {
  return (
    <Box>
      <Box>
        {!skip &&
          data?.map((stat, idx) => (
            <StatisticsQuestItem
              item={stat}
              key={'StatisticsQuestList' + idx}
            />
          ))}
      </Box>
      <Box
        p="16px"
        bg={skip ? 'custom.blue.600' : 'custom.blue.200'}
        color={skip ? 'white' : 'custom.blue.600'}
        mb="2px"
      >
        <Box d="flex" alignItems="center" mb="4px">
          <Text fontWeight="500" mr="8px">
            {!skip ? `Total for ${name}` : name}:
          </Text>
        </Box>
        <Box d="flex">
          <DataRow
            w="auto"
            mr="16px"
            title="LL"
            value={ll}
            keyStyle={{ mr: '8px', color: 'inherit' }}
            valueStyle={{ color: 'inherit' }}
          />
          <DataRow
            w="auto"
            mr="16px"
            title="Total"
            value={Math.round(total / 100)}
            keyStyle={{ mr: '8px', color: 'inherit' }}
            valueStyle={{ color: 'inherit' }}
          />
          <DataRow
            w="auto"
            mr="16px"
            title="Avg"
            value={Math.round(avg / 100)}
            keyStyle={{ mr: '8px', color: 'inherit' }}
            valueStyle={{ color: 'inherit' }}
          />
          <DataRow
            w="auto"
            mr="16px"
            title="Played"
            value={played}
            keyStyle={{ mr: '8px', color: 'inherit' }}
            valueStyle={{ color: 'inherit' }}
          />
        </Box>
      </Box>
    </Box>
  )
}
