import { useField } from 'formik'

export default function FormikCheckbox({
  name,
  id,
  isDisabled,
  children = null,
  ...rest
}) {
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  const isError = touched && error
  return (
    <label
      className={`waiver-widget--checkbox-container ${isError ? 'error' : ''}`}
      id={id}
    >
      <input type="checkbox" name={name} {...field} />
      <span />
      <p className="waiver-widget--text">{children}</p>
    </label>
  )
}
