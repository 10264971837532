import { Box, Switch, Text } from '@chakra-ui/react'
import EditUserModalModal from '../Modals/EditUserModal'
import DeleteUserModal from '../Modals/DeleteUserModal'
import React, { useMemo } from 'react'
import { usePatchUser } from '../../../../../libs/hooks/useUsers'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { PERMISSION_USERS, userInState } from '../../../../../constants'
import PermissionRequired from '../../../../Common/PermissionRequired'

export default function UsersListItem({ name, locations, el }) {
  const usersPermission = PermissionRequired(PERMISSION_USERS, true)
  const { mutate: patchUser } = usePatchUser()
  const queryClient = useQueryClient()
  const user = useSelector(userInState)
  const locationsList = useMemo(() => {
    if (locations.length !== 0) {
      return locations
        .map((location) => {
          return location?.name
        })
        .join(', ')
    } else {
      return <>—</>
    }
  }, [locations])

  function onChangeDisabled(userId) {
    return (e) =>
      patchUser(
        {
          obj: {
            userId,
            disabled: !e.target.checked
          }
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['users'])
          }
        }
      )
  }

  return (
    <Box p={{ base: '16px' }} mb="2px" bg="white" d="flex">
      <Box w="50%">
        <Text mb="4px">{name}</Text>
        <Text>{locationsList}</Text>
      </Box>
      <Box d="flex" w="50%" justifyContent="flex-end" alignItems="center">
        <Switch
          mr="16px"
          isChecked={!el.disabled}
          isDisabled={user.id === el.id || !usersPermission}
          onChange={onChangeDisabled(el.id)}
          _focus={false}
        />
        <EditUserModalModal
          role={el.role}
          lastname={el.last_name}
          email={el.email}
          phone={el.phone}
          firstname={el.first_name}
          locations={el.locations}
          locationsIds={el.locationsIds}
          userId={el.id}
        />
        <DeleteUserModal
          name={el.first_name + ' ' + el.last_name}
          userId={el.id}
        />
      </Box>
    </Box>
  )
}
