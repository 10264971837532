import { Box, IconButton, Text } from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'
import { CrosshairIcon } from '../../../assets/Icons/CrosshairIcon'
import * as changeCase from 'change-case'

export default function CustomWarningToast({
  title = '',
  message = '',
  close = null,
  msgObjects = null
}) {
  function onClose() {
    if (close !== null && typeof close === 'function') {
      close()
    }
  }
  return (
    <Box
      bg="orange.500"
      color="white"
      fontFamily="inherit"
      p={{ base: '16px', '2xl': '24px' }}
      pos="relative"
      d="flex"
      alignItems="center"
    >
      <WarningIcon w="24px" height="24px" mr="8px" />
      <Box>
        <Text
          fontWeight="600"
          fontSize={{ base: '14px', '2xl': '16px' }}
          textTransform="capitalize"
        >
          {title}
        </Text>
        <Text
          fontWeight="400"
          fontSize={{ base: '12px', '2xl': '14px' }}
          noOfLines={4}
        >
          {msgObjects !== null && Object.keys(msgObjects).length !== 0
            ? ''
            : Array.isArray(message)
            ? message?.map((message) => message).join(', ')
            : message}
        </Text>
        {msgObjects !== null &&
          Object.keys(msgObjects).length !== 0 &&
          Object.keys(msgObjects)?.map((key, idx) => {
            const isArray = Array.isArray(msgObjects[key])
            return (
              <Text
                fontWeight="400"
                fontSize={{ base: '12px', '2xl': '14px' }}
                key={idx + 'customWarningToast'}
                textTransform="capitalize"
              >
                {changeCase.noCase(key)}:{' '}
                {isArray
                  ? msgObjects[key]
                      ?.map((msg) => changeCase.noCase(msg))
                      .join(',')
                  : msgObjects[key]}
              </Text>
            )
          })}
      </Box>
      <IconButton
        aria-label={'closeToast'}
        onClick={onClose}
        bg="transparent"
        w="30px"
        height="30px"
        minH="unset"
        minW="unset"
        icon={<CrosshairIcon w="40%" minW="unset" minH="unset" height="40%" />}
        pos="absolute"
        right="6px"
        top="6px"
      />
    </Box>
  )
}
