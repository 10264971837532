import React from 'react'
import { useTable } from 'react-table'
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import WithSorting from '../WithSorting'

export default function TableCreator({
  columns,
  data,
  styles = {},
  sortingColumns = [],
  sizesMap = [],
  tableFilter = {},
  setTableFilter = null
}) {
  const {
    tr,
    th,
    thead,
    table,
    tbody,
    td,
    tdMinor,
    trMinor,
    trFunc = null
  } = styles
  function getSize(header, accessor, prop) {
    if (header && typeof header === 'string') {
      const lowerHeader = header.toLowerCase()
      if (sizesMap[lowerHeader]) {
        return sizesMap[lowerHeader][prop] ? sizesMap[lowerHeader][prop] : ''
      }
    } else {
      const lowerAccessor = accessor.toLowerCase()
      if (sizesMap[lowerAccessor]) {
        return sizesMap[lowerAccessor][prop]
          ? sizesMap[lowerAccessor][prop]
          : ''
      }
    }
  }

  function isNeedCbProps(row) {
    if (trFunc !== null && typeof trFunc === 'function') {
      return trFunc(row)
    } else return {}
  }

  function needSize() {
    return Object.keys(sizesMap).length !== 0
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    })

  return (
    <Table {...getTableProps()} {...table}>
      <Thead {...thead}>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()} {...tr}>
            {headerGroup.headers.map((column) => {
              return (
                <Th
                  {...column.getHeaderProps()}
                  {...th}
                  w={needSize() && getSize(column.Header, column.id, 'width')}
                  maxW={
                    needSize() && getSize(column.Header, column.id, 'maxWidth')
                  }
                >
                  {typeof column.Header === 'string' &&
                  sortingColumns.includes(column.Header.toLowerCase()) ? (
                    <WithSorting
                      value={column.Header}
                      filter={tableFilter}
                      setFilter={setTableFilter}
                    >
                      {column.render('Header')}
                    </WithSorting>
                  ) : (
                    <Box
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      fontSize="inherit"
                      fontWeight="inherit"
                      textDecoration="inherit"
                    >
                      {column.render('Header')}
                    </Box>
                  )}
                </Th>
              )
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()} {...tbody}>
        {rows.map((row, i) => {
          prepareRow(row)
          let columnsCount = row.cells.length
          if (row?.original?.minors && row?.original?.minors?.length !== 0) {
            return (
              <React.Fragment key={'waiverWithMinor' + i}>
                <Tr {...row.getRowProps()} {...tr} {...isNeedCbProps(row)}>
                  {row.cells.map((cell, idx) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        {...td}
                        w={
                          needSize() &&
                          getSize(cell.column.Header, cell.column.id, 'width')
                        }
                        maxW={
                          needSize() &&
                          getSize(
                            cell.column.Header,
                            cell.column.id,
                            'maxWidth'
                          )
                        }
                      >
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                </Tr>
                {row?.original?.minors?.map((minor, idx) => (
                  <Tr
                    {...trMinor}
                    key={'minor' + row.original['full name'] + idx}
                  >
                    <Td {...tdMinor} colSpan={columnsCount}>
                      <Box
                        d="flex"
                        w="100%"
                        justifyContent="center"
                        alignItems="center"
                        height="inherit"
                      >
                        <Text
                          fontSize={{ base: '12px', '2xl': '14px' }}
                          color="custom.blue.600"
                          fontWeight="500"
                        >
                          Minor:
                        </Text>
                        &nbsp;{minor.name} ({minor.birthdate})
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </React.Fragment>
            )
          } else {
            return (
              <Tr {...row.getRowProps()} {...tr} {...isNeedCbProps(row)}>
                {row.cells.map((cell) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      {...td}
                      w={
                        needSize() &&
                        getSize(cell.column.Header, cell.column.id, 'width')
                      }
                      maxW={
                        needSize() &&
                        getSize(cell.column.Header, cell.column.id, 'maxWidth')
                      }
                    >
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
              </Tr>
            )
          }
        })}
      </Tbody>
    </Table>
  )
}
