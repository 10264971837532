import { api } from '../../index'

export async function getPartners({ page = null, pageSize = null }) {
  try {
    const { data } = await api.get('/api/promopartners', {
      params: {
        page,
        page_size: pageSize
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function createPartner({ name }) {
  try {
    const { data } = await api.post('/api/promopartners/', {
      name: name
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function updatePartner({ partnerId, name }) {
  try {
    const { data } = await api.put(`/api/promopartners/${partnerId}/`, {
      name: name
    })
    return data
  } catch (e) {
    console.log(e)
  }
}
export async function deletePartner({ partnerId }) {
  try {
    const { data } = await api.delete(`/api/promopartners/${partnerId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}
