import { Box, Text, Textarea } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'

export default function CustomTextarea({
  label = 'label',
  initialTextHeight = '28px',
  placeholder,
  initialValue = '',
  editMode = false,
  cb = null,
  inputProps = {},
  containerProps = {},
  labelProps = {},
  externalValue = null,
  externalOnChange = null
}) {
  const theme = useTheme()
  const customColors = theme.colors.custom
  const ref = useRef()
  const [value, setValue] = useState(initialValue === null ? '' : initialValue)

  function onChangeText(e) {
    setValue(e.target.value)
  }

  useEffect(() => {
    ref.current.style.height = `${initialTextHeight}`
    ref.current.style.height = ref.current.scrollHeight + 'px'
  }, [value, externalValue])

  useEffect(() => {
    if (cb !== null && typeof cb === 'function') {
      cb(value)
    }
  }, [value])

  return (
    <Box
      pos="relative"
      minH="56px"
      border={
        editMode
          ? `1px solid ${customColors.borderBlue}`
          : '1px solid transparent'
      }
      borderRadius="none"
      bg="custom.lightGray"
      mb="16px"
      p={label ? '26px 16px 0' : '16px'}
      {...containerProps}
    >
      <Text
        pos="absolute"
        zIndex="1"
        top="8px"
        left="15px"
        fontSize="10px"
        color="custom.gray.300"
        {...labelProps}
      >
        {label}
      </Text>
      <Textarea
        variant="unstyled"
        ref={ref}
        resize="none"
        borderRadius="none"
        value={externalValue !== null ? externalValue : value}
        onChange={externalValue !== null ? externalOnChange : onChangeText}
        minH="unset"
        placeholder={placeholder}
        color="custom.gray.900"
        readOnly={!editMode}
        p="0 0 4px"
        fontSize={{ base: '12px', '2xl': '14px' }}
        {...inputProps}
      />
    </Box>
  )
}
