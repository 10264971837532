import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Text
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import WithPagination from '@/components/Common/WithPagination'
import GiftCardsList from '@/components/GiftCards/GiftCardsList'
import GiftCardsTable from '@/components/GiftCards/GiftCardsTable'
import NewGiftCardModal from '@/components/GiftCards/Modals/NewGiftCardModal'

import { debounceSearchTime } from '../../configs'
import { matchMedia768InState } from '../../constants'
import { useGiftCards } from '../../libs/hooks/useGiftCards'

export default function GiftCards() {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState({
    page: 1,
    pageSize: 15,
    search: ''
  })
  const { search: urlParams } = useLocation()
  useEffect(() => {
    const code = urlParams.replace('?find=', '')
    if (code) {
      setFilter({
        page: 1,
        pageSize: 15,
        search: code
      })
    }
  }, [urlParams])
  const { data: { results: giftcards = [], count: total = '' } = {} } =
    useGiftCards(filter)

  useEffect(() => {
    const code = urlParams.replace('?find=', '')
    if (code) {
      setFilter({
        ...filter,
        page: 1,
        pageSize: 15
      })
      setSearch(code)
    }
  }, [urlParams, giftcards])

  const debouncedSearch = useCallback(
    debounce((code) => {
      setFilter({
        ...filter,
        search: code
      })
    }, debounceSearchTime),
    []
  )

  function onChangeHandler() {
    return (e) => setSearch(e.target.value)
  }

  function onChangePagination(val) {
    setFilter({
      ...filter,
      ...val
    })
  }

  useEffect(() => {
    if (search.length === 0) {
      debouncedSearch('')
    }
    if (search.length > 2) {
      debouncedSearch(search)
    }
  }, [search])
  const data = giftcards
  return (
    <Box p={{ base: '24px 16px', md: '32px 24px' }}>
      <Text
        color="custom.black.600"
        mb={{ base: '24px' }}
        fontSize={{ base: '24px' }}
        d={{ base: 'none', md: 'block' }}
        fontWeight="600"
      >
        Gift cards
      </Text>
      <Box p={{ base: '16px' }} bg="white" mb="2px" d={{ md: 'flex' }}>
        <Text
          color="custom.black.600"
          d={{ md: 'none' }}
          mb={{ base: '16px' }}
          fontSize={{ base: '24px' }}
          fontWeight="600"
        >
          Gift cards
        </Text>
        <InputGroup mb="8px">
          <Input variant="gbase" value={search} onChange={onChangeHandler()} />
          <InputRightElement>
            <SearchIcon color="custom.gray.200" />
          </InputRightElement>
        </InputGroup>
        <NewGiftCardModal />
      </Box>
      {isLargerThan768 ? (
        <WithPagination totalCountItems={total} cb={onChangePagination}>
          <GiftCardsTable tableData={data} />
        </WithPagination>
      ) : (
        <WithPagination totalCountItems={total} cb={onChangePagination}>
          <GiftCardsList data={data} />
        </WithPagination>
      )}
    </Box>
  )
}
