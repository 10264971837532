import { Box, IconButton, Text, useTheme } from '@chakra-ui/react'
import StyledAccordion from '../../Common/StyledAccodion'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import { CopyIconWithoutBg } from '../../../assets/Icons/CopyIconWithoutBg'
import GBaseTag from '../../Common/GBaseTag'
import EditGiftCardModal from '../Modals/EditGiftCardModal'
import DeleteGiftcardModal from '../Modals/DeleteGiftcardModal'
import { friendlyTime } from '../../../libs'

export default function GiftCardsItem({ card }) {
  const theme = useTheme()
  const customColors = theme.colors.custom
  const recipientName = card?.recipient_name ? card?.recipient_name : ''
  const recipientEmail = card?.recipient_email ? card?.recipient_email : ''
  const buyerEmail = card?.buyer_email ? card?.buyer_email : ''
  const recipientPhone = card?.recipient_phone ? card?.recipient_phone : ''
  const total = card?.total ? Math.round(card?.total / 100) : ''
  const sendEmailOn = card?.deliver_date
    ? friendlyTime(card?.deliver_date * 1000, 'MM.DD.YY')
    : ''
  const personalMessage = card?.personal_message ? card?.personal_message : ''
  const status = card?.status ? card?.status : ''
  const last4 = card?.card_last
  const cardholder = card?.cardholder

  function copyHandler(e) {
    e.preventDefault()
  }

  return (
    <StyledAccordion
      defaultIndex={null}
      styles={{
        item: { mb: '2px' }
      }}
      needProps={false}
    >
      <Box
        color="custom.gray.900"
        d="grid"
        gridTemplateColumns="4fr 1fr"
        mr="8px"
        alignItems="center"
      >
        <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          <Text
            fontSize="12px"
            fontWeight="400"
            mb="4px"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            maxW="80%"
          >
            {recipientName}
          </Text>
          <Box d="flex" alignItems="center">
            <Text
              fontSize="12px"
              fontWeight="400"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxW="80%"
            >
              {recipientEmail}
            </Text>
            <CopyToClipboard text={recipientEmail}>
              <IconButton
                aria-label="copy-link"
                minW="unset"
                minH="unset"
                w="16px"
                h="16px"
                bg="transparent"
                ml="8px"
                _hover={false}
                _focus={false}
                onClick={copyHandler}
                icon={
                  <CopyIconWithoutBg
                    color="custom.gray.200"
                    w="inherit"
                    h="inherit"
                  />
                }
              />
            </CopyToClipboard>
          </Box>
        </Box>
        {status && (
          <GBaseTag
            label={status}
            color={status.toLowerCase() === 'used' ? 'red' : 'green'}
            justifySelf="end"
          />
        )}
      </Box>
      <Box p="16px" bg="white">
        <Box
          p="8px"
          bg="custom.lightGray"
          border={`1px solid ${customColors.borderBlue}`}
          mb="16px"
        >
          <Box mb="6px">
            <Text color="custom.gray.300">Buyer:</Text>
            <Text color="custom.gray.900">{recipientName}</Text>
          </Box>
          {!!last4 && (
            <Box mb="6px">
              <Text color="custom.gray.300">Last 4:</Text>
              <Text color="custom.gray.900">{last4}</Text>
            </Box>
          )}
          {!!cardholder && (
            <Box mb="6px">
              <Text color="custom.gray.300">Cardholder:</Text>
              <Text color="custom.gray.900">{cardholder}</Text>
            </Box>
          )}
          <Box mb="6px">
            <Text color="custom.gray.300">Buyer email:</Text>
            <Box d="flex" alignItems="center">
              <Text
                fontSize="12px"
                fontWeight="400"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxW="90%"
              >
                {buyerEmail}
              </Text>
              <CopyToClipboard text={buyerEmail}>
                <IconButton
                  aria-label="copy-link"
                  minW="unset"
                  minH="unset"
                  w="16px"
                  h="16px"
                  bg="transparent"
                  ml="8px"
                  _hover={false}
                  _focus={false}
                  onClick={copyHandler}
                  icon={
                    <CopyIconWithoutBg
                      color="custom.gray.200"
                      w="inherit"
                      h="inherit"
                    />
                  }
                />
              </CopyToClipboard>
            </Box>
          </Box>
          <Box mb="6px">
            <Text color="custom.gray.300">Total:</Text>
            <Text color="custom.gray.900">{total}</Text>
          </Box>
          {!!sendEmailOn && (
            <Box mb="6px">
              <Text color="custom.gray.300">Send email on (optional):</Text>
              <Text color="custom.gray.900">{sendEmailOn}</Text>
            </Box>
          )}
          {personalMessage && (
            <Box mb="6px">
              <Text color="custom.gray.300">
                Add personal message (optional):
              </Text>
              <Text color="custom.gray.900">{personalMessage}</Text>
            </Box>
          )}
        </Box>
        <Box d="grid" gridTemplateColumns="1fr 1fr" gridGap="8px">
          <EditGiftCardModal original={card} />
          <DeleteGiftcardModal
            recipientName={card?.recipient_name}
            giftcardId={card?.id}
          />
        </Box>
      </Box>
    </StyledAccordion>
  )
}
