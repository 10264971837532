import { Box, Text } from '@chakra-ui/react'
import AddDowntimeModal from '../Modals/AddDowntimeModal'
import DowntimeList from './DowntimeList'

export default function Downtime() {
  return (
    <>
      <Box
        mb="2px"
        p="16px"
        bg="white"
        d="flex"
        justifyContent="space-between"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Text
          color="custom.black.500"
          fontSize={{ base: '18px', '2xl': '24px' }}
          mb={{ base: '8px', md: 'unset' }}
          fontWeight="500"
        >
          Downtime
        </Text>
        <AddDowntimeModal />
      </Box>
      <DowntimeList />
    </>
  )
}
