import * as yup from 'yup'

export const discountItemValidation = yup.object().shape({
  amountType: yup.string().required(),
  amountText: yup
    .number()
    .positive()
    .required()
    .when('amountType', {
      is: '%',
      then: yup.number().required().max(100)
    }),
  isAdjourned: yup.boolean(),
  daysToActivate: yup.number().when('isAdjourned', {
    is: true,
    then: yup.number().integer().moreThan(-1).required()
  })
})
