import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CrosshairIcon } from '../../../../../../../assets/Icons/CrosshairIcon'
import { useDeleteAttractionsExperience } from '../../../../../../../libs/hooks/useAttractionsExperience'
import { useExperiences } from '../../../../../../../libs/hooks/useExperiences'
import { deleteLocationModalValidation } from '../../../../../../../configs/validation/deleteLocationModalValidation'
import { PERMISSION_EVENT, userInState } from '../../../../../../../constants'
import FormikDropdown from '../../../../../../Common/FormikComponents/FormikDropdown'
import PermissionRequired from '../../../../../../Common/PermissionRequired'

export default function DeleteExperienceModal({
  name = '',
  disabled = false,
  eventId,
  eventvariantId
}) {
  const experiencePermission = PermissionRequired(PERMISSION_EVENT, true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const queryClient = useQueryClient()
  const history = useHistory()
  const { mutate: deleteAttractionExperience } =
    useDeleteAttractionsExperience()
  const { data: { results: experience = [] } = {} } = useExperiences()
  const user = useSelector(userInState)
  const userLocation = user?.locations || []
  const optionsExperience = useMemo(() => {
    const options = []
    if (experience?.length !== 0) {
      for (let i = 0; i < experience.length; i++) {
        const expLocationId = experience[i].event.location
        if (
          userLocation?.includes(expLocationId) &&
          experience[i].id !== eventvariantId
        ) {
          options.push({
            value: experience[i].id,
            label: experience[i].name
          })
        }
      }
      return options
    } else {
      return []
    }
  }, [experience, eventvariantId])

  function deleteHandler(values) {
    deleteAttractionExperience(
      {
        obj: {
          id: eventvariantId,
          eventvariantId: values.experience.value
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['event', +eventId])
          queryClient.invalidateQueries(['experience'])
          onClose()
        }
      }
    )
  }

  const formik = useFormik({
    initialValues: {
      experience: { value: '', label: '' }
    },
    validationSchema: deleteLocationModalValidation,
    onSubmit: deleteHandler
  })
  const formikHandleSubmit = formik.handleSubmit
  const resetForm = formik.resetForm
  const formikValues = formik.values

  useEffect(() => {
    if (formikValues.experience.value !== '') {
      resetForm()
    }
  }, [eventvariantId])
  return (
    <>
      <Button
        variant="red"
        fontSize={{ base: '12px', '2xl': '14px' }}
        onClick={onOpen}
        isDisabled={!experiencePermission}
      >
        <CrosshairIcon mr="8px" />
        Delete
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
          >
            <ModalHeader mb="8px">Remove experience</ModalHeader>
            <ModalCloseButton />
            {optionsExperience.length === 0 ? (
              <Text color="custom.black.500" fontSize="14px" mb="16px">
                You can't delete this attraction, because haven't more
                attractions with another experience.
              </Text>
            ) : (
              <>
                <Text
                  color="custom.black.500"
                  fontSize="14px"
                  mb="16px"
                  as="span"
                  d="flex"
                  flexWrap="wrap"
                >
                  You must choose a default experience before delete&nbsp;
                  <Text color="custom.blue.600">{name}</Text>.
                </Text>
                <FormikDropdown
                  main={{
                    options: optionsExperience,
                    placeholder: 'Experience'
                  }}
                  formik={{
                    name: 'experience',
                    id: 'experience'
                  }}
                  chakraProps={{ fontSize: { base: '12px', '2xl': '14px' } }}
                />
              </>
            )}
            <ModalFooter
              d="grid"
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
              mt="16px"
            >
              <Button w="100%" variant="lightBlue" onClick={onClose}>
                Cancel
              </Button>
              <Button
                w="100%"
                variant="red"
                onClick={formikHandleSubmit}
                _hover={false}
                isDisabled={optionsExperience.length === 0}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
