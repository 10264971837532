import { Box, Button } from '@chakra-ui/react'
import { friendlyTime } from '../../../../../libs'
import { useEffect, useState } from 'react'

export default function CapItem({
  code,
  startTime,
  name,
  callback,
  checkedInitial,
  disabled,
  checkedForCopy,
  copyCallback
}) {
  const checked = checkedInitial

  useEffect(() => {
    if (checkedForCopy && !checked) {
      copyCallback(null)
    }
  }, [checked, checkedForCopy])

  function toggleSelect(state) {
    return () => {
      callback(state)
    }
  }
  return (
    <Box
      bg={checked ? 'white' : 'custom.lightGray'}
      opacity={disabled ? 0.4 : 1}
      border="1px solid"
      borderColor={checked ? 'custom.blue.600' : 'custom.borderBlue'}
      p="16px 16px 14px"
    >
      <Box d="flex" flexDir="column">
        <Box
          as="span"
          mb="4px"
          color="custom.black.500"
          fontSize={{ base: '14px' }}
          fontWeight="500"
        >
          {code}
        </Box>
        <Box
          as="span"
          mb="4px"
          d="flex"
          color="custom.gray.300"
          fontSize={{ base: '12px' }}
        >
          Game:
          <Box fontWeight="500" ml="4px" color="custom.gray.900">
            {name}
          </Box>
        </Box>
        <Box
          as="span"
          d="flex"
          color="custom.gray.300"
          fontSize={{ base: '12px' }}
        >
          Date:
          <Box fontWeight="500" ml="4px" color="custom.gray.900">
            {friendlyTime(startTime)}
          </Box>
        </Box>
        <Button
          variant="unstyled"
          color="custom.blue.600"
          padding="6px 6px 6px 0"
          w="120px"
          textAlign="start"
          minH="unset"
          minW="unset"
          isDisabled={disabled}
          h="auto"
          onClick={toggleSelect(!checked)}
          fontSize={{ base: '12px' }}
          alignSelf="start"
        >
          {checked ? 'Selected' : 'Select'}
        </Button>
      </Box>
    </Box>
  )
}
