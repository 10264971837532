import { Box, Text } from '@chakra-ui/react'

import DeleteEmailTemplateModal from '../Modals/DeleteEmailTemplateModal'
import EditEmailTemplateModal from '../Modals/EditEmailTemplateModal'

export default function EmailTemplatesItem({ name = '', emailTemplateId }) {
  return (
    <Box d="flex" bg="white" p={{ base: '16px', '2xl': '16px 24px' }} mb="2px">
      <Text color="custom.gray.900" fontSize={{ base: '12px', '2xl': '14px' }}>
        {name}
      </Text>
      <Box d="flex" alignItems="center" ml="auto">
        <EditEmailTemplateModal emailTemplateId={emailTemplateId} />
        <DeleteEmailTemplateModal
          name={name}
          emailTemplateId={emailTemplateId}
        />
      </Box>
    </Box>
  )
}
