import { createIcon } from '@chakra-ui/icons'

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 12 12',
  boxSize: '12',
  path: (
    <g fill="currentColor">
      <path d="M12 6.85714H6.85714V12H5.14286V6.85714H0V5.14286H5.14286V0H6.85714V5.14286H12V6.85714Z" />
    </g>
  )
})
