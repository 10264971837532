import { Box, Text } from '@chakra-ui/react'
import { changeFormatAndTimezone } from '../../../../libs'

export default function Comment({ name, date, text, timezone }) {
  return (
    <Box w="100%" bg="white" p="16px" fontSize="12px" mb="2px">
      <Box
        d="flex"
        w="100%"
        fontSize="inherit"
        justifyContent="space-between"
        mb="2px"
      >
        <Text lineHeight="200%" fontWeight="500" color="custom.black.500">
          {name}
        </Text>
        <Text lineHeight="200%" color="custom.gray.300">
          {changeFormatAndTimezone(date, timezone, 'hh:mm A - MM.DD.YY')}
        </Text>
      </Box>
      <Text lineHeight="200%" color="custom.gray.900">
        {text}
      </Text>
    </Box>
  )
}
