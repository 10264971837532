import { useMutation } from 'react-query'

import { addToOrder } from '../../api/bookings'

export function useAddToOrder(options = {}) {
  return useMutation(
    ({ array: bookings, promocode, giftcard }) =>
      addToOrder({ bookings, promocode, giftcard }),
    {
      ...options
    }
  )
}
