import {
  Button,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { useExperiences } from '../../../../../../libs/hooks/useExperiences'
import { useDeleteLocation } from '../../../../../../libs/hooks/useLocations'
import { deleteLocationModalValidation } from '../../../../../../configs/validation/deleteLocationModalValidation'
import { PERMISSION_LOCATIONS, userInState } from '../../../../../../constants'
import FormikDropdown from '../../../../../Common/FormikComponents/FormikDropdown'
import PermissionRequired from '../../../../../Common/PermissionRequired'

export default function DeleteLocationModal({ locationId, locationName }) {
  const queryClient = useQueryClient()
  const locationsPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: deleteLocation } = useDeleteLocation()
  const { data: { results: experience = [] } = {} } = useExperiences()
  const user = useSelector(userInState)
  const userLocation = user?.locations || []
  const optionsExperience = useMemo(() => {
    const options = []
    if (experience?.length !== 0) {
      for (let i = 0; i < experience.length; i++) {
        const expLocationId = experience[i].event.location
        if (
          userLocation?.includes(expLocationId) &&
          expLocationId !== locationId
        ) {
          options.push({
            value: experience[i].id,
            label: experience[i].name
          })
        }
      }
      return options
    } else {
      return []
    }
  }, [experience])

  function submitHandler(values) {
    deleteLocation(
      {
        obj: {
          locationId: locationId,
          eventvariantId: values.experience.value
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['profile']).then(() => {
            queryClient.invalidateQueries(['locations'])
            queryClient.invalidateQueries(['experience'])
          })

          onClose()
        }
      }
    )
  }

  const formik = useFormik({
    initialValues: {
      experience: ''
    },
    validationSchema: deleteLocationModalValidation,
    onSubmit: submitHandler
  })
  const formikHandleSubmit = formik.handleSubmit
  return (
    <>
      <IconButton
        aria-label="delete-gate"
        color="custom.gray.200"
        variant="funcColumnIconButtons"
        onClick={onOpen}
        isDisabled={!locationsPermission}
        icon={<CrosshairIcon w="inherit" h="inherit" />}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <FormikProvider value={formik}>
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
          >
            <ModalHeader mb="16px">Delete {locationName}?</ModalHeader>
            <ModalCloseButton />

            {optionsExperience.length === 0 ? (
              <Text color="custom.black.500" fontSize="14px" mb="16px">
                You can't delete this location, because haven't events with
                another experience.
              </Text>
            ) : (
              <>
                <Text color="custom.black.500" fontSize="14px" mb="16px">
                  You must choose a default experience before delete location.
                </Text>
                <FormikDropdown
                  main={{
                    options: optionsExperience,
                    placeholder: 'Experience'
                  }}
                  formik={{
                    name: 'experience',
                    id: 'experience'
                  }}
                  chakraProps={{ fontSize: { base: '12px', '2xl': '14px' } }}
                />
              </>
            )}
            <ModalFooter
              d="grid"
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
              mt="16px"
            >
              <Button w="100%" variant="lightBlue" onClick={onClose}>
                Cancel
              </Button>
              <Button
                w="100%"
                variant="red"
                onClick={formikHandleSubmit}
                _hover={false}
                isDisabled={optionsExperience.length === 0}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormikProvider>
      </Modal>
    </>
  )
}
