import './index.css'
import { Box } from '@chakra-ui/react'

export default function Preloader({ cyclic = false, ...rest }) {
  return (
    <Box w="100%" m="20px 0" p="0" height="30px" {...rest}>
      <Box className="progress progress-striped">
        <Box className={cyclic ? 'progress-bar-cyclic' : 'progress-bar'} />
      </Box>
    </Box>
  )
}
