import { useFormikContext } from 'formik'

import PencilIcon from '../../common/Icons/PencilIcon'
import { changeFormatAndTimezone } from '../../../common'

export default function ProfileItem({ profile, simple = false }) {
  const {
    setFieldValue,
    values: { selectedProfile, timezone }
  } = useFormikContext()

  function onClickHandler(id) {
    return () => {
      if (id !== null) {
        const correctedMinorsProfile = {
          ...profile,
          waiver: {
            ...profile.waiver,
            minors: Array.isArray(profile?.waiver?.minors)
              ? profile.waiver.minors
              : []
          }
        }

        const copiedProfile = JSON.parse(JSON.stringify(correctedMinorsProfile))
        setFieldValue('selectedProfile', correctedMinorsProfile)
        setFieldValue('originalProfile', copiedProfile)
        setFieldValue('createMode', false)
      }
    }
  }

  const id = profile?.id ? profile?.id : ''
  const firstname = profile?.firstname ? profile?.firstname : ''
  const lastname = profile?.lastname ? profile?.lastname : ''
  const email = profile?.email ? profile?.email : ''
  const birthdate = profile?.birthdate ? profile?.birthdate : ''
  const signAt = profile?.waiver?.sign_time || ''
  const signAtForm = signAt
    ? changeFormatAndTimezone(
        signAt + 'Z',
        timezone,
        'ddd MMM DD YYYY hh:mm:ss'
      )
    : '-'

  return (
    <div
      className={`waiver-widget--profile ${simple ? 'without-hover' : ''}`}
      onClick={onClickHandler(id)}
    >
      <span>
        {' '}
        {firstname}&nbsp;{lastname}
      </span>
      <span>
        E-mail: <i>{email}</i>
      </span>
      <span>
        Birthdate: <i>{birthdate}</i>
      </span>
      <span>
        Singed at: <i>{signAtForm}</i>
      </span>
      {!simple && (
        <button>
          <PencilIcon rect={false} />
          Edit profile information
        </button>
      )}
    </div>
  )
}
