import StatisticsQuestroomTable from './StatisticsQuestroomTable'
import StatisticsPartnersTable from './StatisticsPartnersTable'
import { Box } from '@chakra-ui/react'

export default function StatisticsDesktop({
  type,
  statisticsLocations = [],
  buttonsSwitchData,
  dataPartners = []
}) {
  return (
    <>
      {type === buttonsSwitchData[0] && (
        <>
          {statisticsLocations.length === 0 && (
            <Box textAlign="center" m="20px 0" fontSize="14px">
              No results were found for your search.
            </Box>
          )}
          {statisticsLocations?.map((statLoc, idx) => {
            return (
              <StatisticsQuestroomTable
                tableData={statLoc.eventvariants_statistic}
                total={statLoc.total}
                ll={statLoc.ll}
                avg={statLoc.avg}
                skip={idx === statisticsLocations.length - 1}
                name={statLoc.name}
                played={statLoc.played}
                key={idx + 'StatisticsQuestroomTable'}
              />
            )
          })}
        </>
      )}
      {type === buttonsSwitchData[1] && (
        <StatisticsPartnersTable tableData={dataPartners} />
      )}
    </>
  )
}
