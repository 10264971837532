import {
  Button,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,  ModalOverlay,
useDisclosure} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { useDeleteUser } from '../../../../../../libs/hooks/useUsers'
import { PERMISSION_USERS } from '../../../../../../constants'
import PermissionRequired from '../../../../../Common/PermissionRequired'



export default function DeleteUserModal({ name = '', userId }) {
  const usersPermission = PermissionRequired(PERMISSION_USERS, true)
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: deleteUser } = useDeleteUser()

  function onClickDelete() {
    deleteUser(
      {
        obj: {
          userId: userId
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['users'])
          onClose()
        }
      }
    )
  }

  return (
    <>
      <IconButton
        aria-label="delete-gate"
        color="custom.gray.200"
        variant="funcColumnIconButtons"
        onClick={onOpen}
        isDisabled={!usersPermission}
        icon={<CrosshairIcon />}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="16px">Delete {name}?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter
            d="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="8px"
          >
            <Button w="100%" variant="lightBlue" onClick={onClose}>
              Cancel
            </Button>
            <Button w="100%" variant="red" onClick={onClickDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
