import { useMutation, useQuery } from 'react-query'

import { getCompany, updateCompany } from '../../api/Settings/account'

export function useCompany(companyId, queryOption = {}) {
  return useQuery(['company', +companyId], () => getCompany(companyId), {
    ...queryOption
  })
}

export function useUpdateCompany(options = {}) {
  return useMutation(({ obj }) => updateCompany(obj), {
    ...options
  })
}
