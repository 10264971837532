import {
  AccordionButton,
  AccordionPanel,
  Box,
  Grid,
  Heading,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

import GBaseTag from '../../GBaseTag'
import { friendlyTimeWithTZ } from '../../../../libs'
import { BOOKING_URL } from '../../../../constants'
import DeleteBookingModal from '../../../Bookings/BookingTable/Modals/DeleteBookingModal'

export default function Card({
  start,
  status,
  code,
  firstname,
  lastname,
  location,
  adults,
  children,
  experience,
  id,
  bookingsQueryKeys
}) {
  const showDeleteButton = status === 'canceled'
  return (
    <>
      <h2>
        <AccordionButton
          bgColor="#ffffff"
          _expanded={{ bgColor: '#EBEEF0' }}
          p={0}
        >
          <Box
            minH="70px"
            w="100%"
            minW="288px"
            marginY="2px"
            p="10px 8px 8px"
            fontSize="12px"
          >
            <Box d="flex" justifyContent="space-between">
              <Heading as="h3" size="xs" fontWeight={600} maxWidth="100%">
                {experience}
              </Heading>
              {showDeleteButton && (
                <DeleteBookingModal
                  bookingId={id}
                  bookingCode={code}
                  bookingsQueryKeys={bookingsQueryKeys}
                />
              )}
            </Box>
            <Heading as="h3" size="xs" align="left" fontWeight={400}>
              {`${firstname} ${lastname}`}
            </Heading>
            <Box d="flex" alignItems="center">
              <Text fontSize={14} opacity={0.6} fontWeight={500}>
                {friendlyTimeWithTZ(start, 'MM.DD.YY[,] h:mm A')}
              </Text>
              <Text
                fontSize={14}
                opacity={0.6}
                fontWeight={500}
                ml="12px"
                variant="gbase"
                maxWidth="100%"
              >
                {`Players: ${adults + children}`}
              </Text>
              <GBaseTag label={status} ml="auto" />
            </Box>
          </Box>
        </AccordionButton>
      </h2>
      <AccordionPanel bgColor="#EBEEF0" px={2} borderTop="2px solid #6e8391">
        <Grid templateColumns="1fr 4fr" gap={2}>
          <Text fontSize={14} opacity={0.6} fontWeight={500}>
            Location:
          </Text>
          <Text ml={2} fontSize={14}>
            {location}
          </Text>
          <Text fontSize={14} opacity={0.6} fontWeight={500}>
            Code:
          </Text>
          <Link to={`${BOOKING_URL}/${id}`}>
            <Text
              fontSize={14}
              ml={2}
              color="custom.blue.600"
              textDecoration="underline"
            >
              #{code}
            </Text>
          </Link>
        </Grid>
      </AccordionPanel>
    </>
  )
}
