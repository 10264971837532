import {
  DELETE_REFRESH_TOKEN,
  EDIT_TOKEN,
  SET_AUTH_TOKEN,
  SET_BOOKINGS_SORT_LOCATION,
  SET_BOOKINGS_SORT_TIME,
  SET_CURRENT_PAGE,
  SET_INITIAL_STATE,
  SET_ITEMS_PER_PAGE,
  SET_ITEMS_TOTAL_COUNT,
  SET_LOCATION_FILTERS_STATE,
  SET_MIN_CALENDAR_DAY,
  SET_PERMISSIONS,
  SET_REFRESH_TOKEN,
  SET_SELECTED_TAB_DISCOUNTS,
  SET_SELECTED_TAB_STATISTICS,
  SET_SELECTED_WAIVER,
  SET_SETTINGS_TAB,
  SET_STATUS_FILTERS_STATE,
  SET_USER,
  SIGN_OUT,
  TOGGLE_MENU
} from '../../constants'

const initialState = {
  authToken: '',
  refreshToken: '',
  menuIsOpen: false,
  minCalendarDay: '',
  user: '',
  settingsTab: { value: 'account', label: 'Account' },
  selectedTabs: {
    statistics: 0,
    discounts: 0
  },
  filters: {
    status: { value: 'all', label: 'All' },
    location: { value: '', label: 'All' }
  },
  permissions: [],
  bookingsSortByLoc: '',
  bookingsSortByTime: ''
}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKINGS_SORT_TIME: {
      return {
        ...state,
        bookingsSortByTime: action.payload
      }
    }

    case TOGGLE_MENU: {
      return {
        ...state,
        menuIsOpen: !state.menuIsOpen
      }
    }

    case SET_BOOKINGS_SORT_LOCATION: {
      return {
        ...state,
        bookingsSortByLoc: action.payload
      }
    }

    case SET_MIN_CALENDAR_DAY: {
      return {
        ...state,
        minCalendarDay: action.payload
      }
    }

    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.payload
      }
    }

    case SET_REFRESH_TOKEN: {
      return {
        ...state,
        refreshToken: action.payload
      }
    }

    case SET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload
      }
    }

    case SIGN_OUT: {
      return initialState
    }

    case SET_USER: {
      return {
        ...state,
        user: action.payload
      }
    }

    case SET_STATUS_FILTERS_STATE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload
        }
      }
    }

    case SET_LOCATION_FILTERS_STATE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          location: action.payload
        }
      }
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload
        }
      }
    }

    case SET_ITEMS_PER_PAGE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload
        }
      }
    }

    case SET_ITEMS_TOTAL_COUNT: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalCountItems: action.payload
        }
      }
    }

    case SET_SETTINGS_TAB: {
      return {
        ...state,
        settingsTab: action.payload
      }
    }

    case DELETE_REFRESH_TOKEN: {
      return {
        ...state,
        refreshToken: ''
      }
    }

    case EDIT_TOKEN: {
      return {
        ...state,
        authToken: action.payload
      }
    }

    case SET_SELECTED_TAB_STATISTICS: {
      return {
        ...state,
        selectedTabs: {
          ...state.selectedTabs,
          statistics: action.payload
        }
      }
    }
    case SET_SELECTED_TAB_DISCOUNTS: {
      return {
        ...state,
        selectedTabs: {
          ...state.selectedTabs,
          discounts: action.payload
        }
      }
    }

    case SET_SELECTED_WAIVER: {
      return {
        ...state,
        selectedWaiver: action.payload
      }
    }

    case SET_INITIAL_STATE: {
      return initialState
    }

    default:
      return state
  }
}
