import { useFormikContext } from 'formik'

import AcceptReplaceModal from '../Modals/AcceptReplaceModal'
import ProfileItem from '../ProfileItem'
import ButtonBlue from '../../common/ButtonBlue'

export default function SelectProfile() {
  const {
    values: { profiles = [], technicalNumber, createMode } = {},
    setFieldValue
  } = useFormikContext()

  function newProfileHandler() {
    setFieldValue('createMode', true)
  }

  function replace() {
    const selectedProfile = profiles[0]
    const obj = {
      ...selectedProfile,
      birthdate: '',
      email: '',
      firstname: '',
      lastname: '',
      waiver: {
        ...selectedProfile.waiver,
        sign: null,
        signed_for: 'M',
        minors: []
      }
    }
    setFieldValue('futurePlayers', null)
    setFieldValue('isEditMode', true)
    setFieldValue('createMode', true)
    setFieldValue('isReplaceProfileMode', true)
    setFieldValue('selectedProfile', obj)
    setFieldValue('originalProfile', selectedProfile)
  }

  const showReplaceButton = profiles?.length === 1 && !technicalNumber
  return (
    <div className="waiver-widget--select-profile">
      <span className="waiver-widget--title-text">Please select profile</span>
      {profiles?.map((profile, idx) => (
        <ProfileItem profile={profile} key={'waiversProfile' + idx} />
      ))}
      {showReplaceButton && <AcceptReplaceModal cb={replace} />}
      {(profiles?.length === 0 || technicalNumber) && (
        <ButtonBlue disabled={createMode} onClick={newProfileHandler}>
          Create new profile
        </ButtonBlue>
      )}
    </div>
  )
}
