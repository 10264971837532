import {
  Box,
  IconButton,
  useDisclosure,
  Heading,
  Skeleton,
  useToast,
  HStack,
  Button
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../assets/Icons/CrosshairIcon'
import { useDeleteBooking } from '../../../../../libs/hooks/useDeleteBooking'
import { PERMISSION_BOOKING_DELETE } from '../../../../../constants'
import PermissionRequired from '../../../../Common/PermissionRequired'

export default function DeleteBookingModal({
  bookingCode,
  bookingId,
  bookingsQueryKeys
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const deleteBookingPermission = PermissionRequired(
    PERMISSION_BOOKING_DELETE,
    true
  )
  const queryClient = useQueryClient()
  const { mutate: deleteBooking } = useDeleteBooking()
  const [isLoading, setIsLoading] = useState(false)
  function onSuccessHandler() {
    queryClient.invalidateQueries(bookingsQueryKeys).then(() => {
      setIsLoading(false)
      onClose()
    })
    toast({
      title: 'Booking deleted!',
      status: 'success',
      duration: 2000,
      isClosable: true
    })
  }

  function onClickHandler() {
    if (deleteBookingPermission) {
      setIsLoading(true)
      deleteBooking(
        {
          bookingId
        },
        {
          onSuccess: () => onSuccessHandler()
        }
      )
    }
  }

  return (
    <Box position="relative">
      <IconButton
        aria-label={'delete-booking'}
        bg="none"
        onClick={onOpen}
        _hover={false}
        minH="unset"
        minW="unset"
        h="20px"
        w="20px"
        isDisabled={!deleteBookingPermission}
        icon={<CrosshairIcon color="custom.gray.300" />}
      />

      {isOpen && (
        <Box
          position="absolute"
          right={0}
          top={"-20%"}
          bgColor="#fff"
          p={2}
          border="1px"
          borderColor="custom.gray.500"
        >
          <Heading
            align="left"
            mb={2}
            as="h5"
            size="sm"
            color="black"
            fontWeight={500}
          >
            Delete booking #{bookingCode}?
          </Heading>
          <HStack>
            <Skeleton isLoaded={!isLoading}>
              <Button variant="red" fontWeight={600} onClick={onClickHandler}>
                Delete booking
              </Button>
            </Skeleton>
            <Button
              fontSize={14}
              fontWeight={400}
              variant="ghost"
              onClick={onClose}
            >
              Cancel
            </Button>
          </HStack>
        </Box>
      )}

      {/* <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb={{ base: '12px', md: '18px' }}>
            Delete booking #{bookingCode}?
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter
            d="grid"
            w="100%"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap={{ base: '8px', md: '16px' }}
          >
            <Button variant="lightBlue" onClick={onClose}>
              Cancel
            </Button>
            {isLoading ? (
              <Preloader cyclic={true} m="0" h="40px" />
            ) : (
              <Button variant="red" onClick={onClickHandler}>
                Delete booking
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Box>
  )
}
