import { Checkbox, Text } from '@chakra-ui/react'
import CustomIcon from '../Test'
import { useEffect, useState } from 'react'

export default function CustomCheckbox({
  children,
  initialState = false,
  cb = null,
  isDisabled = false,
  anotherConatiner = false,
  ...rest
}) {
  const [state, setState] = useState(initialState)

  function onChange() {
    return (e) => {
      e.stopPropagation()
      e.preventDefault()
      setState(e.target.checked)
    }
  }

  useEffect(() => {
    if (cb !== null && typeof cb === 'function') {
      cb(state)
    }
  }, [state])

  useEffect(() => {
    setState(initialState)
  }, [initialState])

  return (
    <Checkbox
      icon={<CustomIcon />}
      css={{
        touchAction: 'none',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px'
      }}
      isChecked={state}
      isDisabled={isDisabled}
      onChange={onChange()}
      iconColor="custom.blue.600"
      variant="gbase"
      size="lg"
      {...rest}
    >
      {children && !anotherConatiner && (
        <Text
          fontSize={{ base: '12px', '2xl': '14px' }}
          ml="8px"
          color="custom.gray.900"
        >
          {children}
        </Text>
      )}
      {children && anotherConatiner && children}
    </Checkbox>
  )
}
