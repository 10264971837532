import React, { useMemo } from 'react'
import { Box, Switch, Text, useTheme } from '@chakra-ui/react'
import { TABLE_COLUMN_VALUES_USERS } from '../../../../../configs'
import { TABLE_COLUMNS_SIZES_USERS } from '../../../../../configs/tablesSizes'
import TableCreator from '../../../../Common/TableCreator'
import DeleteUserModal from '../Modals/DeleteUserModal'
import EditUserModal from '../Modals/EditUserModal'
import { usePatchUser } from '../../../../../libs/hooks/useUsers'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { PERMISSION_USERS, userInState } from '../../../../../constants'
import PermissionRequired from '../../../../Common/PermissionRequired'

export default function UsersTable({ tableData = [] }) {
  const usersPermission = PermissionRequired(PERMISSION_USERS, true)
  const queryClient = useQueryClient()
  const theme = useTheme()
  const user = useSelector(userInState)
  const customsColor = theme.colors.custom
  const data = useMemo(
    () =>
      tableData.sort((a, b) => {
        return a.id - b.id
      }),
    [tableData]
  )
  const { mutate: patchUser } = usePatchUser()

  function onChangeDisabled(userId) {
    return (e) =>
      patchUser(
        {
          obj: {
            userId,
            disabled: !e.target.checked
          }
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['users'])
          }
        }
      )
  }

  const tableStyles = {
    thead: {
      fontWeight: 500,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 'inherit',
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '0 16px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      h: '56px'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    },
    trMinor: {
      height: '40px',
      bg: 'custom.blue.200',
      borderBottom: `2px solid ${customsColor.lightGray}`
    },
    tdMinor: {
      padding: '0',
      color: 'custom.gray.900'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_USERS.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'full name') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          if (original.first_name && original.last_name) {
            return (
              <Text>
                {original.first_name}&nbsp;{original.last_name}
              </Text>
            )
          } else {
            return <>—</>
          }
        }
      }

      if (field.Header.toLowerCase() === 'e-mail') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          return <Text textTransform="lowercase">{original.email}</Text>
        }
      }

      if (field.Header.toLowerCase() === 'role') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          return <>{original?.role?.name}</>
        }
      }

      if (field.Header.toLowerCase() === 'phone') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          if (original.phone) {
            return <Text>{original.phone}</Text>
          } else {
            return <>—</>
          }
        }
      }

      if (field.Header.toLowerCase() === 'location') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          if (original.locations.length !== 0) {
            return original.locations
              .map((location) => {
                return location?.name
              })
              .join(', ')
          } else {
            return <>—</>
          }
        }
      }

      if (field.Accessor.toLowerCase() === 'functionalcolumn') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          return (
            <Box d="flex" justifyContent="flex-end" alignItems="center">
              <Switch
                mr="16px"
                isChecked={!original.disabled}
                isDisabled={user.id === original.id || !usersPermission}
                onChange={onChangeDisabled(original.id)}
                _focus={false}
              />
              <EditUserModal
                role={original.role}
                lastname={original.last_name}
                email={original.email}
                phone={original.phone}
                firstname={original.first_name}
                locations={original.locations}
                locationsIds={original.locationsIds}
                userId={original.id}
              />
              <DeleteUserModal
                name={original.first_name + ' ' + original.last_name}
                userId={original.id}
              />
            </Box>
          )
        }
      }
      return fieldObject
    })
  }, [])
  return (
    <TableCreator
      data={data}
      columns={columns}
      styles={tableStyles}
      sizesMap={TABLE_COLUMNS_SIZES_USERS}
    />
  )
}
