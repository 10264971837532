import { Box, Button, ButtonGroup, Text } from '@chakra-ui/react'
import AddLocationModal from './Modals/AddLocationModal'
import { useLocations } from '../../../../libs/hooks/useLocations'
import { LocationsDataView } from './LocationsDataView'

export default function Locations() {
  const { data: { results: locations = [] } = {} } = useLocations()

  const data = locations
  return (
    <Box>
      <Box
        p={{ base: '16px', '2xl': '24px' }}
        mb="2px"
        bg="white"
        d="flex"
        alignItems="center"
      >
        <Text
          color="custom.black.500"
          fontSize={{ base: '18px', '2xl': '24px' }}
          fontWeight="500"
        >
          Locations
        </Text>
        <AddLocationModal />
      </Box>

      <LocationsDataView data={data} />
    </Box>
  )
}
