import { api } from '../index'
import { correctionTimezone, selectedDayMidnightTimeStart } from '../../libs'

export async function getBookings({
  page = 1,
  pageSize = 10,
  status = null,
  search = '',
  location = '',
  order = null
}) {
  let statusStr = status
  let searchStr = search
  let locationsStr = location
  let start = null
  if (statusStr === 'all') {
    statusStr = null
  }

  if (typeof statusStr === 'number') {
    start = Math.round(correctionTimezone(Math.round(statusStr / 1000), true))
    statusStr = null
  }

  if (statusStr === 'today') {
    const today = new Date()
    start = selectedDayMidnightTimeStart(today)
    statusStr = null
  }

  if (searchStr === '') {
    searchStr = null
  }

  if (location === '') {
    locationsStr = null
  }

  try {
    const { data } = await api.get('@/api/bookings/', {
      params: {
        ordering: order ? order : '-order',
        page: page,
        page_size: pageSize,
        status: statusStr,
        search: searchStr,
        location: locationsStr,
        start: start
      }
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getSchedules() {
  try {
    const data = await api.get('/api/schedules/')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getEventVariants() {
  try {
    const { data } = await api.get('/api/eventvariants/')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getPriceInterval(
  eventvariantId,
  weekday = null,
  date = null
) {
  const { data } = await api.get(
    `/api/eventvariants/${eventvariantId}/price-intervals`,
    {
      params: {
        weekday: weekday,
        date
      }
    }
  )
  return [{ ...data[0], eventvariantId, weekday }]
}

export async function addToOrder(payload) {
  const { data } = await api.post('/api/orders/get_order_amount/', payload)
  return data
}

export async function deleteBooking(bookingId) {
  const { data } = await api.delete(`api/bookings/${bookingId}`)
  return data
}
