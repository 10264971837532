import { useTheme } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Box, Button } from '@chakra-ui/react'

export default function MenuSwitcher({
  options = [],
  name = '',
  cb = null,
  ...rest
}) {
  const { container = {}, button = {} } = rest
  const theme = useTheme()
  const customColors = theme.colors.custom
  const [checkedDay, setCheckedDays] = useState(options[0])

  function isChecked(obj) {
    return checkedDay.value === obj.value
  }

  function onClickHandler(obj) {
    return () => setCheckedDays(obj)
  }

  useEffect(() => {
    if (cb !== null && typeof cb === 'function') {
      cb(checkedDay.value)
    }
  }, [checkedDay])

  return (
    <Box
      d="grid"
      gridTemplateColumns={`repeat(${options.length}, 1fr)`}
      fontSize={{ base: '14px', '2xl': '16px' }}
      bg="white"
      {...container}
    >
      {options.map((object, idx) => (
        <Button
          h="56px"
          key={'MenuSwitcher' + idx + (name ? name : '')}
          borderRadius="none"
          fontSize="inherit"
          bg="transparent"
          padding="0"
          minW="unset"
          textTransform="capitalize"
          fontWeight={isChecked(object) ? 500 : 400}
          color={isChecked(object) ? 'custom.blue.600' : 'custom.black.500'}
          borderBottom={
            isChecked(object)
              ? `2px solid ${customColors.blue[600]}`
              : `2px solid transparent`
          }
          _focus={{ boxShadow: 'none' }}
          _hover={false}
          _active={false}
          onClick={onClickHandler(object)}
          {...button}
        >
          {object.label}
        </Button>
      ))}
    </Box>
  )
}
