import { Box, Button, Text, useToast } from '@chakra-ui/react'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import CustomTextarea from '../../../../../Common/CustomTextarea'
import { useState } from 'react'
import { PencilIcon } from '../../../../../../assets/Icons/PencilIcon'
import Checkouts from '../CheckoutsWaiverList'
import TwoStyledInput from '../../../../../Common/TwoStyledInput'
import {
  useDeleteWaiversblock,
  usePatchWaiversblock
} from '../../../../../../libs/hooks/useWaiversblocks'
import { useQueryClient } from 'react-query'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import { PERMISSION_LOCATIONS } from '../../../../../../constants'
import { generateWarningToast } from '../../../../../../libs'

export default function WaiverBlock({
  name = '',
  title = '',
  description = '',
  waiverId = '',
  checkouts = [],
  waiverBlockId = ''
}) {
  const queryClient = useQueryClient()
  const toast = useToast()
  const locationsPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const { mutate: patchWaiverBlock } = usePatchWaiversblock()
  const { mutate: deleteWaiverBlock } = useDeleteWaiversblock(waiverBlockId)
  const [editMode, setEditMode] = useState(false)
  const initialState = {
    name: name,
    title: title,
    description: description
  }
  const [state, setState] = useState(initialState)
  const needDisable =
    state.name === '' ||
    state.title === '' ||
    state.description === '' ||
    !locationsPermission

  function onChangeHandler(prop, commonEvent = false) {
    return (value) => {
      setState({
        ...state,
        [prop]: commonEvent ? value.target.value : value
      })
    }
  }

  function onDeleteHandler() {
    changeEditMode(false)
    deleteWaiverBlock(
      {},
      {
        onSuccess: () => queryClient.invalidateQueries(['waiver', +waiverId]),
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          generateWarningToast({
            id: 'edit-waiver-block',
            toast,
            msgObjects,
            message: msg
          })
        }
      }
    )
  }

  function changeEditMode(editMode, itsSave = false) {
    return () => {
      if (editMode) {
        setEditMode(editMode)
      }

      if (!itsSave && !editMode) {
        setEditMode(editMode)
        setState({
          name: name,
          title: title,
          description: description
        })
      }
      if (itsSave && !editMode) {
        const toSend = {
          waiverblockId: waiverBlockId,
          name: state.name,
          title: state.title,
          description: state.description
        }
        patchWaiverBlock(
          {
            obj: toSend
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(['waiver', +waiverId])
              setEditMode(editMode)
            },
            onError: (err) => {
              const msg = err?.response?.data?.msg
              const msgObjects = err?.response?.data
              generateWarningToast({
                id: 'edit-waiver-block',
                toast,
                msgObjects,
                message: msg
              })
            }
          }
        )
      }
    }
  }

  return (
    <Box mb={{ base: '16px', md: '24px' }}>
      <Box
        bg="white"
        p={{ base: '16px', md: '20px 16px', '2xl': '24px' }}
        mb="2px"
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
      >
        {editMode ? (
          <TwoStyledInput
            placeholder="Name"
            w={{ base: '100%', md: '380px', '2xl': '480px' }}
            mb={{ base: '16px', md: '0' }}
            value={state.name}
            onChange={onChangeHandler('name')}
          />
        ) : (
          <Text
            fontSize={{ base: '18px', '2xl': '24px' }}
            fontWeight="500"
            mb={{ base: '8px', md: 'unset' }}
          >
            {name}
          </Text>
        )}

        {editMode ? (
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gridGap={{ base: '8px', md: '16px' }}
            ml={{ md: 'auto' }}
          >
            <Button
              w="100%"
              fontSize={{ base: '12px', '2xl': '14px' }}
              variant="blue"
              isDisabled={needDisable}
              _hover={{
                _disabled: false
              }}
              onClick={changeEditMode(false, true)}
            >
              <DisketteIcon mr="8px" />
              Save
            </Button>
            <Button
              w="100%"
              fontSize={{ base: '12px', '2xl': '14px' }}
              variant="red"
              onClick={changeEditMode(false, false)}
            >
              <CrosshairIcon mr="8px" />
              Cancel
            </Button>
          </Box>
        ) : (
          <Box
            d="grid"
            gridTemplateColumns="1fr 1fr"
            ml={{ base: 'none', md: 'auto' }}
            gridGap={{ base: '8px', md: '16px' }}
          >
            <Button
              variant="blue"
              w={{ base: '100%', md: '90px' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              isDisabled={!locationsPermission}
              onClick={changeEditMode(true)}
              ml={{ md: 'auto' }}
            >
              <PencilIcon mr="8px" />
              Edit
            </Button>
            <Button
              variant="red"
              w={{ base: '100%', md: '90px' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              onClick={onDeleteHandler}
              isDisabled={!locationsPermission}
              ml={{ md: 'auto' }}
            >
              <CrosshairIcon mr="8px" />
              Delete
            </Button>
          </Box>
        )}
      </Box>
      <Box bg="white" p={{ base: '16px', md: '20px 16px', '2xl': '24px' }}>
        <CustomTextarea
          label="Title"
          mb="8px"
          editMode={editMode}
          inputProps={{
            value: state.title,
            onChange: onChangeHandler('title', true)
          }}
        />
        {editMode ? (
          <CustomTextarea
            label="Description"
            mb="16px"
            editMode={editMode}
            externalValue={state.description}
            externalOnChange={onChangeHandler('description', true)}
          />
        ) : (
          <Box
            border="1px solid transparent"
            borderRadius="none"
            p="26px 16px 16px"
            bg="custom.lightGray"
            pos="relative"
            mb="16px"
            fontSize={{ base: '12px', '2xl': '14px' }}
          >
            <Text
              pos="absolute"
              zIndex="1"
              top="8px"
              left="15px"
              fontSize="10px"
              color="custom.gray.300"
            >
              Description
            </Text>
            <Box dangerouslySetInnerHTML={{ __html: state.description }} />
          </Box>
        )}

        <Checkouts
          checkouts={checkouts}
          waiverBlockId={waiverBlockId}
          waiverId={waiverId}
        />
      </Box>
    </Box>
  )
}
