import { useMutation, useQuery } from 'react-query'

import {
  deleteUser,
  editUser,
  getUsers,
  newUser
} from '../../api/Settings/users'

export function useUsers(queryOption = {}) {
  return useQuery(['users'], () => getUsers(), {
    ...queryOption
  })
}

export function usePatchUser(options = {}) {
  return useMutation(({ obj }) => editUser(obj), {
    ...options
  })
}

export function useNewUser(options = {}) {
  return useMutation(({ obj }) => newUser(obj), {
    ...options
  })
}

export function useDeleteUser(options = {}) {
  return useMutation(({ obj }) => deleteUser(obj), {
    ...options
  })
}
