import * as yup from 'yup'

import { cleanPhoneNumber } from '../../../libs'

export const editAccountModalValidation = yup.object().shape({
  name: yup.string().required(),
  sendgrid: yup.string().required(),
  notifEmail: yup.string().email().required(),
  twilioSeed: yup.string().required(),
  twilioToken: yup.string().required(),
  twilioPhone: yup
    .string()
    .required()
    .test('phone length', 'must be 11 charters length', (val) => {
      if (val && val.length) {
        const value = cleanPhoneNumber(val)
        return /\d{10}/.test(value)
      } else return false
    }),
  minGiftcardValue: yup.number().required().min(1),
  maxGiftcardValue: yup
    .number()
    .required()
    .min(yup.ref('minGiftcardValue'), 'Max should be >= min')
})
