import { settingsOptions } from '../../../configs'
import { Box, Button } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSettingsTab } from '../../../actions'
import {
  PERMISSION_COMPANIES,
  PERMISSION_EVENT,
  PERMISSION_LOCATIONS,
  PERMISSION_NOTIFICATIONS,
  PERMISSION_USERS,
  settingsTabInState
} from '../../../constants'
import PermissionRequired from '../../Common/PermissionRequired'

export default function ButtonsSelector() {
  const dispatch = useDispatch()
  const settingsTab = useSelector(settingsTabInState)
  const locationPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const userPermission = PermissionRequired(PERMISSION_USERS, true)
  const accountPermission = PermissionRequired(PERMISSION_COMPANIES, true)
  const attractionPermission = PermissionRequired(PERMISSION_EVENT, true)

  function buttonDisabled(tabName) {
    if (tabName.toLowerCase() === 'locations') {
      return !locationPermission
    }
    if (tabName.toLowerCase() === 'cities') {
      return !locationPermission
    }
    if (tabName.toLowerCase() === 'notifications') {
      return !notificationPermission
    }
    if (tabName.toLowerCase() === 'users') {
      return !userPermission
    }
    if (tabName.toLowerCase() === 'account') {
      return !accountPermission
    }

    if (tabName.toLowerCase() === 'attractions') {
      return !attractionPermission
    }
    if (tabName.toLowerCase() === 'combo') {
      return !attractionPermission
    }

    return false
  }

  const initialChecked = useMemo(() => {
    if (settingsOptions.length !== 0) {
      for (let i = 0; i < settingsOptions.length; i++) {
        if (settingsOptions[i].value === settingsTab.value) {
          return i
        }
      }
    } else {
      return 0
    }
  }, [settingsOptions])

  const [checked, setChecked] = useState(initialChecked)

  function onClickHandler(idx) {
    setChecked(idx)
    dispatch(setSettingsTab(settingsOptions[idx]))
  }

  return (
    <Box ml="auto">
      {settingsOptions.map((el, idx) => {
        return (
          <Button
            fontWeight={checked === idx ? '500' : '400'}
            bg={checked === idx ? 'custom.blue.600' : 'rgba(255,255,255,1)'}
            color={checked === idx ? 'rgba(255,255,255,1)' : 'custom.gray.300'}
            onClick={() => onClickHandler(idx)}
            borderRadius="none"
            border="1px solid"
            isDisabled={buttonDisabled(el.value)}
            padding={{ base: '0 12px', lg: '0 16px' }}
            borderColor={
              checked === idx ? 'custom.blue.600' : 'custom.borderBlue'
            }
            zIndex={checked === idx ? '2' : '1'}
            borderLeft={idx !== 0 && 'none'}
            _hover={false}
            key={'settingsButtons' + idx}
            fontSize={{ base: '12px', '2xl': '14px' }}
          >
            {el.label}
          </Button>
        )
      })}
    </Box>
  )
}
