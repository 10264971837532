import { Box, Button, Text } from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'

export default function WithSortingMultiple({
  children,
  value = '',
  filter = {},
  setFilter
}) {
  const header = filter?.header || ''
  const sort = filter[value]?.sort || null
  const isChecked = filter?.hasOwnProperty(value) && !!filter[value]?.sort
  function onClickHandler() {
    if (setFilter !== null && typeof setFilter === 'function') {
      const sort = filter[value]?.sort

      if (!isChecked) {
        setFilter({
          ...filter,
          [value]: {
            sort: 'up'
          }
        })
      } else {
        if (sort === '') {
          setFilter({
            ...filter,
            [value]: {
              sort: 'up'
            }
          })
        } else {
          if (sort === 'up') {
            setFilter({
              ...filter,
              [value]: {
                sort: 'down'
              }
            })
          }
          if (sort === 'down') {
            setFilter({
              ...filter,
              [value]: {
                sort: ''
              }
            })
          }
        }
      }
    }
  }

  return (
    <Box
      d="block"
      alignItems="center"
      justifyContent="center"
      textTransform="inherit"
      height="inherit"
    >
      <Button
        variant="ghost"
        fontSize="inherit"
        fontWeight="inherit"
        borderRadius="none"
        height="inherit"
        w="100%"
        _focus={{
          border: 'none'
        }}
        textTransform="inherit"
        bg={sort !== '' && isChecked && 'custom.blue.300'}
        onClick={onClickHandler}
      >
        <Text
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textTransform="inherit"
        >
          {children}
        </Text>
        <Box d="flex" flexDir="column" ml="4px">
          <TriangleUpIcon
            w="inherit"
            height="8px"
            color={
              sort === 'up' && isChecked ? 'custom.blue.600' : 'custom.gray.200'
            }
          />
          <TriangleDownIcon
            w="inherit"
            height="8px"
            color={
              sort === 'down' && isChecked
                ? 'custom.blue.600'
                : 'custom.gray.200'
            }
          />
        </Box>
      </Button>
    </Box>
  )
}
