import {
  Button,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { useDeleteGiftCard } from '../../../../libs/hooks/useGiftCards'
import {
  matchMedia768InState,
  PERMISSION_GIFTCARDS
} from '../../../../constants'
import PermissionRequired from '../../../Common/PermissionRequired'

export default function DeleteGiftcardModal({
  giftcardId,
  recipientName = ''
}) {
  const giftcardsPermission = PermissionRequired(PERMISSION_GIFTCARDS, true)
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { mutate: deleteGiftcard } = useDeleteGiftCard()
  function deleteHandler() {
    deleteGiftcard(
      { obj: { giftcardId: giftcardId } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['giftcards'])
          onClose()
        }
      }
    )
  }
  return (
    <>
      {isLargerThan768 ? (
        <IconButton
          aria-label="delete-gate"
          color="custom.gray.200"
          onClick={onOpen}
          variant="funcColumnIconButtons"
          ml="18px"
          isDisabled={!giftcardsPermission}
          icon={<CrosshairIcon w="inherit" h="inherit" />}
        />
      ) : (
        <Button
          variant="red"
          onClick={onOpen}
          isDisabled={!giftcardsPermission}
        >
          Remove
        </Button>
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="34px">Delete Recipient {recipientName}?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter
            d="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="8px"
          >
            <Button w="100%" variant="lightBlue" onClick={onClose}>
              Cancel
            </Button>
            <Button w="100%" variant="red" onClick={deleteHandler}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
