import * as yup from 'yup'

export const newAndEditNotificationTemplateModalValidation = yup
  .object()
  .shape({
    templateName: yup.string().required(),
    smsMessage: yup.string().required(),
    emailMessage: yup.string().required(),
    sendHoursBeforeGame: yup.number().min(0)
  })
