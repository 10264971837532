import { Box, IconButton, Text } from '@chakra-ui/react'
import DropdownSelect from '../../../../../Common/DropdownSelect'
import React, { useEffect, useMemo, useState } from 'react'
import { useTheme } from '@emotion/react'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import { PERMISSION_NOTIFICATIONS } from '../../../../../../constants'

export default function NotificationTemplatesEventsElement({
  exp,
  templatesOptions,
  notificationOption,
  applyChanges = null,
  applyTemplate = null,
  isLoading,
  editMode,
  setEditMode,
  idx
}) {
  const theme = useTheme()
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const customColors = theme.colors.custom
  const [isFirstRun, setIsFirstRun] = useState(true)
  const initialStateNotifications = useMemo(() => {
    const notifications = exp?.notifications
    if (notifications.length === 0) return notificationOption[0]
    if (notifications.length === 2) return notificationOption[3]
    if (notifications.length === 1 && notifications.includes('SMS'))
      return notificationOption[1]
    if (notifications.length === 1 && notifications.includes('EMAIL'))
      return notificationOption[2]
    return ''
  }, [exp, notificationOption])
  const initialStateTemplates = useMemo(() => {
    const template = exp.template
    if (template === null) {
      return templatesOptions[0]
    } else {
      const templateId = exp?.template?.id
      for (let i = 0; i < templatesOptions.length; i++) {
        if (templatesOptions[i].value === templateId) {
          return templatesOptions[i]
        }
      }
    }
  }, [exp, templatesOptions])

  const [selectValues, setSelectValues] = useState({
    template: initialStateTemplates,
    notification: initialStateNotifications
  })

  const template = selectValues?.template
  const notification = selectValues?.notification

  function onChangeDropdown(prop) {
    return (val) =>
      setSelectValues({
        ...selectValues,
        [prop]: val
      })
  }

  useEffect(() => {
    if (!isFirstRun) {
      setEditMode({
        idx,
        enabled: true
      })
    }
    if (isFirstRun) {
      setIsFirstRun(false)
    }

    if (
      selectValues?.template?.value === null &&
      selectValues?.notification?.value?.length !== 0
    ) {
      setSelectValues({
        ...selectValues,
        notification: notificationOption[0]
      })
    }
  }, [selectValues])

  function saveHandler() {
    if (exp.template === null) {
      if (
        selectValues.notification &&
        selectValues.template &&
        applyTemplate !== null &&
        typeof applyTemplate === 'function'
      ) {
        applyTemplate(
          selectValues.template.value,
          exp.eventvariant.id,
          selectValues.notification.value
        )
      }
    } else {
      if (
        selectValues.notification &&
        selectValues.template &&
        applyChanges !== null &&
        typeof applyChanges === 'function'
      ) {
        applyChanges(
          selectValues.template.value,
          exp.eventvariant.id,
          selectValues.notification.value
        )
      }
    }
  }

  const newGame = {
    box: {
      bg: 'white',
      padding: { base: '16px', md: '8px 16px', '2xl': '8px 24px' },
      fontSize: { base: '12px', '2xl': '14px' },
      mb: '2px',
      display: { base: 'block', md: 'grid' },
      gridTemplateColumns: { base: 'unset', md: '2fr 0.3fr 1fr 1fr' },
      alignItems: { base: 'unset', md: 'center' },
      gridGap: { base: 'unset', md: '16px' },
      ...(editMode.idx !== idx && editMode.enabled && { opacity: 0.5 })
    },
    text: {
      color: 'custom.black.500',
      mb: { base: '8px', md: 'unset' },
      fontWeight: { base: '500', md: '400' },
      fontSize: 'inherit',
      gridColumn: { base: 'unset', md: '1/2' }
    },
    dropdown: {
      1: {
        chakraProps: {
          mb: { base: '8px', md: 'unset' },
          gridColumn: { base: 'unset', md: '3/4' }
        },
        selectProps: {
          main: {
            isDisabled:
              isLoading ||
              (editMode.idx !== idx && editMode.enabled) ||
              !notificationPermission,
            options: templatesOptions,
            placeholder: 'Template...',
            value: selectValues?.template,
            onChange: onChangeDropdown('template')
          },
          styles: {
            ...(!template && {
              control: {
                background: customColors.red[20]
              }
            })
          }
        }
      },
      2: {
        chakraProps: {
          gridColumn: { base: 'unset', md: '4/5' }
        },
        selectProps: {
          main: {
            isDisabled:
              isLoading ||
              selectValues?.template?.value === null ||
              (editMode.idx !== idx && editMode.enabled) ||
              templatesOptions.length === 0 ||
              !notificationPermission,
            options: notificationOption,
            placeholder: 'Notification by...',
            value: selectValues?.notification,
            onChange: onChangeDropdown('notification')
          },
          styles: {
            ...(!notification && {
              control: {
                background: customColors.red[20]
              }
            })
          }
        }
      }
    }
  }

  return (
    <Box {...newGame.box}>
      <Text {...newGame.text}>{exp.eventvariant.name}</Text>
      {editMode.idx === idx && editMode.enabled && (
        <IconButton
          variant="blue"
          w="40px"
          h="40px"
          m="0 auto"
          icon={<DisketteIcon />}
          onClick={saveHandler}
        />
      )}
      <DropdownSelect {...newGame.dropdown[1]} />
      <DropdownSelect {...newGame.dropdown[2]} />
    </Box>
  )
}
