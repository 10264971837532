import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Image,
  Input,
  Text,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { BiImage, MdBrokenImage } from 'react-icons/all'
import InputMask from 'react-input-mask'
import { useQueryClient } from 'react-query'

import { loadImageToCloud } from '../../../../../../api/booking'
import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { convertByteToMb, generateWarningToast } from '../../../../../../libs'
import { usePublishWaiver } from '../../../../../../libs/hooks/publishWaiver'
import { usePatchWaiver } from '../../../../../../libs/hooks/useWaivers'
import {
  MAXIMUM_IMAGE_SIZE,
  MAXIMUM_IMAGES_COUNT
} from '../../../../../../configs'
import {
  BASE_URL_WO_SLASH,
  PHONE_INPUT_MASK
} from '../../../../../../constants'
import CustomWarningToast from '../../../../../Common/CustomWarningToast'
import FormikTwoStyledInput from '../../../../../Common/FormikComponents/FormikTwoStyledInput'
import Preloader from '../../../../../Common/Preloader'

export default function WaiverInfoSaved({ waiverId }) {
  const queryClient = useQueryClient()
  const toast = useToast()
  const formik = useFormikContext()
  const formikValues = formik.values
  const resetForm = formik.resetForm
  const setFieldValue = formik.setFieldValue
  const { mutate: patchWaiver, isLoading: isLoadingWaiverPatch } =
    usePatchWaiver()
  const image = formikValues.logoDraft
  const [selectedImage, setSelectedImage] = useState([])
  const [inputReload, setInputReload] = useState(true)
  const [itsBroken, setItsBroken] = useState(false)
  const [localLoading, setLocalLoading] = useState(false)
  const isLoading = localLoading
  const { mutate: publish } = usePublishWaiver()
  function save() {
    const { phoneDraft, emailDraft, addressDraft, logoDraft } = formikValues

    const toSend = {
      waiverId,
      phoneDraft,
      emailDraft,
      addressDraft,
      ...(!logoDraft & { logoDraft: '' })
    }
    console.log('toSend saved', toSend)
    sendHandler(toSend, 'Saved')
  }

  function publishHandler() {
    setLocalLoading(true)
    publish(waiverId, {
      onSuccess: () => {
        setLocalLoading(false)
        queryClient.invalidateQueries(['waiver', +waiverId])
        resetForm()
        setSelectedImage([])
        setInputReload(false)
      },
      onError: (err) => {
        setLocalLoading(false)
        const msg = err?.response?.data
        generateWarningToast({ msgObjects: msg, toast, id: 'edit-waiver' })
      }
    })
  }

  function sendHandler(toSend, type) {
    setLocalLoading(true)
    if (selectedImage.length !== 0) {
      console.log('here 2')
      loadImageToCloud(selectedImage)
        .then((r) => {
          return {
            ...toSend,
            ...(type === 'Saved' ? { logoDraft: r[0] } : { logo: r[0] })
          }
        })
        .then((obj) => {
          patchWaiver(
            {
              obj
            },
            {
              onSuccess: () => {
                setLocalLoading(false)
                queryClient.invalidateQueries(['waiver', +waiverId])
                resetForm()
                setSelectedImage([])
                setInputReload(false)
              },
              onError: (err) => {
                setLocalLoading(false)
                const msg = err?.response?.data
                generateWarningToast({
                  msgObjects: msg,
                  toast,
                  id: 'save-waiver'
                })
              }
            }
          )
        })
    } else {
      console.log('here 1')
      patchWaiver(
        {
          obj: toSend
        },
        {
          onSuccess: () => {
            setLocalLoading(false)
            queryClient.invalidateQueries(['waiver', +waiverId])
            resetForm()
            setSelectedImage([])
            setInputReload(false)
          },
          onError: (err) => {
            setLocalLoading(false)
            const msg = err?.response?.data
            generateWarningToast({ msgObjects: msg, toast, id: 'save-waiver' })
          }
        }
      )
    }
  }

  function deleteOldPhoto() {
    setFieldValue('logoDraft', '')
  }

  function onChangeFileHandler() {
    return (e) => {
      const images = Array.from(e.target.files)
      const allSizesSmallerRequired = images.some(
        (img) => img.size > MAXIMUM_IMAGE_SIZE
      )
      if (!allSizesSmallerRequired) {
        if (
          images.length <= MAXIMUM_IMAGES_COUNT &&
          images.length + selectedImage.length <= MAXIMUM_IMAGES_COUNT
        ) {
          if (selectedImage.length !== 0) {
            setSelectedImage([...selectedImage, ...images])
          } else {
            setSelectedImage(images)
          }
        } else {
          toast({
            status: 'warning',
            duration: 5000,
            isClosable: true,
            render: () => (
              <CustomWarningToast
                title="Warning"
                message={`You can load maximum ${MAXIMUM_IMAGES_COUNT} images.`}
                close={toast.closeAll}
              />
            )
          })
        }
      } else {
        toast({
          status: 'warning',
          duration: 5000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={`maximum image size must be
                                         less than ${convertByteToMb(
                                           MAXIMUM_IMAGE_SIZE
                                         )}mb.`}
              close={toast.closeAll}
            />
          )
        })
      }
    }
  }

  function deleteImageHandler(idx) {
    setInputReload(true)
    const newArr = [...selectedImage]
    newArr.splice(idx, 1)
    setSelectedImage(newArr)
  }

  function istBrokenHandler() {
    setItsBroken(true)
  }

  return (
    <FormikProvider value={formik}>
      <Box pos="relative">
        {isLoading && (
          <Box pos="absolute" top="0" left="0" bottom="0" right="0" zIndex={2}>
            <Preloader h="8px" w="calc(100% - 4px)" opacity="0.8" m="2px" />
          </Box>
        )}
        <Box
          bg="white"
          opacity={isLoading ? 0.4 : 1}
          p={{ base: '16px', md: '20px 16px', '2xl': '24px' }}
          mb="2px"
          d="grid"
          gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
          gridGap="16px"
        >
          {selectedImage.length === 0 && !image && (
            <Icon
              as={BiImage}
              m="0 auto"
              gridColumn={{ base: 'unset', md: '1/3' }}
              color="custom.gray.200"
              w="200px"
              h="200px"
            />
          )}
          {selectedImage.map((img, idx) => (
            <Box
              w="fit-content"
              maxW={{ base: '280px' }}
              pos="relative"
              gridColumn={{ base: 'unset', md: '1/3' }}
              m={{ base: '0 auto 16px', '2xl': '0 auto' }}
              key={'picturesFinishGame' + idx}
            >
              <IconButton
                aria-label="copy"
                pos="absolute"
                right="8px"
                top="8px"
                bg="gray"
                w="24px"
                height="24px"
                minH="unset"
                minW="unset"
                borderRadius="50%"
                _focus={{ border: 'none' }}
                _hover={{ bg: '' }}
                icon={<CrosshairIcon color="white" w="80%" height="50%" />}
                onClick={() => deleteImageHandler(idx)}
              />

              <Image src={URL.createObjectURL(img)} />
            </Box>
          ))}
          {!!image &&
            (itsBroken ? (
              <Box
                textAlign="center"
                border="1px solid"
                gridColumn={{ base: 'unset', md: '1/3' }}
                borderColor="custom.gray.200"
                pos="relative"
                p="20px"
              >
                <IconButton
                  aria-label="copy"
                  pos="absolute"
                  right="8px"
                  top="8px"
                  bg="gray"
                  w="24px"
                  height="24px"
                  minH="unset"
                  minW="unset"
                  borderRadius="50%"
                  _focus={{ border: 'none' }}
                  _hover={{ bg: '' }}
                  icon={<CrosshairIcon color="white" w="80%" height="50%" />}
                  onClick={deleteOldPhoto}
                />
                <Icon as={MdBrokenImage} w="40px" height="40px" />
                <Text
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  color="custom.black.500"
                >
                  Image link broken.
                </Text>
              </Box>
            ) : (
              <Box
                pos="relative"
                gridColumn={{ base: 'unset', md: '1/3' }}
                maxW="280px"
                m="0 auto"
              >
                <IconButton
                  aria-label="copy"
                  pos="absolute"
                  right="8px"
                  top="8px"
                  bg="gray"
                  w="24px"
                  height="24px"
                  minH="unset"
                  minW="unset"
                  borderRadius="50%"
                  _focus={{ border: 'none' }}
                  _hover={{ bg: '' }}
                  icon={<CrosshairIcon color="white" w="80%" height="50%" />}
                  onClick={deleteOldPhoto}
                />
                <Image
                  src={image}
                  onError={istBrokenHandler}
                  border="1px solid"
                  borderColor="custom.gray.200"
                  w="100%"
                />
              </Box>
            ))}
          <FormControl
            height="40px"
            mt="8px"
            w="100%"
            mr={{ base: '4px', md: '8px' }}
            bg="black"
            gridColumn={{ base: 'unset', md: '1/3' }}
          >
            <FormLabel
              htmlFor="file-upload"
              variant="lightBlue"
              w="inherit"
              fontWeight="500"
              height="100%"
              alignItems="center"
              d="inline-flex"
              justifyContent="center"
              cursor="pointer"
              _disabled={{ color: 'custom.gray.200', cursor: 'no-drop' }}
              disabled={!!(selectedImage.length >= 1 || image)}
              m="0"
              fontSize={{ base: '12px', '2xl': '14px' }}
            >
              Add logo
            </FormLabel>
            {inputReload && (
              <Input
                type="file"
                id="file-upload"
                disabled={!!(selectedImage.length >= 1 || image)}
                accept="image/jpeg, image/png"
                onChange={onChangeFileHandler()}
                max="5"
                d="none"
              />
            )}
          </FormControl>
          <FormikTwoStyledInput
            name="phoneDraft"
            input={{
              mask: PHONE_INPUT_MASK,
              as: InputMask
            }}
            placeholder="Phone"
          />
          <FormikTwoStyledInput name="addressDraft" placeholder="address" />
          <FormikTwoStyledInput name="emailDraft" placeholder="email" />
          <Box
            d="grid"
            gridColumn={{ base: 'unset', md: '2/3' }}
            gridTemplateColumns="1fr 1fr"
            gridGap="16px"
          >
            <Button variant="lightBlue" h="inherit" onClick={save}>
              Save
            </Button>
            <Button variant="blue" h="inherit" onClick={publishHandler}>
              Publish
            </Button>
          </Box>
        </Box>
      </Box>
    </FormikProvider>
  )
}
