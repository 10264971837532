import { Box, Button } from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import AccordionDropdown from './AccordionDropdown'
import AccordionInput from './AccordionInput'
import DeleteAttractionsModal from '../Modals/DeleteAttractionsModal'
import { useUpdateEvent } from '../../../../../libs/hooks/useEvents'
import { useLocations } from '../../../../../libs/hooks/useLocations'
import { usePaymentGates } from '../../../../../libs/hooks/useStripe'
import { addAndEditAttractionValidation } from '../../../../../configs/validation/addAttractionValidation'
import { SETTING_EXPERIENCE_URL } from '../../../../../constants'
import StyledAccordion from '../../../../Common/StyledAccodion'

export default function AttractionsListItem({ element = {} }) {
  const [edit, setEdit] = useState(false)
  const history = useHistory()
  const { data: { results: locations = [] } = {} } = useLocations()
  const { mutate: editExperience } = useUpdateEvent()
  const queryClient = useQueryClient()

  const optionsLocation = useMemo(() => {
    if (locations?.length !== 0) {
      const arr = locations.map((loc) => {
        return {
          value: loc.id,
          label: loc.name
        }
      })
      return [{ value: '', label: 'Unset' }, ...arr]
    } else {
      return []
    }
  }, [locations])

  const { data: { results: stripeData = [] } = {} } = usePaymentGates()
  const optionsStripe = useMemo(() => {
    if (stripeData?.length !== 0) {
      const arr = stripeData.map((stripe) => {
        return {
          value: {
            id: stripe.id,
            publicKey: stripe.public
          },
          label: stripe.name
        }
      })
      return [{ value: '', label: 'Unset' }, ...arr]
    } else {
      return []
    }
  }, [stripeData])
  const selectedElement = element
  const name = selectedElement.name
  const interval = selectedElement.interval
  const storylines = selectedElement.max_storylines
  const maxplrs = selectedElement.max_players
  const locationId = selectedElement.location
  const paymentId = selectedElement.stripe
  const pixel = selectedElement.pixel_id ? selectedElement.pixel_id : ''
  const ga =
    selectedElement.google_analytics !== null
      ? selectedElement.google_analytics
      : ''
  const eventId = element.id

  const selectedOptionLocation = useMemo(() => {
    for (let i = 0; i < optionsLocation.length; i++) {
      if (optionsLocation[i].value === locationId) {
        return optionsLocation[i]
      }
    }
  }, [optionsLocation, locationId])

  const selectedOptionStripe = useMemo(() => {
    for (let i = 0; i < optionsStripe.length; i++) {
      if (optionsStripe[i].value.id === paymentId) {
        return optionsStripe[i]
      }
    }
  }, [optionsStripe, paymentId])

  const formik = useFormik({
    initialValues: {
      name: name,
      interval: interval,
      storylines: storylines,
      maxplrs: maxplrs,
      location: selectedOptionLocation,
      payment: selectedOptionStripe,
      pixel: pixel,
      ga: ga
    },
    validationSchema: addAndEditAttractionValidation,
    enableReinitialize: true,
    onSubmit: (values) => saveEdit(values)
  })

  function saveEdit(values) {
    const toSend = {
      name: values?.name,
      interval: values?.interval,
      max_storylines: values?.storylines,
      max_players: values?.maxplrs,
      location: values?.location?.value,
      pixel_id: values?.pixel,
      google_analytics: values?.ga,
      stripe: values?.payment?.value?.id ? values?.payment?.value?.id : '',
      eventId: +eventId
    }
    const final = {}

    Object.keys(toSend).forEach((key) => {
      if (toSend[key]) {
        final[key] = toSend[key]
      }
    })
    editExperience(
      { obj: final },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['events'])
          setEdit(false)
        }
      }
    )
  }

  const formikHandleSubmit = formik.handleSubmit
  const formikValues = formik.values
  const setFieldValue = formik.setFieldValue
  const resetForm = formik.resetForm
  function onClickButton(path) {
    return (e) => {
      e.stopPropagation()
      history.push(path)
    }
  }

  function onChangeDropdownHandler(prop) {
    return (val) => setFieldValue(prop, val)
  }

  function cancelHandler() {
    setEdit(false)
    resetForm()
  }

  return (
    <StyledAccordion styles={{ button: { mb: '0' } }} defaultIndex={null}>
      <Button
        color="custom.blue.600"
        fontSize={{ base: '12px', '2xl': '14px' }}
        fontWeight="400"
        onClick={onClickButton(SETTING_EXPERIENCE_URL + `/${eventId}`)}
        variant="link"
      >
        {name}
      </Button>
      <FormikProvider value={formik}>
        <Box bg="white" padding="16px">
          <AccordionInput name="name" label="Name" disabled={!edit} />
          <AccordionInput name="interval" label="Interval" disabled={!edit} />
          <AccordionInput
            name="storylines"
            label="Storylines"
            disabled={!edit}
          />
          <AccordionInput name="maxplrs" label="Max Players" disabled={!edit} />
          <AccordionInput name="pixel" label="Pixel" disabled={!edit} />
          <AccordionInput name="ga" label="Ga" disabled={!edit} />
          <AccordionDropdown
            name="Location"
            mb="8px"
            options={optionsLocation}
            value={formikValues.location}
            isDisabled={!edit}
            onChange={onChangeDropdownHandler('location')}
          />
          <AccordionDropdown
            name="Payment"
            mb="16px"
            options={optionsStripe}
            value={formikValues.payment}
            isDisabled={!edit}
            onChange={onChangeDropdownHandler('payment')}
          />
          <Box d="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="8px">
            {edit ? (
              <>
                <Button variant="blue" onClick={formikHandleSubmit}>
                  Save
                </Button>
                <Button variant="red" onClick={cancelHandler}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button variant="lightBlue" onClick={() => setEdit(true)}>
                  Edit
                </Button>
                <DeleteAttractionsModal name={name} eventId={eventId} />
              </>
            )}
          </Box>
        </Box>
      </FormikProvider>
    </StyledAccordion>
  )
}
