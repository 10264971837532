import { Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import './../DataPicker/custom-div.css'

export default function NewOrderDataPicker({
  cb = null,
  selected = null,
  datepicker = {},
  ...rest
}) {
  const [date, setDate] = useState(selected)

  useEffect(() => {
    if (cb !== null && cb !== undefined && typeof cb === 'function') {
      cb(date)
    }
  }, [date])

  return (
    <Box d="flex" justifyContent="center" {...rest}>
      <div className="wrp">
        <DatePicker onChange={(date) => setDate(date)} inline {...datepicker} />
      </div>
    </Box>
  )
}
