import { Box, Link, Text } from '@chakra-ui/react'
import React from 'react'
import DataRow from '../../Common/DataRow'
import GBaseTag from '../../Common/GBaseTag'
import { BOOKING_URL, GIFT_CARDS_URL } from '../../../constants'
import { changeFormatAndTimezone } from '../../../libs'

export default function CashListItem({
  element = {},
  invalidateCashString,
  timezone
}) {
  const client = element?.client_email
  const sum = element?.amount
  const user = element?.gbase_user
  const comment = element?.comment
  const booking = element?.booking_code
  const giftcard = element?.giftcard_code
  const date = changeFormatAndTimezone(element?.created, timezone)
  const isPositive = sum > 0
  const gbaseTagColor = isPositive ? 'green' : 'red'
  const label = isPositive ? `+$${sum}` : `-$${-sum}`
  const tag = (
    <GBaseTag label={label} color={gbaseTagColor} m="0 auto" minW="100px" />
  )
  return (
    <Box p="16px" mb="2px" bg="white">
      {giftcard && (
        <Box
          d="flex"
          flexWrap="wrap"
          alignItems="center"
          mb={{ base: '4px', '2xl': '8px' }}
        >
          <Text
            fontFamily="inherit"
            fontWeight="400"
            color="custom.gray.300"
            fontSize={{ base: '12px', '2xl': '14px' }}
            lineHeight="24px"
            mr="8px"
            as={'span'}
            textTransform="capitalize"
          >
            Giftcard
          </Text>
          <Link href={`${GIFT_CARDS_URL}/?find=${giftcard}`} isExternal>
            <Text
              ml="auto"
              color="custom.blue.600"
              textDecoration="underline"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {giftcard}
            </Text>
          </Link>
        </Box>
      )}

      {booking && (
        <Box
          d="flex"
          flexWrap="wrap"
          alignItems="center"
          mb={{ base: '4px', '2xl': '8px' }}
        >
          <Text
            fontFamily="inherit"
            fontWeight="400"
            color="custom.gray.300"
            fontSize={{ base: '12px', '2xl': '14px' }}
            lineHeight="24px"
            mr="8px"
            as={'span'}
            textTransform="capitalize"
          >
            Booking
          </Text>
          <Link href={`${BOOKING_URL}/${booking}`} isExternal>
            <Text
              ml="auto"
              color="custom.blue.600"
              textDecoration="underline"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              #{booking}
            </Text>
          </Link>
        </Box>
      )}
      <Box
        d="flex"
        flexWrap="wrap"
        alignItems="center"
        mb={{ base: '4px', '2xl': '8px' }}
      >
        <Text
          fontFamily="inherit"
          fontWeight="400"
          color="custom.gray.300"
          fontSize={{ base: '12px', '2xl': '14px' }}
          lineHeight="24px"
          mr="8px"
          as={'span'}
          textTransform="capitalize"
        >
          Client:
        </Text>
        <Text
          fontFamily="inherit"
          fontWeight="400"
          color="custom.gray.900"
          fontSize={{ base: '12px', '2xl': '14px' }}
          sx={{ lineBreak: 'anywhere' }}
          as={'span'}
          lineHeight="24px"
        >
          {client ? client : '—'}
        </Text>
      </Box>
      <DataRow
        title="User"
        value={user ? user : 'unset'}
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />
      <DataRow
        title="Date"
        value={date ? date : 'unset'}
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />
      <DataRow
        title="Sum"
        value={tag}
        keyStyle={{ mr: '8px' }}
        justifyContent="unset"
      />
      {comment && (
        <DataRow
          title="Comment"
          value={comment}
          keyStyle={{ mr: '8px' }}
          justifyContent="unset"
        />
      )}
    </Box>
  )
}
