import { Text } from '@chakra-ui/react'
import moment from 'moment'
import momentTz from 'moment-timezone'
import { Link } from 'react-router-dom'

import CustomWarningToast from '@/components/Common/CustomWarningToast'
import { BOOKING_URL } from '../constants'

export function getRandomNumber(min, max) {
  return Math.floor(min + Math.random() * (max + 1 - min))
}
export const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export function fakeDataGenerator(count = 10) {
  let array = []
  const dates = [
    '01.10.21',
    '03.10.21',
    '04.10.21',
    '11.10.21',
    '13.10.21',
    '14.10.21',
    '15.10.21',
    '17.10.21',
    '22.10.21'
  ]

  function getFakeMinutes() {
    const minutes = getRandomNumber(0, 59)
    if (minutes < 10) {
      return '0' + minutes
    } else {
      return '' + minutes
    }
  }

  function getAmPm() {
    const rand = getRandomNumber(0, 1)
    if (rand === 0) {
      return 'AM'
    } else {
      return 'PM'
    }
  }

  function getStatus() {
    const rand = getRandomNumber(0, 3)
    if (rand === 0) {
      return 'Completed'
    }
    if (rand === 1) {
      return 'Running'
    }
    if (rand === 2) {
      return 'Upcoming'
    }
    if (rand === 3) {
      return 'Canceled'
    }
  }

  for (let i = 0; i < count; i++) {
    array.push({
      code: i + 1,
      date: dates[getRandomNumber(0, 8)],
      time:
        `${getRandomNumber(0, 12)}:${getFakeMinutes()}` + ' ' + `${getAmPm()}`,
      location: 'SBG',
      players: getRandomNumber(2, 20),
      client: 'Vladislav Samoilov',
      status: getStatus(),
      experience: 'Da Vinci Challenge'
    })
  }

  return array
}

export function uppercaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function linksCreator(value) {
  return value?.map((bookingItem, idx) => (
    <Link
      to={`${BOOKING_URL}/${bookingItem.id}`}
      key={'link' + idx + bookingItem.code}
    >
      <Text
        fontFamily="inherit"
        fontWeight="400"
        fontSize={{ base: '12px', '2xl': '14px' }}
        as="span"
        textDecoration="underline"
        color="custom.blue.600"
        whiteSpace="nowrap"
        mr={idx !== value.length - 1 && '6px'}
        lineHeight="19px"
      >
        #{bookingItem.code}
        {idx !== value.length - 1 && ','}
      </Text>
    </Link>
  ))
}

export function convertByteToMb(byte) {
  return byte / 1000000
}

export function isObject(obj) {
  return obj instanceof Object && obj.constructor === Object
}

export function flatObject(obj) {
  let finalObject = {}

  function newObjGenerator(obj, finalObject) {
    for (let key in obj) {
      if (!isObject(obj[key])) {
        finalObject[key] = obj[key]
      }
    }

    for (let key in obj) {
      if (isObject(obj[key])) {
        newObjGenerator(obj[key], finalObject)
      }
    }

    return finalObject
  }

  return newObjGenerator(obj, finalObject)
}

export function correctionTimezone(date, needSelectedDay = false) {
  const dateForCorrect = new Date(date * 1000)
  const today = new Date()
  const day = needSelectedDay ? dateForCorrect.getDate() : today.getUTCDate()
  const month = dateForCorrect.getMonth()
  const year = dateForCorrect.getFullYear()
  const hours = dateForCorrect.getHours()
  const minutes = dateForCorrect.getMinutes()
  const seconds = dateForCorrect.getSeconds()
  return Math.round(
    Date.UTC(year, month, day, hours, minutes, seconds).valueOf() / 1000
  )
}

export function friendlyUTCTime(utcTime, format = 'h:mm A - MM.DD.YY') {
  return moment.utc(utcTime).local().format(format)
}

export function friendlyTime(value, format = 'h:mm A - MM.DD.YY') {
  return moment(value).utcOffset(0).format(format)
}

export function friendlyTimeWithTZ(value, format = 'h:mm A - MM.DD.YY') {
  return moment(value).format(format)
}

export function endTodayTime() {
  const nowTime = new Date()
  const endOfToday = new Date(
    +friendlyTime(nowTime, 'YYYY'),
    +friendlyTime(nowTime, 'MM') - 1,
    +friendlyTime(nowTime, 'DD'),
    23,
    59
  )

  return Math.floor(endOfToday / 1000.0)
}

export function selectedDayMidnightTimeStart(selectedDate) {
  const dateForCorrect = new Date(selectedDate)
  const day = dateForCorrect.getDate()
  const month = dateForCorrect.getMonth()
  const year = dateForCorrect.getFullYear()

  return Math.round(Date.UTC(year, month, day, 0, 0, 1).valueOf() / 1000)
}

export function selectedDayMidnightTimeEnd(selectedDate) {
  const dateForCorrect = new Date(selectedDate)
  const day = dateForCorrect.getDate()
  const month = dateForCorrect.getMonth()
  const year = dateForCorrect.getFullYear()

  return Math.round(Date.UTC(year, month, day, 23, 59, 59).valueOf() / 1000)
}

export function isToday(time) {
  const todayBeforeForCorrect = new Date()
  const todayBeforeDay = todayBeforeForCorrect.getDate()
  const todayBeforeMonth = todayBeforeForCorrect.getMonth()
  const todayBeforeYear = todayBeforeForCorrect.getFullYear()
  const todayBeforeMidnight = Date.UTC(
    todayBeforeYear,
    todayBeforeMonth,
    todayBeforeDay,
    23,
    59,
    59
  )

  const todayForCorrect = new Date()
  const todayDay = todayForCorrect.getDate()
  const todayMonth = todayForCorrect.getMonth()
  const todayYear = todayForCorrect.getFullYear()
  const todayAfterMidnight = Date.UTC(todayYear, todayMonth, todayDay, 0, 0, 1)

  const dateForCorrect = new Date(time)
  const day = dateForCorrect.getDate()
  const month = dateForCorrect.getMonth()
  const year = dateForCorrect.getFullYear()
  const hours = dateForCorrect.getHours()
  const minutes = dateForCorrect.getMinutes()
  const seconds = dateForCorrect.getSeconds()
  const selectedDay = Date.UTC(year, month, day, hours, minutes, seconds)

  return todayAfterMidnight < selectedDay && selectedDay < todayBeforeMidnight
}

export function convertValues(x, inputMin, inputMax, outputMin, outputMax) {
  return (
    ((x - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin) +
    outputMin
  )
}

export function objectToCsv(data) {
  const csvRows = []
  const headers = Object.keys(data[0])
  csvRows.push(headers.join(';'))

  for (const row of data) {
    const values = headers.map((header) => {
      const escaped = row[header]
      return `"${escaped}"`
    })
    csvRows.push(values.join(';'))
  }

  return csvRows.join('\n')
}

export function dataURLtoFile(dataUrl, filename) {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export function UTCValueFromString(stringDate) {
  return moment(new Date(stringDate)).utc().valueOf()
}

export function UTCDateFromLocal(stringDate) {
  return moment(stringDate).utc()
}

export function getTimeFromDate(date, type) {
  if (type === 'hours') return moment(new Date(date)).utc().hours()
  if (type === 'minutes') return moment(new Date(date)).utc().minutes()
}

export function convertWeekdayInIndex(day) {
  const d = day.toLowerCase()
  switch (d) {
    case 'monday':
      return 0
    case 'tuesday':
      return 1
    case 'wednesday':
      return 2
    case 'thursday':
      return 3
    case 'friday':
      return 4
    case 'saturday':
      return 5
    case 'sunday':
      return 6
  }
}

export function convertSecondsToAmPm(secondsHandler) {
  let seconds = secondsHandler
  if (seconds > 86400) seconds = secondsHandler - 86400
  if (seconds < 59) {
    return '12:00 AM'
  }
  const hours = Math.floor(seconds / 3600)
  let minutes = Math.floor((seconds % 3600) / 60)
  if (minutes.toString().length === 1) minutes = '0' + minutes

  let amPm = hours < 12 ? ' AM' : ' PM'
  if (hours === 24) amPm = ' AM'
  let h = hours % 12 || 12
  if (h.toString().length === 1) h = '0' + h
  return `${h}:${minutes} ${amPm}`
}

export function convertTime12hTo24h(time) {
  const timeBeforeColumn = /\d\d(?=:)/
  const timeAfterColumn = /\d\d(?!:)/
  const AMorPM = /[a-zA-Z]+/
  const [AmPm] = time?.match(AMorPM) || [null]
  const [hours] = time?.match(timeBeforeColumn) || [null]
  const [minutes] = time?.match(timeAfterColumn) || [null]

  if (AmPm === 'PM' && +hours === 12) {
    return hours + ':' + minutes
  }

  if (AmPm === 'AM' && +hours === 12) {
    return 12 + +hours + ':' + minutes
  }

  if (AmPm === 'AM') {
    return hours + ':' + minutes
  }

  if (AmPm === 'PM') {
    return 12 + +hours + ':' + minutes
  }
}

export function convertAmPmToSeconds(time) {
  const time24 = convertTime12hTo24h(time)
  const timeBeforeColumn = /\d\d(?=:)/
  const timeAfterColumn = /\d\d(?!:)/
  const [hours] = time24.match(timeBeforeColumn)
  const [minutes] = time24.match(timeAfterColumn)
  return hours * 3600 + minutes * 60
}

export function onlyFrontTimestampCorrection(unixTime) {
  const offset = new Date().getTimezoneOffset()
  let res = unixTime
  if (offset < 0) res = unixTime - offset * 60
  if (offset > 0) res = unixTime + offset * 60
  return res
}

export function onlyFrontDateCorrection(unixTime, format = 'MM.DD.YY') {
  const offset = new Date().getTimezoneOffset()
  return moment(new Date(unixTime * 1000))
    .utc(-offset)
    .format(format)
}

export function weekdayIndex(unixTime, format = 'MM.DD.YY') {
  const offset = new Date().getTimezoneOffset()
  return moment(new Date(unixTime)).utc(-offset).isoWeekday() - 1
}

export function cleanPhoneNumber(phone) {
  return phone.replaceAll(/[+()-]/g, '')
}

export function correctionPhoneNumber(phone, type) {
  if (type === 'show') {
    return phone.replace('+1', '')
  }
  if (type === 'send') {
    return '+1' + cleanPhoneNumber(phone)
  }
}

export function correctDiscountsPrice(obj = {}) {
  if (Object.keys(obj).length !== 0) {
    const object = {}
    Object.keys(obj).forEach((key) => {
      object[key] = obj[key] / 100
    })
    return object
  } else {
    return {}
  }
}

export function getRandId() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

export function generateWarningToast({
  message,
  msgObjects = {},
  toast,
  id = '',
  position = 'bottom'
}) {
  const toastId = `error-toast-${id}`
  const text = 'Something went wrong'
  const needDefaultMessage = Object.keys(msgObjects).length === 0 && !message
  if (!toast.isActive(toastId)) {
    return toast({
      status: 'warning',
      id: toastId,
      duration: 3500,
      isClosable: true,
      position,
      render: () => (
        <CustomWarningToast
          title="Warning"
          message={needDefaultMessage ? text : message}
          msgObjects={msgObjects}
          close={toast.closeAll}
        />
      )
    })
  }
}

export function changeFormatAndTimezone(
  date,
  timezone,
  format = 'h:mm A - MM.DD.YY'
) {
  if (timezone) {
    return momentTz.tz(date, timezone).format(format)
  }
  return moment(date).format(format)
}

export function resetPagination(id) {
  if (document.getElementById(id)) {
    document.getElementById(id).click()
  }
}

export function convertTZ(date, tzString) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString
    })
  )
}

export function createErrorText(text) {
  return (
    <Text as="p" ml={{ md: '8px' }} color="custom.red.600" fontSize="14px">
      {text}
    </Text>
  )
}

export function changeTime(timezone) {
  const newDateTz = convertTZ(new Date(), timezone)
  const newDate = new Date(
    moment(newDateTz).year(),
    moment(newDateTz).month(),
    moment(newDateTz).date(),
    moment(newDateTz).hours(),
    moment(newDateTz).minutes()
  )
  return newDate
}

export function preparedObjectToCsv(players) {
  const keyForTransform = ['fullname', 'email', 'phone', 'birthdate']
  if (players.length === 0) return []
  return players.map((player) => {
    const obj = {}
    Object.keys(player).forEach((key) => {
      if (keyForTransform.includes(key)) {
        if (player[key]) {
          obj[key] = player[key]
        } else {
          obj[key] = '-'
        }
      }

      if (key === 'firstname') {
        obj['fullname'] = player['firstname'] + ' ' + player['lastname']
      }
    })
    return obj
  })
}

export function getStringDate(date) {
  if (date) {
    return date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear()
  }
}
