import React, { useMemo } from 'react'
import {
  COLUMNS_WITH_SORTING_COMMENTS,
  TABLE_COLUMN_VALUES_COMMENTS
} from '../../../configs'
import { changeFormatAndTimezone } from '../../../libs'
import TableCreator from '../../Common/TableCreator'
import { TABLE_COLUMNS_SIZES_COMMENTS } from '../../../configs/tablesSizes'
import { useTheme } from '@chakra-ui/react'

export default function PlayerCommentsTable({ tableData = [] }) {
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '16px 10px',
      letterSpacing: '-0.1px',
      textAlign: 'center'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    }
  }

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_COMMENTS.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header.toLowerCase() === 'date') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          return (
            <>
              {changeFormatAndTimezone(
                original?.created_at,
                original?.timezone,
                'hh:mm A - MM.DD.YY'
              )}
            </>
          )
        }
      }

      if (field.Header.toLowerCase() === 'name') {
        fieldObject['Cell'] = ({ row: { original } }, value) => {
          const obj = original?.created_by
          const name = obj?.name
          return <>{name} </>
        }
      }
      return fieldObject
    })
  }, [tableData])

  return (
    <TableCreator
      data={tableData}
      columns={columns}
      styles={tableStyles}
      sizesMap={TABLE_COLUMNS_SIZES_COMMENTS}
    />
  )
}
