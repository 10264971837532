import { useTheme } from '@emotion/react'
import { Box, IconButton, Text, useToast } from '@chakra-ui/react'
import { PencilIcon } from '../../../../../../../../assets/Icons/PencilIcon'
import { CrosshairIcon } from '../../../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../../../assets/Icons/DisketteIcon'
import {
  useDeleteBasePrice,
  useUpdateBasePrice
} from '../../../../../../../../libs/hooks/useBasePrices'
import { useQueryClient } from 'react-query'
import CustomWarningToast from '../../../../../../../Common/CustomWarningToast'
import PermissionRequired from '../../../../../../../Common/PermissionRequired'
import { PERMISSION_EVENT } from '../../../../../../../../constants'
import { FormikProvider, useFormik } from 'formik'
import FormikInput from '../../../../../../../Common/FormikComponents/FormikInput'
import { priceItemValidation } from '../../../../../../../../configs/validation/priceItemValidation'

export default function BasePricingItem({
  maxPlayers = 0,
  minPlayers = 0,
  price = 0,
  eventVariantId = '',
  timeLetter = '',
  priceId = '',
  maxPlayersLimitation = null,
  minPlayersLimitation = null
}) {
  const theme = useTheme()
  const toast = useToast()
  const errorBasePricingDelete = {
    id: 'error-base-pricing-delete',
    text: 'Something went wrong'
  }
  const errorBasePricing = {
    id: 'error-base-pricing',
    text: 'Something went wrong'
  }
  const priceInDollars = Number(price / 100).toFixed(2)
  const basePricingPermission = PermissionRequired(PERMISSION_EVENT, true)
  const queryClient = useQueryClient()
  const customColors = theme.colors.custom
  const { mutate: patchBasePrice } = useUpdateBasePrice()
  const { mutate: deleteBasePrice } = useDeleteBasePrice()

  function generateWarningToast({ text, id, incomingText }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 2000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={incomingText ? incomingText : text}
              close={toast.closeAll}
            />
          )
        })
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      minPlayers,
      maxPlayers,
      price: priceInDollars,
      edit: false,
      minPlayersLimitation,
      maxPlayersLimitation
    },
    validationSchema: priceItemValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const patchedObj = {
        ...values,
        eventVariantId: eventVariantId,
        priceId: priceId,
        pricingType: timeLetter,
        price: values.price * 100
      }
      if (formikValues.edit) {
        patchBasePrice(
          { obj: patchedObj },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['eventvariantId', +eventVariantId])
              setFieldValue('edit', false)
            },
            onError: (err) => {
              const msg = err?.response?.data?.msg[0]
              generateWarningToast({
                ...errorBasePricing,
                incomingText: msg
              })()
            }
          }
        )
      }
    }
  })
  const formikValues = formik.values
  const setFieldValue = formik.setFieldValue
  const resetForm = formik.resetForm
  const formikHandleSubmit = formik.handleSubmit

  function deletePriceOrCancel() {
    if (formikValues.edit) {
      setFieldValue('edit', false)
      resetForm()
    } else {
      deleteBasePrice(
        {
          obj: {
            eventVariantId: eventVariantId,
            priceId: priceId
          }
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['eventvariantId', +eventVariantId])
            setFieldValue('edit', false)
          },
          onError: (err) => {
            const msg = err?.response?.data?.msg[0]
            generateWarningToast({
              ...errorBasePricingDelete,
              incomingText: msg
            })()
            setFieldValue('edit', false)
          }
        }
      )
    }
  }

  function onEdit() {
    setFieldValue('edit', true)
  }

  return (
    <FormikProvider value={formik}>
      <Box
        bg={formikValues.edit ? 'custom.blue.200' : 'custom.lightGray'}
        minHeight="40px"
        padding="8px 16px"
        borderBottom={`1px solid ${customColors.borderBlue}`}
        d="grid"
        _last={{ borderBottom: 'none' }}
        gridTemplateColumns="2fr 1fr 1fr"
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 3fr 1fr"
          alignSelf="center"
          justifySelf="center"
        >
          {formikValues.edit ? (
            <FormikInput
              name="minPlayers"
              bg="white"
              textAlign="center"
              width="40px"
              height="24px"
              p="2px 0"
              placeholder="min"
            />
          ) : (
            <Text textAlign="center" width="40px">
              {formikValues.minPlayers}
            </Text>
          )}
          <Text
            textAlign="center"
            d="flex"
            alignItems="center"
            justifyContent="center"
          >
            -
          </Text>
          {formikValues.edit ? (
            <FormikInput
              name="maxPlayers"
              bg="white"
              textAlign="center"
              width="40px"
              height="24px"
              p="2px 0"
              placeholder="max"
            />
          ) : (
            <Text textAlign="center" width="40px">
              {formikValues.maxPlayers}
            </Text>
          )}
        </Box>
        {formikValues.edit ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormikInput
              name="price"
              bg="white"
              textAlign="center"
              width="40px"
              height="24px"
              p="2px 0"
              placeholder="price"
            />
          </Box>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            ${formikValues.price}
          </Box>
        )}

        <Box
          d="grid"
          gridTemplateColumns="1fr 1fr"
          gridColumnGap="16px"
          alignSelf="center"
          justifySelf="end"
        >
          <IconButton
            aria-label="edit-row-base-pricing"
            variant="funcColumnIconButtons"
            color={formikValues.edit ? 'custom.blue.600' : 'custom.gray.200'}
            h="12px"
            w="12px"
            justifySelf="end"
            onClick={formikValues.edit ? formikHandleSubmit : onEdit}
            isDisabled={!basePricingPermission}
            icon={
              formikValues.edit ? (
                <DisketteIcon h="inherit" w="inherit" />
              ) : (
                <PencilIcon h="inherit" w="inherit" />
              )
            }
          />
          <IconButton
            aria-label="edit-row-base-pricing"
            variant="funcColumnIconButtons"
            color="custom.gray.200"
            justifySelf="start"
            h="12px"
            w="12px"
            isDisabled={!basePricingPermission}
            onClick={deletePriceOrCancel}
            icon={<CrosshairIcon h="inherit" w="inherit" />}
          />
        </Box>
      </Box>
    </FormikProvider>
  )
}
