import { useMutation, useQuery, useQueryClient } from 'react-query'

import { getPriceInterval } from '../../api/bookings'
import { patchPriceInterval } from '../../api/Settings/attractions'

export function usePriceIntervals(
  idsArray = [],
  weekday = null,
  date = null,
  queryOption = {}
) {
  const queryClient = useQueryClient()

  const queryKeys = [weekday, 'priceIntervals', date, ...idsArray]
  function invalidate() {
    return queryClient.invalidateQueries(queryKeys)
  }
  return {
    invalidate,
    ...useQuery(
      queryKeys,
      async () => {
        if (idsArray.length !== 0) {
          const arr = []
          const result = await Promise.all(
            idsArray.map((id) => getPriceInterval(id, weekday, date))
          )
          result?.forEach((el) => {
            arr.push(...el)
          })
          return arr
        }
      },
      {
        ...queryOption
      }
    )
  }
}

export function usePatchPriceInterval(options = {}) {
  return useMutation(({ obj }) => patchPriceInterval(obj), {
    ...options
  })
}
