import { Box, Text } from '@chakra-ui/react'
import EditTemplateModal from '../Modals/EditTemplateModal'
import DeleteTemplateModal from '../Modals/DeleteTemplateModal'

export default function TemplatesItem({ name = '', notificationTemplateId }) {
  return (
    <Box d="flex" bg="white" p={{ base: '16px', '2xl': '16px 24px' }} mb="2px">
      <Text color="custom.gray.900" fontSize={{ base: '12px', '2xl': '14px' }}>
        {name}
      </Text>
      <Box d="flex" alignItems="center" ml="auto">
        <EditTemplateModal notificationTemplateId={notificationTemplateId} />
        <DeleteTemplateModal
          name={name}
          notificationTemplateId={notificationTemplateId}
        />
      </Box>
    </Box>
  )
}
