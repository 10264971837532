import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'

// type DeleteModalProps = {
//   disabled?: boolean;
//   onDelete: () => void;
// };

export default function DeleteComboModal({ disabled = false, onDelete, id }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleDelete = async () => {
    await onDelete()
    onClose()
  }
  return (
    <>
      <Button
        variant={'red'}
        color="custom.red.600"
        isDisabled={disabled}
        onClick={onOpen}
      >
        <DeleteIcon mr={1} />
        Delete
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="8px">Delete Combo</ModalHeader>
          <ModalCloseButton />
          <Text>Are you sure?</Text>
          <ModalFooter
            d="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="8px"
            mt="16px"
          >
            <Button w="100%" variant="lightBlue" onClick={onClose}>
              Cancel
            </Button>
            <Button w="100%" variant="red" onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
