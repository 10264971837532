import ScheduleItem from '../ScheduleItem'

export default function ScheduleList({
  intervals = [],
  eventVariantId = '',
  weekday = '',
  invalidatePriceIntervals,
  isLoadingPriceIntervals
}) {
  const data = intervals
  return data?.map((el, idx) => {
    return (
      <ScheduleItem
        key={'ScheduleList' + el.id + weekday + eventVariantId}
        endTime={el.end_time * 1000}
        startTime={el.start_time * 1000}
        onRequest={el.on_request}
        pricingType={el.pricing_type}
        hide={el.hide}
        eventVariantId={eventVariantId}
        weekday={weekday}
        isLoadingPriceIntervals={isLoadingPriceIntervals}
        id={el.id}
        cutOffTimeSwitch={el.cut_off_time}
        cutOffTimeValue={el.cut_off_time_value}
        invalidatePriceIntervals={invalidatePriceIntervals}
      />
    )
  })
}
