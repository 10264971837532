import { useQuery } from 'react-query'

import { getBookingsByBookingCodes } from '../../api/booking'

export function useBookingsByCodes(codes = [], queryOption = {}) {
  return useQuery(
    ['bookingsByOrder', ...codes],
    () => getBookingsByBookingCodes(codes),
    {
      ...queryOption
    }
  )
}
