import { Alert, AlertIcon, Box, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { CopyToClipboard } from 'react-copy-to-clipboard/src'
import { useHistory } from 'react-router-dom'

import { WAIVER_WIDGET_URL } from '../../../../constants'
import ButtonLightBlue from '../../../common/ButtonLightBlue'
import ButtonRed from '../../../common/ButtonRed'
import CopyIcon from '../../../common/Icons/CopyIcon'
import Modal from '../../../common/Modal'
import PlaceholderInput from '../../../common/PlaceholderInput'

export default function CompleteWaiverModal({
  isOpen,
  onOpen,
  onClose,
  setOpen,
  isDisabled,
  selectedProfile
}) {
  const history = useHistory()
  const { values, setFieldValue, resetForm } = useFormikContext()
  const bookingCode = values.bookingCode

  function addAnother() {
    resetForm()
    history.push(WAIVER_WIDGET_URL + `?booking=${bookingCode}`)
    onClose()
  }

  function onExitHandler() {
    resetForm()
    history.push(WAIVER_WIDGET_URL)
    onClose()
  }
  const email = selectedProfile?.email
  const textForCopy = `${window.location.origin}/waiver-widget?booking=${values?.bookingCode}`
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      closeByOutsideClick={false}
      additionalClassName={'waiver-widget--success-modal'}
    >
      <div className="waiver-widget--title-text">Your waiver is all set!</div>
      <Box>
        <Text mb={4}>
          Use link below to share it with any other adult participants who
          haven't completed theirs yet.
        </Text>
        <Alert status="info" mb={4}>
          <AlertIcon />
          Remember, any minors participating must have their waiver signed by an
          adult.
        </Alert>
        <Text>We're excited to welcome you to your game at Questroom!</Text>
      </Box>
      <div className="waiver-widget--title-text">Share the link:</div>
      <div className="waiver-widget--input-copy">
        <CopyToClipboard text={textForCopy}>
          <button>
            <CopyIcon />
          </button>
        </CopyToClipboard>
        <PlaceholderInput value={textForCopy} placeholder="Link" disabled />
      </div>
      <div className="waiver-widget--send-link">
        <PlaceholderInput value={email} placeholder="E-mail" disabled />
        {/*<ButtonLightBlue>Send a link</ButtonLightBlue>*/}
      </div>
      <div className="waiver-widget--buttons-container">
        <ButtonLightBlue onClick={addAnother} disabled={isDisabled}>
          Add another player
        </ButtonLightBlue>
        <ButtonRed onClick={onExitHandler}>Exit</ButtonRed>
      </div>
    </Modal>
  )
}
