import { useField } from 'formik'
import InputWithApply from '../../InputWithApply'

export default function FormikDiscounts({
  formik,
  label = '',
  cbClick = null,
  isDisabled = false
}) {
  const { name = '' } = formik
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  return (
    <InputWithApply
      label={label}
      cb={setValue}
      isDisabled={isDisabled}
      cbClick={cbClick}
    />
  )
}
