import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../../../constants'
import LocationsList from './LocationsList'
import LocationsTable from './LocationTable'

export const LocationsDataView = ({ data }) => {
  const isLargerThan768 = useSelector(matchMedia768InState)

  return isLargerThan768 ? (
    <LocationsTable tableData={data} />
  ) : (
    <LocationsList data={data} />
  )
}
