import { Box, Button, IconButton } from '@chakra-ui/react'
import { CombineMenuIcon } from './CombineMenuIcon'
import { useDispatch, useSelector } from 'react-redux'
import {
  matchMedia768InState,
  menuIsOpenInState,
  userInState
} from '../../../constants'
import { toggleMenu } from '../../../actions'
import NavigationMenu from '../NavigationMenu'

export default function BurgerMenu() {
  const dispatch = useDispatch()
  const isOpen = useSelector(menuIsOpenInState)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const user = useSelector(userInState)
  function openHandler() {
    dispatch(toggleMenu())
  }

  return (
    <Box overflow="hidden" height="100%" d="flex" alignItems="center">
      <IconButton
        aria-label="burger-menu"
        icon={<CombineMenuIcon isMenuOpen={isOpen} />}
        fontSize="24px"
        bg="none"
        onClick={openHandler}
        _focus={{
          boxShadow: 'none'
        }}
        _hover={{
          bg: isLargerThan768 ? (isOpen ? 'blue.50' : 'gray.100') : 'none'
        }}
      />
      <Box
        w="100%"
        bg="white"
        pos="absolute"
        top="72px"
        left={isOpen ? '0' : '-100%'}
        transition="all 0.2s ease-in"
        overflow="hidden"
        boxShadow="md"
        borderTop="1px solid"
        borderColor="custom.borderBlue"
      >
        <Box d="flex" flexDir="column">
          {!isLargerThan768 && (
            <Button
              variant="gbaseMenuButton"
              borderBottom="1px solid"
              borderColor="custom.borderBlue"
            >
              {user?.email}
            </Button>
          )}
          <NavigationMenu />
        </Box>
      </Box>
    </Box>
  )
}
