import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { friendlyTime } from '../../../../libs'
import { useNoShow } from '../../../../libs/hooks/useNoShow'
import { matchMedia1440InState } from '../../../../constants'
import { BookingContext } from '../../../../pages/Booking'
import DataRow from '../../../Common/DataRow'

export default function NoShowModal({
  locationName = '',
  startTime = '',
  experience = ''
}) {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const [bookingData, _isLoading, filters, setFilters, queryKeysBooking] =
    useContext(BookingContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const data = [
    { key: 'Game Date', value: friendlyTime(startTime, 'MM.DD.YY') },
    { key: 'Start time', value: friendlyTime(startTime, 'h:mm A') },
    { key: 'Location', value: locationName },
    { key: 'Experience', value: experience }
  ]

  const [discountVariant, setDiscountVariant] = useState('%')
  const [discount, setDiscount] = useState('')

  const { mutate: setNoShow } = useNoShow()

  function onClickHandlerDiscount() {
    if (discountVariant === '%') {
      setDiscountVariant('$')
    }

    if (discountVariant === '$') {
      setDiscountVariant('%')
    }
  }

  function onChangeHandler(e) {
    const [value] = e.target.value.match(/\d+/) || ['']
    setDiscount(value)
  }

  function noShowHandler() {
    const toSend = {
      bookingId: id,
      variant: discountVariant,
      ...(discountVariant === '$' && { discount: discount * 100 }),
      ...(discountVariant === '%' && { discount: discount })
    }

    setNoShow(
      { obj: toSend },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeysBooking)
          onClose()
        }
      }
    )
  }

  return (
    <>
      <Button onClick={onOpen} variant="red">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          Mark 'no show'
        </Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader>No show:</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              mb={{ base: '16px', '2xl': '18px' }}
              d="flex"
              flexDir={{ base: 'column', md: 'row' }}
              flexWrap="wrap"
            >
              {data.map((dataRow, idx) => (
                <DataRow
                  title={dataRow.key}
                  key={'modalAdd' + idx}
                  value={dataRow.value}
                  w={{ base: '100%', md: 'unset' }}
                  mb={{ base: '4px', '2xl': '8px' }}
                  keyStyle={{
                    mr: { base: 'unset', md: '8px' }
                  }}
                  valueStyle={{
                    mr: { base: 'unset', md: '16px' }
                  }}
                />
              ))}
            </Box>
            <Box
              d="flex"
              mb={{ base: '16px' }}
              height={{ base: '40px', '2xl': '56px' }}
            >
              <Box
                d="flex"
                w="100%"
                mr={{ base: '4px', md: '8px' }}
                fontSize={{ base: '14px', '2xl': '18px' }}
              >
                <Button
                  w="50%"
                  fontSize="inherit"
                  bg={
                    discountVariant === '%'
                      ? 'custom.blue.600'
                      : 'custom.lightGray'
                  }
                  color={
                    discountVariant === '%'
                      ? 'custom.lightGray'
                      : 'custom.gray.300'
                  }
                  border="1px solid"
                  borderColor={
                    discountVariant === '%'
                      ? 'custom.blue.600'
                      : 'custom.borderBlue'
                  }
                  onClick={onClickHandlerDiscount}
                  minW="unset"
                  borderRadius="none"
                  _hover={{ bg: '' }}
                  fontWeight={discountVariant === '%' ? '500' : '400'}
                  height="inherit"
                >
                  %
                </Button>
                <Button
                  w="50%"
                  fontSize="inherit"
                  bg={
                    discountVariant === '$'
                      ? 'custom.blue.600'
                      : 'custom.lightGray'
                  }
                  color={
                    discountVariant === '$'
                      ? 'custom.lightGray'
                      : 'custom.black.500'
                  }
                  onClick={onClickHandlerDiscount}
                  borderRadius="none"
                  minW="unset"
                  _hover={{ bg: '' }}
                  border="1px solid"
                  borderColor={
                    discountVariant === '$'
                      ? 'custom.blue.600'
                      : 'custom.borderBlue'
                  }
                  fontWeight={discountVariant === '$' ? '500' : '400'}
                  height="inherit"
                >
                  $
                </Button>
              </Box>

              <FormControl
                w="100%"
                ml={{ base: '4px', md: '8px' }}
                height={{ base: '40px', '2xl': '56px' }}
              >
                {isLargerThan1440 && (
                  <FormLabel
                    fontSize="10px"
                    fontWeight="400"
                    color="custom.gray.300"
                    pos="absolute"
                    zIndex="20"
                    top="10px"
                    left="16px"
                  >
                    Discount
                  </FormLabel>
                )}
                <Input
                  variant="gbase"
                  value={discount}
                  onChange={onChangeHandler}
                  type="text"
                  fontSize={{ base: '12px', '2xl': '14px' }}
                  p={{ '2xl': '24px 16px 8px' }}
                  height="inherit"
                  name="discount"
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter flexDir="column">
            <Button
              variant="red"
              w="100%"
              height={{ base: '40px', '2xl': '56px' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              fontWeight="500"
              isDisabled={discount.length === 0}
              onClick={noShowHandler}
            >
              Set no-show
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
