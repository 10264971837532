import moment from 'moment'

export function dataURLtoFile(dataUrl, filename) {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export function friendlyTime(value, format = 'h:mm A - MM.DD.YY') {
  return moment(value).utcOffset(0).format(format)
}

export function changeFormatAndTimezone(
  date,
  timezone,
  format = 'h:mm A - MM.DD.YY'
) {
  if (timezone) {
    return moment(date).tz(timezone).format(format)
  }
  return moment(date).format(format)
}

export function friendlyTimeWithTZ(value, format = 'h:mm A - MM.DD.YY') {
  return moment(value).format(format)
}
