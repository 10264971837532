import CustomPlayersInput from '../../CustomPlayersInput'
import { useField } from 'formik'

export default function FormikPlayersInput({
  name,
  id,
  cb = null,
  minValue = 1,
  maxValue = null,
  ...rest
}) {
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)

  function cbHandler() {
    return (val) => {
      setValue(val)
      if (cb !== null && typeof cb === 'function') {
        cb(val)
      }
    }
  }

  return (
    <CustomPlayersInput
      disableButton={true}
      includeWord={true}
      cb={cbHandler()}
      minValue={minValue}
      maxValue={maxValue}
      {...rest}
    />
  )
}
