import { Box } from '@chakra-ui/react'

export default function PseudoInput({ value = '', placeholder = '', ...rest }) {
  return (
    <Box
      as="span"
      padding="8px 18.5px"
      fontWeight="400"
      borderRadius="none"
      bg="custom.lightGray"
      fontSize="inherit"
      color={value ? 'custom.black.500' : 'custom.gray.300'}
      d="flex"
      h="40px"
      alignItems="center"
      {...rest}
    >
      {value ? value : placeholder}
    </Box>
  )
}
