export default function CrosshairIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99967 0.333984C3.31301 0.333984 0.333008 3.31398 0.333008 7.00065C0.333008 10.6873 3.31301 13.6673 6.99967 13.6673C10.6863 13.6673 13.6663 10.6873 13.6663 7.00065C13.6663 3.31398 10.6863 0.333984 6.99967 0.333984ZM9.86634 9.86732C9.60634 10.1273 9.18634 10.1273 8.92634 9.86732L6.99967 7.94065L5.07301 9.86732C4.81301 10.1273 4.39301 10.1273 4.13301 9.86732C3.87301 9.60732 3.87301 9.18732 4.13301 8.92732L6.05967 7.00065L4.13301 5.07398C3.87301 4.81398 3.87301 4.39398 4.13301 4.13398C4.39301 3.87398 4.81301 3.87398 5.07301 4.13398L6.99967 6.06065L8.92634 4.13398C9.18634 3.87398 9.60634 3.87398 9.86634 4.13398C10.1263 4.39398 10.1263 4.81398 9.86634 5.07398L7.93967 7.00065L9.86634 8.92732C10.1197 9.18065 10.1197 9.60732 9.86634 9.86732Z"
        fill="#BF3938"
      />
    </svg>
  )
}
