import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import InternetChecker from '@/components/Common/InternetChecker'
import MinDayChecker from '@/components/Common/MinDayChecker'
import ResolutionWatcher from '@/components/ResolutionWatcher'

import App from './App'
import { PosthogProvider } from './app/PosthogProvider'
import { theme } from './globalStyles'
import { persistor, store } from './store'

const queryClient = new QueryClient()

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <>
            <QueryClientProvider client={queryClient}>
              <PosthogProvider>
                <App />
                <MinDayChecker />
                <InternetChecker />
                <ReactQueryDevtools initialIsOpen={false} />
              </PosthogProvider>
            </QueryClientProvider>
            <ResolutionWatcher />
          </>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.querySelector('#root')
)
