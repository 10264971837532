import { Box, Button } from '@chakra-ui/react'
import { PlusIcon } from '../../../../assets/Icons/PlusIcon'
import { useSelector } from 'react-redux'
import {
  matchMedia768InState,
  PERMISSION_EVENT,
  userInState
} from '../../../../constants'
import AttractionsList from './AttractionsList'
import { createContext, useReducer, useState } from 'react'
import { attractionsReducer, init } from './localState/reducer'
import AttractionsTable from './AttractionsTable'
import { useEvents } from '../../../../libs/hooks/useEvents'
import { setData } from './localState/actions'
import Preloader from '../../../Common/Preloader'
import NewAttractionsListItem from './NewAttractionsListItem'
import PermissionRequired from '../../../Common/PermissionRequired'

export const AttractionContext = createContext(null)

export default function Attractions() {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [attractionsState, attractionsDispatch] = useReducer(
    attractionsReducer,
    [],
    init
  )
  const [newElement, setNewElement] = useState(false)
  const user = useSelector(userInState)
  const userLocations = user?.locations
  const attractionPermissionAccess = PermissionRequired(PERMISSION_EVENT, true)
  const { isLoading, refetch: refetchEvents } = useEvents({
    onSuccess: (data) => {
      const loadedEvents = data
      const final = []
      loadedEvents.forEach((event) => {
        if (userLocations?.includes(event.location)) {
          final.push(event)
        }
      })
      final.sort((a, b) => b.id - a.id)
      attractionsDispatch(setData(final))
    }
  })

  function newElementHandler() {
    setNewElement(true)
  }

  return (
    <Box>
      <Button
        variant="blue"
        fontWeight="500"
        fontSize={{ base: '12px', '2xl': '14px' }}
        w="100%"
        mb={{ base: '16px' }}
        display={{ base: 'inline-flex', md: 'none' }}
        onClick={newElementHandler}
        isDisabled={!attractionPermissionAccess}
      >
        <PlusIcon mr="8px" color={{ md: 'custom.blue.600', '2xl': 'white' }} />
        New Attractions
      </Button>
      <AttractionContext.Provider
        value={[attractionsState, attractionsDispatch]}
      >
        {isLargerThan768 ? (
          isLoading ? (
            <Preloader />
          ) : (
            <AttractionsTable
              attractionPermissionAccess={attractionPermissionAccess}
            />
          )
        ) : (
          <>
            {newElement && (
              <NewAttractionsListItem setNewElement={setNewElement} />
            )}

            <AttractionsList
              newElement={newElement}
              attractionPermissionAccess={attractionPermissionAccess}
            />
          </>
        )}
      </AttractionContext.Provider>
    </Box>
  )
}
