import {
  clonedElementInState,
  editInState,
  newModeInState,
  requiredPropsInState,
  useAttractionsDispatch,
  useAttractionsSelector
} from '../../../localState/constants'
import { Button } from '@chakra-ui/react'
import { dismissHandler, editHandler } from '../../../Common'
import DeleteAttractionsModal from '../../../Modals/DeleteAttractionsModal'
import React from 'react'
import { setNewMode } from '../../../localState/actions'

export default function AttractionsLargeButtons({
  row,
  saveEdit,
  createNew,
  attractionPermissionAccess
}) {
  const dispatch = useAttractionsDispatch()
  const edit = useAttractionsSelector(editInState)
  const newMode = useAttractionsSelector(newModeInState)
  const clonedEl = useAttractionsSelector(clonedElementInState)
  const requiredProps = useAttractionsSelector(requiredPropsInState)
  const disableSave =
    requiredProps.every((prop) => !!clonedEl[prop]) &&
    !!clonedEl['location'] &&
    !!clonedEl['stripe']
  function onCancelHandler() {
    if (newMode) {
      dispatch(setNewMode(false))
      dismissHandler(dispatch)()
    } else {
      dismissHandler(dispatch)()
    }
  }
  function onSaveHandler() {
    if (newMode) {
      createNew()
    } else {
      saveEdit(row.original.id)
    }
  }

  return edit.enabled && +row.id === edit.idCell ? (
    <>
      <Button
        aria-label="edit-gate"
        color="custom.blue.600"
        variant="link"
        mr="12px"
        isDisabled={!disableSave}
        onClick={onSaveHandler}
      >
        Save
      </Button>
      <Button
        aria-label="delete-gate"
        color="custom.red.600"
        variant="link"
        onClick={onCancelHandler}
      >
        Cancel
      </Button>
    </>
  ) : (
    <>
      <Button
        aria-label="edit-gate"
        color="custom.blue.600"
        variant="link"
        mr="12px"
        isDisabled={
          (edit.enabled && +row.id !== edit.idCell) ||
          !attractionPermissionAccess
        }
        onClick={editHandler(row.id, edit, dispatch)}
      >
        Edit
      </Button>
      <DeleteAttractionsModal
        name={row.original.name}
        disabled={edit.enabled}
        eventId={+row.original.id}
      />
    </>
  )
}
