import { api } from '../index'

export async function sendToEmail({ email, url }) {
  try {
    const { data } = await api.post('/api/users/reset_password_by_email/', {
      user_email: email,
      front_url: window.location.origin
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function setNewPassword({ email, password, token }) {
  try {
    const { data } = await api.post('/api/users/set_new_password/', {
      user_email: email,
      password,
      token
    })
    return data
  } catch (e) {
    console.log(e)
  }
}
