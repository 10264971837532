import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import ButtonBlue from '../../common/ButtonBlue'
import ButtonGray from '../../common/ButtonGray'
import FormikInput from '../../common/FormikComponents/FormikInput'
import { InputDatePicker } from '../../common/InputDatepicker'
import { friendlyTimeWithTZ } from '../../../common'

export default function CreateProfile() {
  const { setFieldValue, values, errors, touched, validateField } =
    useFormikContext()
  const createdProfile = values.createdProfile
  const minimalAge = values?.loadedBooking?.minimal_age
    ? values?.loadedBooking?.minimal_age
    : 0
  const minDate = new Date(
    new Date().getUTCFullYear() - minimalAge,
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0
  )
  const selectedProfile = values?.selectedProfile
  const birthdate = selectedProfile?.birthdate
  const slicedDate = birthdate ? birthdate?.split('-') : ''
  const initialState = birthdate
    ? new Date(+slicedDate[0], +slicedDate[1], +slicedDate[2], 0, 0)
    : ''
  const [date, setDate] = useState(initialState)
  const [datePickerError, setDatePickerError] = useState(false)
  const isReplaceProfileMode = values.isReplaceProfileMode
  const itsNewProfile = values.itsNewProfile
  const technicalNumber = values.itsNewProfile

  function onChangeDate() {
    return (val) => {
      setDate(val)
      const datePath = isReplaceProfileMode
        ? 'selectedProfile.birthdate'
        : 'createdProfile.birthdate'
      setFieldValue(datePath, friendlyTimeWithTZ(val, 'YYYY-MM-DD'))
    }
  }

  useEffect(() => {
    if (!createdProfile.birthdate) {
      onChangeDate()(date)
    }
  }, [createdProfile.birthdate])

  const firstnameError = errors?.createdProfile?.firstname
  const lastnameError = errors?.createdProfile?.lastname
  const emailError = errors?.createdProfile?.email
  const birthdateError = errors?.createdProfile?.birthdate

  const firstnameSelectedError = errors?.selectedProfile?.firstname
  const lastnameSelectedError = errors?.selectedProfile?.lastname
  const birthdateSelectedError = errors?.selectedProfile?.birthdate
  const emailSelectedError = errors?.selectedProfile?.email

  const globalError =
    firstnameError ||
    lastnameError ||
    emailError ||
    birthdateError ||
    datePickerError
  const selectedProfileError =
    firstnameSelectedError ||
    lastnameSelectedError ||
    emailSelectedError ||
    birthdateSelectedError
  const disableSave = isReplaceProfileMode ? selectedProfileError : globalError
  const requireAuth = values?.waiverData?.require_authentication

  function cancel() {
    setFieldValue('technicalNumber', false)
    setFieldValue('phoneNumberAccepted', false)
    setFieldValue('phoneNumber', '+1')
    setFieldValue('phoneNumberMasked', '')
    setFieldValue('smsCode', '')
    setFieldValue('createMode', false)
  }

  function save() {
    if (isReplaceProfileMode) {
      setFieldValue('createMode', false)
    } else {
      setFieldValue('selectedProfile', {
        ...createdProfile,
        minors: []
      })
      setFieldValue('createMode', false)
      setFieldValue('itsNewProfile', true)
    }
  }

  const firstnamePath = isReplaceProfileMode
    ? 'selectedProfile.firstname'
    : 'createdProfile.firstname'
  const lastnamePath = isReplaceProfileMode
    ? 'selectedProfile.lastname'
    : 'createdProfile.lastname'
  const emailPath = isReplaceProfileMode
    ? 'selectedProfile.email'
    : 'createdProfile.email'

  return (
    <div className="waiver-widget--create-profile">
      <span className="waiver-widget--title-text">Create new profile</span>
      <span className="waiver-widget--text">
        In order to sign the waiver you need to create a player profile with us
        - it’s quick and easy, and it saves your details for the future so you
        don’t need to fill in a waiver next time!
      </span>
      <div className="waiver-widget--two-flex">
        <FormikInput name={firstnamePath} placeholder="Firstname" />
        <FormikInput name={lastnamePath} placeholder="Lastname" />
      </div>
      <FormikInput name={emailPath} placeholder="E-mail" />
      <DatePicker
        showYearDropdown={true}
        yearDropdownItemNumber={100}
        scrollableYearDropdown={true}
        showMonthDropdown={true}
        onChange={onChangeDate()}
        dateFormat="yyyy-MM-dd"
        selected={date}
        maxDate={minDate}
        minDate={new Date(1900, 1, 1)}
        customInput={
          <InputDatePicker
            setExternalError={setDatePickerError}
            mask={'9999-99-99'}
          />
        }
      />
      <div className="waiver-widget--buttons-container">
        {!isReplaceProfileMode && (
          <ButtonGray
            onClick={cancel}
            className="button button-gray red"
            disabled={!requireAuth}
          >
            Cancel
          </ButtonGray>
        )}
        <ButtonBlue onClick={save} disabled={disableSave}>
          Save
        </ButtonBlue>
      </div>
    </div>
  )
}
