import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Text
} from '@chakra-ui/react'
import ButtonsToggle from '../../Common/ButtonsToggle'
import DropdownSelect from '../../Common/DropdownSelect'
import DatePickerModal from '../../Booking/Modals/DatePickerModal'
import { useSelector } from 'react-redux'
import { matchMedia768InState, statisticsTabInState } from '../../../constants'
import { useEffect, useState } from 'react'
import { correctionTimezone, friendlyTime } from '../../../libs'

export default function UpperModule({
  buttonsSwitchData = [],
  refresh,
  cb = null,
  timeCb = null
}) {
  const selectedStatisticsTab = useSelector(statisticsTabInState)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [customTime, setCustomTime] = useState({
    from: '',
    to: ''
  })

  function onChangeDate(prop) {
    return (time) => {
      const correctedTime = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
        prop === 'from' ? 7 : 6,
        prop === 'from' ? 0 : 59,
        prop === 'from' ? 0 : 59
      )

      if (prop === 'from') {
        setCustomTime({
          ...customTime,
          from: correctedTime.valueOf()
        })
      }

      if (prop === 'to') {
        setCustomTime({
          ...customTime,
          to: correctedTime.valueOf()
        })
      }
    }
  }

  const [selectedTimeTab, setSelectedTimeTab] = useState(0)

  const dropdownOption = [
    { value: 0, label: 'this week' },
    { value: 1, label: 'this month' },
    { value: 2, label: 'custom time' }
  ]

  useEffect(() => {
    if (!isLargerThan768) {
      setSelectedTimeTab(3)
    }
  }, [isLargerThan768])

  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate()
  }

  useEffect(() => {
    const today = new Date()
    const dayOfTheWeek = new Date().getDay()

    const weekStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - dayOfTheWeek,
      7,
      0
    )
    const weekEnd = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + (6 - dayOfTheWeek + 1),
      6,
      59,
      59
    )
    const thisMonth = new Date().getMonth()
    const thisYear = new Date().getFullYear()
    const thisMonthStart = new Date(today.getFullYear(), thisMonth, 1, 7, 0)
    const thisMonthEnd = new Date(
      today.getFullYear(),
      thisMonth,
      daysInMonth(thisMonth + 1, thisYear),
      6,
      59,
      59
    )

    const timeObj = {
      from: '',
      to: ''
    }
    switch (selectedTimeTab) {
      case 0:
        timeObj.from = correctionTimezone(
          Math.round(weekStart.valueOf() / 1000),
          true
        )
        timeObj.to = correctionTimezone(
          Math.round(weekEnd.valueOf() / 1000),
          true
        )
        break
      case 1:
        timeObj.from = correctionTimezone(
          Math.round(thisMonthStart.valueOf() / 1000),
          true
        )
        timeObj.to = correctionTimezone(
          Math.round(thisMonthEnd.valueOf() / 1000),
          true
        )
        break
    }
    if (
      timeCb !== null &&
      typeof timeCb === 'function' &&
      selectedTimeTab !== 2
    ) {
      timeCb(timeObj)
    }
  }, [selectedTimeTab])

  function onChangeDropdown(object) {
    setSelectedTimeTab(object.value)
  }

  function onSearchHandler() {
    if (timeCb !== null && typeof timeCb === 'function') {
      let customTimeFromHandler = Math.round(customTime.from / 1000)
      let customTimeToHandler = Math.round(customTime.to / 1000)
      if (selectedTimeTab === 2) {
        customTimeToHandler = customTimeToHandler + 86400
      }
      timeCb({
        from: correctionTimezone(customTimeFromHandler, true),
        to: correctionTimezone(customTimeToHandler, true)
      })
    }
  }

  return (
    <Box p={{ base: '16px' }} bg="white" mb="2px">
      <Text
        color="custom.black.600"
        mb={{ base: '16px' }}
        fontSize={{ base: '24px' }}
        d={{ md: 'none' }}
        fontWeight="600"
      >
        Statistics
      </Text>
      {!isLargerThan768 && (
        <>
          <Button
            variant="blue"
            w="100%"
            mb="8px"
            fontSize={{ base: '12px', '2xl': '14px' }}
            onClick={refresh}
          >
            Refresh
          </Button>
          <ButtonsToggle
            data={buttonsSwitchData}
            cb={cb}
            returnIndex={true}
            skipInitial={true}
            initialState={buttonsSwitchData[selectedStatisticsTab]}
            w="100%"
            mb={{ base: '8px' }}
            d={{ base: 'grid', md: 'none' }}
          />
        </>
      )}
      <Box
        d="grid"
        gridTemplateColumns={{
          base: '1fr 1fr',
          md: 'repeat(4, 1fr)',
          '2xl': '4fr repeat(4, 1fr)'
        }}
        gridGap="8px"
        mb={{ base: '8px', md: 'unset' }}
      >
        <ButtonsToggle
          data={['this week', 'this month', 'custom time']}
          cb={setSelectedTimeTab}
          initialState={'today'}
          returnIndex={true}
          w="496px"
          mb={{ base: '8px' }}
          d={{ base: 'none', '2xl': 'grid' }}
        />
        <DropdownSelect
          chakraProps={{ d: { base: 'none', md: 'block', '2xl': 'none' } }}
          selectProps={{
            main: {
              options: dropdownOption,
              onChange: onChangeDropdown,
              value: dropdownOption[selectedTimeTab]
            }
          }}
        />
        {selectedTimeTab === 2 && (
          <>
            <InputGroup gridColumn={{ '2xl': '3/4' }}>
              <Input
                variant="gbase"
                placeholder="From"
                isDisabled={true}
                value={
                  customTime.from
                    ? friendlyTime(customTime.from, 'MM.DD.YY')
                    : ''
                }
              />
              <InputRightElement>
                <DatePickerModal
                  cb={onChangeDate('from')}
                  skipInitial={true}
                  datepicker={{
                    maxDate: customTime.to ? customTime.to : new Date()
                  }}
                />
              </InputRightElement>
            </InputGroup>
            <InputGroup gridColumn={{ '2xl': '4/5' }}>
              <Input
                variant="gbase"
                placeholder="To"
                isDisabled={true}
                value={
                  customTime.to ? friendlyTime(customTime.to, 'MM.DD.YY') : ''
                }
              />
              <InputRightElement>
                <DatePickerModal
                  cb={onChangeDate('to')}
                  skipInitial={true}
                  datepicker={{
                    maxDate: new Date(),
                    ...(customTime.from && { minDate: customTime.from })
                  }}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              variant="blue"
              w="100%"
              _hover={{
                _disabled: false
              }}
              onClick={onSearchHandler}
              isDisabled={!customTime.from || !customTime.to}
              d={{ base: 'none', md: 'inline-flex' }}
              gridColumn={{ '2xl': '5/6' }}
            >
              Search
            </Button>
          </>
        )}
      </Box>
      <Button
        variant="blue"
        w="100%"
        d={{ md: 'none' }}
        onClick={onSearchHandler}
        isDisabled={!customTime.from || !customTime.to}
      >
        Search
      </Button>
    </Box>
  )
}
