import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { useEditPaymentGate } from '@/libs/hooks/useStripe'

import ButtonsToggle from '@/components/Common/ButtonsToggle/index'
import CustomWarningToast from '@/components/Common/CustomWarningToast'
import FormikTwoStyledInput from '@/components/Common/FormikComponents/FormikTwoStyledInput'

import { CrosshairIcon } from '@/assets/Icons/CrosshairIcon'
import { DisketteIcon } from '@/assets/Icons/DisketteIcon'
import { PencilIcon } from '@/assets/Icons/PencilIcon'

export default function EditPaymentGatesModal({
  paymentGateId,
  paymentGateName,
  privateKey,
  publicKey,
  isDefault,
  feePercentInt,
  salesTaxPercent,
  paymentProvider,
  additionalPrivateKey
}) {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: updatePaymentGate } = useEditPaymentGate()

  const paymentGateError = {
    id: 'error-payment-gate-edit',
    text: 'Something went wrong.'
  }
  const formik = useFormik({
    initialValues: {
      paymentGateId,
      paymentGateName,
      privateKey,
      additionalPrivateKey,
      publicKey,
      isDefault,
      feePercentInt,
      salesTaxPercent,
      paymentProvider
    },
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  function generateWarningToast({ text, id, incomingText, msgObject }) {
    if (!toast.isActive(id)) {
      return toast({
        status: 'warning',
        id: id,
        duration: 4000,
        isClosable: true,
        render: () => (
          <CustomWarningToast
            title="Warning"
            message={incomingText ? incomingText : text}
            close={toast.closeAll}
            msgObjects={msgObject}
          />
        )
      })
    }
  }

  const { values: formikValues, setFieldValue, resetForm } = formik

  function handleSubmit(values) {
    updatePaymentGate(
      { ...values, paymentGateId: +paymentGateId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['stripe'])
          onClose()
        },
        onError: (err) => {
          resetForm()
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          generateWarningToast({
            ...paymentGateError,
            incomingText: msg,
            msgObjects
          })
        }
      }
    )
  }

  useEffect(() => {
    if (!isOpen) {
      resetForm()
    }
    return () => {
      resetForm()
    }
  }, [isOpen])

  function onChangeSwitch(e) {
    setFieldValue('isDefault', e.target.checked)
  }

  return (
    <>
      <IconButton
        aria-label="edit-gate"
        color="custom.gray.200"
        mr="12px"
        variant="funcColumnIconButtons"
        onClick={() => onOpen()}
        icon={<PencilIcon w="inherit" h="inherit" />}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
        >
          <ModalHeader mb="34px">Edit Payment gate</ModalHeader>
          <ModalCloseButton />
          <FormikProvider value={formik}>
            <ModalBody fontSize={{ base: '12px' }}>
              <ButtonsToggle
                data={['STRIPE', 'SQUAREUP']}
                useExternalControl={true}
                w="224px"
                initialState={formik.values.paymentProvider}
                cb={(data) => {
                  formik.setFieldValue('paymentProvider', data)
                }}
              />
              <FormikTwoStyledInput
                id="name"
                name="paymentGateName"
                placeholder="name"
                mt="16px"
                mb="16px"
              />
              <FormikTwoStyledInput
                id="publicKey"
                name="publicKey"
                placeholder="Public key"
                mb="16px"
              />
              <FormikTwoStyledInput
                id="feePercentInt"
                name="feePercentInt"
                placeholder="Booking Fee, %"
                mb="16px"
              />
              <FormikTwoStyledInput
                id="salesTaxPercent"
                name="salesTaxPercent"
                placeholder="Sales Tax Fee, %"
                mb="16px"
              />
              <FormikTwoStyledInput
                id="privateKey"
                name="privateKey"
                placeholder="Private key"
                mb="16px"
              />
              <FormikTwoStyledInput
                id="additional"
                name="additionalPrivateKey"
                placeholder={
                  formik.values.payment_provider === 'SQUAREUP'
                    ? 'Application ID'
                    : 'Stripe Connect ID'
                }
                mb="16px"
              />
              <Box
                d="grid"
                gridTemplateColumns="1fr 1fr"
                mb="16px"
                border="1px solid"
                borderColor={{ base: 'transparent', md: 'custom.borderBlue' }}
                height={{ base: '40px', md: '56px' }}
                alignItems="center"
                p="0px 18px"
                bg="custom.lightGray"
              >
                <Text>is Default:</Text>
                <Switch
                  isChecked={formikValues.isDefault}
                  justifySelf="end"
                  mt="2px"
                  onChange={onChangeSwitch}
                />
              </Box>
            </ModalBody>
            <ModalFooter
              d="grid"
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
            >
              <Button w="100%" variant="blue" onClick={formik.handleSubmit}>
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button w="100%" variant="red" onClick={onClose}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </ModalFooter>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  )
}
