import React, { useEffect, useState } from 'react'
import { Input, useTheme } from '@chakra-ui/react'
import {
  clonedElementInState,
  requiredPropsInState,
  useAttractionsDispatch,
  useAttractionsSelector
} from '../../localState/constants'
import { setPropertyValue, setRequiredProps } from '../../localState/actions'

const EditableCell = ({ prop = '', isNumeric = false, isRequired = false }) => {
  const regexp = /^[1-9]\d*$/
  const dispatch = useAttractionsDispatch()
  const clonedEl = useAttractionsSelector(clonedElementInState)
  const [input, setInput] = useState(clonedEl[prop])
  const [error, setError] = useState(false)
  const requiredProps = useAttractionsSelector(requiredPropsInState)
  const theme = useTheme()
  const customColors = theme.colors.custom
  useEffect(() => {
    if (isRequired) {
      if (!requiredProps.includes(prop)) {
        dispatch(setRequiredProps([...requiredProps, prop]))
      }
      if (!error && !input) setError(true)
    }
  }, [requiredProps])

  function onChange(e) {
    if (isNumeric) {
      setInput(regexp.test(e.target.value) ? e.target.value : '1')
    } else {
      setInput(e.target.value)
    }
  }

  function onBlur() {
    const obj = {
      property: prop,
      value: input
    }
    if (!input && requiredProps.includes(prop)) {
      if (!error) setError(true)
    } else {
      if (error) setError(false)
      dispatch(setPropertyValue(obj))
    }
  }

  useEffect(() => {
    const obj = {
      property: prop,
      value: input
    }
    dispatch(setPropertyValue(obj))
  }, [input])

  return (
    <Input
      placeholder={input ? input : 'empty'}
      variant="unstyled"
      value={input ? input : ''}
      onChange={onChange}
      onBlur={onBlur}
      bg={error ? 'rgba(255,223,223,0.8)' : 'white'}
      textAlign="center"
      borderRadius="none"
      _focus={{ borderColor: 'custom.blue.600' }}
      border={`1px solid ${customColors.borderBlue}`}
      fontSize="inherit"
      height="40px"
      maxW="85%"
      padding="0 2px"
      textOverflow="ellipsis"
      type={isNumeric ? 'number' : 'text'}
    />
  )
}
export default EditableCell
