import { FormControl } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { useField } from 'formik'

import DropdownSelect from '../../DropdownSelect'

export default function FormikDropdown({
  main,
  chakraProps,
  selectProps,
  cb = null,
  label,
  isDisabled = false,
  formik = {},
  ...rest
}) {
  const { name = '', id = '' } = formik
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  const theme = useTheme()
  const customColors = theme.colors.custom
  function onChangeHandler() {
    return (e) => {
      setValue(e)
      if (cb !== null && typeof cb === 'function') {
        cb(e)
      }
    }
  }

  const isError = error && touched
  return (
    <>
      <FormControl
        id={id}
        isInvalid={isError}
        onClick={() => setTouched(true)}
        {...rest}
      >
        <DropdownSelect
          chakraProps={{ ...chakraProps }}
          cb={() => setTouched(true)}
          label={label}
          selectProps={{
            main: {
              value: value,
              onChange: onChangeHandler(),
              isDisabled: isDisabled,
              ...main,
              ...formik
            },
            styles: {
              control: {
                background: isError
                  ? `${customColors.red[20]}`
                  : `${customColors.lightGray}`
              }
            },
            ...selectProps
          }}
        />
      </FormControl>
    </>
  )
}
