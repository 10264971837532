import { api } from '../index'
import { friendlyTimeWithTZ } from '../../libs'

export async function getPlayers({
  search = null,
  createdFrom = null,
  createdTo = null,
  bookedFrom = null,
  bookedTo = null,
  experience = null,
  location = null,
  playersCount = null,
  status = null,
  email = null,
  name = null,
  isInBlacklist = null,
  phone = null,
  pageSize = null,
  page = null
}) {
  let prepare = {
    search: search ? search : null,
    fullname: name ? name : null,
    email: email ? email : null,
    phone: phone ? phone : null,
    is_in_blacklist: isInBlacklist ? isInBlacklist : null,
    created_from: createdFrom
      ? friendlyTimeWithTZ(createdFrom, 'YYYY-MM-DD HH:mm:SS')
      : null,
    created_to: createdTo
      ? friendlyTimeWithTZ(createdTo, 'YYYY-MM-DD HH:mm:SS')
      : null,
    booked_from: bookedFrom
      ? friendlyTimeWithTZ(bookedFrom, 'YYYY-MM-DD HH:mm:SS')
      : null,
    booked_to: bookedTo
      ? friendlyTimeWithTZ(bookedTo, 'YYYY-MM-DD HH:mm:SS')
      : null,
    room: experience ? experience : null,
    players_count: playersCount ? playersCount : null,
    location_name: location ? location : null,
    status: status ? status : null,
    page,
    page_size: pageSize
  }
  const final = {}
  Object.keys(prepare).forEach((key) => {
    if (prepare[key]) {
      final[key] = prepare[key]
    }
  })

  let searchStr = search
  if (!search) {
    searchStr = null
  }

  const { data } = await api.get('/api/players/', {
    params: prepare
  })
  return data
}

export async function newPlayers({
  firstname,
  lastname,
  birthdate,
  phone,
  email
}) {
  try {
    const { data } = await api.post('/api/players/', {
      firstname: firstname,
      lastname: lastname,
      birthdate: birthdate,
      phone: phone,
      email: email
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getPlayer({ playerId }) {
  try {
    const { data } = await api.get(`/api/players/${playerId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function toggleBlacklistPlayer({ playerId, isInBlacklist }) {
  try {
    const { data } = await api.patch(`/api/players/${playerId}/`, {
      is_in_blacklist: isInBlacklist
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function addPlayer({
  firstname,
  lastname,
  phone,
  birthdate,
  inBlacklist,
  email
}) {
  const { data } = await api.post('/api/players/', {
    firstname,
    lastname,
    email,
    is_in_blacklist: inBlacklist,
    ...(phone && { phone }),
    ...(birthdate && { birthdate })
  })
  return data
}

export async function addPlayerComment({ playerId, text }) {
  const { data } = await api.post(`/api/players/${playerId}/add_comment/`, {
    text
  })
  return data
}

export async function editPlayer({
  playerId,
  firstname,
  lastname,
  email,
  phone,
  inBlacklist,
  birthdate
}) {
  const { data } = await api.patch(`/api/players/${playerId}/`, {
    firstname,
    email,
    lastname,
    phone,
    birthdate,
    is_in_blacklist: inBlacklist
  })
  return data
}
