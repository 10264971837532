import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'

import ButtonBlue from '../../common/ButtonBlue'
import ButtonGray from '../../common/ButtonGray'
import PencilIcon from '../../common/Icons/PencilIcon'
import { friendlyTime } from '../../../common'

export default function BookingResult({ showStartOverButton }) {
  const history = useHistory()
  const {
    values: {
      loadedBooking = {},
      unassigned,
      bookingWasLoaded,
      itsNewProfile,
      technicalNumber,
      waiverData = {}
    } = {},
    resetForm,
    setFieldValue
  } = useFormikContext()
  const code = loadedBooking?.code
  const unlimitedSigns = loadedBooking?.unlimited_signs
  const experience = loadedBooking?.event_variant
  const players = loadedBooking?.players
  const maxPlayers = loadedBooking?.max_players
  const phone = waiverData?.phone
  const startTime = loadedBooking?.start_time
    ? friendlyTime(loadedBooking?.start_time * 1000, 'MM.DD.YY, h:mm A')
    : ''
  const showPlayers = !unassigned && !unlimitedSigns

  function startOver() {
    setFieldValue('someMinorIsEdit', false)
    setFieldValue('newMinorMode', false)
    setFieldValue('isReplaceProfileMode', false)
    setFieldValue('selectedProfile', null)
    setFieldValue('futurePlayers', null)
    setFieldValue('signaturePhoto', null)
    setFieldValue('signedFor', null)
    if (itsNewProfile) {
      setFieldValue('createMode', true)
      setFieldValue('createdProfile.firstname', '')
      setFieldValue('createdProfile.lastname', '')
      setFieldValue('createdProfile.email', '')
    } else {
      setFieldValue('createMode', false)
    }

    if (technicalNumber) {
      setFieldValue('technicalNumber', false)
      setFieldValue('phoneNumberAccepted', false)
      setFieldValue('phoneNumber', '+1')
      setFieldValue('phoneNumberMasked', '')
      setFieldValue('smsCode', '')
      setFieldValue('createMode', false)
    }
  }

  function changeHandler() {
    history.push('/waiver-widget')
    resetForm()
  }

  return (
    <div className={`waiver-widget--booking-result`}>
      <span>Sign your waivers</span>
      <span>
        Please check that these details are correct: Your Booking
        number/experience etc.
      </span>
      <div className="waiver-widget--not-correct">
        <span>
          Not the correct game details?&nbsp;
          <p>
            Please call <a href={`tel:${phone}`}>{phone}</a>
          </p>
        </span>
      </div>
      {showStartOverButton && (
        <div className="waiver-widget--start-over-container">
          <ButtonBlue onClick={startOver}>Start over</ButtonBlue>
        </div>
      )}
      <div className="waiver-widget--split" />
      <div className="waiver-widget--booking-code-wrapper">
        <button onClick={changeHandler}>
          <PencilIcon />
        </button>
        <span className="waiver-widget--title-text">
          Booking number:&nbsp;<p>#{code}</p>
        </span>
      </div>
      <div className="waiver-widget--booking-info">
        <span>{experience}</span>
        <span>·</span>
        <span>{startTime}</span>
        <span>·</span>
        {showPlayers && (
          <span>
            {players} of {maxPlayers}
          </span>
        )}
      </div>
      <ButtonGray icon={<PencilIcon />} onClick={changeHandler}>
        Edit information
      </ButtonGray>
      <div className="waiver-widget--split" />
    </div>
  )
}
