import { Box, Button, Text } from '@chakra-ui/react'
import EditLocationModal from '../Modals/EditLocationModal'
import DeleteLocationModal from '../Modals/DeleteLocationModal'
import { SETTINGS_WAIVER_URL } from '../../../../../constants'
import React from 'react'
import { useHistory } from 'react-router-dom'

export default function LocationListItem({ location = {}, locationId = '' }) {
  const history = useHistory()
  const { name = '', address = '', timezone = '' } = location
  function redirectHandler(url) {
    return () => {
      history.push(url)
    }
  }

  return (
    <Box
      d="grid"
      gridTemplateColumns="60% 20% 20%"
      bg="white"
      p={{ base: '16px' }}
      mb="2px"
      fontSize={{ base: '12px', '2xl': '14px' }}
    >
      <Text color="custom.gray.900" whiteSpace="wrap">
        {name}
      </Text>
      <Box justifySelf="center">
        <Button
          variant="link"
          textDecor="none"
          onClick={redirectHandler(`${SETTINGS_WAIVER_URL}/${locationId}`)}
        >
          <Text color="custom.blue.600">Waiver</Text>
        </Button>
      </Box>
      <Box justifySelf="end">
        <EditLocationModal locationId={locationId} location={location} />
        <DeleteLocationModal locationId={locationId} locationName={name} />
      </Box>
    </Box>
  )
}
