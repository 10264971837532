import { api } from '../index'

export async function acceptGiftOrPromo({
  preBookings,
  giftcard = null,
  promocode = null
}) {
  const toSend = {
    bookings: preBookings
  }

  if (giftcard !== null) {
    toSend['giftcard'] = giftcard
  }

  if (promocode !== null) {
    toSend['promocode'] = promocode
  }

  const { data } = await api.post('/api/orders/use_promo_and_giftcard/', toSend)
  return data
}

export async function createNewOrder({
  firstname,
  lastname,
  phone,
  email,
  bookings,
  giftcard,
  promocode,
  payment
}) {
  try {
    if (payment.payment_source === 'cash') delete payment.payment_token

    const payload = {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      email: email,
      bookings: bookings,
      ...(giftcard?.length > 0 && { giftcard: giftcard }),
      ...(promocode?.length > 0 && { promocode: promocode }),
      payment: payment
    }

    console.log('Creating new order')
    console.log('Payload:', payload)
    const { data } = await api.post('/api/orders/pay/', payload)
    return data
  } catch (error) {
    console.log('Failed to create new order')
    console.log('Error:', error)
    throw error
  }
}
