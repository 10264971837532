import { useFormikContext } from 'formik'
import { forwardRef, useEffect, useState } from 'react'

import PlaceholderInput from '../PlaceholderInput'

export const InputDatePickerPlaceholder = forwardRef(
  (
    {
      value,
      onClick,
      onChange,
      onFocus,
      setExternalError = null,
      useExternalError = false,
      externalError = false,
      mask,
      onBlur,
      disableErrors = false,
      bg
    },
    ref
  ) => {
    const [error, setError] = useState(false)
    const formik = useFormikContext()
    const setTouched = formik.setFieldTouched
    const touchedHandler = formik?.touched?.createdProfile?.birthdate
    const errorFormikHandler = formik?.errors?.createdProfile?.birthdate
    const errorFormik = touchedHandler && errorFormikHandler
    const errorHandler = useExternalError ? externalError : errorFormik

    function isValidDate(dateString) {
      if (dateString.includes('-')) return true
      if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(dateString)) return false
      const parts = dateString.split('-')
      const day = parseInt(parts[2], 10)
      const month = parseInt(parts[1], 10)
      const year = parseInt(parts[0], 10)

      if (year < 1000 || year > 3000 || month === 0 || month > 12) return false

      const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

      if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29

      return day > 0 && day <= monthLength[month - 1]
    }

    function onChangeHandler(val) {
      if (!touchedHandler && !useExternalError)
        setTouched('createdProfile.birthdate', true)
      onChange(val)
    }

    function onBlurHandler() {
      onBlur()
      if (!touchedHandler && !useExternalError)
        setTouched('createdProfile.birthdate', true)
    }

    useEffect(() => {
      if (
        !isValidDate(value) &&
        !error &&
        (touchedHandler || useExternalError)
      ) {
        setError(true)
        if (
          setExternalError !== null &&
          typeof setExternalError === 'function'
        ) {
          setExternalError(true)
        }
      }

      if (isValidDate(value) && error) {
        setError(false)
        if (
          setExternalError !== null &&
          typeof setExternalError === 'function'
        ) {
          setExternalError(false)
        }
      }
    }, [value])
    return (
      <PlaceholderInput
        placeholder="Birthdate"
        mask={mask}
        // isError={!disableErrors && (error || errorHandler)}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlurHandler}
        value={value}
        onChange={onChangeHandler}
        inputRef={ref}
      />
    )
  }
)
