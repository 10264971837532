import { Box, IconButton, Text } from '@chakra-ui/react'
import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { convertSecondsToAmPm, convertWeekdayInIndex } from '../../../../libs'
import AddLimitationModal from '../../Modals/AddLimitationModal'
import EditLimitationModal from '../../Modals/EditLimitationModal'
import { useDeleteLimitation } from '../../../../libs/hooks/useLimitations'
import { useQueryClient } from 'react-query'
import PermissionRequired from '../../../Common/PermissionRequired'
import { PERMISSION_LIMITATIONS } from '../../../../constants'

export default function LimitationsList({ data = {}, day }) {
  const queryClient = useQueryClient()
  const { mutate: deleteLimitation } = useDeleteLimitation()
  const limitationPermission = PermissionRequired(PERMISSION_LIMITATIONS, true)
  function onDeleteHandler(limitationId) {
    return () => {
      const toSend = {
        limitationId
      }
      deleteLimitation(
        {
          obj: toSend
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'limitations',
              +convertWeekdayInIndex(day)
            ])
          }
        }
      )
    }
  }
  return data?.map((el, idx) => {
    return (
      <Box mb="24px" key={'LimitationsList' + 'idx' + idx}>
        <Box p="16px" bg="white" d="flex" mb="2px" alignItems="center">
          <Text color="custom.black.500" fontSize="18px" fontWeight="500">
            {el.location_name}
          </Text>
          <AddLimitationModal
            location={el.location_name}
            locationId={el.location_id}
            day={day}
          />
        </Box>
        {el?.limitations?.map((limitation, idx) => {
          const {
            id,
            time_start: startTime,
            time_end: endTime,
            summaryRow = false,
            location_id: locationId,
            peoples = ''
          } = limitation
          const strStart = convertSecondsToAmPm(startTime)
          const strEnd = convertSecondsToAmPm(endTime)
          return (
            <Box
              p="16px"
              bg="white"
              d="flex"
              mb="2px"
              key={'LimitationsList' + 'timeSlots' + idx}
            >
              <Text color="custom.gray.900">
                {strStart} - {strEnd}
              </Text>
              <Box ml="auto" d="flex" justifyContent="end" alignItems="center">
                <EditLimitationModal
                  limitationId={limitation.id}
                  day={day}
                  locationId={el.location_id}
                  location={el.location_name}
                  strEnd={strEnd}
                  strStart={strStart}
                />
                <IconButton
                  aria-label="rebook-name"
                  variant="funcColumnIconButton"
                  minW="unset"
                  w="14px"
                  h="14px"
                  _hover={false}
                  _focus={false}
                  color="custom.gray.200"
                  ml="16px"
                  isDisabled={!limitationPermission}
                  onClick={onDeleteHandler(limitation.id)}
                  icon={<CrosshairIcon w="inherit" h="inherit" />}
                />
              </Box>
            </Box>
          )
        })}
      </Box>
    )
  })
}
