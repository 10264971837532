import { api } from '../../index'
/**
 *
 * @returns { Promise<Array<{
 *    id: number,
 *    name: string,
 *    private: string | null,
 *    public: string | null,
 *    additional: string | null,
 *    test_private: string | null,
 *    test_public: string | null,
 *    test_additional: string | null,
 *    is_test_environment: boolean,
 *    fee: number,
 *    sales_tax_fee: number,
 *    events_count: number,
 *    company: number,
 *    is_default: boolean,
 *    account_id: number | null,
 *    payment_provider: 'STRIPE' | 'SQUAREUP',
 *    stripe_connect_fee_percent: number
 *  }>>}
 */
export async function getPaymentGates() {
  const { data } = await api.get('/api/stripe/')
  return data.results
}

export async function getDefaultStripe() {
  try {
    const { data } = await api.get('/api/stripe/?is_default=true')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function addStripe({
  name,
  privateKey,
  publicKey,
  fee,
  payment_provider
}) {
  if (!payment_provider)
    throw new Error(
      'You must provide payment provider name to create Payment Gate'
    )
  const { data } = await api.post('/api/stripe/', {
    name: name,
    private: privateKey,
    public: publicKey,
    fee,
    payment_provider
  })
  return data
}

export const PaymentProviders = {
  stripe: 'STRIPE',
  square: 'SQUAREUP'
}
/**
 *
 * @param {{
 *  paymentGateId: string
 *  paymentGateName: string
 *  privateKey: string
 *  publicKey: string
 *  additionalPrivateKey: string
 *  isDefault: boolean
 *  feePercentInt: number
 *  salesTaxPercent: number
 *  paymentProvider: typeof PaymentProviders[keyof typeof PaymentProviders]
 * }} UpdatePaymentGatePayload
 * @returns
 */
export async function updatePaymentGate({
  paymentGateId,
  paymentGateName,
  privateKey,
  publicKey,
  isDefault,
  feePercentInt = 0,
  salesTaxPercent = 0,
  paymentProvider,
  additionalPrivateKey
}) {
  const payload = {
    name: paymentGateName,
    private: privateKey,
    public: publicKey,
    is_default: isDefault,
    fee: feePercentInt,
    sales_tax_fee: salesTaxPercent,
    payment_provider: paymentProvider,
    additional: additionalPrivateKey
  }

  const { data } = await api.patch(`/api/stripe/${paymentGateId}/`, payload)
  return data
}

export async function deleteStripe({ stripeId }) {
  try {
    const { data } = await api.delete(`/api/stripe/${stripeId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getCompany(companyId) {
  try {
    const { data } = await api.get(`/api/companys/${companyId}/`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function updateCompany({
  companyId,
  mailchimpKey,
  mailchimpList,
  mailchimpUsername,
  name,
  notifEmail,
  sendgrid,
  twilioPhone,
  twilioToken,
  twilioSeed,
  minGiftcardValue,
  maxGiftcardValue,
  sendgridContactList,
  minimalAge
}) {
  try {
    const { data } = await api.patch(`/api/companys/${companyId}/`, {
      mailchimp_key: mailchimpKey,
      mailchimp_list_id: mailchimpList,
      mailchimp_username: mailchimpUsername,
      name,
      notif_email: notifEmail,
      sendgrid,
      twillio_phone: twilioPhone,
      twillio_sid: twilioSeed,
      twillio_token: twilioToken,
      sendgrid_contact_list: sendgridContactList,
      min_giftcard_value: minGiftcardValue,
      max_giftcard_value: maxGiftcardValue,
      minimal_age: minimalAge
    })
    return data
  } catch (e) {
    console.log(e)
  }
}
