import {
  Box,
  Button,
  GridItem,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import ButtonsToggle from '@/components/Common/ButtonsToggle/index'
import { Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PlusIcon } from '../../../../../../assets/Icons/PlusIcon'
import { useCreateStripe } from '../../../../../../libs/hooks/useStripe'
import { newStripeModal } from '../../../../../../configs/validation/newStripeModal'
import { matchMedia768InState } from '../../../../../../constants'
import FormikTwoStyledInput from '../../../../../Common/FormikComponents/FormikTwoStyledInput'

export default function AddPaymentGatesModal() {
  const queryClient = useQueryClient()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: newStripe } = useCreateStripe()

  function submitHandler(values) {
    const toSend = values
    newStripe(
      { obj: toSend },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['stripe'])
          onClose()
        }
      }
    )
  }

  return (
    <>
      {isLargerThan768 ? (
        <Button
          variant="blue"
          ml="auto"
          onClick={onOpen}
          fontSize={{ base: '12px', '2xl': '14px' }}
        >
          <PlusIcon mr="8px" />
          New payment
        </Button>
      ) : (
        <IconButton
          aria-label="editAccount"
          variant="funcColumnIconButtons"
          ml="auto"
          icon={<PlusIcon w="inherit" h="inherit" />}
          onClick={onOpen}
          color="custom.gray.200"
        />
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
        >
          <Formik
            initialValues={{
              name: '',
              publicKey: '',
              privateKey: '',
              payment_provider: 'STRIPE',
              fee: 0
            }}
            onSubmit={submitHandler}
            validationSchema={newStripeModal}
          >
            {({
              isSubmitting,
              values,
              handleSubmit,
              setFieldValue,
              isValid,
              errors,
              handleChange
            }) => {
              return (
                <>
                  <ModalHeader mb="34px" d="flex">
                    Add payment gates
                    <Box
                      ml="auto"
                      d={{ base: 'none', md: 'grid' }}
                      gridTemplateColumns="1fr 1fr"
                      gridColumnGap="8px"
                      fontSize={{ base: '12px' }}
                    >
                      <Button
                        w="100%"
                        variant="blue"
                        fontSize="inherit"
                        onClick={handleSubmit}
                      >
                        <DisketteIcon mr="8px" />
                        Save
                      </Button>
                      <Button
                        w="100%"
                        variant="red"
                        fontSize="inherit"
                        onClick={onClose}
                      >
                        <CrosshairIcon mr="8px" />
                        Cancel
                      </Button>
                    </Box>
                  </ModalHeader>
                  <ModalCloseButton d={{ md: 'none' }} />
                  <ModalBody
                    fontSize={{ base: '12px' }}
                    d={{ base: 'block', md: 'grid' }}
                    gridTemplateColumns={{ md: '1fr 1fr' }}
                    gridColumnGap={{ md: '16px' }}
                    gridRowGap={{ md: '16px' }}
                  >
                    <GridItem colSpan={2}>
                      <ButtonsToggle
                        data={['STRIPE', 'SQUAREUP']}
                        useExternalControl={true}
                        initialState={values.payment_provider}
                        cb={(data) => {
                          setFieldValue('payment_provider', data)
                        }}
                      />
                    </GridItem>
                    <FormikTwoStyledInput
                      id="name"
                      name="name"
                      placeholder="name"
                    />
                    <FormikTwoStyledInput
                      id="fee"
                      name="fee"
                      placeholder="Fee, %"
                    />
                    <FormikTwoStyledInput
                      id="publicKey"
                      name="publicKey"
                      placeholder="Public key"
                    />
                    <FormikTwoStyledInput
                      id="privateKey"
                      name="privateKey"
                      placeholder="Private key"
                    />
                    {values.payment_provider === 'SQUAREUP' && (
                      <FormikTwoStyledInput
                        id="additional"
                        name="additional"
                        placeholder="Application ID"
                        mb="16px"
                      />
                    )}
                  </ModalBody>

                  <ModalFooter
                    d={{ base: 'grid', md: 'none' }}
                    gridTemplateColumns="1fr 1fr"
                    gridColumnGap="8px"
                  >
                    <Button w="100%" variant="blue" onClick={handleSubmit}>
                      <DisketteIcon mr="8px" />
                      Save
                    </Button>
                    <Button w="100%" variant="red" onClick={onClose}>
                      <CrosshairIcon mr="8px" />
                      Cancel
                    </Button>
                  </ModalFooter>
                </>
              )
            }}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}
