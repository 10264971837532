import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { authTokenInState } from '../../constants'

const PrivateRoute = ({ children, ...rest }) => {
  const authToken = useSelector(authTokenInState)

  return (
    <Route
      {...rest}
      render={() => {
        if (authToken === '') {
          return <Redirect to="/login" />
        } else {
          return children
        }
      }}
    />
  )
}

export default PrivateRoute
