import { Box, Button, Text } from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useSetNewPassword } from '../../libs/hooks/resetPassword'
import CustomChakraInput from '@/components/Common/CustomChakraInput'
import { newPasswordValidation } from '../../configs/validation/newPasswordValidation'
import { LOGIN_URL } from '../../constants'

export default function NewPassword() {
  const history = useHistory()
  const { mutate: setNewPassword } = useSetNewPassword()
  const { search } = useLocation()
  const replacedSearch = search.replace('?', '')
  const spliced = replacedSearch.split('&')
  const obj = useMemo(() => {
    const obj = {}
    if (spliced.length !== 0) {
      spliced.forEach((str) => {
        const [key, value] = str.split('=')
        obj[key] = value
      })
    }

    return obj
  }, [])

  function goTo() {
    return () => history.push(LOGIN_URL)
  }

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      repeatPassword: '',
      token: obj?.token,
      email: obj?.email
    },
    validationSchema: newPasswordValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      setNewPassword(
        {
          obj: {
            email: values.email,
            password: values.newPassword,
            token: values.token
          }
        },
        {
          onSuccess: () => goTo()()
        }
      )
    }
  })
  const formikHandleSubmit = formik.handleSubmit
  return (
    <FormikProvider value={formik}>
      <Box d="flex" alignItems="center" justifyContent="center" height="100%">
        <Box
          d="flex"
          flexDir="column"
          bg="white"
          p={{ base: '24px 16px', md: '24px' }}
          boxShadow="purple"
          w={{ base: '288px', md: '350px', '2xl': '427px' }}
        >
          <Text
            as="h1"
            textTransform="uppercase"
            color="custom.blue.600"
            fontSize={{ base: '32px' }}
            lineHeight="150%"
            fontWeight="700"
            textAlign="center"
            mb={{ base: '24px' }}
          >
            Gbase
          </Text>
          <CustomChakraInput
            name="newPassword"
            label="New Password"
            placeholder="New Password"
            fontSize={{ base: '14px' }}
            mb={{ base: '16px' }}
            type="password"
            height={{ base: '56px' }}
          />
          <CustomChakraInput
            name="repeatPassword"
            label="Repeat Password"
            placeholder="Repeat Password"
            fontSize={{ base: '14px' }}
            type="password"
            mb={{ base: '16px' }}
            height={{ base: '56px' }}
          />
          <Box d="grid" gridTemplateColumns="1fr 1fr" gridGap="16px">
            <Button
              variant="lightBlue"
              type="submit"
              fontSize={{ base: '14px' }}
              fontWeight="500"
              height={{ base: '56px' }}
              w="100%"
              transition="all 0.2s ease"
              onClick={goTo()}
            >
              Back
            </Button>
            <Button
              variant="blue"
              type="submit"
              fontSize={{ base: '14px' }}
              fontWeight="500"
              height={{ base: '56px' }}
              w="100%"
              transition="all 0.2s ease"
              onClick={formikHandleSubmit}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </FormikProvider>
  )
}
