import { createIcon } from '@chakra-ui/icons'

export const PencilIcon = createIcon({
  displayName: 'PencilIcon',
  viewBox: '0 0 14 14',
  boxSize: '14',
  path: (
    <g fill="currentColor">
      <path d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z" />
    </g>
  )
})
