import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import PicturesContent from './PicturesContent'

export default function Pictures({ accordion = true, ...rest }) {
  if (accordion) {
    return (
      <Accordion
        allowToggle
        defaultIndex={[0]}
        bg="rgba(255,255,255,1)"
        borderStyle="hidden"
        p="16px 16px 12px"
        fontFamily="inherit"
        mb="16px"
        {...rest}
      >
        <AccordionItem borderStyle="hidden">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  p="2px 2px 2px 0"
                  fontWeight="500"
                  fontSize={{ base: '18px', '2xl': '24px' }}
                  height="32px"
                  mb={{ base: '8px', '2xl': '16px' }}
                  _hover={{ bg: 'white' }}
                  _focus={{ border: 'none' }}
                  _active={{ bg: 'white' }}
                >
                  <Box flex="1" textAlign="left">
                    Pictures:
                  </Box>

                  <ArrowIcon
                    w="auto"
                    height="16px"
                    transition="all 0.2s ease-in"
                    transform={isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'}
                    color="custom.blue.600"
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel p="0">
                <PicturesContent />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    )
  } else {
    return <PicturesContent />
  }
}
