import { Box, IconButton, Text } from '@chakra-ui/react'
import { CopyIconWithoutBg } from '../../../../assets/Icons/CopyIconWithoutBg'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'

export default function Payment({
  id,
  type,
  total,
  identifier,
  user,
  fee,
  last,
  cardholder
}) {
  return (
    <Box w="100%" bg="white" p="16px" mb="2px">
      <Box w="100%" fontSize="12px">
        <Box
          fontSize="inherit"
          d="flex"
          flexWrap="wrap"
          alignItems="center"
          mb="4px"
        >
          <Box d="flex" mr="16px">
            <Text
              fontSize="inherit"
              fontWeight="500"
              lineHeight="200%"
              color="custom.black.500"
              mr="8px"
            >
              ID:
            </Text>
            <Text
              fontSize="inherit"
              fontWeight="500"
              lineHeight="200%"
              color="custom.black.500"
            >
              {id}
            </Text>
          </Box>
          <Box d="flex" mr="16px">
            <Text
              fontSize="inherit"
              color="custom.gray.300"
              lineHeight="200%"
              mr="8px"
            >
              Type:
            </Text>
            <Text color="custom.gray.900" lineHeight="200%">
              {type}
            </Text>
          </Box>
          <Box d="flex" mr="16px">
            <Text
              fontSize="inherit"
              color="custom.gray.300"
              lineHeight="200%"
              mr="8px"
            >
              Total:
            </Text>
            <Text color="custom.gray.900" lineHeight="200%">
              {total}
            </Text>
          </Box>
          <Box d="flex" mr="16px">
            <Text
              fontSize="inherit"
              color="custom.gray.300"
              lineHeight="200%"
              mr="8px"
            >
              Fee:
            </Text>
            <Text color="custom.gray.900" lineHeight="200%">
              {fee}
            </Text>
          </Box>
          {!!last && (
            <Box d="flex" mr="16px">
              <Text
                fontSize="inherit"
                color="custom.gray.300"
                lineHeight="200%"
                mr="8px"
              >
                Last 4:
              </Text>
              <Text color="custom.gray.900" lineHeight="200%">
                {last}
              </Text>
            </Box>
          )}
          {!!cardholder && (
            <Box d="flex" mr="16px">
              <Text
                fontSize="inherit"
                color="custom.gray.300"
                lineHeight="200%"
                mr="8px"
              >
                Cardholder:
              </Text>
              <Text color="custom.gray.900" lineHeight="200%">
                {cardholder}
              </Text>
            </Box>
          )}
        </Box>
        <Box d="flex" alignItems="center" mb="4px">
          <Text
            fontSize="inherit"
            color="custom.gray.300"
            lineHeight="200%"
            mr="8px"
          >
            Identifier:
          </Text>
          <CopyToClipboard text={identifier}>
            <IconButton
              aria-label="copy-link"
              bg="none"
              w="14px"
              height="14px"
              minW="unset"
              minH="unset"
              mr="8px"
              p="0"
              _focus={{ border: 'none' }}
              _hover={{ bg: 'transparent' }}
              icon={
                <CopyIconWithoutBg
                  w="inherit"
                  height="inherit"
                  color="custom.gray.200"
                />
              }
            />
          </CopyToClipboard>
          <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {identifier}
          </Text>
        </Box>
        <Box d="flex" alignItems="center" mb="4px">
          <Text
            fontSize="inherit"
            color="custom.gray.300"
            mr="8px"
            lineHeight="200%"
          >
            User:
          </Text>
          <CopyToClipboard text={user}>
            <IconButton
              aria-label="copy-link"
              bg="none"
              w="14px"
              height="14px"
              minW="unset"
              minH="unset"
              mr="8px"
              p="0"
              _focus={{ border: 'none' }}
              _hover={{ bg: 'transparent' }}
              icon={
                <CopyIconWithoutBg
                  w="inherit"
                  height="inherit"
                  color="custom.gray.200"
                />
              }
            />
          </CopyToClipboard>
          <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {user}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
