import { Box, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { useCompany } from '@/libs/hooks/useCompany'
import { usePaymentGates } from '@/libs/hooks/useStripe'

import AccountField from '@/components/Common/AccountField'

import { matchMedia768InState, userInState } from '@/constants'

import AddPaymentGatesModal from './Modals/AddPaymentGatesModal'
import EditAccountModal from './Modals/EditAccountModal'
import PaymentGatesList from './PaymentGatesList'
import PaymentsGatesTable from './PaymentsGatesTable'

export default function Account() {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const user = useSelector(userInState)

  const { data: paymentGates } = usePaymentGates()

  const companyId = user?.company
  const { data: company = {} } = useCompany(companyId, {
    enabled: !!companyId
  })
  const name = company.name
  const sendgrid = company?.sendgrid || '—'
  const notifEmail = company?.notif_email || '—'
  const twilioSeed = company?.twillio_sid || '—'
  const twilioToken = company?.twillio_token || '—'
  const twilioPhone = company?.twillio_phone || '—'
  const minGiftcardValue = company?.min_giftcard_value || '—'
  const maxGiftcardValue = company?.max_giftcard_value || '—'
  const sendgridContactList = company?.sendgrid_contact_list || '—'
  const minimalAge = company?.minimal_age || '—'

  return (
    <>
      <Box
        p={{ base: '16px', '2xl': '24px' }}
        bg="white"
        d="flex"
        alignItems="center"
        mb="2px"
      >
        <Text
          color="custom.black.500"
          fontSize={{ base: '18px', '2xl': '24px' }}
          fontWeight="500"
        >
          Account
        </Text>
        <EditAccountModal />
      </Box>
      <Box
        p={{ base: '16px' }}
        bg="white"
        mb={{ base: '16px', md: '24px', '2xl': '32px' }}
        d={{ base: 'block', md: 'grid' }}
        gridTemplateColumns={{ base: 'unset', md: 'repeat(3, 1fr)' }}
        gridGap={{ base: '16px' }}
        fontSize={{ base: '12px', '2xl': '14px' }}
      >
        <AccountField value={name} placeholder="Name" />
        <AccountField value={sendgrid} placeholder="Sendgrid" />
        <AccountField
          value={sendgridContactList}
          placeholder="Sendgrid Contact List"
        />
        <AccountField value={twilioSeed} placeholder="Twillio seed" />
        <AccountField value={twilioToken} placeholder="Twillio token" />
        <AccountField value={twilioPhone} placeholder="Twillio phone" />
        <AccountField value={notifEmail} placeholder="Notification e-mail" />
        <AccountField
          value={minGiftcardValue}
          placeholder="Min Giftcard value, $"
        />
        <AccountField
          value={maxGiftcardValue}
          placeholder="Max Giftcard value, $"
        />
        <AccountField value={minimalAge} placeholder="Minimal Age" />
      </Box>
      <Box
        p={{ base: '16px', '2xl': '24px' }}
        mb="2px"
        bg="white"
        d="flex"
        alignItems="center"
      >
        <Text
          color="custom.black.500"
          fontSize={{ base: '18px', '2xl': '24px' }}
          fontWeight="500"
        >
          Payment gates
        </Text>
        <AddPaymentGatesModal />
      </Box>
      {isLargerThan768 ? (
        <PaymentsGatesTable tableData={paymentGates} />
      ) : (
        <PaymentGatesList data={paymentGates} />
      )}
    </>
  )
}
