import { useFormikContext } from 'formik'
import LogRocket from 'logrocket'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import ButtonBlue from '../../common/ButtonBlue'
import FormikInput from '../../common/FormikComponents/FormikInput'
import ArrowRight from '../../common/Icons/ArrowRight'
import { useNotification } from '../../common/NotificationService'
import { getWaiverWidgetByBooking } from '../../../api'

export default function EnterBookingNumber() {
  const buttonRef = useRef(null)
  const timerRef = useRef(null)
  const toast = useNotification()
  const [isLoading, setIsLoading] = useState(false)
  const { values, setFieldValue } = useFormikContext()
  const { search, hash } = useLocation()

  const {
    bookingWasLoaded,
    loadedBooking,
    bookingRefetchFunc,
    bookingRefetchingTimerIsRun
  } = values

  const bookingCode = values?.bookingCode?.replaceAll('#', '')

  async function refetch(loadedBooking) {
    try {
      const waiver = getWaiverWidgetByBooking(bookingCode?.toUpperCase())
      onSuccessLoad(waiver, loadedBooking)
    } catch (error) {
      const msg = error?.response?.data?.msg
      toast({
        message: msg
      })
    }
  }

  useEffect(() => {
    if (bookingRefetchFunc === null && JSON.stringify(loadedBooking) !== '{}') {
      setFieldValue('bookingRefetchFunc', refetch)
    }
  }, [bookingRefetchFunc, loadedBooking])

  useEffect(() => {
    if (timerRef.current !== null && !bookingRefetchingTimerIsRun) {
      clearInterval(timerRef.current)
      timerRef.current = null
    }
  }, [bookingRefetchingTimerIsRun])

  function onSuccessLoad(data, loadedBooking) {
    const keys = Object.keys(data)
    const somethingChange = keys.some((key) => {
      return data[key] !== loadedBooking[key]
    })
    if (!bookingWasLoaded) {
      setFieldValue('bookingRefetchingTimerIsRun', true)
      setFieldValue('bookingWasLoaded', true)
    }
    if (somethingChange) {
      setFieldValue('loadedBooking', data)
    }
  }

  async function onClickNext() {
    setIsLoading(true)
    try {
      const waiver = await getWaiverWidgetByBooking(bookingCode?.toUpperCase())
      onSuccessLoad(waiver, loadedBooking)
      setIsLoading(false)
      localStorage.setItem('bookingCode', bookingCode)
      LogRocket.identify(bookingCode)
    } catch (error) {
      const message = error?.response?.data?.msg
      const msgObjects = error?.response?.data
      setIsLoading(false)
      toast({
        title: 'Error',
        message,
        msgObjects
      })
    }
  }

  useEffect(() => {
    const searchSplit = search.split('=')
    if (
      (search === '?booking=' ||
        (searchSplit[0] === '?booking' && !bookingWasLoaded)) &&
      (hash !== '' || (searchSplit.length > 1 && searchSplit[1] !== '')) &&
      buttonRef !== null
    ) {
      if (hash) {
        const bookingCode = hash
        setFieldValue('bookingCode', bookingCode)
        localStorage.setItem('bookingCode', bookingCode)
        LogRocket.identify(bookingCode)
      }
      if (searchSplit.length > 1 && searchSplit[1] !== '') {
        const bookingCode = searchSplit[1]
        setFieldValue('bookingCode', bookingCode)
        localStorage.setItem('bookingCode', bookingCode)
        LogRocket.identify(bookingCode)
      }

      setTimeout(() => buttonRef.current.click(), 0)
    }
  }, [hash, search, buttonRef, bookingWasLoaded])

  return (
    <div
      className={`waiver-widget--enter-booking-number ${
        bookingWasLoaded ? 'hide' : 'show'
      }`}
    >
      <span>Hi there!</span>
      <span>
        We are excited to host your game!
        <br />
        <i>Please enter the booking number</i>&nbsp; you received in your
        confirmation email and text message.
      </span>
      <FormikInput name="bookingCode" placeholder="Booking code" />
      <ButtonBlue
        onClick={onClickNext}
        btnRef={buttonRef}
        icon={<ArrowRight />}
        isLoading={isLoading}
      >
        Next
      </ButtonBlue>
    </div>
  )
}
