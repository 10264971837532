import { useField, useFormikContext } from 'formik'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import { useState } from 'react'
import { ErrorIcon } from '../../../assets/Icons/ErrorIcon'

export default function CustomChakraInput({
  label,
  name,
  id,
  type = 'text',
  ...rest
}) {
  const { isSubmitting } = useFormikContext()
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  const { placeholder } = rest
  const [wasTouched, setWasTouched] = useState(false)

  function onClickHandler() {
    if (!wasTouched) {
      setWasTouched(true)
    }
  }

  function onBlurHandler(e) {
    setValue(e.target.value.trim())
    setTouched(true)
  }

  return (
    <FormControl id={id} isInvalid={error && touched}>
      <FormLabel
        fontWeight="400"
        fontSize={{ base: '10px' }}
        color="custom.gray.300"
        textTransform="capitalize"
        pos="absolute"
        lineHeight="160%"
        left="16px"
        top="8px"
        d={wasTouched ? 'inline-flex' : 'none'}
        alignItems="center"
        zIndex={1}
      >
        {label}&nbsp;
        <FormErrorMessage fontSize={{ base: '10px' }} lineHeight="160%" m="0">
          -&nbsp;{error}
        </FormErrorMessage>
      </FormLabel>
      <InputGroup>
        <Input
          {...field}
          {...rest}
          type={type}
          variant="gbase"
          placeholder={wasTouched ? '' : placeholder}
          p={wasTouched ? '24px 16px 8px' : '0 16px'}
          onBlur={onBlurHandler}
          onClick={onClickHandler}
        />
        {error && touched && (
          <InputRightElement height="56px">
            <ErrorIcon color="custom.red.650" />
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  )
}
