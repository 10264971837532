import { Box, Text, useTheme } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { matchMedia1440InState } from '../../../../../constants'
import Preloader from '../../../../Common/Preloader'

export default function TableHeaderCalendar({
  columns = [],
  locationLabel = '',
  isLoading = false,
  ...rest
}) {
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const onlyDigits = /\d\d/
  const theme = useTheme()
  const customsColor = theme.colors.custom
  return (
    <Box {...rest}>
      <Box
        p="0 24px"
        d="flex"
        alignItems="center"
        bg="white"
        pos="relative"
        _before={{
          content: `""`,
          position: 'absolute',
          height: '50%',
          right: 0,
          bottom: 0,
          width: '0.5px',
          background: customsColor.lightGray
        }}
        _after={{
          content: `""`,
          position: 'absolute',
          height: '50%',
          left: 0,
          bottom: 0,
          width: '0.5px',
          background: customsColor.lightGray
        }}
      >
        {isLoading && (
          <Preloader
            w="100%"
            h="100%"
            cyclic
            m="0 auto"
            p="5px 5px"
            pos="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            opacity="0.4"
          />
        )}
        <Text
          fontSize="14px"
          fontWeight="500"
          color="custom.blue.600"
          zIndex="1"
        >
          {locationLabel}
        </Text>
      </Box>
      {columns.length !== 0 &&
        columns?.map((value, idx) => {
          const [digits] = value.header.match(onlyDigits) || [null]
          return (
            <Box
              key={'LimitationsTableHeader' + idx}
              d="flex"
              justifyContent="center"
              alignItems="center"
              pos="relative"
              bg="white"
              fontSize="12px"
              color="custom.gray.300"
              _before={{
                content: `""`,
                position: 'absolute',
                height: '50%',
                right: 0,
                bottom: 0,
                width: '0.5px',
                background: customsColor.lightGray
              }}
              _after={{
                content: `""`,
                position: 'absolute',
                height: '50%',
                left: 0,
                bottom: 0,
                width: '0.5px',
                background: customsColor.lightGray
              }}
            >
              {isLargerThan1440 ? value.header : digits}
            </Box>
          )
        })}
    </Box>
  )
}
