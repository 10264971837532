import { Box, IconButton, Text, useTheme } from '@chakra-ui/react'
import { CrosshairIcon } from '../../../assets/Icons/CrosshairIcon'
import { PlusIcon } from '../../../assets/Icons/PlusIcon'
import { useEventVariants } from '../../../libs/hooks/useEventVariants'
import { useFormikContext } from 'formik'

export default function ApplyExperienceList({ keyName = '' }) {
  const formik = useFormikContext()
  const theme = useTheme()
  const customColors = theme.colors.custom
  const formikValues = formik.values
  const setFieldValue = formik.setFieldValue
  const setFieldTouched = formik.setFieldTouched
  const { data: { results: eventVariants = [] } = {} } = useEventVariants()
  const applyToErrors = formik.errors.hasOwnProperty('applyTo')
  const applyToTouched = formik.touched.hasOwnProperty('applyTo')
  const applyToError = applyToErrors && applyToTouched
  function includeIds(id) {
    return formikValues.applyTo?.includes(id)
  }

  function toggleEventVariant(id) {
    return () => {
      if (!applyToTouched) {
        setFieldTouched('applyTo', true)
      }
      const applyToEventvariant = formikValues.applyTo
      if (applyToEventvariant.includes(id)) {
        const newArr = []
        for (let i = 0; i < applyToEventvariant.length; i++) {
          if (applyToEventvariant[i] !== id) {
            newArr.push(applyToEventvariant[i])
          }
        }
        setFieldValue('applyTo', newArr)
      } else {
        setFieldValue('applyTo', [...applyToEventvariant, id])
      }
    }
  }
  return (
    <Box>
      <Text
        fontSize={{ base: '14px', '2xl': '18px' }}
        mb={{ base: '8px', md: '16px' }}
        fontWeight="500"
      >
        Apply to
      </Text>
      <Box d="flex" flexDir={{ base: 'column', md: 'row' }} flexWrap="wrap">
        {eventVariants?.map((eventVariant, idx) => {
          const name = eventVariant?.name
          const id = eventVariant?.id
          return (
            <Box
              key={'eventVariantsArr' + idx + id + keyName}
              bg={
                applyToError
                  ? 'custom.red.20'
                  : includeIds(id)
                  ? 'custom.blue.600'
                  : 'custom.lightGray'
              }
              color={includeIds(id) ? 'white' : 'custom.blue.600'}
              borderRadius="20px"
              d="grid"
              gridTemplateColumns="3fr 1fr"
              p="0 14px"
              h={{ base: '50px', md: '40px' }}
              border={
                !includeIds(id)
                  ? `1px solid ${customColors.borderBlue}`
                  : `1px solid ${customColors.blue[600]}`
              }
              mb={{ base: '8px', '2xl': '16px' }}
              mr={{ base: 'unset', md: '8px', '2xl': '16px' }}
              alignItems="center"
            >
              <Text
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {name}
              </Text>
              <IconButton
                aria-label="delete-applied"
                variant="funcColumnIconButton"
                w="25px"
                h="25px"
                _focus={false}
                onClick={toggleEventVariant(id)}
                icon={
                  includeIds(id) ? (
                    <CrosshairIcon w="12px" h="12px" />
                  ) : (
                    <PlusIcon w="12px" h="12px" />
                  )
                }
                justifySelf="end"
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
