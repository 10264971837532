export default function Preloader() {
  return (
    <div className="preloader">
      <div className="preloader-container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
