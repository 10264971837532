import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import { useNotification } from '../../common/NotificationService'

export default function ScrollHelper() {
  const formik = useFormikContext()
  const isValidating = formik.isValidating
  const isSubmitting = formik.isSubmitting
  const formikValues = formik.values
  const someMinorIsEdit = formikValues.someMinorIsEdit
  const errors = formik.errors
  const selectedProfileError = formik.errors?.selectedProfile
  const toast = useNotification()

  useEffect(() => {
    if (
      !isValidating &&
      isSubmitting &&
      formik.errors?.hasOwnProperty('selectedProfile')
    ) {
      const id = Object.keys(selectedProfileError)[0]
      if (id !== 'birthdate') {
        console.log('here 1')
        const element = document.getElementById(id)
        if (element) {
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }

        setTimeout(() => element?.focus(), 800)
        return
      }
    }

    if (!isValidating && isSubmitting && someMinorIsEdit) {
      const element = document.querySelector('#edit-minor')
      console.log('here 2')
      if (element) {
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }

      toast({
        message: 'Please, save minor.',
        title: 'Error'
      })
      return
    }

    if (
      !isValidating &&
      isSubmitting &&
      errors?.hasOwnProperty('emptyMinors')
    ) {
      const element = document.querySelector('#minors-block')
      console.log('here 3')
      if (element) {
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }

      return
    }

    if (
      !isValidating &&
      isSubmitting &&
      errors?.hasOwnProperty('newMinorMode')
    ) {
      const minorError = errors?.hasOwnProperty('newMinorMode')
      if (minorError && formik.values.newMinorMode) {
        console.log('minor new error')
        const element = document.querySelector('#new-minor')
        if (element) {
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
        return
      }
    }

    if (
      !isValidating &&
      isSubmitting &&
      errors?.hasOwnProperty('requiredCheckboxes')
    ) {
      const errorsCheckboxesKeys = errors?.hasOwnProperty('requiredCheckboxes')
        ? Object?.keys(errors?.requiredCheckboxes)
        : []
      if (errorsCheckboxesKeys?.length !== 0) {
        console.log('here 4')
        const id = errorsCheckboxesKeys[0]
        const element = document.getElementById(id)
        if (element) {
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    }
  }, [
    isValidating,
    isSubmitting,
    errors?.requiredCheckboxes,
    errors?.newMinorMode,
    errors?.emptyMinors,
    selectedProfileError,
    someMinorIsEdit
  ])

  return null
}
