import { Box, useDisclosure } from '@chakra-ui/react'
import { useRef } from 'react'

import NewPrivateGamePricingItem from './NewPrivateGamePricingItem'
import PrivateGamePricingAccordion from './PrivateGamePricingAccrodion'
import PrivateGamePricingElementHeader from './PrivateGamePricingElementHeader'
import PrivateGamePricingItem from './PrivateGamePricingItem'
import { PERMISSION_EVENT } from '../../../../../../../constants'
import PermissionRequired from '../../../../../../Common/PermissionRequired'

export default function PrivateGamePricingList({
  expanded = false,
  timeLetter = '',
  eventVariantId = '',
  minPlayersLimitation = null,
  maxPlayersLimitation = null,
  slots = []
}) {
  const data = slots
  console.log(data)
  const {
    isOpen: isAddNewOpen,
    onOpen: onAddNewOpen,
    onClose: onAddNewClose
  } = useDisclosure()
  const basePricePermission = PermissionRequired(PERMISSION_EVENT, true)
  const accordionRef = useRef(null)

  function onClickPlusButton(isExpanded) {
    return (e) => {
      if (!isExpanded) {
        accordionRef.current.click()
      }
      e.stopPropagation()
      onAddNewOpen()
    }
  }

  const isEmptyAccordion = data.length === 0 && !isAddNewOpen

  return (
    <PrivateGamePricingAccordion
      defaultIndex={expanded ? [0] : null}
      isDisabled={isEmptyAccordion}
      needProps={true}
      styles={{
        button: {
          ref: accordionRef
        }
      }}
    >
      <PrivateGamePricingElementHeader
        basePricePermission={basePricePermission}
        onClickPlusButton={onClickPlusButton}
      />
      <Box>
        {isAddNewOpen && (
          <NewPrivateGamePricingItem
            onClose={onAddNewClose}
            timeLetter={timeLetter}
            minPlayersLimitation={minPlayersLimitation}
            maxPlayersLimitation={maxPlayersLimitation}
            eventVariantId={eventVariantId}
          />
        )}
        {data.length === 0 && !isAddNewOpen && (
          <Box
            h="40px"
            d="flex"
            alignItems="center"
            fontWeight="500"
            color="custom.gray.300"
            justifyContent="center"
          >
            No slots
          </Box>
        )}
        {data
          ?.sort((a, b) => a.min_players - b.min_players)
          .map((el, idx) => (
            <PrivateGamePricingItem
              key={'BasePricingList' + idx}
              minPlayersLimitation={minPlayersLimitation}
              maxPlayersLimitation={maxPlayersLimitation}
              minPlayers={el.min_players}
              maxPlayers={el.max_players}
              price={el.price}
              timeLetter={timeLetter}
              eventVariantId={eventVariantId}
              priceId={el.id}
            />
          ))}
      </Box>
    </PrivateGamePricingAccordion>
  )
}
