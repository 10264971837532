import { api } from '../../index'

export async function getDiscounts() {
  try {
    const { data } = await api.get('/api/discounts/')
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function patchDiscount({
  discountId,
  effective,
  discountType,
  amount,
  amountType,
  includeHolidays,
  isAdjourned,
  daysToActivate,
  eventvariants = null
}) {
  const prepare = {
    effective: effective,
    discount_type: discountType,
    amount: amount,
    amount_type: amountType,
    include_holidays: includeHolidays,
    is_adjourned: isAdjourned,
    ...(discountType === 'A' && {
      days_to_activate: isAdjourned ? daysToActivate : null
    }),
    ...(discountType === 'A' && { is_adjourned: isAdjourned }),
    days_to_activate: isAdjourned ? daysToActivate : null,
    event_variants: eventvariants
  }

  const toSend = {}
  Object.keys(prepare).forEach((key) => {
    if (key === 'days_to_activate' && prepare['is_adjourned'] === false) {
      toSend[key] = prepare[key]
    }
    if (Array.isArray(prepare[key])) {
      toSend[key] = prepare[key]
    }
    if (
      (!!prepare[key] && !Array.isArray(prepare[key])) ||
      typeof prepare[key] === 'boolean' ||
      key === 'days_to_activate'
    ) {
      toSend[key] = prepare[key]
    }
  })

  const { data } = await api.patch(`/api/discounts/${discountId}/`, toSend)
  return data
}

export async function createDiscount({
  discountId,
  effective,
  discountType,
  amount,
  amountType,
  includeHolidays,
  isAdjourned,
  daysToActivate,
  eventvariants
}) {
  const toSend = {
    effective: effective,
    discount_type: discountType,
    amount: amount,
    amount_type: amountType,
    include_holidays: includeHolidays,
    is_adjourned: isAdjourned,
    days_to_activate: daysToActivate,
    event_variants: eventvariants
  }
  try {
    const { data } = await api.post('/api/discounts/', toSend)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function deleteDiscount(discountId) {
  try {
    const { data } = await api.delete(`/api/discounts/${discountId}`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function getDiscountsAssigned() {
  try {
    const { data } = await api.get('/api/discounts-assigned/')
    return data
  } catch (e) {
    console.log(e)
  }
}
