import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { loadImageToCloud } from '../../../../../../api/booking'
import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import { PlusIcon } from '../../../../../../assets/Icons/PlusIcon'
import { convertByteToMb, generateWarningToast } from '../../../../../../libs'
import { useCities } from '../../../../../../libs/hooks/useCities'
import { useCreateLocation } from '../../../../../../libs/hooks/useLocations'
import {
  MAXIMUM_IMAGE_SIZE,
  MAXIMUM_IMAGES_COUNT
} from '../../../../../../configs'
import { addLocationValidation } from '../../../../../../configs/validation/addLocationModal'
import {
  matchMedia768InState,
  matchMedia1440InState,
  PERMISSION_LOCATIONS
} from '../../../../../../constants'
import CustomWarningToast from '../../../../../Common/CustomWarningToast'
import FormikDropdown from '../../../../../Common/FormikComponents/FormikDropdown'
import FormikTwoStyledInput from '../../../../../Common/FormikComponents/FormikTwoStyledInput'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import { CityDropdown } from '../../../../../inputs/CityDropdown'

export default function AddLocationModal() {
  const toast = useToast()
  const cities = useCities()
  const locationsPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const queryClient = useQueryClient()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: createLocation } = useCreateLocation()
  const [isLoadingImages, setIsLoadingImages] = useState()
  const [selectedImage, setSelectedImage] = useState([])
  const [inputReload, setInputReload] = useState(true)
  const optionsTimezones = useMemo(() => {
    return moment.tz.names().map((tz) => {
      return {
        value: tz,
        label: tz
      }
    })
  }, [])

  function isLoading() {
    return isLoadingImages
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      timezone: '',
      linkHref: '',
      linkText: '',
      parkingPhoto: null,
      cityId: '',
      goodFeedbackLink: '',
      badFeedbackLink: ''
    },
    onSubmit: submitHandler,
    validationSchema: addLocationValidation
  })

  const handleSubmit = formik.handleSubmit
  const resetForm = formik.resetForm

  async function onChangeFileHandler(e) {
    try {
      const images = Array.from(e.target.files)
      const imageCount = images.length + selectedImage.length

      if (images.some((img) => img.size > MAXIMUM_IMAGE_SIZE)) {
        throw new Error(
          `Maximum image size must be less than ${convertByteToMb(
            MAXIMUM_IMAGE_SIZE
          )} MB.`
        )
      } else if (imageCount > MAXIMUM_IMAGES_COUNT) {
        throw new Error(
          `You can load a maximum of ${MAXIMUM_IMAGES_COUNT} images.`
        )
      } else {
        setSelectedImage([...selectedImage, ...images])
      }
    } catch (error) {
      showErrorToast(error)
    }
  }

  function showErrorToast(message) {
    toast({
      status: 'warning',
      duration: 5000,
      isClosable: true,
      render: () => (
        <CustomWarningToast
          title="Warning"
          message={message}
          close={toast.closeAll}
        />
      )
    })
  }

  function onCloseHandler() {
    setSelectedImage([])
    resetForm()
    onClose()
  }

  function deleteImageHandler(idx) {
    setInputReload(true)
    const newArr = [...selectedImage]
    newArr.splice(idx, 1)
    setSelectedImage(newArr)
  }

  useEffect(() => {
    if (!inputReload) {
      setTimeout(() => setInputReload(true), 50)
    }
  }, [inputReload])

  async function submitHandler(values) {
    const toSend = {
      ...values,
      timezone: values.timezone.value,
      cityId: values.cityId.value
    }

    let parkingPhoto = ''
    if (selectedImage) {
      parkingPhoto = (await loadImageToCloud(selectedImage))[0]
    }

    const obj = {
      ...toSend,
      parkingPhoto
    }

    try {
      await createLocation({ obj })
      await queryClient.invalidateQueries(['profile', 'locations'])
      onCloseHandler()
    } catch (err) {
      const msg = err?.response?.data
      generateWarningToast({
        msgObjects: msg,
        toast,
        id: 'new-location'
      })
    }
  }

  const dropDownSelectProps = {
    styles: {
      control: {
        height: isLargerThan768 ? '56px' : '40px',
        padding: isLargerThan768 ? '12px 4px 0' : '0',
        border: !isLargerThan768 && 'none'
      },
      ...(!isLargerThan768 && {
        placeholder: {
          padding: '0 6px'
        }
      })
    }
  }
  return (
    <>
      {isLargerThan768 ? (
        <Button
          variant="blue"
          ml="auto"
          onClick={onOpen}
          isDisabled={!locationsPermission}
          fontSize={{ base: '12px', '2xl': '14px' }}
        >
          <PlusIcon mr="8px" />
          Add location
        </Button>
      ) : (
        <IconButton
          aria-label="editAccount"
          variant="funcColumnIconButtons"
          ml="auto"
          isDisabled={!locationsPermission}
          icon={<PlusIcon w="inherit" h="inherit" />}
          onClick={onOpen}
          color="custom.blue.600"
        />
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onCloseHandler}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '720px', '2xl': '656px' }}
        >
          <FormikProvider value={formik}>
            <ModalHeader mb="34px" d="flex">
              Add location
              <Box
                ml="auto"
                d={{ base: 'none', md: 'grid' }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="8px"
                fontSize={{ base: '12px' }}
              >
                <Button
                  w="100%"
                  variant="blue"
                  fontSize="inherit"
                  onClick={handleSubmit}
                >
                  <DisketteIcon mr="8px" />
                  Save
                </Button>
                <Button
                  w="100%"
                  variant="red"
                  fontSize="inherit"
                  onClick={onCloseHandler}
                >
                  <CrosshairIcon mr="8px" />
                  Cancel
                </Button>
              </Box>
            </ModalHeader>
            <ModalCloseButton d={{ md: 'none' }} />
            <ModalBody fontSize={{ base: '12px' }} d={{ base: 'block' }}>
              <Box
                mb="16px"
                display={{ base: 'block', md: 'grid' }}
                gridTemplateColumns={{ base: 'unset', md: '1fr' }}
                gridColumnGap={{ md: '16px' }}
              >
                <FormikTwoStyledInput
                  id="name"
                  name="name"
                  placeholder="Name"
                  mb="8px"
                />
                <FormikTwoStyledInput
                  id="address"
                  name="address"
                  placeholder="Address"
                  mb="8px"
                />
                <CityDropdown name={'cityId'} />
                <FormikTwoStyledInput
                  name="linkHref"
                  placeholder="Direction link href"
                  mb="8px"
                />
                <FormikTwoStyledInput
                  name="linkText"
                  placeholder="Direction link text"
                  mb="8px"
                />
                <FormikTwoStyledInput
                  name="goodFeedbackLink"
                  placeholder="Good Feedback Link"
                  mb="8px"
                />
                <FormikTwoStyledInput
                  name="badFeedbackLink"
                  placeholder="Bad Feedback Link"
                  mb="8px"
                />
                <FormikTwoStyledInput
                  name="telegramChatId"
                  placeholder="Telegram Bot Chat ID"
                  mb="8px"
                />
                <FormikDropdown
                  main={{ options: optionsTimezones, placeholder: 'Unset' }}
                  formik={{
                    name: 'timezone',
                    id: 'timezone'
                  }}
                  label={isLargerThan768 ? 'Timezone' : ''}
                  selectProps={dropDownSelectProps}
                />
                <FormControl
                  height="40px"
                  mt="8px"
                  mb="8px"
                  w="100%"
                  mr={{ base: '4px', md: '8px' }}
                  bg="black"
                >
                  <FormLabel
                    htmlFor="file-upload"
                    variant="lightBlue"
                    w="inherit"
                    fontWeight="500"
                    height="100%"
                    alignItems="center"
                    d="inline-flex"
                    justifyContent="center"
                    cursor="pointer"
                    _disabled={{ color: 'custom.gray.200', cursor: 'no-drop' }}
                    disabled={!!(selectedImage.length >= 1 || isLoadingImages)}
                    m="0"
                    fontSize={{ base: '12px', '2xl': '14px' }}
                  >
                    Add Parking Image
                  </FormLabel>
                  {inputReload && (
                    <Input
                      type="file"
                      id="file-upload"
                      disabled={
                        !!(selectedImage.length >= 1 || isLoadingImages)
                      }
                      accept="image/jpeg, image/png"
                      onChange={onChangeFileHandler}
                      max="5"
                      d="none"
                    />
                  )}
                </FormControl>

                {selectedImage.map((img, idx) => (
                  <Box
                    w="fit-content"
                    maxW={{ base: '100%' }}
                    pos="relative"
                    m="0 auto"
                    mb={{ base: '16px', '2xl': 'unset' }}
                    key={'picturesFinishGame' + idx}
                  >
                    <IconButton
                      aria-label="copy"
                      pos="absolute"
                      right="8px"
                      top="8px"
                      bg="gray"
                      w="24px"
                      height="24px"
                      minH="unset"
                      minW="unset"
                      borderRadius="50%"
                      _focus={{ border: 'none' }}
                      _hover={{ bg: '' }}
                      icon={
                        <CrosshairIcon color="white" w="80%" height="50%" />
                      }
                      onClick={() => deleteImageHandler(idx)}
                    />

                    <Image src={URL.createObjectURL(img)} />
                  </Box>
                ))}
              </Box>
            </ModalBody>

            <ModalFooter
              d={{ base: 'grid', md: 'none' }}
              gridTemplateColumns="1fr 1fr"
              gridColumnGap="8px"
            >
              <Button w="100%" variant="blue" onClick={handleSubmit}>
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button w="100%" variant="red" onClick={onCloseHandler}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </ModalFooter>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  )
}
