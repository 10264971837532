import { useTheme } from '@emotion/react'
import { Box, IconButton, Text, useToast } from '@chakra-ui/react'
import { PencilIcon } from '@/assets/Icons/PencilIcon'
import { CrosshairIcon } from '@/assets/Icons/CrosshairIcon'
import { DisketteIcon } from '@/assets/Icons/DisketteIcon'
import { useState } from 'react'

import PermissionRequired from '@/components/Common/PermissionRequired'
import { PERMISSION_EVENT } from '@/constants'
import { FieldArray, FormikProvider, useFormik, useFormikContext } from 'formik'
import FormikInput from '@/components/Common/FormikComponents/FormikInput'
import { PlusIcon } from '@/assets/Icons/PlusIcon'
import BasePricingAccordion from '@/components/Settings/Tabs/Attractions/AttractionsExperience/BasePricing/BasePricingList/BasePricingAccrodion'
import { useMutation, useQueryClient } from 'react-query'
import { ComboEndpoint } from '@/api/combo'

const comboAPI = ComboEndpoint()

export function ComboPricingControl({ id, prices = [] }) {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { mutate: mutateCreateComboPrice } = useMutation({
    mutationFn: ({ price, ...data }) => {
      return comboAPI.createComboPrice(id, { price: parseInt(price), ...data })
    },
    onSuccess: () => {
      queryClient.refetchQueries(['combo', id])
    }
  })

  const formik = useFormik({
    initialValues: {
      min_players: null,
      max_players: null,
      price: null,
      combo: id
    },
    onSubmit: (data) => mutateCreateComboPrice(data),
    enableReinitialize: true
  })

  // const fieldArray = useF

  const [showCreateNewPrice, setCreateNewPrice] = useState(false)
  const basePricingPermission = PermissionRequired(PERMISSION_EVENT, true)

  const handleNewPrice = () => {
    setCreateNewPrice(true)
  }

  const handlePriceChange = (fn) => {
    return async () => {
      try {
        await fn()
        setCreateNewPrice(false)
      } catch (error) {
        toast({
          title: 'Failed to manipulate price',
          description: 'Check Network tab and console.log'
        })
        console.error('Create price \n', +error)
      }
    }
  }

  return (
    <Box mb={{ base: '2px', md: '24px', '2xl': '32px' }}>
      <Box bg="white" padding="16px" mb="2px">
        <Text fontSize={{ base: '18px', '2xl': '24px' }} fontWeight="500">
          Base pricing
        </Text>
      </Box>
      <Box
        bg="white"
        padding={{ base: '16px', '2xl': '24px' }}
        d="grid"
        gridTemplateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gridGap="2px"
      >
        <BasePricingAccordion
          defaultIndex={[0]}
          allowToggle={false}
          needProps={true}
        >
          <Box d="flex" alignItems="center">
            Default Price
            {basePricingPermission && (
              <IconButton
                aria-label="add-experience"
                w="18px"
                as="span"
                h="18px"
                icon={<PlusIcon w="12px" h="12px" />}
                color="custom.blue.600"
                mr="16px"
                ml="auto"
                variant="funcColumnIconButtons"
                disabled={showCreateNewPrice}
                onClick={handleNewPrice}
              />
            )}
          </Box>
          <Box>
            <FormikProvider value={formik}>
              {showCreateNewPrice && (
                <BasePricingItem
                  key={'New'}
                  minPlayers={formik.values.min_players}
                  maxPlayers={formik.values.max_players}
                  price={formik.values.price}
                  onSaveChange={handlePriceChange(formik.submitForm)}
                  onDelete={() => setCreateNewPrice(false)}
                />
              )}
            </FormikProvider>

            {prices
              ?.sort((a, b) => a.min_players - b.min_players)
              .map((item, index) => (
                <BasePricingEditableItem
                  key={index}
                  minPlayers={item.min_players}
                  maxPlayers={item.max_players}
                  price={item.price}
                  priceId={item.id}
                  comboId={id}
                />
              ))}

            {formik.values?.length === 0 && !showCreateNewPrice && (
              <Box
                h="40px"
                d="flex"
                alignItems="center"
                fontWeight="500"
                color="custom.gray.300"
                justifyContent="center"
              >
                No slots
              </Box>
            )}
          </Box>
        </BasePricingAccordion>
      </Box>
    </Box>
  )
}

const BasePricingEditableItem = ({ priceId, comboId, ...props }) => {
  const queryClient = useQueryClient()

  const { mutate: mutateUpdateComboPrice } = useMutation({
    mutationFn: (data) => {
      return comboAPI.updateComboPrice(comboId, priceId, data)
    },
    onSuccess: () => {
      queryClient.refetchQueries(['combo', comboId])
    }
  })

  const { mutate: mutateDeleteComboPrice } = useMutation({
    mutationFn: (data) => {
      return comboAPI.deleteComboPrice(comboId, priceId)
    },
    onSuccess: () => {
      queryClient.refetchQueries(['combo', comboId])
    }
  })

  const formik = useFormik({
    initialValues: {
      min_players: props.minPlayers,
      max_players: props.maxPlayers,
      price: props.price / 100,
      combo: comboId
    },
    onSubmit: mutateUpdateComboPrice,
    enableReinitialize: true
  })

  return (
    <FormikProvider value={formik}>
      <BasePricingItem
        {...props}
        onSaveChange={formik.submitForm}
        onDelete={mutateDeleteComboPrice}
      />
    </FormikProvider>
  )
}

const currenyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export function BasePricingItem({
  maxPlayers = 0,
  minPlayers = 0,
  price = 0,
  maxPlayersLimit = null,
  minPlayersLimit = null,
  onSaveChange,
  onDelete,
  index
}) {
  const [edit, setEdit] = useState(false)
  const theme = useTheme()
  const toast = useToast()

  const basePricingPermission = PermissionRequired(PERMISSION_EVENT, true)
  const customColors = theme.colors.custom

  const handleSave = async () => {
    try {
      await onSaveChange()
      setEdit(false)
    } catch (error) {
      toast({
        title: 'Failed to save price',
        description: 'Check Network tab and console.log'
      })
      console.error(error)
    }
  }

  const handleDelete = async () => {
    try {
      await onDelete(index)
      setEdit(false)
    } catch (error) {
      toast({
        title: 'Failed to delete price',
        description: 'Check Network tab and console.log'
      })
      console.error(error)
    }
  }

  return (
    <Box
      bg={edit ? 'custom.blue.200' : 'custom.lightGray'}
      minHeight="40px"
      padding="8px 16px"
      borderBottom={`1px solid ${customColors.borderBlue}`}
      d="grid"
      _last={{ borderBottom: 'none' }}
      gridTemplateColumns="2fr 1fr 1fr"
    >
      <Box
        display="grid"
        gridTemplateColumns="1fr 3fr 1fr"
        alignSelf="center"
        justifySelf="center"
      >
        {edit ? (
          <FormikInput
            name={`min_players`}
            bg="white"
            textAlign="center"
            width="40px"
            height="24px"
            p="2px 0"
            placeholder="min"
          />
        ) : (
          <Text textAlign="center" width="40px">
            {minPlayers}
          </Text>
        )}
        <Text
          textAlign="center"
          d="flex"
          alignItems="center"
          justifyContent="center"
        >
          -
        </Text>
        {edit ? (
          <FormikInput
            name={`max_players`}
            bg="white"
            textAlign="center"
            width="40px"
            height="24px"
            p="2px 0"
            placeholder="max"
          />
        ) : (
          <Text textAlign="center" width="40px">
            {maxPlayers}
          </Text>
        )}
      </Box>
      {edit ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <FormikInput
            name={`price`}
            bg="white"
            textAlign="center"
            width="40px"
            height="24px"
            p="2px 0"
            placeholder="price"
          />
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          {currenyFormatter.format(price / 100)}
        </Box>
      )}

      <Box
        d="grid"
        gridTemplateColumns="1fr 1fr"
        gridColumnGap="16px"
        alignSelf="center"
        justifySelf="end"
      >
        <IconButton
          aria-label="edit-row-base-pricing"
          variant="funcColumnIconButtons"
          color={edit ? 'custom.blue.600' : 'custom.gray.200'}
          h="12px"
          w="12px"
          justifySelf="end"
          onClick={edit ? handleSave : () => setEdit(true)}
          isDisabled={!basePricingPermission}
          icon={
            edit ? (
              <DisketteIcon h="inherit" w="inherit" />
            ) : (
              <PencilIcon h="inherit" w="inherit" />
            )
          }
        />
        <IconButton
          aria-label="edit-row-base-pricing"
          variant="funcColumnIconButtons"
          color="custom.gray.200"
          justifySelf="start"
          h="12px"
          w="12px"
          isDisabled={!basePricingPermission}
          onClick={handleDelete}
          icon={<CrosshairIcon h="inherit" w="inherit" />}
        />
      </Box>
    </Box>
  )
}
