import { useSelector } from 'react-redux'

import { permissionsInState } from '../../../constants'

export default function PermissionRequired(permission, returnBoolean = false) {
  const permissions = useSelector(permissionsInState)
  if (permissions?.includes(permission)) {
    if (returnBoolean) {
      return true
    } else {
      return {
        isDisabled: false,
        _disabled: {
          _hover: {
            background: 'red'
          }
        }
      }
    }
  } else {
    if (returnBoolean) {
      return false
    } else {
      return { isDisabled: true }
    }
  }
}
