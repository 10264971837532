import { PostHogProvider} from 'posthog-js/react'
import React from 'react';

import { POSTHOG_HOST, POSTHOG_KEY } from '@/libs/tracking';


const options = {
  api_host: POSTHOG_HOST,
}

export const PosthogProvider = ({ children }) => {
  return (
    <PostHogProvider apiKey={POSTHOG_KEY} options={options}>
      {children}
    </PostHogProvider>
  )
}