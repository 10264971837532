export default function CircularIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1498 2.35C12.6998 0.9 10.7098 0 8.49977 0C4.07977 0 0.509766 3.58 0.509766 8C0.509766 12.42 4.07977 16 8.49977 16C12.2298 16 15.3398 13.45 16.2298 10H14.1498C13.3298 12.33 11.1098 14 8.49977 14C5.18977 14 2.49977 11.31 2.49977 8C2.49977 4.69 5.18977 2 8.49977 2C10.1598 2 11.6398 2.69 12.7198 3.78L9.49977 7H16.4998V0L14.1498 2.35Z"
        fill="#BF3938"
      />
    </svg>
  )
}
