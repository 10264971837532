import { Box } from '@chakra-ui/react'

import EmailTemplatesItem from '../EmailTemplatesItem'

export default function EmailTemplatesList({ data = [] }) {
  return (
    <Box>
      {data
        ?.sort(function (a, b) {
          return a.id - b.id
        })
        .map((el, idx) => (
          <EmailTemplatesItem
            key={'paymentGate' + idx}
            name={el.template_name}
            emailTemplateId={el.id}
          />
        ))}
    </Box>
  )
}
