import * as yup from 'yup'
import { cleanPhoneNumber } from '../../../libs'

export const newOrderValidation = yup.object().shape({
  payData: yup.object().shape({
    type: yup.string().required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    phone: yup
      .string()
      .required()
      .test('phone length', 'must be 11 charters length', (val) => {
        if (val && val.length) {
          const value = cleanPhoneNumber(val)
          return /\d{10}/.test(value)
        } else return false
      }),
    email: yup.string().email().required(),
    cvc: yup.string().when('type', {
      is: 'online',
      then: yup.string().required()
    }),
    expDate: yup.string().when('type', {
      is: 'online',
      then: yup.string().required()
    }),
    transaction: yup.string().when('type', {
      is: 'offline',
      then: yup.string().required()
    }),
    location: yup.object().when('type', {
      is: 'cash',
      then: yup.object().required()
    })
  })
})
