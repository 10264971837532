import { useTheme } from '@emotion/react'
import { Box, IconButton, Text, useToast } from '@chakra-ui/react'
import { CrosshairIcon } from '../../../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../../../assets/Icons/DisketteIcon'
import { useCreatePrivateGamePrice } from '../../../../../../../../libs/hooks/useBasePrices'
import { useQueryClient } from 'react-query'
import CustomWarningToast from '../../../../../../../Common/CustomWarningToast'
import { FormikProvider, useFormik } from 'formik'
import { priceItemValidation } from '../../../../../../../../configs/validation/priceItemValidation'
import FormikInput from '../../../../../../../Common/FormikComponents/FormikInput'

export default function NewPrivateGamePricingItem({
  onClose,
  timeLetter = '',
  eventVariantId = '',
  minPlayersLimitation = null,
  maxPlayersLimitation = null
}) {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const toast = useToast()
  const errorBasePricingNew = {
    id: 'error-base-pricing-new',
    text: 'Something went wrong'
  }
  const customColors = theme.colors.custom
  const { mutate } = useCreatePrivateGamePrice()
  const formik = useFormik({
    initialValues: {
      minPlayers: '',
      maxPlayers: '',
      price: '',
      minPlayersLimitation,
      maxPlayersLimitation
    },
    enableReinitialize: true,
    validationSchema: priceItemValidation,
    onSubmit: (values) => {
      const newPrice = {
        eventVariantId: +eventVariantId,
        minPlayers: +values.minPlayers,
        maxPlayers: +values.maxPlayers,
        price: +values.price * 100
      }

      mutate(
        { obj: newPrice },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'eventvariantId',
              'private',
              +eventVariantId
            ])
            onClose()
          },
          onError: (err) => {
            const msg = err?.response?.data?.msg[0]
            generateWarningToast({
              ...errorBasePricingNew,
              incomingText: msg
            })()
          }
        }
      )
    }
  })
  const formikHandleSubmit = formik.handleSubmit

  function generateWarningToast({ text, id, incomingText }) {
    return () => {
      if (!toast.isActive(id)) {
        return toast({
          status: 'warning',
          id: id,
          duration: 2000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={incomingText ? incomingText : text}
              close={toast.closeAll}
            />
          )
        })
      }
    }
  }

  return (
    <FormikProvider value={formik}>
      <Box
        bg="custom.blue.200"
        minHeight="40px"
        padding="5.5px 16px"
        borderBottom={`1px solid ${customColors.borderBlue}`}
        d="grid"
        _last={{ borderBottom: 'none' }}
        gridTemplateColumns="2fr 1fr 1fr"
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 3fr 1fr"
          alignSelf="center"
          justifySelf="center"
        >
          <FormikInput
            name="minPlayers"
            bg="white"
            textAlign="center"
            width="40px"
            height="24px"
            p="2px 0"
            placeholder="min"
          />
          <Text
            textAlign="center"
            d="flex"
            alignItems="center"
            justifyContent="center"
          >
            -
          </Text>
          <FormikInput
            name="maxPlayers"
            bg="white"
            textAlign="center"
            width="40px"
            height="24px"
            p="2px 0"
            placeholder="max"
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <FormikInput
            name="price"
            bg="white"
            textAlign="center"
            width="40px"
            height="24px"
            p="2px 0"
            placeholder="price"
          />
        </Box>
        <Box
          d="grid"
          gridTemplateColumns="1fr 1fr"
          gridColumnGap="16px"
          alignSelf="center"
          justifySelf="end"
        >
          <IconButton
            aria-label="edit-row-base-pricing"
            variant="funcColumnIconButtons"
            color="custom.blue.600"
            h="12px"
            w="12px"
            justifySelf="end"
            onClick={formikHandleSubmit}
            icon={<DisketteIcon h="inherit" w="inherit" />}
          />
          <IconButton
            aria-label="edit-row-base-pricing"
            variant="funcColumnIconButtons"
            color="custom.gray.200"
            justifySelf="start"
            h="12px"
            w="12px"
            onClick={onClose}
            icon={<CrosshairIcon h="inherit" w="inherit" />}
          />
        </Box>
      </Box>
    </FormikProvider>
  )
}
