import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { ComboBookingsEndpoint } from '../../api/combo'

const comboBookingsAPI = ComboBookingsEndpoint()

export function useComboBookings(filters, queryOption = {}) {
  const queryKeys = useMemo(() => {
    const array = ['combo-bookings']
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        array.push(filters[key])
      }
    })
    return array
  }, [filters])

  return useQuery(queryKeys, () => comboBookingsAPI.getAll(filters), {
    ...queryOption
  })
}
