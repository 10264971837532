import { useMutation, useQuery } from 'react-query'

import { getSchedules } from '../../api/bookings'
import {
  deletePriceInterval,
  newSchedule
} from '../../api/Settings/attractions'

export function useSchedules(queryOption = {}) {
  return useQuery(['schedules'], () => getSchedules(), {
    ...queryOption
  })
}

export function useNewSchedule(options = {}) {
  return useMutation(({ obj }) => newSchedule(obj), {
    ...options
  })
}

export function useDeletePriceInterval(options = {}) {
  return useMutation(({ obj }) => deletePriceInterval(obj), {
    ...options
  })
}
