import { Box } from '@chakra-ui/react'
import momentTz from 'moment-timezone'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { correctionTimezone, isToday, UTCDateFromLocal } from '@/libs'
import { useCalendar } from '@/libs/hooks/useCalendar'
import { useExperiences } from '@/libs/hooks/useExperiences'
import { useLocations } from '@/libs/hooks/useLocations'

import {
  CALENDAR_REFETCH_TIME,
  filtersInState,
  locationFilterInState
} from '@/constants'

import TableBodyCalendar from './TableBodyCalendar'
import TableHeaderCalendar from './TableHeaderCalendar'
import { TABLE_COLUMN_VALUES_BOOKING_CALENDAR } from '../../../../configs'
import Preloader from '../../../Common/Preloader'

export default function TableBookingCalendar({
  tableData = [],
  tableDataRefetch,
  weekday = null,
  isLoading = false
}) {
  const locationFilter = useSelector(locationFilterInState)
  const { status } = useSelector(filtersInState)
  const isTodayLocal = status.value === 'today' || isToday(status.value)
  const [localError, setLocalError] = useState('')
  const [firstLoadSuccess, setFirstLoadSuccess] = useState(false)
  const intervalRef = useRef(null)
  const selectedDay = useMemo(() => {
    return status.value === 'today'
      ? correctionTimezone(Math.round(new Date().valueOf() / 1000), true) * 1000
      : correctionTimezone(Math.round(status.value / 1000), true) * 1000
  }, [status])

  const {
    data: calendarData = {},
    isLoading: isCalendarLoading,
    refetch: refetchCalendar,
    isRefetching: isRefetchingLoading
  } = useCalendar(Math.round(selectedDay / 1000), {
    enabled: !!selectedDay,
    refetchOnWindowFocus: false
  })

  const {
    data: { results: locations = [] } = {},
    isLoading: isLocationLoading
  } = useLocations({
    refetchOnWindowFocus: false
  })
  const { locationNames, locationsIdByNames, locationObjectsByNames } =
    useMemo(() => {
      const obj = {}
      const obj2 = {}
      const obj3 = {}
      if (locations.length !== 0) {
        locations.forEach((location) => {
          obj[location?.id] = location?.name
          obj2[location?.name] = location?.id
          obj3[location?.name] = location
        })
      }
      return {
        locationNames: obj,
        locationsIdByNames: obj2,
        locationObjectsByNames: obj3
      }
    }, [locations])

  const {
    data: { results: testExp = [] } = {},
    isLoading: isExperiencesLoading
  } = useExperiences({
    refetchOnWindowFocus: false
  })
  const { eventNamesObj2, eventNamesObj3 } = useMemo(() => {
    //create experience name objects like: experienceName: 245 (id) and
    // get all experiences ids, for request prices below;
    const eventNamesObj2 = {}
    const eventNamesObj3 = {}
    const arr = []
    if (testExp.length !== 0) {
      testExp.forEach((tableItem) => {
        const eventVariantId = tableItem?.id
        const eventName = tableItem?.name
        if (!arr.includes(eventVariantId)) {
          arr.push(eventVariantId)
        }

        if (!eventNamesObj2.hasOwnProperty(eventVariantId)) {
          eventNamesObj2[eventVariantId] = eventName
        }

        if (!eventNamesObj3.hasOwnProperty(eventName)) {
          eventNamesObj3[eventName] = eventVariantId
        }
      })
      return { eventVariantsIds2: arr, eventNamesObj2, eventNamesObj3 }
    } else {
      return { eventVariantsIds2: [], eventNamesObj2: {}, eventNamesObj3: {} }
    }
  }, [testExp, weekday])

  const preparedCalendarData = useMemo(() => {
    const final = {}
    if (
      Object.keys(calendarData).length !== 0 &&
      Object.keys(eventNamesObj3) &&
      Object.keys(locationsIdByNames).length !== 0
    ) {
      Object.keys(calendarData).forEach((key) => {
        const element = calendarData[key]
        const locationId = locationsIdByNames[key]
        element.forEach((object) => {
          const eventvariantName = object.event_variant
          const eventvariantId = eventNamesObj3[eventvariantName]
          final[eventvariantId] = { ...object, locationId, eventvariantId }
        })
      })
    }
    return final
  }, [calendarData, eventNamesObj3])

  const tableDataObjectsByIntervalId = useMemo(() => {
    const obj = {}
    if (tableData.length !== 0) {
      for (let i = 0; i < tableData.length; i++) {
        const element = tableData[i]
        const experienceIntervalId = element.interval
        if (experienceIntervalId) {
          // check interval for null;
          if (obj.hasOwnProperty(experienceIntervalId)) {
            obj[experienceIntervalId] = [...obj[experienceIntervalId], element]
          } else {
            obj[experienceIntervalId] = [element]
          }
        }
      }
    }
    return obj
  }, [tableData])

  function refetch() {
    refetchCalendar()
    tableDataRefetch()
  }

  const intervalLoadingHandler =
    isCalendarLoading || isExperiencesLoading || isLocationLoading
  useEffect(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }
    if (!intervalLoadingHandler) {
      intervalRef.current = setInterval(() => {
        refetch()
      }, CALENDAR_REFETCH_TIME)
    }

    return () => clearInterval(intervalRef.current)
  }, [intervalLoadingHandler, selectedDay])

  const eventVarWithIntervals = useMemo(() => {
    const obj = {}
    if (
      Object.keys(preparedCalendarData).length !== 0 &&
      testExp.length !== 0
    ) {
      for (let i = 0; i < testExp.length; i++) {
        const experience = testExp[i]
        const timezone = experience.event.tz
        const experienceId = experience.id
        const prices = preparedCalendarData[experienceId]
        const pricesIntervalsObject = {}
        const intervals = prices?.slots || []
        const locationId = prices?.locationId
        const breakTime = prices?.break_time
        const breakTimeSeconds = breakTime * 60
        intervals?.forEach((interval, idx) => {
          const intervalId = interval.id
          const bookingByIntervalId = tableDataObjectsByIntervalId[intervalId]
          const startTime = interval?.start * 1000
          const endTime = interval?.end * 1000
          const dateFromInterval = new Date(startTime)
          const selectedDayDate = momentTz.tz(new Date(selectedDay), timezone)
          const nextDay = endTime - startTime < 0
          const secondsCoordinatesStart =
            UTCDateFromLocal(startTime).hours() * 60 * 60 +
            UTCDateFromLocal(startTime).minutes() * 60
          const secondsCoordinatesEnd =
            UTCDateFromLocal(endTime).hours() * 60 * 60 +
            UTCDateFromLocal(endTime).minutes() * 60
          const dateForCompareHandler = new Date(
            selectedDayDate.year(),
            selectedDayDate.month(),
            selectedDayDate.date(),
            dateFromInterval.getUTCHours(),
            dateFromInterval.getUTCMinutes(),
            0
          )
          const dateForCompare =
            dateForCompareHandler.valueOf() -
            dateForCompareHandler.getTimezoneOffset() * 60 * 1000

          pricesIntervalsObject[interval.id] = {
            ...interval,
            breakTime,
            breakTimeSeconds,
            secondsStart: secondsCoordinatesStart,
            secondsEnd: secondsCoordinatesEnd,
            locationId,
            dateForCompare,
            experienceId,
            date: Math.round(selectedDay / 1000),
            bookings:
              bookingByIntervalId && bookingByIntervalId?.length !== 0
                ? bookingByIntervalId
                : []
          }
        })
        obj[experience.id] = {
          ...experience,
          calendarIntervals: {
            ...prices,
            intervals: pricesIntervalsObject
          }
        }
      }
    }
    return obj
  }, [calendarData, testExp, tableDataObjectsByIntervalId])

  const preparedData2 = useMemo(() => {
    const obj = {}
    const keysArray = Object.keys(eventVarWithIntervals)
    const locationNamesLength = Object.keys(locationNames)
    const experiencesNamesLength = Object.keys(eventNamesObj2)
    if (
      keysArray.length !== 0 &&
      locationNamesLength.length !== 0 &&
      experiencesNamesLength !== 0
    ) {
      keysArray.map((key) => {
        const element = eventVarWithIntervals[key]
        const locationId = element.location_id
        const experienceID = key
        const locationName = locationNames[locationId]
        const experienceName = eventNamesObj2[experienceID]
        if (obj.hasOwnProperty(locationName)) {
          obj[locationName] = {
            ...obj[locationName],
            [experienceName]: Object.values(
              eventVarWithIntervals[key]?.calendarIntervals?.intervals
            )
          }
        } else {
          obj[locationName] = {
            [experienceName]: Object.values(
              eventVarWithIntervals[key]?.calendarIntervals?.intervals
            )
          }
        }
      })
    }
    return obj
  }, [eventVarWithIntervals, locationNames, eventNamesObj2])

  const preparedSortedData = useMemo(() => {
    let obj = preparedData2
    if (locationFilter.value !== '') {
      const locationName = locationFilter.label
      if (preparedData2.hasOwnProperty(locationName)) {
        obj = {
          [locationName]: preparedData2[locationName]
        }
      }
    }
    return obj
  }, [preparedData2, locationFilter])

  const isPriceObjectLoading =
    isCalendarLoading || isExperiencesLoading || isLocationLoading || isLoading
  const columns = TABLE_COLUMN_VALUES_BOOKING_CALENDAR
  const isItLoading = isPriceObjectLoading

  const isGlobalRefetching = isLoading || isRefetchingLoading
  const calendar = useMemo(() => {
    return Object.keys(preparedSortedData)?.map((location, idx) => {
      if (!firstLoadSuccess) setFirstLoadSuccess(true)
      return (
        <Box key={'bookingCalendarTable' + idx}>
          <TableHeaderCalendar
            columns={columns}
            locationLabel={location}
            d="grid"
            isLoading={isRefetchingLoading}
            gridTemplateColumns={{
              md: `6fr repeat(${columns.length}, 1fr)`,
              '2xl': `4fr repeat(${columns.length}, 1fr)`
            }}
            h="40px"
          />
          <TableBodyCalendar
            key={Math.random()}
            columns={columns}
            isToday={isTodayLocal}
            d="grid"
            locationObject={locationObjectsByNames[location]}
            location={preparedSortedData[location]}
            gridTemplateColumns={{
              md: `6fr ${columns.length}fr`,
              '2xl': `4fr ${columns.length}fr`
            }}
            h="40px"
          />
        </Box>
      )
    })
  }, [
    preparedSortedData,
    isTodayLocal,
    isGlobalRefetching,
    preparedData2,
    calendarData
  ])
  return isItLoading ? (
    <Preloader />
  ) : Object.keys(preparedData2).length === 0 || localError ? (
    <Box textAlign="center" m="20px 0" fontSize="14px">
      {localError ? localError : 'No results were found for your search.'}
    </Box>
  ) : (
    calendar
  )
}
