import axios from 'axios'

import { refreshToken } from './auth'
import { setAuthToken, signOut } from '../actions'
import { BASE_URL } from '../constants'
import { store } from '../store'

export const api = axios.create({
  baseURL: BASE_URL
})

export const apiUnprotect = axios.create({
  baseURL: BASE_URL
})

let isRefreshing = false
let failedQueue = []

const processQueue = (error, accessToken = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(accessToken)
    }
  })

  failedQueue = []
}

api.interceptors.request.use(
  function (config) {
    const state = store.getState()
    if (state.app.authToken !== '') {
      config.headers.Authorization = 'Bearer ' + state.app.authToken
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers.Accept = 'application/json'
    //    config.headers['Cache-Control'] = 'no-cache';
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (config) {
    const { status } = config
    return config
  },
  async function (error) {
    const originalRequest = error.config
    const state = store.getState()
    const refresh = state.app.refreshToken

    if (
      (error.response.status === 401 &&
        originalRequest.url === '/api/token/refresh/') ||
      refresh === ''
    ) {
      failedQueue = []
      store.dispatch(signOut())
      isRefreshing = false
    }

    if (error.response.status === 401 && refresh !== '') {
      const state = store.getState()
      const refresh = state.app.refreshToken

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return axios(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      isRefreshing = true
      try {
        const { data } = await refreshToken(refresh)
        store.dispatch(setAuthToken(data.access))
        originalRequest.headers['Authorization'] = 'Bearer ' + data.access
        processQueue(null, data.access)
        isRefreshing = false
        return axios.request(originalRequest)
      } catch (e) {
        store.dispatch(signOut())
      }
    }
    return Promise.reject(error)
  }
)
