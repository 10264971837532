import { FormControl, Input } from '@chakra-ui/react'
import { useField } from 'formik'
import InputMask from 'react-input-mask'

export default function FormikScheduleInput({
  name,
  id,
  placeholder,
  bg = '',
  ...rest
}) {
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)
  const regexp =
    /(0[1-9])|(1[0-2|_]):([0-5]|_)([0-9]|_)|__:__|(_[0-2]):([0-5]|_)([0-9]|_)/

  function onChangeCustom(e) {
    if (e.target.value.match(regexp)) {
      setValue(e.target.value)
    }
  }

  return (
    <FormControl {...rest}>
      <Input
        as={InputMask}
        mask="99:99"
        pl="20px"
        name={name}
        id={id}
        borderRadius="0"
        placeholder={placeholder}
        value={value}
        onChange={onChangeCustom}
        onBlur={() => setTouched(true)}
        bg={error && touched ? 'custom.red.20' : bg ? bg : 'custom.white'}
        fontSize={{ base: '12px', '2xl': '14px' }}
      />
    </FormControl>
  )
}
