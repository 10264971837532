import { createIcon } from '@chakra-ui/icons'

export const ProfileIcon = createIcon({
  displayName: 'ProfileIcon',
  viewBox: '0 0 24 24',
  boxSize: '24',
  path: [
    <g fill="currentColor" key={'pathProfileIcon1'}>
      <path d="M12 2C14.7667 2 17 4.23333 17 7C17 9.76667 14.7667 12 12 12C9.23333 12 7 9.76667 7 7C7 4.23333 9.23333 2 12 2Z" />
    </g>,
    <g fill="currentColor" key={'pathProfileIcon2'}>
      <path d="M22 22H2V19.5111C2 15.9556 8.66667 14 12 14C15.3333 14 22 15.9556 22 19.5111V22Z" />
    </g>
  ]
})
