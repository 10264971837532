import { CardElement } from '@stripe/react-stripe-js'
import './styles.css'

const CARD_ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: 'rgb(240, 57, 122)',
      color: 'rgb(0, 0, 0)',
      fontSize: '16px',
      fontFamily: '"Poppins", sans-serif',
      '::placeholder': {
        color: '#CFD7DF'
      }
    },
    empty: {},
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238'
      }
    }
  }
}

export default function CardSection() {
  return <CardElement options={CARD_ELEMENT_OPTIONS} className={'test'} />
}
