import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../constants'
import CashTable from '@/components/Cash/CashTable'
import CashList from '@/components/Cash/CashList'
import { Box, Text } from '@chakra-ui/react'
import DropdownSelect from '@/components/Common/DropdownSelect'
import { useLocations } from '../../libs/hooks/useLocations'
import AddCashModal from '@/components/Cash/Modals/AddCashModal'
import { useCash } from '../../libs/hooks/useCash'
import GBaseTag from '@/components/Common/GBaseTag'
import WithPagination2 from '@/components/Common/WithPagination2'
import { resetPagination } from '../../libs'

export default function Cash() {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const resetPaginationId = 'reset-pagination-cash'
  const [paginationState, setPaginationState] = useState({
    page: 1,
    pageSize: 15
  })
  const [selectedLocation, setSelectedLocation] = useState(null)
  const { data: { results: locations = [] } = {} } = useLocations()
  const optionsLocation = useMemo(() => {
    if (locations?.length !== 0) {
      return locations.map((loc) => {
        return {
          value: loc.id,
          label: loc.name,
          timezone: loc.timezone
        }
      })
    } else {
      return []
    }
  }, [locations])

  useEffect(() => {
    if (!selectedLocation) {
      setSelectedLocation(optionsLocation[0])
    }
  }, [locations])

  const {
    queryKeys: invalidateCashString,
    data: {
      results: cash = [],
      count: totalItems = '',
      total_amount: totalAmount = null
    } = {},
    isLoading
  } = useCash(
    {
      page: paginationState?.page,
      pageSize: paginationState?.pageSize,
      locationId: selectedLocation ? selectedLocation?.value : null
    },
    {
      enabled: !!selectedLocation,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    // setPaginationState({
    //     page: 1,
    //     pageSize: 15,
    // });
    resetPagination(resetPaginationId)
  }, [selectedLocation])

  function onChangeLocation(obj) {
    setSelectedLocation(obj)
  }

  const isPositive = totalAmount > 0
  const totalLabel = isPositive ? `+$${totalAmount}` : `-$${-totalAmount}`
  return (
    <Box
      p={{
        base: '16px 16px 80px',
        md: '24px 24px 80px',
        '2xl': '48px 48px 80px'
      }}
    >
      {isLargerThan768 && (
        <Text variant="boldTitle" mb={{ md: '24px', '2xl': '32px' }}>
          Cash
        </Text>
      )}
      <Box
        bg={{ base: 'white' }}
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
        p={{ base: '16px', md: '16px' }}
        mb={{ base: '6px', md: '2px' }}
      >
        <Box
          mb={{ base: '8px', md: '0' }}
          mr={{ base: '0', md: '8px' }}
          w={{ base: '', md: '20.4%' }}
        >
          <DropdownSelect
            selectProps={{
              main: {
                isDisabled: optionsLocation.length === 0,
                options: optionsLocation,
                placeholder:
                  optionsLocation.length === 0 ? 'Empty' : 'Location',
                onChange: onChangeLocation,
                value: selectedLocation
              }
            }}
            chakraProps={{ fontSize: 'inherit' }}
          />
        </Box>
        <Box d="flex" alignItems="center" mb="8px">
          {!isLargerThan768 && (
            <Text fontSize={{ base: '14px' }} mr="8px">
              Total:
            </Text>
          )}
          {typeof totalAmount === 'number' && (
            <GBaseTag
              color={isPositive ? 'green' : 'red'}
              label={totalLabel}
              fontSize={{ base: '14px', '2xl': '16px' }}
              height={{ md: '40px' }}
            />
          )}
        </Box>

        <AddCashModal invalidateCashString={invalidateCashString} />
      </Box>

      {isLargerThan768 ? (
        <WithPagination2
          totalCountItems={totalItems}
          isLoading={isLoading}
          actualLength={cash.length}
          setState={setPaginationState}
          state={paginationState}
          id={resetPaginationId}
        >
          <CashTable
            tableData={cash}
            timezone={selectedLocation?.timezone}
            invalidateCashString={invalidateCashString}
          />
        </WithPagination2>
      ) : (
        <WithPagination2
          totalCountItems={totalItems}
          isLoading={isLoading}
          actualLength={cash.length}
          setState={setPaginationState}
          state={paginationState}
          id={resetPaginationId}
        >
          <CashList
            data={cash}
            timezone={selectedLocation?.timezone}
            invalidateCashString={invalidateCashString}
          />
        </WithPagination2>
      )}
    </Box>
  )
}
