export const TABLE_COLUMNS_SIZES_BOOKINGS_BEFORE_1440 = {
  code: {
    width: '11%',
    maxWidth: '62px'
  },
  'game date': {
    maxWidth: '62px',
    width: '11%'
  },
  'start time': {
    maxWidth: '62px',
    width: '11%'
  },
  location: {
    maxWidth: '62px',
    width: '9%'
  },
  experience: {
    maxWidth: '62px',
    width: '9%'
  },
  players: {
    maxWidth: '62px',
    width: '11%'
  },
  status: {
    maxWidth: '62px',
    width: '12%'
  },
  client: {
    maxWidth: '62px',
    width: '20%'
  },
  funcColumn: {
    maxWidth: '62px',
    width: '3%'
  }
}

export const TABLE_COLUMNS_SIZES_BOOKINGS_AFTER_1440 = {
  code: {
    width: '11%',
    maxWidth: '62px'
  },
  'game date': {
    maxWidth: '62px',
    width: '11%'
  },
  'start time': {
    maxWidth: '62px',
    width: '11%'
  },
  location: {
    maxWidth: '62px',
    width: '9%'
  },
  experience: {
    maxWidth: '62px',
    width: '12%'
  },
  players: {
    maxWidth: '62px',
    width: '9%'
  },
  client: {
    maxWidth: '62px',
    width: '19%'
  },
  status: {
    maxWidth: '62px',
    width: '15%'
  },
  funcColumn: {
    maxWidth: '62px',
    width: '3%'
  }
}

export const TABLE_COLUMNS_SIZES_COMMENTS = {
  name: {
    maxWidth: '1px',
    width: '18%'
  },
  comment: {
    maxWidth: '1px',
    width: '61%'
  }
}

export const TABLE_COLUMNS_SIZES_PROMOCODES_BEFORE_1440 = {
  code: {
    maxWidth: '1px',
    width: '15.8%'
  },
  created: {
    maxWidth: '1px',
    width: '15.8%'
  },
  'exp.date': {
    maxWidth: '1px',
    width: '15.8%'
  },
  details: {
    maxWidth: '1px',
    width: '15.8%'
  },
  partner: {
    maxWidth: '1px',
    width: '15.8%'
  },
  status: {
    maxWidth: '1px',
    width: '15.8%'
  },
  funcColumn: {
    maxWidth: '1px',
    width: '5%'
  }
}

export const TABLE_COLUMNS_SIZES_PROMOCODES_AFTER_1440 = {
  code: {
    maxWidth: '1px',
    width: '11.8%'
  },
  created: {
    maxWidth: '1px',
    width: '11.8%'
  },
  'exp.date': {
    maxWidth: '1px',
    width: '11.8%'
  },
  reason: {
    maxWidth: '1px',
    width: '11.8%'
  },
  experience: {
    maxWidth: '1px',
    width: '11.8%'
  },
  details: {
    maxWidth: '1px',
    width: '11.8%'
  },
  partner: {
    maxWidth: '1px',
    width: '11.8%'
  },
  status: {
    maxWidth: '1px',
    width: '11.8%'
  },
  funcColumn: {
    maxWidth: '1px',
    width: '5%'
  }
}

export const TABLE_COLUMNS_SIZES_PAYMENTS = {
  email: {
    maxWidth: '1px',
    width: '15%'
  },
  type: {
    maxWidth: '1px',
    width: '15%'
  },
  identifier: {
    maxWidth: '1px',
    width: '15%'
  },
  total: {
    maxWidth: '1px',
    width: '15%'
  },
  user: {
    maxWidth: '1px',
    width: '35%'
  }
}

export const TABLE_COLUMNS_SIZES_WAIVERS = {
  'full name': {
    maxWidth: '1px',
    width: '20%'
  },
  phone: {
    maxWidth: '1px',
    width: '14%'
  },
  'e-mail': {
    maxWidth: '1px',
    width: '26%'
  },
  date: {
    maxWidth: '1px',
    width: '22%'
  },
  'waiver in pdf': {
    maxWidth: '1px',
    width: '18%'
  }
}

export const TABLE_COLUMNS_SIZES_PLAYERS = {
  func: {
    maxWidth: '1px',
    width: '5%'
  },
  'full name': {
    maxWidth: '1px',
    width: '20%'
  },
  Phone: {
    maxWidth: '1px',
    width: '14%'
  },
  'e-mail': {
    maxWidth: '1px',
    width: '22%'
  },
  DOB: {
    maxWidth: '1px',
    width: '18%'
  },
  'Last Room Played': {
    maxWidth: '1px',
    width: '15%'
  },
  functionalColumn: {
    maxWidth: '1px',
    width: '5%'
  }
}

export const TABLE_COLUMNS_SIZES_CASH = {
  booking: {
    maxWidth: '1px',
    width: '10%'
  },
  giftcard: {
    maxWidth: '1px',
    width: '10%'
  },
  client: {
    maxWidth: '1px',
    width: '11%'
  },
  user: {
    maxWidth: '1px',
    width: '11%'
  },
  Sum: {
    maxWidth: '1px',
    width: '10%'
  },
  comments: {
    maxWidth: '1px',
    width: '38%'
  },
  date: {
    maxWidth: '1px',
    width: '10%'
  }
}

export const TABLE_COLUMNS_SIZES_LIMITATIONS = {
  location: {
    maxWidth: '1px',
    width: '20%'
  },
  '08 AM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '09 AM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '10 AM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '11 AM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '12 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '01 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '02 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '03 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '04 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '05 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '06 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '07 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '08 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '09 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '10 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '11 PM': {
    maxWidth: '1px',
    width: '4.2%'
  },
  '12 AM': {
    maxWidth: '1px',
    width: '4.2%'
  }
}

export const TABLE_COLUMNS_SIZES_STATISTICS_QUESTROOM_BEFORE_1440 = {
  name: {
    maxWidth: '1px',
    width: '36%'
  },
  sched: {
    maxWidth: '1px',
    width: '8%'
  },
  down: {
    maxWidth: '1px',
    width: '8%'
  },
  ps: {
    maxWidth: '1px',
    width: '8%'
  },
  played: {
    maxWidth: '1px',
    width: '8%'
  },
  waste: {
    maxWidth: '1px',
    width: '8%'
  },
  ll: {
    maxWidth: '1px',
    width: '8%'
  },
  total: {
    maxWidth: '1px',
    width: '8%'
  },
  avg: {
    maxWidth: '1px',
    width: '8%'
  }
}

export const TABLE_COLUMNS_SIZES_STATISTICS_QUESTROOM = {
  1: {
    maxWidth: '1px',
    width: '4.25%'
  },
  2: {
    maxWidth: '1px',
    width: '4.25%'
  },
  3: {
    maxWidth: '1px',
    width: '4.25%'
  },
  4: {
    maxWidth: '1px',
    width: '4.25%'
  },
  5: {
    maxWidth: '1px',
    width: '4.25%'
  },
  6: {
    maxWidth: '1px',
    width: '4.25%'
  },
  7: {
    maxWidth: '1px',
    width: '4.25%'
  },
  8: {
    maxWidth: '1px',
    width: '4.25%'
  },
  9: {
    maxWidth: '1px',
    width: '4.25%'
  },
  10: {
    maxWidth: '1px',
    width: '4.25%'
  },
  11: {
    maxWidth: '1px',
    width: '4.25%'
  },
  12: {
    maxWidth: '1px',
    width: '4.25%'
  },
  sched: {
    maxWidth: '1px',
    width: '10%'
  },
  down: {
    maxWidth: '1px',
    width: '10%'
  },
  ps: {
    maxWidth: '1px',
    width: '10%'
  },
  played: {
    maxWidth: '1px',
    width: '10%'
  },
  waste: {
    maxWidth: '1px',
    width: '10%'
  },
  ll: {
    maxWidth: '1px',
    width: '15%'
  },
  total: {
    maxWidth: '1px',
    width: '15%'
  },
  avg: {
    maxWidth: '1px',
    width: '15%'
  }
}

export const TABLE_COLUMNS_SIZES_PAYMENT_GATES = {
  name: {
    maxWidth: '1px',
    width: '20%'
  },
  'public key': {
    maxWidth: '1px',
    width: '40%'
  },
  'private key': {
    maxWidth: '1px',
    width: '12%'
  },
  fee: {
    maxWidth: '1px',
    width: '10%'
  },
  default: {
    maxWidth: '1px',
    width: '10%'
  },
  funccolumn: {
    maxWidth: '1px',
    width: '8%'
  }
}

export const TABLE_COLUMNS_SIZES_STATISTICS_PARTNERS = {
  partner: {
    maxWidth: '1px',
    width: '25%'
  },
  total: {
    maxWidth: '1px',
    width: '25%'
  },
  used: {
    maxWidth: '1px',
    width: '25%'
  },
  amount: {
    maxWidth: '1px',
    width: '25%'
  },
  discount: {
    maxWidth: '1px',
    width: '25%'
  }
}

export const TABLE_COLUMNS_SIZES_GIFT_CARDS_BEFORE_1440 = {
  recipient: {
    maxWidth: '1px',
    width: '21%'
  },
  buyer: {
    maxWidth: '1px',
    width: '21%'
  },
  code: {
    maxWidth: '1px',
    width: '16%'
  },
  total: {
    maxWidth: '1px',
    width: '16%'
  },
  status: {
    maxWidth: '1px',
    width: '16%'
  },
  funcColumn: {
    maxWidth: '1px',
    width: '10%'
  }
}

export const TABLE_COLUMNS_SIZES_GIFT_CARDS_AFTER_1440 = {
  'recipient name': {
    maxWidth: '1px',
    width: '16%'
  },
  'recipient e-mail': {
    maxWidth: '1px',
    width: '16%'
  },
  buyer: {
    maxWidth: '1px',
    width: '16%'
  },
  'buyer e-mail': {
    maxWidth: '1px',
    width: '16%'
  },
  code: {
    maxWidth: '1px',
    width: '8%'
  },
  total: {
    maxWidth: '1px',
    width: '8%'
  },
  status: {
    maxWidth: '1px',
    width: '8%'
  },
  funcColumn: {
    maxWidth: '1px',
    width: '8%'
  }
}

export const TABLE_COLUMNS_SIZES_LOCATIONS = {
  name: {
    maxWidth: '1px',
    width: '25%'
  },
  address: {
    maxWidth: '1px',
    width: '25%'
  },
  timezone: {
    maxWidth: '1px',
    width: '25%'
  },
  waiver: {
    maxWidth: '1px',
    width: '15%'
  },
  funccolumn: {
    maxWidth: '1px',
    width: '10%'
  }
}

export const TABLE_COLUMNS_SIZES_USERS = {
  'full name': {
    maxWidth: '1px',
    width: '20%'
  },
  'e-mail': {
    maxWidth: '1px',
    width: '20%'
  },
  phone: {
    maxWidth: '1px',
    width: '20%'
  },
  role: {
    maxWidth: '1px',
    width: '20%'
  },
  location: {
    maxWidth: '1px',
    width: '20%'
  }
}

export const TABLE_COLUMNS_SIZES_COMBO = {
  name: {
    maxWidth: '1px',
    width: '20%'
  },
  interval: {
    maxWidth: '1px',
    width: '8%'
  },
  storylines: {
    maxWidth: '1px',
    width: '8%'
  },
  'max ppl': {
    maxWidth: '1px',
    width: '8%'
  },
  location: {
    maxWidth: '1px',
    width: '11%'
  },
  payments: {
    maxWidth: '1px',
    width: '11%'
  },
  pixel: {
    maxWidth: '1px',
    width: '11%'
  },
  ga: {
    maxWidth: '1px',
    width: '11%'
  }
}

export const TABLE_COLUMNS_SIZES_COMBO_BOOKINGS = {
  // id: {
  //   maxWidth: '1px',
  //   width: '10%'
  // },
  name: {
    maxWidth: '1px',
    width: '35%'
  },
  code: {
    maxWidth: '100px',
    width: '10%'
  },
  uses: {
    maxWidth: '1px',
    width: '10%'
  },
  status: {
    maxWidth: '1px',
    width: '11%'
  }
}

export const TABLE_COLUMNS_SIZES_ATTRACTIONS = {
  name: {
    maxWidth: '1px',
    width: '20%'
  },
  interval: {
    maxWidth: '1px',
    width: '8%'
  },
  storylines: {
    maxWidth: '1px',
    width: '8%'
  },
  'max ppl': {
    maxWidth: '1px',
    width: '8%'
  },
  location: {
    maxWidth: '1px',
    width: '11%'
  },
  payments: {
    maxWidth: '1px',
    width: '11%'
  },
  pixel: {
    maxWidth: '1px',
    width: '11%'
  },
  ga: {
    maxWidth: '1px',
    width: '11%'
  }
}

export const TABLE_COLUMNS_SIZES_PARTNERS = {
  name: {
    maxWidth: '1px',
    width: '25%'
  },
  coupons: {
    maxWidth: '1px',
    width: '25%'
  },
  used: {
    maxWidth: '1px',
    width: '25%'
  },
  funcColumn: {
    maxWidth: '1px',
    width: '25%'
  }
}
