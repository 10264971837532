import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text
} from '@chakra-ui/react'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../../constants'
import PaymentsList from './PaymentsList'
import PaymentsTable from './PaymentsTable'
import Preloader from '../../Common/Preloader'
import { useContext } from 'react'
import { BookingContext } from '../../../pages/Booking'

export default function Payments({ ...rest }) {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [bookingData = {}, isLoading] = useContext(BookingContext)
  const payments = bookingData.order?.payments || []
  const email = bookingData.order?.email
  const data = payments
  return (
    <Accordion
      allowToggle
      defaultIndex={[0]}
      borderStyle="hidden"
      fontFamily="inherit"
      mb={{ base: '16px', '2xl': '32px' }}
      {...rest}
    >
      <AccordionItem borderStyle="hidden">
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton
                fontWeight="500"
                fontSize={{ base: '18px', '2xl': '24px' }}
                bg="white"
                mb="2px"
                p={{ base: '16px', '2xl': '24px' }}
                _hover={{ bg: 'white' }}
                _focus={{ border: 'none' }}
                _active={{ bg: 'white' }}
              >
                <Box flex="1" textAlign="left">
                  Payments:
                </Box>
                <ArrowIcon
                  w="auto"
                  height="16px"
                  transition="all 0.2s ease-in"
                  transform={isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'}
                  color="custom.blue.600"
                />
              </AccordionButton>
            </h2>
            <AccordionPanel p="0">
              {isLoading ? (
                <Box
                  bg="white"
                  height="56px"
                  p="10px"
                  d="flex"
                  alignItems="center"
                >
                  <Preloader m="0" />
                </Box>
              ) : (
                <>
                  {data.length === 0 ? (
                    <Box
                      bg="white"
                      height="56px"
                      p="10px"
                      d="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text fontSize={{ base: '12px', '2xl': '14px' }}>
                        No Payments.
                      </Text>
                    </Box>
                  ) : (
                    <>
                      {!isLargerThan768 && (
                        <PaymentsList data={data} email={email} />
                      )}
                      {isLargerThan768 && (
                        <PaymentsTable tableData={data} email={email} />
                      )}
                    </>
                  )}
                </>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
