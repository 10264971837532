import {
  Box,
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useTheme,
  useToast
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SearchIcon } from '@chakra-ui/icons'
import { useSelector } from 'react-redux'
import { matchMedia768InState, PAGE_ERROR_MESSAGE } from '../../constants'
import PlayersList from '@/components/Players/PlayersList'
import PlayersTable from '@/components/Players/PlayersTable'
import DropdownSelect from '@/components/Common/DropdownSelect'
import { useLocations } from '../../libs/hooks/useLocations'
import debounce from 'lodash.debounce'
import { debounceSearchTime } from '../../configs'
import { usePlayers, usePlayersByMutation } from '../../libs/hooks/usePlayers'
import {
  friendlyTimeWithTZ,
  generateWarningToast,
  objectToCsv,
  preparedObjectToCsv
} from '../../libs'
import { useExperiences } from '../../libs/hooks/useExperiences'
import DatePickerModal from '@/components/Booking/Modals/DatePickerModal'
import { CrosshairIcon } from '../../assets/Icons/CrosshairIcon'
import WithPagination from '@/components/Common/WithPagination'
import AddPlayerModal from '@/components/Players/Modals/AddPlayerModal'

export default function Players({ blacklist = false }) {
  const toast = useToast()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const theme = useTheme()
  const customColors = theme.colors.custom
  const [enableSearchModule, setEnableSearchModule] = useState(false)
  const { data: { results: locations = [] } = {} } = useLocations()
  const { data: { results: experience = [] } = {} } = useExperiences()
  const initPaginationState = {
    page: 1,
    pageSize: 15
  }
  const [paginationState, setPaginationState] = useState(initPaginationState)
  const filtersInitialState = {
    searchString: '',
    createdFrom: '',
    createdTo: '',
    bookedFrom: '',
    bookedTo: '',
    playersCount: '',
    name: '',
    phone: '',
    email: '',
    location: {},
    status: {},
    experience: {}
  }
  const [inputsObject, setInputsObject] = useState(filtersInitialState)
  const [isInBlacklist, setIsInBlacklist] = useState(false)
  const [acceptedFilters, setAcceptedFilters] = useState({})
  const [acceptedSearchString, setAcceptedSearchString] = useState('')
  const [searchAfterReset, setSearchAfterReset] = useState(false)
  const isFiltersEmpty = useMemo(() => {
    return Object.keys(acceptedFilters).length === 0
  }, [acceptedFilters])
  const {
    data: { results: players = [], count: total = '' } = {},
    refetch: refetchPlayer
  } = usePlayers(
    {
      ...(!!acceptedSearchString && { search: acceptedSearchString }),
      ...(!isFiltersEmpty && { ...acceptedFilters }),
      ...(isInBlacklist && { isInBlacklist: isInBlacklist }),
      page: paginationState?.page,
      pageSize: paginationState?.pageSize
    },
    {
      enabled: false,
      retry: false,
      onError: (err) => {
        const errorMessage = err?.response?.data?.detail
        if (errorMessage === PAGE_ERROR_MESSAGE) {
          setPaginationState(initPaginationState)
        }
      }
    }
  )

  function refetchHandler() {
    setPaginationState(initPaginationState)
    refetchPlayer()
  }

  useEffect(() => {
    refetchPlayer()
  }, [paginationState])

  const {
    mutate: playersForCvs,
    reset: resetPlayersForCvs,
    isLoading: csvLoading
  } = usePlayersByMutation({
    ...(!!acceptedSearchString && { search: acceptedSearchString }),
    ...(!isFiltersEmpty && { ...acceptedFilters }),
    ...(isInBlacklist && { isInBlacklist: isInBlacklist }),
    page: 1,
    pageSize: 1000
  })

  const download = function (data) {
    const blob = new Blob([data], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute('hidden', '')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  function exportCsv() {
    playersForCvs(
      {},
      {
        onSuccess: (morePlayers) => {
          resetPlayersForCvs()
          const { results: players = [], count: total = '' } = morePlayers
          const obj = preparedObjectToCsv(players)
          download(objectToCsv(obj))
        },
        onError: (err) => {
          resetPlayersForCvs()
          const msg = err?.response?.data
          generateWarningToast({
            msgObjects: msg,
            toast,
            id: 'edit-experience-modal'
          })
        }
      }
    )
  }

  useEffect(() => {
    refetchPlayer()
  }, [acceptedFilters, acceptedSearchString, isInBlacklist])

  useEffect(() => {
    const accepted = {}
    Object.keys(inputsObject).forEach((key) => {
      if (key !== 'searchString' && typeof inputsObject[key] !== 'object') {
        accepted[key] = inputsObject[key]
      }
      if (typeof inputsObject[key] === 'object') {
        accepted[key] = inputsObject[key].value
      }
    })
    setAcceptedFilters(accepted)
  }, [isFiltersEmpty])

  const debouncedSearch = useCallback(
    debounce((text) => {
      if (text.length >= 3) {
        setAcceptedSearchString(text)
      } else {
        setAcceptedSearchString('')
      }
    }, debounceSearchTime),
    []
  )

  useEffect(() => {
    debouncedSearch(inputsObject.searchString)
  }, [inputsObject.searchString])

  const isLocationEmpty = useMemo(() => {
    return Object.keys(inputsObject?.location).length === 0
  }, [inputsObject.location])

  const optionsLocation = useMemo(() => {
    if (locations?.length !== 0) {
      return locations.map((loc) => {
        return {
          value: loc.name,
          label: loc.name,
          locId: loc.id
        }
      })
    } else {
      return []
    }
  }, [locations])

  const optionsExperience = useMemo(() => {
    const options = []
    if (experience?.length !== 0 && !!inputsObject?.location.value) {
      for (let i = 0; i < experience.length; i++) {
        if (experience[i].event.location === inputsObject?.location?.locId) {
          options.push({
            value: experience[i].name,
            label: experience[i].name
          })
        }
      }
      return options
    } else {
      return []
    }
  }, [experience, inputsObject?.location])
  const optionsStatus = [
    { value: 'upcoming', label: 'Upcoming' },
    { value: 'running', label: 'Running' },
    { value: 'completed', label: 'Completed' },
    { value: 'canceled', label: 'Canceled' }
  ]

  function onChangeHandlerInputs(input) {
    return (e) => {
      setInputsObject({
        ...inputsObject,
        [input]: e.target.value
      })
    }
  }

  function onChangeHandlerCustom(input) {
    return (value = null) => {
      setInputsObject({
        ...inputsObject,
        [input]: value
      })
    }
  }

  function onChangeHandlerTime(input) {
    return (value = null) => {
      const checkedData = new Date(value)
      const correctData = new Date(
        checkedData.getFullYear(),
        checkedData.getMonth(),
        checkedData.getDate(),
        input === 'bookedFrom' || input === 'createdFrom' ? 0 : 23,
        input === 'bookedTo' || input === 'createdTo' ? 59 : 0,
        0
      ).valueOf()
      setInputsObject({
        ...inputsObject,
        [input]: correctData
      })
    }
  }

  useEffect(() => {
    onChangeHandlerCustom('experience')({})
  }, [inputsObject.location])

  function searchEnableHandler() {
    setEnableSearchModule(!enableSearchModule)
  }

  function searchByFilters() {
    const accepted = {}
    Object.keys(inputsObject).forEach((key) => {
      if (key !== 'searchString' && typeof inputsObject[key] !== 'object') {
        accepted[key] = inputsObject[key]
      }
      if (typeof inputsObject[key] === 'object') {
        accepted[key] = inputsObject[key].value
      }
    })
    setAcceptedFilters(accepted)
  }

  function showTime(value) {
    return value ? friendlyTimeWithTZ(value, 'MM.DD.YYYY') : ''
  }

  function resetHandler() {
    setInputsObject(filtersInitialState)
    setSearchAfterReset(true)
  }

  useEffect(() => {
    if (searchAfterReset) {
      searchByFilters()
      setSearchAfterReset(false)
    }
  }, [searchAfterReset])

  function blackListHandler() {
    setIsInBlacklist(!isInBlacklist)
  }

  function setToEmpty(prop, value = '') {
    return () => {
      setInputsObject({
        ...inputsObject,
        [prop]: value
      })
    }
  }

  function returnStyles(prop) {
    return {
      ml: inputsObject[prop] ? '8px' : 0,
      w: inputsObject[prop] ? '40px' : 0,
      minW: 'unset',
      onClick: setToEmpty(prop),
      transform: inputsObject[prop] ? 'scale(1)' : 'scale(0)',
      transition: 'all 0.3s ease-out',
      border: inputsObject[prop]
        ? `1px solid ${customColors.borderBlue}`
        : 'none',
      icon: <CrosshairIcon color="custom.red.600" minW="unset" w="inherit" />,
      variant: 'gbaseOutline',
      bg: 'custom.lightGray'
    }
  }

  function returnDropdownStyles(prop) {
    const isEmpty = Object.keys(inputsObject[prop]).length === 0
    return {
      ml: !isEmpty ? '8px' : 0,
      w: !isEmpty ? '40px' : 0,
      minW: 'unset',
      onClick: setToEmpty(prop, {}),
      transform: !isEmpty ? 'scale(1)' : 'scale(0)',
      transition: 'all 0.3s ease-out',
      border: !isEmpty ? `1px solid ${customColors.borderBlue}` : 'none',
      icon: <CrosshairIcon color="custom.red.600" minW="unset" w="inherit" />,
      variant: 'gbaseOutline',
      bg: 'custom.lightGray'
    }
  }

  const data = players

  return (
    <Box
      p={{
        base: '16px 16px 80px',
        md: '24px 24px 80px',
        '2xl': '48px 48px 80px'
      }}
    >
      {isLargerThan768 && (
        <Text variant="boldTitle" mb={{ md: '24px', '2xl': '32px' }}>
          Players
        </Text>
      )}
      <Box p={{ base: '16px' }} bg="white" mb="2px">
        {!isLargerThan768 && <Text variant="boldTitle">Players</Text>}
        <Box
          d="flex"
          flexDir={{ base: 'column', md: 'row' }}
          flexWrap={{ base: 'wrap', '2xl': 'nowrap' }}
        >
          <InputGroup mb={{ base: '8px' }} mr={{ '2xl': '16px' }}>
            <Input
              variant="gbase"
              fontSize={{ base: '12px', '2xl': '14px' }}
              value={inputsObject.searchString}
              onChange={onChangeHandlerInputs('searchString')}
              placeholder="Firstname, lastname or phone"
            />
            <InputRightElement>
              <Icon as={SearchIcon} color="custom.gray.200" />
            </InputRightElement>
          </InputGroup>
          <Box
            d="grid"
            gridTemplateColumns={{ base: '1fr 1fr', md: 'repeat(4, 1fr)' }}
            gridColumnGap={{ base: '8px', md: '16px' }}
          >
            {isLargerThan768 && (
              <>
                <Button
                  variant="lightBlue"
                  w="100%"
                  onClick={searchEnableHandler}
                >
                  Filter
                </Button>
                <Button
                  variant="blue"
                  w="100%"
                  isDisabled={csvLoading}
                  onClick={exportCsv}
                >
                  Exp to CVS
                </Button>
              </>
            )}
            <Button
              variant={isInBlacklist ? 'blackChecked' : 'black'}
              onClick={blackListHandler}
              w="100%"
            >
              Blacklist
            </Button>
            <AddPlayerModal refetchFunc={refetchHandler} />
          </Box>
        </Box>
      </Box>
      {enableSearchModule && (
        <Box p={{ base: '16px' }} bg="white" mb="2px">
          <Text
            fontSize={{ base: '18px' }}
            fontWeight="500"
            color="custom.black.500"
            mb={{ base: '16px' }}
          >
            Search
          </Text>
          <Box
            d="grid"
            gridTemplateColumns={{
              base: 'repeat(3, 1fr)',
              '2xl': 'repeat(4, 1fr)'
            }}
            gridGap="16px"
            fontSize={{ base: '12px' }}
          >
            <Box d="flex">
              <InputGroup>
                <Input
                  variant="gbase"
                  placeholder="Created from"
                  fontSize="inherit"
                  value={showTime(inputsObject.createdFrom)}
                  isDisabled={true}
                />
                <InputRightElement h="100%">
                  <DatePickerModal
                    returnMilliseconds={true}
                    skipInitial={true}
                    cb={onChangeHandlerTime('createdFrom')}
                  />
                </InputRightElement>
              </InputGroup>
              <IconButton
                aria-label="reset-input"
                {...returnStyles('createdFrom')}
              />
            </Box>
            <Box d="flex">
              <InputGroup>
                <Input
                  variant="gbase"
                  placeholder="Created To"
                  fontSize="inherit"
                  value={showTime(inputsObject.createdTo)}
                  isDisabled={true}
                />
                <InputRightElement h="100%">
                  <DatePickerModal
                    returnMilliseconds={true}
                    skipInitial={true}
                    minDate={new Date(inputsObject.createdFrom)}
                    cb={onChangeHandlerTime('createdTo')}
                  />
                </InputRightElement>
              </InputGroup>
              <IconButton
                aria-label="reset-input"
                {...returnStyles('createdTo')}
              />
            </Box>
            <Box d="flex">
              <InputGroup>
                <Input
                  variant="gbase"
                  placeholder="Booked from"
                  fontSize="inherit"
                  value={showTime(inputsObject.bookedFrom)}
                  isDisabled={true}
                />
                <InputRightElement h="100%">
                  <DatePickerModal
                    returnMilliseconds={true}
                    skipInitial={true}
                    cb={onChangeHandlerTime('bookedFrom')}
                  />
                </InputRightElement>
              </InputGroup>
              <IconButton
                aria-label="reset-input"
                {...returnStyles('bookedFrom')}
              />
            </Box>
            <Box d="flex">
              <InputGroup>
                <Input
                  variant="gbase"
                  placeholder="Booked to"
                  fontSize="inherit"
                  value={showTime(inputsObject.bookedTo)}
                  isDisabled={true}
                />
                <InputRightElement h="100%">
                  <DatePickerModal
                    returnMilliseconds={true}
                    skipInitial={true}
                    minDate={new Date(inputsObject.bookedFrom)}
                    cb={onChangeHandlerTime('bookedTo')}
                  />
                </InputRightElement>
              </InputGroup>
              <IconButton
                aria-label="reset-input"
                {...returnStyles('bookedTo')}
              />
            </Box>
            <Box d="flex">
              <DropdownSelect
                selectProps={{
                  main: {
                    isDisabled: optionsLocation.length === 0,
                    options: optionsLocation,
                    placeholder:
                      optionsLocation.length === 0 ? 'Empty' : 'Location',
                    value:
                      Object.keys(inputsObject.location).length !== 0
                        ? inputsObject.location
                        : '',
                    onChange: onChangeHandlerCustom('location')
                  }
                }}
                chakraProps={{ w: '100%' }}
              />
              <IconButton
                aria-label="reset-input"
                {...returnDropdownStyles('location')}
              />
            </Box>
            <Box d="flex">
              <DropdownSelect
                selectProps={{
                  main: {
                    placeholder: 'Experience',
                    options: optionsExperience,
                    isDisabled: isLocationEmpty,
                    value:
                      Object.keys(inputsObject.experience).length !== 0
                        ? inputsObject.experience
                        : '',
                    onChange: onChangeHandlerCustom('experience')
                  }
                }}
                chakraProps={{
                  ...(isLocationEmpty && { opacity: 0.6 }),
                  w: '100%'
                }}
              />
              <IconButton
                aria-label="reset-input"
                {...returnDropdownStyles('experience')}
              />
            </Box>
            <Box d="flex">
              <DropdownSelect
                selectProps={{
                  main: {
                    options: optionsStatus,
                    placeholder: 'Today',
                    value:
                      Object.keys(inputsObject.status).length !== 0
                        ? inputsObject.status
                        : '',
                    onChange: onChangeHandlerCustom('status')
                  }
                }}
                chakraProps={{ w: '100%' }}
              />
              <IconButton
                aria-label="reset-input"
                {...returnDropdownStyles('status')}
              />
            </Box>
            <Box d="flex">
              <Input
                variant="gbase"
                placeholder="Players count"
                fontSize="inherit"
                value={inputsObject.playersCount}
                onChange={onChangeHandlerInputs('playersCount')}
              />
              <IconButton
                aria-label="reset-input"
                {...returnStyles('playersCount')}
              />
            </Box>
            <Box d="flex">
              <Input
                variant="gbase"
                placeholder="Name"
                fontSize="inherit"
                value={inputsObject.name}
                onChange={onChangeHandlerInputs('name')}
              />
              <IconButton aria-label="reset-input" {...returnStyles('name')} />
            </Box>
            <Box d="flex">
              <Input
                variant="gbase"
                placeholder="Phone"
                fontSize="inherit"
                value={inputsObject.phone}
                onChange={onChangeHandlerInputs('phone')}
              />
              <IconButton aria-label="reset-input" {...returnStyles('phone')} />
            </Box>
            <Box d="flex">
              <Input
                variant="gbase"
                placeholder="Email"
                fontSize="inherit"
                value={inputsObject.email}
                onChange={onChangeHandlerInputs('email')}
              />
              <IconButton aria-label="reset-input" {...returnStyles('email')} />
            </Box>
            <Box
              d="grid"
              gridTemplateColumns="1fr 1fr 1fr"
              gridColumnGap="16px"
            >
              <Button
                variant="lightBlue"
                w="100%"
                onClick={resetHandler}
                p="0 8px"
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxW="99%"
                >
                  Reset filters
                </Text>
              </Button>
              <Button
                variant="lightBlue"
                w="100%"
                onClick={searchEnableHandler}
              >
                Close
              </Button>
              <Button variant="blue" w="100%" onClick={searchByFilters}>
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {isLargerThan768 ? (
        <WithPagination totalCountItems={total} cb={setPaginationState}>
          <PlayersTable
            tableData={data}
            blacklist={blacklist}
            refetchFunction={refetchPlayer}
          />
        </WithPagination>
      ) : (
        <WithPagination totalCountItems={total} cb={setPaginationState}>
          <PlayersList
            data={data}
            blacklist={blacklist}
            refetchFunction={refetchPlayer}
          />
        </WithPagination>
      )}
    </Box>
  )
}
