import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import useInternetConnection from '../../../libs/hooks/useInternetConnection'

export default function InternetChecker() {
  const isOnline = useInternetConnection()
  const toast = useToast()
  const id = 'internet-connection'
  const [connectionWasLost, setConnectionWasLost] = useState(false)

  useEffect(() => {
    if (!isOnline) {
      setConnectionWasLost(true)
    }
  }, [isOnline])

  useEffect(() => {
    if (!isOnline && connectionWasLost) {
      if (!toast.isActive(id)) {
        toast({
          title: 'Internet connection lost.',
          status: 'warning',
          id,
          duration: 10000,
          isClosable: true,
          position: 'bottom-right'
        })
      } else {
        toast.update(id, {
          title: 'Internet connection lost.',
          status: 'warning'
        })
      }
    }
    if (isOnline && connectionWasLost) {
      if (!toast.isActive(id)) {
        toast({
          title: 'Internet connection restored.',
          status: 'success',
          id,
          duration: 2000,
          isClosable: true,
          position: 'bottom-right'
        })
      } else {
        toast.update(id, {
          title: 'Internet connection restored.',
          status: 'success'
        })
      }
      setConnectionWasLost(false)
    }
  }, [isOnline, connectionWasLost])

  return null
}
