import React, { useMemo } from 'react'

import { Box, Text, useTheme } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import {
  TABLE_COLUMN_VALUES_PROMOCODES_AFTER_1440,
  TABLE_COLUMN_VALUES_PROMOCODES_BEFORE_1440
} from '../../../../../configs'
import TableCreator from '../../../../Common/TableCreator'
import GBaseTag from '../../../../Common/GBaseTag'
import {
  TABLE_COLUMNS_SIZES_PROMOCODES_AFTER_1440,
  TABLE_COLUMNS_SIZES_PROMOCODES_BEFORE_1440
} from '../../../../../configs/tablesSizes'
import { matchMedia1440InState, PROMO_PAGE_URL } from '../../../../../constants'
import { useSelector } from 'react-redux'
import { friendlyUTCTime } from '../../../../../libs'
import { usePartners } from '../../../../../libs/hooks/usePartners'
import DeletePromocodeModal from '../../Modals/DeletePromocodeModal'

export default function PromocodesTable({ tableData = [] }) {
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const { data: { results: partners = [] } = {} } = usePartners()
  const partnersObject = useMemo(() => {
    const obj = {}
    if (partners?.length !== 0) {
      partners.forEach((partner) => {
        obj[partner.id] = partner.name
      })
    }
    return obj
  }, [partners])
  const data = useMemo(() => tableData, [tableData])

  const tableStyles = {
    thead: {
      fontWeight: 400,
      fontSize: { base: '12px', '2xl': '14px' },
      fontFamily: 'inherit'
    },
    th: {
      border: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.black.500',
      textAlign: 'center',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      height: 'inherit',
      padding: 0
    },
    tbody: {
      fontSize: { base: '12px', '2xl': '14px' }
    },
    td: {
      border: 'none',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      textTransform: 'capitalize',
      color: 'custom.gray.900',
      padding: '16px 10px',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    tr: {
      borderBottom: `2px solid ${customsColor.lightGray}`,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      bg: 'white',
      h: '56px'
    }
  }

  const columns = useMemo(() => {
    const columnValues = isLargerThan1440
      ? TABLE_COLUMN_VALUES_PROMOCODES_AFTER_1440
      : TABLE_COLUMN_VALUES_PROMOCODES_BEFORE_1440
    return columnValues.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor
      }

      if (field.Header === 'code') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          return (
            <Link to={`${PROMO_PAGE_URL}/${original.id}`}>
              <Text
                ml="auto"
                color="custom.blue.600"
                textDecoration="underline"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {original.code}
              </Text>
            </Link>
          )
        }
      }
      if (field.Header === 'experience') {
        fieldObject['Cell'] = ({ row: { original } }) => {
          const firstThreeElement = []
          const more = original?.experienceToShow?.length - 3
          const length =
            original?.experienceToShow?.length < 3
              ? original?.experienceToShow?.length
              : 3
          for (let i = 0; i < length; i++) {
            firstThreeElement.push(original.experienceToShow[i])
          }
          return (
            <Box d="flex" justifyContent="center">
              {firstThreeElement.length === 0 && <>—</>}
              {firstThreeElement.length !== 0 &&
                firstThreeElement?.map((exp, idx) => {
                  return (
                    <Box
                      bg="custom.blue.600"
                      borderRadius="4px"
                      d="flex"
                      w="32px"
                      h="32px"
                      key={Date.now() + 'experience' + 'promocodesTable' + idx}
                      justifyContent="center"
                      color="white"
                      fontSize="14px"
                      mr="8px"
                      alignItems="center"
                    >
                      {exp}
                    </Box>
                  )
                })}
              {more > 0 && (
                <Box
                  bg="custom.blue.200"
                  borderRadius="4px"
                  d="flex"
                  w="32px"
                  h="32px"
                  justifyContent="center"
                  color="custom.blue.600"
                  fontSize="14px"
                  mr="8px"
                  alignItems="center"
                >
                  +{more}
                </Box>
              )}
            </Box>
          )
        }
      }

      if (field.Header === 'created') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return <>{friendlyUTCTime(original.created, 'MM.DD.YY')}</>
        }
      }

      if (field.Header === 'partner') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return (
            <>{original.partner ? partnersObject[original.partner] : '—'}</>
          )
        }
      }

      if (field.Header === 'reason') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return <>{original.reason ? original.reason : '—'}</>
        }
      }

      if (field.Header === 'details') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          const discountDollar = original?.discount_cent
            ? `$${original?.discount_cent / 100}`
            : ''
          const discountPercent = original?.discount_percent
            ? `${original?.discount_percent}%`
            : ''
          const toShow =
            discountPercent || discountDollar
              ? discountPercent || discountDollar
              : '—'
          return <>{toShow}</>
        }
      }

      if (field.Header === 'status') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return (
            <GBaseTag
              fontSize="inherit"
              m="0 auto"
              color={original.status === 'Active' ? 'green' : 'red'}
              label={value}
            />
          )
        }
      }

      if (field.Accessor === 'funcColumn') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return (
            <DeletePromocodeModal
              promocodeId={original.id}
              promocodeName={original.code}
            />
          )
        }
      }
      return fieldObject
    })
  }, [isLargerThan1440, partners])
  return (
    <Box mb="60px">
      <TableCreator
        data={data}
        columns={columns}
        styles={tableStyles}
        sizesMap={
          isLargerThan1440
            ? TABLE_COLUMNS_SIZES_PROMOCODES_AFTER_1440
            : TABLE_COLUMNS_SIZES_PROMOCODES_BEFORE_1440
        }
      />
    </Box>
  )
}
