import * as yup from 'yup'

export const priceItemValidation = yup.object().shape({
  minPlayers: yup
    .number()
    .positive()
    .required()
    .integer()
    .min(yup.ref('minPlayersLimitation'))
    .max(yup.ref('maxPlayersLimitation'))
    .max(yup.ref('maxPlayers')),
  maxPlayers: yup
    .number()
    .positive()
    .required()
    .integer()
    .min(yup.ref('minPlayers'))
    .min(yup.ref('minPlayersLimitation'))
    .max(yup.ref('maxPlayersLimitation')),
  price: yup.number().positive().required()
})
