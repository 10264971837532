import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import Pictures from '../../Pictures'
import { loadImageToCloud } from '../../../../api/booking'
import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { convertByteToMb } from '../../../../libs'
import { useResendPictures } from '../../../../libs/hooks/useResendPictures'
import { MAXIMUM_IMAGE_SIZE, MAXIMUM_IMAGES_COUNT } from '../../../../configs'
import { BookingContext } from '../../../../pages/Booking'
import CustomWarningToast from '../../../Common/CustomWarningToast'
import Preloader from '../../../Common/Preloader'

export default function ResendPicturesModal() {
  const queryClient = useQueryClient()
  const [bookingData, _, filters, setFilters, queryKeysBooking] =
    useContext(BookingContext)
  const { id } = useParams()
  const toast = useToast()
  const [selectedImage, setSelectedImage] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoadingImages, setIsLoadingImages] = useState(false)
  const { mutate, isLoading: isLoadingResendPictures } = useResendPictures()

  function onSuccessHandler() {
    setIsLoadingImages(false)
    queryClient.invalidateQueries(queryKeysBooking).then(() => onClose())
  }

  function isLoading() {
    return isLoadingImages || isLoadingResendPictures
  }

  function onChangeFileHandler(e) {
    const images = Array.from(e.target.files)
    const allSizesSmallerRequired = images.some(
      (img) => img.size > MAXIMUM_IMAGE_SIZE
    )
    if (!allSizesSmallerRequired) {
      if (
        images.length <= MAXIMUM_IMAGES_COUNT &&
        images.length + selectedImage.length <= MAXIMUM_IMAGES_COUNT
      ) {
        if (selectedImage.length !== 0) {
          setSelectedImage([...selectedImage, ...images])
        } else {
          setSelectedImage(images)
        }
      } else {
        toast({
          status: 'warning',
          duration: 5000,
          isClosable: true,
          render: () => (
            <CustomWarningToast
              title="Warning"
              message={`You can load maximum ${MAXIMUM_IMAGES_COUNT} images.`}
              close={toast.closeAll}
            />
          )
        })
      }
    } else {
      toast({
        status: 'warning',
        duration: 5000,
        isClosable: true,
        render: () => (
          <CustomWarningToast
            title="Warning"
            message={`maximum image size must be
                                         less than ${convertByteToMb(
                                           MAXIMUM_IMAGE_SIZE
                                         )}mb.`}
            close={toast.closeAll}
          />
        )
      })
    }
  }

  function deleteImageHandler(idx) {
    const newArr = [...selectedImage]
    newArr.splice(idx, 1)
    setSelectedImage(newArr)
  }

  function onSubmit(values) {
    if (selectedImage) {
      setIsLoadingImages(true)
      loadImageToCloud(selectedImage)
        .then((r) => {
          return {
            bookingId: id,
            photos: r
          }
        })
        .then((obj) => {
          mutate(
            { obj: obj },
            {
              onSuccess: (data) => {
                queryClient.invalidateQueries(queryKeysBooking)
                onSuccessHandler()
                onClose()
              }
            }
          )
        })
    }
  }

  return (
    <>
      <Button onClick={onOpen} variant="blue">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          Resend Pictures
        </Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent maxW={{ base: '288px', md: '348px', '2xl': '427px' }}>
          <ModalHeader>Resend Pictures</ModalHeader>
          <ModalCloseButton as={CrosshairIcon} />
          <ModalBody>
            <Box
              mb={{ base: '16px' }}
              d="flex"
              flexDir={{ base: 'column', md: 'row' }}
              flexWrap="wrap"
            ></Box>
            <Box
              mb="12px"
              borderBottom="1px solid"
              borderColor={
                selectedImage.length !== 0 ? 'custom.gray.200' : 'transparent'
              }
            >
              <Pictures p="0" accordion={false} />
            </Box>
            <Formik
              initialValues={{
                file: ''
              }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values, handleSubmit, isValid, errors }) => {
                return (
                  <>
                    {selectedImage.length !== 0 && (
                      <Box
                        d={{ base: 'flex', '2xl': 'grid' }}
                        gridTemplateColumns={{ '2xl': '1fr 1fr' }}
                        gridColumnGap={{ '2xl': '16px' }}
                        gridRowGap={{ '2xl': '16px' }}
                        flexDir={{ base: 'column', '2xl': 'unset' }}
                        flexWrap="wrap"
                        mb={{ '2xl': '16px' }}
                      >
                        {selectedImage.map((img, idx) => (
                          <Box
                            w="fit-content"
                            maxW={{ base: '100%' }}
                            pos="relative"
                            mb={{ base: '16px', '2xl': 'unset' }}
                            key={'picturesFinishGame' + idx}
                          >
                            <IconButton
                              aria-label="copy"
                              pos="absolute"
                              right="8px"
                              top="8px"
                              bg="gray"
                              w="24px"
                              height="24px"
                              minH="unset"
                              minW="unset"
                              borderRadius="50%"
                              _focus={{ border: 'none' }}
                              _hover={{ bg: '' }}
                              icon={
                                <CrosshairIcon
                                  color="white"
                                  w="80%"
                                  height="50%"
                                />
                              }
                              onClick={() => deleteImageHandler(idx)}
                            />

                            <Image src={URL.createObjectURL(img)} />
                          </Box>
                        ))}
                      </Box>
                    )}
                    <Box d="flex">
                      <FormControl
                        height="40px"
                        w="100%"
                        mr={{ base: '4px', md: '8px' }}
                        bg="black"
                      >
                        <FormLabel
                          htmlFor="file-upload"
                          variant="lightBlue"
                          w="inherit"
                          fontWeight="500"
                          height="100%"
                          alignItems="center"
                          d="inline-flex"
                          justifyContent="center"
                          cursor="pointer"
                          _disabled={{
                            color: 'custom.gray.200',
                            cursor: 'no-drop'
                          }}
                          disabled={
                            !!(selectedImage.length >= 10 || isLoading())
                          }
                          m="0"
                          fontSize={{ base: '12px', '2xl': '14px' }}
                        >
                          Add Image
                        </FormLabel>
                        <Input
                          type="file"
                          id="file-upload"
                          disabled={
                            !!(selectedImage.length >= 10 || isLoading())
                          }
                          multiple
                          accept="image/jpeg, image/png"
                          onChange={(e) => {
                            onChangeFileHandler(e)
                          }}
                          max="5"
                          d="none"
                        />
                      </FormControl>
                      {isLoading() ? (
                        <Preloader m={0} height="40px" cyclic={true} />
                      ) : (
                        <Box w="100%" ml={{ base: '4px', md: '8px' }}>
                          <Button
                            variant="blue"
                            w="100%"
                            isDisabled={selectedImage.length === 0}
                            fontWeight="500"
                            fontSize={{ base: '12px', '2xl': '14px' }}
                            onClick={handleSubmit}
                          >
                            Resend
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </>
                )
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
