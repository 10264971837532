import * as yup from 'yup'

export const addPlayersValidation = yup.object().shape({
  sum: yup.string().required(),
  payData: yup.object().shape({
    offlineCard: yup.string().when('type', {
      is: 'online',
      then: yup.string().required()
    }),
    cvc: yup.string().when('type', {
      is: 'online',
      then: yup.string().required()
    }),
    expDate: yup.string().when('type', {
      is: 'online',
      then: yup.string().required()
    }),
    transaction: yup.string().when('type', {
      is: 'offline',
      then: yup.string().required()
    }),
    location: yup.object().when('type', {
      is: 'cash',
      then: yup.object().required()
    })
  })
})
