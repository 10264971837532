import { createIcon } from '@chakra-ui/icons'

export const DisketteIcon = createIcon({
  displayName: 'DisketteIcon',
  viewBox: '0 0 12 12',
  boxSize: '12',
  path: (
    <g fill="currentColor">
      <path d="M9.33333 0H0V12H12V2.66667L9.33333 0ZM6 10.6667C4.89333 10.6667 4 9.77333 4 8.66667C4 7.56 4.89333 6.66667 6 6.66667C7.10667 6.66667 8 7.56 8 8.66667C8 9.77333 7.10667 10.6667 6 10.6667ZM8 4H1.33333V1.33333H8V4Z" />
    </g>
  )
})
