import { api } from '../../../index'

export async function getWaivers() {
  const { data } = await api.get('/api/waivers/')
  return data
}

export async function getWaiver(waiverId) {
  const { data } = await api.get(`/api/waivers/${waiverId}`)
  return data
}

export async function patchWaiver({
  waiverId,
  blocks,
  created,
  code,
  heading,
  rules,
  parentRule,
  trackPlayers,
  requireAuthentication,
  unassigned,
  phone,
  phoneDraft,
  email,
  emailDraft,
  logo,
  logoDraft,
  address,
  addressDraft
}) {
  const prepare = {
    blocks: blocks,
    created: created,
    code: code,
    heading: heading,
    rules: rules,
    parent_rule: parentRule,
    track_players: trackPlayers,
    require_authentication: requireAuthentication,
    unassigned: unassigned,
    phone,
    address,
    logo,
    email,
    email_draft: emailDraft,
    phone_draft: phoneDraft,
    address_draft: addressDraft,
    logo_draft: logoDraft
  }
  const toSend = {}
  Object.keys(prepare).forEach((key) => {
    if (
      !!prepare[key] ||
      typeof prepare[key] === 'boolean' ||
      (key === 'logo' && prepare[key] === '') ||
      (key === 'logo_draft' && prepare[key] === '')
    ) {
      toSend[key] = prepare[key]
    }
  })
  const { data } = await api.patch(`/api/waivers/${waiverId}/`, toSend)
  return data
}

export async function deleteWaiver({ waiverId }) {
  const { data } = await api.delete(`/api/waivers/${waiverId}`)
  return data
}

export async function createWaiver({
  heading = '',
  rules = '',
  parentRule = '',
  blocks,
  locationId
}) {
  const { data } = await api.post('/api/waivers/', {
    heading: heading,
    rules: rules,
    parent_rule: parentRule,
    blocks: blocks,
    location: locationId
  })
  return data
}

export async function getWaiversblocks() {
  const { data } = await api.get('/api/waiversblocks/')
  return data
}

export async function createWaiversblock({
  checkouts,
  name,
  title,
  description,
  position,
  waiverId
}) {
  const { data } = await api.post('/api/waiversblocks/', {
    checkouts: checkouts,
    name: name,
    title: title,
    description: description,
    position: position,
    waiver: waiverId
  })
  return data
}

export async function getWaiversblock(waiverblockId) {
  const { data } = await api.get(`/api/waiversblocks/${waiverblockId}`)
  return data
}

export async function patchWaiverblock({
  waiverblockId,
  checkouts,
  name,
  title,
  description,
  position,
  waiverId
}) {
  const prepare = {
    checkouts: checkouts,
    name: name,
    title: title,
    description: description,
    position: position,
    waiver: waiverId
  }
  const toSend = {}
  Object.keys(prepare).forEach((key) => {
    if (!!prepare[key] || typeof prepare[key] === 'boolean') {
      toSend[key] = prepare[key]
    }
  })
  const { data } = await api.patch(
    `/api/waiversblocks/${waiverblockId}/`,
    toSend
  )
  return data
}

export async function deleteWaiverblock(waiverblockId) {
  const { data } = await api.delete(`/api/waiversblocks/${waiverblockId}/`)
  return data
}

export async function getWaivercheckouts() {
  const { data } = await api.get('/api/waiverscheckouts/')
  return data
}

export async function createWaivercheckouts({ text, position, waiverBlockId }) {
  const { data } = await api.post('/api/waiverscheckouts/', {
    text: text,
    position: position,
    block: waiverBlockId
  })
  return data
}

export async function getWaivercheckout({ waivercheckoutId }) {
  const { data } = await api.get(`/api/waiverscheckouts/${waivercheckoutId}/`)
  return data
}

export async function patchWaivercheckout({
  waivercheckoutId,
  text,
  position,
  block
}) {
  const prepare = {
    text: text,
    position: position,
    block: block
  }
  const toSend = {}
  Object.keys(prepare).forEach((key) => {
    if (!!prepare[key] || typeof prepare[key] === 'boolean') {
      toSend[key] = prepare[key]
    }
  })
  const { data } = await api.patch(
    `/api/waiverscheckouts/${waivercheckoutId}/`,
    toSend
  )
  return data
}

export async function deleteWaivercheckout({ waivercheckoutId }) {
  const { data } = await api.delete(
    `/api/waiverscheckouts/${waivercheckoutId}/`
  )
  return data
}

export async function getWaiversentitys({
  bookingId,
  ordering = 'id',
  currentPage = 1,
  itemsPerPage = 25
}) {
  const { data } = await api.get('/api/waiversentitys/', {
    params: {
      bookings: bookingId,
      ordering: ordering,
      page: currentPage,
      page_size: itemsPerPage
    }
  })
  return data
}

export async function copyWaiverToBooking({ bookingId, waiverId }) {
  const { data } = await api.get(
    `api/waiversentitys/${waiverId}/copy/${bookingId}/`
  )
  return data
}

export async function patchWaiversentitys({ bookings, waiversentitysId }) {
  const { data } = await api.patch(`/api/waiversentitys/${waiversentitysId}/`, {
    bookings: bookings
  })
  return data
}

export async function publishWaiver(waiverId) {
  const { data } = await api.post(`api/waivers/${waiverId}/publish/`)
  return data
}
