import { Box, IconButton, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setCurrentPage, setStatusFilter } from '../../../../actions'
import { ArrowIcon } from '../../../../assets/Icons/ArrowIcon'
import {
  friendlyTime,
  friendlyTimeWithTZ,
  getStringDate,
  isToday
} from '../../../../libs'
import {
  filtersInState,
  matchMedia768InState,
  minCalendarDayInState
} from '../../../../constants'
import DatePickerModal from '../../../Booking/Modals/DatePickerModal'

export default function DateBar({ isLoading = false }) {
  const dispatch = useDispatch()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const { status } = useSelector(filtersInState)
  const minCalendarDay = useSelector(minCalendarDayInState)
  const spiltDateHandler = minCalendarDay ? minCalendarDay.split('.') : null
  const timeHandler = new Date()
  const splitDate = useMemo(() => {
    if (spiltDateHandler === null) return null
    const [day, month, year] = spiltDateHandler
    return { day, month, year }
  }, [spiltDateHandler])
  const dateHandler =
    typeof status.value === 'number'
      ? status.value
      : splitDate
      ? new Date(
          +splitDate.year,
          +splitDate.month,
          +splitDate.day,
          timeHandler.getHours(),
          timeHandler.getMinutes()
        )
      : new Date()
  const selectedDay = status?.value === 'today' ? dateHandler : status?.value
  const itsTimeFormat =
    status?.value === 'today' || typeof status?.value === 'number'
  const toCompare =
    typeof selectedDay === 'number' ? new Date(selectedDay) : selectedDay
  const isTodayLoc =
    itsTimeFormat && getStringDate(toCompare) === minCalendarDay

  useEffect(() => {
    return () => dispatch(setStatusFilter({ value: 'today', label: 'Today' }))
  }, [])

  function onChangeStatus(status) {
    if (status) {
      dispatch(setStatusFilter(status))
      dispatch(setCurrentPage(1))
    }
  }

  function nextDay() {
    const day = new Date(selectedDay)
    const nextDay = day.setDate(day.getDate() + 1)
    const nextDayConstr = new Date(nextDay)
    onChangeStatus({
      value: nextDayConstr.valueOf(),
      label: friendlyTime(nextDayConstr, 'MM.DD.YY')
    })
  }

  function prevDay() {
    const day = new Date(selectedDay)
    const prevDay = day.setDate(day.getDate() - 1)
    const prevDayConstr = new Date(prevDay)
    onChangeStatus({
      value: prevDayConstr.valueOf(),
      label: friendlyTime(prevDayConstr, 'MM.DD.YY')
    })
  }

  function onChangeDate() {
    return (timestamp) => {
      if (isToday(timestamp)) {
        onChangeStatus({ value: 'today', label: 'Today' })
      } else {
        onChangeStatus({
          value: timestamp,
          label: friendlyTime(timestamp, 'MM.DD.YY')
        })
      }
    }
  }

  return (
    <Box
      h="56px"
      d="flex"
      justifyContent="center"
      alignItems="center"
      bg="white"
    >
      <IconButton
        aria-label="prevDay"
        variant="funcColumnIconButton"
        onClick={prevDay}
        isDisabled={isLoading}
        color="custom.blue.600"
        mr={{ md: 'auto', '2xl': '16px' }}
        _disabled={{
          color: 'custom.gray.200'
        }}
        icon={<ArrowIcon />}
      />
      <DatePickerModal
        selectedDate={selectedDay}
        cb={onChangeDate()}
        returnMilliseconds={true}
      >
        <Text
          color="custom.gray.900"
          fontSize={{ md: '14px', '2xl': '18px' }}
          fontWeight="500"
          minW="136px"
        >
          {isTodayLoc && 'Today'}&nbsp;
          {friendlyTimeWithTZ(selectedDay, 'MM.DD.YY')}
        </Text>
      </DatePickerModal>
      <IconButton
        aria-label="nextDay"
        variant="funcColumnIconButton"
        color="custom.blue.600"
        isDisabled={isLoading}
        ml={{ md: 'auto', '2xl': '16px' }}
        onClick={nextDay}
        icon={<ArrowIcon transform="rotate(180deg)" />}
      />
    </Box>
  )
}
