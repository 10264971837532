import {
  ADD_PREBOOKING,
  CHANGE_ETHERNET_STATUS,
  DELETE_ELEM_FROM_PREBOOKINGS,
  DELETE_REFRESH_TOKEN,
  EDIT_TOKEN,
  SET_AUTH_TOKEN,
  SET_BOOKINGS_SORT_LOCATION,
  SET_BOOKINGS_SORT_TIME,
  SET_BREAKPOINTS_STATE,
  SET_CURRENT_PAGE,
  SET_INITIAL_STATE,
  SET_ITEMS_PER_PAGE,
  SET_ITEMS_TOTAL_COUNT,
  SET_LOCATION_FILTERS_STATE,
  SET_MIN_CALENDAR_DAY,
  SET_PERMISSIONS,
  SET_REFRESH_TOKEN,
  SET_SELECTED_TAB_DISCOUNTS,
  SET_SELECTED_TAB_STATISTICS,
  SET_SELECTED_WAIVER,
  SET_SETTINGS_TAB,
  SET_STATUS_FILTERS_STATE,
  SET_TO_EMPTY,
  SET_USER,
  SIGN_OUT,
  TOGGLE_MENU
} from '../constants'

//app actions
export const toggleMenu = () => ({ type: TOGGLE_MENU })
export const setAuthToken = (payload) => ({ type: SET_AUTH_TOKEN, payload })
export const signOut = () => ({ type: SIGN_OUT })
export const editAuthToken = (payload) => ({ type: EDIT_TOKEN, payload })
export const setUser = (payload) => ({ type: SET_USER, payload })
export const setStatusFilter = (payload) => ({
  type: SET_STATUS_FILTERS_STATE,
  payload
})
export const setLocationFilter = (payload) => ({
  type: SET_LOCATION_FILTERS_STATE,
  payload
})
export const setLocationSort = (payload) => ({
  type: SET_BOOKINGS_SORT_LOCATION,
  payload
})
export const setTimeSort = (payload) => ({
  type: SET_BOOKINGS_SORT_TIME,
  payload
})
export const setCurrentPage = (payload) => ({
  type: SET_CURRENT_PAGE,
  payload
})
export const setItemsPerPage = (payload) => ({
  type: SET_ITEMS_PER_PAGE,
  payload
})
export const setItemsTotalCount = (payload) => ({
  type: SET_ITEMS_TOTAL_COUNT,
  payload
})
export const setSettingsTab = (payload) => ({
  type: SET_SETTINGS_TAB,
  payload
})
export const setRefreshToken = (payload) => ({
  type: SET_REFRESH_TOKEN,
  payload
})
export const deleteRefreshToken = () => ({ type: DELETE_REFRESH_TOKEN })
export const setSelectedTabStatistics = (payload) => ({
  type: SET_SELECTED_TAB_STATISTICS,
  payload
})
export const setSelectedTabDiscounts = (payload) => ({
  type: SET_SELECTED_TAB_DISCOUNTS,
  payload
})
export const setSelectedWaiver = (payload) => ({
  type: SET_SELECTED_WAIVER,
  payload
})
export const setPermissions = (payload) => ({ type: SET_PERMISSIONS, payload })
export const setMinCalendarDay = (payload) => ({
  type: SET_MIN_CALENDAR_DAY,
  payload
})
export const setInitialState = () => ({ type: SET_INITIAL_STATE })

//resolution watcher actions
export const setMatchMedia = (payload) => ({
  type: SET_BREAKPOINTS_STATE,
  payload
})

//newOrder actions
export const addPrebooking = (payload) => ({ type: ADD_PREBOOKING, payload })
export const deleteAllPrebookings = () => ({ type: SET_TO_EMPTY })
export const deleteElemFromPrebooking = (payload) => ({
  type: DELETE_ELEM_FROM_PREBOOKINGS,
  payload
})

//ethernet actions
export const changeOnlineStatus = (payload) => ({
  type: CHANGE_ETHERNET_STATUS,
  payload
})
