import Comment from '../Comment'

export default function CommentsList({ data = [] }) {
  return data?.map((comment, idx) => (
    <Comment
      text={comment?.text}
      date={comment?.created_at}
      name={comment?.created_by?.name}
      timezone={comment?.timezone}
      key={idx + 'commentsList'}
    />
  ))
}
