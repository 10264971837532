export default function ArrowRight() {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.29055 9.87998L5.17055 5.99998L1.29055 2.11998C0.900547 1.72998 0.900547 1.09998 1.29055 0.70998C1.68055 0.31998 2.31055 0.31998 2.70055 0.70998L7.29055 5.29998C7.68055 5.68998 7.68055 6.31998 7.29055 6.70998L2.70055 11.3C2.31055 11.69 1.68055 11.69 1.29055 11.3C0.910547 10.91 0.900547 10.27 1.29055 9.87998Z"
        fill="white"
      />
    </svg>
  )
}
