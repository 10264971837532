import TableCreatorBookings from './TableCreatorBookings'
import CardList from '../../Common/CardList'
import { useSelector } from 'react-redux'
import { matchMedia1440InState, matchMedia768InState } from '../../../constants'
import {
  TABLE_COLUMNS_SIZES_BOOKINGS_AFTER_1440,
  TABLE_COLUMNS_SIZES_BOOKINGS_BEFORE_1440
} from '../../../configs/tablesSizes'

export default function BookingTable({
  data = [],
  isLoading = false,
  bookingsQueryKeys
}) {
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  return (
    <>
      {isLargerThan768 ? (
        <TableCreatorBookings
          tableData={data}
          isLoading={isLoading}
          bookingsQueryKeys={bookingsQueryKeys}
          sizesMap={
            isLargerThan1440
              ? TABLE_COLUMNS_SIZES_BOOKINGS_AFTER_1440
              : TABLE_COLUMNS_SIZES_BOOKINGS_BEFORE_1440
          }
        />
      ) : (
        <CardList
          data={data}
          isLoading={isLoading}
          bookingsQueryKeys={bookingsQueryKeys}
        />
      )}
    </>
  )
}
