import { Box, Button, Text } from '@chakra-ui/react'
import ButtonsToggle from '@/components/Common/ButtonsToggle'
import { useDispatch, useSelector } from 'react-redux'
import {
  matchMedia768InState,
  STATISTICS_PRELOADER_TIME,
  statisticsTabInState
} from '../../constants'
import StatisticsMobile from '@/components/Statistics/StatisticsMobile'
import { switchTypeStatistics } from '../../configs'
import StatisticsDesktop from '@/components/Statistics/StatisticsDesktop'
import UpperModule from '@/components/Statistics/UpperModule'
import { setSelectedTabStatistics } from '../../actions'
import { useStatisticsPartners } from '../../libs/hooks/useStatiscticsPartners'
import { usePartners } from '../../libs/hooks/usePartners'
import { useEffect, useMemo, useState } from 'react'
import { useStatisticsLocation } from '../../libs/hooks/useStatisticsLocation'
import Preloader from '@/components/Common/Preloader'
import { useStatisticsRefresh } from '../../libs/hooks/useStatisticsRefresh'

export default function Statistics() {
  const dispatch = useDispatch()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const selectedStatisticsTab = useSelector(statisticsTabInState)
  const buttonsSwitchData = switchTypeStatistics
  const [timeObj, setTimeObj] = useState({
    from: '',
    to: ''
  })
  const {
    data: {
      results: statisticsLocations = [],
      company_avg: avg = 0,
      company_ll: ll = 0,
      company_played: played = 0,
      company_total: total = 0
    } = {},
    refetch: refetchStatLocation,
    isRefetching: isLoadingStatisticsLocations
  } = useStatisticsLocation(timeObj, {
    refetchOnWindowFocus: false,
    enabled: Object.values(timeObj).every((value) => !!value),
    retry: false
  })

  useEffect(() => {
    console.log('statisticsLocations', statisticsLocations)
  }, [statisticsLocations])

  const sample = {
    avg: 0,
    down: 0,
    event_id: 162323232232,
    id: 2833234242,
    ll: 0,
    name: '',
    played: 0,
    players_info: { 1: 0, 2: 0 },
    ps: 0,
    sched: 0,
    total: 0,
    waste: 0
  }

  const withTotalByAllLocations = useMemo(() => {
    const newStat = statisticsLocations.map((statLoc) => {
      const experiences = statLoc?.eventvariants_statistic
      const exps = experiences?.map((item, idx) => {
        if (!Array.isArray(item)) return item
        let startRowIdx = idx
        let rowSpanLength = item.length
        const { sched, down, ps, waste, played, ll } = item.reduce(
          (previousValue, currentValue) => {
            return {
              sched: previousValue.sched + currentValue.sched,
              down: previousValue.down + currentValue.down,
              ps: previousValue.ps + currentValue.ps,
              waste: previousValue.waste + currentValue.waste,
              played: previousValue.played + currentValue.played,
              ll: previousValue.ll + currentValue.ll
            }
          },
          { sched: 0, down: 0, ps: 0, waste: 0, played: 0, ll: 0 }
        )
        console.log('total ll', ll)
        const exp = item.map((item, idx) => {
          if (idx === 0) {
            return {
              ...item,
              startRowIdx,
              rowSpanLength,
              sched,
              down,
              ps,
              waste,
              played,
              ll: (ll / rowSpanLength).toFixed(2)
            }
          } else {
            return {
              ...item,
              sched: 'NOT_SHOW',
              down: 'NOT_SHOW',
              ps: 'NOT_SHOW',
              waste: 'NOT_SHOW',
              played: 'NOT_SHOW',
              ll: 'NOT_SHOW'
            }
          }
        })
        return exp
      })
      const flat = exps.flat(1)
      return {
        ...statLoc,
        eventvariants_statistic: flat
      }
    })
    console.log('newStat', newStat)
    if (newStat.length === 0) return []
    const obj = { total, played, ll, avg }
    return [
      ...newStat,
      { ...obj, name: 'total', eventvariants_statistic: [sample] }
    ]
  }, [statisticsLocations])

  const {
    data: { results: statisticsPartners = [] } = {},
    refetch: refetchStatPartners,
    isRefetching: isLoadingStatisticsPartners
  } = useStatisticsPartners(timeObj, {
    enabled: Object.values(timeObj).every((value) => !!value),
    retry: false
  })

  const [minTimePreloader, setMinTimePreloader] = useState(false)

  useEffect(() => {
    if (minTimePreloader) {
      const type = selectedStatisticsTab === 0 ? 'locations' : 'partners'
      setTimeout(() => {
        if (type === 'locations') {
          refetchStatLocation()
        }

        if (type === 'partners') {
          refetchStatPartners()
        }
        setMinTimePreloader(false)
      }, STATISTICS_PRELOADER_TIME)
    }
  }, [minTimePreloader])

  const isSomethingLoading =
    isLoadingStatisticsLocations ||
    isLoadingStatisticsPartners ||
    minTimePreloader

  useEffect(() => {
    if (Object.values(timeObj).every((value) => !!value)) {
      refetchStatPartners()
      refetchStatLocation()
    }
  }, [timeObj])

  const { data: { results: partners = [] } = {} } = usePartners()
  const partnersObj = useMemo(() => {
    const obj = {}
    if (partners.length !== 0) {
      partners.forEach((partner) => {
        obj[partner.id] = {
          ...partner
        }
      })
    }
    return obj
  }, [partners])

  const preparedStatisticsPartner = useMemo(() => {
    let arr = []
    if (
      statisticsPartners.length !== 0 &&
      Object.keys(partnersObj)?.length !== 0
    ) {
      arr = statisticsPartners.map((statPartner) => {
        return {
          ...statPartner,
          partner: partnersObj[statPartner.partner]
        }
      })
    }
    return arr
  }, [statisticsPartners, partnersObj])

  function onTimeObjectChange() {
    return (val) => {
      setTimeObj(val)
    }
  }

  function changeTabHandler() {
    return (val) => {
      dispatch(setSelectedTabStatistics(val))
    }
  }

  const { mutate: refresh } = useStatisticsRefresh()

  function onRefreshHandler() {
    setMinTimePreloader(true)
    const type = selectedStatisticsTab === 0 ? 'locations' : 'partners'
    refresh({ type })
  }

  const dataPartners = preparedStatisticsPartner

  return (
    <Box p={{ base: '16px', md: '32px 24px' }}>
      <Box maxWidth={{ '2xl': '1344px' }} m={{ '2xl': '0 auto' }}>
        <Box d={{ md: 'flex' }}>
          <Text
            color="custom.black.600"
            mb={{ base: '16px' }}
            fontSize={{ base: '24px' }}
            d={{ base: 'none', md: 'block' }}
            fontWeight="600"
          >
            Statistics
          </Text>
          {isLargerThan768 && (
            <>
              <ButtonsToggle
                data={buttonsSwitchData}
                cb={changeTabHandler()}
                returnIndex={true}
                skipInitial={true}
                initialState={buttonsSwitchData[selectedStatisticsTab]}
                w="200px"
                h="40px"
                ml="auto"
                d={{ base: 'none', md: 'grid' }}
              />
              <Button
                variant="blue"
                fontSize={{ base: '12px', '2xl': '14px' }}
                ml="16px"
                onClick={onRefreshHandler}
              >
                Refresh
              </Button>
            </>
          )}
        </Box>
        <UpperModule
          buttonsSwitchData={buttonsSwitchData}
          refresh={onRefreshHandler}
          cb={changeTabHandler()}
          timeCb={onTimeObjectChange()}
        />
        {isSomethingLoading ? (
          <Preloader cyclic={true} />
        ) : (
          <>
            {isLargerThan768 ? (
              <StatisticsDesktop
                type={buttonsSwitchData[selectedStatisticsTab]}
                buttonsSwitchData={buttonsSwitchData}
                statisticsLocations={withTotalByAllLocations}
                dataPartners={dataPartners}
              />
            ) : (
              <StatisticsMobile
                type={buttonsSwitchData[selectedStatisticsTab]}
                buttonsSwitchData={buttonsSwitchData}
                statisticsLocations={withTotalByAllLocations}
                dataPartners={dataPartners}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  )
}
