import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Img,
  Input,
  Text,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { BiSave } from 'react-icons/bi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'

import CustomTextarea from '@/components/Common/CustomTextarea'
import ExperienceApplyList from '@/components/Common/ExperienceApplyList'
import FormikTwoStyledInput from '@/components/Common/FormikComponents/FormikTwoStyledInput'

import { ComboEndpoint } from '@/api/combo'
import { ArrowIcon } from '@/assets/Icons/ArrowIcon'
import { CrosshairIcon } from '@/assets/Icons/CrosshairIcon'
import { PlusIcon } from '@/assets/Icons/PlusIcon'
import { MAXIMUM_IMAGE_SIZE } from '@/configs'

import { ComboPricingControl } from './ComboBasePrice'
import DeleteComboModal from './DeleteComboModal'

const comboAPI = ComboEndpoint()

export const comboSchema = yup.object().shape({
  title: yup.string().required()
})

export default function ComboDetailsPage() {
  const toast = useToast()
  const queryClient = useQueryClient()

  const history = useHistory()
  const { id } = useParams()
  const isNew = id === 'new'

  const {
    data: comboData,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['combo', id],
    queryFn() {
      return comboAPI.getSingle(id)
    },
    enabled: !isNew,
    retry: false
  })

  const [image, setImage] = useState(null)

  useEffect(() => {
    if (comboData?.image) setImage(comboData?.image)
  }, [comboData?.image])

  const { mutate: mutateCombo } = useMutation({
    mutationFn: async (data) => {
      const { image, ...body } = data

      const crudHandler = isNew
        ? () => comboAPI.createSingle(body)
        : () => comboAPI.updateSingle(id, body)

      const response = await crudHandler()

      if (image) {
        await comboAPI.putImage(response.id, {
          title: response.title,
          image: image === '_deleted_' ? null : image
        })
      }

      return response
    },
    onSuccess: async (response) => {
      toast({ title: 'Success!' })
      await queryClient.refetchQueries(['combo', response.id])
      history.replace(`/settings/combo/${response.id}`)
    }
  })

  const { mutate: deleteSingleCombo } = useMutation({
    mutationFn: comboAPI.deleteSingle,
    onSuccess() {
      queryClient.invalidateQueries(['combo'])
    }
  })
  const initialValues = {
    id: comboData?.id || '',
    title: comboData?.title || '',
    description: comboData?.description || '',
    terms_and_conditions: comboData?.terms_and_conditions || '',
    number_of_players: comboData?.number_of_players || 4,
    available_experiences: comboData?.available_experiences || [],
    base_price: comboData?.base_price || [],
    uses_available: comboData?.uses_available || 1,
    telegram_chat_id: comboData?.telegram_chat_id || ''
  }
  const formik = useFormik({
    initialValues,
    validationSchema: comboSchema,
    onSubmit: (data) => mutateCombo(data),
    enableReinitialize: true
  })

  async function handleDeleteImage() {
    setImage(null)
    await formik.setFieldValue('image', '_deleted_')
  }

  function onChangeFileHandler(type) {
    return (e) => {
      const image = e.target.files[0]
      const isImageSmaller = image.size < MAXIMUM_IMAGE_SIZE

      if (!isImageSmaller) {
        return console.error('Image size exceeds ', MAXIMUM_IMAGE_SIZE)
      }

      if (type === 'image') {
        setImage(URL.createObjectURL(image))
        formik.setFieldValue('image', image)
      }
    }
  }

  if (isNew) {
    return (
      <Box
        p={{ base: '24px 16px', md: '48px 24px', '2xl': '48px' }}
        maxW={{ base: 'unset', md: 'unset', '2xl': '1100px' }}
        m="0 auto"
      >
        {/* Header */}
        <Box as={'header'} d="flex" flexDir={{ base: 'column', md: 'row' }}>
          <Button
            variant="ghost"
            fontFamily="inherit"
            p="0"
            w={{ md: '100%', '2xl': '50%' }}
            justifyContent="start"
            mb="16px"
            onClick={() => history.push('/settings')}
          >
            <Text
              as={'h1'}
              fontSize={{ base: '24px', '2xl': '32px' }}
              fontWeight={{ base: '600' }}
              color="custom.black.500"
              lineHeight="164%"
            >
              <ArrowIcon
                w="auto"
                height="16px"
                mr="16px"
                mb="2px"
                color="custom.gray.200"
              />
              {'Create new Combo'}
            </Text>
          </Button>
          <Button
            variant="blue"
            fontWeight="500"
            fontSize={{ base: '12px', '2xl': '14px' }}
            w="100%"
            maxW={{ base: 'unset', md: '144px', '2xl': '176px' }}
            mb={{ base: '16px' }}
            ml={{ md: 'auto' }}
            display={{ base: 'inline-flex' }}
            onClick={formik.submitForm}
          >
            <PlusIcon mr="1" />
            {'Save'}
          </Button>
        </Box>

        {/* Content */}
        <FormikProvider value={formik}>
          <>
            <Box bg="white" mb="2px" p="4">
              <Box
                d="grid"
                gridGap="8px"
                gridTemplateColumns={{
                  base: '1fr 1fr',
                  md: 'repeat(4, 1fr)'
                }}
                fontSize={{ base: '12px', '2xl': '14px' }}
                mb="8px"
              >
                <Box
                  gridColumn={{ base: '1/3', md: '1/4' }}
                  d="grid"
                  gridTemplateColumns={{ base: '1fr', md: '1fr auto 1fr' }}
                  gridGap={{ base: '8px', md: '16px' }}
                >
                  <FormikTwoStyledInput
                    id="title"
                    isDisabled={false}
                    name="title"
                    placeholder="Combo Title"
                    gridColumn={{ base: '1/3', md: 'unset' }}
                  />
                  <FormikTwoStyledInput
                    id="uses_available"
                    name="uses_available"
                    placeholder="Combo uses"
                    gridColumn={{ base: '1/3', md: 'unset' }}
                  />
                  <FormikTwoStyledInput
                    id="telegram_chat_id"
                    name="telegram_chat_id"
                    placeholder="Telegram chat ID"
                    gridColumn={{ base: '1/3', md: 'unset' }}
                  />
                </Box>
              </Box>
              <Box mb={{ base: '16px', '2xl': '24px' }}>
                <Text
                  fontSize={{ base: '14px', '2xl': '18px' }}
                  fontWeight="500"
                  mt={{ base: '8px', '2xl': '24px' }}
                  mb={{ base: '8px', '2xl': '16px' }}
                >
                  Games available in combo
                </Text>
                <ExperienceApplyList
                  gridColumn={{ base: '1/4' }}
                  // initialState={eventvariants}
                  onChange={(selectedGamesId) => {
                    formik.setFieldValue(
                      'available_experiences',
                      selectedGamesId
                    )
                  }}
                  w="100%"
                  readOnly={false}
                  mb="16px"
                />
              </Box>
              <Text
                fontSize={{ base: '14px', '2xl': '18px' }}
                fontWeight="500"
                mb={{ base: '8px', '2xl': '16px' }}
              >
                Description
              </Text>
              <Box>
                <CustomTextarea
                  placeholder="Description"
                  label=""
                  editMode={true}
                  initialValue={formik.values.description}
                  cb={formik.handleChange('description')}
                />
              </Box>
              <Text
                fontSize={{ base: '14px', '2xl': '18px' }}
                fontWeight="500"
                mb={{ base: '8px', '2xl': '16px' }}
              >
                Terms & conditions:
              </Text>
              <Box>
                <CustomTextarea
                  placeholder="Terms & conditions"
                  label=""
                  editMode={true}
                  initialValue={formik.values.terms_and_conditions}
                  cb={formik.handleChange('terms_and_conditions')}
                />
              </Box>
              <Box>
                <Text
                  fontSize={{ base: '14px', '2xl': '18px' }}
                  fontWeight="500"
                  mb={{ base: '8px', '2xl': '16px' }}
                >
                  Image:
                </Text>
                {!image ? (
                  <FormControl
                    height="40px"
                    w="100%"
                    gridColumn={{ base: '1/3', md: '2/3' }}
                    mr={{ base: '4px', md: '8px' }}
                  >
                    <FormLabel
                      htmlFor="file-upload-image"
                      variant="lightBlue"
                      w="100%"
                      fontWeight="500"
                      height="100%"
                      alignItems="center"
                      d="inline-flex"
                      justifyContent="center"
                      cursor="pointer"
                      _disabled={{
                        color: 'custom.gray.200',
                        cursor: 'no-drop'
                      }}
                      m="0"
                      fontSize={{ base: '12px', '2xl': '14px' }}
                    >
                      <PlusIcon mr="8px" />
                      Image
                    </FormLabel>
                    <Input
                      type="file"
                      id="file-upload-image"
                      accept="image/jpeg, image/png"
                      onChange={onChangeFileHandler('image')}
                      d="none"
                    />
                  </FormControl>
                ) : (
                  <Box pos="relative" d="flex" m="0 auto" w="220px">
                    <IconButton
                      aria-label={'delete-photo'}
                      pos="absolute"
                      right="8px"
                      top="8px"
                      bg="gray"
                      w="24px"
                      height="24px"
                      minH="unset"
                      minW="unset"
                      border="1px solid white"
                      borderRadius="50%"
                      _focus={{ border: 'none' }}
                      _hover={{ bg: '' }}
                      onClick={handleDeleteImage}
                      icon={
                        <CrosshairIcon color="white" w="12px" height="12px" />
                      }
                    />
                    <Img src={image} maxW="200px" m="0 auto" />
                  </Box>
                )}
              </Box>
            </Box>
          </>
        </FormikProvider>
      </Box>
    )
  }

  if (isLoading) return null

  if (isError)
    return (
      <Box
        p={{ base: '24px 16px', md: '48px 24px', '2xl': '48px' }}
        maxW={{ base: 'unset', md: 'unset', '2xl': '1100px' }}
        m="0 auto"
      >
        {/* Header */}
        <Box as={'header'} d="flex" flexDir={{ base: 'column', md: 'row' }}>
          <Button
            variant="ghost"
            fontFamily="inherit"
            p="0"
            w={{ md: '100%', '2xl': '50%' }}
            justifyContent="start"
            mb="16px"
            onClick={() => history.push('/settings')}
          >
            <Text
              as={'h1'}
              fontSize={{ base: '24px', '2xl': '32px' }}
              fontWeight={{ base: '600' }}
              color="custom.black.500"
              lineHeight="164%"
            >
              <ArrowIcon
                w="auto"
                height="16px"
                mr="16px"
                mb="2px"
                color="custom.gray.200"
              />
              {'Error fetching Combo'}
            </Text>
          </Button>
        </Box>
      </Box>
    )

  return (
    <Box
      p={{ base: '24px 16px', md: '48px 24px', '2xl': '48px' }}
      maxW={{ base: 'unset', md: 'unset', '2xl': '1100px' }}
      m="0 auto"
    >
      {/* Header */}
      <Box as={'header'} d="flex" flexDir={{ base: 'column', md: 'row' }}>
        <Button
          variant="ghost"
          fontFamily="inherit"
          p="0"
          w={{ md: '100%', '2xl': '50%' }}
          justifyContent="start"
          mb="16px"
          onClick={() => history.push('/settings')}
        >
          <Text
            as={'h1'}
            fontSize={{ base: '24px', '2xl': '32px' }}
            fontWeight={{ base: '600' }}
            color="custom.black.500"
            lineHeight="164%"
          >
            <ArrowIcon
              w="auto"
              height="16px"
              mr="16px"
              mb="2px"
              color="custom.gray.200"
            />
            {comboData.title}
          </Text>
        </Button>
        <Button
          variant="blue"
          fontWeight="500"
          fontSize={{ base: '12px', '2xl': '14px' }}
          mb={{ base: '16px' }}
          ml={{ md: 'auto' }}
          mr={{ md: 2 }}
          display={{ base: 'inline-flex' }}
          onClick={formik.submitForm}
        >
          <BiSave className="mr-2" />
          Save
        </Button>
        <DeleteComboModal onDelete={() => deleteSingleCombo(id)} />
      </Box>

      {/* Content */}
      <FormikProvider value={formik}>
        <>
          <Box bg="white" mb="2px" p="4">
            {/* <PseudoInput
                name='id'
                placeholder='id'
                value={comboData.id}
                isDisabled={false}
                gridColumn={{ base: '1/3', md: 'unset' }}
              /> */}

            <Text
              fontSize={{ base: '14px', '2xl': '18px' }}
              fontWeight="500"
              mt={{ base: '8px', '2xl': '24px' }}
              mb={{ base: '8px', '2xl': '16px' }}
            >
              Info
            </Text>
            <Box
              gridColumn={{ base: '1/3', md: '1/3' }}
              d="grid"
              gridTemplateColumns={{ base: '1fr', md: '1fr 2fr 1fr' }}
              gridGap={{ base: '8px', md: '16px' }}
            >
              <FormikTwoStyledInput
                name="id"
                placeholder="id"
                value={comboData.id}
                isDisabled={true}
                gridColumn={{ base: '1/3', md: 'unset' }}
              />
              <FormikTwoStyledInput
                id="title"
                isDisabled={false}
                name="title"
                placeholder="Combo Title"
                gridColumn={{ base: '1/3', md: 'unset' }}
              />
              <FormikTwoStyledInput
                id="combo_usage"
                name="uses_available"
                placeholder="Combo uses"
                gridColumn={{ base: '1/3', md: '3/3' }}
              />
              <FormikTwoStyledInput
                id="telegram_chat_id"
                name="telegram_chat_id"
                placeholder="Telegram chat ID"
                gridColumn={{ base: '1/3', md: 'unset' }}
              />
            </Box>
            <Box mb={{ base: '16px', '2xl': '24px' }}>
              <Text
                fontSize={{ base: '14px', '2xl': '18px' }}
                fontWeight="500"
                mt={{ base: '8px', '2xl': '24px' }}
                mb={{ base: '8px', '2xl': '16px' }}
              >
                Games available in combo
              </Text>
              {comboData && (
                <ExperienceApplyList
                  gridColumn={{ base: '1/4' }}
                  initialState={comboData?.available_experiences}
                  onChange={(selectedGames) =>
                    formik.setFieldValue('available_experiences', selectedGames)
                  }
                  w="100%"
                  mb="16px"
                />
              )}
            </Box>
            <Text
              fontSize={{ base: '14px', '2xl': '18px' }}
              fontWeight="500"
              mb={{ base: '8px', '2xl': '16px' }}
            >
              Description
            </Text>
            <Box>
              <CustomTextarea
                placeholder="Description"
                label=""
                editMode={true}
                initialValue={comboData?.description}
                cb={formik.handleChange('description')}
              />
            </Box>
            <Text
              fontSize={{ base: '14px', '2xl': '18px' }}
              fontWeight="500"
              mb={{ base: '8px', '2xl': '16px' }}
            >
              Terms & conditions:
            </Text>
            <Box>
              <CustomTextarea
                placeholder="Terms & conditions"
                label=""
                editMode={true}
                initialValue={comboData?.terms_and_conditions}
                cb={formik.handleChange('terms_and_conditions')}
              />
            </Box>
            <Box>
              <Text
                fontSize={{ base: '14px', '2xl': '18px' }}
                fontWeight="500"
                mb={{ base: '8px', '2xl': '16px' }}
              >
                Image:
              </Text>
              {!image ? (
                <FormControl
                  height="40px"
                  w="100%"
                  gridColumn={{ base: '1/3', md: '2/3' }}
                  mr={{ base: '4px', md: '8px' }}
                >
                  <FormLabel
                    htmlFor="file-upload-image"
                    variant="lightBlue"
                    w="100%"
                    fontWeight="500"
                    height="100%"
                    alignItems="center"
                    d="inline-flex"
                    justifyContent="center"
                    cursor="pointer"
                    _disabled={{
                      color: 'custom.gray.200',
                      cursor: 'no-drop'
                    }}
                    m="0"
                    fontSize={{ base: '12px', '2xl': '14px' }}
                  >
                    <PlusIcon mr="8px" />
                    Image
                  </FormLabel>
                  <Input
                    type="file"
                    id="file-upload-image"
                    accept="image/jpeg, image/png"
                    onChange={onChangeFileHandler('image')}
                    d="none"
                  />
                </FormControl>
              ) : (
                <Box pos="relative" d="flex" m="0 auto" w="220px">
                  <IconButton
                    aria-label={'delete-photo'}
                    pos="absolute"
                    right="8px"
                    top="8px"
                    bg="gray"
                    w="24px"
                    height="24px"
                    minH="unset"
                    minW="unset"
                    border="1px solid white"
                    borderRadius="50%"
                    _focus={{ border: 'none' }}
                    _hover={{ bg: '' }}
                    onClick={handleDeleteImage}
                    icon={
                      <CrosshairIcon color="white" w="12px" height="12px" />
                    }
                  />
                  <Img src={image} maxW="200px" m="0 auto" />
                </Box>
              )}
            </Box>
          </Box>
        </>
      </FormikProvider>
      <ComboPricingControl id={id} prices={formik.values?.base_price} />
    </Box>
  )
}
