import CashListItem from '../CashListItem'

export default function CashList({
  data = [],
  timezone,
  invalidateCashString
}) {
  return (
    data.length !== 0 &&
    data.map((el, idx) => {
      return (
        <CashListItem
          key={el.id + el.location_name}
          element={el}
          timezone={timezone}
          invalidateCashString={invalidateCashString}
        />
      )
    })
  )
}
