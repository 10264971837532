import { Box, Text } from '@chakra-ui/react'
import { friendlyTime } from '../../../../libs'
import { useDowntimes } from '../../../../libs/hooks/useDowntimes'
import React, { useMemo } from 'react'
import { useExperiences } from '../../../../libs/hooks/useExperiences'
import { useSelector } from 'react-redux'
import {
  matchMedia1440InState,
  matchMedia768InState
} from '../../../../constants'
import EditDowntimeModal from '../../Modals/EditDowntimeModal'
import DeleteDowntimeModal from '../../Modals/DeleteDowntimeModal'

export default function DowntimeList() {
  const { data: downtimes = [] } = useDowntimes()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const { data: { results: experience = [] } = {} } = useExperiences()
  const experiencesObject = useMemo(() => {
    const obj = {}
    experience?.forEach((exp) => {
      const name = exp.name
      const nameLength = name.length
      let cutName = name
      if (nameLength > 12) {
        cutName = name.slice(0, 12) + '…'
      }
      obj[exp.id] = cutName
    })
    return obj
  }, [experience])

  return (
    <Box
      p="16px"
      bg="white"
      d={{ base: 'block', md: 'grid' }}
      gridTemplateColumns={{
        md: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)',
        '2xl': 'repeat(3, 1fr)',
        '4xl': 'repeat(4, 1fr)'
      }}
      gridGap={{ base: '8px', '2xl': '16px' }}
    >
      {downtimes
        ?.sort((a, b) => a.effective_date_from - b.effective_date_from)
        ?.map((el, idx) => {
          const expIds = el.apply_to
          const firstThreeElement = []
          const moreThan = isLargerThan1440 ? 8 : isLargerThan768 ? 5 : 4
          const more = expIds?.length - moreThan
          const length = expIds?.length < moreThan ? expIds?.length : moreThan
          for (let i = 0; i < length; i++) {
            firstThreeElement.push(expIds[i])
          }
          const effectiveDateFrom = friendlyTime(
            el.effective_date_from * 1000,
            'MM/DD/YY (h:mm A)'
          )
          const effectiveDateTo = friendlyTime(
            el.effective_date_to * 1000,
            'MM/DD/YY (h:mm A)'
          )
          const downtimeId = el.id
          return (
            <Box
              bg="custom.blue.600"
              key={'HolidaysList' + idx}
              color="white"
              d="flex"
              borderRadius="16px"
              height={{ base: 'auto', md: 'auto' }}
              minH={{ base: '80px', md: 'unset' }}
              justifyContent="flex-start"
              alignItems="center"
              p={{ base: '6px 12px', md: '2px 16px' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
              mb={{ base: '8px', md: 'unset' }}
            >
              <Box alignSelf="start">
                <Box mb="8px" d="flex" flexWrap="wrap" mt="10px">
                  <Text letterSpacing="-0.6px">{effectiveDateFrom}</Text>
                  <Text letterSpacing="-0.6px">&nbsp;-&nbsp;</Text>
                  <Text letterSpacing="-0.6px">{effectiveDateTo}</Text>
                </Box>

                <Box d="flex" flexWrap="wrap">
                  {firstThreeElement.length !== 0 &&
                    firstThreeElement?.map((exp, idx) => {
                      return (
                        <Box
                          bg="custom.blue.600"
                          borderRadius="4px"
                          d="flex"
                          h="32px"
                          fontWeight="500"
                          key={
                            Date.now() + 'experience' + 'promocodesTable' + idx
                          }
                          justifyContent="center"
                          color="white"
                          border="2px solid"
                          borderColor="white"
                          fontSize="14px"
                          p="2px 4px"
                          mr="4px"
                          mb="4px"
                          alignItems="center"
                        >
                          {experiencesObject[exp]}
                        </Box>
                      )
                    })}
                  {more > 0 && (
                    <Box
                      bg="custom.blue.200"
                      borderRadius="4px"
                      d="flex"
                      w="32px"
                      h="32px"
                      justifyContent="center"
                      color="custom.blue.600"
                      fontSize="14px"
                      mr="8px"
                      alignItems="center"
                    >
                      +{more}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box d="flex" ml="auto" alignSelf="start">
                <EditDowntimeModal downtimeId={downtimeId} />
                <DeleteDowntimeModal
                  downtimeId={downtimeId}
                  name={`${effectiveDateFrom} - ${effectiveDateTo}`}
                />
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}
