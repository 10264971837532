import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

export default function Modal({
  isOpen,
  setOpen,
  children,
  closeByOutsideClick = true,
  additionalClassName
}) {
  const [isOpenHandler, setIsOpenHandler] = useState(isOpen)

  function closeHandler() {
    if (closeByOutsideClick) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = '15px'
      setTimeout(() => setIsOpenHandler(true), 300)
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.paddingRight = 'initial'
      setTimeout(() => setIsOpenHandler(false), 300)
    }
  }, [isOpen])

  const animation = isOpen ? isOpen && isOpenHandler : isOpen

  const modal = (
    <div
      className={`modal ${animation ? 'active' : ''}
         ${additionalClassName ? additionalClassName : ''}`}
      onClick={closeHandler}
    >
      <div className="modal--filter" />
      <div className="modal--content">{children}</div>
    </div>
  )

  return (
    (isOpen || isOpenHandler) &&
    ReactDOM.createPortal(modal, document.querySelector('#root'))
  )
}
