import { useMutation, useQuery } from 'react-query'

import {
  applyTemplate,
  applyTemplateChanges,
  deleteTemplate,
  getNotificationTemplate,
  getNotificationTemplates,
  getNotificationTemplatesEvents,
  newNotificationTemplate,
  patchNotificationTemplate
} from '../../api/Settings/notification'

export function useNotificationTemplates(queryOption = {}) {
  return useQuery(['notificationTemplates'], () => getNotificationTemplates(), {
    ...queryOption
  })
}

export function useNotificationTemplatesEvents(queryParam, queryOption = {}) {
  return useQuery(
    ['notificationTemplatesEvents', queryParam],
    () => getNotificationTemplatesEvents(queryParam),
    {
      ...queryOption
    }
  )
}

export function useNotificationTemplate(
  notificationTemplateId,
  queryOption = {}
) {
  return useQuery(
    ['notificationTemplate', +notificationTemplateId],
    () => getNotificationTemplate(notificationTemplateId),
    {
      ...queryOption
    }
  )
}

export function usePatchNotificationTemplate(options = {}) {
  return useMutation(({ obj }) => patchNotificationTemplate(obj), {
    ...options
  })
}

export function useNewNotificationTemplate(options = {}) {
  return useMutation(({ obj }) => newNotificationTemplate(obj), {
    ...options
  })
}

export function useApplyTemplateChanges(options = {}) {
  return useMutation(({ obj }) => applyTemplateChanges(obj), {
    ...options
  })
}

export function useApplyTemplate(options = {}) {
  return useMutation(({ obj }) => applyTemplate(obj), {
    ...options
  })
}

export function useDeleteTemplate(options = {}) {
  return useMutation(({ obj }) => deleteTemplate(obj), {
    ...options
  })
}
