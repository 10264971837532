import { useQuery } from 'react-query'

import { getCityList } from '../../api/Settings/city'

export function useCities() {
  const { data } = useQuery('cities', () => getCityList(), {
    staleTime: Infinity
  })

  return data || []
}
