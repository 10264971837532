import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Input,
  Text
} from '@chakra-ui/react'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { PlusIcon } from '../../../assets/Icons/PlusIcon'
import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../../constants'
import { useContext, useMemo, useState } from 'react'
import { flatObject } from '../../../libs'
import Preloader from '../../Common/Preloader'
import CommentsList from './CommentsList'
import CommentsTable from './CommentsTable'
import { useNewComment } from '../../../libs/hooks/useNewComment'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { BookingContext } from '../../../pages/Booking'

export default function Comments({ ...rest }) {
  const queryClient = useQueryClient()
  const [bookingData = {}, isLoading, filters, setFilters, queryKeysBooking] =
    useContext(BookingContext)
  const comments = bookingData?.order?.comments || []
  const orderId = bookingData?.order?.id || null
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [commentText, setCommentText] = useState('')
  const { id } = useParams()
  const data = useMemo(() => {
    if (comments.length !== 0) {
      return comments.map((comment) => {
        const newObj = {
          ...flatObject(comment),
          created_at: comment.created_at
        }
        return newObj
      })
    } else {
      return []
    }
  }, [comments])

  const { mutate: addNewComment, isLoading: isCommentSending } = useNewComment()

  function sendCommentHandler() {
    const toSend = {
      orderId: orderId,
      text: commentText
    }
    addNewComment(
      {
        obj: toSend
      },
      {
        onSuccess: () => queryClient.invalidateQueries(queryKeysBooking)
      }
    )
  }

  return (
    <Accordion
      allowToggle
      defaultIndex={[0]}
      mb={{ base: '16px', '2xl': '32px' }}
      borderStyle="hidden"
      fontFamily="inherit"
      {...rest}
    >
      <AccordionItem borderStyle="hidden">
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton
                fontWeight="500"
                fontSize={{ base: '18px', '2xl': '24px' }}
                bg="white"
                p={{
                  base: isExpanded ? '16px 16px 0' : '16px',
                  '2xl': isExpanded ? '24px 24px 0' : '24px'
                }}
                _hover={{ bg: 'white' }}
                _focus={{ border: 'none' }}
                _active={{ bg: 'white' }}
              >
                <Box d="flex" w="100%" alignItems="center">
                  <Box flex="1" textAlign="left">
                    <Text>Comments</Text>
                  </Box>
                  <ArrowIcon
                    w="auto"
                    height="16px"
                    transition="all 0.2s ease-in"
                    transform={isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'}
                    color="custom.blue.600"
                  />
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel p="0">
              <Box
                bg="white"
                p={{ base: '16px', '2xl': '24px' }}
                mb="2px"
                fontSize={{ base: '12px', '2xl': '14px' }}
                d={{ base: 'block', md: 'flex' }}
              >
                <Input
                  placeholder="Write comment"
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  fontSize="inherit"
                  fontWeight="400"
                  p="0 16px"
                  w="100%"
                  mb="8px"
                  _placeholder={{
                    color: 'custom.gray.300'
                  }}
                  variant="gbase"
                />
                {isCommentSending ? (
                  <Preloader
                    w={{ base: '100%', md: 'auto' }}
                    m="0"
                    ml={{ base: 'unset', md: '8px', '2xl': '16px' }}
                    minW={{ base: '140px', '2xl': '160px' }}
                    height="40px"
                  />
                ) : (
                  <Button
                    variant="blue"
                    w={{ base: '100%', md: 'auto' }}
                    ml={{ base: 'unset', md: '8px', '2xl': '16px' }}
                    minW={{ base: '140px', '2xl': '160px' }}
                    fontWeight="500"
                    onClick={sendCommentHandler}
                  >
                    <PlusIcon
                      w={{ base: '12px', '2xl': '14px' }}
                      height={{ base: '12px', '2xl': '14px' }}
                      color="white"
                      mr="8px"
                    />
                    Add comment
                  </Button>
                )}
              </Box>
              {isLoading ? (
                <Box
                  bg="white"
                  height="56px"
                  p="10px"
                  d="flex"
                  alignItems="center"
                >
                  <Preloader m="0" />
                </Box>
              ) : (
                <>
                  {data.length === 0 ? (
                    <Box
                      bg="white"
                      height="56px"
                      p="10px"
                      d="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text fontSize={{ base: '12px', '2xl': '14px' }}>
                        No Comments.
                      </Text>
                    </Box>
                  ) : (
                    <>
                      {!isLargerThan768 && <CommentsList data={data} />}
                      {isLargerThan768 && <CommentsTable tableData={data} />}
                    </>
                  )}
                </>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
