import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { loadImageToCloudWithAssociate } from '../../../../../../../api/booking'
import { CrosshairIcon } from '../../../../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../../../../assets/Icons/DisketteIcon'
import { PlusIcon } from '../../../../../../../assets/Icons/PlusIcon'
import {
  convertByteToMb,
  generateWarningToast
} from '../../../../../../../libs'
import { useCreateAttractionsExperience } from '../../../../../../../libs/hooks/useCreateAttractionsExperience'
import { useExperiences } from '../../../../../../../libs/hooks/useExperiences'
import { MAXIMUM_IMAGE_SIZE } from '../../../../../../../configs'
import { newAttractionExperienceModalValidation } from '../../../../../../../configs/validation/newAttractionExperienceModalValidation'
import {
  matchMedia768InState,
  matchMedia1440InState,
  PERMISSION_EVENT,
  userInState
} from '../../../../../../../constants'
import CustomTextarea from '../../../../../../Common/CustomTextarea'
import FormikDropdown from '../../../../../../Common/FormikComponents/FormikDropdown'
import FormikTwoStyledInput from '../../../../../../Common/FormikComponents/FormikTwoStyledInput'
import PermissionRequired from '../../../../../../Common/PermissionRequired'
import Preloader from '../../../../../../Common/Preloader'
import PseudoInput from '../../../../../../Common/PseudoInput'

export default function NewAttractionExperienceModal({
  eventId,
  locationId,
  experiencesLength
}) {
  const toast = useToast()
  const errorMaximumImageSize = {
    id: 'experience-edit-error',
    text: `maximum image size must be less than ${convertByteToMb(
      MAXIMUM_IMAGE_SIZE
    )}mb.`
  }
  const attractionsExperiencePermission = PermissionRequired(
    PERMISSION_EVENT,
    true
  )
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const theme = useTheme()
  const customColor = theme.colors.custom
  const { mutate: createNew, isLoading: newInProcess } =
    useCreateAttractionsExperience()
  const [frame, setFrame] = useState(null)
  const [image, setImage] = useState(null)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const { data: { results: experience = [] } = {} } = useExperiences()
  const [isLoadingProcess, setIisLoadingProcess] = useState(false)
  const isLoading = isLoadingProcess || newInProcess
  const user = useSelector(userInState)
  const usersLocations = user?.locations
  const optionsExperience = useMemo(() => {
    if (experience?.length !== 0 && usersLocations.length !== 0) {
      const array = []
      experience.forEach((exp) => {
        if (usersLocations.includes(exp.location_id)) {
          array.push({ value: exp.id, label: exp.name })
        }
      })
      return [...array, { value: '', label: 'Unset' }]
    } else {
      return []
    }
  }, [experience, usersLocations])

  const optionsExperienceWithoutThis = useMemo(() => {
    return optionsExperience.filter((exp) => {
      const label = exp?.label
      if (label !== 'Unset') return true
    })
  }, [optionsExperience])

  const formik = useFormik({
    initialValues: {
      id: '',
      humanUsage: '',
      name: '',
      ticketMinPlayers: '',
      ticketEnabled: false,
      playersStep: '',
      areas: '',
      minPlayers: '',
      maxPlayers: '',
      flowsMinPlayers: '',
      flowsMaxPlayers: '',
      lockWith: { value: '', label: 'Unset' },
      termsAndConditions: '',
      photoFrame: '',
      image: '',
      cutOffTime: false,
      disableStatistic: false,
      combinedStatisticWith: [],
      changePriceOnRemovePlayers: false
    },
    validationSchema: newAttractionExperienceModalValidation,
    onSubmit: submitHandler
  })

  const formikValues = formik.values
  const setFieldValue = formik.setFieldValue
  const handleChange = formik.handleChange
  const handleSubmit = formik.handleSubmit
  console.log(formikValues.combinedStatisticWith)
  function onChangeFileHandler(type) {
    return (e) => {
      const image = e.target.files[0]
      const isImageSmaller = image.size < MAXIMUM_IMAGE_SIZE
      if (!isImageSmaller) {
        generateWarningToast({
          message: errorMaximumImageSize.text,
          id: errorMaximumImageSize.id
        })
        return
      }
      if (type === 'frame') {
        setFrame(image)
      }

      if (type === 'image') {
        setImage(image)
      }
    }
  }

  function deleteImageHandler() {
    return () => {
      setImage(null)
      setFieldValue('photoFrame', '')
    }
  }

  function submitHandler(values) {
    const toSend = {
      event: eventId,
      id: +values.id,
      name: values.name,
      humanUsage: +values.humanUsage,
      areas: +values.areas,
      minPlayers: +values.minPlayers,
      maxPlayers: +values.maxPlayers,
      flowMinPlayers: +values.flowsMinPlayers,
      flowMaxPlayers: +values.flowsMaxPlayers,
      playersStep: +values.playersStep,
      lockWith: values.lockWith.value ? [values.lockWith.value] : [],
      ticketsEnabled: +values.ticketEnabled,
      ticketsMinPlayers: +values.ticketsMinPlayers,
      termsAndConditions: values.termsAndConditions,
      location_id: locationId,
      combinedStatisticWith: values?.combinedStatisticWith?.map(
        (stat) => stat?.value
      ),
      disableStatistic: values?.disableStatistic,
      changePriceOnRemovePlayers: values?.changePriceOnRemovePlayers
    }

    if (frame || image) {
      setIisLoadingProcess(true)
      const images = []
      if (frame) images.push({ img: frame, label: 'frame' })
      if (image) images.push({ img: image, label: 'image' })
      loadImageToCloudWithAssociate(images)
        .then((r) => {
          const imagesObject = {}
          r.forEach((answer) => (imagesObject[answer.label] = answer.id))
          return {
            ...toSend,
            ...(imagesObject.hasOwnProperty('frame') && {
              photoFrame: imagesObject['frame']
            }),
            ...(imagesObject.hasOwnProperty('image') && {
              image: imagesObject['image']
            })
          }
        })
        .then((obj) => {
          createNew(
            {
              obj: obj
            },
            {
              onSuccess: () => {
                setIisLoadingProcess(false)
                setImage(null)
                queryClient.invalidateQueries(['experience'])
                queryClient.invalidateQueries(['event', +eventId]).then((r) => {
                  onClose()
                  if (experiencesLength > 1) {
                    document.querySelector(`#experienceButtons-0`).click()
                  }
                })
              },
              onError: (err) => {
                setIisLoadingProcess(false)
                const msg = err?.response?.data
                generateWarningToast({
                  msgObjects: msg,
                  toast,
                  id: 'new-experience-modal'
                })
              }
            }
          )
        })
    } else {
      createNew(
        {
          obj: { ...toSend }
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['experience'])
            queryClient.invalidateQueries(['event', +eventId])
            if (experiencesLength > 1) {
              document.querySelector(`#experienceButtons-0`).click()
            }
            onClose()
          },
          onError: (err) => {
            const msg = err?.response?.data
            generateWarningToast({
              msgObjects: msg,
              toast,
              id: 'new-experience-modal'
            })
          }
        }
      )
    }
  }

  return (
    <>
      <Button
        variant="blue"
        fontWeight="500"
        fontSize={{ base: '12px', '2xl': '14px' }}
        w="100%"
        maxW={{ base: 'unset', md: '144px', '2xl': '176px' }}
        mb={{ base: '16px' }}
        ml={{ md: 'auto' }}
        display={{ base: 'inline-flex' }}
        onClick={onOpen}
        isDisabled={!attractionsExperiencePermission}
      >
        <PlusIcon mr="8px" />
        New Experience
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
        >
          <FormikProvider value={formik}>
            <>
              <ModalHeader
                d="flex"
                flexDir={{ base: 'column', md: 'row' }}
                mb="34px"
                alignItems={{ base: 'unset', md: 'center' }}
              >
                <Text mb={{ base: '8px', md: 'unset' }}>New Experience</Text>
                <Box
                  d="grid"
                  gridTemplateColumns={{
                    base: '1fr 1fr',
                    md: 'repeat(4, 1fr)'
                  }}
                  gridGap="8px"
                  w={{ base: '100%', md: 'unset' }}
                  ml={{ base: 'unset', md: 'auto' }}
                >
                  {isLoading ? (
                    <Preloader
                      cyclic={true}
                      gridColumn={{ base: '1/3', md: '1/4' }}
                      w="250px"
                      m="0"
                      h="40px"
                    />
                  ) : (
                    <>
                      <FormControl
                        height="40px"
                        w="100%"
                        gridColumn={{ base: '1/3', md: '1/2' }}
                        mr={{ base: '4px', md: '8px' }}
                      >
                        <FormLabel
                          htmlFor="file-upload"
                          variant="lightBlue"
                          w="100%"
                          fontWeight="500"
                          height="100%"
                          alignItems="center"
                          d="inline-flex"
                          justifyContent="center"
                          cursor="pointer"
                          _disabled={{
                            color: 'custom.gray.200',
                            cursor: 'no-drop'
                          }}
                          m="0"
                          fontSize={{ base: '12px', '2xl': '14px' }}
                        >
                          <PlusIcon mr="8px" />
                          Frame
                        </FormLabel>
                        <Input
                          type="file"
                          id="file-upload"
                          accept="image/jpeg, image/png"
                          onChange={onChangeFileHandler('frame')}
                          d="none"
                        />
                      </FormControl>
                      <FormControl
                        height="40px"
                        w="100%"
                        gridColumn={{ base: '1/3', md: '2/3' }}
                        mr={{ base: '4px', md: '8px' }}
                      >
                        <FormLabel
                          htmlFor="file-upload-image"
                          variant="lightBlue"
                          w="100%"
                          fontWeight="500"
                          height="100%"
                          alignItems="center"
                          d="inline-flex"
                          justifyContent="center"
                          cursor="pointer"
                          _disabled={{
                            color: 'custom.gray.200',
                            cursor: 'no-drop'
                          }}
                          m="0"
                          fontSize={{ base: '12px', '2xl': '14px' }}
                        >
                          <PlusIcon mr="8px" />
                          Image
                        </FormLabel>
                        <Input
                          type="file"
                          id="file-upload-image"
                          accept="image/jpeg, image/png"
                          onChange={onChangeFileHandler('image')}
                          d="none"
                        />
                      </FormControl>
                      <Button
                        w="100%"
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        variant="blue"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        <DisketteIcon mr="8px" />
                        Save
                      </Button>
                      <Button
                        w="100%"
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        variant="red"
                        onClick={onClose}
                      >
                        <CrosshairIcon mr="8px" />
                        Cancel
                      </Button>
                    </>
                  )}
                </Box>
              </ModalHeader>
              <ModalCloseButton d={{ md: 'none' }} />
              <ModalBody m="0 0 16px" color="custom.black.500">
                <Box bg="white" mb="2px">
                  <Box
                    d="grid"
                    gridGap="8px"
                    gridTemplateColumns={{
                      base: '1fr 1fr',
                      md: 'repeat(4, 1fr)'
                    }}
                    fontSize={{ base: '12px', '2xl': '14px' }}
                    mb="8px"
                  >
                    <FormikTwoStyledInput
                      id="id"
                      name="id"
                      placeholder="ID"
                      gridColumn={{ base: '1/3', md: 'unset' }}
                    />
                    <FormikTwoStyledInput
                      id="name"
                      name="name"
                      placeholder="Name"
                      gridColumn={{ base: '1/3', md: 'unset' }}
                    />
                    <FormikTwoStyledInput
                      id="humanUsage"
                      name="humanUsage"
                      placeholder="Admin usage"
                    />
                    <FormikTwoStyledInput
                      id="areas"
                      name="areas"
                      placeholder="Areas"
                    />
                    <FormikTwoStyledInput
                      id="minPlayers"
                      name="minPlayers"
                      placeholder="Min players"
                    />
                    <FormikTwoStyledInput
                      id="maxPlayers"
                      name="maxPlayers"
                      placeholder="Max players"
                    />
                    <FormikTwoStyledInput
                      id="flowsMinPlayers"
                      name="flowsMinPlayers"
                      placeholder="Min players flow"
                    />
                    <FormikTwoStyledInput
                      id="flowsMaxPlayers"
                      name="flowsMaxPlayers"
                      placeholder="Max players flow"
                    />
                    <FormikTwoStyledInput
                      id="playersStep"
                      name="playersStep"
                      placeholder="Players step"
                    />
                    <FormikDropdown
                      main={{
                        options: optionsExperience,
                        placeholder: 'Unset'
                      }}
                      formik={{
                        name: 'lockWith',
                        id: 'lockWith'
                      }}
                      label={isLargerThan1440 ? 'Lock with' : ''}
                      selectProps={{
                        styles: {
                          control: {
                            height: isLargerThan1440 ? '56px' : '40px',
                            padding: isLargerThan1440 ? '12px 4px 0' : '0',
                            border: !isLargerThan768 && 'none'
                          }
                        }
                      }}
                    />
                    <InputGroup gridColumn={{ base: '1/3', md: 'unset' }}>
                      <PseudoInput
                        pos="relative"
                        w="100%"
                        placeholder="Tickets"
                        paddingRight="54px"
                        h={{ md: '40px', '2xl': '56px' }}
                        border={
                          isLargerThan768
                            ? `1px solid ${customColor.borderBlue}`
                            : 'none'
                        }
                      />
                      <InputRightElement
                        right="12px"
                        d="flex"
                        alignItems="center"
                        h="100%"
                      >
                        <Switch
                          size="md"
                          isChecked={formikValues.ticketEnabled}
                          onChange={handleChange('ticketEnabled')}
                        />
                      </InputRightElement>
                    </InputGroup>

                    <FormikTwoStyledInput
                      id="ticketMinPlayers"
                      name="ticketMinPlayers"
                      placeholder="Tickets min players"
                      gridColumn={{ base: '1/3', md: 'unset' }}
                    />
                    <InputGroup gridColumn={{ base: '1/3', md: 'unset' }}>
                      <PseudoInput
                        pos="relative"
                        w="100%"
                        placeholder="Exclude statistics"
                        paddingRight="54px"
                        h={{ md: '40px', '2xl': '56px' }}
                        border={
                          isLargerThan768
                            ? `1px solid ${customColor.borderBlue}`
                            : 'none'
                        }
                      />
                      <InputRightElement
                        right="12px"
                        d="flex"
                        alignItems="center"
                        h="100%"
                      >
                        <Switch
                          size="md"
                          isChecked={formikValues.disableStatistic}
                          onChange={handleChange('disableStatistic')}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <InputGroup gridColumn={{ base: '1/3', md: 'unset' }}>
                      <PseudoInput
                        pos="relative"
                        w="100%"
                        placeholder="Change price after remove players"
                        lineHeight="14px"
                        p={{
                          base: '8px 18.5px 8px 8px',
                          md: '4px 18.5px 4px 4px',
                          '2xl': '8px 18.5px 8px 8px'
                        }}
                        fontSize={{ base: '11px', '2xl': '13px' }}
                        paddingRight="54px"
                        h={{ md: '40px', '2xl': '56px' }}
                        border={
                          isLargerThan768
                            ? `1px solid ${customColor.borderBlue}`
                            : 'none'
                        }
                      />
                      <InputRightElement
                        right="12px"
                        d="flex"
                        alignItems="center"
                        h="100%"
                      >
                        <Switch
                          size="md"
                          isChecked={formikValues.changePriceOnRemovePlayers}
                          onChange={handleChange('changePriceOnRemovePlayers')}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                  <Box mb={{ base: '16px', '2xl': '24px' }}>
                    <FormikDropdown
                      main={{
                        options: optionsExperienceWithoutThis,
                        placeholder: isLargerThan1440
                          ? ''
                          : 'Combine statistics with',
                        isMulti: true
                      }}
                      formik={{
                        name: 'combinedStatisticWith',
                        id: 'combinedStatisticWith'
                      }}
                      label={isLargerThan1440 ? 'Combine statistics with' : ''}
                      selectProps={{
                        styles: {
                          control: {
                            minHeight: isLargerThan1440 ? '56px' : '40px',
                            height: 'auto',
                            padding: isLargerThan1440 ? '16px 4px 8px' : '0',
                            border: !isLargerThan768 && 'none'
                          }
                        }
                      }}
                    />
                  </Box>
                  <Text
                    fontSize={{ base: '14px', '2xl': '18px' }}
                    fontWeight="500"
                    mb={{ base: '8px', '2xl': '16px' }}
                  >
                    Terms & conditions:
                  </Text>
                  <Box>
                    <CustomTextarea
                      placeholder="Terms & conditions"
                      label=""
                      editMode={true}
                      initialValue={formikValues.termsAndConditions}
                      cb={handleChange('termsAndConditions')}
                    />
                  </Box>
                  {(formikValues.photoFrame || frame) && (
                    <Box>
                      <Text
                        fontSize={{ base: '14px', '2xl': '18px' }}
                        fontWeight="500"
                        mb={{ base: '8px', '2xl': '16px' }}
                      >
                        Frame:
                      </Text>
                      <Box pos="relative" d="flex" m="0 auto" w="220px">
                        <IconButton
                          aria-label={'delete-photo'}
                          pos="absolute"
                          right="8px"
                          top="8px"
                          bg="gray"
                          w="24px"
                          height="24px"
                          minH="unset"
                          minW="unset"
                          border="1px solid white"
                          borderRadius="50%"
                          _focus={{ border: 'none' }}
                          _hover={{ bg: '' }}
                          onClick={deleteImageHandler(setFieldValue, 'frame')}
                          icon={
                            <CrosshairIcon
                              color="white"
                              w="12px"
                              height="12px"
                            />
                          }
                        />
                        <Img
                          src={
                            frame === null
                              ? formikValues.photoFrame
                              : URL.createObjectURL(frame)
                          }
                          maxW="200px"
                          m="0 auto"
                        />
                      </Box>
                    </Box>
                  )}

                  {(formikValues.image || image) && (
                    <Box>
                      <Text
                        fontSize={{ base: '14px', '2xl': '18px' }}
                        fontWeight="500"
                        mb={{ base: '8px', '2xl': '16px' }}
                      >
                        Image:
                      </Text>
                      <Box pos="relative" d="flex" m="0 auto" w="220px">
                        <IconButton
                          aria-label={'delete-photo'}
                          pos="absolute"
                          right="8px"
                          top="8px"
                          bg="gray"
                          w="24px"
                          height="24px"
                          minH="unset"
                          minW="unset"
                          border="1px solid white"
                          borderRadius="50%"
                          _focus={{ border: 'none' }}
                          _hover={{ bg: '' }}
                          onClick={deleteImageHandler(setFieldValue, 'image')}
                          icon={
                            <CrosshairIcon
                              color="white"
                              w="12px"
                              height="12px"
                            />
                          }
                        />
                        <Img
                          src={
                            image === null
                              ? formikValues.image
                              : URL.createObjectURL(image)
                          }
                          maxW="200px"
                          m="0 auto"
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </ModalBody>
            </>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  )
}
