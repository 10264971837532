import { Box, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import ButtonsToggle from '@/components/Common/ButtonsToggle'
import { H1 } from '@/components/Text/Heading'

export default function TitleModule({
  title,
  setType: onPageTypeChange = null,
  type
}) {
  return (
    <Box d="flex" w="100%" justifyContent="space-between" mb="32px">
      <Box d="flex" alignItems={'baseline'}>
        <H1>{title}</H1>

        <Link
          color={'custom.blue.600'}
          fontSize={24}
          ml="3"
          to={'/combos'}
          as={RouterLink}
        >
          Combos
        </Link>
        <Link
          color={'custom.blue.600'}
          fontSize={24}
          ml="3"
          to={'/tickets'}
          as={RouterLink}
        >
          Tickets
        </Link>
      </Box>
      <ButtonsToggle
        data={['table', 'calendar']}
        useExternalControl={true}
        initialState={type}
        w="224px"
        cb={onPageTypeChange}
      />
    </Box>
  )
}
