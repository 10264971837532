import { useMutation } from 'react-query'

import { deleteBooking } from '../../api/bookings'

export function useDeleteBooking(options = {}) {
  return useMutation(
    ({ bookingId }) => {
      return deleteBooking(bookingId)
    },
    {
      ...options
    }
  )
}
