import { Box, Text } from '@chakra-ui/react'
import DataRow from '../../../Common/DataRow'

export default function StatisticsQuestItem({ item }) {
  const { name = '', ll = '', total = '', avg = '', played = '' } = item
  const totalInDollars = Math.round(total / 100)
  const avgInDollars = Math.round(avg / 100)
  return (
    <Box p="16px" bg="white" mb="2px">
      <Box d="flex" alignItems="center" mb="4px">
        <Text color="custom.black.500" fontWeight="500" mr="8px">
          Experience:
        </Text>
        <Text color="custom.blue.600">{name}</Text>
      </Box>
      <Box d="flex">
        {ll !== 'NOT_SHOW' && (
          <DataRow
            w="auto"
            mr="16px"
            title="LL"
            value={ll}
            keyStyle={{ mr: '8px' }}
          />
        )}
        <DataRow
          w="auto"
          mr="16px"
          title="Total"
          value={totalInDollars}
          keyStyle={{ mr: '8px' }}
        />
        <DataRow
          w="auto"
          mr="16px"
          title="Avg"
          value={avgInDollars}
          keyStyle={{ mr: '8px' }}
        />
        {played !== 'NOT_SHOW' && (
          <DataRow
            w="auto"
            mr="16px"
            title="Played"
            value={played}
            keyStyle={{ mr: '8px' }}
          />
        )}
      </Box>
    </Box>
  )
}
