import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { CrosshairIcon } from '../../../../assets/Icons/CrosshairIcon'
import { DisketteIcon } from '../../../../assets/Icons/DisketteIcon'
import { generateWarningToast } from '../../../../libs'
import { useLocations } from '../../../../libs/hooks/useLocations'
import { useNewCash } from '../../../../libs/hooks/useNewCash'
import { newCashModalValidation } from '../../../../configs/validation/newCashModalValidation'
import {
  matchMedia1440InState,
  PERMISSION_CASH_EDIT,
  userInState
} from '../../../../constants'
import CustomTextarea from '../../../Common/CustomTextarea'
import FormikDropdown from '../../../Common/FormikComponents/FormikDropdown'
import FormikTwoStyledInput from '../../../Common/FormikComponents/FormikTwoStyledInput'
import PermissionRequired from '../../../Common/PermissionRequired'
import Preloader from '../../../Common/Preloader'
import SearchBookingGiftcard from '../../../Common/SearchBookingGiftcard'

export default function AddCashModal({ invalidateCashString }) {
  const toast = useToast()
  const theme = useTheme()
  const cashEditPermission = PermissionRequired(PERMISSION_CASH_EDIT, true)
  const customColor = theme.colors.custom
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const [isLoadingProcess, setIisLoadingProcess] = useState(false)
  const [searchObject, setSearchObject] = useState(null)
  const user = useSelector(userInState)
  const isLoading = isLoadingProcess
  const { mutate: addNewCash } = useNewCash()
  const { data: { results: locations = [] } = {} } = useLocations()
  const optionsLocation = useMemo(() => {
    if (locations.length !== 0) {
      return locations.map((location) => {
        return {
          value: location.id,
          label: location.name
        }
      })
    } else return []
  }, [locations])

  const formik = useFormik({
    initialValues: {
      code: '—',
      date: '—',
      user: user?.email,
      client: '—',
      comment: '',
      sum: '',
      location: optionsLocation[0],
      label: 'Booking/giftcard'
    },
    enableReinitialize: true,
    validationSchema: newCashModalValidation,
    onSubmit: submitHandler
  })

  const handleSubmit = formik.handleSubmit
  const formikValues = formik.values
  const handleChange = formik.handleChange
  const setFieldValue = formik.setFieldValue
  const resetForm = formik.resetForm

  useEffect(() => {
    if (searchObject) {
      const {
        name = '—',
        code = '—',
        email = '—',
        type = '',
        locationId = ''
      } = searchObject
      setFieldValue('client', email)
      setFieldValue('code', code)
      setFieldValue('label', type)
      if (locationId) {
        const selectedLocation = optionsLocation.find(
          (loc) => loc.value === locationId
        )
        setFieldValue('location', selectedLocation)
      }
    }
  }, [searchObject])

  function submitHandler(values) {
    const label = values?.label
    const toSend = {
      ...values,
      locationId: values?.location?.value,
      ...(values?.client !== '—' &&
        values?.client !== '' && { clientEmail: values?.client }),
      amount: values?.sum,
      ...(label === 'booking' && { bookingCode: values?.code }),
      ...(label === 'giftcard' && { giftcardCode: values?.code })
    }

    addNewCash(toSend, {
      onSuccess: () => {
        queryClient.invalidateQueries(invalidateCashString).then(() => {
          resetForm()
          onClose()
        })
      },
      onError: (err) => {
        const msg = err?.response?.data
        generateWarningToast({
          msgObjects: msg,
          toast,
          id: 'edit-experience-modal'
        })
      }
    })
  }

  function onCloseHandler() {
    resetForm()
    onClose()
  }
  return (
    <>
      <Button
        w={{ base: '100%', md: '20.4%' }}
        variant="gbase"
        fontSize="inherit"
        ml={{ base: '0', md: 'auto' }}
        fontWeight="500"
        onClick={onOpen}
        isDisabled={!cashEditPermission}
      >
        <Icon as={AddIcon} mr="8px" />
        Add cash
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        variant="gbase"
      >
        <ModalOverlay />
        <ModalContent
          variant="gbase"
          w="100%"
          maxW={{ base: '288px', md: '720px', '2xl': '1100px' }}
        >
          <FormikProvider value={formik}>
            <>
              <ModalHeader
                d="flex"
                flexDir={{ base: 'column', md: 'row' }}
                mb="34px"
                alignItems={{ base: 'unset', md: 'center' }}
              >
                <Text mb={{ base: '8px', md: 'unset' }}>Add Cash</Text>
                <Box
                  d="grid"
                  gridTemplateColumns={{ base: '1fr 1fr', md: '1fr 1fr' }}
                  gridGap="8px"
                  w={{ base: '100%', md: 'unset' }}
                  ml={{ base: 'unset', md: 'auto' }}
                >
                  {isLoading ? (
                    <Preloader
                      cyclic={true}
                      gridColumn={{ base: '1/3', md: '1/4' }}
                      w="250px"
                      m="0"
                      h="40px"
                    />
                  ) : (
                    <>
                      <Button
                        w="100%"
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        variant="blue"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        <DisketteIcon mr="8px" />
                        Save
                      </Button>
                      <Button
                        w="100%"
                        fontSize={{ base: '12px', '2xl': '14px' }}
                        variant="red"
                        onClick={onCloseHandler}
                      >
                        <CrosshairIcon mr="8px" />
                        Cancel
                      </Button>
                    </>
                  )}
                </Box>
              </ModalHeader>
              <ModalCloseButton d={{ md: 'none' }} />
              <ModalBody m="0 0 16px" color="custom.black.500">
                <Box bg="white" mb="2px">
                  <Box
                    d="grid"
                    gridGap="8px"
                    gridTemplateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                    fontSize={{ base: '12px', '2xl': '14px' }}
                    mb={{ base: '16px', '2xl': '24px' }}
                  >
                    <SearchBookingGiftcard
                      gridColumn={{ base: 'unset', md: '1/4' }}
                      cb={setSearchObject}
                      resetCb={resetForm}
                    />
                    <FormikTwoStyledInput
                      name="code"
                      placeholder={formikValues.label}
                      isDisabled={true}
                      gridColumn={{ md: 'unset' }}
                    />
                    <FormikTwoStyledInput
                      name="user"
                      placeholder="User"
                      isDisabled={true}
                      gridColumn={{ md: 'unset' }}
                    />
                    <FormikTwoStyledInput
                      name="client"
                      isDisabled={true}
                      placeholder="Client"
                    />
                    <FormikTwoStyledInput name="sum" placeholder="Sum" />
                    <FormikDropdown
                      main={{
                        options: optionsLocation,
                        placeholder: isLargerThan1440 ? '' : 'Locations'
                      }}
                      formik={{
                        name: 'location',
                        id: 'location'
                      }}
                      isDisabled={formikValues.label === 'booking'}
                      label={
                        isLargerThan1440
                          ? formikValues.location
                            ? ''
                            : 'Locations'
                          : ''
                      }
                      selectProps={{
                        styles: {
                          control: {
                            height: isLargerThan1440 ? '56px' : '40px'
                          }
                        }
                      }}
                      chakraProps={{
                        h: '100%',
                        fontSize: 'inherit'
                      }}
                    />
                    <CustomTextarea
                      placeholder="Comment"
                      label="Comment"
                      containerProps={{
                        gridRow: { base: 'unset', md: '4/5' },
                        gridColumn: { base: 'unset', md: '1/4' },
                        alignSelf: 'flex-start'
                      }}
                      editMode={true}
                      externalValue={formikValues.comment}
                      externalOnChange={handleChange('comment')}
                    />
                  </Box>
                </Box>
              </ModalBody>
            </>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  )
}
