import * as yup from 'yup'

export const addDowntimeValidation = yup.object().shape({
  applyTo: yup.array().min(1),
  startDateMillis: yup.number().max(yup.ref('endDateMillis')),
  endDateMillis: yup.number().min(yup.ref('startDateMillis')),
  startTimeAmPm: yup.string().required(),
  endTimeAmPm: yup.string().required(),
  start: yup
    .string()
    .test('minutes', 'Must be equal or less than 59', (val) => {
      if (!val) return false
      const [_, res] = val.split(':') || [null]
      if (res !== null) {
        return +res <= 59
      } else {
        return false
      }
    })
    .required(),
  end: yup
    .string()
    .test('minutes', 'Must be equal or less than 59', (val) => {
      if (!val) return false
      const [_, res] = val.split(':') || [null]
      if (res !== null) {
        return +res <= 59
      } else {
        return false
      }
    })
    .required()
})
