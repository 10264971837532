import { useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'

import {
  getPlayer,
  getPlayers,
  newPlayers,
  toggleBlacklistPlayer
} from '../../api/players'

export function usePlayers(filters, queryOption = {}) {
  const queryKeys = useMemo(() => {
    const array = ['players']
    Object.keys(filters).forEach((key) => {
      if (typeof filters[key] === 'boolean') {
        array.push(filters[key])
      }

      if (filters[key] && typeof filters[key] !== 'boolean') {
        array.push(filters[key])
      }
    })
    return array
  }, [filters])

  return useQuery(queryKeys, () => getPlayers(filters), {
    ...queryOption
  })
}

export function usePlayersByMutation(filters, options = {}) {
  return useMutation(() => getPlayers(filters), {
    ...options
  })
}

export function useNewPlayer(options = {}) {
  return useMutation(({ obj }) => newPlayers(obj), {
    ...options
  })
}

export function useToggleBlacklistPlayer(options = {}) {
  return useMutation(({ obj }) => toggleBlacklistPlayer(obj), {
    ...options
  })
}

export function usePlayer(playerId, queryOption = {}) {
  return useQuery(['player', +playerId], () => getPlayer({ playerId }), {
    ...queryOption
  })
}

export function useSomePlayers(playersIds = [], queryOption = {}) {
  return useQuery(
    ['player', ...playersIds],
    () => {
      if (playersIds.length !== 0) {
        return Promise.all(
          playersIds.map(async (playerId) => {
            const player = await getPlayer({ playerId: playerId })
            return {
              ...player,
              playerId
            }
          })
        )
      }
    },
    {
      ...queryOption
    }
  )
}
