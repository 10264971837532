import * as yup from 'yup'

export const createPromoValidation = yup.object().shape({
  playersMin: yup
    .number()
    .positive()
    .max(yup.ref('playersMax'), 'Max should be <= min')
    .required(),
  playersMax: yup
    .number()
    .positive()
    .min(yup.ref('playersMin'), 'Max should be >= min')
    .required(),
  bookFrom: yup
    .number()
    .positive()
    .max(yup.ref('bookTo'), 'Book From should be <= book To'),
  bookTo: yup
    .number()
    .positive()
    .min(yup.ref('bookFrom'), 'Book To should be >= book From'),
  playFrom: yup
    .number()
    .positive()
    .max(yup.ref('playTo'), 'Play To should be <= play To'),
  playTo: yup
    .number()
    .positive()
    .min(yup.ref('bookFrom'), 'Play From should be >= play To'),
  available: yup.number().positive().required(),
  typeDiscount: yup.string(),
  discount: yup
    .number()
    .positive()
    .required()
    .when('typeDiscount', {
      is: '%',
      then: yup
        .number()
        .positive()
        .required()
        .max(100, 'Max value of the discount must be less or equal 100')
    }),
  partner: yup.object().required(),
  eventvariants: yup
    .array()
    .test(
      'eventvariants',
      'Must select at least one experience',
      (val) => val.length !== 0
    )
})
