import { Box, Icon, Image, Text } from '@chakra-ui/react'
import { FormikProvider, useFormikContext } from 'formik'
import { useState } from 'react'
import { BiImage, MdBrokenImage } from 'react-icons/all'
import InputMask from 'react-input-mask'

import {
  BASE_URL_WO_SLASH,
  PHONE_INPUT_MASK
} from '../../../../../../constants'
import FormikTwoStyledInput from '../../../../../Common/FormikComponents/FormikTwoStyledInput'

export default function WaiverInfoPublished({ waiverId }) {
  const formik = useFormikContext()
  const formikValues = formik.values
  const image = formikValues.logo
  const [selectedImage, setSelectedImage] = useState([])
  const [itsBroken, setItsBroken] = useState(false)

  function istBrokenHandler() {
    setItsBroken(true)
  }

  return (
    <FormikProvider value={formik}>
      <Box
        bg="white"
        p={{ base: '16px', md: '20px 16px', '2xl': '24px' }}
        mb="2px"
        d="grid"
        gridGap="16px"
        gridTemplateColumns={{ base: '1fr' }}
      >
        {selectedImage.length === 0 && !image && (
          <Icon
            as={BiImage}
            m="0 auto"
            gridColumn={{ base: 'unset' }}
            color="custom.gray.200"
            w="200px"
            h="200px"
          />
        )}
        {!!image &&
          (itsBroken ? (
            <Box
              textAlign="center"
              border="1px solid"
              gridColumn={{ base: 'unset', md: '1/3' }}
              borderColor="custom.gray.200"
              pos="relative"
              p="20px"
            >
              <Icon as={MdBrokenImage} w="40px" height="40px" />
              <Text
                fontSize={{ base: '12px', '2xl': '14px' }}
                color="custom.black.500"
              >
                Image link broken.
              </Text>
            </Box>
          ) : (
            <Box
              pos="relative"
              gridColumn={{ base: 'unset' }}
              maxW="280px"
              m="0 auto"
            >
              <Image
                src={image}
                onError={istBrokenHandler}
                border="1px solid"
                borderColor="custom.gray.200"
                w="100%"
              />
            </Box>
          ))}
        <FormikTwoStyledInput
          name="phone"
          isDisabled={true}
          input={{
            mask: PHONE_INPUT_MASK,
            as: InputMask
          }}
          placeholder="Phone"
        />
        <FormikTwoStyledInput
          name="address"
          isDisabled={true}
          placeholder="address"
        />
        <FormikTwoStyledInput
          name="email"
          isDisabled={true}
          placeholder="email"
        />
      </Box>
    </FormikProvider>
  )
}
