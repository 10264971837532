import { useEffect, useMemo } from 'react'
import { changeTime, convertTZ, getStringDate } from '../../../libs'
import { useLocations } from '../../../libs/hooks/useLocations'
import { useDispatch, useSelector } from 'react-redux'
import {
  filtersInState,
  locationFilterInState,
  minCalendarDayInState
} from '../../../constants'
import moment from 'moment-timezone'
import { setMinCalendarDay } from '../../../actions'

export default function MinDayChecker() {
  const { data: { results: locations = [] } = {} } = useLocations()
  const locationsObject = useMemo(() => {
    const obj = {}
    locations?.forEach((loc) => {
      const id = loc.id
      obj[id] = loc
    })
    return obj
  }, [locations])
  const dispatch = useDispatch()
  const minCalendarDay = useSelector(minCalendarDayInState)
  const locationInState = useSelector(locationFilterInState)
  const locationLabel = locationInState.label
  const { status } = useSelector(filtersInState)
  useEffect(() => {
    if (
      locations.length !== 0 &&
      !!locationLabel &&
      locationLabel?.toLowerCase() === 'all'
    ) {
      const selectedDate = new Date().toUTCString()
      let minDate = new Date(selectedDate).valueOf()
      let timezoneMin = null
      locations.forEach((loc) => {
        const locationTimestamp = convertTZ(new Date(), loc.timezone).valueOf()
        if (minDate > locationTimestamp) {
          minDate = locationTimestamp
          timezoneMin = loc.timezone
        }
      })

      const minDateConstructor = new Date(
        moment(minDate).year(),
        moment(minDate).month(),
        moment(minDate).date(),
        moment(minDate).hours(),
        moment(minDate).minutes()
      )

      const stringDate = getStringDate(minDateConstructor)
      if (stringDate !== minCalendarDay) {
        dispatch(setMinCalendarDay(stringDate))
      }
    }
    if (
      locations.length !== 0 &&
      !!locationLabel &&
      locationLabel?.toLowerCase() !== 'all'
    ) {
      const id = locationInState.value
      const selectedLoc = locationsObject[id]
      const timezone = selectedLoc?.timezone
      const locationTime = changeTime(timezone)
      const stringDate = getStringDate(locationTime)
      if (stringDate !== minCalendarDay) {
        dispatch(setMinCalendarDay(stringDate))
      }
    }
  }, [locations, minCalendarDay, locationInState, status])

  return null
}
