import { createIcon } from '@chakra-ui/icons'

export const CopyIconWithoutBg = createIcon({
  displayName: 'CopyIconWithoutBg',
  viewBox: '0 0 12 14',
  boxSize: '14',
  path: (
    <g fill="currentColor">
      <path d="M8.33366 0.583344H1.33366C0.691992 0.583344 0.166992 1.10834 0.166992 1.75001V9.91668H1.33366V1.75001H8.33366V0.583344ZM10.0837 2.91668H3.66699C3.02533 2.91668 2.50033 3.44168 2.50033 4.08334V12.25C2.50033 12.8917 3.02533 13.4167 3.66699 13.4167H10.0837C10.7253 13.4167 11.2503 12.8917 11.2503 12.25V4.08334C11.2503 3.44168 10.7253 2.91668 10.0837 2.91668ZM10.0837 12.25H3.66699V4.08334H10.0837V12.25Z" />
    </g>
  )
})
