import { api } from '../index'

export default async function getCalendarData(startTime = null) {
  const { data } = await api.get('/api/eventvariants/calendar/', {
    params: {
      start: startTime
    }
  })
  return data
}
