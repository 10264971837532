import { Box, Button, Text, useToast } from '@chakra-ui/react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import CustomChakraInput from '@/components/Common/CustomChakraInput'
import { loginValidation } from '../../configs/validation/login'
import { useDispatch, useSelector } from 'react-redux'
import { authTokenInState, BOOKINGS_URL } from '../../constants'
import { useEffect } from 'react'
import { auth } from '../../api/auth'
import { setAuthToken, setRefreshToken } from '../../actions'
import { useAuth } from '../../libs/hooks/useAuth'
import { generateWarningToast } from '../../libs'

export default function Login() {
  const dispatch = useDispatch()
  const history = useHistory()
  const toast = useToast()
  const isAuth = useSelector(authTokenInState)
  const { mutate: auth } = useAuth()
  useEffect(() => {
    if (isAuth) {
      history.push(BOOKINGS_URL)
    }
  }, [isAuth])

  function goTo() {
    return () => history.push('/email')
  }

  async function onSubmit(values, { setErrors, resetForm }) {
    auth(
      {
        obj: values
      },
      {
        onSuccess: (data) => {
          dispatch(setAuthToken(data.access))
          dispatch(setRefreshToken(data.refresh))
        },
        onError: (data) => {
          if (data.response.status === 401) {
            generateWarningToast({
              message: data.response.data.detail || 'Email error',
              toast,
              id: 'auth'
            })
          }
        }
      }
    )
  }

  return (
    <Box d="flex" alignItems="center" justifyContent="center" height="100%">
      <Box
        d="flex"
        flexDir="column"
        bg="white"
        p={{ base: '24px 16px', md: '24px' }}
        boxShadow="purple"
        w={{ base: '288px', md: '350px', '2xl': '427px' }}
      >
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={loginValidation}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, handleSubmit, isValid, errors }) => {
            return (
              <>
                <Text
                  as="h1"
                  textTransform="uppercase"
                  color="custom.blue.600"
                  fontSize={{ base: '32px' }}
                  lineHeight="150%"
                  fontWeight="700"
                  textAlign="center"
                  mb={{ base: errors.common ? '8px' : '24px' }}
                >
                  Gbase
                </Text>
                {errors.common && (
                  <Text
                    as="span"
                    fontSize="12px"
                    lineHeight="16px"
                    color="custom.red.650"
                  >
                    {errors.common}
                  </Text>
                )}
                <CustomChakraInput
                  name="email"
                  id="email"
                  label="E-mail"
                  placeholder="E-mail"
                  fontSize={{ base: '14px' }}
                  mb={{ base: '16px' }}
                  height={{ base: '56px' }}
                />
                <CustomChakraInput
                  name="password"
                  id="password"
                  label="password"
                  type="password"
                  placeholder="Password"
                  fontSize={{ base: '14px' }}
                  mb={{ base: '16px' }}
                  height={{ base: '56px' }}
                />
                <Box d="flex">
                  <Button
                    variant="lightBlue"
                    type="button"
                    fontSize={{ base: '14px' }}
                    fontWeight="500"
                    height={{ base: '56px' }}
                    mr={{ base: errors.common ? '8px' : '0' }}
                    minW="unset"
                    overflow="hidden"
                    p={!errors.common && '0'}
                    transition="all 0.2s ease"
                    w={errors.common ? '100%' : '0'}
                    onClick={goTo()}
                  >
                    <Text d={{ base: 'none', md: 'block' }}>
                      Reset password
                    </Text>
                    <Text d={{ base: 'block', md: 'none' }}>Reset</Text>
                  </Button>
                  <Button
                    variant="blue"
                    type="submit"
                    fontSize={{ base: '14px' }}
                    fontWeight="500"
                    height={{ base: '56px' }}
                    w="100%"
                    ml={{ base: errors.common ? '8px' : '0' }}
                    transition="all 0.2s ease"
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                </Box>
              </>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}
