import { useField } from 'formik'
import { FormControl, Input } from '@chakra-ui/react'

export default function FormikFinishInput({ name, id, placeholder, ...rest }) {
  const [field, { touched, error, value }, { setValue, setTouched }] =
    useField(name)

  return (
    <FormControl {...rest}>
      <Input
        variant="gbase"
        placeholder={placeholder}
        {...field}
        bg={error && touched ? 'custom.red.20' : 'custom.lightGray'}
        fontSize={{ base: '12px', '2xl': '14px' }}
      />
    </FormControl>
  )
}
