import { Box, Text } from '@chakra-ui/react'
import { useMemo } from 'react'

import CutOffElement from './CutOffElement'
import { useExperiences } from '../../../libs/hooks/useExperiences'
import { useLocations } from '../../../libs/hooks/useLocations'

export default function CutOffBlock() {
  const {
    data: { results: experiences = [] } = {},
    isLoading,
    isRefetching
  } = useExperiences({
    refetchOnWindowFocus: false
  })
  const { data: { results: locations = [] } = {} } = useLocations()
  const isLoadingTotal = isLoading || isRefetching

  const experiencesByLocationId = useMemo(() => {
    const obj = {}
    experiences.forEach((exp) => {
      const locationId = exp.event.location
      if (obj.hasOwnProperty(locationId)) {
        obj[locationId] = [...obj[locationId], exp]
      } else {
        obj[locationId] = [exp]
      }
    })
    return obj
  }, [experiences])

  const locationsWithExp = useMemo(() => {
    if (
      locations.length === 0 ||
      Object.keys(experiencesByLocationId).length === 0
    )
      return []
    const locationsNew = locations.map((loc) => {
      return {
        ...loc,
        experiences: experiencesByLocationId.hasOwnProperty(loc.id)
          ? experiencesByLocationId[loc.id]
          : []
      }
    })
    return locationsNew
  }, [locations, experiencesByLocationId])

  console.log('locationsWithExp', locationsWithExp)

  console.log('3 / 2', 5 % 6)
  const cuttOffOld = useMemo(() => {
    return (
      experiences.length !== 0 &&
      experiences.map((exp) => {
        return (
          <CutOffElement
            key={'small' + exp.id}
            name={exp.name}
            experienceId={exp.id}
            cutOffTime={exp.cut_off_time}
            isDisabled={isLoadingTotal}
          />
        )
      })
    )
  }, [experiences, isLoadingTotal])

  const cuttOff = useMemo(() => {
    return locationsWithExp?.map((location) => {
      const locationName = location.name
      const exps = location.experiences
      const isEmpty = !exps?.length
      const expsLength = exps.length
      if (isEmpty) return null
      return (
        <Box mb="8px" key={'bigloc' + location.id}>
          <Box
            bg="white"
            p={{ base: '16px 8px', md: '16px' }}
            mb={{ base: '2px' }}
          >
            <Text
              fontSize={{ base: '18px', md: '16px' }}
              fontWeight="500"
              color="custom.black.500"
            >
              {locationName}
            </Text>
          </Box>
          <Box
            d="grid"
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(3, 1fr)',
              '2xl': 'repeat(6, 1fr)'
            }}
            gridGap={{ base: '2px' }}
          >
            {exps?.map((exp) => {
              return (
                <CutOffElement
                  key={'big' + exp.id}
                  name={exp.name}
                  experienceId={exp.id}
                  cutOffTime={exp.cut_off_time}
                  isDisabled={isLoadingTotal}
                />
              )
            })}
            <Box
              bg="white"
              d={{ base: 'none', md: 'block' }}
              gridColumn={{
                md: `${(expsLength % 3) + 1}/4`,
                '2xl': `${(expsLength % 6) + 1}/7`
              }}
            />
          </Box>
        </Box>
      )
    })
  }, [locationsWithExp, isLoadingTotal])
  return (
    <Box mb="12px">
      <Box bg="white" p={{ base: '12px', md: '24px' }} mb={{ base: '2px' }}>
        <Text
          fontSize={{ base: '20px', md: '28px' }}
          fontWeight="600"
          color="custom.black.500"
        >
          Cut off time
        </Text>
      </Box>
      <Box
        d="grid"
        mb="8px"
        gridTemplateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(3, 1fr)',
          '2xl': 'repeat(6, 1fr)'
        }}
        gridGap={{ base: '2px' }}
      >
        {cuttOffOld}
        <Box
          bg="white"
          d={{ base: 'none', md: 'block' }}
          gridColumn={{
            md: `${(experiences.length % 3) + 1}/4`,
            '2xl': `${(experiences.length % 6) + 1}/7`
          }}
        />
      </Box>
      {cuttOff}
    </Box>
  )
}
