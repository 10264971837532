import React from 'react'
import { useTable } from 'react-table'
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import WithSorting from '../WithSorting'

export default function TableCreatorStatistics({
  columns,
  data,
  styles = {},
  sortingColumns = [],
  sizesMap = [],
  tableFilter = {},
  setTableFilter = null
}) {
  const {
    tr,
    th,
    thead,
    table,
    tbody,
    td,
    tdMinor,
    trMinor,
    trFunc = null
  } = styles
  function getSize(header, accessor, prop) {
    if (header && typeof header === 'string') {
      const lowerHeader = header.toLowerCase()
      if (sizesMap[lowerHeader]) {
        return sizesMap[lowerHeader][prop] ? sizesMap[lowerHeader][prop] : ''
      }
    } else {
      const lowerAccessor = accessor.toLowerCase()
      if (sizesMap[lowerAccessor]) {
        return sizesMap[lowerAccessor][prop]
          ? sizesMap[lowerAccessor][prop]
          : ''
      }
    }
  }

  function isNeedCbProps(row) {
    if (trFunc !== null && typeof trFunc === 'function') {
      return trFunc(row)
    } else return {}
  }

  function needSize() {
    return Object.keys(sizesMap).length !== 0
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    })

  return (
    <Table {...getTableProps()} {...table}>
      <Thead {...thead}>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()} {...tr}>
            {headerGroup.headers.map((column) => {
              return (
                <Th
                  {...column.getHeaderProps()}
                  {...th}
                  w={needSize() && getSize(column.Header, column.id, 'width')}
                  maxW={
                    needSize() && getSize(column.Header, column.id, 'maxWidth')
                  }
                >
                  {typeof column.Header === 'string' &&
                  sortingColumns.includes(column.Header.toLowerCase()) ? (
                    <WithSorting
                      value={column.Header}
                      filter={tableFilter}
                      setFilter={setTableFilter}
                    >
                      {column.render('Header')}
                    </WithSorting>
                  ) : (
                    <Box
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      fontSize="inherit"
                      fontWeight="inherit"
                      textDecoration="inherit"
                    >
                      {column.render('Header')}
                    </Box>
                  )}
                </Th>
              )
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()} {...tbody}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <Tr {...row.getRowProps()} {...tr} {...isNeedCbProps(row)}>
              {row.cells.map((cell) => {
                const rowSpanLength = cell?.row?.original?.rowSpanLength
                const headers = [
                  'Down',
                  'PS',
                  'Waste',
                  'Played',
                  'LL, %',
                  'Sched'
                ]
                const rowSpanNeed =
                  headers.includes(cell?.column?.Header) && rowSpanLength
                if (cell?.value === 'NOT_SHOW') return null
                return (
                  <Td
                    {...cell.getCellProps()}
                    {...td}
                    {...(rowSpanNeed && { rowSpan: rowSpanLength })}
                    w={
                      needSize() &&
                      getSize(cell.column.Header, cell.column.id, 'width')
                    }
                    maxW={
                      needSize() &&
                      getSize(cell.column.Header, cell.column.id, 'maxWidth')
                    }
                  >
                    {cell.render('Cell')}
                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
