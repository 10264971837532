import { TABLE_COLUMN_VALUES_LIMITATION_GRAPH } from '../../../../configs'
import React, { useMemo } from 'react'
import LimitationsTableHeader from './LimitationsTableHeader'
import { Box } from '@chakra-ui/react'
import LimitationsLegend from '../LimitationsLegend'
import LimitationsTableBody from './LimitationsTableBody'

export default function LimitationsTable({ tableData = [], day = '' }) {
  const columns = TABLE_COLUMN_VALUES_LIMITATION_GRAPH
  const data = useMemo(() => tableData, [tableData])
  return (
    <Box>
      {data?.map((data, idx) => (
        <React.Fragment key={'React.Fragment LimitationsTable' + idx}>
          <LimitationsTableHeader
            location={data.location_name}
            columns={columns}
            day={day}
            locationId={data.location_id}
            d="grid"
            gridTemplateColumns={{
              md: `6fr repeat(${columns.length}, 1fr)`,
              '2xl': `3fr repeat(${columns.length}, 1fr)`
            }}
            h="56px"
          />
          <LimitationsTableBody
            columns={columns}
            data={data}
            day={day}
            location={data.location_name}
            d="grid"
            gridTemplateColumns={{
              md: `6fr ${columns.length}fr`,
              '2xl': `3fr ${columns.length}fr`
            }}
            h="56px"
          />
        </React.Fragment>
      ))}
      <LimitationsLegend />
    </Box>
  )
}
