import { useQuery } from 'react-query'

import { getPlayerPrice } from '../../api/booking'

export function usePlayersPrice(bookingId, players, queryOption = {}) {
  return useQuery(
    ['playersPrice', +bookingId, +players],
    () => getPlayerPrice(players, bookingId),
    {
      ...queryOption
    }
  )
}
