import { Box, IconButton, useTheme } from '@chakra-ui/react'
import React from 'react'
import { useQueryClient } from 'react-query'

import { CrosshairIcon } from '../../../../../assets/Icons/CrosshairIcon'
import {
  convertSecondsToAmPm,
  convertValues,
  convertWeekdayInIndex
} from '../../../../../libs'
import { useDeleteLimitation } from '../../../../../libs/hooks/useLimitations'
import { PERMISSION_LIMITATIONS } from '../../../../../constants'
import PermissionRequired from '../../../../Common/PermissionRequired'
import EditLimitationModal from '../../../Modals/EditLimitationModal'

export default function LimitationsTableBody({
  data = {},
  columns = [],
  day = '',
  location,
  ...rest
}) {
  const limitations = [...(data || []).limitations, { summaryRow: true }]
  const queryClient = useQueryClient()
  const summary = []
  const theme = useTheme()
  const customsColor = theme.colors.custom
  const { mutate: deleteLimitation } = useDeleteLimitation()
  const limitationsPermissions = PermissionRequired(
    PERMISSION_LIMITATIONS,
    true
  )
  function onDeleteHandler(limitationId) {
    return () => {
      const toSend = {
        limitationId
      }
      deleteLimitation(
        {
          obj: toSend
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'limitations',
              +convertWeekdayInIndex(day)
            ])
          }
        }
      )
    }
  }

  return limitations.map((limitation, limitationIdx) => {
    const {
      time_start: startTime,
      time_end: endTime,
      summaryRow = false,
      location_id: locationId,
      id: limitationId,
      peoples = ''
    } = limitation
    if (!(startTime < 28800 && endTime < 28800)) {
      let correctedStartSeconds = startTime
      let correctedEndSeconds = endTime
      if (endTime > 93600) {
        correctedEndSeconds = 93600
      }
      if (startTime <= 28800 && endTime > 28800) {
        correctedStartSeconds = 28800
      }
      summary.push({
        ...limitation,
        time_start: correctedStartSeconds,
        time_end: correctedEndSeconds
      })
      const startPoint = convertValues(
        correctedStartSeconds - 1 * 60 * 60,
        25200,
        90000,
        0,
        100
      )
      let dif
      if (correctedEndSeconds - correctedStartSeconds > 0) {
        dif = correctedEndSeconds - correctedStartSeconds
      } else {
        dif = 86400 + (correctedEndSeconds - correctedStartSeconds)
      }
      const strStart = convertSecondsToAmPm(startTime)
      const strEnd = convertSecondsToAmPm(endTime)
      const width = convertValues(dif, 0, 64800, 0, 100)
      return (
        <Box {...rest} overflowX="hidden" key={'limitation' + limitationIdx}>
          <Box
            p="0 24px"
            d="flex"
            alignItems="center"
            bg="white"
            color="custom.gray.900"
            fontSize={{ md: '12px', '2xl': '14px' }}
            borderTop={`1px solid ${customsColor.lightGray}`}
            borderBottom={`1px solid ${customsColor.lightGray}`}
          >
            {summaryRow ? (
              'Summary'
            ) : (
              <>
                {`${strStart} - ${strEnd}`}
                <Box ml="auto">
                  <Box
                    d="grid"
                    gridTemplateColumns="1fr 1fr"
                    ml="12px"
                    gridGap="12px"
                    justifySelf="right"
                  >
                    <EditLimitationModal
                      day={day}
                      location={location}
                      locationId={locationId}
                      limitationId={limitationId}
                      strStart={strStart}
                      strEnd={strEnd}
                      peoples={peoples}
                    />
                    <IconButton
                      aria-label="edit-limitation"
                      icon={<CrosshairIcon w="inherit" h="inherit" />}
                      minW="unset"
                      w="16px"
                      h="16px"
                      onClick={onDeleteHandler(limitationId)}
                      color="custom.gray.200"
                      isDisabled={!limitationsPermissions}
                      variant="funcColumnIconButton"
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box
            d="grid"
            gridTemplateColumns={`repeat(${columns.length}, 1fr)`}
            pos="relative"
          >
            {columns?.map(({ header, time }, idxColumn) => {
              return (
                <Box
                  w="100%"
                  h="100%"
                  bg="white"
                  pos="relative"
                  borderTop={`1px solid ${customsColor.lightGray}`}
                  borderBottom={`1px solid ${customsColor.lightGray}`}
                  key={idxColumn + 'limitationCell' + limitationIdx}
                  _before={{
                    content: `""`,
                    pos: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    w: '0.5px',
                    zIndex: 1,
                    bg: customsColor.lightGray
                  }}
                  _after={{
                    content: `""`,
                    pos: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    w: '0.5px',
                    zIndex: 1,
                    bg: customsColor.lightGray
                  }}
                ></Box>
              )
            })}
            {!summaryRow ? (
              <Box
                pos="absolute"
                w={width + '%'}
                left={startPoint + '%'}
                h="100%"
                p="16.5px 0"
                key={'limitationCell' + limitationIdx}
                zIndex={5}
              >
                <Box
                  w="100%"
                  h="100%"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  bg="custom.borderBlue"
                ></Box>
              </Box>
            ) : (
              <React.Fragment key={'summaryCell' + limitationIdx}>
                <Box
                  pos="absolute"
                  w="100%"
                  left="0"
                  h="100%"
                  p="16.5px 0"
                  zIndex={5}
                >
                  <Box
                    w="100%"
                    h="100%"
                    d="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="rgb(205, 227, 252)"
                  ></Box>
                </Box>
                {summary.map((sum, summaryIdx) => {
                  const {
                    time_start: startTimeSummary,
                    time_end: endTimeSummary
                  } = sum
                  const startPoint = convertValues(
                    startTimeSummary - 1 * 60 * 60,
                    25200,
                    90000,
                    0,
                    100
                  )
                  let dif
                  if (endTimeSummary - startTimeSummary > 0) {
                    dif = endTimeSummary - startTimeSummary
                  } else {
                    dif = 86400 + (endTimeSummary - startTimeSummary)
                  }
                  const width = convertValues(dif, 0, 64800, 0, 100)
                  return (
                    <Box
                      pos="absolute"
                      w={width + '%'}
                      left={startPoint + '%'}
                      h="100%"
                      p="16.5px 0"
                      key={
                        'summaryCell' +
                        summaryIdx +
                        limitationIdx +
                        limitationId
                      }
                      zIndex={5}
                    >
                      <Box
                        w="100%"
                        h="100%"
                        d="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="white"
                        opacity="0.2"
                        bg="custom.blue.600"
                      ></Box>
                    </Box>
                  )
                })}
              </React.Fragment>
            )}
          </Box>
        </Box>
      )
    }
  })
}
