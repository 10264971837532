import StatisticsQuestList from './StatisticsQuestList'
import StatisticsPartnersList from './StatisticsPartnersList'

export default function StatisticsMobile({
  statisticsLocations = [],
  dataPartners = [],
  buttonsSwitchData,
  type
}) {
  return (
    <>
      {type === buttonsSwitchData[0] && (
        <>
          {statisticsLocations?.map((statLoc, idx) => (
            <StatisticsQuestList
              data={statLoc.eventvariants_statistic}
              total={statLoc.total}
              ll={statLoc.ll}
              avg={statLoc.avg}
              name={statLoc.name}
              played={statLoc.played}
              skip={statisticsLocations.length - 1 === idx}
              key={idx + 'StatisticsQuestroomTableMobile'}
            />
          ))}
        </>
      )}

      {type === buttonsSwitchData[1] && (
        <StatisticsPartnersList data={dataPartners} />
      )}
    </>
  )
}
