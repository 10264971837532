import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { generateWarningToast } from '../../../../libs'
import { useResendNotification } from '../../../../libs/hooks/useResendNotification'

export default function ResendNotificationModal() {
  const theme = useTheme()
  const toast = useToast()
  const customColors = theme.colors.custom
  const { id } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [afterSendSuccess, setAfterSendSuccess] = useState(false)
  const [state, setState] = useState({
    sendSms: false,
    sendEmail: false
  })
  const { mutate: resend, reset: mutationReset } = useResendNotification()

  function onChangeHandler(prop) {
    return (e) => {
      setState({
        ...state,
        [prop]: e.target.checked
      })
    }
  }

  function onClickHandler() {
    const toSend = {
      bookingId: id,
      sendSms: state.sendSms,
      sendEmail: state.sendEmail
    }
    resend(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          setAfterSendSuccess(true)
        },
        onError: (err) => {
          const msg = err?.response?.data
          generateWarningToast({
            msgObjects: msg,
            toast,
            id: 'edit-experience-modal'
          })
        }
      }
    )
  }

  function onCloseHandler() {
    setAfterSendSuccess(false)
    mutationReset()
    onClose()
  }

  return (
    <>
      <Button onClick={onOpen} variant="lightBlue">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          Resend Notification
        </Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onCloseHandler}
        variant="gbase"
      >
        <ModalOverlay />
        {!afterSendSuccess ? (
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
          >
            <ModalHeader mb="16px">Resend Notification:</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box
                border={`1px solid ${customColors.borderBlue}`}
                bg="custom.lightGray"
                h="40px"
                p="0 16px"
                d="flex"
                mb="8px"
                alignItems="center"
              >
                <Text color="custom.gray.900">Send SMS</Text>
                <Switch
                  ml="auto"
                  size="md"
                  checked={state.sendSms}
                  onChange={onChangeHandler('sendSms')}
                />
              </Box>
              <Box
                border={`1px solid ${customColors.borderBlue}`}
                bg="custom.lightGray"
                h="40px"
                p="0 16px"
                d="flex"
                mb="8px"
                alignItems="center"
              >
                <Text color="custom.gray.900">Send E-mail</Text>
                <Switch
                  ml="auto"
                  size="md"
                  checked={state.sendEmail}
                  onChange={onChangeHandler('sendEmail')}
                />
              </Box>
            </ModalBody>

            <ModalFooter d="grid" gridTemplateColumns="1fr 1fr" gridGap="8px">
              <Button variant="lightBlue" onClick={onCloseHandler}>
                Close
              </Button>
              <Button
                onClick={onClickHandler}
                variant="blue"
                isDisabled={!(state.sendSms || state.sendEmail)}
                _hover={{
                  _disabled: false
                }}
              >
                <Text
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  Send
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        ) : (
          <ModalContent
            variant="gbase"
            w="100%"
            maxW={{ base: '288px', md: '348px', '2xl': '427px' }}
          >
            <ModalHeader mb="16px">Resend Notification:</ModalHeader>
            <ModalCloseButton />
            <ModalBody mb="16px">
              <Text color="custom.gray.900" fontSize="16px">
                Notification was sent successfully!
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button variant="lightBlue" w="100%" onClick={onCloseHandler}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  )
}
