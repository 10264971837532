import { Box, Text } from '@chakra-ui/react'
import DiscountAssignedItem from '../DiscountAssignedItem'
import { useSelector } from 'react-redux'
import { matchMedia768InState } from '../../../../../constants'
import { useDiscountsAssigned } from '../../../../../libs/hooks/useDiscounts'
import { useEffect, useState } from 'react'

export default function DiscountAssignedList({
  arraysWithEventvariantsByType = {},
  discountsRefetching
}) {
  const { data, isRefetching: discountsAssignedRefetching } =
    useDiscountsAssigned()
  const isRefetching = discountsRefetching || discountsAssignedRefetching
  const [localIsLoading, setLocalIsLoading] = useState(false)
  function loadingHandler() {
    return (val) => setLocalIsLoading(val)
  }
  useEffect(() => {
    if (localIsLoading && !isRefetching) {
      setLocalIsLoading(false)
    }
  }, [isRefetching])
  const isLargerThan768 = useSelector(matchMedia768InState)

  return (
    <Box mb={{ md: '24px' }}>
      <Text
        color="custom.black.500"
        fontSize={{ base: '18px' }}
        mb={{ base: '16px' }}
        fontWeight="500"
      >
        Discount assigned
      </Text>
      {isLargerThan768 && (
        <Box
          d="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          p="16px"
          bg="white"
          mb="2px"
        >
          <Text color="custom.gray.300" fontSize={{ md: '14px' }}>
            Discount type
          </Text>
          <Text
            color="custom.gray.900"
            fontSize={{ md: '14px' }}
            justifySelf="center"
          >
            Same day booking
          </Text>
          <Text
            color="custom.gray.900"
            fontSize={{ md: '14px' }}
            justifySelf="center"
          >
            Multiple bookings
          </Text>
          <Text
            color="custom.gray.900"
            fontSize={{ md: '14px' }}
            justifySelf="center"
          >
            Advanced booking
          </Text>
        </Box>
      )}
      {data?.map((item, idx) => (
        <DiscountAssignedItem
          name={item.name}
          assignedDiscounts={item.assigned_discounts}
          eventvariantId={item.id}
          isLoading={localIsLoading}
          setLocalIsLoading={loadingHandler()}
          arraysWithEventvariantsByType={arraysWithEventvariantsByType}
          key={'DiscountAssignedItem' + idx}
        />
      ))}
    </Box>
  )
}
