import { Box } from '@chakra-ui/react'

import PaymentGateItem from '../PaymentGateItem'

/**
 *
 * @param {{ data: Awaited<ReturnType<typeof getPaymentGates>> }} data
 * @returns
 */
export default function PaymentGatesList({ data: paymentGates }) {
  return (
    <Box>
      {paymentGates
        ?.sort((a, b) => b.id - a.id)
        ?.map((gate, idx) => (
          <PaymentGateItem
            key={'paymentGate' + idx}
            id={gate.id}
          />
        ))}
    </Box>
  )
}
