import PermissionRequired from '../PermissionRequired'
import {
  BOOKINGS_URL,
  CASH_URL,
  DISCOUNTS_URL,
  GIFT_CARDS_URL,
  PERMISSION_CASH_VIEW,
  PERMISSION_COMPANIES,
  PERMISSION_CUT_OFF,
  PERMISSION_DISCOUNTS,
  PERMISSION_EVENT,
  PERMISSION_GIFTCARDS,
  PERMISSION_LIMITATIONS,
  PERMISSION_LOCATIONS,
  PERMISSION_NOTIFICATIONS,
  PERMISSION_PLAYERS,
  PERMISSION_STATISTICS,
  PERMISSION_USERS,
  PLAYERS_URL,
  SETTINGS_URL,
  STATISTICS_URL,
  USERS_ROLES_URL
} from '../../../constants'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function PermissionControl({ onClickHandler }) {
  const location = useLocation()
  const statisticsPermissionAccess = PermissionRequired(
    PERMISSION_STATISTICS,
    true
  )
  const discountsPermissionAccess = PermissionRequired(
    PERMISSION_DISCOUNTS,
    true
  )
  const cashPermissionAccess = PermissionRequired(PERMISSION_CASH_VIEW, true)
  const giftcardPermission = PermissionRequired(PERMISSION_GIFTCARDS, true)
  const cutOffTimePermission = PermissionRequired(PERMISSION_CUT_OFF, true)
  const locationPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  const notificationPermission = PermissionRequired(
    PERMISSION_NOTIFICATIONS,
    true
  )
  const usersPermission = PermissionRequired(PERMISSION_USERS, true)
  const limitationsPermission = PermissionRequired(PERMISSION_LIMITATIONS, true)
  const accountPermission = PermissionRequired(PERMISSION_COMPANIES, true)
  const attractionPermission = PermissionRequired(PERMISSION_EVENT, true)
  const playersPermission = PermissionRequired(PERMISSION_PLAYERS, true)

  const allSettingsBlocked =
    !locationPermission &&
    !notificationPermission &&
    !usersPermission &&
    !accountPermission &&
    !attractionPermission &&
    !limitationsPermission &&
    !cutOffTimePermission

  useEffect(() => {
    if (STATISTICS_URL === location.pathname && !statisticsPermissionAccess) {
      onClickHandler(0, BOOKINGS_URL)
    }
  }, [location, statisticsPermissionAccess])

  useEffect(() => {
    if (PLAYERS_URL === location.pathname && !playersPermission) {
      onClickHandler(0, BOOKINGS_URL)
    }
  }, [location, playersPermission])

  useEffect(() => {
    if (USERS_ROLES_URL === location.pathname && !usersPermission) {
      onClickHandler(0, BOOKINGS_URL)
    }
  }, [location, usersPermission])

  useEffect(() => {
    if (DISCOUNTS_URL === location.pathname && !discountsPermissionAccess) {
      onClickHandler(0, BOOKINGS_URL)
    }
  }, [location, discountsPermissionAccess])

  useEffect(() => {
    if (CASH_URL === location.pathname && !cashPermissionAccess) {
      onClickHandler(0, BOOKINGS_URL)
    }
  }, [location, cashPermissionAccess])

  useEffect(() => {
    if (GIFT_CARDS_URL === location.pathname && !giftcardPermission) {
      onClickHandler(0, BOOKINGS_URL)
    }
  }, [location, cashPermissionAccess])

  useEffect(() => {
    if (SETTINGS_URL === location.pathname && allSettingsBlocked) {
      onClickHandler(0, BOOKINGS_URL)
    }
  }, [location, allSettingsBlocked])

  return null
}
