import { useColumnOrder, useResizeColumns, useTable } from 'react-table'
import React, { useEffect, useMemo } from 'react'
import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import {
  changeFormatAndTimezone,
  friendlyTime,
  friendlyTimeWithTZ,
  friendlyUTCTime,
  uppercaseFirstLetter
} from '../../../../libs'
import GBaseTag from '../../../Common/GBaseTag'
import {
  sortingColumns,
  TABLE_COLUMN_VALUES_BOOKINGS
} from '../../../../configs'
import {
  BOOKING_URL,
  bookingsSortByLocInState,
  bookingsSortByTimeInState
} from '../../../../constants'
import { Link } from 'react-router-dom'
import Preloader from '../../../Common/Preloader'
import DeleteBookingModal from '../Modals/DeleteBookingModal'
import { useDispatch, useSelector } from 'react-redux'
import WithSortingBookings from '../../../Common/WithSortingBookings'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import { CopyIconWithoutBg } from '../../../../assets/Icons/CopyIconWithoutBg'
import WithSorting from '../../../Common/WithSorting'
import { useState } from 'react'
import { setLocationSort, setTimeSort } from '../../../../actions'
import WithSortingMultiple from '../../../Common/WithSortingMultiple'

export default function TableCreatorBookings({
  tableData,
  isLoading,
  sizesMap = [],
  bookingsQueryKeys
}) {
  const dispatch = useDispatch()
  const filterInitial = {}
  const [tableFilter, setTableFilter] = useState(filterInitial)
  const sortByLoc = useSelector(bookingsSortByLocInState)
  const sortByTime = useSelector(bookingsSortByTimeInState)

  useEffect(() => {
    if (tableFilter.hasOwnProperty('location')) {
      dispatch(setLocationSort(tableFilter['location'].sort))
    }
    if (tableFilter.hasOwnProperty('start time')) {
      dispatch(setTimeSort(tableFilter['start time'].sort))
    }
  }, [tableFilter])

  useEffect(() => {
    if (sortByLoc !== '') {
      setTableFilter({
        ...tableFilter,
        location: {
          sort: sortByLoc
        }
      })
    }
  }, [sortByLoc])

  useEffect(() => {
    if (sortByTime !== '') {
      setTableFilter({
        ...tableFilter,
        'start time': {
          sort: sortByTime
        }
      })
    }
  }, [sortByTime])

  function getSize(header, accessor, prop) {
    if (header && typeof header === 'string') {
      const lowerHeader = header?.toLowerCase()
      if (sizesMap[lowerHeader]) {
        return sizesMap[lowerHeader][prop] ? sizesMap[lowerHeader][prop] : ''
      }
    } else {
      const lowerAccessor = accessor?.toLowerCase()
      if (sizesMap[lowerAccessor]) {
        return sizesMap[lowerAccessor][prop]
          ? sizesMap[lowerAccessor][prop]
          : ''
      }
    }
  }

  const data = useMemo(
    () => (tableData ? tableData : []),
    [tableData, bookingsQueryKeys, sortByLoc, sortByTime]
  )

  const columns = useMemo(() => {
    return TABLE_COLUMN_VALUES_BOOKINGS.map((field) => {
      const fieldObject = {
        Header: field.Header,
        accessor: field.Accessor,
        minWidth: field.minWidth
      }

      if (field.Header === 'code') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return (
            <Box d="flex" alignItems="center" justifyContent="center">
              <Link to={`${BOOKING_URL}/${original.id}`}>
                <Text
                  ml="auto"
                  color="custom.blue.600"
                  textDecoration="underline"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  #{value}
                </Text>
              </Link>
              <CopyToClipboard text={value}>
                <IconButton
                  aria-label="copy-link"
                  bg="none"
                  w="14px"
                  height="14px"
                  minW="unset"
                  minH="unset"
                  ml="8px"
                  p="0"
                  _focus={{ border: 'none' }}
                  _hover={{ bg: 'transparent' }}
                  icon={
                    <CopyIconWithoutBg
                      w="100%"
                      height="100%"
                      color="custom.gray.200"
                    />
                  }
                />
              </CopyToClipboard>
            </Box>
          )
        }
      }

      if (field.Header === 'game date') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return <>{friendlyUTCTime(value, 'MM.DD.YY')}</>
        }
      }

      if (field.Header === 'start time') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          const startTime = changeFormatAndTimezone(
            value,
            original.timezone,
            'h:mm A'
          )
          return <>{startTime}</>
        }
      }

      if (field.Header === 'client') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          const lastname = original.order.lastname?.toLowerCase()
          const firstname = original.order.firstname?.toLowerCase()
          const phone = original.order.phone
          return (
            <Text
              textTransform="capitalize"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {firstname ? firstname : ''}&nbsp;{lastname ? lastname : ''}&nbsp;
              {phone ? `(${phone})` : ''}
            </Text>
          )
        }
      }

      if (field.Header === 'status') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          return (
            <>
              <GBaseTag fontSize="inherit" m="0 auto" label={value} />
            </>
          )
        }
      }
      if (field.Accessor === 'funcColumn') {
        fieldObject['Cell'] = ({ row: { original }, value }) => {
          const status = original.status
          if (status === 'canceled') {
            return (
              <>
                <DeleteBookingModal
                  bookingCode={original.code}
                  bookingId={original.id}
                  bookingsQueryKeys={bookingsQueryKeys}
                />
              </>
            )
          }

          return null
        }
      }

      return fieldObject
    })
  }, [data, sortByTime, sortByLoc])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder
  } = useTable({ columns, data }, useColumnOrder, useResizeColumns)
  useEffect(() => {
    setColumnOrder(TABLE_COLUMN_VALUES_BOOKINGS)
  }, [])

  return isLoading ? (
    <Preloader />
  ) : data.length !== 0 ? (
    <Table {...getTableProps()}>
      <Thead
        pos={['relative', 'relative', 'sticky']}
        zIndex="1"
        top={['unset', 'unset', '74px']}
      >
        {headerGroups.map((headerGroup) => (
          <Tr
            {...headerGroup.getHeaderGroupProps()}
            fontSize={['12px', '12px', '12px', '12px', '12px', '14px']}
            bg="white"
            mb="2px"
            height="56px"
          >
            {headerGroup.headers.map((column) => {
              return (
                <Th
                  {...column.getHeaderProps()}
                  height="inherit"
                  p="0 3px"
                  fontSize="inherit"
                  textAlign="center"
                  lineHeight="24px"
                  fontWeight="400"
                  textTransform="none"
                  color="custom.gray.900"
                  maxW={getSize(column.Header, column.Accessor, 'maxWidth')}
                  w={getSize(column.Header, column.Accessor, 'width')}
                >
                  {sortingColumns.includes(column.Header) ? (
                    <WithSortingMultiple
                      value={column.Header}
                      filter={tableFilter}
                      setFilter={setTableFilter}
                    >
                      {uppercaseFirstLetter(column.render('Header'))}
                    </WithSortingMultiple>
                  ) : (
                    <Text
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {uppercaseFirstLetter(column.render('Header'))}
                    </Text>
                  )}
                </Th>
              )
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <Tr
              {...row.getRowProps()}
              p="16px 24px"
              fontSize={['12px', '12px', '12px', '12px', '12px', '14px']}
              bg="white"
              mb="2px"
              h="56px"
            >
              {row.cells.map((cell) => {
                return (
                  <Td
                    {...cell.getCellProps()}
                    p="0 3px"
                    textAlign="center"
                    color="custom.gray.900"
                    whiteSpace="nowrap"
                    overflow={cell.column.id !== 'funcColumn' && 'hidden'}
                    textOverflow="ellipsis"
                    w={getSize(
                      cell.column.Header,
                      cell.column.Accessor,
                      'width'
                    )}
                    maxW={getSize(
                      cell.column.Header,
                      cell.column.Accessor,
                      'maxWidth'
                    )}
                  >
                    {cell.render('Cell')}
                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  ) : (
    <Box textAlign="center" m="20px 0" fontSize="14px">
      No results were found for your search.
    </Box>
  )
}
