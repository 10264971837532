import {
  Box,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useOutsideClick
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash.debounce'
import { debounceSearchWaiversTime } from '../../../configs'
import { useSelector } from 'react-redux'
import {
  BOOKING_URL,
  GIFT_CARDS_URL,
  matchMedia768InState,
  userInState
} from '../../../constants'
import { useTheme } from '@emotion/react'
import { useBookings } from '../../../libs/hooks/useBookings'
import { useGiftCards } from '../../../libs/hooks/useGiftCards'
import GBaseTag from '../GBaseTag'
import { CrosshairIcon } from '../../../assets/Icons/CrosshairIcon'

export default function SearchBookingGiftcard({
  cb = null,
  resetCb = null,
  ...rest
}) {
  const theme = useTheme()
  const customColors = theme.colors.custom
  const [searchInput, setSearchInput] = useState('')
  const [selectedObject, setSelectedObject] = useState({})
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const inputGroupRef = useRef()
  const user = useSelector(userInState)

  function goTo(url) {
    return (e) => {
      e.stopPropagation()
      window.open(url, '_blank')
    }
  }

  const {
    data: { results: bookings = [], count: totalBookings = '' } = {},
    isLoading,
    refetch: dataBookingRefetch
  } = useBookings(
    {
      page: 1,
      pageSize: 999,
      search: searchInput
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )
  const {
    data: { results: giftcards = [], count: totalGiftcards = '' } = {},
    refetch: refetchGiftcard
  } = useGiftCards(
    {
      page: 1,
      pageSize: 999,
      search: searchInput
    },
    {
      enabled: false
    }
  )

  useOutsideClick({
    ref: inputGroupRef,
    handler: () => setShowAutocomplete(false)
  })

  const debouncedSearch = useCallback(
    debounce(() => {
      dataBookingRefetch()
      refetchGiftcard()
    }, debounceSearchWaiversTime),
    []
  )

  useEffect(() => {
    if (bookings.length !== 0 || giftcards.length !== 0) {
      setShowAutocomplete(true)
    }
  }, [giftcards, bookings])

  const isSelectedObjectComplete = useMemo(() => {
    return Object.keys(selectedObject).length !== 0
  }, [selectedObject])

  useEffect(() => {
    if (isSelectedObjectComplete) {
      const str =
        selectedObject.code +
        ' ' +
        selectedObject.name +
        ' ' +
        selectedObject.email +
        ' [' +
        selectedObject.type.toUpperCase() +
        ']'
      setSearchInput(str)
    }
  }, [isSelectedObjectComplete, selectedObject])

  function acceptPrompt(obj, type) {
    if (type === 'booking') {
      const name = obj?.order?.firstname + ' ' + obj?.order?.lastname
      const email = obj?.order?.email
      const code = obj?.code
      const locationId = obj?.event?.location?.id
      if (cb !== null && typeof cb === 'function') {
        cb({ name, email, code, type, locationId })
      }
      setSelectedObject({ name, email, code, type })
    }

    if (type === 'giftcard') {
      const name = obj?.recipient_name
      const email = obj?.recipient_email
      const code = obj?.code
      if (cb !== null && typeof cb === 'function') {
        cb({ name, email, code, type })
      }

      setSelectedObject({ name, email, code, type })
    }
    setShowAutocomplete(false)
  }

  useEffect(() => {
    if (searchInput.length === 0) {
      debouncedSearch.cancel()
      setSelectedObject({})
      setShowAutocomplete(true)
    }
    if (searchInput.length >= 2 && !isSelectedObjectComplete) {
      debouncedSearch(searchInput)
    }
  }, [searchInput])

  function reset() {
    debouncedSearch.cancel()
    setSelectedObject({})
    setShowAutocomplete(true)
    setSearchInput('')
    if (resetCb !== null && typeof resetCb === 'function') {
      resetCb()
    }
  }
  return (
    <Box
      bg="white"
      mb="2px"
      fontSize={{ base: '12px', '2xl': '14px' }}
      d={{ base: 'block', md: 'flex' }}
      {...rest}
    >
      <InputGroup ref={inputGroupRef}>
        <Input
          placeholder="Name, code, email"
          fontSize="inherit"
          fontWeight="400"
          p="0 16px"
          mb="8px"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          _placeholder={{
            color: 'custom.gray.300'
          }}
          _focus={{
            borderColor: 'custom.blue.600'
          }}
          onClick={() => setShowAutocomplete(true)}
          variant="gbase"
        />
        {showAutocomplete &&
          (bookings?.length !== 0 || giftcards.length !== 0) && (
            <Box
              as="ul"
              listStyleType="none"
              position="absolute"
              width="inherit"
              bg="custom.lightGray"
              border={`1px solid ${customColors.blue[600]}`}
              top="39px"
              zIndex="200"
              overflow="auto"
              maxH={{ base: '300px', md: '200px' }}
            >
              {bookings?.map((prompt, idx) => (
                <Box
                  as="li"
                  key={'prompt' + idx}
                  minH="40px"
                  display="flex"
                  alignItems={{ base: 'start', md: 'center' }}
                  p="5px 10px 5px 20px"
                  _hover={{ bg: 'custom.gray.50' }}
                  cursor="pointer"
                  flexDir={{ base: 'column', md: 'row' }}
                  onClick={() => acceptPrompt(prompt, 'booking')}
                  borderBottom={`1px solid ${customColors.gray[50]}`}
                >
                  <Box d={{ base: 'flex', md: 'block' }}>
                    <Button
                      variant="blue"
                      w="80px"
                      p="2px 5px"
                      h="32px"
                      mr="5px"
                      onClick={goTo(`${BOOKING_URL}/${prompt.id}`)}
                    >
                      {prompt?.code?.replace('_rebooked', '  (R)') || '-'}
                    </Button>
                    {!isLargerThan768 && (
                      <GBaseTag
                        color="upcoming"
                        label="Booking"
                        m="0 0 0 auto"
                      />
                    )}
                  </Box>
                  {prompt.order.firstname} {prompt.order.lastname}{' '}
                  {prompt.order.email}
                  {'  '}
                  {isLargerThan768 && (
                    <GBaseTag color="upcoming" label="Booking" m="0 0 0 auto" />
                  )}
                </Box>
              ))}
              {giftcards?.map((prompt, idx) => (
                <Box
                  as="li"
                  key={'prompt' + idx}
                  minH="40px"
                  display="flex"
                  alignItems={{ base: 'start', md: 'center' }}
                  flexDir={{ base: 'column', md: 'row' }}
                  p="5px 10px 5px 20px"
                  _hover={{ bg: 'custom.gray.50' }}
                  cursor="pointer"
                  onClick={() => acceptPrompt(prompt, 'giftcard')}
                  borderBottom={`1px solid ${customColors.gray[50]}`}
                >
                  <Box
                    d={{ base: 'flex', md: 'block' }}
                    w={{ base: '100%', md: 'auto' }}
                    mb={{ base: '4px', md: 'unset' }}
                  >
                    <Button
                      variant="blue"
                      w="80px"
                      p="2px 5px"
                      h="32px"
                      mr="5px"
                      onClick={goTo(`${GIFT_CARDS_URL}/?find=${prompt.code}`)}
                    >
                      {prompt?.code?.replace('_rebooked', '(R)') || '-'}
                    </Button>
                    {!isLargerThan768 && (
                      <GBaseTag
                        color="completed"
                        label="giftcard"
                        m="0 0 0 auto"
                        h={{ base: '32px', md: 'auto' }}
                      />
                    )}
                  </Box>
                  {prompt.recipient_name} {prompt.recipient_email}{' '}
                  {isLargerThan768 && (
                    <GBaseTag
                      color="completed"
                      label="giftcard"
                      m="0 0 0 auto"
                    />
                  )}
                </Box>
              ))}
            </Box>
          )}
        {isLargerThan768 && (
          <InputRightElement>
            {isSelectedObjectComplete ? (
              <IconButton
                aria-label={'reset'}
                variant="unstyled"
                icon={<CrosshairIcon color="red" />}
                onClick={reset}
              />
            ) : (
              <SearchIcon color="custom.gray.200" />
            )}
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  )
}
