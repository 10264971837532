import { Box, Button, IconButton, Input, useToast } from '@chakra-ui/react'
import { PencilIcon } from '../../../../../../assets/Icons/PencilIcon'
import { CrosshairIcon } from '../../../../../../assets/Icons/CrosshairIcon'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  matchMedia768InState,
  PERMISSION_LOCATIONS
} from '../../../../../../constants'
import { DisketteIcon } from '../../../../../../assets/Icons/DisketteIcon'
import AcceptionDeleteModal from '../AcceptionDeleteModal'
import { useQueryClient } from 'react-query'
import {
  useDeleteWaivercheckout,
  usePatchWaivercheckout
} from '../../../../../../libs/hooks/useWaiverscheckouts'
import PermissionRequired from '../../../../../Common/PermissionRequired'
import { generateWarningToast } from '../../../../../../libs'

export default function CheckoutsWaiverItem({
  waiverBlockId = '',
  placeholder,
  waivercheckoutId = '',
  waiverId,
  text = '',
  ...rest
}) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { mutate: deleteCheckout } = useDeleteWaivercheckout()
  const { mutate: patchCheckout } = usePatchWaivercheckout()
  const isLargerThan768 = useSelector(matchMedia768InState)
  const [editMode, setEditMode] = useState(false)
  const inputVariant = editMode ? 'gbase' : 'gbase2'
  const [input, setInput] = useState(text)
  const locationsPermission = PermissionRequired(PERMISSION_LOCATIONS, true)
  function onChangeHandler() {
    return (e) => setInput(e.target.value)
  }

  function setEditModeHandler(state, itsSave = false) {
    return () => {
      setEditMode(state)
      if (!itsSave && !state) {
        setInput(text)
      }
      if (itsSave && !state) {
        const toSend = {
          waivercheckoutId: waivercheckoutId,
          text: input,
          block: waiverBlockId
        }
        patchCheckout(
          {
            obj: toSend
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['waiver', +waiverId])
            },
            onError: (err) => {
              const msg = err?.response?.data?.msg
              const msgObjects = err?.response?.data
              generateWarningToast({
                id: 'edit-checkout',
                toast,
                msgObjects,
                message: msg
              })
            }
          }
        )
      }
    }
  }

  function deleteCheckoutHandler(onClose = null) {
    const toSend = {
      waivercheckoutId: waivercheckoutId
    }
    deleteCheckout(
      {
        obj: toSend
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['waiver', +waiverId])
          if (onClose !== null && typeof onClose === 'function') {
            onClose()
          }
        },
        onError: (err) => {
          const msg = err?.response?.data?.msg
          const msgObjects = err?.response?.data
          generateWarningToast({
            id: 'delete-checkout',
            toast,
            msgObjects,
            message: msg
          })
        }
      }
    )
  }

  return (
    <Box mb="16px" d="flex" flexDir={{ base: 'column', md: 'row' }} {...rest}>
      <Input
        variant={inputVariant}
        mb={{ base: '8px', md: 'unset' }}
        value={input}
        isDisabled={!editMode}
        onChange={onChangeHandler()}
        placeholder={placeholder}
      />

      {isLargerThan768 ? (
        <Box
          d="grid"
          gridTemplateColumns="1fr 1fr"
          gridGap="20px"
          alignItems="center"
          ml="20px"
        >
          {editMode ? (
            <>
              <IconButton
                variant="funcColumnIconButton"
                color="custom.blue.600"
                w="12px"
                minW="unset"
                h="12px"
                minH="unset"
                isDisabled={input === ''}
                _hover={{ boxShadow: 'none' }}
                _focus={false}
                onClick={setEditModeHandler(false, true)}
                icon={<DisketteIcon />}
              />
              <IconButton
                variant="funcColumnIconButton"
                color="custom.gray.200"
                w="12px"
                minW="unset"
                h="12px"
                minH="unset"
                onClick={setEditModeHandler(false, false)}
                icon={<CrosshairIcon />}
              />
            </>
          ) : (
            <>
              <IconButton
                variant="funcColumnIconButton"
                color="custom.gray.200"
                w="12px"
                minW="unset"
                h="12px"
                minH="unset"
                _hover={{ boxShadow: 'none' }}
                _focus={false}
                isDisabled={!locationsPermission}
                onClick={setEditModeHandler(true, false)}
                icon={<PencilIcon />}
              />
              <AcceptionDeleteModal
                type="checkout"
                text={input}
                functionForDelete={deleteCheckoutHandler}
                icon
              />
            </>
          )}
        </Box>
      ) : (
        <Box d="grid" gridTemplateColumns="1fr 1fr" gridGap="8px">
          {editMode ? (
            <>
              <Button variant="blue" onClick={setEditModeHandler(false, true)}>
                <DisketteIcon mr="8px" />
                Save
              </Button>
              <Button variant="red" onClick={setEditModeHandler(false, false)}>
                <CrosshairIcon mr="8px" />
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button variant="blue" onClick={setEditModeHandler(true)}>
                <PencilIcon mr="8px" />
                Edit
              </Button>
              <AcceptionDeleteModal
                type="checkout"
                text={input}
                functionForDelete={deleteCheckoutHandler}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
