import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import OrderCard from '../OrderCard'
import { preBookingsInState } from '../../../constants'
import { correctionTimezone, friendlyTime } from '../../../libs'
import { useExperiences } from '../../../libs/hooks/useExperiences'
import { useLocations } from '../../../libs/hooks/useLocations'
import moment from 'moment'

export default function OrderCards() {
  const { preBookings } = useSelector(preBookingsInState)
  const { data: { results: locations = [] } = {} } = useLocations()
  const { data: { results: experience = [] } = {} } = useExperiences()
  const preparedData = useMemo(() => {
    if (preBookings?.length !== 0) {
      return preBookings?.map((book) => {
        let locationName = ''
        let experienceName = ''
        for (let l = 0; l < locations?.length; l++) {
          if (book?.locationId === locations[l].id) {
            locationName = locations[l].name
            break
          }
        }

        for (let e = 0; e < experience?.length; e++) {
          if (book?.eventvariant === experience[e].id) {
            experienceName = experience[e].name
            break
          }
        }
        const start = moment(book?.interval?.start).format('h:mm A')
        const end = moment(book?.interval?.end).format('h:mm A')
        const date = moment(book?.date).format('MM.DD.YY')
        return {
          ...book,
          discounts: { M: book.M, A: book.A, S: book.S },
          experience: experienceName,
          startTime: start,
          endTime: end,
          location: locationName,
          date: date,
          intervalId: book?.interval?.id
        }
      })
    } else return []
  }, [experience, locations, preBookings])

  return (
    <Box mt="2px">
      {preparedData?.map((card, idx) => {
        return (
          <OrderCard
            experience={card.experience}
            date={card.date}
            location={card.location}
            startTime={card.startTime}
            endTime={card.endTime}
            players={card.players}
            price={card.price}
            key={'ordercard' + idx}
            idx={idx}
            intervalId={card.intervalId}
            discounts={card.discounts}
          />
        )
      })}
    </Box>
  )
}
